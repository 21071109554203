
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewChild, type OnInit, ElementRef, Input, Output, EventEmitter, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ClaseGastoCajaChica } from 'src/app/Finanzas/interfaces/tipotransferencia.interface';
import { FinanzasService } from 'src/app/Finanzas/services/finanzas.service';
import { UserLogged } from 'src/app/auth/interfaces';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { Proveedor } from 'src/app/compras/interfaces/proveedores.interface';
import { DescargaCfdi } from 'src/app/efisco/interfaces/solicitud.interface';
import { SearchConfiguration } from 'src/app/service/interfaces/data-search.interface';
import { ModalService } from 'src/app/service/modal.service';
import { UtilsService } from 'src/app/service/utils.service';
import { Almacen, ConceptoAlmacen } from 'src/app/home/interfaces/almacen.interface';
import { FacturacionService } from 'src/app/ventas/services/facturacion.service';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EventsService } from 'src/app/service/events.service';
import { Producto } from 'src/app/Inventarios/interfaces/producto.interface';

@Component({
  selector: 'ctrl-edicion-producto-compuesto-page',
  templateUrl: './ctrl-edicion-producto-compuesto-page.component.html',
})
export class CtrlEdicionProductoCompuestoPageComponent implements OnInit {

  @ViewChild('txtProducto')
  public txtProducto!: ElementRef<any>;

  @ViewChild('txtAlmacen')
  public txtAlmacen!: ElementRef<any>;

  @ViewChild('txtConcepto')
  public txtConceptoAlmacen!: ElementRef<any>;

  @ViewChild('txtUnidades')
  public txtUnidades!: ElementRef<any>;
  @ViewChild('busquedaProductos')
  public ctrlBusquedaProductos!: ElementRef<HTMLElement>;

  @ViewChild('busquedaAlmacen')
  public ctrlbusquedaAlmacen!: ElementRef<HTMLElement>;

  @ViewChild('busquedaConceptoAlmacen')
  public ctrlbusquedaConceptoAlmacen!: ElementRef<HTMLElement>;

  @Input()
  public item!: any | null;
  @Output()
  public onCloseEdition: EventEmitter<any> = new EventEmitter();



  private readonly baseUrl: string = environment.baseUrlApi;
  searchConfigurationProducto: SearchConfiguration | null = null;
  searchConfigurationAlmacen: SearchConfiguration | null = null;
  searchConfigurationConceptoAlmacen: SearchConfiguration | null = null;

  info: UserLogged = {} as UserLogged;
  addingcfdi: boolean = false;
  fechaActual: Date = new Date();
  private vtasService = inject(FacturacionService)
  private eventsService = inject(EventsService);
  private http = inject(HttpClient);

  public myForm: FormGroup = this.fb.group({
    Id: [0],
    Producto: [{} as Producto],
    Cantidad: [],
    Almacen: [{} as Almacen],
    ConceptoSalida: [{} as ConceptoAlmacen],
  })

  constructor(private fb: FormBuilder, private ms: ModalService, private utileService: UtilsService) { }
  ngOnInit(): void {
    this.info = this.utileService.getUserLogged();
    this.searchConfigurationProducto = this.ms.GetSearchConfiguration("Producto", `Empresa.Id = ${this.info.empresa!.numero}`);
    this.searchConfigurationAlmacen = this.ms.GetSearchConfiguration("Almacen", `Empresa.Id = ${this.info.empresa!.numero}`);
    this.searchConfigurationConceptoAlmacen = this.ms.GetSearchConfiguration("ConceptoAlmacen", `Empresa.Id = ${this.info.empresa!.numero} && EsSalida=true`);


    setTimeout(() => {
      const t: any = this.txtProducto;
      if (this.item) {
        this.myForm.reset({ ...this.item });
        if (this.item.Producto) {
          t.tagInput.nativeElement.value = this.item.Producto.Clave;
        }
      }


      t.tagInput.nativeElement.focus();
    }, 150);
  }


  selectedItem(ent: ComboBoxEntity) {
    this.myForm.controls["clase"].setValue(ent);
  }

  blurProducto(value: any) {
    if (value.after != value.before) {
      if (value.after) {
      } else {
        this.myForm.controls["Producto"].setValue(null);
      }
    }
  }

  selectedCombo(ent: any, tipo: string) {
    this.myForm.controls[tipo].setValue(ent);
  }


  openProdSearch() {
    const b: any = this.ctrlBusquedaProductos;
    this.ms.openModal(b, (e: any) => {
      if (e) {
        this.myForm.controls["Producto"].setValue(e);
        const t: any = this.txtProducto;
        t.tagInput.nativeElement.value = e.Clave;
        setTimeout(() => {
          const t2: any = this.txtUnidades;
          t2.tagInput.nativeElement.focus();
        }, 100);
      } else {
        setTimeout(() => {
          const t: any = this.txtProducto;
          t.tagInput.nativeElement.focus();
        }, 100);
      }
    }, 'lg')
  }

  openConceptoAlmacenSearch() {
    const b: any = this.ctrlbusquedaConceptoAlmacen;
    this.ms.openModal(b, (e: any) => {
      if (e) {
        this.myForm.controls["ConceptoSalida"].setValue(e);
        const t: any = this.txtConceptoAlmacen;
        t.tagInput.nativeElement.value = e.Clave;
      } else {
        setTimeout(() => {
          const t: any = this.txtConceptoAlmacen;
          t.tagInput.nativeElement.focus();
        }, 100);
      }
    }, 'lg')
  }

  openAlmacenSearch() {
    const b: any = this.ctrlbusquedaAlmacen;
    this.ms.openModal(b, (e: any) => {
      if (e) {
        this.myForm.controls["Almacen"].setValue(e);
        const t: any = this.txtAlmacen;
        t.tagInput.nativeElement.value = e.Clave;
        setTimeout(() => {
          const t2: any = this.txtConceptoAlmacen;
          t2.tagInput.nativeElement.focus();
        }, 100);
      } else {
        setTimeout(() => {
          const t: any = this.txtAlmacen;
          t.tagInput.nativeElement.focus();
        }, 100);
      }
    }, 'lg')
  }


  get getFilter(): any {
    if (this.searchConfigurationProducto) {
      return this.searchConfigurationProducto.filter;
    }
    return "";
  }

  get getAlmacenFilter(): any {
    if (this.searchConfigurationAlmacen) {
      return this.searchConfigurationAlmacen.filter;
    }
    return "";
  }


  get getProductsColumns(): any {
    if (this.searchConfigurationProducto) {
      return this.searchConfigurationProducto.columns;
    }
    return [];
  }

  get getAlmacenColumns(): any {
    if (this.searchConfigurationAlmacen) {
      return this.searchConfigurationAlmacen.columns;
    }
    return [];
  }

  get getConceptoAlmacenColumns(): any {
    if (this.searchConfigurationConceptoAlmacen) {
      return this.searchConfigurationConceptoAlmacen.columns;
    }
    return [];
  }


  get getConceptoAlmacenFilter(): any {
    if (this.searchConfigurationConceptoAlmacen) {
      return this.searchConfigurationConceptoAlmacen.filter;
    }
    return "";
  }

  get getConceptoAlmacenPropertys(): string {
    if (this.searchConfigurationConceptoAlmacen) {
      let props = "";
      this.searchConfigurationConceptoAlmacen.propertys.forEach((prop) => {
        props += `${prop.name}|${prop.type},`
      })

      if (props.length > 0) {
        props = props.substring(0, props.length - 1);
        return props;
      }
    }
    return "";
  }



  get getProductsPropertys(): string {
    if (this.searchConfigurationProducto) {
      let props = "";
      this.searchConfigurationProducto.propertys.forEach((prop) => {
        props += `${prop.name}|${prop.type},`
      })

      if (props.length > 0) {
        props = props.substring(0, props.length - 1);
        return props;
      }
    }
    return "";
  }

  get getAlmacenPropertys(): string {
    if (this.searchConfigurationAlmacen) {
      let props = "";
      this.searchConfigurationAlmacen.propertys.forEach((prop) => {
        props += `${prop.name}|${prop.type},`
      })

      if (props.length > 0) {
        props = props.substring(0, props.length - 1);
        return props;
      }
    }
    return "";
  }


  get getNombreConceptoAlmacen(): string {
    if (this.myForm.value.ConceptoSalida) {
      if (this.myForm.value.ConceptoSalida.Nombre) {
        return this.myForm.value.ConceptoSalida.Nombre;
      }
    }
    return "";
  }


  get getNombreAlmacen(): string {
    if (this.myForm.value.Almacen) {
      if (this.myForm.value.Almacen.Nombre) {
        return this.myForm.value.Almacen.Nombre;
      }
    }
    return "";
  }




  get getNombreProducto(): string {
    if (this.myForm.value.Producto) {
      if (this.myForm.value.Producto.Nombre) {
        return this.myForm.value.Producto.Nombre;
      }
    }
    return "";
  }


  keydown(e: any) {
    if (e.code == "F8") {
      this.accept();
    } else if (e.code == "F9") {
      this.closeModal();
    }
  }

  openCfdi() {
    this.addingcfdi = true;
  }

  closeModal() {
    this.ms.closeModal(null);
  }

  accept() {

    const ent = this.myForm.value;

    let idProd = 0, idConcAlm = 0, idAlm = 0;

    if (ent.Producto) {
      if (ent.Producto.Id > 0) { idProd = ent.Producto.Id; }
    }

    if (ent.Almacen) {
      if (ent.Almacen.Id > 0) { idAlm = ent.Almacen.Id; }
    }

    if (ent.ConceptoSalida) {
      if (ent.ConceptoSalida.Id > 0) { idConcAlm = ent.ConceptoSalida.Id; }
    }

    if (idProd == 0) {
      this.eventsService.publish('home:showAlert', { message: "Debe de indicar un Producto", cancelButton: false });
      return;
    }

    if (ent.Cantidad <= 0) {
      this.eventsService.publish('home:showAlert', { message: "Debe de indicar la Cantidad.", cancelButton: false });
      return;
    }

    if (idAlm == 0) {
      this.eventsService.publish('home:showAlert', { message: "Debe de indicar un Almacen.", cancelButton: false });
      return;
    }

    if (idConcAlm == 0) {
      this.eventsService.publish('home:showAlert', { message: "Debe de indicar un Concepto de Salida de Almacen.", cancelButton: false });
      return;
    }
    this.ms.closeModal(ent);
  }
}
