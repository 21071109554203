import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EventsService } from 'src/app/service/events.service';
import { CorteCajaService } from '../../services/cortecaja.service';
import { Result } from 'src/app/auth/interfaces';
import { UtilsService } from 'src/app/service/utils.service';
import { ModalService } from 'src/app/service/modal.service';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { CorteCaja } from '../../interfaces/cortecaja.interface';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';

@Component({
  selector: 'seleccion-corte-caja',
  templateUrl: './seleccion-corte-caja.component.html',
  styleUrls: ['./seleccion-corte-caja.component.scss']
})
export class SeleccionCorteCajaComponent {
  @ViewChild('cboSucursal') public cboSucursal!: ElementRef<TextBoxComponent>;

  cortesCaja: CorteCaja[] = [];
  mensajeSinCortes: string = "SIN CORTES DE CAJA";

  private utilsService = inject(UtilsService);
  private fb = inject(FormBuilder);
  private corteCajaService = inject(CorteCajaService);
  private eventsService = inject(EventsService);
  private modalService = inject(ModalService);

  opcionSeleccionada?: ComboBoxEntity;
  get selectCuentaManualItem(): ComboBoxEntity | null {
    return this.opcionSeleccionada!;
  }

  ngOnInit() {
    setTimeout(() => {
      const txt: any = this.cboSucursal;
      txt.tagInput.nativeElement.focus()
    }, 0);

    setTimeout(() => {
      let usuarioLogin = this.utilsService.getUserLogged();
      this.opcionSeleccionada = { Id: usuarioLogin.sucursal?.numero!, Clave: usuarioLogin.sucursal?.clave!, Nombre: usuarioLogin.sucursal?.nombre! } as ComboBoxEntity;
      this.busquedaCorteCaja.sucursalId = usuarioLogin.sucursal?.numero!;
      this.buscarCorte();

    }, 100);
  }

  today: Date = new Date();
  public frmBuscarCorte: FormGroup = this.fb.group({
    Sucursal: [],
    FechaInicio: [new Date(this.today.getFullYear(), this.today.getMonth(), 1)],
    FechaFin: [new Date()],
  });

  seleccionSucursal(event: any) {
    if (event) {
      this.busquedaCorteCaja.sucursalId = event.Id
    }
  }

  seleccionarCorte(corte: any) {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.corteCajaService.buscarCortePorId(corte.Id).subscribe({
      next: (resp: Result) => {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        this.modalService.closeModal(JSON.parse(resp.message));
      },
      error: (err: Error) => console.error(err)
    })
  }

  busquedaCorteCaja: any = {
    sucursalId: 0,
    fechaInicio: new Date(this.today.getFullYear(), this.today.getMonth(), 1),
    fechaFin: new Date()
  }

  buscarCorte() {
    if (this.busquedaCorteCaja.sucursalId === 0) {
      this.eventsService.publish('home:showAlert', { message: `Debe seleccionar una sucursal para la busqueda de los cortes de caja.`, icon: 'fa-triangle-exclamation text-yellow', cancelButton: false });
      return;
    }
    let inicio = this.utilsService.Date2Str(this.busquedaCorteCaja.fechaInicio, 3);
    let fin = this.utilsService.Date2Str(this.busquedaCorteCaja.fechaFin, 3);
    this.utilsService.isLoad(true);
    this.corteCajaService.buscarCortesCaja(this.busquedaCorteCaja.sucursalId, inicio, fin).subscribe({
      next: (resp: Result) => {
        this.cortesCaja = JSON.parse(resp.message);
        this.utilsService.isLoad(false);
        if (this.cortesCaja.length === 0) {
          this.mensajeSinCortes = "NO SE ENCONTRARON CORTES DE CAJA";
        }
      },
      error: (err: Error) => console.error(err)
    })
  }

  onChangeFechasCorte(event: any, prop: string) {
    this.busquedaCorteCaja[prop] = event;
  }

  close() {
    this.modalService.closeModal(null);
    this.mensajeSinCortes = "SIN CORTES DE CAJA";
    this.busquedaCorteCaja = {
      sucursalId: 0,
      fechaInicio: new Date(),
      fechaFin: new Date()
    }
    this.cortesCaja = [];
  }

}
