import { Component, ElementRef, inject, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Validators } from '@angular/forms';
import { EnumDataType, TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { ActiveButtons, EventReturn } from 'src/app/component-ui/interfaces/container-base.interface';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { ConfiguracionService } from 'src/app/configuracion/service/configuracion.service';
import { ClaseCliente, TipoCliente } from '../../interfaces/tipocliente.interface';
import { Cliente, c_RegimenFiscal, c_UsoCfdi } from '../../interfaces/cliente.interface';
import { Empresa } from 'src/app/configuracion/interfaces/empresa.interface';
import { Direccion, DireccionSAT } from 'src/app/configuracion/interfaces/direccion.interface';
import { Moneda } from 'src/app/configuracion/interfaces/moneda.interface';
import { CondicionPago } from '../../interfaces/condicionpago.interface';
import { c_FormaPago } from '../../interfaces/formapago.interface';
import { ClaseVenta } from '../../interfaces/claseventa.interface';
import { Usuario } from 'src/app/configuracion/interfaces/usuario.interface';
import { ModalService } from 'src/app/service/modal.service';
import { ListaPrecio } from '../../interfaces/listaprecio.interface';
import { UtilsService } from 'src/app/service/utils.service';
import { UserLogged } from 'src/app/auth/interfaces';
import { PredefinidoCliente } from '../../interfaces/predefinidocliente.interface';
import { EventsService } from 'src/app/service/events.service';
import { SearchConfiguration } from 'src/app/service/interfaces/data-search.interface';
import { FacturacionService } from '../../services/facturacion.service';
import { event } from 'jquery';

@Component({
  selector: 'app-clientes-page',
  templateUrl: './clientes-page.component.html',
  styleUrls: ['./clientes-page.component.scss'],
})
export class ClientesPageComponent implements OnInit {
  cobranza: any = {
    lunes: false,
    martes: false,
    miercoles: false,
    jueves: false,
    viernes: false,
    sabado: false
  }
  idUnico: string = '';
  private eventsService = inject(EventsService);
  predefinido: PredefinidoCliente | null = null;
  noActivarOpcionesCredito: boolean = false;
  public itemsCombo: ComboBoxEntity[] = [];
  filter = "";
  info: UserLogged = { ambienteId: 0, numero: 0, clave: 0, nombreUsuario: "", nombreCompletoUsuario: "" };

  @ViewChild('ctrlBusqueda')
  public ctrlBusqueda!: ElementRef<HTMLElement>;

  public EDT = EnumDataType;
  activeButtons: ActiveButtons = {
    new: true,
    delete: true,
    return: true,
    save: true,
    first: true,
    left: true,
    right: true,
    last: true,
    search: true,
    print: false,
    exit: false
  }

  @Input()
  public esVentana: boolean = false;


  nombreCabecera: string = "";

  private newEntityObj = {
    Empresa: [{} as Empresa, Validators.required],
    Id: [0],
    ClienteCabeceraId: [0],
    Clave: [0],
    Nombre: [''],
    Rfc: ['', [Validators.required, Validators.pattern(this.configuracionService.RegExRfc)]],
    RegimenFiscal: [{} as c_RegimenFiscal, Validators.required],
    UsoCfdi: [{} as c_UsoCfdi, Validators.required],
    Direccion: [{} as Direccion, Validators.required],
    DireccionSAT: [{} as DireccionSAT],
    NumRegIdTrib: [''],
    Moneda: [{} as Moneda, Validators.required],
    CondicionDePago: [{} as CondicionPago, Validators.required],
    FormaDePago: [{} as c_FormaPago, Validators.required],
    Telefonos: [''],
    ReferenciaBancaria: [''],
    Correos: [''],
    Tipo: [{} as TipoCliente, Validators.required],
    Clase: [{} as ClaseCliente, Validators.required],
    ClaseVenta: [{} as ClaseVenta, Validators.required],
    ClaseVenta2: [{} as ClaseVenta],
    ClaseVenta3: [{} as ClaseVenta],
    ListaPrecio: [{} as ListaPrecio],
    Vendedor: [{} as Usuario],
    Cobrador: [{} as Usuario],
    LimiteCredito: [0],
    Descuento: [0],
    NoValidarCredito: [false],
    ParaTickets: [false],
    CreditoSuspendido: [false],
    SuspenderCreditoAlFacturar: [false],
    Comentarios: [''],
    ParaFacturaGlobal: [false],
    ClienteBaseSistema: [false],
    NoAnticipos: [false],
    Asientos: [[]],
    Baja: [false],
    LunesCobranza: [false],
    MartesCobranza: [false],
    MiercolesCobranza: [false],
    JuevesCobranza: [false],
    ViernesCobranza: [false],
    SabadoCobranza: [false],


  };

  public myForm: FormGroup = this.fb.group(this.newEntityObj);

  @ViewChild('txtNombre')
  public txtNombre!: ElementRef<TextBoxComponent>;

  @ViewChild('txtRfc')
  public txtRfc!: ElementRef<HTMLElement>;

  @ViewChild('txtClave')
  public txtClave!: ElementRef<TextBoxComponent>;

  @ViewChild('seleccionarCuenta')
  public ctrlCuenta!: ElementRef<HTMLElement>;

  searchConfigurationCliente: SearchConfiguration | null = null;

  constructor(private configuracionService: ConfiguracionService,
    private fb: FormBuilder, private containerService: ContainerBaseService,
    private utilsService: UtilsService,
    private fs: FacturacionService,
    private ms: ModalService) {
  }
  ngOnInit(): void {
    this.idUnico = String(new Date().getTime() * 10000);
    this.info = this.utilsService.getUserLogged();
    this.filter = `Empresa.Id = ${this.info.empresa!.numero}`
    this.searchConfigurationCliente = this.ms.GetSearchConfiguration("Cliente", `Empresa.Id = ${this.info.empresa!.numero}`);
    if (this.esVentana) {
      this.activeButtons = {
        new: true,
        delete: true,
        return: true,
        save: true,
        first: true,
        left: true,
        right: true,
        last: true,
        search: true,
        print: true,
        exit: true
      }
    }
    this.obtenerPredefinidos();
    this.containerService.obtenerValorPropiedad("Usuario", "Rol.NoActivarOpcionesCredito", this.info.numero).subscribe((value) => {
      this.noActivarOpcionesCredito = value.toLowerCase() == "true";
    });
  }

  obtenerPredefinidos() {
    this.containerService.getLastEntity("PredefinidoCliente").subscribe((ent) => {
      this.predefinido = ent;
    });
  }

  get getNombreCabecera(): string {
    return this.nombreCabecera;
  }


  onBeforeSave(param: EventReturn) {
    param.callback(true);
  }

  eliminarClienteCabecera() {
    this.nombreCabecera = "";
    this.myForm.controls["ClienteCabeceraId"].setValue(0);
  }

  openClienteSearch() {
    const b: any = this.ctrlBusqueda;
    this.ms.openModal(b, (e: any) => {
      if (e) {
        if (e.Id != this.myForm.value.Id) {
          this.myForm.controls["ClienteCabeceraId"].setValue(e.Id);
          this.nombreCabecera = e.Nombre;
        } else {
          this.eventsService.publish('home:showAlert', { message: "No puede seleccionar al mismo Cliente como cabecera, verifique.", cancelButton: false });
        }
      }
    }, 'lg')
  }

  get getColumns(): any {
    if (this.searchConfigurationCliente) {
      return this.searchConfigurationCliente.columns;
    }
    return [];
  }

  get getPropertys(): string {
    if (this.searchConfigurationCliente) {
      let props = "";
      this.searchConfigurationCliente.propertys.forEach((prop) => {
        props += `${prop.name}|${prop.type},`
      })

      if (props.length > 0) {
        props = props.substring(0, props.length - 1);
        return props;
      }

    }
    return "";
  }


  onAfterSave(entity: Cliente) {
    this.myForm.reset(entity);
  }

  onItemSearched(ent: Cliente): void {
    this.nombreCabecera = "";
    if (ent) {

      if (ent.Empresa) {
        this.filter = `Empresa.Id = ${ent.Empresa.Id}`;
      } else {
        this.filter = '';
        if (this.myForm.value) {
          if (this.myForm.value.Empresa) {
            ent.Empresa = this.myForm.value.Empresa;
            if (!ent.Empresa["Id"]) {
              this.myForm.controls["Empresa"].setValue({ Id: this.info.empresa!.numero, Clave: this.info.empresa!.clave, Nombre: this.info.empresa!.nombre })
              ent.Empresa = this.myForm.value.Empresa;
              this.filter = `Empresa.Id = ${this.info.empresa!.numero}`;
            } else {
              this.filter = `Empresa.Id = ${this.myForm.value.Empresa.Id}`
            }
          }
        }
      }

      if (ent.Id == 0 && this.predefinido) {
        ent.CondicionDePago = this.predefinido.CondicionDePago;
        ent.ClaseVenta = this.predefinido.ClaseVenta1;
        ent.ClaseVenta2 = this.predefinido.ClaseVenta2;
        ent.ClaseVenta3 = this.predefinido.ClaseVenta3;
        ent.FormaDePago = this.predefinido.FormaPago;
        ent.Moneda = this.predefinido.Moneda;
        ent.Tipo = this.predefinido.TipoCliente;
        ent.Clase = this.predefinido.ClaseCliente;
        ent.ListaPrecio = this.predefinido.ListaPrecio;
      }

      if (ent.Id == 0 && !this.predefinido && this.noActivarOpcionesCredito) {
        this.eventsService.publish('home:showAlert', { message: "No ha configurado los Predefinidos para las Altas de Clientes, verifique con el administrador de COMPLIT.", cancelButton: false });
      }
      if (ent.ClienteCabeceraId > 0) {
        this.fs.obtenerNombreCliente(ent.ClienteCabeceraId).subscribe((result) => {
          this.nombreCabecera = result.message;
        })
      }
      this.myForm.reset(ent);
      setTimeout(() => {
        const txt: any = this.txtRfc;
        txt.tagInput.nativeElement.focus();
      }, 50);

      this.cobranza = {
        lunes: ent.LunesCobranza,
        martes: ent.MartesCobranza,
        miercoles: ent.MiercolesCobranza,
        jueves: ent.JuevesCobranza,
        viernes: ent.ViernesCobranza,
        sabado: ent.SabadoCobranza
      }
    }
  }

  enterField(e: any) {
    const txt: any = this.txtClave;
    txt.tagInput.nativeElement.blur();
  }

  openBankAccounts() {
    const b: any = this.ctrlCuenta;
    this.ms.openModal(b, (e: any) => {
    }, 'lg')
  }

  onCloseAsiento(resp: any) {
    if (resp) {
      this.myForm.controls["Asientos"].setValue([...resp.items]);
    }
  }

  blur(values: any) {
    const txt: any = this.txtClave;
    if (isNaN(Number(txt.value))) return;
    if (!txt.value) {
      this.containerService.getLastEntity("Cliente", this.filter).subscribe((ent) => {
        this.onChangueEntity(ent);
      });
      return;
    }
    if (values.before != values.after) {
      this.searchByField(txt.value);
    } else {
      setTimeout(() => {
        const txt: any = this.txtRfc;
        txt.tagInput.nativeElement.focus();
      }, 50);
    }
  }

  searchByField(value: any) {
    this.containerService.getEntityByClave("Cliente", parseInt(value), this.filter).subscribe((ent) => {
      if (ent) {
        this.onChangueEntity(ent);
      } else {
        this.containerService.getEmptyEntity("Cliente")
          .subscribe((ent) => {
            this.onChangueEntity({ ...ent, Clave: parseInt(value) });
          });
      }
    });
  }

  get getIsNew(): any {
    return this.myForm.value.Id == 0;
  }

  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombre;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  onChangueEntity(ent: any) {
    if (ent) {
      this.onItemSearched(ent);
    } else {
      this.nombreCabecera = "";
    }
  }

  get getFilter(): string {
    return this.filter;
  }

  get getEnableText(): boolean {

    if (this.myForm.value) {
      if (this.myForm.value.Id == 0) {
        return false;
      }
    }

    return true;
  }

  onItemsComboSearched(items: ComboBoxEntity[]): void {
    this.itemsCombo = items;
  }

  get getItemsCombo(): ComboBoxEntity[] {
    return this.itemsCombo;
  }

  get getIsBaseCustomer(): boolean {
    if (this.myForm.value) {
      return this.myForm.value.ClienteBaseSistema;
    }
    return false;
  }

  get getFiltro(): string {
    if (this.myForm.value.Rfc) {
      if (this.myForm.value.Rfc?.length == 12) {
        return "Moral = true";
      } else if (this.myForm.value.Rfc?.length == 13) {
        return "Fisica = true";
      }
    }
    return "";
  }

  selectedCombo(entity: ComboBoxEntity, tipo: string) {
    this.myForm.controls[tipo].setValue(entity);
  }

  changeCheckboxDiasCobro(event: any, dia: number) {
    let value = event.srcElement.checked;
    switch (dia) {
      case 1:
        this.myForm.value.LunesCobranza = value;
        this.cobranza.lunes = value;
        break;
      case 2:
        this.myForm.value.MartesCobranza = value;
        this.cobranza.martes = value;
        break;
      case 3:
        this.myForm.value.MiercolesCobranza = value;
        this.cobranza.miercoles = value;
        break;
      case 4:
        this.myForm.value.JuevesCobranza = value;
        this.cobranza.jueves = value;
        break;
      case 5:
        this.myForm.value.ViernesCobranza = value;
        this.cobranza.viernes = value;
        break;
      default:
        this.myForm.value.SabadoCobranza = value;
        this.cobranza.sabado = value;
        break;
    }
  }

  elementoSeleccionado(elemento: any) {
    setTimeout(() => {
      this.searchByField(elemento.Clave);
    }, 300);
  }

  busquedaRegistro() {
    const b: any = this.ctrlBusqueda;
    this.ms.openModal(b, (e: any) => {
      if (e) {
        this.searchByField(e.Clave);
      }
    }, 'lg')
  }
}
