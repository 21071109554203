import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { ReportFilter, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ReportsService } from 'src/app/service/reports.service';
import { UtilsService } from 'src/app/service/utils.service';

@Component({
  selector: 'app-verificador-ordenes',
  templateUrl: './verificador-ordenes.component.html',
  styleUrls: ['./verificador-ordenes.component.scss']
})
export class VerificadorOrdenesComponent {
  @ViewChild('txtNombreReporte')
  public txtNombreReporte!: ElementRef;
  private reportsService = inject(ReportsService);
  private utils = inject(UtilsService);
  private fb = inject(FormBuilder);


  listaOpciones: any[] = [
    { Id: 1, Clave: 1, Nombre: "Vigente" },
    { Id: 2, Clave: 2, Nombre: "Cancelado" },
    { Id: 3, Clave: 3, Nombre: "Todos" }
  ];
  opcionSeleccionada?: ComboBoxEntity;

  menuId: string = "verificadorordenes";


  activeButtons: ActiveButtons = {
    all: false,
    print: true,
  }

  public verificadorOrdenesFrm: FormGroup = this.fb.group({
    Fecha1: new Date(),
    Fecha2: new Date(),
    NombreReporte: '',
    Num1: 0
  });


  ngOnInit(): void {
    const fecha: string = this.utils.Date2Str(new Date(), 5);
    this.verificadorOrdenesFrm.get('NombreReporte')?.setValue('VERIFICADOR DE ÓRDENES ' + this.rangoFechas);
    setTimeout(() => {
      this.opcionSeleccionada = this.listaOpciones[2];
      this.focus();
    }, 100);
  }

  onChangeFecha(value: any) {
    this.verificadorOrdenesFrm.get('NombreReporte')?.setValue('VERIFICADOR DE ÓRDENES ' + this.rangoFechas);
  }

  get rangoFechas() {
    return this.utils.getEtiquetaRangoPorFechas(this.verificadorOrdenesFrm.value.Fecha1, this.verificadorOrdenesFrm.value.Fecha2, 2).toUpperCase();
  }

  public filtrosReporte: ReportFilter =
    {
      menuId: this.menuId,
      ReportHeader: {
        Fecha1: new Date(),
        Fecha2: new Date(),
      } as ReportHeader,
      NombreReporte: '',
      TituloVisor: 'Verificador de Ordenes',
      NombreExcel: 'Verificador de Ordenes.xlsx',
      FilterOptions: [
        { Campo: 'emp.Clave', Etiqueta: 'Empresa', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: 'Empresa' },
        { Campo: 'suc.Clave', Etiqueta: 'Sucursal', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: 'Sucursal' },
        { Campo: 'cli.Clave', Etiqueta: 'Cliente', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: 'Cliente' },
        { Campo: 'prod.Clave', Etiqueta: 'Producto', Tipo: TypeFilter.text, BotonBusqueda: true, Entidad: 'Producto' },
        { Campo: '(oman.SerieString + cast(oman.Folio as nvarchar))', Etiqueta: 'Serie Folio Pre Orden', Tipo: TypeFilter.text },
        { Campo: '(ord.Serie + cast(ord.Folio as nvarchar))', Etiqueta: 'Serie Folio Orden', Tipo: TypeFilter.text },
        { Campo: '(v.SerieString + cast(v.Folio as nvarchar))', Etiqueta: 'Serie Folio Factura', Tipo: TypeFilter.text },
      ]
    };

  focus() {
    setTimeout(() => {
      const txt: any = this.txtNombreReporte;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  clickBoton(event: string) {
    this.filtrosReporte.ReportHeader = this.verificadorOrdenesFrm.value;
    this.reportsService.printReport(this.filtrosReporte, '/Manufactura/VerificadorOrden');
  }

  changeselec(event: any) {
    this.filtrosReporte = event;
  }

  selectedCombo(entity: any) {
    this.opcionSeleccionada = entity;
    this.verificadorOrdenesFrm.get("Num1")?.setValue(this.opcionSeleccionada!.Id);
  }
  changeFiltros(value: ReportHeader) {
    if (value) {
      this.opcionSeleccionada = this.listaOpciones.find(p => p.Id == value.Num1);
    }
  }

  get selectOpcionManualItem(): ComboBoxEntity | null {
    return this.opcionSeleccionada!;
  }
}
