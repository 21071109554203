<app-container-base (onItemsComboSearched)="onItemsComboSearched($event)" (onItemSearched)="onItemSearched($event)"
  (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)" [entity]="myForm" entityName="CuentaCxc"
  title="Cuentas de CXC" icon="fa fa-note-sticky" subTitle="Administra la Cuentas de CXC">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Información">
        <div class="panel-content">
          <component-ui-combo-box (onNewCatalog)="onNewCatalog($event)" (onChangueEntity)="onChangueEntity($event)"
            [isCatalog]="true" formControlName="Id" [isNewItem]="getIsNew" [setItems]="getItemsCombo"
            [label]="'CuentaCxc'" entityName="CuentaCxc" [tabIndex]="1" [listProperty]="'ClaveNombre'"
            [widthTextColumns]="1" [widthLabelColumns]="3" />
          <components-ui-text-box [label]="'Nombre'" [required]="true" formControlName="Nombre" [tabIndex]="2"
            [widthTextColumns]="3" [widthLabelColumns]="3" #txtNombre [maxLength]="100" />

          <component-ui-check-box
            helpLine="Se validará cuando se quiera facturar a crédito si tiene importe vencido, en dado caso que si, el sistema no permitirá la venta."
            [tabIndex]="4" [widthLabelColumns]="3 " formControlName="EsParaValidarCreditoVencido" [widthTextColumns]="7"
            [label]="'Es para validar Crédito'" />

        </div>
      </app-panel-base>
    </form>
  </div>
</app-container-base>
