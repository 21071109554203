
import { Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { TimeBoxComponent } from 'src/app/component-ui/components/time-box/time-box.component';
import { ModalService } from 'src/app/service/modal.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { ConceptosCarga } from '../../interfaces/cargaentrega.interface';

@Component({
  selector: 'opcion-archivar-carga-entrega',
  templateUrl: './opcion-archivar-carga-entrega.component.html',
})
export class OpcionArchivarCargaEntregaComponent {

  // @Input()
  // Archivar: boolean = false;

  // @Input()
  // conceptosCarga: ConceptosCarga |null = null;


  @ViewChild('txtComentarioArchivar')
  public txtComentarioArchivar!: ElementRef<TextBoxComponent>;

  @ViewChild('txtHoraArchivar')
  public txtHoraArchivar!: ElementRef<TimeBoxComponent>;

  @ViewChild('botonArchivarAceptar')
  public botonArchivarAceptar!: ElementRef<HTMLButtonElement>;




  private readonly baseUrl: string = environment.baseUrlApi;


  public myForm: FormGroup = this.fb.group({
    Fecha: [Date, Validators.required],
    Comentario: '',
    Hora: ''
  })

  constructor(private fb: FormBuilder,
    private mService: ModalService, private renderer: Renderer2) {
  }

  accept() {
    let fecha: Date = this.myForm.value.Fecha;
    if (!this.myForm.value.Fecha) {
      Swal.fire({ text: `Indique la fecha para realizar la entrega.`, icon: 'error', })
      return;
    }


    if (!this.myForm.value.Hora) {
      Swal.fire({ text: `Indique la hora para realizar la entrega.`, icon: 'error', })
      return;
    }
    let datePart: string[] = this.myForm.value.Hora.split(":");
    const hora: number = parseInt(datePart[0]);
    const minuto: number = parseInt(datePart[1]);

    fecha = new Date(fecha.getFullYear(), fecha.getMonth(), fecha.getDate(), hora, minuto, 0);

    this.myForm.get("Fecha")?.setValue(fecha);


    // this.conceptosCarga!.observacionesCargaEntrega! = this.myForm.value.Comentario;
    // this.conceptosCarga!.fechaProgramadaEnvio! = this.myForm.value.Fecha;

    this.mService.closeModal(this.myForm.value);


  }

  closeModal() {
    this.mService.closeModal(null);
  }

  enterFecha(e: any) {
    e.preventDefault();
    const txtctrl: any = this.txtHoraArchivar;
    txtctrl.tagInput.nativeElement.focus();
  }

  enterHora(e: any) {
    e.preventDefault();
    const txtctrl: any = this.txtComentarioArchivar;
    txtctrl.tagInput.nativeElement.focus();
  }

  enterComentario(e: any) {
    e.preventDefault();

    const txtctrl: any = this.botonArchivarAceptar;
    txtctrl.tagInput.nativeElement.focus();

    //this.renderer.selectRootElement(this.botonArchivarAceptar.nativeElement).focus();



  }




  ngOnInit(): void {
    const ent = {
      Fecha: new Date(),
      Comentario: '',
      idEntregaNuestra:0
    };
    this.myForm.reset(ent);
  }


  // verificaArchivar(fn: any) {

  //   let mensaje = "¿Desea Archivar los elementos seleccionados?"
  //   if (!this.Archivar) {
  //     mensaje = "¿Desea Desarchivar los elementos seleccionados?"
  //   }
  //   let mensajeBoton = " Archivar";
  //   if (!this.Archivar) {
  //     mensajeBoton = " Desarchivar";
  //   }

  //   this.eventsService.publish('home:showAlert', {
  //     textAccept: "Si, " + mensajeBoton,
  //     cancelButton: true,
  //     message: mensaje,
  //     onConfirm: (data: AlertResponse) => {
  //       if (data.isAccept) {
  //         fn && fn();
  //       }
  //     }
  //   });
  // }




}
