import { Component, DEFAULT_CURRENCY_CODE, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, forwardRef, inject, AfterViewInit, ViewChildren, AfterViewChecked, SimpleChanges } from '@angular/core';
import { FilterOptions, ReportFilter, ReportFilterContentList, ReportFilterList, ReportFilterListItem, ReportFilterOrderList, TypeFilter, ReportHeader } from '../../interfaces/selection-filter.interface';
import { ContainerBaseService } from '../../services/container-base.service';
import { FormArray, FormBuilder, FormControl, FormGroup, NonNullableFormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SearchConfiguration, SearchPropertyInfo } from 'src/app/service/interfaces/data-search.interface';
import { ModalService } from 'src/app/service/modal.service';
import { DataSearchComponent } from '../data-search/data-search.component';
import { ColorChromeModule } from 'ngx-color/chrome';
import { Item } from '@generic-ui/ngx-grid/core/structure/source/src/api/item/item';
import { Validators } from 'ngx-editor';
import { EventsService } from 'src/app/service/events.service';
import { ReportsService } from 'src/app/service/reports.service';
import { id } from '@swimlane/ngx-charts';



@Component({
  selector: 'component-ui-selection-filter',
  templateUrl: './selectionFilter.component.html',
  styleUrls: ['./selectionFilter.component.css'],
})
//AfterViewChecked
export class SelectionFilterComponent implements OnInit, AfterViewInit  {

  private containerService = inject(ContainerBaseService)
  private mService = inject(ModalService);

  public sqlWhere: string = "";
  @Input()
  public FiltrosSeleccion: ReportFilter = {} as ReportFilter;

  @Input()
  public ReportHeader: FormGroup = this.fb.group({ NombreReporte: '' });

  @Input()
  public listaFiltros: ReportFilter[] = [];
  @Output()
  public onChangeFilter: EventEmitter<FormGroup> = new EventEmitter();
  @Output()
  FiltrosSeleccionChange = new EventEmitter<ReportFilter>();

  @Output()
  ReportHeaderChange = new EventEmitter<ReportHeader>();

  // @Output()
  // onLoadReportHeader = new EventEmitter<ReportHeader>();

  @Input()
  public filtros: ReportFilter = {} as ReportFilter;
  @Input()
  public tabIndex: number = 0;

  @Input()
  public containerIsModal: boolean = false;

  public vacio: string = '';
  tabIndexOrden: number = 0;
  public newFavorite: FormControl = new FormControl('');
  contentList?: ReportFilterContentList[] = [];
  orderList?: ReportFilterOrderList[] = [];
  orderThen1List?: ReportFilterOrderList[] = [];
  orderThen2List?: ReportFilterOrderList[] = [];
  filterLIst: ReportFilterList[] = [];
  FiltrosSeleccionPredefinidos: ReportFilter = {} as ReportFilter;


  @ViewChild('busquedaNiveles')
  public busquedaNiveles!: ElementRef<any>;

  @ViewChild('coloresPorcentaje')
  public coloresPorcentaje!: ElementRef<any>;

  searchConfiguration: SearchConfiguration[] = [];

  busquedaFiltros: string = "";
  BusquedaEntidadNombre: string = "";
  busquedaColumnas: string[] = [];
  busquedaPropiedades: string = "";
  busquedaOrden: string = "";
  cajaActual: any;
  cargaInicial: boolean = false;


  busquedasearchConfiguration: SearchConfiguration | null = null;


  @ViewChild('content')
  public ctrlBusqueda!: QueryList<ElementRef>;
  private eventsService = inject(EventsService);
  private reportsService = inject(ReportsService);


  @ViewChildren('inputfiltro')
  public cajasFiltros!: QueryList<ElementRef>;


  // @ViewChild('busqueda')
  // public ctrlBusquedaList!: ElementRef<HTMLElement>;
  // @ViewChild(DataSearchComponent)
  // public components!: QueryList<DataSearchComponent>
  // @ViewChildren('input') inputs: QueryList<ElementRef>;

  public myForm: FormGroup = this.fb.group({
    DesgloseLista: [''],
    Desglose: [''],
    DesgloseId: 0,
    OrdenadoLista: [''],
    OrdenadoId:0,
    Ordenado: [''],
    OrdenadoNombre: [''],
    LuegoPorLista: [''],
    LuegoPor: [''],
    LuegoPorNombre: [''],
    LuegoPorAgrupador: [''],
    LuegoPorId: 0,
    DespuesPorLista: [''],
    DespuesPor: [''],
    DespuesPorId: 0,
    NombreReporte: this.FiltrosSeleccion.NombreExcel,
    TituloVisor: this.FiltrosSeleccion.TituloVisor,
    NombreExcel: this.FiltrosSeleccion.NombreExcel,
    FilterOptions: this.fb.array([]),
    menuId: [''],
    EsContabilidad: false
  });

  ngOnChanges(changes: SimpleChanges) {
    if (changes["FiltrosSeleccion"]) {
      let valorFilterOptions: FilterOptions[] = this.myForm.get('FilterOptions')?.value as FilterOptions[];
      let controlesCambios: FilterOptions[] = changes["FiltrosSeleccion"].currentValue.FilterOptions as FilterOptions[];
      if (valorFilterOptions.length > 0) {
        controlesCambios = controlesCambios.filter(x => x.Enabled === false);
        valorFilterOptions = valorFilterOptions.map(x => {
          x.Enabled = true;
          let encontrado: FilterOptions = controlesCambios.find(c => c.Campo === x.Campo) as FilterOptions;
          if (encontrado) {
            x.Enabled = encontrado?.Enabled;
          } else {
            x.Enabled = true;
          }
          x.Valor = x.Enabled ? x.Valor : "";
          x.Nombre = x.Enabled ? x.Nombre : "";
          x.Placeholder = encontrado?.Placeholder ? encontrado?.Placeholder : "";
          return x;
        })
        this.myForm.get('FilterOptions')?.setValue(valorFilterOptions);
      } else {
        let aa = 0;
      }
    }
  }

  constructor(private fb: FormBuilder) { }
  //ngAfterViewChecked(): void {

    ngAfterViewInit(): void {

    let i = 0;
    let campo = "";
    let item: any;
    let cargado: boolean = false;

    if (this.FiltrosSeleccion) {
      if (this.FiltrosSeleccion.menuId) {
        cargado = true;
      } else { return }

    }

    let tipo: number = 0;
    if (cargado) {
      if (!this.cargaInicial) {
        this.cargaInicial = true;

        this.eventsService.publish('home:isLoading', { isLoading: true });
        const filtro: any = this.FiltrosSeleccion;
        filtro.ReportHeader = this.ReportHeader.value;
        this.reportsService.getUserFilter(filtro).subscribe((result: ReportFilter) => {
          this.eventsService.publish('home:isLoading', { isLoading: false });
          if (result) {
            this.FiltrosSeleccionPredefinidos = result;
            for (i = 0; i < this.FilterOptions.length; i++) {
              campo = this.FilterOptions.at(i).get('Campo')?.value;
              tipo = this.FilterOptions.at(i).get('Tipo')?.value;
              if (tipo !== TypeFilter.list && tipo !== TypeFilter.boolean && tipo !== TypeFilter.entityNumber && tipo !== TypeFilter.entityString) {
                item = this.FiltrosSeleccionPredefinidos.FilterOptions?.find(p => p.Campo == campo);
                if (item) {
                  const valorActual = this.FilterOptions.at(i).get('Valor')?.value;
                  if (!valorActual && item.Valor) {
                    this.FilterOptions.at(i).get('Valor')?.setValue(item.Valor);
                  }
                }
              } else {
                item = this.FiltrosSeleccionPredefinidos.FilterOptions?.find(p => p.Campo == campo);
                if (item) {
                  this.FilterOptions.at(i).get('Nombre')?.setValue(item.Nombre);
                }
              }
            }
            let desglose: any = this.FiltrosSeleccionPredefinidos.Desglose;
            if (this.FiltrosSeleccionPredefinidos.Desglose) {
              item = this.contentList?.find(p => p.Nombre == desglose);
              if (item) {
                this.myForm.get('DesgloseLista')?.setValue(item.Nombre);
                this.myForm.get('Desglose')?.setValue(item.Nombre);
                this.myForm.get('DesgloseId')?.setValue(item.Id);
              }
            }

            let ordenado: any = this.FiltrosSeleccionPredefinidos.Ordenado;
            if (this.FiltrosSeleccionPredefinidos.Ordenado) {
              item = this.orderList?.find(p => p.Orden == ordenado);
              if (item) {
                this.myForm.get('OrdenadoLista')?.setValue(item.Nombre);
                this.myForm.get('Ordenado')?.setValue(item.Orden);
                this.myForm.get('OrdenadoId')?.setValue(item.Id);
                this.myForm.get('OrdenadoNombre')?.setValue(item.Nombre);
              }
            }
            let luegoPor: any = this.FiltrosSeleccionPredefinidos.LuegoPorLista;
            if (this.FiltrosSeleccionPredefinidos.LuegoPorLista) {
              item = this.orderThen1List?.find(p => p.Nombre == luegoPor);
              if (item) {
                this.myForm.get('LuegoPorLista')?.setValue(item.Nombre);
                this.myForm.get('LuegoPor')?.setValue(item.Orden);
                this.myForm.get('LuegoPorId')?.setValue(item.Id);
                this.myForm.get('LuegoPorNombre')?.setValue(item.Nombre);
                this.myForm.get('LuegoPorAgrupador')?.setValue(item.Agrupador);
              }
            }

            this.myForm.get('menuId')?.setValue(this.FiltrosSeleccion.menuId)
            this.FiltrosSeleccionChange.emit(this.myForm.value);
            if (result.ReportHeader.Fecha1){
              this.ReportHeader.get("Fecha1")?.setValue(result.ReportHeader.Fecha1);
            }
            if (result.ReportHeader.Fecha2){
              this.ReportHeader.get("Fecha2")?.setValue(result.ReportHeader.Fecha2);
            }

            if (result.ReportHeader.Fecha2){
              this.ReportHeader.get("FechaCorte1")?.setValue(result.ReportHeader.Fecha2);
            }

            if (result.ReportHeader.Fecha2){
              this.ReportHeader.get("FechaCorte2")?.setValue(result.ReportHeader.Fecha2);
            }

            if (result.ReportHeader.Dato1){
              this.ReportHeader.get("Dato1")?.setValue(result.ReportHeader.Dato1);
            }
            if (result.ReportHeader.Opc1){
              this.ReportHeader.get("Opc1")?.setValue(result.ReportHeader.Opc1);
            }
            if (result.ReportHeader.Opc2){
              this.ReportHeader.get("Opc2")?.setValue(result.ReportHeader.Opc2);
            }
            if (result.ReportHeader.Opc3){
              this.ReportHeader.get("Opc3")?.setValue(result.ReportHeader.Opc3);
            }
            if (result.ReportHeader.Opc4){
              this.ReportHeader.get("Opc4")?.setValue(result.ReportHeader.Opc4);
            }
            if (result.ReportHeader.Opc5){
              this.ReportHeader.get("Opc5")?.setValue(result.ReportHeader.Opc5);
            }
            if (result.ReportHeader.Opc6){
              this.ReportHeader.get("Opc6")?.setValue(result.ReportHeader.Opc6);
            }
            if (result.ReportHeader.Opc7){
              this.ReportHeader.get("Opc7")?.setValue(result.ReportHeader.Opc7);
            }
            if (result.ReportHeader.Opc8){
              this.ReportHeader.get("Opc8")?.setValue(result.ReportHeader.Opc8);
            }

            if (result.ReportHeader.Num1){
              this.ReportHeader.get("Num1")?.setValue(result.ReportHeader.Num1);
            }

            if (result.ReportHeader.Num2){
              this.ReportHeader.get("Num2")?.setValue(result.ReportHeader.Num2);
            }

            if (result.ReportHeader.Num3){
              this.ReportHeader.get("Num3")?.setValue(result.ReportHeader.Num3);
            }
            if (result.ReportHeader.Num4){
              this.ReportHeader.get("Num4")?.setValue(result.ReportHeader.Num4);
            }
            if (result.ReportHeader.Num5){
              this.ReportHeader.get("Num5")?.setValue(result.ReportHeader.Num5);
            }

            if (result.ReportHeader.Num6){
              this.ReportHeader.get("Num6")?.setValue(result.ReportHeader.Num6);
            }
            if (result.ReportHeader.Num7){
              this.ReportHeader.get("Num7")?.setValue(result.ReportHeader.Num7);
            }
            if (result.ReportHeader.Num8){
              this.ReportHeader.get("Num8")?.setValue(result.ReportHeader.Num8);
            }
            if (result.ReportHeader.Num9){
              this.ReportHeader.get("Num9")?.setValue(result.ReportHeader.Num9);
            }

            if (result.ReportHeader.Mes1){
              this.ReportHeader.get("Mes1")?.setValue(result.ReportHeader.Mes1);
            }
            if (result.ReportHeader.Mes2){
              this.ReportHeader.get("Mes2")?.setValue(result.ReportHeader.Mes2);
            }
            if (result.ReportHeader.Anio1){
              this.ReportHeader.get("Anio1")?.setValue(result.ReportHeader.Anio1);
            }
            this.ReportHeaderChange.emit(this.ReportHeader.value);


            //this.ReportHeader.reset(result.ReportHeader);
          }
        });

      }
    }
  }
  // ngAfterViewInit(): void {

  // }



  esNumOrText(value: FilterOptions): boolean {
    let result: boolean = false;
    if (value.Tipo === TypeFilter.number || value.Tipo === TypeFilter.text) {
      result = true;
    }
    return result;
  }

  get FilterOptions() {
    return this.myForm.get('FilterOptions') as FormArray;
  }

  getHelpLinePorTipo(item: FilterOptions): string {
    let msg: string = "";
    switch (item.Tipo) {
      case 0:
        msg = "Indique la(s) Clave(s) numéricas separadas por comas o guiones, ejemplo: 1,3,5-8";
        if (item.BotonBusqueda) {
          msg += ", o presione F2 para abrir la búsqueda."
        } else {
          msg += "."
        }
        break;
      case 1:
        msg = "";
        break;
      case 3:
        msg = "Indique la Clave alfanumérica para filtrar el reporte.";
        break;
    }

    return msg;
  }

  ngOnInit(): void {

    let index = 0;
    let li = 0;
    let indexCount = this.tabIndex;
    if (this.FiltrosSeleccion.FilterOptions) {
      this.FiltrosSeleccion.FilterOptions.forEach(item => {
        item.tabIndex = index;
        if (item.Tipo == TypeFilter.levels) {
          index += 5;
          indexCount += 5;
        } else {
          indexCount++;
        }

        if (item.Entidad) {
          item.Filtro = item.Filtro === undefined ? "" : item.Filtro;
          let el: SearchConfiguration = this.mService.GetSearchConfiguration(item.Entidad, item.Filtro);
          this.searchConfiguration.push(el);
        }
      })
    }

    this.tabIndexOrden = indexCount + 1;
    this.myForm.reset(this.FiltrosSeleccion);

    this.contentList = this.FiltrosSeleccion.DesgloseLista;
    this.orderList = this.FiltrosSeleccion.OrdenadoLista;
    this.orderThen1List = this.FiltrosSeleccion.LuegoPorLista!;
    this.orderThen2List = this.FiltrosSeleccion.DespuesPorLista!;

    this.FiltrosSeleccion.Desglose = "";
    this.FiltrosSeleccion.DesgloseId = 0;
    this.FiltrosSeleccion.OrdenadoNombre = "";
    this.FiltrosSeleccion.OrdenadoId = 0;
    this.FiltrosSeleccion.Ordenado = "";
    this.FiltrosSeleccion.LuegoPor = "";
    this.FiltrosSeleccion.LuegoPorNombre = "";
    this.FiltrosSeleccion.LuegoPorId = 0;
    this.FiltrosSeleccion.DespuesPor = "";
    this.FiltrosSeleccion.DespuesPorId = 0;
    this.FiltrosSeleccion.LuegoPorAgrupador = "";

    if (this.FiltrosSeleccion.DesgloseLista) {
      this.FiltrosSeleccion.Desglose = this.FiltrosSeleccion.DesgloseLista[0].Nombre;
      this.FiltrosSeleccion.DesgloseId = this.FiltrosSeleccion.DesgloseLista[0].Id;
      this.myForm.get('DesgloseLista')?.setValue(this.FiltrosSeleccion.DesgloseLista[0].Nombre);
      this.myForm.get('Desglose')?.setValue(this.FiltrosSeleccion.DesgloseLista[0].Nombre);
      this.myForm.get('DesgloseId')?.setValue(this.FiltrosSeleccion.DesgloseLista[0].Id);
    } else {
      this.FiltrosSeleccion.Desglose = '';
      this.FiltrosSeleccion.DesgloseId = 0;
    }

    if (this.FiltrosSeleccion.OrdenadoLista) {
      this.FiltrosSeleccion.Ordenado = this.FiltrosSeleccion.OrdenadoLista[0].Orden;
      this.FiltrosSeleccion.OrdenadoId = this.FiltrosSeleccion.OrdenadoLista[0].Id;
      this.myForm.get('OrdenadoLista')?.setValue(this.FiltrosSeleccion.OrdenadoLista[0].Nombre);
      this.myForm.get('Ordenado')?.setValue(this.FiltrosSeleccion.OrdenadoLista[0].Orden);
      this.myForm.get('OrdenadoId')?.setValue(this.FiltrosSeleccion.OrdenadoLista[0].Id);
      this.myForm.get('OrdenadoNombre')?.setValue(this.FiltrosSeleccion.OrdenadoLista[0].Nombre);
    } else {
      this.FiltrosSeleccion.Ordenado = '';
      this.FiltrosSeleccion.OrdenadoNombre = '';
      this.FiltrosSeleccion.OrdenadoId =0;
    }

    if (this.FiltrosSeleccion.LuegoPorLista) {
      this.FiltrosSeleccion.LuegoPor = this.FiltrosSeleccion.LuegoPorLista[0].Orden;
      this.FiltrosSeleccion.LuegoPorId  = this.FiltrosSeleccion.LuegoPorLista[0].Id;
      this.myForm.get('LuegoPorLista')?.setValue(this.FiltrosSeleccion.LuegoPorLista[0].Nombre);
      this.myForm.get('LuegoPor')?.setValue(this.FiltrosSeleccion.LuegoPorLista[0].Orden);
      this.myForm.get('LuegoPorId')?.setValue(this.FiltrosSeleccion.LuegoPorLista[0].Id);
      this.myForm.get('LuegoPorNombre')?.setValue(this.FiltrosSeleccion.LuegoPorLista[0].Nombre);
      this.myForm.get('LuegoPorAgrupador')?.setValue(this.FiltrosSeleccion.LuegoPorLista[0].Agrupador);
    } else {
      this.FiltrosSeleccion.LuegoPor = '';
      this.FiltrosSeleccion.LuegoPorNombre = '';
      this.FiltrosSeleccion.LuegoPorId = 0;
      this.FiltrosSeleccion.LuegoPorAgrupador = '';
    }

    if (this.FiltrosSeleccion.DespuesPorLista) {
      this.FiltrosSeleccion.DespuesPor = this.FiltrosSeleccion.DespuesPorLista[0].Orden;
      this.FiltrosSeleccion.DespuesPorId = this.FiltrosSeleccion.DespuesPorLista[0].Id;
      this.myForm.get('DespuesPorLista')?.setValue(this.FiltrosSeleccion.DespuesPorLista[0].Nombre);
      this.myForm.get('DespuesPor')?.setValue(this.FiltrosSeleccion.DespuesPorLista[0].Orden);
      this.myForm.get('DespuesPorId')?.setValue(this.FiltrosSeleccion.DespuesPorLista[0].Id);
    } else {
      this.FiltrosSeleccion.DespuesPor = '';
      this.FiltrosSeleccion.DespuesPorId = 0;
    }

    if (this.FiltrosSeleccion.FilterOptions) {
      if (this.FiltrosSeleccion.FilterOptions.length > 0) {
        this.FiltrosSeleccion.FilterOptions.forEach(item => {


          let itemAdd = this.fb.group({
            Campo: item.Campo,
            Etiqueta: item.Etiqueta,
            Tipo: item.Tipo,
            Valor: item.Valor,
            Nombre: item.ListaItems !== undefined ? item.ListaItems[0].Nombre : "",
            //ListaItems: item.ListaItems,
            TipoNiveles: item?.TipoNiveles,
            tabIndex: item.tabIndex,
            BotonBusqueda: item.Entidad && (item.Tipo == 0 || item.Tipo == 3),
            Entidad: item.Entidad,
            MultiClave: (item?.MultiClave === undefined ? true : item?.MultiClave),
            PropiedadRetorna: item.PropiedadRetorna,
            HelpLine: (item.HelpLine === undefined) ? this.getHelpLinePorTipo(item) : item.HelpLine,
            Enabled: (item?.Enabled === undefined ? true : item?.Enabled),
            Placeholder: (item?.Placeholder ? item?.Placeholder : ""),
            IgnorarParaFiltro:item.IgnorarParaFiltro && item.IgnorarParaFiltro,
            EsClienteCabecera:item.EsClienteCabecera,
            EsCliente:item.EsCliente
          })

          if (item.ListaItems) {
            itemAdd.get('Valor')?.setValue(item.ListaItems[0].Nombre);
          }

          this.FilterOptions.push(itemAdd);
          if (item.Tipo != TypeFilter.entityNumber && TypeFilter.entityString) {
            if (item.ListaItems) {
              let _itemList: ReportFilterList = { Clave: item.Etiqueta, Nombre: item.Etiqueta, ListaItems: [] }
              item.ListaItems?.forEach((item2: any, index) => {
                let _listItem: ReportFilterListItem = {
                  Nombre: item2.Nombre,
                  Id: index.toString()
                }
                _itemList.ListaItems?.push(_listItem);
              });
              this.filterLIst.push(_itemList);
            }
          } else {
            this.containerService.getItemsByName(item.Entidad!).subscribe(result => {
              let _itemList: ReportFilterList = { Clave: item.Etiqueta, Nombre: item.Etiqueta, ListaItems: [] }

              let _listItem: ReportFilterListItem = {
                Nombre: "Sin selección",
                Id: ""
              }

              _itemList.ListaItems?.push(_listItem);

              itemAdd.get('Valor')?.setValue(_listItem.Nombre);

              result.forEach(itemCombo => {
                let _listItem: ReportFilterListItem = {
                  Nombre: itemCombo.Nombre,
                  Id: itemCombo.Id.toString()
                }
                _itemList.ListaItems?.push(_listItem);
              })
              this.filterLIst.push(_itemList);
            });
          }
        })
      }
    }
  }

  /*
    lineSubLine,
  productFamily,
  account,
  costAccount
  */

  getNivelNombre(value: any): string {
    let nombre = "";
    switch (value) {
      case 0: nombre = ''; break;
      case 1: nombre = 'FamiliaProducto'; break;
      case 2: nombre = 'CuentaContable'; break;
      case 3: nombre = 'CentroCosto'; break;
    }
    return nombre;
  }

  getlistaFiltrosComboLista(value: any): [] {
    let lista: any;
    if (value.Tipo === TypeFilter.list || value.Tipo === TypeFilter.boolean || value.Tipo === TypeFilter.entityNumber || value.Tipo === TypeFilter.entityString) {
      lista = this.filterLIst.find(p => p.Clave === value.Etiqueta)?.ListaItems;
      if (value.Etiqueta == "Vigente") {
        let a: any = 0;
      }
    }
    return lista;
  }

  onChangeOrder(value: any, item: string) {
    this.myForm.get(item)?.setValue(value.Nombre);
    this.myForm.get('Ordenado')?.setValue(value.Orden);
    this.myForm.get('OrdenadoId')?.setValue(value.Id);
    this.myForm.get('OrdenadoNombre')?.setValue(value.Nombre);
    this.FiltrosSeleccion.Ordenado = value.Orden;
    this.FiltrosSeleccion.OrdenadoNombre = value.Nombre;
    this.FiltrosSeleccion.OrdenadoId = value.Id;
    this.FiltrosSeleccion.OrdenadoLista = value.Nombre;
    this.FiltrosSeleccionChange.emit(this.FiltrosSeleccion);
  }
  onChangeThen1List(value: any, item: string) {
    this.myForm.get(item)?.setValue(value.Nombre);
    this.myForm.get('LuegoPor')?.setValue(value.Orden);
    this.myForm.get('LuegoPorId')?.setValue(value.Id);
    this.myForm.get('LuegoPorNombre')?.setValue(value.Nombre);
    this.myForm.get('LuegoPorAgrupador')?.setValue(value.Agrupador);
    this.FiltrosSeleccion.LuegoPor = value.Orden;
    this.FiltrosSeleccion.LuegoPorLista = value.Nombre;
    this.FiltrosSeleccion.LuegoPorNombre = value.Nombre;
    this.FiltrosSeleccion.LuegoPorId = value.Id;
    this.FiltrosSeleccion.LuegoPorAgrupador = value.Agrupador;
    this.FiltrosSeleccionChange.emit(this.FiltrosSeleccion);
  }

  onChangeThen2List(value: any, item: string) {
    this.myForm.get(item)?.setValue(value.Nombre);
    this.myForm.get('DespuesPor')?.setValue(value.Orden);
    this.myForm.get('DespuesPorId')?.setValue(value.Id);
    this.FiltrosSeleccion.DespuesPor = value.Orden;
    this.FiltrosSeleccion.DespuesPorLista = value.Nombre;
    this.FiltrosSeleccion.DespuesPorId = value.Id;
    this.FiltrosSeleccionChange.emit(this.FiltrosSeleccion);
  }

  onChangeDesglose(value: any, item: string) {
    this.myForm.get(item)?.setValue(value.Nombre);
    this.myForm.get('Desglose')?.setValue(value.Nombre);
    this.myForm.get('DesgloseId')?.setValue(value.Id);
    this.FiltrosSeleccion.Desglose = value.Nombre;
    this.FiltrosSeleccion.DesgloseId = value.Id;
    this.FiltrosSeleccionChange.emit(this.FiltrosSeleccion);
  }

  onChangeFiltroLista(value: any, item: any, control: any) {
    if (item.Tipo == TypeFilter.boolean) {
      if (value.Id == '0') {
        item.Valor = value.Nombre;
        item.Nombre = value.Nombre;
      } else if (value.Id == '1') {
        item.Nombre = value.Nombre;
        item.Valor = 'true';
      } else if (value.Id == '2') {
        item.Nombre = value.Nombre;
        item.Valor = 'false';
      }
    } else if (item.Tipo == TypeFilter.list) {
      item.Valor = value.Nombre;
    } else if (item.Tipo == TypeFilter.entityNumber || item.Tipo == TypeFilter.entityString) {
      item.Valor = value.Id;
    }
    this.FiltrosSeleccionChange.emit(this.myForm.value);
  }

  onChangeFiltro(value: any, control: any) {
    control.Campo = value.Campo;
    control.Etiqueta = value.Etiqueta;
    this.FiltrosSeleccionChange.emit(this.myForm.value);
  }

  onChangeColorsPercent(value: any, control: any) {
    control.Valor = value;
    this.FiltrosSeleccionChange.emit(this.myForm.value);
  }

  onLevelSelect(value: any, control: any) {
    const nivel: any = this.busquedaNiveles;
    let valor = `${nivel.myForm.value['Level1']}_${nivel.myForm.value['Level2']}_${nivel.myForm.value['Level3']}_${nivel.myForm.value['Level4']}_${nivel.myForm.value['Level5']}`
    control.Valor = valor.trim();
    this.FiltrosSeleccion = this.myForm.value;
    this.FiltrosSeleccionChange.emit(this.FiltrosSeleccion);
  }

  onBlurLevelText(value: string, control: any) {
    control.Valor = value;
    this.FiltrosSeleccion = this.myForm.value;
    this.FiltrosSeleccionChange.emit(this.FiltrosSeleccion);
  }

  onChangeValue(value: string, control: any) {
    control.Valor = value;
    this.FiltrosSeleccion = this.myForm.value;
    // this.FiltrosSeleccionChange.emit(this.myForm.value);
    this.FiltrosSeleccionChange.emit(this.FiltrosSeleccion);
  }



  clickBuscar(entidad: string, itemData: any, index: number) {
    if (itemData.value.BotonBusqueda) {
      this.busquedaFiltros = "";
      this.BusquedaEntidadNombre = "";
      this.busquedaColumnas = [];
      this.busquedaPropiedades = "";
      this.busquedaOrden = "";
      this.cajaActual = null;
      let searchConf: SearchConfiguration | undefined;

      this.cajaActual = this.cajasFiltros.toArray()[index];

      if (this.searchConfiguration) {
        searchConf = this.searchConfiguration.find(p => p.entityName == entidad);
        if (searchConf) {
          this.busquedasearchConfiguration = searchConf
          this.busquedaFiltros = searchConf.filter;
          this.BusquedaEntidadNombre = searchConf.entityName;
          this.busquedaColumnas = searchConf.columns;


          let listaProps: SearchPropertyInfo[] | undefined = [];
          listaProps = searchConf.propertys;
          if (listaProps) {
            listaProps.forEach((prop) => {
              this.busquedaPropiedades += `${prop.name}|${prop.type},`
            })

            if (this.busquedaPropiedades.length > 0) {
              this.busquedaPropiedades = this.busquedaPropiedades.substring(0, this.busquedaPropiedades.length - 1);
            }
          }


          let listaOrden: SearchPropertyInfo[] | undefined = [];
          listaOrden = searchConf.orders;
          if (listaOrden) {
            listaOrden.forEach((prop) => {
              this.busquedaOrden += `${prop.name}|${prop.type},`
            })

            if (this.busquedaOrden.length > 0) {
              this.busquedaOrden = this.busquedaOrden.substring(0, this.busquedaOrden.length - 1);
            }
          }
        }
      }

      const b: any = this.ctrlBusqueda;
      this.mService.openModal(b, (e: any) => {
        if (e) {
          setTimeout(() => {
            let filtro: string = "";
            if (itemData.value) {
              if (itemData.value.Valor) {
                filtro = itemData.value.Valor;
              }
            }
            if (filtro.length > 0) {
              filtro += ",";
            }
            if (itemData.value.MultiClave) {
              filtro += String(e.Clave);
            } else {
              if (itemData.value.PropiedadRetorna) {
                filtro = String(e[itemData.value.PropiedadRetorna]);
              } else {
                filtro = String(e.Clave);
              }
            }

            itemData.value.Valor = String(filtro);
            this.cajaActual.value = String(filtro);

            this.FiltrosSeleccionChange.emit(this.myForm.value);
          }, 150);
        }
        this.cajaActual.focus();
      }, 'lg')
    }
  }

  get getFilter(): any {
    return this.busquedaFiltros;
  }

  get getEntityName(): any {
    return this.BusquedaEntidadNombre;
  }


  get getColumns(): any {
    return this.busquedaColumnas;
  }

  get getPropertys(): string {
    return this.busquedaPropiedades;
  }

  get getOrders() {
    return this.busquedaOrden;
  }


  get listaFiltrosCombo(): FilterOptions[] {
    return this.FiltrosSeleccion.FilterOptions!;
  }

  get tieneDesglose(): boolean {
    if (this.contentList) {
      return (this.contentList.length > 0);
    } else {
      return false;
    }
  }

  get tieneOrdenado(): boolean {
    if (this.orderList) {
      return (this.orderList.length > 0);
    } else {
      return false;
    }
  }

  get tieneLuegoPor(): boolean {
    if (this.orderThen1List) {
      return (this.orderThen1List.length > 0);
    } else { return false }
  }

  get tieneDespuesPor(): boolean {
    if (this.orderThen2List) {
      return (this.orderThen2List.length > 0);
    } else {
      return false;
    }
  }

  gettextFilter(value: FilterOptions): string {
    let result = "";
    switch (value.Tipo) {
      case TypeFilter.number:
        result = "0123456789,-";
        break;
      case TypeFilter.text:
        result = "";
        break;
      default:
        result = "";
        break;
    }
    return result;
  }


  // let valor = value.value;
  // for (let i = 0; i < this.filtrosControls.controls.length; i++) {
  //   if (valor === this.filtrosControls.controls[i].value) {
  //     for (let j = i+1; j < this.filtrosControls.controls.length; j++) {
  //       this.filtrosControls.controls[j].setValue(this.filtrosControls.controls[j + 1].value);
  //     }
  //   }
  // }

  // this.FormGroupFiltros = this.myForm;




}

