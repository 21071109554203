import { AfterViewInit, ChangeDetectorRef, Component, DoCheck, ElementRef, Input, ViewChild, forwardRef, EventEmitter, Output, OnInit, inject } from '@angular/core';
import { ComponentsUiService } from '../../services/components-ui.service';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ConfiguracionService } from 'src/app/configuracion/service/configuracion.service';
import { SearchConfiguration, SearchPropertyInfo } from 'src/app/service/interfaces/data-search.interface';
import { ModalService } from 'src/app/service/modal.service';
import { ContainerBaseService } from '../../services/container-base.service';
import { Coordinates } from '../../interfaces/combo-text.interface';
import { AppSettings } from 'src/app/home/services/app-settings.service';
import { BaseService } from 'src/app/service/base.service';

@Component({
  selector: 'components-ui-text-box',
  templateUrl: './text-box.component.html',
  styleUrls: ['./text-box.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TextBoxComponent),
    }
  ]
})
export class TextBoxComponent implements AfterViewInit, ControlValueAccessor, DoCheck, OnInit {
  sourceListBusqueda: any[] = [];
  cords: any = null;
  idUnico: string = '';

  @Input()
  public isEstructura: boolean = false;
  @Input()
  public dataType: EnumDataType = EnumDataType.Characters;
  @Input()
  public placeHolder: string = "";
  @Input()
  public widthLabelColumns: number = 2;
  @Input()
  public widthTextColumns: number = 10;
  @Input()
  public label: string = "Etiqueta";
  @Input()
  public separator: string = ":";
  @Input()
  public maxLength: number = 100;
  @Input()
  public tabIndex: number = 1;
  // @Input()
  // public formControlName: string = "";
  @Input()
  public required: boolean = false;
  @Input()
  public enabled: boolean = true;
  @Input()
  public upperCase: boolean = false;
  @Input()
  public enableIcon: boolean = false;
  @Input()
  public classIcon: string = "fa fa-search";
  @Input()
  public enableLink: boolean = false;
  @Input()
  public isLevel: boolean = false;
  @Input()
  public textFilter: string = "";
  @Input()
  public Entidad: string = "";

  @Input()
  public esParaFiltrar: boolean = false;

  @Input()
  public helpLine: string = "";

  @Input()
  public topLabel: boolean = false;

  @Output()
  public blur = new EventEmitter();

  @Input()
  public alinearDerecha: boolean = false

  @Input()
  public formatoNumerico: boolean = false

  @Input()
  public formatoMoneda: boolean = false

  @Input()
  public entidadBusqueda: string = "";

  @Output()
  focus = new EventEmitter();

  @Output()
  onChangeValue = new EventEmitter<string>();

  @Output()
  public onClicIcon = new EventEmitter();
  @Output()
  public onClicLink = new EventEmitter();

  @Output()
  public elementoSeleccionado = new EventEmitter<any>();

  @Input()
  value: string = "";

  isTypeValid = true;
  values: any = {
    before: 0,
    after: 0
  };

  // @Input()
  // public formGroup!: FormGroup;

  private mService = inject(ModalService);
  private containerBaaseService = inject(ContainerBaseService);
  public appSettings = inject(AppSettings);
  private containerBaseService = inject(ContainerBaseService);
  searchConfiguration: SearchConfiguration = {} as SearchConfiguration;

  busquedaFiltros: string = "";
  BusquedaEntidadNombre: string = "";
  busquedaColumnas: string[] = [];
  busquedaPropiedades: string = "";
  busquedaOrden: string = "";

  @ViewChild('content')
  public ctrlBusqueda!: ElementRef<HTMLElement>;


  @ViewChild('txtTag')
  public tagInput!: ElementRef<HTMLInputElement>;

  get getFilter(): any {
    return this.busquedaFiltros;
  }

  get getEntityName(): any {
    return this.BusquedaEntidadNombre;
  }


  get getColumns(): any {
    return this.busquedaColumnas;
  }

  get getPropertys(): string {
    return this.busquedaPropiedades;
  }

  get getOrders() {
    return this.busquedaOrden;
  }


  get IsUpperCase(): string {
    return this.upperCase ? "text-uppercase" : "";
  }

  getEtiquetaLenght() {
    return this.label.length > 0
  }

  get getStyle(): string {
    let style: string = `border-radius: 0px;${(this.isLevel ? ("border:0px;border-bottom: 1px solid " + (this.appSettings.appDarkMode ? '#e6831d;' : '#000000;') + " border-right: 1px solid " + (this.appSettings.appDarkMode ? '#e6831d;' : '#000000;')) : "border:0px;border-bottom: 1px solid " + (this.appSettings.appDarkMode ? '#e6831d;' : '#000000;'))} ${(this.alinearDerecha ? " text-align: right;" : "")}`;

    return style;
  }
  get IsDataTypeValid(): string {
    if (this.dataType == EnumDataType.RFC) {
      return this.isTypeValid ? "text-box-error-validation" : "text-box-success-validation";
    }

    if (this.dataType == EnumDataType.CURP) {
      return this.isTypeValid ? "text-box-error-validation" : "text-box-success-validation";
    }
    if (this.dataType == EnumDataType.EMAIL) {
      return this.isTypeValid ? "text-box-error-validation" : "text-box-success-validation";
    }
    return "";
  }

  get IsRequired(): string {
    if (this.tagInput) {
      if (this.tagInput.nativeElement.value) return "";
    }
    return this.required ? "tb-obligate" : "";
  }

  // clicIcon() {
  //   this.onClickIcon.emit(this.tagInput.nativeElement.value);
  // }

  onClick() {
    this.onClicIcon.emit();
    if (this.Entidad.length > 0) {
      this.abrirAyuda();
    }
  }

  abrirAyuda() {
    if (this.searchConfiguration) {
      this.busquedaPropiedades = "";
      this.busquedaOrden = "";

      let listaProps: SearchPropertyInfo[] | undefined = [];
      listaProps = this.searchConfiguration.propertys;
      if (listaProps) {
        listaProps.forEach((prop) => {
          this.busquedaPropiedades += `${prop.name}|${prop.type},`
        })

        if (this.busquedaPropiedades.length > 0) {
          this.busquedaPropiedades = this.busquedaPropiedades.substring(0, this.busquedaPropiedades.length - 1);
        }
      }


      let listaOrden: SearchPropertyInfo[] | undefined = [];
      listaOrden = this.searchConfiguration.orders;
      if (listaOrden) {
        listaOrden.forEach((prop) => {
          this.busquedaOrden += `${prop.name}|${prop.type},`
        })

        if (this.busquedaOrden.length > 0) {
          this.busquedaOrden = this.busquedaOrden.substring(0, this.busquedaOrden.length - 1);
        }
      }


      const b: any = this.ctrlBusqueda;
      this.mService.openModal(b, (e: any) => {
        if (e) {

          let filtro: string = "";
          if (this.tagInput.nativeElement) {
            if (this.tagInput.nativeElement.value) {
              filtro = this.tagInput.nativeElement.value;
            }
          }
          if (filtro.length > 0) {
            filtro += ",";
          }
          filtro += String(e.Clave);

          //itemData.value.Valor = String(filtro);




          this.tagInput.nativeElement.value = filtro;
          setTimeout(() => {
            this.tagInput.nativeElement.focus();
          }, 150);
        }
      }, 'lg')

    }
  }

  onClickLink() {
    if (this.enableLink) {
      this.onClicLink.emit();
    }
  }

  onChange = (value: string) => {
    this.onChangeValue.emit(value)
  };

  onChangeTextValue(value: any) {
    this.onChangeValue.emit(value.target.value);
  }

  onTouched = () => { };

  // focus = () => {
  //   this.tagInput.nativeElement.focus();
  // }

  private isDisabled = false;
  inputField = new FormControl();
  constructor(private compsService: ComponentsUiService, private changeDetectorRef: ChangeDetectorRef, private configService: ConfiguracionService) {

  }

  ngOnInit(): void {
    this.idUnico = String(new Date().getTime() * 10000);
    if (this.Entidad) {
      this.searchConfiguration = this.mService.GetSearchConfiguration(this.Entidad, "");
      this.busquedaFiltros = this.searchConfiguration.filter;
      this.BusquedaEntidadNombre = this.Entidad
      this.busquedaColumnas = this.searchConfiguration.columns;
    }


  }
  ngDoCheck(): void {
  }

  ngAfterViewInit(): void {
    this.inputField.valueChanges.subscribe(value => {
      this.onChange(value!);
    });
    this.setDisabledState(this.isDisabled);
  }

  blurControl(value: string) {
    this.tagInput.nativeElement.classList.remove("pulse-text");
    this.writeValue(value);
    this.values.after = value;
    this.blur.emit(this.values);
    this.blurInput();
  }

  writeValue(val: any): void {
    if (this.formatoNumerico || this.formatoMoneda) {
      let valor: number = 0;
      valor = Number(val);
      if (!isNaN(valor)) {
        if (valor > 0) {
          if (this.formatoMoneda) {
            val = "$" + valor.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
          }
          if (this.formatoNumerico) {
            val = valor.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');

          }
        }
      }
    }

    this.inputField.setValue(val);
    this.value = val;
    this.validateDataType(val);
  }

  onKeyUp(e: any) {
    this.validateDataType(this.tagInput.nativeElement.value);

    if (this.entidadBusqueda) {
      this.buscarClientes(e);
    }
  }

  onKeyPress(e: any) {
    if (this.textFilter) {
      var selstart: any;
      var key: any;
      var posicion: any;
      key = String.fromCharCode(e.keyCode);

      if (this.textFilter.toUpperCase().indexOf(key) === -1) {
        e.preventDefault();
      }
    }
  }


  onFocus(e: any) {
    let pes = document.querySelectorAll('.lineadeayuda');
    if (this.helpLine) {
      pes.forEach((elem: any) => { elem.innerHTML = this.helpLine; });
    } else {
      pes.forEach((elem: any) => { elem.innerHTML = "" });
    }

    this.values.before = this.tagInput.nativeElement.value;
    this.tagInput.nativeElement.classList.add("pulse-text");

    if (this.entidadBusqueda) {
      if (!e.target.value) return;
      e.stopPropagation();
      setTimeout(() => {
        const inputEvent: Event = new Event('input');
        e.target.dispatchEvent(inputEvent);
      }, 0);
    }
    if (this.isLevel || this.entidadBusqueda) {
      this.tagInput.nativeElement.selectionStart = 0;
      this.tagInput.nativeElement.selectionEnd = 1000;
    }
    this.focus.emit();
  }

  onKeyDown(e: any) {
    if (this.isEstructura) {
      this.manejoEstructura(e);
    }
  }

  //#region Estructuras
  manejoEstructura(e: any) {
    let valor = this.tagInput.nativeElement.value;
    const regex = new RegExp(/\d+/g);
    let startPos = e.target.selectionStart;
    let endPos = e.target.selectionEnd;
    if (regex.test(e.key)) {
      this.formarEstructura(e);
      return;
    }
    else if (e.key === "Backspace") {
      e.preventDefault();
      let valueDelete = valor.substring(endPos - 1, endPos);
      let valueStart = valor.substring(0, startPos);
      let valueEnd = valor.substring(endPos);
      let newValueStart = valueStart.substring(0, valueStart.length - (valueDelete === "-" ? 2 : 1));
      valor = newValueStart + valueEnd;
      valor = valor.split("-").filter(x => x !== "").join("-");
      this.tagInput.nativeElement.setSelectionRange(startPos - (valueDelete === "-" ? 3 : 2), startPos - (valueDelete === "-" ? 3 : 2));
    } else if (e.key === "Home") {
      this.tagInput.nativeElement.setSelectionRange(0, 0);
    } else if (e.key === "End") {
      this.tagInput.nativeElement.setSelectionRange(valor.length, valor.length);
    } else if (e.key !== "ArrowLeft" && e.key !== "ArrowRight") {
      e.preventDefault();
    }
    this.tagInput.nativeElement.value = valor;
  }

  formarEstructura(e: any): void {
    let valor = this.tagInput.nativeElement.value;
    let startPos = e.target.selectionStart;
    let endPos = e.target.selectionEnd;
    let sumarPos = 0;


    let valueEnd = valor.substring(endPos + 1);
    let valueStart = valor.substring(0, startPos);
    if (startPos === 1 || startPos === 3 || startPos === 5 || startPos === 7) {
      valor = valueStart + "-" + e.key + valueEnd;
      sumarPos = 2;
    } else if (startPos <= 8) {
      sumarPos = 2;
      valor = valueStart + e.key + valueEnd;
    }

    this.tagInput.nativeElement.value = valor;
    e.preventDefault();
    this.tagInput.nativeElement.setSelectionRange(startPos + sumarPos, startPos + sumarPos);
  }
  //#endregion

  validateDataType(value: string): void {
    this.isTypeValid = true;
    if (this.dataType == EnumDataType.RFC) {
      const regex = new RegExp(this.configService.RegExRfc);
      this.isTypeValid = regex.test(value);

      if (value == "" && !this.required) {
        this.isTypeValid = true;
      }
    }

    if (this.dataType == EnumDataType.CURP) {
      const regex = new RegExp(this.configService.RegExCurp);
      this.isTypeValid = regex.test(value);
      console.log(regex.test(value))
      if (value == "" && !this.required) {
        this.isTypeValid = true;
      }
    }

    if (this.dataType == EnumDataType.EMAIL) {
      const regex = new RegExp(this.configService.RegExEmail);
      this.isTypeValid = regex.test(value);
      console.log(regex.test(value))
      if (value == "" && !this.required) {
        this.isTypeValid = true;
      }
    }
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }
  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }
  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    this.changeDetectorRef.markForCheck();
  }



  selectedClienteIndex: number = -1;
  buscarClientes(e: any) {
    if (e.target.value == "" || !isNaN(e.target.value)) {
      this.cords = null;
      this.sourceListBusqueda = [];
      return;
    }

    if (this.selectedClienteIndex == -1) {
      this.selectedClienteIndex = 0;
    }

    if (e.keyCode == "27" || e.keyCode == "37" || e.keyCode == "39" || e.keyCode == "38" || e.keyCode == "40") {
      return;
    }
    let cords = this.getOffset();
    cords.top = cords.top - 850;

    if (this.appSettings.appSidebarMinified) {
      cords.left = cords.left + 300;
    } else {
      cords.left = cords.left + 350;
    }
    this.cords = cords;


    let row = null;
    this.containerBaaseService.busquedaTextBox(e.target.value, this.entidadBusqueda).subscribe((result) => {
      const lista = result
      this.sourceListBusqueda = lista;
      if (this.sourceListBusqueda.length > 0) {
        this.selectedClienteIndex = 0;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedClienteIndex}`);
        if (row) {
          row.scrollIntoView({ block: "center" });
        }
      } else {
        this.selectedClienteIndex = -1;
      }
    });
  }

  blurInput() {
    setTimeout(() => {
      this.cords = null;
    }, 100);
  }

  getOffset(): Coordinates {
    let elem = document.querySelector("body");
    var box = elem!.getBoundingClientRect();
    var left = window.scrollX !== undefined ? window.scrollX :
      (document.documentElement || document.body.parentNode || document.body).scrollLeft;
    var top = window.scrollY !== undefined ? window.scrollY :
      (document.documentElement || document.body.parentNode || document.body).scrollTop;

    top += elem!.offsetHeight;
    return { left: box.left + left, top: box.top + top };
  }



  upInput(e: any) {
    if (this.entidadBusqueda) {
      e.stopPropagation();
      if (this.sourceListBusqueda.length > 0) {
        let row = null;
        if (e.keyCode == "38") {
          if (this.selectedClienteIndex > 0) {
            this.selectedClienteIndex = this.selectedClienteIndex - 1;
            row = document.getElementById(`row-search${this.idUnico}_${this.selectedClienteIndex}`)
          }
        }
        if (row) {
          row.scrollIntoView({ block: "center" });
        }
        return;
      }
    }
  }

  downInput(e: any) {
    if (this.entidadBusqueda) {
      e.stopPropagation();
      if (this.sourceListBusqueda.length > 0) {
        let row = null;
        if (e.keyCode == "40") {
          if (this.selectedClienteIndex < this.sourceListBusqueda.length - 1) {
            this.selectedClienteIndex = this.selectedClienteIndex + 1;
            row = document.getElementById(`row-search${this.idUnico}_${this.selectedClienteIndex}`)
          }
        }
        if (row) {
          row.scrollIntoView({ block: "center" });
        }
        return;
      }
    }
  }

  enterInput(e: any) {
    if (this.entidadBusqueda) {
      let value = '';
      if (this.sourceListBusqueda.length > 0) {
        value = this.sourceListBusqueda[this.selectedClienteIndex].Clave;
        value = value ? value : this.sourceListBusqueda[this.selectedClienteIndex].clave;
      } else {
        value = e.target.value;
      }
      e.target.value = value;
      this.values.after = value;
      if (value) {
        e.target.blur();
      }
    }
  }

  clickTablaBusqueda(item: any) {
    this.tagInput.nativeElement.value = item.clave;
    this.values.after = item.clave;
    this.elementoSeleccionado.emit({ Clave: item.clave });
  }
}

export enum EnumDataType {
  Characters,
  RFC,
  CURP,
  EMAIL
}

export enum BusquedasTextBox {
  Cliente = 'Cliente',
  Proveedor = 'Proveedor',
  Almacen = 'Almacen',
  Producto = 'Producto',
}
