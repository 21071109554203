<ng-container>
  <div class="card m-1 panelbasecomplit" [style]="style">
    <div [ngStyle]="{'height':showMenu?'23px':'23px','border-radius':'0px'}"
      [class]="'p-2 m-0 d-flex align-items-center bottompanel bg-light'">
      <i *ngIf="icon" class="panel-icon fa fa-{{icon}}"></i>
      <h5 class="widget-list-title mt-2">{{camelCase ? (title | titlecase): title }}</h5>
      <span (click)="clicIcon()" [ngbTooltip]="toolTipIcon" *ngIf="activeIconEdit"
        style="position: absolute;right: 5px;cursor:pointer;">
        <i class="fa fa-pen-to-square t-plus-1 fa-fw fa-lg"></i>
      </span>
      <span style="position: absolute;right: 14px;cursor:pointer;" *ngIf="showMenu">
        <div>
          <a data-bs-toggle="dropdown"><i class="fa fa-fw me-1 fa-ellipsis-h"
              style="color: #fd8204; font-size: 1.2rem;"></i></a>
          <div class="dropdown-menu dropdown-menu-end">
            <div *ngFor="let item of itemsMenuDropDown;">
              <a class="dropdown-item" (click)="clicMenu(item.Id!)"> <i *ngIf="item.Icono" [ngClass]="item.Icono"></i>
                {{item.Nombre}}
              </a>
            </div>
          </div>
        </div>
      </span>
    </div>
    <div [class]="'card-body ' + (appSettings.appDarkMode ? ' bg-light':' bg-white')"
      [style]="hasPadding ? '':'padding:0px !important;'">
      <ng-content select=".panel-content">
      </ng-content>
    </div>
  </div>
</ng-container>
