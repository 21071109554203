import { AfterViewInit, Component, ElementRef, inject, OnInit, ViewChild } from '@angular/core';
import { EmpresaSucursalDto, Result, UserLogged } from 'src/app/auth/interfaces';
import { ComboBoxEntity, Coordinates } from 'src/app/component-ui/interfaces/combo-text.interface';
import { EventsService } from 'src/app/service/events.service';
import { InventarioService } from '../../services/inventario.service';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UtilsService } from 'src/app/service/utils.service';
import { ModalService } from 'src/app/service/modal.service';
import { ReportsService } from 'src/app/service/reports.service';
import { AppSettings } from 'src/app/home/services/app-settings.service';
import { DocumentoInventario, DocumentoInventarioDetalle } from '../../interfaces/documentosinventario.interface';
import { Producto, ProductoUnidad } from '../../interfaces/producto.interface';
import { FilterOptions, ReportFilter, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { startOfDay } from 'date-fns';
import { FacturacionService } from 'src/app/ventas/services/facturacion.service';
import * as moment from 'moment';
import { ComboBoxComponent } from 'src/app/component-ui/components/combo-box/combo-box.component';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';
import { ExistenciaProductoPaquete } from 'src/app/ventas/interfaces/venta.interface';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { Usuario } from 'src/app/configuracion/interfaces/usuario.interface';
import { ActiveButtons, EventReturn } from 'src/app/component-ui/interfaces/container-base.interface';
import { en } from '@fullcalendar/core/internal-common';

@Component({
  selector: 'app-emision-documento-inventario-page',
  templateUrl: './emision-documento-inventario-page.component.html',
  styles: [
  ]
})
export class EmisionDocumentoInventarioPageComponent implements OnInit {


  //control de flujo de la pantalla
  saving: boolean = false;

  //referencia a elementos de la pantalla

  @ViewChild('cboSerie')
  public cboSerie!: ElementRef<ComboBoxComponent>;
  @ViewChild('cboDocumento')
  public cboDocumento!: ElementRef<ComboBoxComponent>;
  @ViewChild('txtFolioTrasSuc')
  public txtFolioTrasSuc!: ElementRef<TextBoxComponent>;
  @ViewChild('txtDescription')
  public txtDescription!: ElementRef<HTMLElement>;
  @ViewChild('busquedaProductosTraspasos')
  public ctrlBusquedaProductos!: ElementRef<HTMLElement>;


  @ViewChild('fechaEmisionTrasSuc')
  public fechaEmisionTrasSuc!: ElementRef<HTMLElement>;

  @ViewChild('busquedaProductosEntradaTraspasos')
  public ctrlBusquedaProductosEntrada!: ElementRef<HTMLElement>;

  @ViewChild('ctrlConversiones')
  public ctrlConversiones!: ElementRef<HTMLElement>;
  @ViewChild('modalCancelOptiones')
  public modalCancelOptiones!: ElementRef<HTMLElement>;

  @ViewChild('ctrlExistenciasTrasSuc')
  public ctrlExistenciasTrasSuc!: ElementRef<HTMLElement>;


  //*  variables globales del usuario
  info: UserLogged = {} as UserLogged;
  empresaActual: EmpresaSucursalDto | undefined = {} as EmpresaSucursalDto;
  sucursalActual: EmpresaSucursalDto | undefined = {} as EmpresaSucursalDto;
  usuarioActual: UserLogged = {} as UserLogged;
  serieActual: ComboBoxEntity = {} as ComboBoxEntity;
  listaSeries: ComboBoxEntity[] = [];
  noVerCostos: boolean = true;
  rowEditandoDescricionAdic: number = 0;
  usarSegundoNombreProducto: boolean = false;
  esEscapeSucDestino: boolean = false;
  mascara: string = "";



  //* injects
  private eventsService = inject(EventsService);
  private invService = inject(InventarioService);
  private cService = inject(ContainerBaseService);
  private fb = inject(FormBuilder);
  private utilsService = inject(UtilsService);
  private ms = inject(ModalService);
  private reportsService = inject(ReportsService)
  public appSettings = inject(AppSettings);
  private http = inject(HttpClient);
  private readonly baseUrl: string = environment.baseUrlApi;

  listaComprobantes: ComboBoxEntity[] = [];
  source: Array<DocumentoInventarioDetalle> = [];
  showDescription: boolean = false;
  loading: boolean = false;
  fechaEmision: Date = new Date()
  sourceProducts: any[] = [];
  sourceProductsEntrada: any[] = [];
  indexEditing: number = -1;
  esEscapeProducto: boolean = false;
  esEscapeProductoEntrada: boolean = false;
  esEscapeCantidad: boolean = false;
  esEscapeLotePaquete: boolean = false;
  esEnterProducto: boolean = false
  esEnterProductoEntrada: boolean = false
  enviarFocoProducto: boolean = false
  enviarFocoProductoEntrada: boolean = false
  enviarFocoCantidad: boolean = false
  enviarFocoLotePaquete: boolean = false
  enviarOtroRenglon: boolean = false;
  cords: any = null;
  idUnico: string = '';
  selectedIndex: number = -1;
  cargandoInicio: boolean = false;


  colCantidad: number = 4;
  colProducto: number = 1;
  colProductoEntrada: number = 2;
  colDecripcion: number = 3;
  colLotePaquete: number = 8;



  existencia: number = 0;
  conversiones: ProductoUnidad[] = [];
  unidadFinal: string = '';
  abrirBusqueda: boolean = false;

  public myFormTotales: FormGroup = this.fb.group({
    TotalCosto: [''],
    TotalRegistros: [''],
    TotalCantidad: [''],
  });

  public filtroMovtos: ReportFilter =
    {
      ReportHeader: {} as ReportHeader,
      TituloVisor: 'Documento de inventario',
      NombreExcel: '',
      FilterOptions: [
        { Campo: 'movto.Id', Etiqueta: '', Tipo: TypeFilter.number },
      ]
    };



  public myForm: FormGroup = this.fb.group({
    Id: [0],
    Clave: [0],
    Nombre: [''],
    Empresa: [null],
    Sucursal: [null],
    SerieId: [0],
    TipoDocumento: [null],
    Serie: [null],
    SerieString: [''],
    Folio: [0],
    FechaEmision: [Date],
    FechaEmisionString: [''],
    FechaElabora: [Date],
    FechaCancelacion: [null],
    MotivoCancelacion: [''],
    FechaCancelacionString: [''],
    Comentarios: [''],
    DescripcionAdicional: [''],
    TotalKilos: [0],
    Total: [0],
    UsuarioElabora: [null],
    UsuarioCancela: [null],
    Conceptos: [[]],
    Baja: [false],
  })

  constructor(private cb: ContainerBaseService, private fServices: FacturacionService, private iService: InventarioService) {
  }

  get getCancelDate(): string {
    if (this.myForm.value.FechaCancelacion) {
      return moment(this.myForm.value.FechaCancelacion).format("DD/MM/YYYY");
    }
    return "";
  }


  openCancel() {
    const b: any = this.modalCancelOptiones;
    this.ms.openModal(b, (e: any) => {
      if (e) {
        this.proceedCancel(e)
      }
    });
  }


  proceedCancel(e: any) {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    const date2 = new Date();
    const d = { year: e.Fecha.getFullYear(), month: e.Fecha.getMonth(), day: e.Fecha.getDate(), hour: date2.getHours(), min: date2.getMinutes(), sec: date2.getSeconds() };
    e.Fecha = new Date(d.year, d.month, d.day, d.hour, d.min, d.sec);
    const date = moment(e.Fecha).format("DD/MM/YYYY HH:mm:ss");
    this.iService.cancelarDocumentoInventario(this.myForm.value.Id, date, e.MotivoCancelacion).subscribe((result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result.success) {
        this.findEntityByParams()
      } else {
        this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
      }
    })
  }

  get getItemsSerie(): ComboBoxEntity[] {
    return this.listaSeries;
  }

  get getItemsComps(): ComboBoxEntity[] {
    return this.listaComprobantes;
  }

  ngOnInit(): void {
    this.appSettings.appSidebarMinified = true;
    this.info = this.utilsService.getUserLogged();
    this.idUnico = String(new Date().getTime() * 10000);
    this.empresaActual = this.info.empresa;
    this.sucursalActual = this.info.sucursal;
    this.usuarioActual = this.utilsService.getUserLogged();
    this.fServices.obtenerMascaraProducto(this.info.empresa!.numero).subscribe((result) => { this.mascara = result.message });
    this.sourceReset();
    this.cb.obtenerValorPropiedad("Usuario", "Rol.NoVerCostos", this.info.numero).subscribe((value) => {
      this.noVerCostos = value.toLowerCase() == "true";
    });
    this.getComps();
  }

  changueTitle(title: string) {
    this.eventsService.publish('home:changeTabTitle', { tabTitle: `Emitiendo => [${title}]`, url: "emisiondocumentoinventario" });
  }

  getComps() {
    const params = new HttpParams().set("idEmpresa", this.info.empresa!.numero).set("idSucursal", this.info.sucursal!.numero);
    return this.http.get<ComboBoxEntity[]>(`${this.baseUrl}/Inventarios/ObtenerListaComprobantes`, { params }).subscribe((lista) => {
      if (lista.length > 0) {
        this.listaComprobantes = lista;
        this.listaComprobantes.map(P => { P.ClaveNombre = `${String(P.Clave).padStart(3, '0')} ${P.Nombre}` });
        if (lista.length == 1) {
          this.getSeries(lista[0].Id);
          this.changueTitle(lista[0].Nombre);
        } else {
          this.eventsService.publish('home:isLoading', { isLoading: false });
          const t: any = this.cboDocumento;
          setTimeout(() => {
            let ent = this.myForm.value;
            ent.Empresa = { Id: this.info.empresa!.numero };
            ent.Sucursal = { Id: this.info.sucursal!.numero, Clave: this.info.sucursal!.clave, Nombre: this.info.sucursal!.nombre };
            this.myForm.reset(ent);
            t.tagInput.nativeElement.focus();
          }, 100);
        }
      } else {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        this.eventsService.publish('home:showAlert', { message: "No se encontró ningúna Serie de Inventarios configurada para la Emp/Suc actuales.", cancelButton: false });
      }
    })
  }

  getSeries(idDocumento: number) {
    const txt: any = this.cboSerie;
    txt.tagInput.nativeElement.value = "";
    this.fServices.getSeriesInventarios(this.info.empresa!.numero, this.info.sucursal!.numero, idDocumento).subscribe((lista) => {
      this.cargandoInicio = false;
      if (lista.length > 0) {
        this.listaSeries = lista;
        if (lista.length == 1) {
          this.cargandoInicio = true;
          this.getEmptyEntity(this.listaSeries[0].Id, idDocumento);
        } else {
          this.eventsService.publish('home:isLoading', { isLoading: false });
          setTimeout(() => {
            let ent = this.myForm.value;
            ent.Empresa = { Id: this.info.empresa!.numero };
            ent.Sucursal = { Id: this.info.sucursal!.numero, Clave: this.info.sucursal!.clave, Nombre: this.info.sucursal!.nombre };
            ent.TipoDocumento = { Id: idDocumento };
            txt.tagInput.nativeElement.value = '';
            this.sourceReset();
            txt.tagInput.nativeElement.focus()
            this.myForm.reset(ent);
          }, 150);
        }
      }
    })
  }

  getEmptyEntity(idSerie: number = 0, idDocto: number = 0) {
    this.cService.getEmptyEntity("DocumentoInventario").subscribe((ent) => {
      this.initializeEntity(ent, idSerie, idDocto)
    })
  }

  initializeEntity(ent: any, idSerie: number = 0, idDocto: number = 0) {
    ent.Empresa = { Id: this.info.empresa!.numero };
    ent.Sucursal = { Id: this.info.sucursal!.numero, Clave: this.info.sucursal!.clave, Nombre: this.info.sucursal!.nombre };
    ent.TipoDocumento = { Id: idDocto };
    if (idSerie > 0) {
      const serie = this.listaSeries.filter((P) => P.Id == idSerie)[0];
      ent.Serie = { Id: idSerie };
      ent.SerieString = serie.Serie;
      setTimeout(() => {
        this.selectedCombo(serie, "Serie");
      }, 300);
    }
    ent.TipoDocumento = { Id: idDocto };
    this.sourceReset();
    this.getNextFolio(ent);
  }


  // 1.- metodos de inicializacion de la pantalla y grid
  get getExtras() {
    return " Serie + String(' ', 1) + Nombre as SerieNombre, Serie";
  }

  // inicializacion del grid

  changueDescription(i: number, item: DocumentoInventarioDetalle) {
    if (this.myForm.value.Id > 0) {
      return;
    }
    if (item.Producto?.ActualizarDescrpcion) {
      this.rowEditandoDescricionAdic = i;
      this.indexEditing = i;
      this.showDescription = true;
      this.myForm.get("DescripcionAdicional")?.setValue(item.Descripcion);
      const t: any = this.txtDescription;
      setTimeout(() => {
        t.nativeElement.focus();
      }, 100);
    }
  }

  get permiteEliminarDelGrid() {
    return !(this.myForm.value.Id > 0);
  }

  get getNoVerCostos(): boolean {
    return !this.noVerCostos;
  }

  selectedCombo(entity: ComboBoxEntity, tipo: string) {
    this.myForm.controls[tipo].setValue(entity);
    switch (tipo) {
      case "Serie":
        let entrar = true;
        if (this.myForm.value.Serie) {
          if (this.myForm.value.Serie.Id == entity.Id) {
            entrar = false;
          }
        }
        if (entrar && !this.cargandoInicio) {
          this.getEmptyEntity(entity.Id, this.myForm.value.TipoDocumento.Id);
          console.log("entro...")
        }
        this.cargandoInicio = false;
        break;
      case "TipoDocumento":
        this.myForm.controls[tipo].setValue(entity);
        if (!this.cargandoInicio) {
          this.cargandoInicio = true;
          this.getSeries(entity.Id);
          this.changueTitle(entity.Nombre);
        }
        break;
    }
  }

  //*3.- metodos de control de flujo del grid col1
  // * columna 1 Producto

  escapeProducto(e: any) {
    let index = this.indexEditing;
    this.esEscapeProducto = true;
    let item: DocumentoInventarioDetalle = this.source[index];
    e.target.value = item.Producto?.Clave ? item.Producto?.Clave : "";
    setTimeout(() => {
      this.esEscapeProducto = true;
      const item = { ...this.source[index] };
      if (!item.Producto) {
        e.target.value = "";
      } else {
        e.target.value = item.Producto.Clave;
      }
      if (index > 0) {
        this.indexEditing = index - 1;
        this.enviarFocoProducto = true;
        this.sendFocus();
      } else {
        const txt: any = this.fechaEmisionTrasSuc;
        txt.tagInput.nativeElement.focus()
      }
    }, 50);
  }
  focusProducto(index: number, e: any, item: DocumentoInventarioDetalle) {
    this.setIndexEdit(index, e, item);
    let c = this.getLastItem();
    if (c == 0 && index > 0) {
      e.target.blur();
      return;
    }
    if ((index >= c + 1) && c > 0) {
      e.target.blur();
    }
  }

  enterProducto(e: any) {
    this.esEnterProducto = true;
    this.enviarFocoCantidad = true;
    let value = '';
    if (this.sourceProducts.length > 0) {
      value = this.sourceProducts[this.selectedIndex].Clave;
    } else {
      value = e.target.value;
    }
    e.target.value = value;
    if (value) {
      e.target.blur();
    }
  }

  blurProducto(e: any) {
    let index = this.indexEditing;
    e.target.classList.remove("focus-editor-grid");

    setTimeout(() => {
      this.cords = null;
      if (this.esEscapeProducto) {
        this.esEnterProducto = false;
        this.esEscapeProducto = false;
        return;
      }
      let item = { ...this.source[index] };
      if (item.Producto) {
        if (!e.target.value) {
          e.target.value = item.Producto.Clave;
          this.enviarFocoProducto = true;
          this.sendFocus();
          return;
        }

        if (item.Producto.Clave != e.target.value) {
          this.searchProduct(e.target.value);
        } else {
          if (this.esEnterProducto) {

            if (this.enviarFocoCantidad) {
              this.sendFocus();
            }

          }
        }
      } else {
        if (e.target.value != "") {
          this.searchProduct(e.target.value);
        } else {
          e.target.classList.remove("focus-editor-grid");
        }
      }
      this.esEnterProducto = false;
      this.esEscapeProducto = false;
    }, 100);
  }

  buscarProducto(e: any) {
    if (e.target.value == "" || !isNaN(e.target.value.trim().replace(/\s/g, ""))) {
      this.cords = null;
      this.sourceProducts = [];
      return;
    }

    this.sourceProductsEntrada = [];

    if (this.selectedIndex == -1) {
      this.selectedIndex = 0;
    }
    let row = null;
    if (e.keyCode == "38") {
      e.preventDefault();
    }

    if (e.keyCode == "40") {
      e.preventDefault();
    }
    if (e.keyCode == "27" || e.keyCode == "37" || e.keyCode == "39" || e.keyCode == "38" || e.keyCode == "40") {
      return;
    }
    let cords = this.getOffset(e.target);
    cords.top = cords.top - 400;
    cords.left = cords.left - 290;
    this.cords = cords;
    //todo si no ha seleccionado el tipode movimineto o el folio
    this.fServices.busquedaProductos(e.target.value, 0, false).subscribe((result) => {
      const lista = JSON.parse(result.message);
      this.sourceProducts = lista;
      if (this.sourceProducts.length > 0) {
        this.selectedIndex = 0;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`);
        if (row) {
          row.scrollIntoView({ block: "center" });
        }
      } else {
        this.selectedIndex = -1;
      }
    });
  }

  //* Columna 2 Producto Entrada col2


  clicTablaProducto(e: any, item: any) {
    const t: any = document.activeElement;
    t.value = item.Clave;
    const input: any = document.getElementById(`txt_${this.indexEditing}_2${this.idUnico}`)!;
    if (input) {
      input.value = item.Clave;
      this.enviarFocoCantidad = true;
    }
  }



  navegarProductos(e: any) {
    let row = null;
    if (e.keyCode == 40) {
      if (this.selectedIndex + 1 < this.sourceProducts.length) {
        this.selectedIndex++;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`)
        row!.scrollIntoView({ block: "center" });
      }
    } else if (e.keyCode == 38) {
      if (this.selectedIndex > 0) {
        this.selectedIndex--;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`)
        row!.scrollIntoView({ block: "center" });
      }
    }
  }




  focusProductoEntrada(index: number, e: any, item: DocumentoInventarioDetalle) {
    this.setIndexEdit(index, e, item);
    let c = this.getLastItem();

    if (c == 0 && index > 0) {
      e.target.blur();
      return;
    }

    if ((index >= c + 1) && c > 0) {
      e.target.blur();
    }
  }

  enterProductoEntrada(e: any) {
    this.esEnterProductoEntrada = true;
    this.esEscapeProductoEntrada = false;
    this.enviarFocoCantidad = true;
    let value = '';
    if (this.sourceProductsEntrada.length > 0) {
      value = this.sourceProductsEntrada[this.selectedIndex].Clave;
    } else {
      value = e.target.value;
    }
    e.target.value = value;
    if (value) {
      e.target.blur();
    }
  }


  //* Columna 4 COL4


  escapeCantidad(e: any) {
    this.esEscapeCantidad = true;
    let item: DocumentoInventarioDetalle = this.source[this.indexEditing];
    e.target.value = item.Cantidad ? item.Cantidad : 0;

    this.initEditor(this.indexEditing, 1);

  }

  enterCantidad(e: any) {
    if (e.target.value == "" || parseInt(e.target.value) == 0) {
      return;
    }
    let item = this.source[this.indexEditing];

    this.enviarOtroRenglon = true;

    e.target.blur();
  }

  blurCantidad(e: any) {
    e.target.classList.remove("focus-editor-grid");
    if (this.esEscapeCantidad) {
      this.esEscapeCantidad = false;
      return;
    }
    if (e.target.value == "" || parseInt(e.target.value) == 0 && !this.enterCantidad) {
      e.target.classList.remove("focus-editor-grid");
      return;
    }
    const cantidad = parseFloat(e.target.value);
    let item = this.source[this.indexEditing];
    if (parseFloat(String(item.Cantidad)) == cantidad) {
      if (this.enviarOtroRenglon) {
        setTimeout(() => {
          this.sendFocus();
        }, 150);
      }
      return;
    };
    if (item.Conversiones) {
      if (item.Conversiones.length > 0) {
        let totalEnConversiones = 0;
        item.Conversiones.forEach((i) => totalEnConversiones += i.CantidadUnidadConcepto);
        if (cantidad != totalEnConversiones) {
          item.Cantidad = totalEnConversiones;
        } else {
          item.Cantidad = cantidad;
        }
      } else {
        item.Cantidad = cantidad;
        item.Conversiones = [];
        item.TextoConversiones = "";
        this.enviarOtroRenglon = true;
      }
    } else {
      item.Cantidad = cantidad;
      item.Conversiones = [];
      item.TextoConversiones = "";
      this.enviarOtroRenglon = true;
    }
    this.source[this.indexEditing] = { ...item };
    this.source = [...this.source];
    this.sendFocus();
    this.calcTotales();
  }



  keyDownCantidad(e: any) {
    const noDecimales: boolean = this.source[this.indexEditing].Producto!.NoDecimalesCantidad;
    if (e.key === '.' && noDecimales) { e.preventDefault(); }
  }


  //* Columna 8 Paquete col8

  enterPaquete(e: any) {
    this.enviarOtroRenglon = true;
    e.target.blur();
  }

  blurPaquete(e: any) {
    e.target.classList.remove("focus-editor-grid");
    const lote: string = e.target.value;
    let item: DocumentoInventarioDetalle = this.source[this.indexEditing];
    if (item.NumeroLotePaquete == lote) {
      if (this.enviarOtroRenglon) {
        this.sendFocus();
      }
      return;
    };
    item.NumeroLotePaquete = lote;
    this.source[this.indexEditing] = { ...item };
    this.source = [...this.source];
    this.sendFocus();
  }




  //* metodos genericos del grid

  getOffset(elem: HTMLInputElement): Coordinates {
    var box = elem.getBoundingClientRect();
    var left = window.scrollX !== undefined ? window.scrollX :
      (document.documentElement || document.body.parentNode || document.body).scrollLeft;
    var top = window.scrollY !== undefined ? window.scrollY :
      (document.documentElement || document.body.parentNode || document.body).scrollTop;

    top += elem.offsetHeight;
    return { left: box.left + left, top: box.top + top };
  }


  openExistencias() {
    if (this.source[this.indexEditing].Producto?.Inventariable) {
      const b: any = this.ctrlExistenciasTrasSuc;
      this.ms.openModal(b, (e: ExistenciaProductoPaquete | null) => {
        setTimeout(() => {
          this.initEditor(this.indexEditing, this.colCantidad);
        }, 50);
      }, 'xl')
    } else {
      this.eventsService.publish('home:showAlert', { message: "El Producto no es inventariable.", cancelButton: false });
    }
  }




  setIndexEdit(index: number, e: any, item: DocumentoInventarioDetalle) {
    if (this.myForm.value.Folio == 0) {
      this.eventsService.publish('home:showAlert', {
        textAccept: "Aceptar",
        cancelButton: false,
        message: 'Favor de indicar el Folio.',
        onConfirm: (data: AlertResponse) => {
          this.indexEditing = -1;
          return;
        }
      });
      return;
    }



    this.indexEditing = index;
    e.target.select();
    e.target.classList.add("focus-editor-grid");

    return;



    //todo revisar este codigo y ver como aplica con el nuevo grid
    // if (!this.permiteEliminar) {
    //   return false;
    // }

    // if (index > 0) {
    //   if (!this.source[index - 1].Producto) {
    //     return false;
    //   }
    //   if (this.source[index - 1].Producto!.Id == 0) {
    //     return false;
    //   }
    // }




    //* si esta cancelado, no deberia de poder editar algo.
    if (this.myForm.value.FechaCancelacion) {
      this.indexEditing = -1;
      e.target.blur();
      return;
    }

    this.indexEditing = index;
    e.target.select();
    e.target.classList.add("focus-editor-grid");
  }



  getLastItem(): number {
    return this.source.filter(P => P.Producto && P.Cantidad > 0).length;
  }

  sendFocus() {
    setTimeout(() => {
      if (!this.enviarOtroRenglon) {
        if (this.enviarFocoCantidad) {
          this.initEditor(this.indexEditing, this.colCantidad);
          this.enviarFocoCantidad = false;
        }

        if (this.enviarFocoProducto) {
          this.initEditor(this.indexEditing, this.colProducto);
          this.enviarFocoProducto = false;
        }

        if (this.enviarFocoProductoEntrada) {
          this.initEditor(this.indexEditing, this.colProductoEntrada);
          this.enviarFocoProductoEntrada = false;
        }

        if (this.enviarFocoLotePaquete) {
          this.initEditor(this.indexEditing, this.colLotePaquete);
          this.enviarFocoLotePaquete = false;
        }
      } else {
        this.indexEditing = this.indexEditing + 1;
        this.initEditor(this.indexEditing, 1);
        this.enviarOtroRenglon = false;
      }
    }, 50);
  }

  get getTotalCosto(): number {
    let total = 0;

    this.source.forEach((i) => {
      if (i.Producto) {
        total += (Number(i.Costo) * Number(i.Cantidad));
      }
    })

    return total;
  }

  get getTotalCantidad(): number {
    let total = 0;
    this.source.forEach((i) => {
      if (i.Producto) {
        total += Number(i.Cantidad);
      }
    })

    return total;
  }

  keyDownRow(e: any, index: number) {
    if (e.ctrlKey && e.keyCode == "46") {
      e.preventDefault();
      this.deleteRow(index);
    }
  }


  up(e: any, celda: number) {
    e.preventDefault();
    if (celda == 0 && this.sourceProducts.length > 0) {
      return;
    }
    if (this.indexEditing > 0) {
      if (celda == 0) {
        this.esEscapeProducto = true;
      }
      e.target.blur();
      setTimeout(() => {
        this.indexEditing = this.indexEditing - 1;
        this.initEditor(this.indexEditing, celda + 1);
        this.esEscapeProducto = false;
      }, 50);
    } else {
      this.initEditor(this.indexEditing, 1);
    }
  }


  down(e: any, celda: number) {
    e.preventDefault();
    if (celda == 0 && this.sourceProducts.length > 0) {
      return;
    }
    let c = this.getLastItem();
    if (this.indexEditing < c - 1) {
      e.target.blur();
      setTimeout(() => {
        this.indexEditing = this.indexEditing + 1;
        this.initEditor(this.indexEditing, celda + 1);
      }, 50);
    } else {
      if (this.indexEditing + 1 < c + 1) {
        this.esEscapeProducto = true;
        this.indexEditing = this.indexEditing + 1;
        this.initEditor(this.indexEditing, 1);
      }
    }
  }

  sourceReset(conceptos: any = []) {
    if (!conceptos) {
      this.source = [];
    } else {
      this.source = conceptos;
    }



    for (let index = 0; index < 100; index++) {
      this.source = [...this.source, this.estructuraVacia()];
    }
  }

  estructuraVacia() {
    return {
      Id: 0,
      Producto: null,
      Cantidad: 0,
      Existencia: 0,
      NumeroLotePaquete: '',
      NumeroPedimento: '',
      NoIdentificacion: '',
      Unidad: null,
      Descripcion: '',
      DescripcionConvesion: '',
      Costo: 0,
      Total: 0,
      TextoConversiones: '',
      Conversiones: [],
    }
  }

  conversionClick(index: number) {
    if (this.myForm.value.Id > 0) {
      return;
    }
    this.indexEditing = index;
    let item = this.source[this.indexEditing];
    this.conversiones = item.Producto!.OtrasUnidades;
    //this.unidadFinal = item.UnidadNombre;
    this.openConvertions((e: any) => this.aceptConversions(e, true));
  }


  deleteRow(indx: number) {
    if (this.myForm.value.Id > 0) {
      this.eventsService.publish('home:showAlert', { message: "El Documento no puede modificarse, proceda a Cancelar.", cancelButton: false });
      return;
    }

    this.eventsService.publish('home:showAlert', {
      message: '¿Desea eliminar el reglón?.',
      onConfirm: (data: AlertResponse) => {
        if (data.isAccept) {
          const idEliminar = this.source[indx].Id;
          if (idEliminar > 0) {
            this.eventsService.publish('home:isLoading', { isLoading: true });
            this.invService.eliminarTraspasoDetalle(idEliminar).subscribe((result) => {
              this.eventsService.publish('home:isLoading', { isLoading: false });
              this.source.splice(indx, 1);
              this.source = [...this.source];
              this.calcTotales();
              let ent: DocumentoInventario = this.myForm.value;
              ent.Conceptos?.splice(indx, 1);
              this.myForm.reset(ent);
              //this.PptoDetalle.removeElement  (indx);
            });
          } else {
            this.source.splice(indx, 1);
            this.source = [...this.source];
            this.calcTotales();
            let ent: DocumentoInventario = this.myForm.value;
            ent.Conceptos?.splice(indx, 1);
            this.myForm.reset(ent);
          }
        }
      }
    });
  }

  get gettotalCargoProrrateado(): number {
    let totalImporteProrratear = 0;
    return totalImporteProrratear;
  }




  calcTotales() {
    let ent: DocumentoInventario = this.myForm.value;
    ent.Total = 0;
    let totalConcepto: number = 0, subTotalVenta = 0, totalConceptos = 0;
    let count: number = 0;
    let regs: number = 0;

    this.source.map((concepto, index) => {
      if (concepto.Producto) {
        if (concepto.Producto.Id > 0) {
          concepto.Total = parseFloat((concepto.Cantidad * concepto.Costo).toFixed(6));
          totalConcepto += concepto.Total;
          count += concepto.Cantidad;
          regs++;
        }
      }
    });
    let totalImporteNormal = 0;
    let totalImporteProrratear = 0;
    this.source.filter(P => !P.Producto?.SeProrrateaEnCompras && P.Producto?.Inventariable).forEach((i) => {
      let importe = parseFloat(String(i.Cantidad)) * parseFloat(String(i.Costo));
      totalImporteNormal += importe;
    });

    this.myFormTotales.get('TotalCosto')!.setValue(totalConcepto);
    this.myFormTotales.get('TotalRegistros')!.setValue(regs);
    this.myFormTotales.get('TotalCantidad')!.setValue(count);
    ent.Total = totalConcepto;
    this.myForm.reset(ent);
    this.source = [...this.source];
  }


  tabCheckBox(e: any) {
    e.preventDefault();
    this.initEditor(0, 1)
  }


  initEditor(row: number, col: number) {
    col = col - 1;
    const input = document.getElementById(`txt_${row}_${col}${this.idUnico}`)!;
    if (input) input.focus();
  }


  // initEditor(row: number, col: number) {
  //   // let output:string = "row:" + row + " col:" + col;
  //   console.log(col);


  //   const elem: any = this.getElemEditor(row, col);
  //   if (elem) {
  //     setTimeout(() => {
  //       elem.firstElementChild?.click();
  //     }, 250);
  //   }
  // }


  getElemEditor(row: number, col: number) {

    let elem: any = null;
    const elems: any = document.getElementById("divTraspasosSucursales")!.querySelectorAll('.gui-content');
    elems.forEach((renglon: HTMLElement) => {
      renglon.childNodes.forEach((nodeRow: any, indexRow: number) => {
        if (indexRow == row && !elem) {
          let colNum = -1;
          nodeRow.childNodes.forEach((nodeColumna: any) => {
            if (nodeColumna.childNodes && nodeColumna.className && !elem) {
              colNum++;
              if (col == colNum) {
                elem = nodeColumna;
              }
            }
          });
        }
      })
    });

    //const elem: any = document.getElementById("divFacturacion")!.querySelector(`[ng-reflect-column-index='${col}'][ng-reflect-row-index='${row}']`);
    return elem;
  }

  openConvertions(cb: any) {
    const b: any = this.ctrlConversiones;
    this.ms.openModal(b, (e: any) => {
      cb && cb(e);
    })
  }
  aceptConversions(e: any, isclick: boolean = false) {
    this.conversiones = [];
    this.unidadFinal = '';
    let item = this.source[this.indexEditing];
    if (e) {
      if (e.cantidad > 0) {
        item.Conversiones = e.items;
        item.TextoConversiones = e.conversiones;
        item.Cantidad = e.cantidad;
        this.source = [...this.source];
        this.indexEditing++;
        !isclick && this.initEditor(this.indexEditing, this.colProducto);
      } else {
        item.Conversiones = [];
        item.TextoConversiones = '';
        !isclick && this.initEditor(this.indexEditing, this.colCantidad);
      }
      this.calcTotales();
    } else {
      this.source = [...this.source];
      !isclick && this.initEditor(this.indexEditing, this.colCantidad);
    }
  }

  //4.- matodos de consulta a el backend

  navigate(type: string) {
    this.findEntityByParamsNavigate(type);
  }

  get esNuevo() {
    return this.myForm.value.Id == 0;
  }

  findEntityByParamsNavigate(type: string) {
    debugger
    const params = new HttpParams()
      .set("idEmpresa", this.myForm.value.Empresa.Id)
      .set("idSucursal", this.myForm.value.Sucursal.Id)
      .set("idDoc", this.myForm.value.TipoDocumento.Id)
      .set("serie", this.myForm.value.SerieString)
      .set("folio", this.myForm.value.Folio)
      .set("tipo", type);
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.http.get<DocumentoInventario>(`${this.baseUrl}/Inventarios/ObtenerDocumentoInventarioPorParametrosNavegacion`, { params }).subscribe((traspaso) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      debugger
      this.setEntity(traspaso);
    });
  }

  setEntity(doc: DocumentoInventario, cb: any = null) {
    if (!doc) {
      this.new();
    } else {
      let ent = this.myForm.value;
      ent = { ...doc };
      this.sourceReset(ent.Conceptos);
      this.myForm.reset(ent);
      this.calcTotales();

      if (cb) cb();
    }
  }

  new() {
    const ent: DocumentoInventario = this.myForm.value;
    this.getEmptyEntity(ent.Serie.Id, ent.TipoDocumento.Id);
    this.sourceReset();
  }

  getNextFolio(ent: DocumentoInventario) {
    const params = new HttpParams().set("idEmpresa", ent.Empresa.Id)
      .set("idSucursal", ent.Sucursal.Id)
      .set("idDocumento", ent.TipoDocumento.Id)
      .set("serie", ent.SerieString);
    this.http.get<number>(`${this.baseUrl}/Inventarios/ObtenerSiguienteFolio`, { params }).subscribe((folio) => {
      ent.Folio = folio;
      this.getDate(ent);
    })
  }

  getDate(ent: DocumentoInventario) {
    this.fServices.ObtenerFechaPorSucursal(ent.Sucursal.Id).subscribe((result) => {
      //2024-01-29T10:23:49
      const f = result.message.split('T')[0].split('-');
      const t = result.message.split('T')[1].split(':');
      ent.FechaEmision = new Date(parseInt(f[0]), parseInt(f[1]) - 1, parseInt(f[2]), parseInt(t[0]), parseInt(t[1]), parseInt(t[2]));
      this.eventsService.publish('home:isLoading', { isLoading: false });
      this.myForm.reset(ent);
      this.initEditor(0, 1);
    })

  }


  searchProductEntrada(value: string) {
    this.loading = true;
    value = this.fServices.aplciarMascara(value, this.mascara);
    const params = new HttpParams().set("idEmpresa", this.empresaActual!.numero).set("idSucursal", this.sucursalActual!.numero).set("idCliente", 0).set("clave", value);
    this.http.get<Producto>(`${this.baseUrl}/Ventas/ObtenerProducto`, { params }).subscribe((producto: Producto) => {
      this.loading = false;
      if (producto) {
        let item = this.source[this.indexEditing];
        this.source[this.indexEditing] = { ...item };
        this.source = [...this.source];
        this.enviarFocoCantidad = true;
        this.sendFocus();
        // this.source[this.indexEditing].NoIdentificacionEntrada = value;
        // this.initEditor(this.indexEditing, this.colCantidad);
      } else {
        this.eventsService.publish('home:showAlert', {
          message: "No se encontró el Producto Entrada indicado.", cancelButton: false, onConfirm: (data: AlertResponse) => {
            this.source = [...this.source];
            this.initEditor(this.indexEditing, this.colProductoEntrada);
          }
        });
      }
    });
  }

  searchProduct(value: string) {
    this.loading = true;
    value = this.fServices.aplciarMascara(value, this.mascara);
    const params = new HttpParams().set("idEmpresa", this.empresaActual!.numero).set("idSucursal", this.sucursalActual!.numero).set("idCliente", 0).set("clave", value);
    return this.http.get<Producto>(`${this.baseUrl}/Ventas/ObtenerProducto`, { params }).subscribe((producto) => {
      this.loading = false;
      if (producto) {
        if (!producto.Almacen) {
          this.eventsService.publish('home:showAlert', {
            message: 'El producto seleccionado no tiene configurado un Almacén, por favor verifique.',
            cancelButton: false,
            onConfirm: (data: AlertResponse) => {
              if (data.isAccept) {
                this.source[this.indexEditing].NoIdentificacion = "";
                this.source = [...this.source];
                this.initEditor(this.indexEditing, this.colProducto);
              }
            }
          });
          return;
        }

        if (!producto.Inventariable) {
          this.eventsService.publish('home:showAlert', {
            message: 'El producto seleccionado no es Inventariable, por favor verifique.',
            cancelButton: false,
            onConfirm: (data: AlertResponse) => {
              if (data.isAccept) {
                this.source[this.indexEditing].NoIdentificacion = "";
                this.source = [...this.source];
                this.initEditor(this.indexEditing, this.colProducto);
              }
            }
          });
          return;
        }


        this.fServices.getExistencia(producto.Id, this.myForm.value.Sucursal.Id, producto.Almacen.Id).subscribe((existencia) => {
          this.invService.getUltimoCostoPorProducto(this.empresaActual!.numero, this.sucursalActual!.numero, producto.Almacen.Id, producto.Id).subscribe(prod => {
            let item = this.source[this.indexEditing];
            item.Existencia = existencia;
            item.Producto = producto;
            item.NoIdentificacion = producto.Clave;
            if (!this.usarSegundoNombreProducto) {
              item.Descripcion = producto.Nombre;
            } else {
              item.Descripcion = producto.SegundoNombre;
            }

            item.Cantidad = 0;
            item.Unidad = producto.Unidad;
            if (prod != 0) {
              item.Costo = prod;
            } else {
              item.Costo = 0;
            }
            debugger
            this.source = [...this.source];
            this.showDescription = producto.ActualizarDescrpcion;
            this.myForm.controls["DescripcionAdicional"].setValue(producto.Nombre);
            if (!producto.ActualizarDescrpcion) {
              if (producto.OtrasUnidades && producto.OtrasUnidades?.length > 0 && producto.AplicaVentas) {
                this.getExistencia(producto);
                this.conversiones = producto.OtrasUnidades;
                this.openConvertions((e: any) => this.aceptConversions(e));
              } else {
                this.conversiones = [];
                this.unidadFinal = '';

                if (!producto.ProductoEquivalente) {
                  this.enviarFocoCantidad = true;
                  this.getExistencia(producto, this.sendFocus());

                } else {
                  this.enviarFocoProductoEntrada = true;
                  this.getExistencia(producto, this.sendFocus());
                }
              }
            } else {
              this.rowEditandoDescricionAdic = this.indexEditing;
              setTimeout(() => {
                const t: any = this.txtDescription;
                t.nativeElement.focus();
              }, 100);
            }
          });
        });


      } else {

        this.eventsService.publish('home:showAlert', {
          message: 'No se encontró el Producto indicado.',
          cancelButton: false,
          onConfirm: (data: AlertResponse) => {
            this.source[this.indexEditing].NoIdentificacion = "";
            this.source[this.indexEditing].Existencia = 0;
            this.source = [...this.source];
            this.enviarFocoProducto = true;
            this.sendFocus();
            //this.initEditor(this.indexEditing, this.colProducto);
          }
        });
      }
    })
  }

  getExistencia(producto: Producto, cb: any = null) {
    this.fServices.getExistencia(producto.Id, this.myForm.value.Sucursal.Id, producto.Almacen.Id).subscribe((existencia) => {
      this.existencia = existencia;
      setTimeout(() => {
        this.source[this.indexEditing].Existencia = existencia;
        this.source = [...this.source];
        if (cb) { cb(); }
      }, 150);
    });
  }


  onBeforeSave(param: EventReturn) {
    this.existencia = 0;
    if (this.saving) return;
    //this.myForm.get('Clave')!.setValue(this.myForm.value.Folio);
    let ent: DocumentoInventario = this.myForm.value;
    if (this.myForm.value.Id == 0) {
      ent.UsuarioElabora = { Id: this.usuarioActual.numero, Clave: this.usuarioActual.numero, Nombre: this.usuarioActual.nombreUsuario } as Usuario;
      ent.FechaElabora = new Date();
    } else {
      this.eventsService.publish('home:showAlert', { message: `El Documento de Inventario no puede modificarse, proceda a Cancelar.`, cancelButton: false });
      param.callback(false);
      return;
    }
    this.myForm.controls["Empresa"].setValue({ Id: this.info.empresa!.numero })
    ent.FechaEmisionString = moment(ent.FechaEmision).format("YYYY-MM-DDTHH:mm:ss");


    ent.Conceptos = this.source.filter(P => P.NoIdentificacion != '');

    if (ent.Conceptos.length == 0) {
      this.eventsService.publish('home:showAlert', { message: `Debe de indicar por lo menos un concepto`, cancelButton: false });
      param.callback(false);
      return;
    }

    if (ent.Conceptos.filter((P: any) => P.Cantidad <= 0).length > 0) {
      this.eventsService.publish('home:showAlert', {
        message: "No puede haber conceptos con cantidad menor o igual a cero, por favor verifique.", cancelButton: false, onConfirm: (data: AlertResponse) => {
          return;
        }
      });
      param.callback(false);
      return;
    }
    this.myForm.controls["Conceptos"].setValue(ent.Conceptos);
    param.callback(true);
  }

  onAfterSave(entity: DocumentoInventario) {
    this.setEntity(entity);
  }


  acceptDescription() {
    this.source[this.rowEditandoDescricionAdic].Descripcion = this.myForm.value.DescripcionAdicional;
    this.showDescription = false;
    this.source = [...this.source];
    this.initEditor(this.indexEditing, 4);
  }
  cancelDescription() {
    this.showDescription = false;
    this.initEditor(this.indexEditing, 4);
  }

  activeButtons: ActiveButtons = {
    new: true,
    delete: false,
    return: true,
    save: true,
    first: true,
    left: true,
    right: true,
    last: true,
    search: false,
    print: true,
  }

  onClickBarButton(button: string): void {
    switch (button) {
      case "new": this.new(); break;
      //case "save": this.save(); break;
      case "print": this.print(); break;
      case "first": this.navigate(button); break;
      case "left": this.navigate(button); break;
      case "right": this.navigate(button); break;
      case "last": this.navigate(button); break;
      case "return": this.findEntityByParams();
        // setTimeout(() => {
        //   const txtFolio: any = this.txtFolio;
        //   txtFolio.tagInput.nativeElement.focus();
        // }, 100);
        break;
    }
  }

  imprimirFormato() {


  }


  imprimirDocumento(id: number) {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    const params = new HttpParams().set("idMovto", id);
    this.http.get<Result>(`${this.baseUrl}/Inventarios/ImprimirDocumentoInventarios`, { params }).subscribe((result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result.success) {
        this.downloadPdf(result.message);
      } else {
        this.eventsService.publish('home:showAlert', { message: `${result.message}`, cancelButton: false });
      }
    })
  }

  print() {

    const Id = this.myForm.get("Id")?.value;

    if (Id == 0) {


    }


    this.imprimirDocumento(Id);
  }

  downloadPdf(base64: string) {
    this.fServices.printDocument(this.fServices.base64ToArrayBuffer(base64));
  }



  blurFolio(values: any) {
    if (values.after != values.before) {
      this.findEntityByParams();
    }
  }

  findEntityByParams(cb: any = null) {
    const ent = this.myForm.value;
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.fServices.findDocumentoInventarioByParams(ent.Empresa.Id, ent.Sucursal.Id, ent.TipoDocumento.Id, ent.SerieString, ent.Folio).subscribe((traspaso) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      debugger
      this.setEntity(traspaso, cb);
    });
  }


}
