<div class="form-group row">
  <label class="col-sm-{{widthLabelColumns}} col-form-label tb-right">{{label}}{{separator}}</label>
  <div class="d-flex col-sm-{{widthTextColumns}}">
    <input (focus)="focus()" [style]="'border-radius: 0px;border:0px;border-bottom: 1px solid ' + (this.appSettings.appDarkMode ? '#e6831d;' : '#000000;')"
      [tabindex]="tabIndex"
      (blur)="blurControl(txtTag.value)" [(ngModel)]="value" maxLength="{{maxLength}}"
      [class]="'form-control form-control-sm ' + IsRequired + ' ' + (appSettings.appDarkMode ? 'bg-transparent ':' ')" type="text" placeholder="{{placeHolder}}" #txtTag
      (keypress)="onKeyPress($event)" (keydown)="onKeyDown($event)" />
  </div>
</div>
