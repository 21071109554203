import { AfterViewInit, Component, ElementRef, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ComboBoxComponent } from 'src/app/component-ui/components/combo-box/combo-box.component';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { LevelType, ReportFilter, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ContabilidadService } from 'src/app/contabilidad/services/contabilidad.service';
import { EventsService } from 'src/app/service/events.service';
import { ReportsService } from 'src/app/service/reports.service';
import { UtilsService } from 'src/app/service/utils.service';

@Component({
  selector: 'mayor-general',
  templateUrl: './mayor-general-page.component.html',
  styles: [
  ]
})
export class MayorGeneralComponent implements AfterViewInit {

  private utilsService = inject(UtilsService);
  private contabilidadService = inject(ContabilidadService);
  private eventsService = inject(EventsService);


  @ViewChild('comboMes2')
  public comboMes2!: ElementRef<ComboBoxComponent>;

  @ViewChild('comboAnio')
  public comboAnio!: ElementRef<ComboBoxComponent>;



  listaMeses: any[] = [];
  listaAnios: any[] = [];
  mes1Seleccionado?: ComboBoxEntity;
  mes2Seleccionado?: ComboBoxEntity;
  anioSeleccionado?: ComboBoxEntity;
  checkTodoElAnio: boolean = false;
  seleccionando: boolean = false;
  menuId: string = "mayorgeneral";
  filtroMes1?: number = 0;
  filtroMes2?: number = 0;

  get getMes1(): ComboBoxEntity | null {
    this.myForm.get('NombreReporte')?.setValue('MAYOR GENERAL ' + this.rangoFechas);
    return this.mes1Seleccionado!;
  }



  get getMes2(): ComboBoxEntity | null {
    this.myForm.get('NombreReporte')?.setValue('MAYOR GENERAL ' + this.rangoFechas);
    return this.mes2Seleccionado!;
  }

  get selectAnioManualItem(): ComboBoxEntity | null {
    this.myForm.get('NombreReporte')?.setValue('MAYOR GENERAL ' + this.rangoFechas);
    return this.anioSeleccionado!;
  }

  get rangoFechas() {
    let result = "";
    if (!this.anioSeleccionado) return result;
    let fecha1: Date = new Date(this.anioSeleccionado!.Id, this.myForm.value.Mes1 - 1, 1);
    let fecha2: Date = new Date(this.anioSeleccionado!.Id, this.myForm.value.Mes2 - 1, 1);

    result = this.utilsService.getEtiquetaRangoPorFechas(fecha1, fecha2, 1);
    return result.toUpperCase();
  }



  @ViewChild('txtNombreReporte')
  public txtNombreReporte!: ElementRef<HTMLElement>;;

  public filtrosReporte: ReportFilter =
    {
      EsContabilidad: true,
      menuId: this.menuId,
      ReportHeader: {} as ReportHeader,
      DesgloseLista: [{ Nombre: 'Con Auxiliares' },{ Nombre: 'Sin Auxiliares' }],
      NombreReporte: '',
      TituloVisor: 'Mayor general',
      NombreExcel: 'Mayor general.xlsx',
      FilterOptions: [
        { Campo: 'emp.Clave', Etiqueta: 'Empresa', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Empresa" },
        { Campo: 'mpactual.ClaveCuentaNivel', Etiqueta: 'Cuenta contable', Tipo: TypeFilter.levels, TipoNiveles: LevelType.account },
        { Campo: 'mpactual.ClaveCentroCostoNivel', Etiqueta: 'Centros de Costos', Tipo: TypeFilter.levels, TipoNiveles: LevelType.costAccount },
      ]
    };


  public myForm: FormGroup = this.fb.group({
    NombreReporte: '',
    Opc1: false,
    Opc2: false,
    Anio1: 0,
    Mes1: 0,
    Mes2: 0,
    Opc3:false
  });


  activeButtons: ActiveButtons = {
    all: false,
    print: true,
  }

  constructor(private fb: FormBuilder, private reportsService: ReportsService) { }


  ngAfterViewInit(): void {
    this.listaMeses = this.utilsService.getListaMeses();
    const mes = new Date().getMonth() + 1;
    const mesItem = this.listaMeses.find(p => p.Id == mes);
    this.mes1Seleccionado = mesItem;
    this.mes2Seleccionado = mesItem;
  }



  ngOnInit(): void {

    this.focus('txtNombreReporte');

    this.listaMeses = this.utilsService.getListaMeses();
    const mes = new Date().getMonth() + 1;
    const mesItem = this.listaMeses.find(p => p.Id == mes);
    this.mes1Seleccionado = mesItem;
    this.mes2Seleccionado = mesItem;
    this.myForm.get('Mes1')?.setValue(mesItem.Clave);
    this.myForm.get('Mes2')?.setValue(mesItem.Clave);
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.contabilidadService.obtenerListaDeAniosconMovimientosDePolizas().subscribe(result => {
      if (result) {
        if (result.length > 0) {
          result.forEach((element: number) => {
            this.listaAnios.push({ Id: element, Nombre: element.toString() as string, Clave: element });
          });
        } else {
          const anio: number = new Date().getUTCFullYear();
          this.listaAnios.push({ Id: anio, Nombre: anio.toString(), Clave: anio })
        }
        this.anioSeleccionado = this.listaAnios[0];
        this.myForm.get('Anio1')?.setValue(this.anioSeleccionado!.Clave);
        this.myForm.get('NombreReporte')?.setValue('MAYOR GENERAL DE ' + this.rangoFechas);

      }
    })
  }

  reportHeaderChange(value: any) {
    let a: any = 0;
    if (value) {
      if (value.Mes1 && value.Mes2) {
        let mesItem: any = this.listaMeses.find(p => p.Id == value.Mes1);
        this.mes1Seleccionado = mesItem;
        mesItem = this.listaMeses.find(p => p.Id == value.Mes2);
        this.mes2Seleccionado = mesItem;
      }
      if (value.Anio1) {
        let anioItem: any = this.listaAnios.find(p => p.Id == value.Anio1);
        this.anioSeleccionado = anioItem;
      }
    }
    this.myForm.get('Anio1')?.setValue(this.anioSeleccionado!.Clave);
    this.myForm.get('Mes1')?.setValue(this.mes1Seleccionado!.Clave);
    this.myForm.get('Mes2')?.setValue(this.mes2Seleccionado!.Clave);
    this.myForm.get('NombreReporte')?.setValue('MAYOR GENERAL DE ' + this.rangoFechas);
  }




  selectedCombo(entity: ComboBoxEntity, field: string) {
    if (field == 'Mes1') {
      this.mes1Seleccionado = entity;
      this.mes2Seleccionado = entity;
      this.seleccionando = true;
    } else if (field == 'Mes2') {
      this.mes2Seleccionado = entity;
      if (!this.seleccionando) {
      }
    } else if (field == 'Anio1') {
      this.anioSeleccionado = entity;
    }
    this.myForm.controls[field].setValue(entity.Clave);
  }

  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombreReporte;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }


  changeselec(event: any) {
    this.filtrosReporte = event;
  }

  clickBoton(event: string) {
    this.filtrosReporte.ReportHeader = this.myForm.value;
    this.reportsService.printReport(this.filtrosReporte, '/Contabilidad/ReporteMayorGeneral');
  }
}
