<app-container-base (onItemsComboSearched)="onItemsComboSearched($event)" (onItemSearched)="onItemSearched($event)"
  (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)" [entity]="myForm" entityName="SerieCompra"
  title="Series de compras" icon="fa fa-building" subTitle="Administra la configuración de Series de compras">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Identificación de la Serie de Compra">
        <div class="panel-content">
          <component-ui-combo-box [required]="true" [enabled]="false" [label]="'Empresa'" formControlName="Empresa"
            [zeroMask]="2" entityName="Empresa" [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="8"
            [tabIndex]="1" (onSelectedItem)="selectedCombo($event,'Empresa')" [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box [required]="true" [enabled]="false" [label]="'Sucursal'" formControlName="Sucursal"
            [tabIndex]="2" [zeroMask]="2" entityName="Sucursal" [widthTextColumns]="2" [widthLabelColumns]="2"
            [widthRightColumns]="8" (onSelectedItem)="selectedCombo($event,'Sucursal')"
            [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box formControlName="Id" (onNewCatalog)="onNewCatalog($event)" [label]="'Clave'"
            (onChangueEntity)="onChangueEntity($event)" [isCatalog]="true" [isNewItem]="getIsNew" [zeroMask]="3"
            [required]="true" [setItems]="getItemsCombo" [tabIndex]="3" [listProperty]="'ClaveNombre'"
            [widthTextColumns]="1" [widthLabelColumns]="2" entityName="SerieCompra" />

          <components-ui-text-box [label]="'Nombre'" formControlName="Nombre" [tabIndex]="4" [required]="true"
            [widthTextColumns]="3" [widthLabelColumns]="2" #txtNombre [maxlength]="150" />

          <components-ui-text-box [label]="'Serie'" formControlName="Serie" [maxLength]="10" [required]="true"
            [tabIndex]="5" [widthTextColumns]="3" [widthLabelColumns]="2" [maxlength]="20" />

          <component-ui-combo-box label='Documento de compra' formControlName="DocumentoCompra"
            entityName="DocumentoCompra" [required]="true" [tabIndex]="6" [widthTextColumns]="2" [widthLabelColumns]="2"
            [widthRightColumns]="8" (onSelectedItem)="selectedCombo($event,'DocumentoCompra')" [zeroMask]="3" />

          <component-ui-combo-box (onSelectedItem)="selectedComboFormato($event,'Formato')"
            [selectManualItem]="getFormatoImpresion" [setItemsList]="listaFormatosImpresion" listProperty='Nombre'
            label="Formato" [tabIndex]="7" [inputProperty]="'Nombre'" [widthLabelColumns]="2" [widthTextColumns]="3"
            [required]="false" />

          <!-- <components-ui-text-box [label]="'Formato'" formControlName="Formato" [maxLength]="50" [tabIndex]="7"
            [widthTextColumns]="3" [widthLabelColumns]="2" /> -->

          <components-ui-text-box [label]="'Copias'" formControlName="Copias" [maxLength]="100" [tabIndex]="8"
            [widthTextColumns]="3" [widthLabelColumns]="2" />

          <components-ui-number-box [label]="'Inicia con folio'" formControlName="IniciarConFolio" [tabIndex]="9"
            [required]="false" [widthTextColumns]="1" [widthLabelColumns]="2" />


        </div>
      </app-panel-base>

      <app-panel-base title="Pólizas automáticas">
        <div class="panel-content">
          <component-ui-combo-box [label]="'Póliza Compra'" filterQuery="DisponibleParaRegenerar = true"
            formControlName="Poliza" [tabIndex]="10" [zeroMask]="2" entityName="PolizaAutomatica" [widthTextColumns]="1"
            [widthLabelColumns]="2" [widthRightColumns]="8" (onSelectedItem)="selectedCombo($event,'Poliza')"
            [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box [label]="'Póliza Cargos'" filterQuery="DisponibleParaRegenerar = true"
            formControlName="PolizaCargos" [tabIndex]="11" [zeroMask]="2" entityName="PolizaAutomatica"
            [widthTextColumns]="1" [widthLabelColumns]="2" [widthRightColumns]="8"
            (onSelectedItem)="selectedCombo($event,'PolizaCargos')" [listProperty]="'ClaveNombre'" />
        </div>
      </app-panel-base>


    </form>
  </div>

</app-container-base>
