<app-container-base subTitle="Generar Token" [hasBar]="false" icon=" fas fa-list-check" [hasCounter]="false">
  <div class="screen-content">
    <form [formGroup]="frmTokenCreditos" autocomplete="off">
      <app-panel-base title="Datos Generales">
        <div class="panel-content">
          <div class="col-md-4">
            <component-ui-combo-box [required]="true" label='Sucursal' formControlName="Sucursal" [tabIndex]="1"
              inputProperty="ClaveNombre" entityName="Sucursal" [widthLabelColumns]="4" [widthTextColumns]="8"
              [maxLength]="6" #txtSucursal (onSelectedItem)="selectedCombo($event, 'sucursal')"
              helpLine="Sucursal en la cual se usará el Token." />
          </div>
          <div class="col-md-4">
            <component-ui-combo-box [required]="true" label='Usuario' formControlName="Usuario" [tabIndex]="2"
              inputProperty="ClaveNombre" entityName="Usuario" [widthLabelColumns]="4" [widthTextColumns]="8"
              [maxLength]="6" #txtUsuario (onSelectedItem)="selectedCombo($event, 'usuario')"
              helpLine="Usuario que usará el Token." />
          </div>
          <div class="col-md-4">
            <components-ui-text-box [tabIndex]="3"
              helpLine="Cliente para el cual se usará el Token. Presione F2 para buscar." label="Cliente"
              [widthLabelColumns]="4" (keydown.enter)="enterCliente()" [required]="true"
              (keydown.f2)="openCustomerSearch()" [widthTextColumns]="8" entidadBusqueda="Cliente" #txtCliente
              (elementoSeleccionado)="elementoSeleccionado($event)" formControlName="Cliente" />
          </div>
          <div class="col-md-4">
            <components-ui-number-box [required]="true" [formatoMoneda]="true" label='Importe Autorizado'
              formControlName="Importe" [tabIndex]="4" [required]="true" [widthLabelColumns]="4" [widthTextColumns]="8"
              #txtImporte helpLine="Cantidad máxima para el crédito." />
          </div>
          <div class="col-md-4">
            <components-ui-password-box [required]="true" label='Contraseña' formControlName="Contrasena" [tabIndex]="5"
              [widthLabelColumns]="4" [widthTextColumns]="8" #txtPassword
              helpLine="Contraseña del usuario que genera el Token (Usuario en sesión actual)." />
          </div>
          <div class="col-md-4">
            <button [disabled]="frmTokenCreditos.invalid || (token.sucursal!.Id === 0 || token.usuarioUsa!.Id === 0)"
              class="btn btn-success float-end me-15px" type="button" (click)="generarToken()">
              <i class="fas fa-unlock-keyhole"></i>
              Generar
            </button>
          </div>
        </div>
      </app-panel-base>
    </form>
  </div>
</app-container-base>
<ng-template #ctrlBusqueda let-modal let-c="close">
  <app-data-search titulo="Búsqueda de Clientes" [columns]="getColumns" [entityName]="'Cliente'"
    [propertys]="getPropertys" [orders]="'Nombre|asc'" filter="" #busqueda />
</ng-template>
