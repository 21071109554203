<app-container-base (onItemsComboSearched)="onItemsComboSearched($event)" (onItemSearched)="onItemSearched($event)"
  (onBeforeSave)="onBeforeSave($event)" [activeButtons]="activeButtons" (onAfterSave)="onAfterSave($event)" [entity]="myForm" entityName="c_Unidad"
  title="Unidades de Medida SAT" icon="fa fa-building" subTitle="Administra las Unidades de Medida del SAT">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Información">
        <div class="panel-content">
          <component-ui-combo-box (onNewCatalog)="onNewCatalog($event)" (onChangueEntity)="onChangueEntity($event)"
            [isCatalog]="true" formControlName="Id" [isNewItem]="getIsNew" [setItems]="getItemsCombo" [label]="'Unidad'"
            entityName="c_Unidad" [tabIndex]="1" [enabled]="false" [listProperty]="'ClaveNombre'" [widthTextColumns]="1"
            [widthLabelColumns]="2" />
          <components-ui-text-box [label]="'Nombre'" [enabled]="false" [required]="true" formControlName="Nombre" [tabIndex]="2"
            [widthTextColumns]="3" [widthLabelColumns]="2" #txtNombre [maxLength]="50" />

          <components-ui-text-box [label]="'Abreviación'" [required]="true" formControlName="Abreviacion" [tabIndex]="3"
            [widthTextColumns]="3" [widthLabelColumns]="2" [maxLength]="10" />

            <component-ui-check-box
            helpLine="Activa/Desactiva la unidad para ser utilizada en el sistemma."
            [tabIndex]="9" [widthLabelColumns]="2" formControlName="Eliminado"
            [widthTextColumns]="7" [label]="'Activo'" />



        </div>
      </app-panel-base>
    </form>
  </div>
</app-container-base>
