import { Result } from './../../auth/interfaces/result.interface';

import { UserLogged } from 'src/app/auth/interfaces';
import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import { ComboBoxComponent } from 'src/app/component-ui/components/combo-box/combo-box.component';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { ComboBoxEntity, Coordinates } from 'src/app/component-ui/interfaces/combo-text.interface';
import { EventsService } from 'src/app/service/events.service';
import { environment } from 'src/environments/environment';
import { CuentasPorPagarService } from '../services/cxp.service';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { ModalService } from 'src/app/service/modal.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilsService } from 'src/app/service/utils.service';
import { ReportsService } from 'src/app/service/reports.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { busquedaTextBox } from 'src/app/cuentas-por-cobrar/interfaces/cxccabecera';
import { CxpCabecera, CxpDetalle } from '../interfaces/cxpcabecera';
import { FilterOptions, ReportFilter, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { Empresa } from 'src/app/configuracion/interfaces/empresa.interface';
import { Sucursal } from 'src/app/configuracion/interfaces/sucursal.interface';
import { ConceptoCxp, CuentaCxp, TipoMovimientoCxp } from '../interfaces/cuentacxp.interface';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { AlertResponse } from "src/app/component-ui/interfaces/alert.interface";
import { Proveedor } from "src/app/compras/interfaces/proveedores.interface";
import { Usuario } from 'src/app/configuracion/interfaces/usuario.interface';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-movimientos-cxp',
  templateUrl: './movimientoscxp.component.html',
})
export class MovimientosCxpComponent {




  //*referencia a elementos de la pantalla
  @ViewChild('tipoMovCxp')
  public tipoMov!: ElementRef<ComboBoxComponent>;
  @ViewChild('txtFolioMovCXP')
  public txtFolio!: ElementRef<TextBoxComponent>;
  @ViewChild('modalCancelarMovCxp')
  public modalCancelarMovCxp!: ElementRef<HTMLElement>;
  @ViewChild('txtObservaciones')
  public txtObservaciones!: ElementRef<HTMLElement>;


  //*  variables globales del usuario
  info: UserLogged = {} as UserLogged;
  usuarioActual: UserLogged = {} as UserLogged;
  tipoMovimientoActual: ComboBoxEntity = {} as ComboBoxEntity;
  tipoCapturaActual: ComboBoxEntity = {} as ComboBoxEntity;
  listaSeries: ComboBoxEntity[] = [];
  private readonly baseUrl: string = environment.baseUrlApi;
  showDescription: boolean = false;
  loading: boolean = false;
  downloading: boolean = false;
  file: any = null;
  oldFolio: number = 0;
  saving: boolean = false;
  buscandoFolio: boolean = false;
  blockCombos: boolean = false;

  //* injects
  private eventsService = inject(EventsService);
  private cxpService = inject(CuentasPorPagarService);
  private cService = inject(ContainerBaseService);
  private fb = inject(FormBuilder);
  private utilsService = inject(UtilsService);
  private ms = inject(ModalService);
  private reportsService = inject(ReportsService)
  private http = inject(HttpClient);



  //* variables del grid
  sourceProveedor: busquedaTextBox[] = [];
  sourceCuenta: any[] = [];
  sourceConcepto: any[] = [];
  totalCargos: number = 0;
  totalAbonos: number = 0;

  cords: any = null;
  source: Array<CxpDetalle> = [];


  indexEditing: number = -1;
  columnEditing: number = -1;
  navigateColumns: boolean = false;
  selectedIndex: number = -1;
  idUnico: string = '';
  esEscapeProveedor: boolean = false;
  esEscapeConcepto: boolean = false;
  esEscapeCuenta: boolean = false;
  esEscapeCargo: boolean = false;
  esEscapeAbono: boolean = false;
  esEscapeSerieOrigina: boolean = false;
  esEscapeFolioOrigina: boolean = false;
  esEscapeSerieAfecta: boolean = false;
  esEscapeFolioAfecta: boolean = false;

  esEnterProveedor: boolean = false;
  esEnterConcepto: boolean = false;
  esEnterCuenta: boolean = false;
  esEnterCargo: boolean = false;
  esEnterAbono: boolean = false;
  esEnterSerieOrigina: boolean = false;
  esEnterFolioOrigina: boolean = false;
  esEnterSerieAfecta: boolean = false;
  esEnterFolioAfecta: boolean = false;

  enviarFocoProveedor: boolean = false;
  enviarFocoConcepto: boolean = false;
  enviarFocoCuenta: boolean = false;
  enviarFocoCargo: boolean = false;
  enviarFocoAbono: boolean = false;
  enviarFocoSerieOrigina: boolean = false;
  enviarFocoFolioOrigina: boolean = false;
  enviarFocoSerieAfecta: boolean = false;
  enviarFocoFolioAfecta: boolean = false;
  enviarOtroRenglon: boolean = false;

  colProveedor: number = 1;
  colConcepto: number = 5;
  colCuenta: number = 3;
  colCargo: number = 9;
  colAbono: number = 10;
  colSerieAfecta: number = 11;
  colFolioAfecta: number = 12;
  colSerieOrigina: number = 7;
  colFolioOrigina: number = 8;


  public filtroMovCxc: ReportFilter =
    {
      ReportHeader: {} as ReportHeader,
      NombreReporte: '',
      Desglose: 'a Detalle',
      TituloVisor: 'Reporte de movimiento de cxp',
      NombreExcel: 'Reporte de movimiento de cxp.xlsx',
      FilterOptions: [
        { Campo: 'cab.Id', Etiqueta: '', Tipo: TypeFilter.number },
      ]
    };

  public myFormTotales: FormGroup = this.fb.group({
    TotalRegistros: [''],
  });

  public myForm: FormGroup = this.fb.group({
    Id: [0],
    Empresa: [{} as Empresa, Validators.required],
    Sucursal: [{} as Sucursal, Validators.required],
    TipoMovimiento: [{} as TipoMovimientoCxp, Validators.required],
    Codigo: [0],
    Observaciones: [''],
    UsuarioCaptura: [null],
    UsuarioCancela: [null],
    MotivoCancelacion: '',
    Fecha: null,
    Anio: 0,
    FechaCancelacion: null,
    Detalle: [[] as CxpDetalle[]],
    Eliminado: false,
    Baja: false,
  })



  constructor() {
  }

  ngAfterViewInit(): void {
    let elem: CxpCabecera = this.myForm.value;
    elem.Empresa = { Id: this.info.empresa?.numero, Clave: this.info.empresa?.clave, Nombre: this.info.empresa?.nombre } as Empresa;
    elem.Sucursal = { Id: this.info.sucursal?.numero, Clave: this.info.sucursal?.clave, Nombre: this.info.sucursal?.nombre } as Sucursal;
    elem.Fecha = new Date();
    elem.Anio = elem.Fecha.getFullYear();
    setTimeout(() => {
      this.myForm.reset(elem);
      const tipoMov: any = this.tipoMov;
      tipoMov.tagInput.nativeElement.focus();
    }, 150);
  }

  ngOnInit(): void {
    this.idUnico = String(new Date().getTime() * 10000);
    this.info = this.utilsService.getUserLogged();
    this.usuarioActual = this.utilsService.getUserLogged();
    this.sourceReset();
  }

  //* ///////////////////////////////////////////////////
  //* 1.- metodos de inicializacion de la pantalla y grid

  get getExtras() {
    return " Serie + String(' ', 1) + Nombre as SerieNombre, Serie";
  }

  get permiteEliminar() {
    return !(this.myForm.value.FechaCancelacion);
  }

  openCancel() {
    const b: any = this.modalCancelarMovCxp;
    this.ms.openModal(b, (e: any) => {
      if (e) {
        this.proceedCancel(e)
      }
    })
  }

  get getBlockCombos() {
    return !this.blockCombos;
  }

  //* 2.- metodos de control cambio para los campos principales
  selectTipoMovto(entity: ComboBoxEntity) {
    if (entity) {
      this.myForm.controls["TipoMovimiento"].setValue(entity);
      this.tipoMovimientoActual = entity;
      const ent: CxpCabecera = this.myForm.value;
      this.getNextFolio(ent).subscribe((folio:number) => {
        ent.Codigo = folio;
        this.blockCombos = true;
        this.setEntity(ent);
        setTimeout(() => {
          const txtFolio: any = this.txtFolio;
          txtFolio.tagInput.nativeElement.focus();
        }, 100);
      });
    }
  }

  onKeyPressFolio(value: any) {
    if (value.keyCode == 13) {
      const txtFolio: any = this.txtObservaciones;
      txtFolio.nativeElement.focus();
    }
  }

  onKeyDownObs(e: any) {
    if (e.keyCode == 9) {
      const txtFolio: any = this.txtObservaciones;
      txtFolio.nativeElement.focus();
    }
  }

  tabFolio(e: any) {
    e.preventDefault();
    this.initEditor(0, 1);
  }


  get permiteGuardar() {
    return (this.myForm.value.Id > 0 && !this.myForm.value.FechaCancelacion) || this.myForm.value.Id == 0;
  }

  get getCancelDate(): string {
    if (this.myForm.value.FechaCancelacion) {
      return moment(this.myForm.value.FechaCancelacion).format("DD/MM/YYYY");
    }
    return "";
  }

  proceedCancel(e: any) {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    const date2 = new Date();
    const d = { year: e.Fecha.getFullYear(), month: e.Fecha.getMonth(), day: e.Fecha.getDate(), hour: date2.getHours(), min: date2.getMinutes(), sec: date2.getSeconds() };
    e.Fecha = new Date(d.year, d.month, d.day, d.hour, d.min, d.sec);
    const date = moment(e.Fecha).format("YYYY-MM-DDTHH:mm:ss");
    const params = new HttpParams()
      .set("id", this.myForm.value.Id)
      .set("motivoCancelacion", e.MotivoCancelacion)
      .set("fechaCancelacion", date);
    this.http.get<Result>(`${this.baseUrl}/CuentasPorPagar/CancelarCxpCabecera`, { params }).subscribe((result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result.success) {
        this.findEntityByParams()
      } else {
        Swal.fire({
          text: result.message, icon: 'info',
        })
      }
    });
  }

  //* 3.- metodos de control de flujo del grid
  //* ////////////////////////////////////////////


  sourceReset(detalle: any = []) {
    if (!detalle) {
      this.source = [];
    } else {
      this.source = detalle;
    }

    this.source!.map((item: CxpDetalle) => {
      if (item.Proveedor) {
        item.ProveedorClave = item.Proveedor!.Clave.toString();
        item.ProveedorNombre = item.Proveedor!.Nombre;
      } else {
        item.ProveedorNombre = '';
      }
      if (item.Cuenta) {
        item.CuentaClave = item.Cuenta.Clave.toString()
        item.CuentaNombre = item.Cuenta.Nombre;
      } else {
        item.CuentaNombre = '';
      }

      if (item.Concepto) {
        item.ConceptoClave = item.Concepto.Clave.toString()
        item.ConceptoNombre = item.Concepto.Nombre;
      } else {
        item.ConceptoNombre = '';
      }
    });

    let totalRegs = 1;
    if (this.source.length < 20) {
      totalRegs = 20;
    }

    for (let index = 0; index < totalRegs; index++) {
      this.source = [...this.source, this.estructuraVacia()]
    }
  }

  estructuraVacia(): any {
    return {
      Id: 0,
      Tipo: '',
      ImporteCargo: 0,
      ImporteAbono: 0,
      SerieOrigina: '',
      FolioOrigina: 0,
      SerieAfecta: '',
      FolioAfecta: 0,
      FechaVencimiento: null,
      Proveedor: null,
      ProveedorClave: 0,
      Cuenta: null,
      CuentaClave: 0,
      CuentaNombre: '',
      Concepto: null,
      ConceptoClave: 0,
      ConceptoNombre: '',
      Usuario: null,
      UUID: '',
      Eliminado: false,
      Baja: false,
    };
  }


  //* eventos para el control del grid

  keyDownRow(e: any, index: number) {
    if (e.ctrlKey && e.keyCode == "46") {
      e.preventDefault();
      +      this.deleteRow(index);
    }
  }

  getOffset(elem: HTMLInputElement): Coordinates {
    var box = elem.getBoundingClientRect();
    var left = window.scrollX !== undefined ? window.scrollX :
      (document.documentElement || document.body.parentNode || document.body).scrollLeft;
    var top = window.scrollY !== undefined ? window.scrollY :
      (document.documentElement || document.body.parentNode || document.body).scrollTop;

    top += elem.offsetHeight;
    return { left: box.left + left, top: box.top + top };
  }

  clicTablaProveedor(e: any, item: any) {
    const t: any = document.activeElement;
    t.value = item.clave;
    const input: any = document.getElementById(`txt_${this.indexEditing}_0${this.idUnico}`)!;
    if (input) {
      input.value = item.clave;
      this.enviarFocoCuenta = true;
    }
  }
  clicTablaCuenta(e: any, item: any) {
    const t: any = document.activeElement;
    t.value = item.Clave;
    const input: any = document.getElementById(`txt_${this.indexEditing}_2${this.idUnico}`)!;
    if (input) {
      input.value = item.Clave;
      this.enviarFocoConcepto = true;
    }
  }

  clicTablaConcepto(e: any, item: any) {
    const t: any = document.activeElement;
    t.value = item.Clave;
    const input: any = document.getElementById(`txt_${this.indexEditing}_4${this.idUnico}`)!;
    if (input) {
      input.value = item.Clave;
      this.enviarFocoSerieOrigina = true;
    }
  }



  buscarCatalogo(e: any, catalogo: string, busquedaCompleta: boolean = false) {
    if ((e.target.value == "" || !isNaN(e.target.value.trim().replace(/\s/g, "")))) {
      //   this.cords = null;
      //   this.sourceAlmacen = [];
      //   this.sourceProducts = [];
      //   this.sourceConcepto = [];
      return;
    }

    if (this.selectedIndex == -1) {
      this.selectedIndex = 0;
    }
    let row = null;
    if (e.keyCode == "38") {
      e.preventDefault();
    }

    if (e.keyCode == "40") {
      e.preventDefault();
    }
    if (row) {
      //row.scrollIntoView({ block: "center" });
    }
    if (e.keyCode == "27" || e.keyCode == "37" || e.keyCode == "39" || e.keyCode == "38" || e.keyCode == "40") {
      return;
    }
    let cords = this.getOffset(e.target);
    cords.top = cords.top - 400;
    cords.left = cords.left - 290;
    this.cords = cords;

    //todo si no ha seleccionado el tipode movimineto o el folio
    //if (!this.myForm.value.Proveedor) { return }
    this.sourceCuenta = [];
    this.sourceProveedor = [];
    this.sourceConcepto = [];
    let valorABuscar = e.target.value;

    if (busquedaCompleta) {
      valorABuscar = "";
    }
    if (catalogo == "Cuenta") {
      this.cxpService.busquedaCuentas(valorABuscar).subscribe((result) => {
        const lista = JSON.parse(result.message);
        this.sourceCuenta = lista;
        if (this.sourceCuenta.length > 0) {
          this.selectedIndex = 0;
          row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`);
          if (row) {
            row.scrollIntoView({ block: "center" });
          }
        } else {
          this.selectedIndex = -1;
        }
      });
    }
    if (catalogo == "Proveedor") {
      this.cService.busquedaTextBox(valorABuscar, "Proveedor").subscribe((result) => {
        const lista = result;
        this.sourceProveedor = lista;
        if (this.sourceProveedor.length > 0) {
          this.selectedIndex = 0;
          row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`);
          if (row) {
            row.scrollIntoView({ block: "center" });
          }
        } else {
          this.selectedIndex = -1;
        }
      });
    }


    if (catalogo == "Concepto") {
      this.cxpService.busquedaConceptos(valorABuscar).subscribe((result) => {
        const lista = JSON.parse(result.message);;
        this.sourceConcepto = lista;
        if (this.sourceConcepto.length > 0) {
          this.selectedIndex = 0;
          row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`);
          if (row) {
            row.scrollIntoView({ block: "center" });
          }
        } else {
          this.selectedIndex = -1;
        }
      });
    }
  }


  escapeProveedor(e: any) {
    let index = this.indexEditing;
    let item: CxpDetalle = this.source[this.indexEditing];
    e.target.value = item.Proveedor ? item.Proveedor.Clave : "";
    this.esEscapeProveedor = true;
    setTimeout(() => {
      this.esEscapeProveedor = true;
      const item = { ...this.source[index] };
      if (!item.Proveedor) {
        e.target.value = "";
      } else {
        e.target.value = item.Proveedor.Clave;
      }
      if (index > 0) {
        this.indexEditing = index - 1;
        this.enviarFocoProveedor = true;
        this.sendFocus();
      } else {
        const txt: any = this.txtFolio;
        txt.tagInput.nativeElement.focus()
      }
    }, 50);
  }


  sendFocus() {
    setTimeout(() => {
      if (this.enviarFocoConcepto) {
        this.initEditor(this.indexEditing, this.colConcepto);
        this.enviarFocoConcepto = false;
      }
      if (this.enviarFocoProveedor) {
        this.initEditor(this.indexEditing, this.colProveedor);
        this.enviarFocoProveedor = false;
      }

      if (this.enviarFocoCuenta) {
        this.initEditor(this.indexEditing, this.colCuenta);
        this.enviarFocoCuenta = false;
      }

      if (this.enviarFocoSerieOrigina) {
        this.initEditor(this.indexEditing, this.colSerieOrigina);
        this.enviarFocoSerieOrigina = false;
      }

      if (this.enviarFocoFolioOrigina) {
        this.initEditor(this.indexEditing, this.colFolioOrigina);
        this.enviarFocoFolioOrigina = false;
      }


      if (this.enviarFocoCargo) {
        this.initEditor(this.indexEditing, this.colCargo);
        this.enviarFocoCargo = false;
      }
      if (this.enviarFocoAbono) {
        this.initEditor(this.indexEditing, this.colAbono);
        this.enviarFocoAbono = false;
      }

      if (this.enviarFocoSerieAfecta) {
        this.initEditor(this.indexEditing, this.colSerieAfecta);
        this.enviarFocoSerieAfecta = false;
      }


      if (this.enviarFocoFolioAfecta) {
        this.initEditor(this.indexEditing, this.colFolioAfecta);
        this.enviarFocoFolioAfecta = false;
      }

      if (this.enviarOtroRenglon) {
        this.indexEditing = this.indexEditing + 1;
        this.enviarOtroRenglon = false;
        this.enviarFocoProveedor = true;
        this.sendFocus();
      }
    }, 50);
  }


  down(e: any, celda: number) {
    e.preventDefault();
    if (celda == 0 && this.sourceProveedor.length > 0) {
      return;
    }
    if (celda == 4 && this.sourceConcepto.length > 0) {
      return;
    }
    if (celda == 2 && this.sourceCuenta.length > 0) {
      return;
    }

    if (celda == 8) {
      this.esEscapeCargo = true;
    }

    let c = this.getLastItem();
    if (this.indexEditing < c - 1) {
      e.target.blur();
      this.indexEditing = this.indexEditing + 1;
      setTimeout(() => {
        this.initEditor(this.indexEditing, celda + 1);
      }, 50);
    } else {
      if (this.indexEditing + 1 < c + 1) {
        //this.esEscapeProveedor = true;
        e.target.blur();
        this.indexEditing = this.indexEditing + 1;
        setTimeout(() => {
          this.source = [...this.source, this.estructuraVacia()];
          this.initEditor(this.indexEditing, this.colProveedor);
        }, 250);
      }
    }
  }

  up(e: any, celda: number) {
    e.preventDefault();
    if (celda == 0 && this.sourceProveedor.length > 0) {
      return;
    }
    if (celda == 4 && this.sourceConcepto.length > 0) {
      return;
    }
    if (celda == 2 && this.sourceCuenta.length > 0) {
      return;
    }

    if (this.indexEditing > 0) {

      if (celda == 2) {
        e.target.blur();
        setTimeout(() => {
          this.indexEditing = this.indexEditing - 1;
          this.initEditor(this.indexEditing, celda + 1);
          this.esEscapeCuenta = false;
        }, 50);
      }

      if (celda == 0) {
        e.target.blur();
        setTimeout(() => {
          this.indexEditing = this.indexEditing - 1;
          this.initEditor(this.indexEditing, celda + 1);
          this.esEscapeProveedor = false;
        }, 50);
      }


      if (celda == 4) {
        e.target.blur();
        setTimeout(() => {
          this.indexEditing = this.indexEditing - 1;
          this.initEditor(this.indexEditing, celda + 1);
          this.esEscapeConcepto = false;
        }, 50);
      }


      if (celda == 6) {
        e.target.blur();
        setTimeout(() => {
          this.indexEditing = this.indexEditing - 1;
          this.initEditor(this.indexEditing, celda + 1);
          this.esEscapeSerieOrigina = false;
        }, 50);
      }

      if (celda == 7) {
        e.target.blur();
        setTimeout(() => {
          this.indexEditing = this.indexEditing - 1;
          this.initEditor(this.indexEditing, celda + 1);
          this.esEscapeFolioOrigina = false;
        }, 50);
      }

      if (celda == 8) {
        this.esEscapeCargo = true;
        e.target.blur();
        setTimeout(() => {
          this.indexEditing = this.indexEditing - 1;
          this.initEditor(this.indexEditing, celda + 1);
        }, 50);
      }

      if (celda == 9) {
        e.target.blur();
        setTimeout(() => {
          this.indexEditing = this.indexEditing - 1;
          this.initEditor(this.indexEditing, celda + 1);
          this.esEscapeAbono = false;
        }, 50);
      }

      if (celda == 10) {
        e.target.blur();
        setTimeout(() => {
          this.indexEditing = this.indexEditing - 1;
          this.initEditor(this.indexEditing, celda + 1);
          this.esEscapeSerieAfecta = false;
        }, 50);
      }


      if (celda == 11) {
        e.target.blur();
        setTimeout(() => {
          this.indexEditing = this.indexEditing - 1;
          this.initEditor(this.indexEditing, celda + 1);
          this.esEscapeFolioAfecta = false;
        }, 50);
      }





    } else {
      this.initEditor(this.indexEditing, celda + 1);
    }
  }

  navegarProveedor(e: any) {
    let row = null;

    if (e.keyCode == 40) {
      if (this.selectedIndex + 1 < this.sourceProveedor.length) {
        this.selectedIndex++;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`)
        row!.scrollIntoView({ block: "center" });

      }
    } else if (e.keyCode == 38) {
      if (this.selectedIndex > 0) {
        this.selectedIndex--;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`)
        row!.scrollIntoView({ block: "center" });
      }
    }
  }

  getLastItem(): number {
    return this.source.filter(P => P.Proveedor).length;
  }



  focusProveedor(index: number, e: any, item: CxpDetalle) {
    let c = this.getLastItem();
    if (index > 0 && index > (c + 1)) {
      return;
    }

    this.setIndexEdit(index, e, item);
    if (e.target.value == "") {
      this.buscarCatalogo(e, "Proveedor", true);
      e.preventDefault();
      return;
    } else {
      this.sourceProveedor = [];
    }

    if (c == 0 && index > 0) {
      e.target.blur();
      return;
    }

    if ((index >= c + 1) && c > 0) {
      e.target.blur();
    }
  }



  setIndexEdit(index: number, e: any, item: CxpDetalle) {

    /*Esto es lo nuevo*/
    //this.indexEditing = -1;


    // if (this.myForm.value.Id != 0) {
    //   this.indexEditing = -1;
    //   e.target.blur();
    //   return;
    // }

    //* si esta cancelado, no deberia de poder editar algo.
    if (this.myForm.value.FechaCancelacion) {
      this.indexEditing = -1;
      e.target.blur();
      return;
    }

    if (this.myForm.value.TipoMovimiento == null || this.myForm.value.TipoMovimiento.Id == 0) {
      this.eventsService.publish('home:showAlert', {
        icon: "fa-triangle-exclamation text-yellow",
        message: "Primero indique el Tipo de Movimiento de Cuentas por Cobrar.", cancelButton: false, onConfirm: (data: AlertResponse) => {
          //todo como enviar el foco al folio o ao tripo de movimeinto
          //this.focusTipoMovimiento();
        }
      });
      this.indexEditing = -1;
      e.target.blur();
      return;
    }

    if (this.myForm.value.Codigo == 0) {
      this.eventsService.publish('home:showAlert', {
        icon: "fa-triangle-exclamation text-yellow",
        message: "Primero indique el Folio del Movimiento de Cuentas por Cobrar.", cancelButton: false, onConfirm: (data: AlertResponse) => {
          //todo como enviar el foco al folio o ao tripo de movimeinto
          //this.focusTipoMovimiento();
        }
      });
      this.indexEditing = -1;
      e.target.blur();
      return;
    }

    this.indexEditing = index;
    e.target.select();
    e.target.classList.add("focus-editor-grid");
  }




  blurProveedor(e: any) {
    let index = this.indexEditing;
    e.target.classList.remove("focus-editor-grid");

    setTimeout(() => {
      this.cords = null;
      if (this.esEscapeProveedor) {
        this.esEnterProveedor = false;
        this.esEscapeProveedor = false;
        return;
      }
      let item = { ...this.source[index] };
      if (item.Proveedor) {
        if (!e.target.value) {
          e.target.value = item.Proveedor.Clave;
          this.enviarFocoProveedor = true;
          this.sendFocus();
          return;
        }

        if (item.Proveedor.Clave != e.target.value) {
          this.buscarProveedor(e.target.value, index);
        } else {
          if (this.esEnterProveedor) {
            if (this.enviarFocoCuenta) {
              this.sendFocus();
            }
          }
        }
      } else {
        if (e.target.value != "") {
          this.buscarProveedor(e.target.value, index);
        } else {
          e.target.classList.remove("focus-editor-grid");
        }
      }
      this.esEnterProveedor = false;
      this.esEscapeProveedor = false;
    }, 100);
  }

  escapeCuenta(e: any) {
    let item: CxpDetalle = this.source[this.indexEditing];
    e.target.value = item.Cuenta ? item.Cuenta.Clave : "";
    this.esEscapeCuenta = true;
    this.initEditor(this.indexEditing, this.colProveedor);
  }

  navegarCuenta(e: any) {
    let row = null;
    if (e.keyCode == 9) {
      e.preventDefault();
      return;
    }
    if (e.keyCode == 40) {
      if (this.selectedIndex + 1 < this.sourceCuenta.length) {
        this.selectedIndex++;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`)
        row!.scrollIntoView({ block: "center" });

      }
    } else if (e.keyCode == 38) {
      if (this.selectedIndex > 0) {
        this.selectedIndex--;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`)
        row!.scrollIntoView({ block: "center" });
      }
    }
  }

  enterCuenta(e: any) {
    this.esEnterCuenta = true;
    this.enviarFocoConcepto = true;
    let value: any = '';
    if (this.sourceCuenta.length > 0) {
      value = this.sourceCuenta[this.selectedIndex].Clave;
    } else {
      value = e.target.value;
    }
    e.target.value = value;
    if (value) {
      e.target.blur();
    }
  }

  enterProveedor(e: any) {
    this.esEnterProveedor = true;
    this.enviarFocoCuenta = true;
    let value: any = '';
    if (this.sourceProveedor.length > 0) {
      value = this.sourceProveedor[this.selectedIndex].clave;
    } else {
      value = e.target.value;
    }
    e.target.value = value;
    if (value) {
      e.target.blur();
    }
  }

  enterConcepto(e: any) {
    this.esEnterConcepto = true;
    this.enviarFocoSerieOrigina = true;
    let value: any = '';
    if (this.sourceConcepto.length > 0) {
      value = this.sourceConcepto[this.selectedIndex].Clave;
    } else {
      value = e.target.value;
    }
    e.target.value = value;
    if (value) {
      e.target.blur();
    }
  }


  blurCuenta(e: any) {
    let index = this.indexEditing;
    e.target.classList.remove("focus-editor-grid");

    setTimeout(() => {
      this.cords = null;
      if (this.esEscapeCuenta) {
        this.esEnterCuenta = false;
        this.esEscapeCuenta = false;
        return;
      }
      let item = { ...this.source[index] };
      if (item.Cuenta) {
        if (!e.target.value) {
          e.target.value = item.Cuenta.Clave;
          this.enviarFocoCuenta = true;
          this.sendFocus();
          return;
        }
        if (item.Cuenta.Clave != e.target.value) {
          this.buscarCuenta(e.target.value, index);
        } else {
          if (this.esEnterCuenta) {
            if (this.enviarFocoConcepto) {
              this.sendFocus();
            }
          }
        }
      } else {
        if (e.target.value != "") {
          this.buscarCuenta(e.target.value, index);
        } else {
          e.target.classList.remove("focus-editor-grid");
        }
      }
      this.esEnterCuenta = false;
      this.esEscapeCuenta = false;
    }, 100);
  }


  focusCuenta(index: number, e: any, item: CxpDetalle) {
    let c = this.getLastItem();
    if (index > 0 && index > (c + 1)) {
      return;
    }

    if (e.target.value == "") {
      this.buscarCatalogo(e, "Cuenta", true);
      e.preventDefault();
    } else {
      this.sourceCuenta = [];
    }
    this.setIndexEdit(index, e, item);
    if (c == 0 && index > 0) {
      e.target.blur();
      return;
    }

    if ((index >= c + 1) && c > 0) {
      e.target.blur();
    }
  }


  navegarConcepto(e: any) {
    let row = null;
    if (e.keyCode == 40) {
      if (this.selectedIndex + 1 < this.sourceConcepto.length) {
        this.selectedIndex++;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`)
        row!.scrollIntoView({ block: "center" });

      }
    } else if (e.keyCode == 38) {
      if (this.selectedIndex > 0) {
        this.selectedIndex--;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`)
        row!.scrollIntoView({ block: "center" });
      }
    }
  }

  escapeConcepto(e: any) {
    let item: CxpDetalle = this.source[this.indexEditing];
    e.target.value = item.Concepto ? item.Concepto.Clave : "";
    this.esEscapeConcepto = true;
    this.initEditor(this.indexEditing, this.colCuenta);
  }



  focusConcepto(index: number, e: any, item: CxpDetalle) {
    let c = this.getLastItem();
    if (index > 0 && index > (c + 1)) {
      return;
    }

    if (e.target.value == "") {
      this.buscarCatalogo(e, "Concepto", true);
      e.preventDefault();
    } else {
      this.sourceConcepto = [];
    }
    this.setIndexEdit(index, e, item);
    if (c == 0 && index > 0) {
      e.target.blur();
      return;
    }

    if ((index >= c + 1) && c > 0) {
      e.target.blur();
    }
  }

  blurConcepto(e: any) {
    let index = this.indexEditing;
    e.target.classList.remove("focus-editor-grid");

    setTimeout(() => {
      this.cords = null;
      if (this.esEscapeConcepto) {
        this.esEnterConcepto = false;
        this.esEscapeConcepto = false;
        return;
      }
      let item = { ...this.source[index] };
      if (item.Concepto) {
        if (!e.target.value) {
          e.target.value = item.Concepto.Clave;
          this.enviarFocoConcepto = true;
          this.sendFocus();
          return;
        }

        if (item.Concepto.Clave != e.target.value) {
          this.buscarConcepto(e.target.value, index);
        } else {
          if (this.esEnterConcepto) {
            if (this.enviarFocoSerieOrigina) {
              this.sendFocus();
            }
          }
        }
      } else {
        if (e.target.value != "") {
          this.buscarConcepto(e.target.value, index);
        } else {
          e.target.classList.remove("focus-editor-grid");
        }
      }
      this.esEnterConcepto = false;
      this.esEscapeConcepto = false;
    }, 100);
  }


  escapeSerieOrigina(e: any) {
    let item = { ...this.source[this.indexEditing] };
    e.target.value = item.SerieOrigina ? item.SerieOrigina : "";
    this.esEscapeSerieOrigina = true;
    this.initEditor(this.indexEditing, this.colConcepto);
  }

  enterSerieOrigina(e: any) {
    this.enviarFocoFolioOrigina = true;
    e.target.blur();
  }

  enterSerieAfecta(e: any) {
    this.enviarFocoFolioAfecta = true;
    e.target.blur();
  }



  blurSerieOrigina(e: any) {
    e.target.classList.remove("focus-editor-grid");
    const serie: string = e.target.value;
    let item = this.source[this.indexEditing];
    if (item.SerieOrigina == serie) {
      if (this.enviarFocoFolioOrigina) {
        this.sendFocus();
      }
      return;
    };
    item.SerieOrigina = serie.toUpperCase();
    this.source[this.indexEditing] = { ...item };
    this.source = [...this.source];
    this.sendFocus();
  }

  blurSerieAfecta(e: any) {
    e.target.classList.remove("focus-editor-grid");
    const serieAfecta: string = e.target.value;
    let item = this.source[this.indexEditing];
    if (item.SerieAfecta == serieAfecta) {
      if (this.enviarFocoFolioAfecta) {
        this.sendFocus();
      }
      return;
    };
    item.SerieAfecta = serieAfecta.toUpperCase();
    this.source[this.indexEditing] = { ...item };
    this.source = [...this.source];
    this.sendFocus();
  }


  enterFolioOrigina(e: any) {
    this.enviarFocoCargo = true;
    e.target.blur();
  }

  escapeFolioOrigina(e: any) {
    let item = { ...this.source[this.indexEditing] };
    e.target.value = item.FolioOrigina ? item.FolioOrigina : 0;
    this.esEscapeFolioOrigina = true;
    this.initEditor(this.indexEditing, this.colSerieOrigina);
  }


  enterFolioAfecta(e: any) {
    this.enviarOtroRenglon = true;
    e.target.blur();
  }




  escapeFolioAfecta(e: any) {
    let item = { ...this.source[this.indexEditing] };
    e.target.value = item.FolioAfecta ? item.FolioAfecta : 0;
    this.esEscapeFolioAfecta = true;
    this.initEditor(this.indexEditing, this.colSerieAfecta);
  }


  escapeSerieAfecta(e: any) {
    let item = { ...this.source[this.indexEditing] };
    e.target.value = item.SerieAfecta ? item.SerieAfecta : "";
    this.esEscapeSerieAfecta = true;
    this.initEditor(this.indexEditing, this.colAbono);
  }



  blurFolioOrigina(e: any) {
    e.target.classList.remove("focus-editor-grid");
    const folio: string = e.target.value;
    let item = this.source[this.indexEditing];
    if (item.FolioOrigina == folio) {
      if (this.enviarFocoCargo) {
        this.sendFocus();
      }
      return;
    };
    item.FolioOrigina = folio;
    this.source[this.indexEditing] = { ...item };
    this.source = [...this.source];
    this.sendFocus();
  }


  escapeCargo(e: any) {
    let item: CxpDetalle = this.source[this.indexEditing];
    e.target.value = item.ImporteCargo ? item.ImporteCargo : 0;
    this.esEscapeCargo = true;
    this.initEditor(this.indexEditing, this.colFolioOrigina);
  }

  escapeAbono(e: any) {
    let item: CxpDetalle = this.source[this.indexEditing];
    e.target.value = item.ImporteAbono ? item.ImporteAbono : 0;
    this.esEscapeAbono = true;
    this.esEnterAbono = false;
    this.initEditor(this.indexEditing, this.colCargo);
  }


  blurFolioAfecta(e: any) {
    e.target.classList.remove("focus-editor-grid");
    if (this.esEscapeFolioAfecta) {
      this.esEscapeFolioAfecta = false;
      return;
    }


    const folio: string = e.target.value;
    let item = this.source[this.indexEditing];
    if (item.FolioOrigina == folio) {
      if (this.enviarOtroRenglon) {
        this.sendFocus();
      }
      return;
    };
    item.FolioAfecta = folio;
    this.source[this.indexEditing] = { ...item };
    this.source = [...this.source];
    this.sendFocus();
  }


  enterCargo(e: any) {
    this.esEnterCargo = true;
    //this.enviarFocoAbono = true;
    e.target.blur();
  }

  enterAbono(e: any) {
    this.esEnterAbono = true;
    this.enviarFocoSerieAfecta = true;
    e.target.blur();
  }

  keyDowncargoTab(e: any) {
    if (e.keyCode == 9) {
      e.preventDefault();
      this.blurCargo(e);
    }
  }



  blurCargo(e: any) {
    if (e.target.value == "") e.target.value = "0";

    e.target.classList.remove("focus-editor-grid");
    const cargo: number = parseFloat(e.target.value);
    let item = this.source[this.indexEditing];

    if (this.esEscapeCargo) {
      this.esEscapeCargo = false;
      return;
    }

    if (parseFloat(String(item.ImporteCargo)) == cargo) {
      if (cargo == 0) {
        this.enviarFocoAbono = true;
      } else {
        item.ImporteAbono = 0;
        this.sourceReset(this.source);
        this.enviarFocoSerieAfecta = true;
      }
      this.sendFocus();
      return;
    }

    item.ImporteCargo = cargo;
    if (cargo > 0) {
      item.ImporteAbono = 0;
    }
    this.source[this.indexEditing] = { ...item };
    this.source = [...this.source];
    if (cargo == 0) {
      this.enviarFocoAbono = true;
    } else {
      this.enviarFocoSerieAfecta = true;
    }
    this.sendFocus();
    this.calcTotales()
  }


  blurAbono(e: any) {
    if (e.target.value == "") e.target.value = "0";
    const abono: number = parseFloat(e.target.value);
    let item = this.source[this.indexEditing];
    if (this.esEscapeAbono) {
      this.esEscapeAbono = false;
      return;
    }

    if (abono == 0 && item.ImporteCargo == 0 && this.esEnterAbono) {
      this.enviarFocoSerieAfecta = false;
      this.enviarFocoAbono = true;
      this.sendFocus();
      return;
    }
    e.target.classList.remove("focus-editor-grid");
    if (parseFloat(String(item.ImporteAbono)) == abono) {
      if (abono == 0 && item.ImporteCargo == 0) {
        this.enviarFocoSerieAfecta = false;
        return;
      }
      if (this.enviarFocoSerieAfecta) {
        this.sendFocus();
      }
      return;
    };



    item.ImporteAbono = abono;
    this.source[this.indexEditing] = { ...item };
    this.source = [...this.source];
    this.sendFocus();
    this.calcTotales()
  }

  deleteRow(indx: number) {

    if (!this.source[indx].Proveedor) {
      return;
    }

    this.eventsService.publish('home:showAlert', {
      message: '¿Desea eliminar el registro?.',
      textAccept: "Sí, eliminar",
      onConfirm: (data: AlertResponse) => {
        if (data.isAccept) {
          const idEliminar = this.source[indx].Id;
          if (idEliminar > 0) {
            this.eventsService.publish('home:isLoading', { isLoading: true });
            this.cxpService.eliminarCxpDetalle(idEliminar).subscribe((result) => {
              this.eventsService.publish('home:isLoading', { isLoading: false });
              this.source.splice(indx, 1);
              this.source = [...this.source];
              this.calcTotales();
              let ent: CxpCabecera = this.myForm.value;
              ent.Detalle?.splice(indx, 1);
              this.myForm.reset(ent);
            })
          } else {
            this.source.splice(indx, 1);
            this.source = [...this.source];
            this.calcTotales();
            let ent: CxpCabecera = this.myForm.value;
            ent.Detalle?.splice(indx, 1);
            this.myForm.reset(ent);
          }
        }
      }
    });
  }

  buscarProveedor(value: string, index: number) {

    if (!value) {
      return;
    };

    if (!this.utilsService.esNumero(value)) {
      value = "0";
    }

    this.loading = true;
    const params = new HttpParams().set("entidad", "Proveedor").set("clave", value).set("filtro", "");
    this.http.get<Proveedor>(`${this.baseUrl}/Base/ObtenerEntidadPorClave`, { params }).subscribe((proveedor) => {
      this.loading = false;
      if (proveedor) {
        let item = this.source[index];
        item.Proveedor = proveedor;
        item.ProveedorClave = proveedor.Clave.toString();
        item.ProveedorNombre = proveedor.Nombre;
        this.sourceReset(this.source);
        //this.initEditor(this.indexEditing, this.colCuenta);
        if (index == this.indexEditing) {
          this.enviarFocoCuenta = true;
          this.sendFocus();
        }
      } else {
        this.eventsService.publish('home:showAlert', {
          textAccept: "Aceptar",
          cancelButton: false,
          message: "No se encontró el Proveedor indicado.",
          onConfirm: (data: AlertResponse) => {
            this.source[index].ProveedorClave = "";
            this.source[index].ProveedorNombre = "";
            this.source[index].Proveedor = null;
            this.source = [...this.source];
            this.initEditor(index, this.colProveedor);
          }
        });
        return;
      }
    });
  }

  buscarCuenta(value: string, index: number) {

    if (!value) {
      return;
    };

    if (!this.utilsService.esNumero(value)) {
      value = "0";
    }


    this.loading = true;
    const params = new HttpParams().set("entidad", "CuentaCxp").set("clave", value).set("filtro", "");
    return this.http.get<CuentaCxp>(`${this.baseUrl}/Base/ObtenerEntidadPorClave`, { params }).subscribe((cuenta) => {
      this.loading = false;
      if (cuenta) {
        let item = this.source[index];
        item.Cuenta = cuenta;
        item.CuentaClave = cuenta.Clave.toString();
        item.CuentaNombre = cuenta.Nombre;
        this.sourceReset(this.source);
        if (index == this.indexEditing) {
          this.enviarFocoConcepto = true;
          this.sendFocus();
        }

      } else {
        this.eventsService.publish('home:showAlert', {
          textAccept: "Aceptar",
          cancelButton: false,
          message: "No se encontró la Cuenta indicada.",
          onConfirm: (data: AlertResponse) => {
            this.source[index].CuentaClave = "";
            this.source[index].Cuenta = null;
            this.source[index].CuentaNombre = "";
            this.source = [...this.source];
            this.initEditor(index, this.colCuenta);
          }
        });
      }
    });
  }

  buscarConcepto(value: string, index: number) {
    this.loading = true;

    if (!value) {
      return;
    };

    if (!this.utilsService.esNumero(value)) {
      value = "0";
    }


    const params = new HttpParams().set("entidad", "ConceptoCxp").set("clave", value).set("filtro", "");
    return this.http.get<ConceptoCxp>(`${this.baseUrl}/Base/ObtenerEntidadPorClave`, { params }).subscribe((concepto) => {
      this.loading = false;
      if (concepto) {
        let item = this.source[index];
        item.Concepto = concepto;
        item.ConceptoClave = concepto.Clave.toString();
        item.ConceptoNombre = concepto.Nombre;
        this.sourceReset(this.source);
        if (index == this.indexEditing) {
          this.enviarFocoSerieOrigina = true;
          this.sendFocus();
        }
        //this.navigateColumns = true;
      } else {
        this.eventsService.publish('home:showAlert', {
          textAccept: "Aceptar",
          cancelButton: false,
          message: "No se encontró el Concepto indicado.",
          onConfirm: (data: AlertResponse) => {
            this.source[index].ConceptoClave = "";
            this.source[index].Concepto = null;
            this.source[index].ConceptoNombre = "";
            this.source = [...this.source];
            this.initEditor(index, this.colConcepto);
          }
        });
      }
    });
  }


  calcTotales() {
    let ent: CxpCabecera = this.myForm.value;
    let cargo: number = 0;
    let abono: number = 0;

    this.source.map((concepto, index) => {
      if (concepto.Proveedor) {
        if (concepto.Proveedor.Id > 0) {
          cargo += concepto.ImporteCargo;
          abono += concepto.ImporteAbono;
        }
      }
    });

    this.totalCargos = cargo;
    this.totalAbonos = abono;
    //this.myFormTotales.get('TotalRegistros')!.setValue(regs);
    this.myForm.reset(ent);
    this.source = [...this.source];
  }

  initEditor(row: number, col: number) {
    console.log(row + ' ' + col);
    col = col - 1;
    const input = document.getElementById(`txt_${row}_${col}${this.idUnico}`)!;
    if (input) input.focus();
  }

  //* 4.- matodos de consulta a el backend


  get esNuevo() {
    return this.myForm.value.Id == 0;
  }

  findEntityByParamsNavigate(type: string) {
    let ent: CxpCabecera = this.myForm.value;
    const params = new HttpParams()
      .set("anio", ent.Anio)
      .set("folio", ent.Codigo)
      .set("idTipoMovto", ent.TipoMovimiento!.Id)
      .set("tipo", type);
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.http.get<CxpCabecera>(`${this.baseUrl}/CuentasPorPagar/ObtenerCxpCabeceraNavegacion`, { params }).subscribe((entity) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      this.setEntity(entity);
    });
  }

  setEntity(entity: CxpCabecera) {
    if (!entity) {
      this.new();
    } else {
      let ent: CxpCabecera = this.myForm.value;
      ent = { ...entity };
      this.sourceReset(ent.Detalle);
      this.calcTotales();
      this.myForm.reset(ent);
    }
  }

  new() {
    const elem: CxpCabecera = this.myForm.value;
    this.cService.getEmptyEntity("CxcCabecera").subscribe((ent: CxpCabecera) => {
      ent.Detalle = [];
      this.sourceReset();
      ent.Empresa = { Id: this.info.empresa?.numero, Clave: this.info.empresa?.clave, Nombre: this.info.empresa?.nombre } as Empresa;
      ent.Sucursal = { Id: this.info.sucursal?.numero, Clave: this.info.sucursal?.clave, Nombre: this.info.sucursal?.nombre } as Sucursal;
      ent.Fecha = new Date();
      ent.Anio = ent.Fecha.getFullYear();
      ent.TipoMovimiento = { Id: this.tipoMovimientoActual.Id, Clave: this.tipoMovimientoActual.Clave, Nombre: this.tipoMovimientoActual.Nombre } as TipoMovimientoCxp;
      this.getNextFolio(ent).subscribe((folio: number) => {
        ent.Codigo = folio;
        this.setEntity(ent);
        const txtFolio: any = this.txtFolio;
        txtFolio.tagInput.nativeElement.value = folio;
        txtFolio.value = folio;
        txtFolio.tagInput.nativeElement.focus();
      });
    });
  }




  getNextFolio(ent: CxpCabecera): Observable<number> {
    const anio: number = ent.Anio;
    const params = new HttpParams().set("anio", anio).set("idTipoMovto", ent.TipoMovimiento!.Id);
    return this.http.get<number>(`${this.baseUrl}/CuentasPorPagar/ObtenerSiguienteClave`, { params });
  }

  save() {

    if (this.saving) return;
    // this.myForm.get('Clave')!.setValue(this.myForm.value.Folio);

    if (!this.permiteGuardar) {
      this.eventsService.publish('home:showAlert', { message: `No es posible realizar modificaciones a la captura de Movimientos de Cuentas por Cobrar.`, cancelButton: false });
      return;
    }

    let ent: CxpCabecera = this.myForm.value;

    if (this.myForm.value.Id == 0) {
      ent.UsuarioCaptura = { Id: this.usuarioActual.numero, Clave: this.usuarioActual.numero, Nombre: this.usuarioActual.nombreUsuario } as Usuario;
      ent.Fecha = new Date();
      ent.Anio = ent.Fecha.getFullYear();
    }

    ent.Detalle = this.source.filter(P => P.Proveedor != null);
    if (ent.Detalle.length == 0) {
      this.eventsService.publish('home:showAlert', { message: `Debe de indicar por lo menos un Movimiento de Cuentas por Pagar`, cancelButton: false });
      return;
    }

    let detalle: any = ent.Detalle.filter(p => p.Proveedor == null);
    if (detalle.length > 0) {
      this.eventsService.publish('home:showAlert', { message: `Debe indicar el Proveedor en el detalle del movimiento.`, cancelButton: false });
      return;
    }

    detalle = ent.Detalle.filter(p => p.Cuenta == null);

    if (detalle.length > 0) {
      this.eventsService.publish('home:showAlert', { message: `Debe indicar la Cuenta en la captura de movimientos.`, cancelButton: false });
      return;
    }

    detalle = ent.Detalle.filter(p => p.Concepto == null);

    if (detalle.length > 0) {
      this.eventsService.publish('home:showAlert', { message: `Debe indicar el Concepto en la captura de movimiento.`, cancelButton: false });

      return;
    }

    detalle = ent.Detalle.filter(p => p.ImporteAbono == 0 && p.ImporteCargo == 0);

    if (detalle.length > 0) {
      this.eventsService.publish('home:showAlert', { message: `Debe indicar un importe de Cargo o Abono en el detalle del movimiento.`, cancelButton: false });

      return;
    }

    ent.Detalle.map(item => {
      if (item.Id == 0) {
        if (item.ImporteCargo > 0) {
          item.Tipo = "C";
        } else {
          item.Tipo = "A";
        }
      }
    })


    this.saving = true;
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.http.post<Result>(`${this.baseUrl}/CuentasPorPagar/GuardarCxpCabecera`, ent).subscribe((result) => {
      this.saving = false;
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result.success) {
        const t: CxpCabecera = JSON.parse(result.message);
        this.myForm.reset(t);
        Swal.fire({ position: 'center', icon: 'success', title: 'Se guardó correctamente', showConfirmButton: false, timer: 1000 }).then(() => {
        });
      } else {
        Swal.fire({ text: `${result.message}`, icon: 'info', })
      }
    })
  }





  activeButtons: ActiveButtons = {
    new: true,
    delete: false,
    return: true,
    save: true,
    first: true,
    left: true,
    right: true,
    last: true,
    search: false,
    print: true,
  }

  onClickBarButton(button: string): void {
    switch (button) {
      case "new": this.new();
        this.blockCombos = false;
        break;
      case "save": this.save(); break;
      case "print": this.print(); break;
      case "first": this.findEntityByParamsNavigate(button); break;
      case "left": this.findEntityByParamsNavigate(button); break;
      case "right": this.findEntityByParamsNavigate(button); break;
      case "last": this.findEntityByParamsNavigate(button); break;
      case "return": this.findEntityByParams();
        this.blockCombos = false;
        setTimeout(() => {
          const txtFolio: any = this.txtFolio;
          txtFolio.tagInput.nativeElement.focus();
        }, 100);
        break;
    }
  }


  print() {

    if (this.myForm.value.Id == 0) {

      this.eventsService.publish('home:showAlert', { message: `Es necesario que primero guarde su captura antes de poder imprimirlo, por favor verifique.`, cancelButton: false });
      return;

      //Swal.fire({ text: `Debe indicar la Cuenta en la captura de movimientos.`, icon: 'error', })

      // Swal.fire({
      //   text: "Es necesario que primero guarde su captura antes de poder imprimirlo, por favor verifique.",
      //   icon: 'warning',
      // });

      return;
    }


    let reportHeader: ReportHeader = {
      Fecha1: this.myForm.get('FechaEmision')?.value,
      Fecha2: this.myForm.get('FechaEmision')?.value,
      NombreReporte: 'VERIFICADOR DE MOVIMIENTOS DE CUENTAS POR COBRAR',
      Dato1: '',
      Opc1: true,
    }
    let filtro: FilterOptions | undefined = this.filtroMovCxc.FilterOptions?.find(p => p.Campo == 'cab.Id');
    if (filtro) {
      filtro!.Valor = String(this.myForm.get('Id')?.value);
    }
    this.filtroMovCxc.ReportHeader = reportHeader;
    this.reportsService.printReport(this.filtroMovCxc, '/CuentasPorCobrar/ReporteCargosyAbonosCXC');
  }

  focoComentarios(value: any) {
    this.utilsService.setHelpLine("Indique un comentario para el movimiento de Traspaso.");
  }



  blurFolio(values: any) {
    if (values.after != values.before) {
      this.findEntityByParams();
    }
  }

  findEntityByParams() {
    const ent: CxpCabecera = this.myForm.value;
    this.eventsService.publish('home:isLoading', { isLoading: true });
    let anio: number = ent.Anio;
    this.cxpService.obtenerCxpCabecera(anio, ent.TipoMovimiento!.Id, ent.Codigo).subscribe((movto) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      this.setEntity(movto);
    });
  }

}
