<app-container-base subTitle="Histórico de Facturas" (onClickBarButton)="imprimir($event)" [activeButtons]="activeButtons"
  [isReport]="true" icon=" fas fa-list-check" [hasCounter]="false">
  <div class="screen-content">
    <form [formGroup]="frmHistoricoFacturas" autocomplete="off">
      <app-panel-base title="Generales">
        <div class="panel-content">
          <div class="row">
            <div class="col-md-12">
              <components-ui-text-box [required]="true" label='Nombre del Reporte' formControlName="NombreReporte"
                [tabIndex]="1" [widthLabelColumns]="2" [widthTextColumns]="10" [maxLength]="100" #txtNombreReporte />
            </div>
          </div>
        </div>
      </app-panel-base>
      <app-panel-base title="Filtros">
        <div class="panel-content">
          <component-ui-selection-filter [(FiltrosSeleccion)]="this.filtrosAuditoriaCredito" [tabIndex]="6"
            (FiltrosSeleccionChange)="changeselec($event)"
            [ReportHeader]="this.frmHistoricoFacturas"></component-ui-selection-filter>
        </div>
      </app-panel-base>
    </form>
  </div>
</app-container-base>
