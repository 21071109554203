import { NgModule } from '@angular/core';
import { ComponentsModule } from '../components/components.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentUiModule } from '../component-ui/component-ui.module';
import { NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { DateBoxComponent } from '../component-ui/components/date-box/date-box.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { GuiGridModule } from '@generic-ui/ngx-grid';
import { NgxEditorModule } from 'ngx-editor';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { TipoPolizaPageComponent } from './pages/tipo-poliza-page/tipo-poliza-page.component';
import { CuentaContablePageComponent } from './pages/cuenta-contable-page/cuenta-contable-page.component';
import { CentroCostoPageComponent } from './pages/centro-costo-page/centro-costo-page.component';
import { ActivoFijoPageComponent } from './pages/activo-fijo-page/activo-fijo-page.component';
import { SeleccionarTreeComponent } from '../component-ui/components/seleccionar-familia-page/seleccionar-familia-page.component';
import { InventarioModule } from '../Inventarios/inventario.module';
import { CapturaPolizaPageComponent } from './pages/captura-poliza-page/captura-poliza-page.component';
import { BusquedaPolizasPageComponent } from './pages/busqueda-polizas-page/busqueda-polizas-page.component';
import { CtrlEdicionPolizaPageComponent } from './pages/ctrl-edicion-poliza-page/ctrl-edicion-poliza-page.component';
import { PolizaAutomaticaPageComponent } from './pages/poliza-automatica-page/poliza-automatica-page.component';
import { RubrosPageComponent } from './pages/rubros-page/rubros-page.component';
import { DiarioGeneralPageComponent } from './pages/diario-general.page/diario-general.page.component';
import { MayorGeneralComponent } from './pages/reportes/mayor-general/mayor-general-page.component';
import { RelacionesAnaliticasComponent } from './pages/reportes/relaciones-analiticas/relaciones-analiticas-page.component';
import { EstadoDeCuentaContablePageComponent } from './pages/estado-de-cuenta-contable-page/estado-de-cuenta-contable-page.component';
import { VerificadorDePolizasPageComponent } from './pages/reportes/verificador-de-polizas-page/verificador-de-polizas-page.component';
import { EfiscoModule } from '../efisco/efisco.module';
import { ReporteEstadoDeCuentaContablePageComponent } from './pages/reporteEstadoDeCuentaContablePage/reporte-estado-de-cuenta-contable-page.component';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { XmlCatalogoCuentasComponent } from './pages/xml-catalogo-cuentas/xml-catalogo-cuentas.component';
import { CtrlEdicionPolizaAutomaticaPageComponent } from './pages/ctrl-edicion-poliza-automatica-page/ctrl-edicion-poliza-automatica-page.component';
import { CalculadoraDepreciacionPageComponent } from './pages/calculadora-depreciacion-page/calculadora-depreciacion-page.component';
import { CtrlBuscadorCfdiComponent } from '../efisco/pages/ctrl-buscador-cfdi/ctrl-buscador-cfdi.component';
import { BalanceGeneralPageComponent } from './pages/reportes/balance-general-page/balance-general-page.component';
import { RubrosERPageComponent } from './pages/rubros-erpage/rubros-erpage.component';
import { XmlBalanzaMensualPageComponent } from './pages/xml-balanza-mensual-page/xml-balanza-mensual-page.component';
import { BalanzaComprobacionPageComponent } from './pages/reportes/balanza-comprobacion-page/balanza-comprobacion-page.component';
import { EstadoResultadosPageComponent } from './pages/reportes/estado-resultados-page/estado-resultados-page.component';
import { BalanceGeneralComparativoComponent } from './pages/reportes/balance-general-comparativo/balance-general-comparativo.component';
import { EdicionEncabezadoPageComponent } from './pages/edicion-encabezado-page/edicion-encabezado-page.component';
import { CapturaDIOTPageComponent } from './pages/captura-diotpage/captura-diotpage.component';


@NgModule({
  imports: [
    ComponentsModule,
    CommonModule,
    FormsModule,
    ComponentUiModule,
    ReactiveFormsModule,
    NgbTimepickerModule,
    DateBoxComponent,
    NgxDaterangepickerMd.forRoot(),
    GuiGridModule,
    NgxEditorModule,
    NgScrollbarModule,
    InventarioModule,
    EfiscoModule,
    DragDropModule
  ],
  exports: [CtrlBuscadorCfdiComponent, CapturaPolizaPageComponent],
  declarations: [
    TipoPolizaPageComponent,
    CuentaContablePageComponent,
    CentroCostoPageComponent,
    ActivoFijoPageComponent,
    CapturaPolizaPageComponent,
    BusquedaPolizasPageComponent,
    CtrlEdicionPolizaPageComponent,
    PolizaAutomaticaPageComponent,
    RubrosPageComponent,
    DiarioGeneralPageComponent,
    CtrlEdicionPolizaPageComponent,
    RelacionesAnaliticasComponent,
    EstadoDeCuentaContablePageComponent,
    VerificadorDePolizasPageComponent,
    MayorGeneralComponent,
    VerificadorDePolizasPageComponent,
    ReporteEstadoDeCuentaContablePageComponent,
    XmlCatalogoCuentasComponent,
    CtrlEdicionPolizaAutomaticaPageComponent,
    CalculadoraDepreciacionPageComponent,
    BalanceGeneralPageComponent,
    RubrosERPageComponent,
    XmlBalanzaMensualPageComponent,
    BalanzaComprobacionPageComponent,
    EstadoResultadosPageComponent,
    BalanceGeneralComparativoComponent,
    EdicionEncabezadoPageComponent,
    CapturaDIOTPageComponent
  ],
  providers: [],
})
export class ContabilidadModule { }
