<div cdkDrag class="data-search card border-1 impresion-productos">
  <div class="card-header" cdkDragHandle>
    <button (click)="close()" type="button" class="btn-close" aria-label="Close"></button>
    <h5>Impresión de Diseños de Producción</h5>
  </div>
  <div class="card-body bg-white-100 p-0">
    <app-container-base [hasBar]="false" [hasCounter]="false" [hasHeader]="false" [containerInnerHeight]="0"
      [isReport]="true" [esPopup]="true">
      <div class="screen-content">
        <div class="panel-content">
          <form [formGroup]="frmImpresionDisenos" autocomplete="off">
            <app-panel-base title="Generales">
              <div class="panel-content">
                <div class="row">
                  <div class="col-md-12">
                    <components-ui-text-box [required]="true" label='Nombre del Reporte' formControlName="NombreReporte"
                      [tabIndex]="1" [widthLabelColumns]="3" [widthTextColumns]="8" [maxLength]="100"
                      #txtNombreReporte />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <component-ui-combo-box label='Estatus' [tabIndex]="4" [zeroMask]="2"
                      [selectManualItem]="selectOpcionManualItem" [setItemsList]="this.listaOpciones"
                      [widthTextColumns]="8" [widthLabelColumns]="3" [inputProperty]="'Nombre'" listProperty='Nombre'
                      (onSelectedItem)="selectedCombo($event)" />
                  </div>
                </div>
              </div>
            </app-panel-base>
            <app-panel-base title="Generales">
              <div class="panel-content">
                <div class="w-100">
                  <component-ui-selection-filter [containerIsModal]="true"
                    [(FiltrosSeleccion)]="this.filtrosImpresionDisenos" [tabIndex]="3"
                    (FiltrosSeleccionChange)="changeselec($event)"></component-ui-selection-filter>
                </div>
                <div class="text-end">
                  <button class="btn btn-info" (click)="print()">
                    <i class="fas fa-print"></i>
                    Imprimir
                  </button>
                </div>
              </div>
            </app-panel-base>
          </form>
        </div>
      </div>
    </app-container-base>
  </div>
</div>
