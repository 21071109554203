<app-container-base subTitle="Estado de Resultados" (onClickBarButton)="clickBoton($event)" [isReport]="true"
  [activeButtons]="activeButtons" icon=" fas fa-arrow-trend-up" [hasCounter]="false">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Generales">
        <div class="panel-content">
          <ng-template #loader>
            <screen-loading />
          </ng-template>
          <div class="row">
            <div class="col-md-8">
              <components-ui-text-box label='Nombre del Reporte' formControlName="NombreReporte" [tabIndex]="1"
                [widthLabelColumns]="3" [widthTextColumns]="8" [maxLength]="100" #txtNombreReporte />
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <component-ui-combo-box (onSelectedItem)="selectedCombo($event,'Mes1')" [selectManualItem]="getMes1"
                [setItemsList]="listaMeses" [listProperty]="'Nombre'" label="Del mes" [tabIndex]="2"
                [inputProperty]="'Nombre'" [widthLabelColumns]="6" [widthTextColumns]="4" #comboMes1 />
            </div>
            <div class="col-md-2">
              <component-ui-combo-box label='Año' [tabIndex]="3" [zeroMask]="2"
                [selectManualItem]="selectAnio1ManualItem" [setItemsList]="this.listaAnios" [widthTextColumns]="6"
                [widthLabelColumns]="4" (onSelectedItem)="selectedCombo($event,'Anio1')" [inputProperty]="'Nombre'"
                [listProperty]="'Nombre'" #comboAnio />
            </div>
            <div class="row">
              <div class="col-md-6">
                <component-ui-combo-box label='Imprimir' [tabIndex]="4" [zeroMask]="2" [selectManualItem]="selectOpcion"
                  [setItemsList]="this.listaOpciones" [widthTextColumns]="6" [widthLabelColumns]="4"
                  (onSelectedItem)="selectedCombo($event,'Num5')" [inputProperty]="'Nombre'"
                  [listProperty]="'Nombre'" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <component-ui-check-box [label]="'Imprimir en Miles'" formControlName="Opc1" [tabIndex]="5"
                  [widthTextColumns]="2" [widthLabelColumns]="6" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <component-ui-check-box [label]="'Reporte comparativo'" formControlName="Opc2" [tabIndex]="6"
                  [widthTextColumns]="2" [widthLabelColumns]="6" (onClick)="clicComparativo($event)" />
              </div>
            </div>
            <div class="col-md-4">
              <component-ui-combo-box (onSelectedItem)="selectedCombo($event,'Mes2')" [selectManualItem]="getMes2"
                [hidden]="!this.myForm.value.Opc2" [setItemsList]="this.listaMeses" [listProperty]="'Nombre'"
                label="Período comparación" [tabIndex]="7" [inputProperty]="'Nombre'" [widthLabelColumns]="6"
                [widthTextColumns]="4" />
            </div>
            <div class="col-md-2">
              <component-ui-combo-box label='Año' [tabIndex]="8" [zeroMask]="2"
                [selectManualItem]="selectAnio2ManualItem" [setItemsList]="this.listaAnios" [widthTextColumns]="6"
                [hidden]="!this.myForm.value.Opc2" [widthLabelColumns]="4"
                (onSelectedItem)="selectedCombo($event,'Anio2')" [inputProperty]="'Nombre'" [listProperty]="'Nombre'" />
            </div>

          </div>
        </div>
      </app-panel-base>
      <app-panel-base title="Generales">
        <div class="panel-content">
          <component-ui-selection-filter [(FiltrosSeleccion)]="this.filtrosReporte" [tabIndex]="9"
            (ReportHeaderChange)="ReportHeaderChange($event)" (FiltrosSeleccionChange)="changeselec($event)"
            [ReportHeader]="this.myForm"></component-ui-selection-filter>
        </div>
      </app-panel-base>
    </form>
  </div>
</app-container-base>
