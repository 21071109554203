import { Component, ElementRef, inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EventsService } from 'src/app/service/events.service';
import { ModalService } from 'src/app/service/modal.service';

@Component({
  selector: 'app-popup-cancelar-transferencia-page',
  templateUrl: './popup-cancelar-transferencia-page.component.html',
  styles: [
  ]
})
export class PopupCancelarTransferenciaPageComponent implements OnInit {


  private eventsService = inject(EventsService);


  public myForm: FormGroup = this.fb.group({
    Fecha: [Date, Validators.required],
    MotivoCancelacion: ['', Validators.required],
  })

  constructor(private fb: FormBuilder,
    private mService: ModalService) {
  }

  accept() {
    if (!this.myForm.value.MotivoCancelacion) {
      this.eventsService.publish('home:showAlert', { message: "Indique el motivo de cancelación.", cancelButton: false });
      return;
    }
    this.mService.closeModal(this.myForm.value);
  }

  closeModal() {
    this.mService.closeModal(null);
  }

  ngOnInit(): void {
    const ent = {
      Fecha: new Date(),
      MotivoCancelacion: '',
      MotivoSAT: { Id: 0 }
    };
    this.myForm.reset(ent);
  }
}
