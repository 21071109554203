import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { PanelBaseDropDownMenu } from '../../interfaces/panelbase.interface';
import { AppSettings } from 'src/app/home/services/app-settings.service';

@Component({
  selector: 'app-panel-base',
  templateUrl: './panel-base.component.html',
  styleUrls: ['./panel-base.component.css']
})

export class PanelBaseComponent {
  @Input()
  public title: string = "titulo"

  @Input()
  public camelCase: boolean = true
  @Input()
  public hasPadding: boolean = true;
  @Input()
  public activeIconEdit: boolean = false;
  @Input()
  public toolTipIcon: string = ""

  @Input()
  public icon: string = ""

  @Input()
  public style: string = ""

  @Input()
  public headerClass: string = "";

  @Input()
  public itemsMenuDropDown: PanelBaseDropDownMenu[] = [];

  @Output()
  public onClicIcon: EventEmitter<any> = new EventEmitter();

  @Output()
  public onClicMenu: EventEmitter<any> = new EventEmitter();
  public appSettings = inject(AppSettings);
  clicIcon() {
    this.onClicIcon.emit();
  }

  get showMenu(){
    return (this.itemsMenuDropDown && this.itemsMenuDropDown.length > 0);
  }

  clicMenu(value:string){

    this.onClicMenu.emit(value);
  }

}
