import { Component, ElementRef, inject, Input, OnInit, ViewChild } from '@angular/core';

import { ModalService } from 'src/app/service/modal.service';
import { FacturacionService } from 'src/app/ventas/services/facturacion.service';
import * as moment from 'moment';
import { EventsService } from 'src/app/service/events.service';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';
import { OrdenManufacturaEnvioProduccion } from 'src/app/Manufactura/interfaces/manufacura.interface';

@Component({
  selector: 'app-popup-listado-ordenes-page',
  templateUrl: './popup-listado-ordenes-page.component.html',
  styles: [
  ]
})
export class PopupListadoOrdenesPageComponent implements OnInit {
  private eventsService = inject(EventsService);
  @ViewChild('modalCancelacion')
  public modalCancelacion!: ElementRef<HTMLElement>;

  @Input()
  public items: any[] = [];
  @Input()
  public soloConsulta: boolean = false;

  ngOnInit(): void {

  }

  cancelar(item: OrdenManufacturaEnvioProduccion) {

    this.eventsService.publish('home:showAlert', {
      message: '¿Está seguro de cancelar la orden de producción?',
      onConfirm: (r: AlertResponse) => {
        if (r.isAccept) {
          const b: any = this.modalCancelacion;
          setTimeout(() => {
            this.ms.openModal(b, (e: any) => {
              if (e) {
                this.proceedCancel(e.MotivoCancelacion, item);
              }
            })
          }, 150);
        }
      }
    });


  }

  proceedCancel(motivo: string, item: OrdenManufacturaEnvioProduccion) {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.fServices.cancelarOrdenEnvioProduccion(item.id, motivo).subscribe((result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result.success) {
        this.imprimir(item);
        setTimeout(() => {
          this.ms.closeModal(true);
        }, 150);
      } else {
        this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
      }
    })
  }

  imprimir(item: OrdenManufacturaEnvioProduccion) {
    this.fServices.imprimirOrdenManufactura(item.idOrdenProduccion, 0, item.id);
  }

  getDate(date: any) {
    if (date)
      return moment(date).format("DD/MM/YYYY");
    return "";
  }

  constructor(private ms: ModalService, private fServices: FacturacionService) { }

  closeModal() {
    this.ms.closeModal(null);
  }

}
