<app-container-base subTitle="Información General Por Cliente" icon=" fas fa-list-check" [hasCounter]="false"
  [hasBar]="false">
  <div class="screen-content">
    <!-- <div class="row w-100">
      <div class="col-xl-12">
        <button type="button" (click)="openProductsSearch()" class="btn btn-orange d-block">Seleccionar Producto</button>
      </div>
    </div> -->
    <div class="row w-100">
      <div class="col-xl-4 pe-0">
        <app-panel-base title="Información General">
          <div class="panel-content">
            <div class="card info-gral">
              <div class="list-group list-group-flush fw-bold">
                <div class="list-group-item d-flex align-items-center">
                  <div class="flex-fill">
                    <div>Clave</div>
                    <div class="text-body text-opacity-80">{{myForm.value.Clave}}</div>
                    <button type="button" (click)="openCustomerSearch()"
                      class="btn btn-orange d-block buscar-producto">Seleccionar Cliente</button>
                  </div>
                </div>
                <div class="list-group-item d-flex align-items-center">
                  <div class="flex-fill">
                    <div>Nombre</div>
                    <div class="text-body text-opacity-80">{{myForm.value.Nombre}}</div>
                  </div>
                </div>
                <div class="list-group-item d-flex align-items-center">
                  <div class="flex-fill">
                    <div>RFC</div>
                    <div class="text-body text-opacity-80">{{myForm.value.RFC}}</div>
                  </div>
                </div>
                <div class="list-group-item d-flex align-items-center">
                  <div class="flex-fill">
                    <div>Vendedor</div>
                    <div class="text-body text-opacity-80">{{myForm.value.Vendedor}}</div>
                  </div>
                </div>
                <!-- <div class="list-group-item d-flex align-items-center">
                  <div class="flex-fill">
                    <div>Última Factura</div>
                    <div class="text-body text-opacity-80">{{myForm.value.UnidadMedida}}</div>
                  </div>
                </div>
                <div class="list-group-item d-flex align-items-center">
                  <div class="flex-fill">
                    <div>Saldo</div>
                    <div class="text-body text-opacity-80">{{ultimaFechaCompra}}</div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </app-panel-base>
      </div>
      <div class="col-xl-8 pe-0">
        <app-panel-base title="Últimas facturas">
          <div class="panel-content">
            <div class="row">
              <div class="col-md-3">
                <span>Del:</span>
                <input type="date" class="form-control mt-1" [value]="myForm.value.fechaInicioString"
                  formControlName="fechaInicio">
              </div>
              <div class="col-md-3">
                <span>Al:</span>
                <input type="date" class="form-control mt-1" [value]="myForm.value.fechaFinString"
                  formControlName="fechaFin">
              </div>
            </div>
            <div style="height: 5px;">

            </div>
            <ng-scrollbar style="height: 135px;">
              <table class="blueTable" style="width: 99.9%;">
                <thead style="position: sticky;top:0;">
                  <tr>
                    <th style="width: 100px;">Sucursal</th>
                    <th style="width: 100px;">Tipo Documento</th>
                    <th style="width: 100px;">Serie-Folio</th>
                    <th style="width: 50px;text-align: right;">Fecha</th>
                    <th style="width: 50px;text-align: right;">Fecha Canc.n</th>
                    <th style="width: 50px;text-align: right;">Total</th>
                  </tr>
                </thead>
                <tbody class="bg-light">
                  <tr *ngFor="let item of listaFacturas">
                    <td>{{item.sucursalNombre}}</td>
                    <td style="text-align: right;">
                      {{item.documentoNombre}}
                    </td>
                    <td style="text-align: right;">
                        {{item.serieFolio}}
                    </td>
                    <td style="text-align: right;">
                      {{item.fechaEmision}}
                    </td>
                    <td style="text-align: right;">
                      {{item.fechaCancelacion?item.fechaCancelacion:''}}
                    </td>
                    <td style="text-align: right;">
                      {{item.total}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </ng-scrollbar>
          </div>
        </app-panel-base>

        <app-panel-base title="Lista de Precios">
          <div class="panel-content">
            <ng-scrollbar style="height: 136px;">
              <table class="blueTable" style="width: 99.9%;">
                <thead style="position: sticky;top:0;">
                  <tr>
                    <th>Clave</th>
                    <th>Nombre</th>
                    <th>Precio Mínimo</th>
                    <th>Precio Máximo</th>
                    <th>Divisor</th>
                    <th>Costo Reposición</th>
                  </tr>
                </thead>
                <tbody class="bg-light">
                  <tr *ngFor="let item of listaPrecios">
                    <td>
                      {{item.listaclave === -1 ? " ": item.listaclave}}
                    </td>
                    <td>{{item.listaNombre}}</td>
                    <td class="text-end">
                      {{item.precioMinimo === -1 ? " ": item.precioMinimo|number:'1.2'}}
                    </td>
                    <td class="text-end">
                      {{item.precioMaximo === -1 ? " ": item.precioMaximo|number:'1.2'}}
                    </td>
                    <td class="text-end">
                      {{item.divisor === -1 ? " ": item.divisor|number:'1.2'}}
                    </td>
                    <td class="text-end">
                      {{item.costoReposicion === -1 ? " ": item.costoReposicion|number:'1.2'}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </ng-scrollbar>
          </div>
        </app-panel-base>
      </div>
    </div>


    <div class="row w-100">
      <div class="col-xl-4 pe-0">


        <app-panel-base title="Proveedores">
          <div class="panel-content">
            <!-- BEGIN #accordion -->
            <div class="accordion" id="accordion">
              <div class="accordion-item border-0" *ngFor="let p of listaProveedores; let  i = index">
                <div class="accordion-header" id="headingTwo">
                  <button class="accordion-button bg-gray-400 text-black px-3 pt-10px pb-10px pointer-cursor collapsed"
                    type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse'+i">
                    <i class="fas fa-cart-flatbed fa-fw me-2 text-success"></i>
                    {{p.nombre}}
                  </button>
                </div>
                <div [id]="'collapse'+i" class="accordion-collapse collapse" data-bs-parent="#accordion">
                  <div class="accordion-body bg-gray-200 p-0">
                    <div class="panel-content">
                      <div class="card info-gral">
                        <div class="list-group list-group-flush fw-bold" style="border-radius: 0px;">
                          <div class="list-group-item d-flex align-items-center p-3px">
                            <div class="flex-fill">
                              <div>Clave</div>
                              <div class="text-body text-opacity-80">
                                {{p.clave}}
                              </div>
                            </div>
                          </div>
                          <div class="list-group-item d-flex align-items-center p-3px">
                            <div class="flex-fill">
                              <div>Nombre</div>
                              <div class="text-body text-opacity-80">
                                {{p.nombre}}
                              </div>
                            </div>
                          </div>
                          <div class="list-group-item d-flex align-items-center p-3px">
                            <div class="flex-fill">
                              <div>RFC</div>
                              <div class="text-body text-opacity-80">
                                {{p.rfc}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- END #accordion -->
          </div>
        </app-panel-base>

      </div>
    </div>
    <ng-template #busquedaProductos let-modal let-c="close">
      <app-data-search titulo="Búsqueda de Producto" [columns]="getCustomerColumns" [entityName]="'Cliente'"
        [filter]="''" [propertys]="getCustomerPropertys" [orders]="'Nombre|asc'" #busquedaProductos />
    </ng-template>

  </div>
</app-container-base>
