<div class="card text-center border-0" cdkDrag style="width: 600px;box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;">
  <div style="cursor: move;" cdkDragHandle class="card-header fw-bold">Indique las Formas de Pago del Documento</div>
  <app-panel-base title="Formas de Pago">
    <div class="panel-content">


      <ng-scrollbar cdkVirtualScrollViewport style="height: 300px;border-bottom: 1px solid #AAAAAA;">
        <table class="blueTable" style="width:550px !important;">
          <thead style="position: sticky;top:0;">
            <tr>
              <th style="width: 50px;">
              </th>
              <th style="width: 120px;text-align: center;">F/Pago</th>
              <th style="width: 80px; text-align: right;padding-right: 5px;">Importe</th>
              <th style="width: 100px;text-align: center;">Tarjeta</th>
              <th style="width: 100px;text-align: center;">Cheque</th>
              <th style="width: 100px;text-align: center;">Transf</th>
            </tr>
          </thead>
          <tbody class="bg-light">
            <tr *ngFor="let item of source; let i = index;">
              <td style="text-align: center;">
                <i (click)="deleteRow(i)" *ngIf="item.Importe>0 && !blockPayment" class="fa fa-rectangle-xmark fa-2x"
                  style="color:red;font-weight: bold; cursor: pointer;"></i>
              </td>
              <td>
                <select [disabled]="this.blockPayment" (change)="selectPayment(i, $event)"
                  class="form-select form-select-sm"
                  style=" width: 153px; font-size: 13px !important; height: 20px; border: 0px; padding: 0; padding-left: 10px;"
                  name="pagos" id="fps">
                  <option *ngFor="let itemr of this.payments"
                    selected="{{(itemr.Id == (item.FormaPago ? item.FormaPago.Id:0))?'selected':''}}"
                    value="{{itemr.Id}}">
                    {{itemr.Nombre}}</option>
                </select>
              </td>
              <td>
                <input *ngIf="item.FormaPago" (focus)="focusImporte($event)" class="grid-editor text-dark"
                  (keydown.enter)="enter($event)" (blur)="blurImporte($event, i)"
                  [style]="'width:80px;text-align:right;height: 22px;'" [value]="item.Importe" />
              </td>
              <td style="text-align: center;">
                <div (click)="editarTarjeta(i)"
                  *ngIf="(item.FormaPago?.Clave == 4 || item.FormaPago?.Clave == 28) && item.Importe>0"
                  [class]="'btn btn-'+(item.DatosTarjeta ?'success':'danger')+' btn-xs'">
                  {{(item.DatosTarjeta ?'Editar':'Capturar')}}
                </div>
              </td>
              <td style="text-align: center;">
                <div (click)="editarCheque(i)" *ngIf="item.FormaPago?.Clave == 2 && item.Importe>0"
                  [class]="'btn btn-'+(item.DatosCheque ?'success':'danger')+' btn-xs'">
                  {{(item.DatosCheque ?'Editar':'Capturar')}}
                </div>
              </td>
              <td style="text-align: center;">
                <div (click)="editarTransferencia(i)" *ngIf="item.FormaPago?.Clave == 3 && item.Importe>0"
                  [class]="'btn btn-'+(item.DatosTransferencia ?'success':'danger')+' btn-xs'">
                  {{(item.DatosTransferencia ?'Editar':'Capturar')}}
                </div>
              </td>
            </tr>

          </tbody>
        </table>
      </ng-scrollbar>
      <div style="text-align: right; margin-top: 5px;">
        <h5>Total capurado: {{getTotalCapturado |number:'1.2-6'}}</h5>
        <h5>Total anticipo: {{anticipoAplicar |number:'1.2-6'}}</h5>
        <hr>
        <h5>Total documento: {{total|number:'1.2-6'}}</h5>
        <button (click)="accept()" class="btn btn-success" style="margin-right: 5px;">Aceptar</button>
        <button (click)="closeModal()" class="btn btn-danger">Cancelar</button>
      </div>
    </div>
    <ng-template #edicionTarjeta let-modal let-c="close">
      <app-datos-tarjeta-page [item]="this.datosTarjeta" [idSucursal]="idSucursal" />
    </ng-template>
    <ng-template #edicionTransferencia let-modal let-c="close">
      <app-datos-transferencia-page [item]="this.datosTransferencia" [idSucursal]="idSucursal" />
    </ng-template>
    <ng-template #edicionCheque let-modal let-c="close">
      <app-datos-cheque-page [item]="this.datosCheque" [idSucursal]="idSucursal" />
    </ng-template>
  </app-panel-base>
</div>
