import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ComboBoxEntity } from '../interfaces/combo-text.interface';
import { startOfDay } from 'date-fns';
import { Result } from 'src/app/auth/interfaces';
import { SeleccionEmpresa } from 'src/app/components/interfaces/seleccion-empresa.interface';


const WIKI_URL = 'https://en.wikipedia.org/w/api.php';
const PARAMS = new HttpParams({
  fromObject: {
    action: 'opensearch',
    format: 'json',
    origin: '*',
  },
});
@Injectable({
  providedIn: 'root'
})
export class ContainerBaseService {

  private readonly baseUrl: string = environment.baseUrlApi;

  constructor(private http: HttpClient) {

  }

  public b64toBlob = (b64Data: any, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  saveEntity(name: string, value: string, isAsync: boolean = false): Observable<any> {
    const params = {
      nombreEntidad: name,
      entidad: value
    }
    let method = `GuardarEntidad${isAsync ? "Async" : ""}`;
    return this.http.post<any>(`${this.baseUrl}/Base/${method}`, params);
  }

  deleteEntity(name: string, id: number): Observable<string> {
    const params = new HttpParams().set("nombreEntidad", name).set("id", id);
    return this.http.get<string>(`${this.baseUrl}/Base/EliminarEntidad`, { params });
  }

  obtenerValorPropiedad(nombreEntidad: string, propiedad: string, id: number): Observable<string> {
    const params = new HttpParams()
      .set("entidad", nombreEntidad)
      .set("propiedad", propiedad)
      .set("id", id);
    return this.http.get<string>(`${this.baseUrl}/Base/ObtenerValorEntidad`, { params });
  }

  searchItemsByFields(name: string, filters: string, fields: string, extraFields: string, order: string, isBusquedaInput: boolean = false): Observable<string> {
    const params = new HttpParams()
      .set("entidad", name)
      .set("filtros", filters)
      .set("campos", fields)
      .set("filtroExtra", extraFields)
      .set("ordenado", order)
      .set("totalResultado", isBusquedaInput ? 10 : 500);
    return this.http.get<string>(`${this.baseUrl}/Base/BusquedaEntidad`, { params });
  }

  getCounter(name: string, filter: string = ''): Observable<number> {
    const params = new HttpParams().set("entidad", name).set("filter", filter);
    return this.http.get<number>(`${this.baseUrl}/Base/ObtenerTotalRegistros`, { params });
  }

  getNextNumber(name: string, filter: string = ''): Observable<string> {
    const params = new HttpParams().set("nombreEntidad", name).set("filter", filter);
    return this.http.get<string>(`${this.baseUrl}/Base/ObtenerSiguienteClave`, { params });
  }

  getNavigateItem(name: string, type: string, value: string): Observable<string> {
    const params = new HttpParams().set("nombreEntidad", name).set("tipo", type).set("claveActual", value);
    return this.http.get<string>(`${this.baseUrl}/Base/ObtenerEntidadNavegacion`, { params });
  }

  getEmptyEntity(name: string, filter: string = ''): Observable<any> {
    const params = new HttpParams().set("nombreEntidad", name)
    return this.http.get<any>(`${this.baseUrl}/Base/ObtenerEntidadVacia`, { params });
  }



  getEmptyEntityNoJson(name: string, filter: string = ''): Observable<any> {
    const params = new HttpParams().set("nombreEntidad", name)
    return this.http.get<any>(`${this.baseUrl}/Base/ObtenerEntidadVaciaNoJson`, { params });
  }

  getLastEntity(name: string, filter: string = ''): Observable<any> {
    console.log("desde el metodo")
    const params = new HttpParams().set("entidad", name).set('filter', filter)
    return this.http.get<any>(`${this.baseUrl}/Base/ObtenerUltimaEntidad`, { params });
  }

  getEntityByClave(name: string, clave: any, filter: string = ''): Observable<any> {
    const params = new HttpParams().set("entidad", name).set("clave", clave).set("filtro", filter);
    return this.http.get<any>(`${this.baseUrl}/Base/ObtenerEntidadPorClave`, { params });
  }

  getEntityById(name: string, id: number): Observable<any> {
    const params = new HttpParams().set("entidad", name).set("id", id);
    return this.http.get<any>(`${this.baseUrl}/Base/ObtenerEntidadPorId`, { params });
  }

  getItemsByName(entity: string, aplicarPermisos: boolean = false, esCatalago: boolean = false): Observable<ComboBoxEntity[]> {
    const params = new HttpParams()
      .set("entidad", entity)
      .set("aplicarPermisos", aplicarPermisos)
      .set("esCatalago", esCatalago);
    return this.http.get<ComboBoxEntity[]>(`${this.baseUrl}/Base/ObtenerListaCombo`, { params });
  }
  printEntity(entity: string, exportar: boolean): Observable<Result> {
    const params = new HttpParams().set("entidad", entity).set("exportar", exportar ? 1 : 0)
    return this.http.get<Result>(`${this.baseUrl}/Base/ImprimirEntidad`, { params });
  }

  busquedaTextBox(filtro: string, entidad: string) {
    const params = new HttpParams().set("filtro", filtro).set("entidad", entidad);
    return this.http.get<any>(`${this.baseUrl}/Base/BusquedaTextBox`, { params });
  }
}
