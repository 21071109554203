import { Component } from '@angular/core';

@Component({
  selector: 'app-utilerias-complit-page',
  templateUrl: './utilerias-complit-page.component.html',
  styleUrls: ['./utilerias-complit-page.component.scss']
})
export class UtileriasComplitPageComponent {
  descargarQZ() {
    window.open("https://stcompliterpprod.blob.core.windows.net/utilerias/qz-tray.exe?sp=r&st=2024-09-04T18:06:13Z&se=2050-09-05T02:06:13Z&sv=2022-11-02&sr=b&sig=8yPLbhgmiQxp7JVQIxJqRmEAD8rd9o51XUPGdCrWrJs%3D")
  }
}
