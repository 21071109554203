<app-container-base subTitle="Conciliación Manual de Sistema vs Banco" [hasBar]="false" [hasCounter]="false"
  icon=" fas fa-bars-staggered">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <div class="d-flex">
        <div style="width:1400px;">
          <app-panel-base [title]="'Información de la conciliación en curso'" [camelCase]="false">
            <div class="panel-content">
              <div class="d-flex mb-3">
                <a>
                  <i class="fas fa-cog fa-5x"></i>
                </a>
                <div class="ps-3">
                  <div class="email-from text-dark fs-14px mb-3px fw-bold">
                    Configuración de conciliación
                  </div>
                  <div class="mb-3px"><i class="fa fa-clock fa-fw"></i> <span class="text-dark">{{titulo}}</span></div>
                </div>
              </div>
              <hr>

              <div class="d-flex">
                <div style="width: 50%;" class="me-4">
                  <h5 class="text-center">NOSOTROS</h5>
                  <div class="col-12 ms-3" style="padding-right: 30px;">
                    <div class=" text-dark">Porcentaje conciliado</div>
                    <div class="fs-18px mb-5px fw-bold" data-animation="number" data-value="1568">
                      {{getNosotrosConciliados | number}} Registos Conciliados ({{getNosotrosConciliadosPorcentaje |
                      number:'1.2'}}%)
                    </div>
                    <div class="progress h-5px rounded-3 bg-gray-400 mb-5px">
                      <div class="progress-bar progress-bar-striped rounded-right bg-green" data-animation="width"
                        data-value="55%" [style]="'width: '+getNosotrosConciliadosPorcentaje+'%;'"></div>
                    </div>
                  </div>
                  <div class="card-body">
                    <table class="table table-bordered table-striped table-sm fw-bold">
                      <tbody>
                        <tr>
                          <td class="w-100px text-end">Anterior:</td>
                          <td class="text-end">$ {{myForm.value.AnteriorNosotros | number:'1.2'}}</td>
                        </tr>
                        <tr>
                          <td class="text-end">Cargos:</td>
                          <td class="text-end">$ {{myForm.value.CargosNosotros | number:'1.2'}}</td>
                        </tr>
                        <tr>
                          <td class="text-end">Abonos:</td>
                          <td class="text-end">$ {{myForm.value.AbonosNosotros | number:'1.2'}}</td>
                        </tr>
                        <tr>
                          <td class="text-end">Actual:</td>
                          <td class="text-end">$ {{myForm.value.ActualNosotros | number:'1.2'}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div style="width: 50%;" class="me-4">
                  <h5 class="text-center">ELLOS</h5>
                  <div class="col-12 ms-3" style="padding-right: 30px;">
                    <div class=" text-dark">Porcentaje conciliado</div>
                    <div class="fs-18px mb-5px fw-bold" data-animation="number" data-value="1568">{{getEllosConciliados
                      | number}} Registos Conciliados ({{getEllosConciliadosPorcentaje | number:'1.2'}}%)</div>
                    <div class="progress h-5px rounded-3 bg-gray-400 mb-5px">
                      <div class="progress-bar progress-bar-striped rounded-right bg-green" data-animation="width"
                        data-value="55%" [style]="'width: '+getEllosConciliadosPorcentaje+'%;'"></div>
                    </div>
                  </div>
                  <div class="card-body">
                    <table class="table table-bordered table-striped table-sm fw-bold m-0">
                      <tbody>
                        <tr>
                          <td class="w-100px text-end">Anterior:</td>
                          <td class="text-end">$ {{myForm.value.AnteriorEllos | number:'1.2'}}</td>
                        </tr>
                        <tr>
                          <td class="text-end">Cargos:</td>
                          <td class="text-end">$ {{myForm.value.CargosEllos | number:'1.2'}}</td>
                        </tr>
                        <tr>
                          <td class="text-end">Abonos:</td>
                          <td class="text-end">$ {{myForm.value.AbonosEllos | number:'1.2'}}</td>
                        </tr>
                        <tr>
                          <td class="text-end">Actual:</td>
                          <td class="text-end">$ {{myForm.value.ActualEllos | number:'1.2'}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="d-flex">
                <div style="width: 50%;" class="pe-1">
                  <div class="btn-group" style="width: 100%;padding-right: 26px;">
                    <div (click)="seleccionNosotrosFiltro(1)"
                      [class]="'btn btn-white ' + (seleccionadoNosotros==1? 'active':'') ">Todos</div>
                    <div (click)="seleccionNosotrosFiltro(2)"
                      [class]="'btn btn-white ' + (seleccionadoNosotros==2? 'active':'') ">Conciliados</div>
                    <div (click)="seleccionNosotrosFiltro(3)"
                      [class]="'btn btn-white ' + (seleccionadoNosotros==3? 'active':'') ">No Conciliados</div>
                    <div (click)="seleccionNosotrosFiltro(4)"
                      [class]="'btn btn-white ' + (seleccionadoNosotros==4? 'active':'') ">Desconciliar</div>
                    <div (click)="seleccionNosotrosFiltro(5)"
                      [class]="'btn btn-white ' + (seleccionadoNosotros==5? 'active':'') ">Buscar</div>
                  </div>
                  <ng-scrollbar style="height: 400px; border-bottom: 1px solid #AAAAAA;">
                    <table class="blueTable" style="width:640px;">
                      <thead style="position: sticky;top:0;">
                        <tr>
                          <th style="width: 30px;">Sel</th>
                          <th
                            [style]="'cursor: pointer;width:50px !important;'+((lastOrderNosotros=='tipoNumeroPoliza'?'background-color:orange;color:#ffffff;':''))"
                            (click)="ordenarNosotros('tipoNumeroPoliza')" class="px-10px">Pól
                            <i *ngIf="lastOrderNosotros=='tipoNumeroPoliza'"
                              [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAscNosotros=='asc'?'down':'up')+'-wide-short float-end'"></i>
                          </th>
                          <th
                            [style]="'cursor: pointer;width:70px !important;'+((lastOrderNosotros=='fecha'?'background-color:orange;color:#ffffff;':''))"
                            (click)="ordenarNosotros('fecha')" class="px-10px">Fecha
                            <i *ngIf="lastOrderNosotros=='fecha'"
                              [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAscNosotros=='asc'?'down':'up')+'-wide-short float-end'"></i>
                          </th>
                          <th
                            [style]="'cursor: pointer;width:100px !important;'+((lastOrderNosotros=='referencia'?'background-color:orange;color:#ffffff;':''))"
                            (click)="ordenarNosotros('referencia')" class="px-10px">Referencia
                            <i *ngIf="lastOrderNosotros=='referencia'"
                              [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAscNosotros=='asc'?'down':'up')+'-wide-short float-end'"></i>
                          </th>
                          <th
                            [style]="'cursor: pointer;width:200px !important;'+((lastOrderNosotros=='concepto'?'background-color:orange;color:#ffffff;':''))"
                            (click)="ordenarNosotros('concepto')" class="px-10px">Concepto
                            <i *ngIf="lastOrderNosotros=='concepto'"
                              [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAscNosotros=='asc'?'down':'up')+'-wide-short float-end'"></i>
                          </th>
                          <th
                            [style]="'cursor: pointer;width:100px !important;'+((lastOrderNosotros=='importeCargo'?'background-color:orange;color:#ffffff;':''))"
                            (click)="ordenarNosotros('importeCargo')" class="px-10px">Cargo
                            <i *ngIf="lastOrderNosotros=='importeCargo'"
                              [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAscNosotros=='asc'?'down':'up')+'-wide-short float-end'"></i>
                          </th>
                          <th
                            [style]="'cursor: pointer;width:100px !important;'+((lastOrderNosotros=='importeAbono'?'background-color:orange;color:#ffffff;':''))"
                            (click)="ordenarNosotros('importeAbono')" class="px-10px">Abono
                            <i *ngIf="lastOrderNosotros=='importeAbono'"
                              [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAscNosotros=='asc'?'down':'up')+'-wide-short float-end'"></i>
                          </th>

                        </tr>
                      </thead>
                      <tbody class="bg-light" style="font-family: math;">
                        <tr *ngFor="let item of listaFiltradaNosotros; let i = index" [id]="'row_nosotros_' + i"
                          [style]="obtenerEstiloSeleccionado(item)">
                          <td class="text-center" style="width:30px !important;">
                            <input (click)="seleccionarNosotros(i)" class="form-check-input" type="checkbox"
                              [checked]="item.seleccionado" value="">
                          </td>
                          <td class="text-black text-center" style="width:50px;"> {{ item.tipoNumeroPoliza| uppercase }}
                          </td>
                          <td class="text-black text-center" style="width:70px;"> {{ item.fecha | date:'dd/MM/yyyy' }}
                          </td>
                          <td class="text-black" style="width:100px;"> {{ item.referencia| uppercase }}</td>
                          <td class="text-black" style="width:200px;"> {{ item.concepto | uppercase }}</td>
                          <td class="text-black text-end" style="width:80px;"> {{ item.importeCargo | number:'1.2-6' }}
                          </td>
                          <td class="text-black text-end" style="width:80px;"> {{ item.importeAbono | number:'1.2-6' }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </ng-scrollbar>
                </div>
                <div style="width: 50%;">
                  <div class="btn-group" style="width: 100%;padding-right: 30px;">
                    <div (click)="seleccionEllosFiltro(1)"
                      [class]="'btn btn-white ' + (seleccionadoEllos==1? 'active':'')">Todos</div>
                    <div (click)="seleccionEllosFiltro(2)"
                      [class]="'btn btn-white ' + (seleccionadoEllos==2? 'active':'')">Conciliados</div>
                    <div (click)="seleccionEllosFiltro(3)"
                      [class]="'btn btn-white ' + (seleccionadoEllos==3? 'active':'')">No Conciliados</div>
                    <!-- <div (click)="seleccionEllosFiltro(4)"
                      [class]="'btn btn-white ' + (seleccionadoEllos==4? 'active':'')">Desconciliar</div> -->
                    <div (click)="seleccionEllosFiltro(5)"
                      [class]="'btn btn-white ' + (seleccionadoEllos==5? 'active':'')">Buscar</div>
                  </div>
                  <ng-scrollbar style="height: 400px; border-bottom: 1px solid #AAAAAA;">
                    <table class="blueTable" style="width:650px;">
                      <thead style="position: sticky;top:0;">
                        <tr>
                          <th style="width: 30px;">Sel</th>
                          <th
                            [style]="'cursor: pointer;width:70px !important;'+((lastOrderEllos=='fecha'?'background-color:orange;color:#ffffff;':''))"
                            (click)="ordenarEllos('fecha')" class="px-10px">Fecha
                            <i *ngIf="lastOrderEllos=='fecha'"
                              [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAscEllos=='asc'?'down':'up')+'-wide-short float-end'"></i>
                          </th>
                          <th
                            [style]="'cursor: pointer;width:100px !important;'+((lastOrderEllos=='referencia'?'background-color:orange;color:#ffffff;':''))"
                            (click)="ordenarEllos('referencia')" class="px-10px">Referencia
                            <i *ngIf="lastOrderEllos=='referencia'"
                              [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAscEllos=='asc'?'down':'up')+'-wide-short float-end'"></i>
                          </th>
                          <th
                            [style]="'cursor: pointer;width:200px !important;'+((lastOrderEllos=='concepto'?'background-color:orange;color:#ffffff;':''))"
                            (click)="ordenarEllos('concepto')" class="px-10px">Concepto
                            <i *ngIf="lastOrderEllos=='concepto'"
                              [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAscEllos=='asc'?'down':'up')+'-wide-short float-end'"></i>
                          </th>
                          <th
                            [style]="'cursor: pointer;width:100px !important;'+((lastOrderEllos=='importeCargo'?'background-color:orange;color:#ffffff;':''))"
                            (click)="ordenarEllos('importeCargo')" class="px-10px">Cargo
                            <i *ngIf="lastOrderEllos=='importeCargo'"
                              [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAscEllos=='asc'?'down':'up')+'-wide-short float-end'"></i>
                          </th>
                          <th
                            [style]="'cursor: pointer;width:100px !important;'+((lastOrderEllos=='importeAbono'?'background-color:orange;color:#ffffff;':''))"
                            (click)="ordenarEllos('importeAbono')" class="px-10px">Abono
                            <i *ngIf="lastOrderEllos=='importeAbono'"
                              [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAscEllos=='asc'?'down':'up')+'-wide-short float-end'"></i>
                          </th>

                        </tr>
                      </thead>
                      <tbody class="bg-light" style="font-family: math;">
                        <tr *ngFor="let item of listaFiltradaEllos let i = index" [id]="'row_ellos_' + i"
                          [style]="obtenerEstiloSeleccionado(item)">
                          <td class="text-center" style="width:30px !important;">
                            <input (click)="seleccionarEllos(i)" class="form-check-input" type="checkbox"
                              [checked]="item.seleccionado" value="">
                          </td>
                          <td class="text-black text-center" style="width:70px;"> {{ item.fecha | date:'dd/MM/yyyy' }}
                          </td>
                          <td class="text-black" style="width:150px;"> {{ item.referencia| uppercase }}</td>
                          <td class="text-black" style="width:200px;"> {{ item.concepto | uppercase }}</td>
                          <td class="text-black text-end" style="width:100px;"> {{ item.importeCargo | number:'1.2-6' }}
                          </td>
                          <td class="text-black text-end" style="width:100px;"> {{ item.importeAbono | number:'1.2-6' }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </ng-scrollbar>
                </div>
              </div>
            </div>
          </app-panel-base>
          <app-panel-base title="Totales por conciliar">
            <div class="panel-content">
              <div class="d-flex" style="width:100%;flex-direction: column;padding: 10px;">

                <div class="btn btn-sm btn-white mb-10px me-2"><i
                    class="fa fa-print t-plus-1 text-blue-500 fa-fw fa-lg"></i> Imprimir resumen de conciliación </div>

                <div class="row">
                  <div class="col-xl-3 col-md-3">
                    <div class="widget widget-stats bg-green-300">
                      <div class="stats-icon"></div>
                      <div class="stats-info">
                        <h4>Cargos por Conciliar Nosotros</h4>
                        <p>{{getConciliarCargosNosotros | number:'1.2'}}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-3 col-md-3">
                    <div class="widget widget-stats bg-green-300">
                      <div class="stats-icon"></div>
                      <div class="stats-info">
                        <h4>Abonos por Conciliar Nosotros</h4>
                        <p>{{getConciliarAbonosNosotros | number:'1.2'}}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-3 col-md-3">
                    <div class="widget widget-stats bg-info">
                      <div class="stats-icon"></div>
                      <div class="stats-info">
                        <h4>Cargos por Conciliar Ellos</h4>
                        <p>{{getConciliarCargosEllos | number:'1.2'}}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-3 col-md-3">
                    <div class="widget widget-stats bg-info">
                      <div class="stats-icon"></div>
                      <div class="stats-info">
                        <h4>Abonos Por Conciliar Ellos</h4>
                        <p>{{getConciliarAbonosEllos | number:'1.2'}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </app-panel-base>
        </div>
      </div>

    </form>
  </div>
</app-container-base>
