import { Injectable, inject } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ConexionBaseDatos, Result } from '../interfaces';
import { Observable, catchError, throwError, tap, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BaseDatosService {
  private readonly baseUrl: string = environment.baseUrlApi;
  private http = inject(HttpClient);
  constructor() { }

  getListaBD() {
    return this.http.get<ConexionBaseDatos[]>(`${this.baseUrl}/baseDatos/getListaBD`);
  }

  getCheckBD(conn: any) {
    let options = { headers: new HttpHeaders({ 'hostFrontEnd': window.location.host }) };
    return this.http.post<Result>(`${this.baseUrl}/baseDatos/checkConnBD`, conn, options)
  }

  guardarBD(conn: ConexionBaseDatos): Observable<boolean> {
    let options = { headers: new HttpHeaders({ 'hostFrontEnd': window.location.host }) };
    let url = `${this.baseUrl}/baseDatos/guardarBD`;
    return this.http.post(url, conn, options)
      .pipe(
        tap((res: any) => {
          console.log(res);
        }),
        map(() => true),
        catchError(err => throwError(() => err.error))
      );
  }

  actualizarBD(ambiente: ConexionBaseDatos): Observable<Result> {
    return this.http.post<Result>(`${this.baseUrl}/baseDatos/actualizarBD`, ambiente);
  }
}
