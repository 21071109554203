import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MovimientoPoliza, MovimientoPolizaCabecera } from '../interfaces/movimientopoliza.interface';
import { CuentaContable } from '../interfaces/cuentacontable.interface';
import { Result } from 'src/app/auth/interfaces';
import { ItemEstadoCuentaContable, ItemEstadoCuentaContableMovimientos } from '../interfaces/polizaautomatica.interface';
import { TipoPoliza } from '../interfaces/tipopoliza.interface';

@Injectable({ providedIn: 'root' })
export class ContabilidadService {
  constructor(private http: HttpClient) {

  }
  private readonly baseUrl: string = environment.baseUrlApi;

  agregarCuentaRubro(idCuenta: number, idRubro: number): Observable<Result> {
    const params = new HttpParams().set("idCuenta", idCuenta).set("idRubro", idRubro);
    return this.http.get<Result>(`${this.baseUrl}/Contabilidad/AgregarCuentaRubro`, { params });
  }

  actualizarOrdenados(ids: string): Observable<Result> {
    const params = new HttpParams().set("ids", ids);
    return this.http.get<Result>(`${this.baseUrl}/Contabilidad/ActualizarOrdenados`, { params });
  }


  obtenerTiposPolizas(): Observable<TipoPoliza[]> {
    return this.http.get<TipoPoliza[]>(`${this.baseUrl}/Contabilidad/ObtenerTiposPolizas`);
  }

  existePoliza(idEmpresa: number, idTipoPoliza: number, fecha: string, numero: number): Observable<Result> {
    const params = new HttpParams()
      .set("idEmpresa", idEmpresa)
      .set("idTipoPoliza", idTipoPoliza)
      .set("fecha", fecha)
      .set("numero", numero)
    return this.http.get<Result>(`${this.baseUrl}/Contabilidad/ExistePoliza`, { params });
  }

  setPolicyUser(ids: string): Observable<Result> {
    const params = new HttpParams().set("ids", ids);
    return this.http.get<Result>(`${this.baseUrl}/Contabilidad/ColocarUsuarioModifica`, { params });
  }

  setConceptoGeneral(ids: string, concepto: string): Observable<Result> {
    const params = new HttpParams().set("ids", ids).set("concepto", concepto);
    return this.http.get<Result>(`${this.baseUrl}/Contabilidad/ColocarConceptoGeneral`, { params });
  }

  deletePolicy(ids: string): Observable<Result> {
    const params = new HttpParams().set("ids", ids);
    return this.http.get<Result>(`${this.baseUrl}/Contabilidad/EliminarPoliza`, { params });
  }


  markAsTemplate(ids: string, esPlantilla: boolean): Observable<void> {
    const params = new HttpParams().set("ids", ids).set("esPlantilla", esPlantilla);
    return this.http.get<void>(`${this.baseUrl}/Contabilidad/MarcarComoPlantilla`, { params });
  }

  getAccount(idEmpresa: number, claveGeneral: string, validarUltimoNivel: boolean): Observable<CuentaContable> {
    const params = new HttpParams().set("idEmpresa", idEmpresa).set("claveGeneral", claveGeneral).set("validarUltimoNivel", validarUltimoNivel);
    return this.http.get<CuentaContable>(`${this.baseUrl}/Contabilidad/ObtenerCuentaContablePorClave`, { params });
  }

  deleteEntity(id: number): Observable<any> {
    const params = new HttpParams().set("id", id);
    return this.http.get<any>(`${this.baseUrl}/Contabilidad/EliminarPolizaError`, { params });
  }

  saveEntity(name: string, value: string): Observable<any> {
    const params = {
      nombreEntidad: name,
      entidad: value
    }
    return this.http.post<any>(`${this.baseUrl}/Contabilidad/GuardarPolizaSingle`, params);
  }

  saveEntityFake(name: string, value: string): Observable<any> {
    const params = {
      nombreEntidad: name,
      entidad: value
    }
    return this.http.post<any>(`${this.baseUrl}/Contabilidad/GuardarPolizaSingleFake`, params);
  }

  deletePolicyDetail(id: number): Observable<Result> {
    const params = new HttpParams().set("id", id);
    return this.http.get<Result>(`${this.baseUrl}/Contabilidad/EliminarDetallePoliza`, { params });
  }

  getPolicyById(id: number): Observable<any> {
    const params = new HttpParams().set("id", id);
    return this.http.get<any>(`${this.baseUrl}/Contabilidad/ObtenerPolizaPorId`, { params });
  }


  isLastLevelAccount(idEmpresa: number, claveGeneral: string): Observable<boolean> {
    const params = new HttpParams().set("idEmpresa", idEmpresa).set("claveGeneral", claveGeneral);
    return this.http.get<boolean>(`${this.baseUrl}/Contabilidad/EsUltimoNivelCuenta`, { params });
  }

  solicitaDIOT(idEmpresa: number, claveGeneral: string): Observable<boolean> {
    const params = new HttpParams().set("idEmpresa", idEmpresa).set("claveGeneral", claveGeneral);
    return this.http.get<boolean>(`${this.baseUrl}/Contabilidad/SolicitaDIOT`, { params });
  }

  capturaTipoCambio(idEmpresa: number, cuenta: string): Observable<boolean> {
    const params = new HttpParams().set("idEmpresa", idEmpresa).set("cuenta", cuenta);
    return this.http.get<boolean>(`${this.baseUrl}/Contabilidad/CapturaTipoCambio`, { params });
  }

  existsAccount(idEmpresa: number, claveGeneral: string): Observable<boolean> {
    const params = new HttpParams().set("idEmpresa", idEmpresa).set("claveGeneral", claveGeneral);
    return this.http.get<boolean>(`${this.baseUrl}/Contabilidad/ExisteCuentaContable`, { params });
  }

  getCuentaNombre(idEmpresa: number, claveGeneral: string): Observable<Result> {
    const params = new HttpParams().set("idEmpresa", idEmpresa).set("claveGeneral", claveGeneral);
    return this.http.get<Result>(`${this.baseUrl}/Contabilidad/ObtenerCuentaContableNombre`, { params });
  }

  getNextClave(idEmpresa: number, nivel: number, idPadre: number): Observable<number> {
    const params = new HttpParams().set("idEmpresa", idEmpresa).set("nivel", nivel).set("idPadre", idPadre);
    return this.http.get<number>(`${this.baseUrl}/Contabilidad/ObtenerSiguienteClave`, { params });
  }

  getPolicyNavigation(idEmpresa: number, anio: number, mes: number, idTipoPoliza: number, folio: number, tipo: string): Observable<MovimientoPoliza[]> {
    const params = new HttpParams().set("idEmpresa", idEmpresa).set("anio", anio).set("mes", mes).set("idTipoPoliza", idTipoPoliza).set("folio", folio).set("tipo", tipo);
    return this.http.get<MovimientoPoliza[]>(`${this.baseUrl}/Contabilidad/ObtenerPolizaNavegacion`, { params });
  }


  saveRange(listaMovimientos: MovimientoPoliza[]): Observable<Result> {
    return this.http.post<Result>(`${this.baseUrl}/Contabilidad/GuardarPoliza`, listaMovimientos);
  }

  cargarCatalogoBase(): Observable<Result> {
    return this.http.post<Result>(`${this.baseUrl}/Contabilidad/CargarCuentasContablesBase`, {});
  }


  getPolicyByNumber(idEmpresa: number, fecha: string, idTipoPoliza: number, folio: number): Observable<MovimientoPoliza[]> {
    const params = new HttpParams().set("idEmpresa", idEmpresa).set("fecha", fecha).set("idTipoPoliza", idTipoPoliza).set("folio", folio);
    return this.http.get<MovimientoPoliza[]>(`${this.baseUrl}/Contabilidad/ObtenerPolizaPorFolio`, { params });
  }

  getTemplatePolicy(idEmpresa: number, idTipoPoliza: number, filtro: string): Observable<MovimientoPolizaCabecera[]> {
    const params = new HttpParams().set("idEmpresa", idEmpresa).set("idTipoPoliza", idTipoPoliza).set("filtro", filtro);
    return this.http.get<MovimientoPolizaCabecera[]>(`${this.baseUrl}/Contabilidad/ObtenerPolizasPlantilla`, { params });
  }

  getPolicyByYearMonth(idEmpresa: number, anio: number, mes: number, idTipoPoliza: number, filtro: string): Observable<MovimientoPolizaCabecera[]> {
    const params = new HttpParams().set("idEmpresa", idEmpresa).set("anio", anio).set("mes", mes).set("idTipoPoliza", idTipoPoliza).set("filtro", filtro);
    return this.http.get<MovimientoPolizaCabecera[]>(`${this.baseUrl}/Contabilidad/ObtenerPolizasPorPeriodo`, { params });
  }

  getPolicyByNumberYearMonth(idEmpresa: number, anio: number, mes: number, idTipoPoliza: number, folio: number): Observable<MovimientoPoliza[]> {
    const params = new HttpParams().set("idEmpresa", idEmpresa).set("anio", anio).set("mes", mes).set("idTipoPoliza", idTipoPoliza).set("folio", folio);
    return this.http.get<MovimientoPoliza[]>(`${this.baseUrl}/Contabilidad/ObtenerPolizaPorFolioPeriodo`, { params });
  }

  getPolicyByTransferencia(id: number): Observable<MovimientoPoliza[]> {
    const params = new HttpParams().set("id", id);
    return this.http.get<MovimientoPoliza[]>(`${this.baseUrl}/Contabilidad/ObtenerPolizaPorIdTransferencia`, { params });
  }

  getPolicyByCheque(id: number): Observable<MovimientoPoliza[]> {
    const params = new HttpParams().set("id", id);
    return this.http.get<MovimientoPoliza[]>(`${this.baseUrl}/Contabilidad/ObtenerPolizaPorIdCheque`, { params });
  }

  getNextPolicyNumber(idEmpresa: number, mes: number, anio: number, idTipoPoliza: number): Observable<number> {
    const params = new HttpParams().set("idEmpresa", idEmpresa).set("mes", mes).set("anio", anio).set("idTipoPoliza", idTipoPoliza);
    return this.http.get<number>(`${this.baseUrl}/Contabilidad/ObtenerSiguienteClavePoliza`, { params });
  }

  getLongLevel(idEmpresa: number, nivel: number): Observable<number> {
    const params = new HttpParams().set("idEmpresa", idEmpresa).set("nivel", nivel);
    return this.http.get<number>(`${this.baseUrl}/Contabilidad/ObtenerLongitudNivel`, { params });
  }

  obtenerListaDeAniosconMovimientosDePolizas() {
    return this.http.get<number[]>(`${this.baseUrl}/Contabilidad/ObtenerListaDeAniosConMovtosPoliza`);
  }

  getEstadoDeCuenta(cuenta: string, anio: number, esMXN: boolean) {
    const params = new HttpParams().set("cuenta", cuenta).set("anio", anio).set("esMXN", esMXN);
    return this.http.get<ItemEstadoCuentaContable[]>(`${this.baseUrl}/Contabilidad/ConsultaEstadoCuentaContable`, { params });
  }

  getEstadoDeCuentaMovimientosPorMes(idEmpresa: number, cuenta: string, mesini: number, mesfin: number, anio: number, esMXN: boolean) {
    const params = new HttpParams().set("idEmpresa", idEmpresa).set("cuenta", cuenta).set("anio", anio).set("mesini", mesini).set("mesfin", mesfin).set("esMXN", esMXN);
    return this.http.get<ItemEstadoCuentaContableMovimientos[]>(`${this.baseUrl}/Contabilidad/ConsultaEstadoCuentaContableMovimientosPorMes`, { params });
  }

  sendBalanceImportarConfiguracion(fileName: string): Observable<Result> {
    const params = new HttpParams().set("fileName", fileName);
    return this.http.get<Result>(`${this.baseUrl}/Contabilidad/CargarImportacionConfiguracion`, { params });
  }




}
