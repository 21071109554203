<app-container-base subTitle="Relaciones analiticas" (onClickBarButton)="clickBoton($event)" [hasCounter]="false"
  [isReport]="true" [activeButtons]="activeButtons" icon=" fas fa-hand-holding-dollar">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Generales">
        <div class="panel-content">
          <div class="row">
            <components-ui-text-box label='Nombre del Reporte' formControlName="NombreReporte" [tabIndex]="1"
              [widthLabelColumns]="2" [widthTextColumns]="8" [maxLength]="100" #txtNombreReporte />
          </div>

          <div class="row">
            <div class="col-md-7">
              <component-ui-combo-box label='Año' [tabIndex]="2" [zeroMask]="2"
                [selectManualItem]="selectAnioManualItem" [setItemsList]="this.listaAnios" [widthTextColumns]="2"
                [widthLabelColumns]="4" (onSelectedItem)="selectedCombo($event,'Anio')" [inputProperty]="'Nombre'"
                [listProperty]="'Nombre'" />
            </div>
          </div>
          <div class="row">
            <div class="col-md-7">
              <component-ui-combo-box label='Mes' [tabIndex]="3" [zeroMask]="2" [selectManualItem]="selectMesManualItem"
                [setItemsList]="this.listaMeses" [widthTextColumns]="2" [widthLabelColumns]="4"
                [inputProperty]="'Nombre'" (onSelectedItem)="selectedCombo($event,'Mes')" [listProperty]="'Nombre'" />
            </div>
          </div>
          <div>
          </div>
          <div class="row">
            <div class="col-md-7">
              <component-ui-check-box label='No mostrar empleados' formControlName="Opc1" [tabIndex]="4"
                [widthLabelColumns]="4" [widthTextColumns]="6" />
            </div>
          </div>
        </div>
      </app-panel-base>
      <app-panel-base title="Generales">
        <div class="panel-content">
          <component-ui-selection-filter [(FiltrosSeleccion)]="this.filtrosReporte" [tabIndex]="5"
            [ReportHeader]="this.myForm"></component-ui-selection-filter>
        </div>
      </app-panel-base>
    </form>
  </div>
</app-container-base>
