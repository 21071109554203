import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GuiCellEdit, GuiCellView, GuiColumnAlign, GuiDataType, GuiRowDetail } from '@generic-ui/ngx-grid';
import { ConceptoOrdenManufactura, ProduccionesOrdenManufactura } from 'src/app/Manufactura/interfaces/manufacura.interface';
import { EmpresaSucursalDto, Result, UserLogged } from 'src/app/auth/interfaces';
import { ComboBoxComponent } from 'src/app/component-ui/components/combo-box/combo-box.component';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { ComboBoxEntity, Coordinates } from 'src/app/component-ui/interfaces/combo-text.interface';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { Empresa } from 'src/app/configuracion/interfaces/empresa.interface';
import { Sucursal } from 'src/app/configuracion/interfaces/sucursal.interface';
import { EventsService } from 'src/app/service/events.service';
import { ModalService } from 'src/app/service/modal.service';
import { UtilsService } from 'src/app/service/utils.service';
import Swal from 'sweetalert2';
import { SerieTraspaso, Traspaso, TraspasoCargoAdicional, TraspasoDetalle } from '../../interfaces/traspaso.interface';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Producto, ProductoUnidad } from '../../interfaces/producto.interface';
import { environment } from 'src/environments/environment';
import { InventarioService } from '../../services/inventario.service';
import { Usuario } from 'src/app/configuracion/interfaces/usuario.interface';
import { FilterOptions, ReportFilter, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ReportsService } from 'src/app/service/reports.service';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';
import { CompraCargoAdicional } from 'src/app/compras/interfaces/compra.interface';
import { FacturacionService } from 'src/app/ventas/services/facturacion.service';
import { CheckBoxComponent } from 'src/app/component-ui/components/check-box/check-box.component';
import { DateEditTemplateComponent } from '@generic-ui/ngx-grid/feature/composition/src/column/edit/template/date-edit-template.component';
import { icons } from 'ngx-editor/lib/icons';
import { AppSettings } from 'src/app/home/services/app-settings.service';
import { ExistenciaProductoPaquete } from 'src/app/ventas/interfaces/venta.interface';


@Component({
  selector: 'app-traspaso-sucursales-page',
  templateUrl: './traspaso-sucursales-page.component.html',
  styleUrls: ['./traspaso-sucursales-page.component.css']
})
export class TraspasoSucursalesPageComponent implements OnInit, AfterViewInit {


  //control de flujo de la pantalla
  saving: boolean = false;
  indexCargoEditar: number = -1;
  cargoEditar: CompraCargoAdicional | null = null;
  //referencia a elementos de la pantalla
  @ViewChild('sucursalDestino')
  public sucursalDestino!: ElementRef<HTMLElement>;
  @ViewChild('cboSerie')
  public cboSerie!: ElementRef<ComboBoxComponent>;
  @ViewChild('txtFolioTrasSuc')
  public txtFolioTrasSuc!: ElementRef<TextBoxComponent>;
  @ViewChild('txtDescription')
  public txtDescription!: ElementRef<HTMLElement>;
  @ViewChild('busquedaProductosTraspasos')
  public ctrlBusquedaProductos!: ElementRef<HTMLElement>;
  @ViewChild('modalCargoAdicional')
  public modalCargoAdicional!: ElementRef<HTMLElement>;

  @ViewChild('fechaEmisionTrasSuc')
  public fechaEmisionTrasSuc!: ElementRef<HTMLElement>;

  @ViewChild('checkBoxEntregaNuestra')
  public checkBoxEntregaNuestra!: ElementRef<CheckBoxComponent>;

  @ViewChild('busquedaProductosEntradaTraspasos')
  public ctrlBusquedaProductosEntrada!: ElementRef<HTMLElement>;

  @ViewChild('ctrlConversiones')
  public ctrlConversiones!: ElementRef<HTMLElement>;
  @ViewChild('modalCancelOptiones')
  public modalCancelOptiones!: ElementRef<HTMLElement>;

  @ViewChild('ctrlExistenciasTrasSuc')
  public ctrlExistenciasTrasSuc!: ElementRef<HTMLElement>;


  //*  variables globales del usuario
  info: UserLogged = {} as UserLogged;
  empresaActual: EmpresaSucursalDto | undefined = {} as EmpresaSucursalDto;
  sucursalActual: EmpresaSucursalDto | undefined = {} as EmpresaSucursalDto;
  usuarioActual: UserLogged = {} as UserLogged;
  serieActual: ComboBoxEntity = {} as ComboBoxEntity;
  listaSeries: ComboBoxEntity[] = [];
  listaConceptos: ConceptoOrdenManufactura[] = [];
  noVerCostos: boolean = true;
  rowEditandoDescricionAdic: number = 0;
  usarSegundoNombreProducto: boolean = false;
  esEscapeSucDestino: boolean = false;
  mascara: string = "";



  //* injects
  private eventsService = inject(EventsService);
  private invService = inject(InventarioService);
  private cService = inject(ContainerBaseService);
  private fb = inject(FormBuilder);
  private utilsService = inject(UtilsService);
  private ms = inject(ModalService);
  private reportsService = inject(ReportsService)
  public appSettings = inject(AppSettings);
  private http = inject(HttpClient);
  private readonly baseUrl: string = environment.baseUrlApi;



  source: Array<TraspasoDetalle> = [];
  showDescription: boolean = false;
  loading: boolean = false;
  fechaEmision: Date = new Date()
  sourceProducts: any[] = [];
  sourceProductsEntrada: any[] = [];
  indexEditing: number = -1;
  esEscapeProducto: boolean = false;
  esEscapeProductoEntrada: boolean = false;
  esEscapeCantidad: boolean = false;
  esEscapeLotePaquete: boolean = false;
  esEnterProducto: boolean = false
  esEnterProductoEntrada: boolean = false
  enviarFocoProducto: boolean = false
  enviarFocoProductoEntrada: boolean = false
  enviarFocoCantidad: boolean = false
  enviarFocoLotePaquete: boolean = false
  enviarOtroRenglon: boolean = false;
  cords: any = null;
  idUnico: string = '';
  selectedIndex: number = -1;



  colCantidad: number = 4;
  colProducto: number = 1;
  colProductoEntrada: number = 2;
  colDecripcion: number = 3;
  colLotePaquete: number = 8;



  existencia: number = 0;
  conversiones: ProductoUnidad[] = [];
  unidadFinal: string = '';
  abrirBusqueda: boolean = false;

  public filtroTraspasos: ReportFilter =
    {
      ReportHeader: {} as ReportHeader,
      NombreReporte: '',
      Desglose: 'a Detalle',
      TituloVisor: 'Verificador de traspasos en sucursales',
      NombreExcel: 'Verificador de traspasos en sucursales.xlsx',
      FilterOptions: [
        { Campo: 'tras.Id', Etiqueta: '', Tipo: TypeFilter.number },
      ]
    };

  public myFormTotales: FormGroup = this.fb.group({
    TotalCosto: [''],
    TotalRegistros: [''],
    TotalCantidad: [''],
  });



  public myForm: FormGroup = this.fb.group({
    Id: [0],
    Nombre: [''],
    Empresa: [{} as Empresa],
    Sucursal: [{} as Sucursal],
    Clave: [''],
    Folio: [''],
    Serie: [{} as SerieTraspaso],
    SerieString: [''],
    SucursalDestino: [null],
    AlmacenSalida: [null],
    AlmacenDestino: [null],
    FechaEmision: [new Date()],
    FechaEmisionString: [new Date()],
    FechaCancelacionString: [new Date()],
    FechaElabora: [new Date()],
    FechaRecepcion: [null],
    FechaCancelacion: [null],
    Comentarios: [''],
    RecibidoEnDestino: [false],
    EsEntreAlmacenes: [false],
    EsEntreSucursales: [false],
    EnTransito: [false],
    MotivoCancelacion: [''],
    Total: [0],
    UsuarioRecibe: [{} as Usuario],
    UsuarioElabora: [null],
    UsuarioCancela: [{} as Usuario],
    Conceptos: [[] as TraspasoDetalle[]],
    CargosAdicionales: [[] as TraspasoCargoAdicional[]],
    DescripcionAdicional: [''],
    EntregaNuestra: [false],
    Eliminado: [false],
    Baja: [false],
  })

  constructor(private cb: ContainerBaseService, private fServices: FacturacionService) {
  }

  get getCancelDate(): string {
    if (this.myForm.value.FechaCancelacion) {
      return moment(this.myForm.value.FechaCancelacion).format("DD/MM/YYYY");
    }
    return "";
  }


  openCancel() {
    const params = new HttpParams()
      .set("id", this.myForm.value.Id)
    this.eventsService.publish('home:isLoading', { isLoading: true });
    return this.http.get<Result>(`${this.baseUrl}/Inventarios/VerificarPuedeCancelar`, { params }).subscribe((result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result) {
        if (result.success) {
          const b: any = this.modalCancelOptiones;
          this.ms.openModal(b, (e: any) => {
            if (e) {
              this.proceedCancel(e)
            }
          });
        } else {
          this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
        }
      }
    });
  }

  clicCargoAdicional(index: number = -1) {
    if (this.myForm.value.Id > 0) { return; }

    const ent = this.myForm.value;
    if (index >= 0) {
      this.cargoEditar = this.myForm.value.CargosAdicionales[index];
      this.indexCargoEditar = index;
    } else {
      this.indexCargoEditar = -1;
      this.cargoEditar = null;
    }


    const b: any = this.modalCargoAdicional;
    this.ms.openModal(b, (e: any) => {
      if (e) {
        let cargos = this.myForm.value.CargosAdicionales;
        if (!cargos) {
          cargos = [];
        }
        if (!this.cargoEditar) {
          cargos = [...cargos, e];
        } else {
          cargos[this.indexCargoEditar] = e;
        }
        this.myForm.controls["CargosAdicionales"].setValue(cargos);
        setTimeout(() => {
          this.calcTotales();
        }, 150);
      }
    }, 'xl');
  }


  proceedCancel(e: any) {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    const date2 = new Date();
    const d = { year: e.Fecha.getFullYear(), month: e.Fecha.getMonth(), day: e.Fecha.getDate(), hour: date2.getHours(), min: date2.getMinutes(), sec: date2.getSeconds() };
    e.Fecha = new Date(d.year, d.month, d.day, d.hour, d.min, d.sec);
    const date = moment(e.Fecha).format("YYYY-MM-DDTHH:mm:ss");
    const params = new HttpParams()
      .set("id", this.myForm.value.Id)
      .set("motivoCancelacion", e.MotivoCancelacion)
      .set("fechaCancelacion", date);
    return this.http.get<Result>(`${this.baseUrl}/Inventarios/CancelarTraspaso`, { params }).subscribe((result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result.success) {
        this.findEntityByParams()
      } else {
        this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
      }
    });
  }

  ngAfterViewInit(): void {

    let elem = this.myForm.value;
    elem.Empresa = { Id: this.empresaActual?.numero, Clave: this.empresaActual?.clave, Nombre: this.empresaActual?.nombre };
    elem.Sucursal = { Id: this.sucursalActual?.numero, Clave: this.sucursalActual?.clave, Nombre: this.sucursalActual?.nombre };

    setTimeout(() => {
      this.myForm.reset(elem);
      const txtSerie: any = this.cboSerie;
      txtSerie.tagInput.nativeElement.focus();
    }, 150);
  }

  ngOnInit(): void {
    this.appSettings.appSidebarMinified = true;
    this.info = this.utilsService.getUserLogged();
    this.idUnico = String(new Date().getTime() * 10000);
    this.empresaActual = this.info.empresa;
    this.sucursalActual = this.info.sucursal;
    this.usuarioActual = this.utilsService.getUserLogged();
    this.fServices.obtenerMascaraProducto(this.info.empresa!.numero).subscribe((result) => { this.mascara = result.message });
    this.sourceReset();
    this.cb.obtenerValorPropiedad("Usuario", "Rol.NoVerCostos", this.info.numero).subscribe((value) => {
      this.noVerCostos = value.toLowerCase() == "true";
    });
    this.fServices.ObtenerFechaPorSucursal(this.sucursalActual!.numero).subscribe((result) => {
      const f = result.message.split('T')[0].split('-');
      const t = result.message.split('T')[1].split(':');
      let ent = this.myForm.value;//this.fechaEmision = new Date(parseInt(f[0]), parseInt(f[1]) - 1, parseInt(f[2]), parseInt(t[0]), parseInt(t[1]), parseInt(t[2]));
      ent.FechaEmision = new Date(parseInt(f[0]), parseInt(f[1]) - 1, parseInt(f[2]), parseInt(t[0]), parseInt(t[1]), parseInt(t[2]));
      this.myForm.controls["FechaEmision"].setValue(ent.FechaEmision);
    });
  }

  // 1.- metodos de inicializacion de la pantalla y grid
  get getExtras() {
    return " Serie + String(' ', 1) + Nombre as SerieNombre, Serie";
  }

  // inicializacion del grid

  changueDescription(i: number, item: TraspasoDetalle) {
    if (this.myForm.value.Id > 0) {
      return;
    }
    if (item.Producto?.ActualizarDescrpcion) {
      this.rowEditandoDescricionAdic = i;
      this.indexEditing = i;
      this.showDescription = true;
      this.myForm.get("DescripcionAdicional")?.setValue(item.Descripcion);
      setTimeout(() => {
        this.txtDescription.nativeElement.focus();
      }, 150);
    }
  }

  get permiteEliminarDelGrid() {
    return !(this.myForm.value.Id > 0);
  }

  get getNoVerCostos(): boolean {
    return this.noVerCostos;
  }

  // 2.- metodos de control cambio para los campos principales

  selectSerie(entity: ComboBoxEntity) {
    this.myForm.controls["Serie"].setValue(entity);
    this.myForm.controls["SerieString"].setValue(entity.Serie);
    this.serieActual = entity;
    const ent = this.myForm.value;
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.getNextFolio(ent).subscribe(folio => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      ent.Folio = folio;
      this.setEntity(ent);
    });
  }


  onClickentregaNuestra(value: boolean) {
    const suc: Sucursal = this.myForm.get('SucursalDestino')!.value;

    if (value) {
      if (suc) {
        if (!suc.UsaDireccionEmpresa) {
          if (!suc.Direccion) {
            this.eventsService.publish('home:showAlert', { message: "La sucursal destino no tiene una dirección asignada por favor verifique.", cancelButton: false });
            this.myForm.get('EntregaNuestra')!.setValue(false);
            return;
          }
        }
      }
    }
  }
  escapeSucDestino(e: any) {
    if (e.keyCode == 27) {
      e.preventDefault();
      this.esEscapeSucDestino = true;
    }
  }


  selectSucursalDestino(entity: Sucursal) {
    this.myForm.controls["SucursalDestino"].setValue(entity);
    if (entity) {
      this.usarSegundoNombreProducto = entity.UsarSegundoNombreProducto!;
      if (!this.esEscapeSucDestino) {
        setTimeout(() => {
          const fechaEmision: any = this.fechaEmisionTrasSuc;
          fechaEmision.tagInput.nativeElement.focus();
        }, 100);
      }
      this.esEscapeSucDestino = false;
    }
  }


  //*3.- metodos de control de flujo del grid col1
  // * columna 1 Producto

  escapeProducto(e: any) {
    let index = this.indexEditing;
    this.esEscapeProducto = true;
    let item: TraspasoDetalle = this.source[index];
    e.target.value = item.Producto?.Clave ? item.Producto?.Clave : "";
    setTimeout(() => {
      this.esEscapeProducto = true;
      const item = { ...this.source[index] };
      if (!item.Producto) {
        e.target.value = "";
      } else {
        e.target.value = item.Producto.Clave;
      }
      if (index > 0) {
        this.indexEditing = index - 1;
        this.enviarFocoProducto = true;
        this.sendFocus();
      } else {
        const txt: any = this.fechaEmisionTrasSuc;
        txt.tagInput.nativeElement.focus()
      }
    }, 50);
  }
  focusProducto(index: number, e: any, item: TraspasoDetalle) {
    this.setIndexEdit(index, e, item);
    let c = this.getLastItem();
    if (c == 0 && index > 0) {
      e.target.blur();
      return;
    }
    if ((index >= c + 1) && c > 0) {
      e.target.blur();
    }
  }

  enterProducto(e: any) {
    this.esEnterProducto = true;
    this.enviarFocoCantidad = true;
    let value = '';
    if (this.sourceProducts.length > 0) {
      value = this.sourceProducts[this.selectedIndex].Clave;
    } else {
      value = e.target.value;
    }
    e.target.value = value;
    if (value) {
      e.target.blur();
    }
  }

  blurProducto(e: any) {
    let index = this.indexEditing;
    e.target.classList.remove("focus-editor-grid");

    setTimeout(() => {
      this.cords = null;
      if (this.esEscapeProducto) {
        this.esEnterProducto = false;
        this.esEscapeProducto = false;
        return;
      }
      let item = { ...this.source[index] };
      if (item.Producto) {
        if (!e.target.value) {
          e.target.value = item.Producto.Clave;
          this.enviarFocoProducto = true;
          this.sendFocus();
          return;
        }

        if (item.Producto.Clave != e.target.value) {
          this.searchProduct(e.target.value);
        } else {
          if (this.esEnterProducto) {
            if (item.AceptaEquivalente) {
              this.enviarFocoProductoEntrada = true;
              this.enviarFocoCantidad = false;
              this.sendFocus();
            } else {
              if (this.enviarFocoCantidad) {
                this.sendFocus();
              }
            }
          }
        }
      } else {
        if (e.target.value != "") {
          this.searchProduct(e.target.value);
        } else {
          e.target.classList.remove("focus-editor-grid");
        }
      }
      this.esEnterProducto = false;
      this.esEscapeProducto = false;
    }, 100);
  }

  buscarProducto(e: any) {
    if (e.target.value == "" || !isNaN(e.target.value.trim().replace(/\s/g, ""))) {
      this.cords = null;
      this.sourceProducts = [];
      return;
    }

    this.sourceProductsEntrada = [];

    if (this.selectedIndex == -1) {
      this.selectedIndex = 0;
    }
    let row = null;
    if (e.keyCode == "38") {
      e.preventDefault();
    }

    if (e.keyCode == "40") {
      e.preventDefault();
    }
    if (e.keyCode == "27" || e.keyCode == "37" || e.keyCode == "39" || e.keyCode == "38" || e.keyCode == "40") {
      return;
    }
    let cords = this.getOffset(e.target);
    cords.top = cords.top - 400;
    cords.left = cords.left - 290;
    this.cords = cords;
    //todo si no ha seleccionado el tipode movimineto o el folio
    this.fServices.busquedaProductos(e.target.value, 0).subscribe((result) => {
      const lista = JSON.parse(result.message);
      this.sourceProducts = lista;
      if (this.sourceProducts.length > 0) {
        this.selectedIndex = 0;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`);
        if (row) {
          row.scrollIntoView({ block: "center" });
        }
      } else {
        this.selectedIndex = -1;
      }
    });
  }

  //* Columna 2 Producto Entrada col2

  escapeProductoEntrada(e: any) {
    let index = this.indexEditing;
    this.esEscapeProductoEntrada = true;
    let item: TraspasoDetalle = this.source[index];
    e.target.value = item.ProductoEntrada?.Clave ? item.ProductoEntrada?.Clave : "";
    this.initEditor(this.indexEditing, 1);
  }


  clicTablaProducto(e: any, item: any) {
    const t: any = document.activeElement;
    t.value = item.Clave;
    const input: any = document.getElementById(`txt_${this.indexEditing}_2${this.idUnico}`)!;
    if (input) {
      input.value = item.Clave;
      this.enviarFocoCantidad = true;
    }
  }



  navegarProductos(e: any) {
    let row = null;
    if (e.keyCode == 40) {
      if (this.selectedIndex + 1 < this.sourceProducts.length) {
        this.selectedIndex++;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`)
        row!.scrollIntoView({ block: "center" });
      }
    } else if (e.keyCode == 38) {
      if (this.selectedIndex > 0) {
        this.selectedIndex--;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`)
        row!.scrollIntoView({ block: "center" });
      }
    }
  }




  focusProductoEntrada(index: number, e: any, item: TraspasoDetalle) {
    this.setIndexEdit(index, e, item);
    let c = this.getLastItem();

    if (c == 0 && index > 0) {
      e.target.blur();
      return;
    }

    if ((index >= c + 1) && c > 0) {
      e.target.blur();
    }
  }

  enterProductoEntrada(e: any) {
    this.esEnterProductoEntrada = true;
    this.esEscapeProductoEntrada = false;
    this.enviarFocoCantidad = true;
    let value = '';
    if (this.sourceProductsEntrada.length > 0) {
      value = this.sourceProductsEntrada[this.selectedIndex].Clave;
    } else {
      value = e.target.value;
    }
    e.target.value = value;
    if (value) {
      e.target.blur();
    }
  }

  blurProductoEntrada(e: any) {
    let index = this.indexEditing;
    e.target.classList.remove("focus-editor-grid");

    setTimeout(() => {
      this.cords = null;
      if (this.esEscapeProductoEntrada) {
        this.esEnterProductoEntrada = false;
        this.esEscapeProductoEntrada = false;
        return;
      }
      let item: TraspasoDetalle = { ...this.source[index] };
      if (item.ProductoEntrada) {
        if (!e.target.value) {
          e.target.value = item.ProductoEntrada.Clave;
          this.enviarFocoProductoEntrada = true;
          this.sendFocus();
          return;
        }
        if (item.ProductoEntrada.Clave != e.target.value) {
          this.searchProductEntrada(e.target.value);
        } else {
          if (this.enviarFocoCantidad) {
            this.sendFocus();
          }
        }
      } else {
        if (e.target.value != "") {
          this.searchProductEntrada(e.target.value);
        } else {
          e.target.classList.remove("focus-editor-grid");
        }
      }
      this.esEnterProductoEntrada = false;
      this.esEscapeProductoEntrada = false;
    }, 100);
  }

  buscarProductoEntrada(e: any) {
    if (e.target.value == "" || !isNaN(e.target.value.trim().replace(/\s/g, ""))) {
      this.cords = null;
      this.sourceProductsEntrada = [];
      return;
    }
    this.sourceProducts = [];


    if (this.selectedIndex == -1) {
      this.selectedIndex = 0;
    }
    let row = null;

    if (e.keyCode == "40" || e.keyCode == "38") {
      e.preventDefault();

    }
    if (e.keyCode == "27" || e.keyCode == "37" || e.keyCode == "39" || e.keyCode == "38" || e.keyCode == "40") {
      return;
    }
    let cords = this.getOffset(e.target);
    cords.top = cords.top - 400;
    cords.left = cords.left - 290;
    this.cords = cords;

    //todo si no ha seleccionado el tipode movimineto o el folio
    //if (!this.myForm.value.Cliente) { return }
    this.fServices.busquedaProductos(e.target.value, 0).subscribe((result) => {
      const lista = JSON.parse(result.message);
      this.sourceProductsEntrada = lista;
      if (this.sourceProductsEntrada.length > 0) {
        this.selectedIndex = 0;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`);
        if (row) {
          row.scrollIntoView({ block: "center" });
        }

      } else {
        this.selectedIndex = -1;
      }
    });
  }



  navegarProductosEntrada(e: any) {
    let row = null;
    if (e.keyCode == 40) {
      if (this.selectedIndex + 1 < this.sourceProducts.length) {
        this.selectedIndex++;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`)
        row!.scrollIntoView({ block: "center" });
      }
    } else if (e.keyCode == 38) {
      if (this.selectedIndex > 0) {
        this.selectedIndex--;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`)
        row!.scrollIntoView({ block: "center" });
      }
    }
  }

  //* Columna 4 COL4


  escapeCantidad(e: any) {
    this.esEscapeCantidad = true;
    let item: TraspasoDetalle = this.source[this.indexEditing];
    e.target.value = item.Cantidad ? item.Cantidad : 0;
    if (item.AceptaEquivalente) {
      this.initEditor(this.indexEditing, 2);
    } else {
      this.initEditor(this.indexEditing, 1);
    }
  }

  enterCantidad(e: any) {
    if (e.target.value == "" || parseInt(e.target.value) == 0) {
      return;
    }
    let item = this.source[this.indexEditing];
    if (item.ManejaLotePaquete) {
      this.enviarFocoLotePaquete = true;
    } else {
      this.enviarOtroRenglon = true;
    }
    e.target.blur();
  }

  blurCantidad(e: any) {
    e.target.classList.remove("focus-editor-grid");
    if (this.esEscapeCantidad) {
      this.esEscapeCantidad = false;
      return;
    }
    if (e.target.value == "" || parseInt(e.target.value) == 0 && !this.enterCantidad) {
      e.target.classList.remove("focus-editor-grid");
      return;
    }
    const cantidad = parseFloat(e.target.value);
    let item = this.source[this.indexEditing];
    if (parseFloat(String(item.Cantidad)) == cantidad) {
      if (this.enviarOtroRenglon) {
        this.sendFocus();
      }
      return;
    };
    if (item.Conversiones) {
      if (item.Conversiones.length > 0) {
        let totalEnConversiones = 0;
        item.Conversiones.forEach((i) => totalEnConversiones += i.CantidadUnidadConcepto);
        if (cantidad != totalEnConversiones) {
          item.Cantidad = totalEnConversiones;
        } else {
          item.Cantidad = cantidad;
        }
      } else {
        item.Cantidad = cantidad;
        item.Conversiones = [];
        item.TextoConversiones = "";
        if (item.ManejaLotePaquete) {
          this.enviarFocoLotePaquete = true;
          this.enviarOtroRenglon = false;
        } else {
          this.enviarOtroRenglon = true;
        }
      }
    } else {
      item.Cantidad = cantidad;
      item.Conversiones = [];
      item.TextoConversiones = "";
      if (item.ManejaLotePaquete) {
        this.enviarFocoLotePaquete = true;
        this.enviarOtroRenglon = false;
      } else {
        this.enviarOtroRenglon = true;
      }
    }
    this.source[this.indexEditing] = { ...item };
    this.source = [...this.source];
    this.sendFocus();
    this.calcTotales();
  }



  keyDownCantidad(e: any) {
    const noDecimales: boolean = this.source[this.indexEditing].Producto!.NoDecimalesCantidad;
    if (e.key === '.' && noDecimales) { e.preventDefault(); }
  }


  //* Columna 8 Paquete col8

  enterPaquete(e: any) {
    this.enviarOtroRenglon = true;
    e.target.blur();
  }

  blurPaquete(e: any) {
    e.target.classList.remove("focus-editor-grid");
    const lote: string = e.target.value;
    let item: TraspasoDetalle = this.source[this.indexEditing];
    if (item.NumeroLotePaquete == lote) {
      if (this.enviarOtroRenglon) {
        this.sendFocus();
      }
      return;
    };
    item.NumeroLotePaquete = lote;
    this.source[this.indexEditing] = { ...item };
    this.source = [...this.source];
    this.sendFocus();
  }

  // blurPaquete(e: any) {
  //   e.target.classList.remove("focus-editor-grid");
  //   if (this.esEscapeCantidad) {
  //     this.esEscapeCantidad = false;
  //     return;
  //   }
  //   if (e.target.value == "" || parseInt(e.target.value) == 0 && !this.enterCantidad) {
  //     e.target.classList.remove("focus-editor-grid");
  //     return;
  //   }
  //   const cantidad = parseFloat(e.target.value);
  //   let item = this.source[this.indexEditing];
  //   if (parseFloat(String(item.Cantidad)) == cantidad) {
  //     if (this.enviarOtroRenglon) {
  //       this.sendFocus();
  //     }
  //     return;
  //   };
  //   if (item.Conversiones) {
  //     if (item.Conversiones.length > 0) {
  //       let totalEnConversiones = 0;
  //       item.Conversiones.forEach((i) => totalEnConversiones += i.CantidadUnidadConcepto);
  //       if (cantidad != totalEnConversiones) {
  //         item.Cantidad = totalEnConversiones;
  //       } else {
  //         item.Cantidad = cantidad;
  //       }
  //     } else {
  //       item.Cantidad = cantidad;
  //       item.Conversiones = [];
  //       item.TextoConversiones = "";
  //       this.enviarOtroRenglon = true;
  //       this.sendFocus();
  //     }
  //   } else {
  //     item.Cantidad = cantidad;
  //     item.Conversiones = [];
  //     item.TextoConversiones = "";
  //     this.enviarOtroRenglon = true;
  //     this.sendFocus();
  //   }
  //   this.source[this.indexEditing] = { ...item };
  //   this.source = [...this.source];
  //   this.sendFocus();
  // }

  escapePaquete(e: any) {
    this.esEscapeLotePaquete = true;
    let item: TraspasoDetalle = this.source[this.indexEditing];
    e.target.value = item.Cantidad ? item.Cantidad : "";
    this.initEditor(this.indexEditing, this.colCantidad);
  }

  // escapeSerie(e: any) {
  //   let item = { ...this.source[this.indexEditing] };
  //   e.target.value = item.SerieOrigina ? item.SerieOrigina : "";
  //   this.esEscapeSerieOrigina = true;
  //   if (item.Tipo === 'S') {
  //     this.initEditor(this.indexEditing, 6);
  //   } else {
  //     this.initEditor(this.indexEditing, 9);
  //   }
  // }


  keyDownPaquete(e: any) {
    const noDecimales: boolean = this.source[this.indexEditing].Producto!.NoDecimalesCantidad;
    if (e.key === '.' && noDecimales) { e.preventDefault(); }
  }




  //* metodos genericos del grid

  getOffset(elem: HTMLInputElement): Coordinates {
    var box = elem.getBoundingClientRect();
    var left = window.scrollX !== undefined ? window.scrollX :
      (document.documentElement || document.body.parentNode || document.body).scrollLeft;
    var top = window.scrollY !== undefined ? window.scrollY :
      (document.documentElement || document.body.parentNode || document.body).scrollTop;

    top += elem.offsetHeight;
    return { left: box.left + left, top: box.top + top };
  }


  openExistencias() {
    if (this.source[this.indexEditing].Producto?.Inventariable) {
      const b: any = this.ctrlExistenciasTrasSuc;
      this.ms.openModal(b, (e: ExistenciaProductoPaquete | null) => {
        setTimeout(() => {
          this.initEditor(this.indexEditing, this.colCantidad);
        }, 50);
      }, 'xl')
    } else {
      this.eventsService.publish('home:showAlert', { message: "El Producto no es inventariable.", cancelButton: false });
    }
  }




  setIndexEdit(index: number, e: any, item: TraspasoDetalle) {





    if (this.myForm.value.Folio == 0) {
      this.eventsService.publish('home:showAlert', {
        textAccept: "Aceptar",
        cancelButton: false,
        message: 'Favor de indicar el Folio.',
        onConfirm: (data: AlertResponse) => {
          this.indexEditing = -1;
          return;
        }
      });
      return;
    }



    this.indexEditing = index;
    e.target.select();
    e.target.classList.add("focus-editor-grid");

    return;



    //todo revisar este codigo y ver como aplica con el nuevo grid
    // if (!this.permiteEliminar) {
    //   return false;
    // }

    // if (index > 0) {
    //   if (!this.source[index - 1].Producto) {
    //     return false;
    //   }
    //   if (this.source[index - 1].Producto!.Id == 0) {
    //     return false;
    //   }
    // }




    //* si esta cancelado, no deberia de poder editar algo.
    if (this.myForm.value.FechaCancelacion) {
      this.indexEditing = -1;
      e.target.blur();
      return;
    }

    this.indexEditing = index;
    e.target.select();
    e.target.classList.add("focus-editor-grid");
  }



  getLastItem(): number {
    return this.source.filter(P => P.Producto && P.Cantidad > 0).length;
  }

  sendFocus() {
    setTimeout(() => {
      if (this.enviarFocoCantidad) {
        this.initEditor(this.indexEditing, this.colCantidad);
        this.enviarFocoCantidad = false;
      }

      if (this.enviarFocoProducto) {
        this.initEditor(this.indexEditing, this.colProducto);
        this.enviarFocoProducto = false;
      }

      if (this.enviarFocoProductoEntrada) {
        this.initEditor(this.indexEditing, this.colProductoEntrada);
        this.enviarFocoProductoEntrada = false;
      }

      if (this.enviarFocoLotePaquete) {
        this.initEditor(this.indexEditing, this.colLotePaquete);
        this.enviarFocoLotePaquete = false;
      }


      if (this.enviarOtroRenglon) {
        this.indexEditing = this.indexEditing + 1;
        this.initEditor(this.indexEditing, 1);
        this.enviarOtroRenglon = false;
      }
    }, 50);
  }


  keyDownRow(e: any, index: number) {
    if (e.ctrlKey && e.keyCode == "46") {
      e.preventDefault();
      this.deleteRow(index);
    }
  }


  up(e: any, celda: number) {
    e.preventDefault();
    if (celda == 0 && this.sourceProducts.length > 0) {
      return;
    }
    if (this.indexEditing > 0) {
      if (celda == 0) {
        this.esEscapeProducto = true;
      }
      e.target.blur();
      setTimeout(() => {
        this.indexEditing = this.indexEditing - 1;
        this.initEditor(this.indexEditing, celda + 1);
        this.esEscapeProducto = false;
      }, 50);
    } else {
      this.initEditor(this.indexEditing, 1);
    }
  }


  down(e: any, celda: number) {
    e.preventDefault();
    if (celda == 0 && this.sourceProducts.length > 0) {
      return;
    }
    let c = this.getLastItem();
    if (this.indexEditing < c - 1) {
      e.target.blur();
      setTimeout(() => {
        this.indexEditing = this.indexEditing + 1;
        this.initEditor(this.indexEditing, celda + 1);
      }, 50);
    } else {
      if (this.indexEditing + 1 < c + 1) {
        this.esEscapeProducto = true;
        this.indexEditing = this.indexEditing + 1;
        this.initEditor(this.indexEditing, 1);
      }
    }
  }

  sourceReset(conceptos: any = []) {
    if (!conceptos) {
      this.source = [];
    } else {
      this.source = conceptos;
    }

    this.source!.map((item: TraspasoDetalle) => {
      if (item.ProductoEntrada) {
        item.NoIdentificacionEntrada = item.ProductoEntrada.Clave;
        item.AceptaEquivalente = true;
      } else {
        item.NoIdentificacionEntrada = '';
        item.AceptaEquivalente = false;
      }
    });

    for (let index = 0; index < 100; index++) {
      this.source = [...this.source, this.estructuraVacia()];
    }
  }

  estructuraVacia() {
    return {
      Id: 0,
      Producto: null,
      ProductoEntrada: null,
      NoIdentificacion: '',
      NoIdentificacionEntrada: '',
      Cantidad: 0,
      CantidadRestante: 0,
      CantidadEntregada: 0,
      CostoProrreateado: 0,
      CostoFinal: 0,
      Existencia: 0,
      NumeroLotePaquete: '',
      NumeroPedimento: '',
      Unidad: null,
      Descripcion: '',
      DescripcionConvesion: '',
      Costo: 0,
      Total: 0,
      TextoConversiones: '',
      UnidadNombre: '',
      Conversiones: [],
      CargosAdicionales: [],
      AceptaEquivalente: false,
      ManejaLotePaquete: false
    }
  }

  conversionClick(index: number) {
    if (this.myForm.value.Id > 0) {
      return;
    }

    this.indexEditing = index;
    let item = this.source[this.indexEditing];
    this.conversiones = item.Producto!.OtrasUnidades;
    this.unidadFinal = item.UnidadNombre;
    this.openConvertions((e: any) => this.aceptConversions(e, true));
  }


  deleteRow(indx: number) {
    if (this.myForm.value.Id > 0) {
      this.eventsService.publish('home:showAlert', { message: "El Traspaso no puede modificarse, proceda a Cancelar.", cancelButton: false });
      return;
    }

    this.eventsService.publish('home:showAlert', {
      message: '¿Desea eliminar el reglón?.',
      onConfirm: (data: AlertResponse) => {
        if (data.isAccept) {
          const idEliminar = this.source[indx].Id;
          if (idEliminar > 0) {
            this.eventsService.publish('home:isLoading', { isLoading: true });
            this.invService.eliminarTraspasoDetalle(idEliminar).subscribe((result) => {
              this.eventsService.publish('home:isLoading', { isLoading: false });
              this.source.splice(indx, 1);
              this.source = [...this.source];
              this.calcTotales();
              let ent: Traspaso = this.myForm.value;
              ent.Conceptos?.splice(indx, 1);
              this.myForm.reset(ent);
              //this.PptoDetalle.removeElement  (indx);
            });
          } else {
            this.source.splice(indx, 1);
            this.source = [...this.source];
            this.calcTotales();
            let ent: Traspaso = this.myForm.value;
            ent.Conceptos?.splice(indx, 1);
            this.myForm.reset(ent);
          }
        }
      }
    });
  }

  get gettotalCargoProrrateado(): number {
    let totalImporteProrratear = 0;
    if (this.source) {
      this.source.filter(P => P.Producto?.SeProrrateaEnCompras && !P.Producto?.Inventariable).forEach((i) => {
        totalImporteProrratear += (parseFloat(String(i.CostoFinal)) * parseFloat(String(i.Cantidad)));
      });
      let cargos = this.myForm.value.CargosAdicionales;
      if (cargos) {
        cargos.forEach((item: TraspasoCargoAdicional) => {
          if (!item.NoProrratear) {
            totalImporteProrratear += parseFloat(String(item.Subtotal));
          }
        })
      }
    }
    return totalImporteProrratear;
  }




  calcTotales() {
    let ent: Traspaso = this.myForm.value;
    ent.Total = 0;
    let totalConcepto: number = 0, subTotalVenta = 0, totalConceptos = 0;
    let count: number = 0;
    let regs: number = 0;

    this.source.map((concepto, index) => {
      if (concepto.Producto) {
        if (concepto.Producto.Id > 0) {
          concepto.Total = parseFloat((concepto.Cantidad * concepto.Costo).toFixed(6));
          concepto.CantidadRestante = concepto.Cantidad - concepto.CantidadEntregada;
          concepto.CostoFinal = concepto.Costo;
          totalConcepto += concepto.Total;
          count += concepto.Cantidad;
          regs++;
        }
      }
    });
    let totalImporteNormal = 0;
    let totalImporteProrratear = 0;
    this.source.filter(P => !P.Producto?.SeProrrateaEnCompras && P.Producto?.Inventariable).forEach((i) => {
      let importe = parseFloat(String(i.Cantidad)) * parseFloat(String(i.Costo));
      totalImporteNormal += importe;
    });

    let cargos = this.myForm.value.CargosAdicionales;
    if (cargos) {
      cargos.forEach((item: TraspasoCargoAdicional) => {
        if (!item.NoProrratear) {
          totalImporteProrratear += parseFloat(String(item.Subtotal));
        }
      })
    }

    if (totalImporteProrratear > 0) {
      this.source.map((concepto) => {
        if (concepto) {
          if (concepto.Producto) {
            if (!concepto.Producto.SeProrrateaEnCompras && concepto.Producto.Inventariable) {

              const cant = concepto.Cantidad;
              if (cant != 0) {
                let importe = parseFloat(String(concepto.Cantidad)) * parseFloat(String(concepto.Costo));
                let prorrateado = ((((importe * 100) / totalImporteNormal) / 100) * totalImporteProrratear) / cant;
                concepto.CostoProrreateado = parseFloat(prorrateado.toFixed(4));
                concepto.CostoFinal = parseFloat(String(concepto.Costo)) + concepto.CostoProrreateado;
              }
            }
          }
        }
      });
    } else {
      this.source.map((item) => {
        item.CostoProrreateado = 0;
        item.CostoFinal = item.Costo;
        return item;
      });
    }

    this.myFormTotales.get('TotalCosto')!.setValue(totalConcepto);
    this.myFormTotales.get('TotalRegistros')!.setValue(regs);
    this.myFormTotales.get('TotalCantidad')!.setValue(count);
    ent.Total = totalConcepto;
    this.myForm.reset(ent);
    this.source = [...this.source];
  }


  deleteCargos(index: number) {
    if (this.myForm.value.Id > 0) { return; }
    this.eventsService.publish('home:showAlert', {
      message: '¿Desea eliminar el Cargo?',
      onConfirm: (r: AlertResponse) => {
        if (r.isAccept) {
          let cargos = this.myForm.value.CargosAdicionales;
          cargos.splice(index, 1);
          cargos = [...cargos];
          this.myForm.controls["CargosAdicionales"].setValue(cargos);
          setTimeout(() => {
            this.calcTotales();
          }, 150);
        }
      }
    });
  }

  tabCheckBox(e: any) {
    e.preventDefault();
    this.initEditor(0, 1)
  }


  initEditor(row: number, col: number) {
    col = col - 1;
    const input = document.getElementById(`txt_${row}_${col}${this.idUnico}`)!;
    if (input) input.focus();
  }


  // initEditor(row: number, col: number) {
  //   // let output:string = "row:" + row + " col:" + col;
  //   console.log(col);


  //   const elem: any = this.getElemEditor(row, col);
  //   if (elem) {
  //     setTimeout(() => {
  //       elem.firstElementChild?.click();
  //     }, 250);
  //   }
  // }


  getElemEditor(row: number, col: number) {

    let elem: any = null;
    const elems: any = document.getElementById("divTraspasosSucursales")!.querySelectorAll('.gui-content');
    elems.forEach((renglon: HTMLElement) => {
      renglon.childNodes.forEach((nodeRow: any, indexRow: number) => {
        if (indexRow == row && !elem) {
          let colNum = -1;
          nodeRow.childNodes.forEach((nodeColumna: any) => {
            if (nodeColumna.childNodes && nodeColumna.className && !elem) {
              colNum++;
              if (col == colNum) {
                elem = nodeColumna;
              }
            }
          });
        }
      })
    });

    //const elem: any = document.getElementById("divFacturacion")!.querySelector(`[ng-reflect-column-index='${col}'][ng-reflect-row-index='${row}']`);
    return elem;
  }

  openConvertions(cb: any) {
    const b: any = this.ctrlConversiones;
    this.ms.openModal(b, (e: any) => {
      cb && cb(e);
    })
  }
  aceptConversions(e: any, isclick: boolean = false) {
    this.conversiones = [];
    this.unidadFinal = '';
    let item = this.source[this.indexEditing];
    if (e) {
      if (e.cantidad > 0) {
        item.Conversiones = e.items;
        item.TextoConversiones = e.conversiones;
        item.Cantidad = e.cantidad;
        this.source = [...this.source];
        this.indexEditing++;
        !isclick && this.initEditor(this.indexEditing, this.colProducto);
      } else {
        item.Conversiones = [];
        item.TextoConversiones = '';
        !isclick && this.initEditor(this.indexEditing, this.colCantidad);
      }
      this.calcTotales();
    } else {
      this.source = [...this.source];
      !isclick && this.initEditor(this.indexEditing, this.colCantidad);
    }
  }

  //4.- matodos de consulta a el backend

  navigate(type: string) {
    this.findEntityByParamsNavigate(type);
  }

  get esNuevo() {
    return this.myForm.value.Id == 0;
  }

  findEntityByParamsNavigate(type: string) {
    const params = new HttpParams()
      .set("idEmpresa", this.myForm.value.Empresa.Id)
      .set("idSucursal", this.myForm.value.Sucursal.Id)
      .set("serie", this.myForm.value.SerieString)
      .set("folio", this.myForm.value.Folio)
      .set("tipo", type);
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.http.get<Traspaso>(`${this.baseUrl}/Inventarios/ObtenerTraspasoPorParametrosNavegacion`, { params }).subscribe((traspaso) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      this.setEntity(traspaso);

    });
  }

  setEntity(traspaso: Traspaso, cb: any = null) {
    if (!traspaso) {
      this.new();
    } else {
      let ent = this.myForm.value;
      ent = { ...traspaso };
      this.sourceReset(ent.Conceptos);
      ent.Conceptos.map((item: TraspasoDetalle) => {
        if (item.Unidad) {
          item.UnidadNombre = item.Unidad!.Nombre;
        } else {
          item.UnidadNombre = '';
        }
      });
      this.myForm.reset(ent);
      this.calcTotales();

      if (cb) cb();
    }
  }

  new() {
    const elem: Traspaso = this.myForm.value;
    this.cService.getEmptyEntity("Traspaso").subscribe((ent) => {
      ent.Conceptos = [];
      this.sourceReset();
      ent.Empresa = { Id: this.info.empresa?.numero, Clave: this.info.empresa?.clave, Nombre: this.info.empresa?.nombre };
      ent.Sucursal = { Id: this.info.sucursal?.numero, Clave: this.info.sucursal?.clave, Nombre: this.info.sucursal?.nombre };
      ent.Serie = this.serieActual;
      ent.SerieString = this.serieActual.Serie;
      this.eventsService.publish('home:isLoading', { isLoading: true });
      this.getNextFolio(ent).subscribe(nuevoFolio => {
        ent.Folio = nuevoFolio;
        this.setEntity(ent);
        this.myForm.get("FechaEmision")?.setValue(new Date());
        setTimeout(() => {
          this.fServices.ObtenerFechaPorSucursal(ent.Sucursal.Id).subscribe((result) => {
            this.eventsService.publish('home:isLoading', { isLoading: false });
            const f = result.message.split('T')[0].split('-');
            const t = result.message.split('T')[1].split(':');
            ent.FechaEmision = new Date(parseInt(f[0]), parseInt(f[1]) - 1, parseInt(f[2]), parseInt(t[0]), parseInt(t[1]), parseInt(t[2]));
            this.myForm.controls["FechaEmision"].setValue(ent.FechaEmision);
            const txtsucDestino: any = this.sucursalDestino;
            txtsucDestino.tagInput.nativeElement.focus();
          })
        }, 250);
      });
    });
  }

  getNextFolio(ent: Traspaso): Observable<any> {
    const params = new HttpParams().set("idEmpresa", ent.Empresa.Id)
      .set("idSucursal", ent.Sucursal.Id)
      .set("serie", ent.SerieString);
    return this.http.get<number>(`${this.baseUrl}/Inventarios/ObtenerSiguienteFolioTraspaso`, { params });
  }


  searchProductEntrada(value: string) {
    this.loading = true;
    value = this.fServices.aplciarMascara(value, this.mascara);
    const params = new HttpParams().set("idEmpresa", this.empresaActual!.numero).set("idSucursal", this.sucursalActual!.numero).set("idCliente", 0).set("clave", value);
    this.http.get<Producto>(`${this.baseUrl}/Ventas/ObtenerProducto`, { params }).subscribe((producto: Producto) => {
      this.loading = false;
      if (producto) {
        let item = this.source[this.indexEditing];
        item.ProductoEntrada = producto;
        item.NoIdentificacionEntrada = producto.Clave;
        this.source[this.indexEditing] = { ...item };
        this.source = [...this.source];
        this.enviarFocoCantidad = true;
        this.sendFocus();
        // this.source[this.indexEditing].NoIdentificacionEntrada = value;
        // this.initEditor(this.indexEditing, this.colCantidad);
      } else {
        this.eventsService.publish('home:showAlert', {
          message: "No se encontró el Producto Entrada indicado.", cancelButton: false, onConfirm: (data: AlertResponse) => {
            this.source[this.indexEditing].NoIdentificacionEntrada = "";
            this.source = [...this.source];
            this.initEditor(this.indexEditing, this.colProductoEntrada);
          }
        });
      }
    });
  }

  searchProduct(value: string) {
    this.loading = true;
    value = this.fServices.aplciarMascara(value, this.mascara);
    const params = new HttpParams().set("idEmpresa", this.empresaActual!.numero).set("idSucursal", this.sucursalActual!.numero).set("idCliente", 0).set("clave", value);
    return this.http.get<Producto>(`${this.baseUrl}/Ventas/ObtenerProducto`, { params }).subscribe((producto) => {
      this.loading = false;
      if (producto) {
        if (!producto.Almacen) {
          this.eventsService.publish('home:showAlert', {
            message: 'El producto seleccionado no tiene configurado un Almacén, por favor verifique.',
            cancelButton: false,
            onConfirm: (data: AlertResponse) => {
              if (data.isAccept) {
                this.source[this.indexEditing].NoIdentificacion = "";
                this.source = [...this.source];
                this.initEditor(this.indexEditing, this.colProducto);
              }
            }
          });
          return;
        }

        if (!producto.Inventariable) {
          this.eventsService.publish('home:showAlert', {
            message: 'El producto seleccionado no es Inventariable, por favor verifique.',
            cancelButton: false,
            onConfirm: (data: AlertResponse) => {
              if (data.isAccept) {
                this.source[this.indexEditing].NoIdentificacion = "";
                this.source = [...this.source];
                this.initEditor(this.indexEditing, this.colProducto);
              }
            }
          });
          return;
        }

        this.invService.getUltimoCostoPorProducto(this.empresaActual!.numero, this.sucursalActual!.numero, producto.Almacen.Id, producto.Id).subscribe(prod => {
          let item = this.source[this.indexEditing];
          item.Producto = producto;
          item.NoIdentificacion = producto.Clave;
          if (!this.usarSegundoNombreProducto) {
            item.Descripcion = producto.Nombre;
          } else {
            item.Descripcion = producto.SegundoNombre;
          }

          item.Cantidad = 0;
          item.Unidad = producto.Unidad;
          if (prod != 0) {
            item.Costo = prod;
          } else {
            item.Costo = 0;
          }

          if (producto.ProductoEquivalente) {
            item.ProductoEntrada = producto.ProductoEquivalente;
            item.NoIdentificacionEntrada = producto.ProductoEquivalente.Clave;
            item.AceptaEquivalente = true;
          } else {
            item.ProductoEntrada = null;
            item.NoIdentificacionEntrada = "";
            item.AceptaEquivalente = false;
          }

          if (producto.ManejaPaquetesLotes) {
            item.ManejaLotePaquete = true;
          }

          item.UnidadNombre = producto.Unidad.Nombre;
          this.source = [...this.source];
          this.showDescription = producto.ActualizarDescrpcion;
          this.myForm.controls["DescripcionAdicional"].setValue(producto.Nombre);

          if (!producto.ActualizarDescrpcion) {
            if (producto.OtrasUnidades && producto.OtrasUnidades?.length > 0 && producto.AplicaVentas) {
              this.getExistencia(producto);
              this.conversiones = producto.OtrasUnidades;
              this.unidadFinal = item.UnidadNombre;
              this.openConvertions((e: any) => this.aceptConversions(e));
            } else {
              this.conversiones = [];
              this.unidadFinal = '';

              if (!producto.ProductoEquivalente) {
                this.enviarFocoCantidad = true;
                this.getExistencia(producto, this.sendFocus());

              } else {
                this.enviarFocoProductoEntrada = true;
                this.getExistencia(producto, this.sendFocus());
              }
            }
          } else {
            this.rowEditandoDescricionAdic = this.indexEditing;
            setTimeout(() => {
              this.getExistencia(producto), () => {
                this.txtDescription.nativeElement.focus();
              };
            }, 150);
          }
        });
      } else {

        this.eventsService.publish('home:showAlert', {
          message: 'No se encontró el Producto indicado.',
          cancelButton: false,
          onConfirm: (data: AlertResponse) => {
            this.source[this.indexEditing].NoIdentificacion = "";
            this.source[this.indexEditing].Existencia = 0;
            this.source = [...this.source];
            this.enviarFocoProducto = true;
            this.sendFocus();
            //this.initEditor(this.indexEditing, this.colProducto);
          }
        });
      }
    })
  }

  getExistencia(producto: Producto, cb: any = null) {
    this.fServices.getExistencia(producto.Id, this.myForm.value.Sucursal.Id, producto.Almacen.Id).subscribe((existencia) => {
      this.existencia = existencia;
      setTimeout(() => {
        this.source[this.indexEditing].Existencia = existencia;
        this.source = [...this.source];
        if (cb) { cb(); }
      }, 150);
    });
  }


  save() {
    this.existencia = 0;
    if (this.saving) return;
    this.myForm.get('Clave')!.setValue(this.myForm.value.Folio);
    let ent: Traspaso = this.myForm.value;
    if (this.myForm.value.Id == 0) {
      ent.UsuarioElabora = { Id: this.usuarioActual.numero, Clave: this.usuarioActual.numero, Nombre: this.usuarioActual.nombreUsuario } as Usuario;
      ent.FechaElabora = new Date();
    } else {
      this.eventsService.publish('home:showAlert', { message: `El Traspaso no puede modificarse, proceda a Cancelar.`, cancelButton: false });
      return;
    }

    ent.EsEntreSucursales = true;

    ent.FechaEmisionString = moment(ent.FechaEmision).format("YYYY-MM-DDTHH:mm:ss");

    if (!ent.SucursalDestino) {
      this.eventsService.publish('home:showAlert', { message: `Debe de indicar la sucursal destino.`, cancelButton: false });
      return;
    }

    if (ent.SucursalDestino) {
      if (ent.SucursalDestino.Id == ent.Sucursal.Id) {
        this.eventsService.publish('home:showAlert', { message: `La Sucursal destino no puede ser la misma que la Sucursal origen.`, cancelButton: false });
        return;
      }
    }

    ent.Conceptos = this.source.filter(P => P.NoIdentificacion != '');

    if (ent.Conceptos.length == 0) {
      this.eventsService.publish('home:showAlert', { message: `Debe de indicar por lo menos un concepto en el Traspaso.`, cancelButton: false });
      return;
    }

    if (ent.Conceptos.filter((P: any) => P.Cantidad <= 0).length > 0) {
      this.eventsService.publish('home:showAlert', {
        message: "No puede haber conceptos con cantidad menor o igual a cero, por favor verifique.", cancelButton: false, onConfirm: (data: AlertResponse) => {
          return;
        }
      });
      return;
    }

    this.verificarEntregaNuestra(() => {
      this.eventsService.publish('home:isLoading', { isLoading: true });
      this.http.post<Result>(`${this.baseUrl}/Inventarios/GuardarTraspaso`, ent).subscribe((result) => {
        if (result.success) {
          Swal.fire({ position: 'center', icon: 'success', title: 'Se guardó correctamente', showConfirmButton: false, timer: 1000 }).then(() => {
            this.findEntityByParams(() => {
              setTimeout(() => {
                this.eventsService.publish('home:isLoading', { isLoading: false });
                this.print()
              }, 150);
            });
          });
        } else {
          this.eventsService.publish('home:isLoading', { isLoading: false });
          this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
        }
      })
    })

  }

  verificarEntregaNuestra(cb: any) {
    const sel: boolean = this.myForm.value.EntregaNuestra;
    this.eventsService.publish('home:showAlert', {
      textAccept: "Si, Continuar",
      icon: "",
      message: sel ? 'Ha seleccionado que SI se entrega por nuestra cuenta, ¿es correcto?' : 'Ha seleccionado que NO se entrega por nuestra cuenta, ¿es correcto?',
      onConfirm: (data: AlertResponse) => {

        if (data.isAccept) {
          cb && cb();
        }
      }
    });
  }




  acceptDescription() {

    this.source[this.rowEditandoDescricionAdic].Descripcion = this.myForm.value.DescripcionAdicional;
    this.showDescription = false;
    this.source = [...this.source];
    //todo
    // this.navigateColumns && this.initEditor(this.rowEditandoDescricionAdic, this.colCantidad);

  }
  cancelDescription() {
    this.showDescription = false;
    //todo
    // this.navigateColumns && this.initEditor(this.rowEditandoDescricionAdic, this.colProducto);
  }

  activeButtons: ActiveButtons = {
    new: true,
    delete: false,
    return: true,
    save: true,
    first: true,
    left: true,
    right: true,
    last: true,
    search: false,
    print: true,
  }

  onClickBarButton(button: string): void {
    switch (button) {
      case "new": this.new(); break;
      case "save": this.save(); break;
      case "print": this.print(); break;
      case "first": this.navigate(button); break;
      case "left": this.navigate(button); break;
      case "right": this.navigate(button); break;
      case "last": this.navigate(button); break;
      case "return": this.findEntityByParams();
        // setTimeout(() => {
        //   const txtFolio: any = this.txtFolio;
        //   txtFolio.tagInput.nativeElement.focus();
        // }, 100);
        break;
    }
  }

  print() {

    let reportHeader: ReportHeader = {
      Fecha1: this.myForm.get('FechaEmision')?.value,
      Fecha2: this.myForm.get('FechaEmision')?.value,
      NombreReporte: 'VERIFICADOR DE TRASPASOS ' + this.myForm.get('SerieString')?.value + this.myForm.get('Folio')?.value,
      Dato1: '',
      Opc1: true,
      Opc2: true
    }
    let filtro: FilterOptions | undefined = this.filtroTraspasos.FilterOptions?.find(p => p.Campo == 'tras.Id');
    if (filtro) {
      filtro!.Valor = String(this.myForm.get('Id')?.value);
    }
    this.filtroTraspasos.ReportHeader = reportHeader;
    this.reportsService.printReport(this.filtroTraspasos, '/Inventarios/ImprimirFormatoTraspaso');

  }


  // print() {
  //   this.imprimirFormato();
    // if (this.myForm.value.Id == 0) {
    //   this.eventsService.publish('home:showAlert', { message: `Es necesario que primero guarde el Traspaso antes de poder imprimirlo, por favor verifique.`, cancelButton: false });
    //   return;
    // }

    // let reportHeader: ReportHeader = {
    //   Fecha1: this.myForm.get('FechaEmision')?.value,
    //   Fecha2: this.myForm.get('FechaEmision')?.value,
    //   NombreReporte: 'VERIFICADOR DE TRASPASOS ' + this.myForm.get('SerieString')?.value + this.myForm.get('Folio')?.value,
    //   Dato1: '',
    //   Opc1: true,
    //   Opc2: true
    // }
    // let filtro: FilterOptions | undefined = this.filtroTraspasos.FilterOptions?.find(p => p.Campo == 'tras.Id');
    // if (filtro) {
    //   filtro!.Valor = String(this.myForm.get('Id')?.value);
    // }
    // this.filtroTraspasos.ReportHeader = reportHeader;
    // this.reportsService.printReport(this.filtroTraspasos, '/Inventarios/VerificadorDeTraspasos');
  //}


  blurFolio(values: any) {
    if (values.after != values.before) {
      this.findEntityByParams();
    }
  }

  focoComentarios(value: any) {
    this.utilsService.setHelpLine("Indique un comentario para el movimiento de Traspaso.");
  }

  findEntityByParams(cb: any = null) {
    const ent = this.myForm.value;
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.invService.obtenerTraspaso(ent.Empresa.Id, ent.Sucursal.Id, ent.SerieString, ent.Folio).subscribe((traspaso) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      this.setEntity(traspaso, cb);
    });
  }
}
