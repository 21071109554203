<app-container-base (onItemsComboSearched)="onItemsComboSearched($event)" (onItemSearched)="onItemSearched($event)"
  [entity]="myForm" (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)"
  entityName="DocumentoCompra" title="Documentos de Compras" icon="fa fa-newspaper"
  subTitle="Administra la configuración de Documentos de Compras">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Información">
        <div class="panel-content">
          <component-ui-combo-box (onNewCatalog)="onNewCatalog($event)" [label]="'Clave'" [widthList]="350"
            (onChangueEntity)="onChangueEntity($event)" formControlName="Id" [isCatalog]="true" [isNewItem]="getIsNew"
            [zeroMask]="3" [setItems]="getItemsCombo" [tabIndex]="1" [listProperty]="'ClaveNombre'"
            [widthTextColumns]="1" [widthLabelColumns]="3" entityName="DocumentoCompra" />

          <components-ui-text-box [tabIndex]="2" [label]="'Nombre'" formControlName="Nombre" [widthTextColumns]="4"
            [widthLabelColumns]="3" [required]="true" [maxLength]="150" #txtNombre />

          <components-ui-text-box [tabIndex]="3" [label]="'Imprimir'" formControlName="TextoImprimir"
            [widthTextColumns]="4" [widthLabelColumns]="3" [required]="true" [maxLength]="100" />

          <component-ui-combo-box [label]="'Clasificación'" [required]="true" formControlName="Clasificacion"
            [tabIndex]="4" [zeroMask]="2" entityName="ClasificacionCompra" [widthTextColumns]="2"
            [widthLabelColumns]="3" [widthRightColumns]="7" (onSelectedItem)="selectedCombo($event,'Clasificacion')"
            [listProperty]="'ClaveNombre'" />

            <component-ui-check-box [tabIndex]="5" [widthLabelColumns]="3" formControlName="CopiarRequerido"
            [label]="'Copiar Requerido'" />

          <components-ui-text-box [tabIndex]="6" [label]="'Documentos que puede copiar'" formControlName="DocumentosCopiar"
            [widthTextColumns]="4" [widthLabelColumns]="3" [maxLength]="150" />

          <div class="card-header h6 mb-1 bg-none p-2 text-orange">
            <i class="fa fa-car-rear fa-lg fa-fw me-1"></i>
            Back Order controla las entregas de los documentos, útil cuando hay entregas a los clientes por partes
          </div>

          <component-ui-check-box [tabIndex]="7" [widthLabelColumns]="3" formControlName="LlevaBackOrder"
            [label]="'Lleva Back Order'" />

          <component-ui-check-box [tabIndex]="8" [widthLabelColumns]="3" formControlName="AfectaBackOrder"
            [label]="'Afecta Back Order'" />


          <component-ui-check-box [label]="'Mismo usuario elabora es el que modifica'"
            formControlName="SoloUsuarioElaboraModifica" [tabIndex]="9" [widthTextColumns]="3"
            [widthLabelColumns]="3" />

          <div class="card-header h6 mb-1 bg-none p-2 text-orange">
            <i class="fa fa-user-clock fa-lg fa-fw me-1"></i>
            Configuración para generar Cuentas Por Pagar
          </div>

          <component-ui-combo-box [label]="'Cuenta de CXP'" formControlName="CuentaCXP" [tabIndex]="10" [zeroMask]="2"
            entityName="CuentaCxp" [widthTextColumns]="2" [widthLabelColumns]="3" [widthRightColumns]="7"
            (onSelectedItem)="selectedCombo($event,'CuentaCXP')" [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box [label]="'Concepto de CXP'" formControlName="ConceptoCXP" [tabIndex]="11"
            [zeroMask]="2" entityName="ConceptoCxp" [widthTextColumns]="2" [widthLabelColumns]="3"
            [widthRightColumns]="7" (onSelectedItem)="selectedCombo($event,'ConceptoCXP')"
            [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box [label]="'Tipo de Movimiento'" formControlName="TipoMovimientoCxp" [tabIndex]="12"
            [zeroMask]="2" entityName="TipoMovimientoCxp" [widthTextColumns]="2" [widthLabelColumns]="3"
            [widthRightColumns]="7" (onSelectedItem)="selectedCombo($event,'TipoMovimientoCxp')"
            [listProperty]="'ClaveNombre'" />

          <div class="card-header h6 mb-1 bg-none p-2 text-orange">
            <i class="fa fa-boxes-stacked fa-lg fa-fw me-1"></i>
            Configuración para generar Inventario
          </div>

          <component-ui-combo-box [label]="'Tipo movimiento'" formControlName="TipoMovimientoAlmacen" [tabIndex]="13"
            [zeroMask]="2" entityName="TipoMovimientoAlmacen" [widthTextColumns]="2" [widthLabelColumns]="3"
            [widthRightColumns]="7" (onSelectedItem)="selectedCombo($event,'TipoMovimientoAlmacen')"
            [listProperty]="'ClaveNombre'" />


          <component-ui-combo-box [label]="'Almacén'" formControlName="Almacen" [tabIndex]="14" [zeroMask]="2"
            entityName="Almacen" [widthTextColumns]="2" [widthLabelColumns]="3" [widthRightColumns]="7"
            (onSelectedItem)="selectedCombo($event,'Almacen')" [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box [label]="'Concepto almacén'" formControlName="ConceptoAlmacen" [tabIndex]="15"
            [zeroMask]="2" entityName="ConceptoAlmacen" [widthTextColumns]="2" [widthLabelColumns]="3"
            [widthRightColumns]="7" (onSelectedItem)="selectedCombo($event,'ConceptoAlmacen')"
            [listProperty]="'ClaveNombre'" />
          <div class="card-header h6 mb-1 bg-none p-2 text-orange">
            <i class="fa fa-car-side fa-lg fa-fw me-1"></i>
            Configuración para vehículos
          </div>

          <component-ui-check-box [label]="'Solicitar Información de vehículo'" formControlName="SolicitaInfoVehiculos"
            [tabIndex]="16" [widthTextColumns]="3" [widthLabelColumns]="3" />
        </div>
      </app-panel-base>
    </form>
  </div>
</app-container-base>
