import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { ReportFilter, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ReportsService } from 'src/app/service/reports.service';

@Component({
  selector: 'app-auditoria-tokens-credito',
  templateUrl: './auditoria-tokens-credito.component.html',
  styleUrls: ['./auditoria-tokens-credito.component.scss']
})
export class AuditoriaTokensCreditoComponent {

  @ViewChild('txtNombreReporte')
  public txtNombreReporte!: ElementRef<TextBoxComponent>;

  private reportsService = inject(ReportsService);

  listaContenido: any[] = [
    {
      Id: 1, Nombre: "Solo Abajo del Costo"
    },
    {
      Id: 2, Nombre: "Solo No Abajo del Costo"
    },
    {
      Id: 3, Nombre: "Todos"
    }
  ];

  listaTipo: any[] = [
    {
      Id: 1, Nombre: "Documentos Guardados"
    },
    {
      Id: 2, Nombre: "Documentos NO Guardados"
    },
    {
      Id: 3, Nombre: "Todos"
    }
  ];

  contenidoSeleccionado?: ComboBoxEntity;
  tipoSeleccionado?: ComboBoxEntity;

  activeButtons: ActiveButtons = {
    all: false,
    print: true,
  }

  private frmBuilder = inject(FormBuilder);

  public frmAuditoriaTokens: FormGroup = this.frmBuilder.group({
    NombreReporte: ["AUDITORÍA DE GENERACIÓN DE TOKEN DE CRÉDITOS", [Validators.required]],
    Fecha1: [new Date(), [Validators.required]],
    Fecha2: [new Date(), [Validators.required]],
    Num1: [3]
  });

  focus() {
    setTimeout(() => {
      const txt: any = this.txtNombreReporte;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }


  ngOnInit() {
    setTimeout(() => {
      this.contenidoSeleccionado = this.listaContenido[2];
      this.tipoSeleccionado = this.listaTipo[2];
      this.focus();
    }, 300);
  }

  public filtrosAuditoriaCredito: ReportFilter =
    {
      menuId: 'auditoriatokenscreditos',
      ReportHeader: {
        Fecha1: new Date(),
        Fecha2: new Date(),
        NombreReporte: 'Auditoría de Generación de Token de Créditos'
      } as ReportHeader,
      Fecha1: new Date(),
      Fecha2: new Date(),
      NombreReporte: 'Auditoría de Generación de Token de Créditos',
      TituloVisor: 'Auditoría de Generación de Token de Créditos',
      NombreExcel: 'Auditoria_Generacion_Token_Creditos.xlsx',
      FilterOptions: [
        { Campo: 'suc.Clave', Etiqueta: 'Sucursal', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Sucursal" },
        { Campo: 'cli.Clave', Etiqueta: 'Cliente', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Cliente" },
        { Campo: 'genera.Clave', Etiqueta: 'Usuario Generó', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Usuario" },
        { Campo: 'usa.Clave', Etiqueta: 'Usuario Usó', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Usuario" }
      ]
    };

  imprimir(event: any) {
    this.filtrosAuditoriaCredito.ReportHeader = this.frmAuditoriaTokens.value;
    this.reportsService.printReport(this.filtrosAuditoriaCredito, '/Config/ReporteAuditoriaTokensCredito');
  }

  changeselec(event: any) {
    this.filtrosAuditoriaCredito = event;
  }

  selectedCombo(entity: any, tipo: string) {
    if (tipo === "contenido") {
      this.filtrosAuditoriaCredito.ReportHeader.Num1 = entity.Id;
    } else {
      this.filtrosAuditoriaCredito.ReportHeader.Num2 = entity.Id;
    }
  }

  get selectContenidoItem(): ComboBoxEntity | null {
    return this.contenidoSeleccionado!;
  }

  get selectTipoItem(): ComboBoxEntity | null {
    return this.tipoSeleccionado!;
  }
}
