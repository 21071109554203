<app-container-base subTitle="Administrador de CFDIs" icon="fas fa-folder-tree" [hasCounter]="false" [hasBar]="false"
  idComponent="file-manager-component">
  <div class="screen-content" [style.height.px]="scrollHeihgt">
    <div class="file-manager h-100" [ngClass]="{ 'file-manager-sidebar-mobile-toggled': mobileSidebarToggled }">

      <div class="p-2">
        <form [formGroup]="myForm" autocomplete="off">

          <div class="row">
            <div class="col-2">
              <div class="btn-group">
                <div (click)="selEmitidos()" type="button"
                  [class]="'btn btn-sm btn-white d-block' + (!emitidos?'':' active')">
                  <i [class]="'fa'+(!emitidos?'r':'')+' fa-fw fa-'+ (emitidos?'check':'square')+' ms-n1'"></i>
                  Emitidos
                </div>
                <div (click)="selEmitidos()" type="button"
                  [class]="'btn btn-sm btn-white d-block' + (!recibidos?'':' active')">
                  <i [class]="'fa'+(!recibidos?'r':'')+' fa-fw fa-'+ (recibidos?'check':'square')+' ms-n1'"></i>
                  Recibidos
                </div>
              </div>
            </div>
            <div class="col-2">
              <div class="row">
                <div class="col-6">
                  <input type="date" class="form-control"
                    formControlName="fechaInicioString">
                </div>
                <div class="col-6">
                  <input type="date" class="form-control"
                    formControlName="fechaFinString">
                </div>
              </div>
            </div>
            <div class="col-2">
              <div class="position-relative">
                <input type="text" class="form-control ps-35px rounded-3" formControlName="busqueda"
                  placeholder="Texto a buscar" #txtBuscarCfdi>
                <button class="btn position-absolute start-0 top-0 shadow-none"><i
                    class="fa fa-search fa-lg text-orange"></i></button>
              </div>
            </div>
            <div class="col-2">
              <div class="col-lg-12 d-lg-block d-none">
                <a href="#" class="btn btn-white d-flex align-items-center rounded-3" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  <i class="fa fa-lg fa-tags me-10px text-body text-opacity-50 d-none"></i>
                  <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em"
                    viewBox="0 0 24 24" data-icon="solar:tag-linear"
                    class="iconify fs-20px my-n1 me-2 ms-n1 text-theme iconify--solar">
                    <g fill="none" stroke="currentColor" stroke-width="1.5">
                      <path
                        d="M4.728 16.137c-1.545-1.546-2.318-2.318-2.605-3.321c-.288-1.003-.042-2.068.45-4.197l.283-1.228c.413-1.792.62-2.688 1.233-3.302c.614-.613 1.51-.82 3.302-1.233l1.228-.284c2.13-.491 3.194-.737 4.197-.45c1.003.288 1.775 1.061 3.32 2.606l1.83 1.83C20.657 9.248 22 10.592 22 12.262c0 1.671-1.344 3.015-4.033 5.704c-2.69 2.69-4.034 4.034-5.705 4.034c-1.67 0-3.015-1.344-5.704-4.033z">
                      </path>
                      <circle cx="8.607" cy="8.879" r="2" transform="rotate(-45 8.607 8.879)"></circle>
                      <path stroke-linecap="round" d="m11.542 18.5l6.979-6.98"></path>
                    </g>
                  </svg>
                  Tipo Comprobante = {{!myForm.value.tipoDeComprobante ? 'Todos': myForm.value.tipoDeComprobante}}
                  <i class="fa ms-auto fa-chevron-down"></i>
                </a>
                <ul class="dropdown-menu">
                  <li (click)="selectedCombo('I', 'tipoDeComprobante')"><a
                      class="dropdown-item d-flex align-items-center"><i
                        class="fa fa-circle fs-7px me-2 ms-n1 text-success"></i> Ingreso</a></li>
                  <li (click)="selectedCombo('E', 'tipoDeComprobante')"><a
                      class="dropdown-item d-flex align-items-center"><i
                        class="fa fa-circle fs-7px me-2 ms-n1 text-body text-opacity-25"></i> Egreso</a></li>
                  <li (click)="selectedCombo('T', 'tipoDeComprobante')"><a
                      class="dropdown-item d-flex align-items-center"><i
                        class="fa fa-circle fs-7px me-2 ms-n1 text-danger"></i> Traslado</a></li>
                  <li (click)="selectedCombo('N', 'tipoDeComprobante')"><a
                      class="dropdown-item d-flex align-items-center"><i
                        class="fa fa-circle fs-7px me-2 ms-n1 text-warning"></i> Nómina</a></li>
                  <li (click)="selectedCombo('P', 'tipoDeComprobante')"><a
                      class="dropdown-item d-flex align-items-center"><i
                        class="fa fa-circle fs-7px me-2 ms-n1 text-primary"></i> Pago</a></li>
                  <li (click)="selectedCombo('TODOS', 'tipoDeComprobante')"><a
                      class="dropdown-item d-flex align-items-center"><i
                        class="fa fa-circle fs-7px me-2 ms-n1 text-primary"></i> Todos</a></li>
                </ul>
              </div>
            </div>
            <div class="col-2">
              <div class="col-lg-12 d-lg-block d-none">
                <a href="#" class="btn btn-white d-flex align-items-center rounded-3" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  <i class="fa fa-lg fa-tags me-10px text-body text-opacity-50 d-none"></i>
                  <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em"
                    viewBox="0 0 24 24" data-icon="solar:tag-linear"
                    class="iconify fs-20px my-n1 me-2 ms-n1 text-theme iconify--solar">
                    <g fill="none" stroke="currentColor" stroke-width="1.5">
                      <path
                        d="M4.728 16.137c-1.545-1.546-2.318-2.318-2.605-3.321c-.288-1.003-.042-2.068.45-4.197l.283-1.228c.413-1.792.62-2.688 1.233-3.302c.614-.613 1.51-.82 3.302-1.233l1.228-.284c2.13-.491 3.194-.737 4.197-.45c1.003.288 1.775 1.061 3.32 2.606l1.83 1.83C20.657 9.248 22 10.592 22 12.262c0 1.671-1.344 3.015-4.033 5.704c-2.69 2.69-4.034 4.034-5.705 4.034c-1.67 0-3.015-1.344-5.704-4.033z">
                      </path>
                      <circle cx="8.607" cy="8.879" r="2" transform="rotate(-45 8.607 8.879)"></circle>
                      <path stroke-linecap="round" d="m11.542 18.5l6.979-6.98"></path>
                    </g>
                  </svg>
                  Método Pago = {{!myForm.value.metodoDePago ? 'Todos': myForm.value.metodoDePago}}
                  <i class="fa ms-auto fa-chevron-down"></i>
                </a>
                <ul class="dropdown-menu">
                  <li (click)="selectedCombo('PUE','metodoDePago')"><a
                      class="dropdown-item d-flex align-items-center"><i
                        class="fa fa-circle fs-7px me-2 ms-n1 text-success"></i> PUE Pago en una sola exhibición</a>
                  </li>
                  <li (click)="selectedCombo('PPD','metodoDePago')"><a
                      class="dropdown-item d-flex align-items-center"><i
                        class="fa fa-circle fs-7px me-2 ms-n1 text-body text-opacity-25"></i> PPD Pago en
                      Parcialidades o diferido</a></li>
                  <li (click)="selectedCombo('TODOS','metodoDePago')"><a
                      class="dropdown-item d-flex align-items-center"><i
                        class="fa fa-circle fs-7px me-2 ms-n1 text-primary"></i> Todos</a></li>
                </ul>
              </div>
            </div>
            <div class="col-2">
              <div class="col-lg-12 d-lg-block d-none">
                <a href="#" class="btn btn-white d-flex align-items-center rounded-3" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  <i class="fa fa-lg fa-tags me-10px text-body text-opacity-50 d-none"></i>
                  <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em"
                    viewBox="0 0 24 24" data-icon="solar:tag-linear"
                    class="iconify fs-20px my-n1 me-2 ms-n1 text-theme iconify--solar">
                    <g fill="none" stroke="currentColor" stroke-width="1.5">
                      <path
                        d="M4.728 16.137c-1.545-1.546-2.318-2.318-2.605-3.321c-.288-1.003-.042-2.068.45-4.197l.283-1.228c.413-1.792.62-2.688 1.233-3.302c.614-.613 1.51-.82 3.302-1.233l1.228-.284c2.13-.491 3.194-.737 4.197-.45c1.003.288 1.775 1.061 3.32 2.606l1.83 1.83C20.657 9.248 22 10.592 22 12.262c0 1.671-1.344 3.015-4.033 5.704c-2.69 2.69-4.034 4.034-5.705 4.034c-1.67 0-3.015-1.344-5.704-4.033z">
                      </path>
                      <circle cx="8.607" cy="8.879" r="2" transform="rotate(-45 8.607 8.879)"></circle>
                      <path stroke-linecap="round" d="m11.542 18.5l6.979-6.98"></path>
                    </g>
                  </svg>
                  Póliza = {{!myForm.value.poliza ? 'Todos': myForm.value.poliza}}
                  <i class="fa ms-auto fa-chevron-down"></i>
                </a>
                <ul class="dropdown-menu">
                  <li *ngFor="let item of tiposPoliza" (click)="selectedCombo(item.abreviacion,'poliza')"><a
                      class="dropdown-item d-flex align-items-center"><i
                        class="fa fa-circle fs-7px me-2 ms-n1 text-success"></i> {{item.abreviacion}}
                      {{item.nombre}}</a>
                  </li>
                  <li (click)="selectedCombo('TODOS','poliza')"><a class="dropdown-item d-flex align-items-center"><i
                        class="fa fa-circle fs-7px me-2 ms-n1 text-primary"></i> Todos</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-2">
              <div class="col-lg-12">
                <span>Número de Póliza:</span>
                <input type="number" class="form-control ps-35px rounded-3" formControlName="numeroPoliza">
              </div>
            </div>
            <div class="col-2 mt-3">
              <div class="col-lg-12 d-lg-block d-none">
                <a href="#" class="btn btn-white d-flex align-items-center rounded-3" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  <i class="fa fa-lg fa-tags me-10px text-body text-opacity-50 d-none"></i>
                  <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em"
                    viewBox="0 0 24 24" data-icon="solar:tag-linear"
                    class="iconify fs-20px my-n1 me-2 ms-n1 text-theme iconify--solar">
                    <g fill="none" stroke="currentColor" stroke-width="1.5">
                      <path
                        d="M4.728 16.137c-1.545-1.546-2.318-2.318-2.605-3.321c-.288-1.003-.042-2.068.45-4.197l.283-1.228c.413-1.792.62-2.688 1.233-3.302c.614-.613 1.51-.82 3.302-1.233l1.228-.284c2.13-.491 3.194-.737 4.197-.45c1.003.288 1.775 1.061 3.32 2.606l1.83 1.83C20.657 9.248 22 10.592 22 12.262c0 1.671-1.344 3.015-4.033 5.704c-2.69 2.69-4.034 4.034-5.705 4.034c-1.67 0-3.015-1.344-5.704-4.033z">
                      </path>
                      <circle cx="8.607" cy="8.879" r="2" transform="rotate(-45 8.607 8.879)"></circle>
                      <path stroke-linecap="round" d="m11.542 18.5l6.979-6.98"></path>
                    </g>
                  </svg>
                  Estado Comprobante = {{!myForm.value.estado ? 'Todos': myForm.value.estado}}
                  <i class="fa ms-auto fa-chevron-down"></i>
                </a>
                <ul class="dropdown-menu">
                  <li (click)="selectedCombo('1', 'estado')"><a class="dropdown-item d-flex align-items-center"><i
                        class="fa fa-circle fs-7px me-2 ms-n1 text-success"></i> Vigentes</a></li>
                  <li (click)="selectedCombo('2', 'estado')"><a class="dropdown-item d-flex align-items-center"><i
                        class="fa fa-circle fs-7px me-2 ms-n1 text-body text-opacity-25"></i> Cancelados</a></li>
                  <li (click)="selectedCombo('TODOS', 'estado')"><a class="dropdown-item d-flex align-items-center"><i
                        class="fa fa-circle fs-7px me-2 ms-n1 text-primary"></i> Todos</a></li>
                </ul>
              </div>
            </div>
            <div class="col-2">
              <a (click)="search()" class="btn btn-primary btn-rounded px-4 btn-sm d-block mt-3">Buscar</a>
            </div>
          </div>
        </form>
      </div>

      <div class="file-manager-container">
        <div class="file-manager-content d-flex flex-column">
          <div class="row">
            <div class="col-lg-4">
              <div class="btn-group me-2 m-2 w-200px">
                <div (click)="selTodos()" type="button" [class]="'btn btn-sm btn-white' + (!todos?'':' active')"><i
                    [class]="'fa'+(!todos?'r':'')+' fa-fw fa-'+ (todos?'check':'square')+' ms-n1'"></i>Todos</div>
                <div (click)="selTodos()" type="button" [class]="'btn btn-sm btn-white' + (!ninguno?'':' active')">
                  <i [class]="'fa'+(!ninguno?'r':'')+' fa-fw fa-'+ (ninguno?'check':'square')+' ms-n1'"></i>
                  Ningúno
                </div>
              </div>
            </div>
            <div class="col-lg-5">
              <div class="btn-group me-2 m-2">
                <button *ngIf="emitidos" (click)="sendEmail()" [disabled]="getActivarOpciones" type="button"
                  class="btn shadow-none text-body border-0"><i class="fa fa-lg me-1 fa-envelopes-bulk text-green"></i>
                  Enviar por correo
                </button>
                <button (click)="descargarZip()" [disabled]="getActivarOpciones" type="button"
                  class="btn shadow-none text-body border-0"><i class="fa fa-lg me-1 fa-download text-green"></i>
                  Descargar .zip
                </button>
                <button (click)="validarEstado()" [disabled]="getActivarOpciones" type="button"
                  class="btn shadow-none text-body border-0"><i class="fa fa-lg me-1 fa-hamsa text-primary"></i>
                  Validar Estado
                </button>

                <button (click)="imprimirSeleccionados()" [disabled]="getActivarOpciones" type="button"
                  class="btn shadow-none text-body border-0"><i class="fa fa-lg me-1 fa-file-pdf text-red"></i>
                  Imprimir
                </button>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="file-manager-sidebar-footer">
                <div class="d-flex align-items-center">
                  <div class="flex-1 ps-3 small">
                    <div class="fw-bold">Registros: <b class="text-body">{{lista.length | number}}</b><b
                        class="text-body"></b></div>
                  </div>
                </div>
              </div>
            </div>

          </div>


          <div class="flex-1 overflow-hidden ms-2">

            <table class="blueTable" style="width:1270px;">
              <thead style="position: sticky;top:0;">
                <tr>
                  <th style="width: 30px;">Sel</th>
                  <th style="width: 30px;">PDF</th>
                  <th style="width: 30px;">XML</th>
                  <th style="width: 30px;">Vig</th>
                  <th style="width: 30px;">MP</th>
                  <th
                    [style]="'cursor: pointer;width:80px !important;'+((lastOrder=='tipoDeComprobante'?'background-color:orange;color:#ffffff;':''))"
                    (click)="ordenar('tipoDeComprobante')" class="px-10px">Tipo
                    <i *ngIf="lastOrder=='tipoDeComprobante'"
                      [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAsc=='asc'?'down':'up')+'-wide-short float-end'"></i>
                  </th>
                  <th
                    [style]="'cursor: pointer;width:200px !important;'+((lastOrder=='uuid'?'background-color:orange;color:#ffffff;':''))"
                    (click)="ordenar('uuid')" class="px-10px">UUID<i *ngIf="lastOrder=='uuid'"
                      [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAsc=='asc'?'down':'up')+'-wide-short float-end'"></i>
                  </th>
                  <th
                    [style]="'cursor: pointer;width:200px !important;'+((lastOrder=='emisorRfc'?'background-color:orange;color:#ffffff;':''))"
                    (click)="ordenar('emisorRfc')" class="px-10px">Emisor<i *ngIf="lastOrder=='emisorRfc'"
                      [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAsc=='asc'?'down':'up')+'-wide-short float-end'"></i>
                  </th>
                  <th
                    [style]="'cursor: pointer;width:200px !important;'+((lastOrder=='receptorRfc'?'background-color:orange;color:#ffffff;':''))"
                    (click)="ordenar('receptorRfc')" class="px-10px">Receptor<i *ngIf="lastOrder=='receptorRfc'"
                      [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAsc=='asc'?'down':'up')+'-wide-short float-end'"></i>
                  </th>
                  <th
                    [style]="'cursor: pointer;width:100px;'+((lastOrder=='serieFolio'?'background-color:orange;color:#ffffff;':''))"
                    (click)="ordenar('serieFolio')" class="px-10px text-center">Serie/Folio<i
                      *ngIf="lastOrder=='serieFolio'"
                      [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAsc=='asc'?'down':'up')+'-wide-short float-end'"></i>
                  </th>
                  <th
                    [style]="'cursor: pointer;width:80px;'+((lastOrder=='fechaEmision'?'background-color:orange;color:#ffffff;':''))"
                    (click)="ordenar('fechaEmision')" class="px-10px text-center">Emisión<i
                      *ngIf="lastOrder=='fechaEmision'"
                      [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAsc=='asc'?'down':'up')+'-wide-short float-end'"></i>
                  </th>
                  <th class="text-end"
                    [style]="'cursor: pointer;width:80px;'+((lastOrder=='subtotal'?'background-color:orange;color:#ffffff;':''))"
                    (click)="ordenar('subtotal')">Subtotal<i *ngIf="lastOrder=='subtotal'"
                      [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAsc=='asc'?'down':'up')+'-wide-short float-end'"></i>
                  </th>
                  <th class="text-end"
                    [style]="'cursor: pointer;width:80px;'+((lastOrder=='ivaTrasladado'?'background-color:orange;color:#ffffff;':''))"
                    (click)="ordenar('ivaTrasladado')">IVA T<i *ngIf="lastOrder=='ivaTrasladado'"
                      [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAsc=='asc'?'down':'up')+'-wide-short float-end'"></i>
                  </th>
                  <th class="text-end"
                    [style]="'cursor: pointer;width:80px;'+((lastOrder=='ivaRetenido'?'background-color:orange;color:#ffffff;':''))"
                    (click)="ordenar('ivaRetenido')">IVA R<i *ngIf="lastOrder=='ivaRetenido'"
                      [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAsc=='asc'?'down':'up')+'-wide-short float-end'"></i>
                  </th>
                  <th class="text-end"
                    [style]="'cursor: pointer;width:80px;'+((lastOrder=='isrRetenido'?'background-color:orange;color:#ffffff;':''))"
                    (click)="ordenar('isrRetenido')">ISR R<i *ngIf="lastOrder=='isrRetenido'"
                      [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAsc=='asc'?'down':'up')+'-wide-short float-end'"></i>
                  </th>
                  <th class="text-end"
                    [style]="'cursor: pointer;width:80px;'+((lastOrder=='total'?'background-color:orange;color:#ffffff;':''))"
                    (click)="ordenar('total')">Total<i *ngIf="lastOrder=='total'"
                      [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAsc=='asc'?'down':'up')+'-wide-short float-end'"></i>
                  </th>
                  <th style="width: 50px;" class="text-center">Póliza</th>
                  <th style="width: 80px;" class="text-center">F/Póliza</th>
                </tr>
              </thead>
            </table>
            <cdk-virtual-scroll-viewport itemSize="20" style="height: 100%;border-bottom: 1px solid #AAAAAA;">
              <table class="blueTable" style="width:1270px;">
                <tbody class="bg-light" style="font-family: math;">
                  <tr *cdkVirtualFor="let item of lista; let i = index;"
                    [style]="item.seleccionado ? 'background-color: #ebff01a1;': (item.tipoDeComprobante == 'PAGO' ? 'background-color: #dedbdb;': (item.vigente?'':'background-color: #ff000026;'))">
                    <td class="text-center" style="width:30px !important;">
                      <input (click)="seleccionar(i)" class="form-check-input" type="checkbox"
                        [checked]="item.seleccionado" value="">
                    </td>
                    <td class="text-center" style="width:30px !important;"><i (click)="print(item.uuid)"
                        class="fa fa-file-pdf text-red fa-lg" style="cursor:pointer;"></i></td>
                    <td class="text-center" style="width:30px !important;"><i (click)="downloadXML(item.uuid)"
                        class="fa fa-file-code text-warning fa-lg" style="cursor:pointer;"></i></td>
                    <td class="text-center" style="width:30px;"> <i
                        [class]="'fas fa-lg fa-fw me-10px  '+(item.vigente?'fa-circle-check text-green':'fa-circle-xmark text-red')+' '"></i>
                    </td>
                    <td class="text-center" style="width:30px;"> {{ item.metodoDePago | uppercase }}</td>
                    <td class="text-center" style="width:80px;"> {{ item.tipoDeComprobante | uppercase }}</td>
                    <td style="width:200px;padding-left: 5px;">{{item.uuid | uppercase}}</td>
                    <td style="width:200px;padding-left: 5px;">{{item.emisorRfc}}/{{item.emisorNombre}}</td>
                    <td style="width:200px;padding-left: 5px;">{{item.receptorRfc}}/{{item.receptorNombre}}</td>
                    <td style="width:100px;padding-left: 5px;">{{item.serieFolio}}</td>
                    <td style="width:80px;" class="text-center">{{item.fechaEmision | date: 'dd/MM/yyy'}}</td>
                    <td style="width:80px;padding-right: 5px;" class="text-end">{{item.subtotal| number:'1.2'}}</td>
                    <td style="width:80px;padding-right: 5px;" class="text-end">{{item.ivaTrasladado| number:'1.2'}}
                    </td>
                    <td style="width:80px;padding-right: 5px;" class="text-end">{{item.ivaRetenido| number:'1.2'}}</td>
                    <td style="width:80px;padding-right: 5px;" class="text-end">{{item.isrRetenido| number:'1.2'}}</td>
                    <td style="width:80px;padding-right: 5px;" class="text-end">{{item.total| number:'1.2'}}</td>
                    <td style="width:50px;" class="text-center">{{item.numeroPoliza>0 ? (item.tipoPoliza +
                      item.numeroPoliza):''}}</td>
                    <td style="width:80px;" class="text-center">{{item.fechaPoliza}}</td>
                  </tr>
                </tbody>
              </table>
            </cdk-virtual-scroll-viewport>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-container-base>
