import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Validators } from 'ngx-editor';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { Diseno, DisenoDetalle, TipoProduccion } from '../../interfaces/manufacura.interface';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { ConfiguracionService } from 'src/app/configuracion/service/configuracion.service';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { EventReturn } from 'src/app/component-ui/interfaces/container-base.interface';
import { EventsService } from 'src/app/service/events.service';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';
import { FacturacionService } from 'src/app/ventas/services/facturacion.service';
import { ModalService } from 'src/app/service/modal.service';
import { LevelTextBoxComponent } from '../../../component-ui/components/level-text-box/level-text-box.component';

@Component({
  selector: 'app-disenos-produccion-page',
  templateUrl: './disenos-produccion-page.component.html',
  styles: [
  ]
})
export class DisenosProduccionPageComponent {
  private eventsService = inject(EventsService);
  private fs = inject(FacturacionService);
  public itemsCombo: ComboBoxEntity[] = [];
  selectedIndex: number = -1;
  DisenoActual: DisenoDetalle | null = null;
  editando: boolean = false;
  editing: boolean = false;
  listaCompuestos: DisenoDetalle[] = [];
  indexEditing: number = -1;
  private newEntityObj = {
    Id: [0],
    Clave: [0],
    Empresa: [null],
    Detalle: [[]],
    Nombre: ['', [Validators.required]],
    Baja: [false],
    Eliminado: [false],
    EsBase: [true],
    EsEspecial: [false]
  };

  public myForm: FormGroup = this.fb.group(this.newEntityObj);

  public puestoActual: Diseno | any;
  @ViewChild('txtNombre')
  public txtNombre!: ElementRef<TextBoxComponent>;

  @ViewChild('ctrlEdicion')
  public ctrlEdicion!: ElementRef<HTMLElement>;


  // @ViewChild('cboPrueba')
  // public cboPrueba!: ElementRef<ComboBoxComponent>;

  constructor(private configuracionService: ConfiguracionService,
    private fb: FormBuilder, private containerService: ContainerBaseService, private ms: ModalService) { }

  onBeforeSave(param: EventReturn) {
    const compuestos = this.listaCompuestos.filter(P => P.Producto);
    this.myForm.controls["Detalle"].setValue(compuestos);
    const entity = this.myForm.value;
    if (!entity.Detalle) {
      this.eventsService.publish('home:showAlert', { message: "Indique los Componentes del Diseño.", cancelButton: false });
      param.callback(false);
      return;
    }

    if (entity.Detalle.length == 0) {
      this.eventsService.publish('home:showAlert', { message: "Indique los Componentes del Diseño.", cancelButton: false });
      param.callback(false);
      return;
    }
    console.log(entity);
    param.callback(true);
  }

  clicEspecial(check: boolean) {
    this.myForm.controls["EsBase"].setValue(!check);
  }

  clicBase(check: boolean) {
    this.myForm.controls["EsEspecial"].setValue(!check);
  }

  onAfterSave(entity: Diseno) {
    this.myForm.reset(entity);

    this.resetCompuestos(entity.Detalle);
  }

  onItemSearched(entity: Diseno): void {
    if (entity) {
      this.resetCompuestos(entity.Detalle);
      this.myForm.reset(entity);
      this.focus('txtNombre')
    }
  }

  onNewCatalog(next: number) {
    this.containerService.getEmptyEntity("Diseno").subscribe((elem) => {
      elem.Clave = next;
      this.myForm.reset(elem);
      this.resetCompuestos(elem.Detalle)
      this.focus("txtNombre")
    })
  }

  get getIsNew(): any {
    return this.myForm.value;
  }

  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombre;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  onChangueEntity(ent: any) {
    if (ent) {
      this.resetCompuestos(ent.Detalle);
      this.myForm.reset(ent);
    }
    this.focus('txtNombre')
  }

  onItemsComboSearched(items: ComboBoxEntity[]): void {
    this.itemsCombo = items;
  }

  get getItemsCombo(): ComboBoxEntity[] {
    return this.itemsCombo;
  }

  onDblClickRow(index: number) {
    if (this.editando) return;
    if (index == -1) {
      index = this.listaCompuestos.length - 1;
    }
    this.selectedIndex = index;
    const sel: any = this.listaCompuestos[this.selectedIndex];
    this.DisenoActual = sel;
    this.editando = true;

    setTimeout(() => {
      const b: any = this.ctrlEdicion;
      this.ms.openModal(b, (e: any) => {
        if (e) {
          this.editando = false;
          this.onCloseEdit(e);
        } else {
          this.editando = false;
        }
      }, 'xl');
    }, 150);
  }

  resetCompuestos(details: DisenoDetalle[] | null, continueEditing = false) {

    if (details) {
      this.listaCompuestos = [...details.filter(p => p.Producto)];
    } else {
      this.listaCompuestos = []
    }
    const length = this.listaCompuestos.length;
    this.listaCompuestos = [...this.listaCompuestos, {
      Id: 0,
      Producto: null,
      Cantidad: 0,
      Almacen: null,
      ConceptoSalida: null,
      Diseno: null,
      Existencia: 0,
      Baja: false,
    }];

    setTimeout(() => {
      this.editando = false;
      this.selectedIndex = -1;
      this.DisenoActual = null;
    }, 100);
  }
  get getSelectedCompuestoActual(): DisenoDetalle | null {
    return this.DisenoActual;
  }


  onCloseEdit(mov: DisenoDetalle): void {
    if (!mov) {
      this.editing = false;
      this.selectedIndex = -1;
      this.DisenoActual = null;
      this.resetCompuestos(this.listaCompuestos, false);
    } else {
      this.listaCompuestos[this.selectedIndex] = mov;
      this.resetCompuestos(this.listaCompuestos, true);
      this.editing = false;
      this.DisenoActual = null
    }
  }
  deleteRowCompuesto(index: number) {
    this.eventsService.publish('home:showAlert', {
      message: '¿Desea eliminar el registro?',
      textAccept: "Si, eliminar",
      textCancel: "Cancelar",
      icon: "fa-circle-question text-blue",
      onConfirm: (r: AlertResponse) => {
        if (r.isAccept) {
          this.listaCompuestos.splice(index, 1);
          this.listaCompuestos = [...this.listaCompuestos];
          this.resetCompuestos(this.listaCompuestos)
        }
      }
    });
  }

  @ViewChild('modalImpresionDisenos')
  public modalImpresionDisenos!: ElementRef<HTMLElement>;

  clickButtonBar(event: any) {
    console.log(event);
    if (event === "print") {
      this.ms.openModal(this.modalImpresionDisenos, (e: any) => {
        if (e) { }
      }, 'lg')
    }
  }

}
