<app-container-base [hasBar]="!editing && !saving && !addingcfdi && !esCaptura" [hasCounter]="false"
  icon="fa fa-money-bill-1" [activeButtons]="activeButtons" (onDelete)="onDelete()" [hasLineHelp]="!esCaptura"
  [containerInnerHeight]="esCaptura ? 400 :getWindowHeight" [hasHeader]="!esCaptura"
  (onClickBarButton)="onClickBarButton($event)" subTitle="Captura de Pólizas" [printManual]="true">
  <div class="screen-content" [style]="'height:'+(esCaptura?'500px;':'100%;')+'width:'+(esCaptura?'1200':'1300')+'px;'">
    <!-- <span *ngIf="saving" class="loaderfact"><i class="fas fa-spinner fa-spin spin"></i></span> -->
    <form [formGroup]="myForm" autocomplete="off">

      <div *ngIf="!esCaptura" class="card border-0 m-1">
        <div class="card-header bg-none p-3 h6 m-0 d-flex align-items-center" style="height: 20px;">
          <div class="card border-0 mb-0" style="height: 20px;">
            <div class="card-body" style="margin-top: -12px;">
              <div class="row">
                <div class="col-lg-2" style="text-align: left;">
                  <div *ngIf="!editing && !saving" class="mb-0 d-md-flex fw-bold">
                    <div class="ms-md-0 mt-md-0 mt-0 dropdown-toggle">
                      <a href="#" data-bs-toggle="dropdown" class="text-dark text-decoration-none">Opciones <b
                          class="caret"></b></a>
                      <div class="dropdown-menu dropdown-menu-end">
                        <a (click)="editarEncabezado()" style="height: 23px;" *ngIf="this.myForm.value.Id >0"
                          class="dropdown-item"><i class="fa fa-fw fa-edit fa-lg m-2"></i>Editar
                          Tipo/Fecha/Número de Póliza</a>
                        <a style="height: 23px;" *ngIf="this.myForm.value.Id >0" (click)="marcarPlantilla()"
                          class="dropdown-item"><i class="fa fa-fw fa-angles-up fa-lg m-2 "></i>Marcar como póliza
                          plantilla</a>
                        <a style="height: 23px;" *ngIf="this.myForm.value.Id ==0" (click)="verPlantillas()"
                          class="dropdown-item"><i class="fa fa-fw fa-copy fa-lg m-2 "></i>Copiar de plantillas</a>
                        <a style="height: 23px;" *ngIf="this.myForm.value.Id ==0" (click)="openSearch(true)"
                          class="dropdown-item"><i class="far fa-fw fa-copy fa-lg m-2 "></i>Copiar de otra póliza</a>
                        <a style="height: 23px;" class="dropdown-item"><i
                            class="fa fa-fw fa-file-excel fa-lg m-2 "></i>Importar póliza desde csv</a>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div *ngIf="this.myForm.value.Id == 0"><a class="animate__animated  animate__bounceIn"
              style="position: absolute;right: 1px;margin-top: -14px;background: yellow;padding: 7px;">NUEVO
              REGISTRO</a></div>
        </div>
      </div>
      <app-panel-base title="Encabezado de la póliza">
        <div class="panel-content">
          <div class="d-flex">
            <div style="width: 100px;margin-right: 2px;">
              <components-ui-date-box [enabled]="!editing && !saving && !addingcfdi && !esCaptura"
                (onChangeValue)="onChangeDate($event)" formControlName="Fecha" label="Fecha" [tabIndex]="1"
                [widthLabelColumns]="12" [topLabel]="true" #txtFecha />
            </div>
            <div style="width: 100px; margin-right: 2px;">
              <component-ui-combo-box [enabled]="!editing && !saving && !addingcfdi && !esCaptura"
                [inputProperty]="'Nombre'" formControlName="TipoPoliza" [tabIndex]="2" [entityName]="'TipoPoliza'"
                [topLabel]="true" (onSelectedItem)="onSelectedItem($event,'TipoPoliza')" label="Tipo"
                [widthTextColumns]="12" [widthLabelColumns]="12" [widthRightColumns]="0" #cboTipoPoliza />
            </div>
            <div style="width: 70px; margin-right: 2px;">
              <components-ui-number-box [enabled]="!editing && !saving && !addingcfdi && !esCaptura" [topLabel]="true"
                label='Número' formControlName="Folio" [tabIndex]="3" [widthLabelColumns]="12"
                (blur)="blurFolio($event)" [widthTextColumns]="12" #txtFolio />
            </div>
            <div class="d-flex">
              <div style="width: 680px;">
                <components-ui-text-box (blur)="blurConcepto($event)" (keydown.enter)="enterConcepto()"
                  [enabled]="!editing && !saving && !addingcfdi" [topLabel]="true" label='Concepto'
                  formControlName="ConceptoGeneral" [tabIndex]="4" [maxLength]="500" [widthLabelColumns]="12"
                  [widthTextColumns]="12" #txtConcepto />
              </div>
              <i *ngIf="this.myForm.value.TipoPoliza?.Id>0 && !addingcfdi && !editing && !esCaptura"
                (click)="openSearch()" style="cursor: pointer; color: #348fe2;margin-top: 18px;margin-left: 5px;"
                class="fas fa-magnifying-glass fa-2x"></i>
            </div>
          </div>
          <div [id]="divConta">
            <ul [id]="'ul-'+divConta" *ngIf="!esCaptura" class="bg-light nav nav-tabs  bg-light"
              style="cursor: pointer;width:1131px;margin-bottom: 2px;">
              <!-- <li (click)="changueMont(0)" class="nav-item"> <a data-bs-toggle="tab" bg-light nav nav-tabs
                  class="{{getIsMonthSelected(0)}}">Apertura</a> </li> -->
              <li *ngIf="myForm.value.TipoPoliza" [id]="'ul-child1-'+divConta" (click)="changueMont(1,$event)"
                class="nav-item"> <a data-bs-toggle="tab text-dark" class="{{getIsMonthSelected(1)}}">Enero</a>
              </li>
              <li *ngIf="myForm.value.TipoPoliza" [id]="'ul-child2-'+divConta" (click)="changueMont(2,$event)"
                class="nav-item"> <a data-bs-toggle="tab text-dark" class="{{getIsMonthSelected(2)}}">Febrero</a>
              </li>
              <li *ngIf="myForm.value.TipoPoliza" [id]="'ul-child3-'+divConta" (click)="changueMont(3,$event)"
                class="nav-item"> <a data-bs-toggle="tab text-dark" class="{{getIsMonthSelected(3)}}">Marzo</a>
              </li>
              <li *ngIf="myForm.value.TipoPoliza" [id]="'ul-child4-'+divConta" (click)="changueMont(4,$event)"
                class="nav-item"> <a data-bs-toggle="tab text-dark" class="{{getIsMonthSelected(4)}}">Abril</a>
              </li>
              <li *ngIf="myForm.value.TipoPoliza" [id]="'ul-child5-'+divConta" (click)="changueMont(5,$event)"
                class="nav-item"> <a data-bs-toggle="tab text-dark" class="{{getIsMonthSelected(5)}}">Mayo</a>
              </li>
              <li *ngIf="myForm.value.TipoPoliza" [id]="'ul-child6-'+divConta" (click)="changueMont(6,$event)"
                class="nav-item"> <a data-bs-toggle="tab text-dark" class="{{getIsMonthSelected(6)}}">Junio</a>
              </li>
              <li *ngIf="myForm.value.TipoPoliza" [id]="'ul-child7-'+divConta" (click)="changueMont(7,$event)"
                class="nav-item"> <a data-bs-toggle="tab text-dark" class="{{getIsMonthSelected(7)}}">Julio</a>
              </li>
              <li *ngIf="myForm.value.TipoPoliza" [id]="'ul-child8-'+divConta" (click)="changueMont(8,$event)"
                class="nav-item"> <a data-bs-toggle="tab text-dark" class="{{getIsMonthSelected(8)}}">Agosto</a>
              </li>
              <li *ngIf="myForm.value.TipoPoliza" [id]="'ul-child9-'+divConta" (click)="changueMont(9,$event)"
                class="nav-item"> <a data-bs-toggle="tab text-dark" class="{{getIsMonthSelected(9)}}">Septiembre</a>
              </li>
              <li *ngIf="myForm.value.TipoPoliza" [id]="'ul-child10-'+divConta" (click)="changueMont(10,$event)"
                class="nav-item"> <a data-bs-toggle="tab text-dark" class="{{getIsMonthSelected(10)}}">Octubre</a> </li>
              <li *ngIf="myForm.value.TipoPoliza" [id]="'ul-child11-'+divConta" (click)="changueMont(11,$event)"
                class="nav-item"> <a data-bs-toggle="tab text-dark" class="{{getIsMonthSelected(11)}}">Noviembre</a>
              </li>
              <li *ngIf="myForm.value.TipoPoliza" [id]="'ul-child12-'+divConta" (click)="changueMont(12,$event)"
                class="nav-item"> <a data-bs-toggle="tab text-dark" class="{{getIsMonthSelected(12)}}">Diciembre</a>
              </li>
            </ul>

            <ng-scrollbar cdkVirtualScrollViewport
              [style]="'height: '+(esCaptura?'150px;':'300px;')+'border-bottom: 1px solid #AAAAAA;'">
              <table class="blueTable" style="width: 1130px;">
                <thead style="position: sticky;top:0;">
                  <tr>
                    <th style="width: 40px;"></th>
                    <th style="width: 40px;text-align: center;">No</th>
                    <th style="width: 150px;">Cuenta</th>
                    <th style="width: 150px;">Nombre Cuenta</th>
                    <th style="width: 150px;">Centro Costo</th>
                    <th style="width: 150px;">Concepto</th>
                    <th style="width: 100px">Referencia</th>
                    <th style="width: 100px;">Cargo</th>
                    <th style="width: 100px;">Abono</th>
                    <th style="width: 100px;">Tipo de Cambio</th>
                    <th style="width: 50px;">CFDI</th>
                  </tr>
                </thead>
                <tbody class="bg-light">
                  <tr draggable='true' (dragstart)='start($event)' (dragover)='dragover($event)' (dragend)='dragEnd($event)'
                    (click)="selectRow(index)" (dblclick)="onDblClickRow(index)" [class]="getRowClass(item)"
                    [style]="(item.modificado ? 'color: red; font-weight: bold;':'') + (selectedIndex == index ?  'background-color:'+(appSettings.appDarkMode ? '#3363b3;':'#ffdbb7;'): ' ')"
                    *ngFor="let item of listaDetalles; let index = index;">
                    <td style="text-align: center;">
                      <i (click)="deleteRow(index)" *ngIf="item.cuenta && !addingcfdi && !editing"
                        class="fa fa-rectangle-xmark fa-lg" style="color:red;font-weight: bold; cursor: pointer;"></i>
                    </td>
                    <td style="text-align:center;">{{ item.cuenta ? item.orden : ''}}&nbsp;</td>
                    <td style="padding-left: 5px;">{{item.claveCuentaNivelGlobal}}</td>
                    <td style="padding-left: 5px;">{{item.cuenta ? item.cuenta.nombre:''}}</td>
                    <td style="padding-left: 5px;">{{item.claveCentroCostoNivelGlobal}}</td>
                    <td style="padding-left: 5px;">{{item.conceptoDetalle}}</td>
                    <td style="padding-left: 5px;">{{item.referencia}}</td>
                    <td style="text-align: right;padding-right: 5px;">{{ (!item.tipoMovimiento && item.cuenta) ?
                      (item.importe|number:'1.2') : ''}}</td>
                    <td style="text-align: right;padding-right: 5px;">{{ (item.tipoMovimiento && item.cuenta) ?
                      (item.importe|number:'1.2') : ''}}</td>
                    <td style="text-align: right;padding-right: 5px;">{{ (item.cuenta && item.tipoCambio != 0) ?
                      (item.tipoCambio|number:'1.2') : ''}}</td>
                    <td style="text-align: center;"> <i (click)="addCfdi(index)"
                        *ngIf="item.cuenta && !addingcfdi && !editing" class="fa fa-share-from-square fa-lg"
                        style="font-weight: bold; cursor: pointer;"></i></td>
                  </tr>

                </tbody>
              </table>
            </ng-scrollbar>
          </div>
        </div>
      </app-panel-base>
      <app-panel-base title="Totales y seguridad">
        <div class="panel-content">

          <div class="d-flex">
            <div *ngIf="!esCaptura" class="widget-list mb-0 bg-light" style="width: 526px; display: flex;">
              <div class="widget-list-item" style="width:50%;">
                <div *ngIf="this.myForm.value.UsuarioAlta" class="widget-list-media">
                  <i class="fas fa-user-check fa-2x text-green"></i>
                </div>
                <div *ngIf="this.myForm.value.UsuarioAlta" class="widget-list-content">
                  <h4 class="widget-list-title">{{this.myForm.value.UsuarioAlta.nombre}}</h4>
                  <p class="widget-list-desc">Usuario elaboró póliza</p>
                </div>
              </div>
              <div class="widget-list-item" style="width:50%;">
                <div *ngIf="this.myForm.value.UsuarioModifica" class="widget-list-media">
                  <i class="fas fa-user-edit fa-2x text-red"></i>
                </div>
                <div *ngIf="this.myForm.value.UsuarioModifica" class="widget-list-content">
                  <h4 class="widget-list-title">{{this.myForm.value.UsuarioModifica.nombre}}</h4>
                  <p class="widget-list-desc">Usuario modificó póliza</p>
                </div>
              </div>
            </div>
            <div style="width: 361px; border: 1px dashed;margin-left: 262px;">
              <div class="d-flex">
                <span style="width: 95px;text-align: right;" class="text-dark fw-bold fs-11px">Sumas (MXN):</span>
                <div style="width: 106px;text-align: right;" class="text-dark fw-bold fs-11px">{{ getCargosMXN |
                  number:'1.2'}}
                </div>
                <div style="width: 106px;text-align: right;" class="text-dark fw-bold fs-11px">{{ getAbonosMXN |
                  number:'1.2'}}
                </div>
              </div>
              <!-- <div class="d-flex ">
                <span style="width: 95px;text-align: right;" class="text-dark fw-bold fs-11px">Diferencia:</span>
                <div style="width: 106px;text-align: right;" class="text-dark fw-bold fs-11px">{{ getDiffMXN |
                  number:'1.2'}}
                </div>
              </div> -->
              <div class="d-flex ">
                <span style="width: 95px;text-align: right;" class="text-dark fw-bold fs-11px">Diferencia MXN:</span>
                <div style="width: 106px;text-align: right;" class="text-dark fw-bold fs-11px">{{ getDiffMXN |
                  number:'1.2'}}
                </div>
              </div>
              <div class="d-flex ">
                <span style="width: 95px;text-align: right;" class="text-dark fw-bold fs-11px">Total asientos:</span>
                <div style="width: 106px;text-align: right;" class="text-dark fw-bold fs-11px">{{ getListCount |
                  number}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </app-panel-base>
      <ng-template let-modal let-c="close" #ctrlBusquedaPoliza>
        <busqueda-polizas-page [esPlantilla]="esPlantilla" [anio]="this.myForm.value.Fecha.getFullYear()"
          [mes]="this.myForm.value.Fecha.getMonth()+1" [idEmpresa]="info.empresa!.numero"
          [idTipoPoliza]="this.myForm.value.TipoPoliza.Id" />
      </ng-template>

      <ng-template let-modal let-c="close" #ctrlEdicionPoliza>
        <ctrl-edicion-poliza-page [esCaptura]="esCaptura" [fechaPoliza]="myForm.value.Fecha" [totalCargos]="getCargos"
          [totalAbonos]="getAbonos" [totalDiferencia]="getDiff" [tabIndex]="50" [item]="getSelectedItem" />
      </ng-template>
      <ng-template let-modal let-c="close" #ctrlAgregarCfdi>
        <app-ctrl-buscador-cfdi [uuidsAgregados]="getComprobantesNacionales" [title]="getTitleCfdi"
          [anio]="myForm.value.Fecha.getFullYear()" [mes]="myForm.value.Fecha.getMonth()" [setIsNew]="addingcfdi" />
      </ng-template>
      <ng-template let-modal let-c="close" #ctrlEdicionEncabezado>
        <app-edicion-encabezado-page [PolizaEdicion]="PolizaEdicion" />
      </ng-template>
    </form>
  </div>
</app-container-base>
