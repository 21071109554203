<app-container-base subTitle="Existencias" (onClickBarButton)="eventosBotones($event)" [activeButtons]="activeButtons"
  [isReport]="true" icon=" fas fa-list-check" [hasCounter]="false">
  <div class="screen-content">
    <form [formGroup]="frmConteoInventario" autocomplete="off">
      <app-panel-base title="Generales">
        <div class="panel-content">
          <div class="col-md-6">
            <components-ui-text-box helpLine="Nombre del encabezado del reporte impreso." [required]="true"
              label='Nombre del Reporte' formControlName="NombreReporte" [tabIndex]="1" [widthLabelColumns]="5"
              [widthTextColumns]="7" [maxLength]="100" #txtNombreReporte />
          </div>
          <div class="col-md-6">
            <components-ui-date-box helpLine="Fecha final a consultar." label="Al" formControlName="Fecha1"
              [tabIndex]="2" [widthLabelColumns]="5" [widthTextColumns]="5"></components-ui-date-box>
          </div>
          <div class="col-md-6">
            <component-ui-combo-box helpLine="Tipo de existencias a imprimir." label='Tipo' [zeroMask]="2"
              [setItemsList]="this.tipos" [tabIndex]="3" [widthTextColumns]="3" [widthLabelColumns]="5"
              (onSelectedItem)="selectedCombo($event,'Tipo')" inputProperty="ClaveNombre" listProperty="ClaveNombre"
              [selectManualItem]="selectTipoManualItem" formControlName="Num1"
              [enabled]="!frmConteoInventario.value.Opc4" />
          </div>
          <div class="col-md-6">
            <component-ui-combo-box helpLine="Tipo de agrupador para impresión del reporte." label='Agrupar por'
              [tabIndex]="4" [zeroMask]="2" [setItemsList]="this.agrupadores" [tabIndex]="4" [widthTextColumns]="3"
              [widthLabelColumns]="5" [enabled]="!frmConteoInventario.value.Opc4"
              (onSelectedItem)="selectedCombo($event,'Agrupador')" inputProperty="ClaveNombre"
              listProperty="ClaveNombre" [selectManualItem]="selectAgrupadorManualItem" formControlName="Num2" />
          </div>
          <div class="col-md-6">
            <component-ui-check-box
              helpLine="Indica si se desea incluir las columnas Máximos, Mínimos y Reorden en la impresión."
              label='Incluir Máximos, Mínimos y Reorden' formControlName="Opc1" [tabIndex]="5" [widthLabelColumns]="5"
              [widthTextColumns]="3" (onClick)="onClickCheckBox('opc1',$event)"
              [enabled]="!frmConteoInventario.value.Opc4" />
          </div>
          <div class="col-md-6" *ngIf="!noVerCostos">
            <component-ui-check-box helpLine="Indica si se desea incluir las columnas de Costos en la impresión."
              label='Agregar Costos' formControlName="Opc3" [tabIndex]="6" [widthLabelColumns]="5"
              [widthTextColumns]="3" (onClick)="onClickCheckBox('opc3',$event)"
              [enabled]="!frmConteoInventario.value.Opc4" />
          </div>
          <div class="col-md-6" *ngIf="!noVerCostos">
            <component-ui-combo-box helpLine="Tipo de costo a consultar." label='Tipo de costo' [zeroMask]="2"
              [setItemsList]="this.tiposCostos" [tabIndex]="7" [widthTextColumns]="4" [widthLabelColumns]="5"
              (onSelectedItem)="selectedCombo($event,'TipoCostos')" inputProperty="ClaveNombre"
              [enabled]="frmConteoInventario.value.Opc3 || !frmConteoInventario.value.Opc4" listProperty="ClaveNombre"
              [selectManualItem]="selectTipoCostoItem" formControlName="Num3" />
          </div>
          <div class="col-md-6">
            <component-ui-check-box label='Agrupar por Almacén' helpLine="Indica si se necesita agrupar por almacén."
              formControlName="Opc2" [tabIndex]="8" [widthLabelColumns]="5" [widthTextColumns]="3"
              (onClick)="onClickCheckBox('opc2',$event)" [enabled]="!frmConteoInventario.value.Opc4" />
          </div>
          <div class="col-md-6">
            <component-ui-check-box label='Ver existencias por Sucursal'
              helpLine="Indica si se necesita ver las existencias por sucursal." formControlName="Opc4" [tabIndex]="9"
              [widthLabelColumns]="5" [widthTextColumns]="3" (onClick)="onClickCheckBox('opc4',$event)" />
          </div>
        </div>
      </app-panel-base>
      <app-panel-base title="Filtros">
        <div class="panel-content">
          <component-ui-selection-filter [(FiltrosSeleccion)]="this.filtrosExistencia" [tabIndex]="10"
            (FiltrosSeleccionChange)="changeselec($event)"
            [ReportHeader]="this.frmConteoInventario"></component-ui-selection-filter>
        </div>
      </app-panel-base>
    </form>
  </div>
</app-container-base>
