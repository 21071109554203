import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { ReportFilter, ReportFilterOrderList, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ReportsService } from 'src/app/service/reports.service';
import { UtilsService } from 'src/app/service/utils.service';

@Component({
  selector: 'informe-utilidad-page',
  templateUrl: './informe-utilidad-page.component.html',
})
export class InformeUtilidadPageComponent {


  @ViewChild('txtNombreReporte')
  public txtNombreReporte!: ElementRef;

  private _utils = inject(UtilsService);

  menuId: string = "informeutilidad";
  filtrosDiarioVentasPredefinidos: ReportFilter = {} as ReportFilter;

  public ordenadoLista: ReportFilterOrderList[] = [
    { Nombre: 'Ninguno', Orden: '[cli.Clave],[doc.Clave]', Id: 0 },
    { Nombre: 'Empresa-Sucursal', Orden: 'empClave,sucClave,[cli.clave],[doc.Clave]', Id: 1 }]

  public luegoPorLista: ReportFilterOrderList[] = [
    { Nombre: 'Ninguno', Orden: '', Id: 0 },
    { Nombre: 'Documento de Venta', Orden: 'docClave', Id: 1, Agrupador: 'doc.Clave' },
    { Nombre: 'Cliente', Orden: 'cliClave', Id: 2, Agrupador: 'cli.Clave' }

  ]


  public filtrosDiarioVentas: ReportFilter =
    {
      menuId: this.menuId,
      ReportHeader: {} as ReportHeader,
      Desglose: 'a Detalle',
      DesgloseLista: [{ Nombre: 'a Detalle' },
      { Nombre: 'a Totales' }],
      OrdenadoLista: this.ordenadoLista,
      LuegoPorLista: this.luegoPorLista,
      Fecha1: new Date(),
      Fecha2: new Date(),
      NombreReporte: '',
      TituloVisor: 'Informe de Utilidad',
      NombreExcel: 'Informe de Utilidad.xlsx',
      FilterOptions: [
        { Campo: 'emp.Clave', Etiqueta: 'Empresa', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Empresa" },
        { Campo: 'suc.Clave', Etiqueta: 'Sucursal', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Sucursal" },
        { Campo: 'vta.SerieString', Etiqueta: 'Serie', Tipo: TypeFilter.text },
        { Campo: 'vta.Folio', Etiqueta: 'Folio', Tipo: TypeFilter.number },
        { Campo: 'cli.Clave', Etiqueta: 'Cliente', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Cliente" },
        { Campo: 'metodo.Clave', Etiqueta: 'Método Pago', Tipo: TypeFilter.number, Entidad: "MetodoPago", BotonBusqueda: true },
      ]
    };


  public myForm: FormGroup = this.fb.group({
    Fecha1: new Date(),
    Fecha2: new Date(),
    NombreReporte: '',
    Opc1: false,
    Opc2: false
  });

  activeButtons: ActiveButtons = {
    all: false,
    print: true,
  }

  clicCosto(value: boolean, tipoCosto: string) {
    if (tipoCosto == 'R'){
      this.myForm.get('Opc1')?.setValue(true);
      this.myForm.get('Opc2')?.setValue(false);
    }else{
      this.myForm.get('Opc1')?.setValue(false);
      this.myForm.get('Opc2')?.setValue(true);
    }
  }


  constructor(private fb: FormBuilder, private reportsService: ReportsService) { }
  ngAfterViewInit(): void {

  }

  ngOnInit(): void {
    let fecha = new Date();
    this.myForm.get('Fecha1')?.setValue(fecha);
    this.myForm.get('NombreReporte')?.setValue('INFORME DE UTILIDAD ' + this.rangoFechas);
    this.focus('txtNombreReporte');
  }


  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombreReporte;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  onChangeFecha(value: any) {
    this.myForm.get('NombreReporte')?.setValue('INFORME DE UTILIDAD ' + this.rangoFechas);
  }

  get rangoFechas(): string {
    return this._utils.getEtiquetaRangoPorFechas(this.myForm.value.Fecha1, this.myForm.value.Fecha2, 2).toUpperCase();
  }


  changeselec(event: any) {
    this.filtrosDiarioVentas = event;
  }

  clickBoton(event: string) {
    this.filtrosDiarioVentas.ReportHeader = this.myForm.value;
    this.reportsService.printReport(this.filtrosDiarioVentas, '/Ventas/ReportesInformeUtilidad');
  }

}
