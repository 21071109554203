<app-container-base [hasCounter]="false" icon="fa fa-bars-staggered" [hasBar]="true" subTitle="Captura de Vales"
  [activeButtons]="activeButtons" (onClickBarButton)="onClickBarButton($event)" [setIsNew]="getIsNew" >
  <div class="screen-content" style="width:1000px;">
    <form [formGroup]="myForm" autocomplete="off">
      <div class="d-flex">
        <div style="width:750px">
          <app-panel-base title="Información del Vale">
            <div class="panel-content">
              <components-ui-date-box formControlName="FechaEmision" [enabled]="false" label="Fecha" [tabIndex]="1"
                [widthLabelColumns]="2" [widthTextColumns]="2" />
              <components-ui-number-box [tabIndex]="2" [maxLength]="10" [label]="'Folio '" formControlName="Clave"
                [required]="true" [widthTextColumns]="2" (blur)="blurFolio($event)" [widthLabelColumns]="2" />
              <hr>
              <component-ui-combo-box label='Elabora' (onSelectedItem)="selectedCombo($event,'UsuarioElabora')"
                formControlName="UsuarioElabora" entityName="Usuario" [tabIndex]="3" [widthTextColumns]="2"
                [widthLabelColumns]="2" [widthRightColumns]="5" [required]="true" [enabled]="false" [zeroMask]="3" />

              <component-ui-combo-box label='Cancela' (onSelectedItem)="selectedCombo($event,'UsuarioCancela')"
                formControlName="UsuarioCancela" entityName="Usuario" [tabIndex]="4" [widthTextColumns]="2"
                [widthLabelColumns]="2" [widthRightColumns]="5" [required]="true" [enabled]="false" [zeroMask]="3" />

              <component-ui-combo-box label='Autoriza' (onSelectedItem)="selectedCombo($event,'UsuarioAutoriza')"
                formControlName="UsuarioAutoriza" entityName="Usuario" [tabIndex]="5" [widthTextColumns]="2"
                [widthLabelColumns]="2" [widthRightColumns]="5" [required]="true" [zeroMask]="3" #cboAutoriza />

              <component-ui-combo-box label='Reibe' (onSelectedItem)="selectedCombo($event,'Recibio')"
                formControlName="Recibio" entityName="ValePersona" [tabIndex]="6" [widthTextColumns]="2"
                [widthLabelColumns]="2" [widthRightColumns]="5" [required]="true" [zeroMask]="3" />
              <hr>
              <component-ui-combo-box label='Tipo' (onSelectedItem)="selectedCombo($event,'Tipo')"
                formControlName="Tipo" entityName="TipoVale" [tabIndex]="7" [widthTextColumns]="2"
                [widthLabelColumns]="2" [widthRightColumns]="5" [required]="true" [zeroMask]="3" />
              <components-ui-number-box [tabIndex]="8" [maxLength]="10" [label]="'Importe Vale'" formControlName="Total"
                [required]="true" [widthTextColumns]="2" [widthLabelColumns]="2" />

              <components-ui-text-box [tabIndex]="9" [label]="'Observaciones'" formControlName="Observaciones"
                [required]="true" [widthTextColumns]="10" [widthLabelColumns]="2" />
            </div>
          </app-panel-base>
        </div>
        <div style="width: 250px;" *ngIf="myForm.value.Id >0 && (!myForm.value.FechaCancelacion || myForm.value.Impreso)">
          <div style="width: 400px;">
            <app-panel-base title="Opciones especiales">
              <div class="panel-content">
                <div *ngIf="!myForm.value.FechaCancelacion" (click)="openCancel()" class="btn btn-sm btn-white mb-10px me-2 d-block"><i
                    class="fa fa-calendar-xmark t-plus-1 text-red fa-fw fa-lg"></i>
                  Cancelar
                </div>
                <div class="btn btn-sm btn-white mb-10px me-2 d-block" *ngIf="myForm.value.Impreso"><i
                    class="fa fa-print t-plus-1 text-primary fa-fw fa-lg"></i>
                  Ya Impreso
                </div>
              </div>
            </app-panel-base>
          </div>
        </div>
      </div>
      <div class="cancelled-header animate__animated animate__bounceInDown" style="margin-top: 0px !important;" *ngIf="myForm.value.FechaCancelacion">
        <div class="cancelled">&nbsp;</div>
        <div style="margin-top:50px;" class="cancelled-detail">{{myForm.value.FechaCancelacion | date:'dd/MM/YYYY'}}
        </div>
        <div class="cancelled-detail">{{ myForm.value.MotivoCancelacion | uppercase}}</div>
      </div>
      <ng-template #modalCancel let-modal let-c="close">
        <app-opcion-cancelacion-vale-page  />
      </ng-template>
    </form>
  </div>
</app-container-base>
