import { Component, ElementRef, inject, OnInit, ViewChild } from '@angular/core';
import { ModalService } from 'src/app/service/modal.service';
import { AceptarRechazarPageComponent } from '../../../efisco/pages/aceptar-rechazar-page/aceptar-rechazar-page.component';
import { FacturacionService } from '../../services/facturacion.service';
import { ListaPrecio } from '../../interfaces/listaprecio.interface';
import { EventsService } from 'src/app/service/events.service';
import { Producto } from '../../../Inventarios/interfaces/producto.interface';
import { SearchConfiguration } from 'src/app/service/interfaces/data-search.interface';
import { UserLogged } from 'src/app/auth/interfaces';
import { UtilsService } from 'src/app/service/utils.service';
import { result } from 'lodash';
import { id } from '@swimlane/ngx-charts';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-agregar-producto-lista-page',
  templateUrl: './agregar-producto-lista-page.component.html',
  styles: [
  ]
})
export class AgregarProductoListaPageComponent implements OnInit {

  searchConfigurationProducto: SearchConfiguration | null = null;
  busquedaAbierta: boolean = false;
  listas: ListaPrecio[] = [];
  info: UserLogged = {} as UserLogged;
  private eventsService = inject(EventsService);
  producto: Producto | null = null;
  idsListas: number[] = [];
  @ViewChild('busquedaProductos')
  public ctrlBusquedaProductos!: ElementRef<HTMLElement>;

  constructor(private ms: ModalService, private fs: FacturacionService, private utileService: UtilsService) { }


  ngOnInit(): void {
    this.info = this.utileService.getUserLogged();
    this.searchConfigurationProducto = this.ms.GetSearchConfiguration("Producto", `Empresa.Id = ${this.info.empresa!.numero}`);
    this.fs.getListasPrecios().subscribe((result) => {
      if (result.success) {
        const listas: ListaPrecio[] = JSON.parse(result.message);
        this.listas = listas;
      }
    })
  }

  seleccionar(index: number, lista: ListaPrecio) {
    let sel = !this.listas[index].Seleccionado;
    this.listas[index].Seleccionado = sel;
    this.listas = [...this.listas];
  }

  openProductsSearch() {
    if (this.busquedaAbierta) return;
    this.busquedaAbierta = true;
    const b: any = this.ctrlBusquedaProductos;
    this.ms.openModal(b, (e: any) => {
      this.busquedaAbierta = false;
      if (e) {
        this.listas.map((item) => {
          item.Seleccionado = false;
          return item;
        });
        this.listas = [...this.listas];
        this.fs.obtenerListasProducto(this.info.empresa!.numero, this.info.sucursal!.numero, e.Id).subscribe((ids) => {
          this.idsListas = ids;
          ids.map((idL) => {
            this.listas.map((item) => {
              if (item.Id == idL) {
                item.Seleccionado = true;
              }
              return item;
            });

          });
          this.listas = [...this.listas];
        });
        this.producto = e;
      }
    }, 'xl')
  }

  getEnabledLista(idLista: number): boolean {

    if (!this.producto) {
      return false;
    }

    let val = false;

    this.idsListas.forEach((id) => {
      if (idLista == id) {
        val = true
      }
    })
    return !val;
  }


  get getProductsColumns(): any {
    if (this.searchConfigurationProducto) {
      return this.searchConfigurationProducto.columns;
    }
    return [];
  }

  get getProductsPropertys(): string {
    if (this.searchConfigurationProducto) {
      let props = "";
      this.searchConfigurationProducto.propertys.forEach((prop) => {
        props += `${prop.name}|${prop.type},`
      })

      if (props.length > 0) {
        props = props.substring(0, props.length - 1);
        return props;
      }

    }
    return "";
  }

  accept() {
    if (!this.producto) {
      this.eventsService.publish('home:showAlert', { message: "Debe el Producto.", cancelButton: false });
      return;
    }
    if (this.listas.filter(P => P.Seleccionado).length == 0) {
      this.eventsService.publish('home:showAlert', { message: "Debe de seleccionar las Listas de Precios donde se agregará el Producto.", cancelButton: false });
      return;
    }

    let agregar = "";

    this.listas.forEach((item) => {
      if (item.Seleccionado) {
        agregar += `${item.Id},`;
      }
    });

    if (agregar) {
      agregar = agregar.substring(0, agregar.length - 1);
    }

    this.fs.insertarProductoEnLista(this.info.empresa!.numero, this.info.sucursal!.numero, this.producto.Id, agregar).subscribe((result) => {
      if (result.success) {
        Swal.fire({ position: 'center', icon: 'success', title: 'Se guardó correctamente', showConfirmButton: false, timer: 2000 }).then(() => {
          this.ms.closeModal(true);
        });
      } else {
        this.eventsService.publish('home:showAlert', { message: `Ocurrio un error: ${result.message}`, cancelButton: false });
      }
    });

  }
  closeModal() {
    this.ms.closeModal(null);
  }


}
