<div class="form-group row {{(topLabel ? 'd-flex flex-column':'')}}">
  <ng-container *ngIf="label.length > 0">

    <label (click)="onClickLink()"
      class="col-sm-{{widthLabelColumns}} {{(topLabel ? 'text-start height-label':'tb-right')}} col-form-label {{(enableLink?'tb-link text-orange':( IsRequired?'text-required': ''))}}">
      <i *ngIf="IsRequired" class="fa fa-asterisk text-required fs-10px me-2"></i>
      {{label}}
      {{ label.length>0?separator:''}}
      {{label.length==0?"&nbsp;":""}}
    </label>
  </ng-container>
  <div class="d-flex col-sm-{{widthTextColumns}}">
    <div class="input-group">
      <input tooltipClass="complit-tooltip" [style]="getStyle" (focus)="onFocus($event)" [tabindex]="tabIndex"
        (keyup)="onKeyUp($event)" spellcheck="true" [(ngModel)]="value" (change)="onChangeTextValue($event)"
        (blur)="blurControl(txtTag.value)" maxLength="{{maxLength}}" (keypress)="onKeyPress($event)"
        (keydown)="onKeyDown($event)" (keydown.arrowup)="upInput($event)" (keydown.arrowdown)="downInput($event)"
        (keydown.enter)="enterInput($event)" (keydown.f2)="onClick()"
        [class]="'form-control form-control-sm '+IsUpperCase +' ' + IsDataTypeValid +' '+ (IsRequired?'bottom-red ': ' ') + (appSettings.appDarkMode ? 'bg-transparent':'') "
        type="text" placeholder="{{placeHolder}}" #txtTag [disabled]="!this.enabled" />
      <div (click)="onClick()"
        [style]="'height: 22px;padding: 2px;color:#ffa500;width: 30px;border-radius: 0px;border-radius: 0px; border: 0px;border-bottom: 1px solid ' + (appSettings.appDarkMode ? '#e6831d;':'#000000;')"
        *ngIf="enableIcon || this.Entidad.length> 0 "
        [class]="'btn btn-white ' + (appSettings.appDarkMode ? 'bg-light':'' )"><i class="{{classIcon}} fa-lg"></i>
      </div>
    </div>
  </div>
</div>

<app-panel-base *ngIf="sourceListBusqueda.length > 0 && cords" title="Búsqueda de {{entidadBusqueda}}"
  [style]="'font-family: monospace; position: absolute; z-index:9;left:'+cords.left+'px;top:'+cords.top+'px;width:1050px;box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;z-index: 99999'">
  <div class="panel-content">
    <ng-scrollbar style="height: 400px;">
      <table class="table table-sm table-bordered table-busqueda"
        style="border: 1px solid rgb(164, 164, 164);height: 38px;table-layout: fixed !important;">
        <thead>
          <tr>
            <th class="pt-0 pb-0">Nombre</th>
            <th style="width:100px;" class="pt-0 pb-0" *ngIf="entidadBusqueda === 'Cliente'">RFC</th>
          </tr>
        </thead>
        <tbody>
          <tr style="cursor:pointer;width:200px;" (click)="clickTablaBusqueda(item)"
            [id]="'row-search' + idUnico + '_'+i" [class]="selectedClienteIndex == i ? 'bg-blue-800 pulse':''"
            *ngFor="let item of sourceListBusqueda; let i = index;">
            <td [class]="selectedClienteIndex == i ? 'text-white':''" [style]="'width:100px;'" class="text-truncate">
              {{item.clave}} - {{item.nombre}}</td>
            <td *ngIf="entidadBusqueda === 'Cliente'" [class]="selectedClienteIndex == i ? 'text-white':''"
              [style]="'width:100px;'" class="text-truncate">
              {{item.rfc}}</td>
          </tr>
        </tbody>
      </table>
    </ng-scrollbar>
  </div>
</app-panel-base>

<ng-template #content let-modal let-c="close" *ngIf="this.Entidad.length > 0">
  <app-data-search titulo="Búsqueda" [columns]="getColumns" [entityName]="getEntityName" [filter]="getFilter"
    [propertys]="getPropertys" [orders]="getOrders" #busqueda />
</ng-template>
