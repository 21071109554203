import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, forwardRef, inject } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ModalService } from 'src/app/service/modal.service';
import { ContabilidadService } from '../../../contabilidad/services/contabilidad.service';
import { Select2UpdateEvent } from 'ng-select2-component';
import { EventsService } from 'src/app/service/events.service';
import { UtilsService } from 'src/app/service/utils.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Rubro, RubroER } from 'src/app/contabilidad/interfaces/rubro.interface';
import { ActivoFijo } from 'src/app/contabilidad/interfaces/activofijo.interface';
import { CentroCosto } from 'src/app/contabilidad/interfaces/centrocosto.interface';
import { FamiliaProducto } from 'src/app/Inventarios/interfaces/familia.interface';
import { CuentaContable } from 'src/app/contabilidad/interfaces/cuentacontable.interface';
import { environment } from 'src/environments/environment';
import { TextBoxComponent } from '../text-box/text-box.component';
import { ComboBoxEntity, Coordinates, TriggerParams } from '../../interfaces/combo-text.interface';
import { S } from '@fullcalendar/core/internal-common';
import { AppSettings } from 'src/app/home/services/app-settings.service';

@Component({
  selector: 'app-level-text-box',
  templateUrl: './level-text-box.component.html',
  styleUrls: [
    './level-text-box.component.scss'
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => LevelTextBoxComponent),
    }
  ]
})
export class LevelTextBoxComponent implements OnInit, ControlValueAccessor {
  @Input()
  public dataValue: string = "";
  @Input()
  public helpLine: string = "";
  @Input()
  public type: string = "";
  @Input()
  public label: string = "Etiqueta";
  @Input()
  public separator: string = ":";
  @Input()
  public widthLabelColumns: number = 2;
  @Input()
  public widthTextColumns: number = 10;
  @Input()
  public captureMode: boolean = false;
  @Input()
  public idEmpresa: number = 0;
  @Input()
  public caracterNivel: string = "";
  @Input()
  public enabled: boolean = true;
  @Input()
  public withLabel: boolean = true;
  @Input()
  public allowRanges: boolean = false;
  @Input()
  public leftBusqueda: number = 0;


  listaAbierta: boolean = false;
  buscarBlur: boolean = true;
  private eventsService = inject(EventsService);
  public appSettings = inject(AppSettings);
  //textFilter = '0123456789';//Se puso así para poder escribir y buscar
  textFilter = '';
  boxPos: any = null;
  info: any;
  source: any[] = [];
  private readonly baseUrl: string = environment.baseUrlApi;
  @Input()
  set setClaveGeneral(claveGeneral: string) {
    if (claveGeneral) {
      let split = claveGeneral.split(' ');
      split.forEach((element: any, index: number) => {
        this.myForm.controls["Level" + (index + 1)].setValue(element);
      });
    }
  };
  @Output()
  public onSelect: EventEmitter<any> = new EventEmitter();
  @Output()
  public onChangue: EventEmitter<any> = new EventEmitter();
  @Output()
  public onBlur: EventEmitter<any> = new EventEmitter();
  @Output()
  public onLastLevel: EventEmitter<any> = new EventEmitter();
  @Output()
  public onEscape: EventEmitter<any> = new EventEmitter();



  @ViewChild('seleccionar') public ctrlSeleccionar!: ElementRef<HTMLElement>;

  @ViewChild('txt1') public txt1!: ElementRef<TextBoxComponent>;
  @ViewChild('txt2') public txt2!: ElementRef<TextBoxComponent>;
  @ViewChild('txt3') public txt3!: ElementRef<TextBoxComponent>;
  @ViewChild('txt4') public txt4!: ElementRef<TextBoxComponent>;
  @ViewChild('txt5') public txt5!: ElementRef<TextBoxComponent>;


  @Input()
  public tabIndex: number = 0;

  ngOnInit(): void {
    this.info = this.utileService.getUserLogged();
    if (this.allowRanges) {
      //this.textFilter = '0123456789,-';
    }
    if (this.captureMode) {
      this.textFilter = "";
    }

  }

  getClaveByIndex(index: number) {
    let clave = "";

    if (this.myForm.value.Level1) {
      clave += this.myForm.value.Level1;
    }
    if (index == 1) return clave;
    if (this.myForm.value.Level2) {
      clave += ` ${this.myForm.value.Level2}`;
    }
    if (index == 2) return clave;
    if (this.myForm.value.Level3) {
      clave += ` ${this.myForm.value.Level3}`;
    }
    if (index == 3) return clave;
    if (this.myForm.value.Level4) {
      clave += ` ${this.myForm.value.Level4}`;
    }
    if (index == 4) return clave;
    if (this.myForm.value.Level5) {
      clave += ` ${this.myForm.value.Level5}`;
    }

    return clave;

  }

  blur(value: any, index: number) {
    if (this.listaAbierta) {
      setTimeout(() => {
        this.listaAbierta = false;
      }, 700);
      return;
    }

    if (!this.buscarBlur) {
      this.buscarBlur = true;
      return;
    }
    if (value.after != value.before) {
      if (index == 1) {
        this.myForm.controls["Level2"].setValue("");
        this.myForm.controls["Level3"].setValue("");
        this.myForm.controls["Level4"].setValue("");
        this.myForm.controls["Level5"].setValue("");
      }
      if (index == 2) {
        this.myForm.controls["Level3"].setValue("");
        this.myForm.controls["Level4"].setValue("");
        this.myForm.controls["Level5"].setValue("");
      }
      if (index == 3) {
        this.myForm.controls["Level4"].setValue("");
        this.myForm.controls["Level5"].setValue("");
      }
      if (index == 4) {
        this.myForm.controls["Level5"].setValue("");
      }

      let cuenta: string = ""
      this.enterCuenta();
      cuenta = this.getClaveGeneral()
      this.search(cuenta, index);
      if (this.caracterNivel == "_") {
        cuenta = this.getClaveGeneralFiltroReporte()
      }
      this.onBlur.emit(cuenta);
      this.source = [];
      this.listaAbierta = false;
      this.onChangue.emit((cuenta ? { claveGeneral: cuenta } : null));
    } else {

      this.idEmpresa = this.idEmpresa === 0 ? this.info.empresa.numero : this.idEmpresa;
      this.contaService.isLastLevelAccount(this.idEmpresa, this.getClaveByIndex(index)).subscribe((isLastLevel) => {
        if (isLastLevel) {
          if (value.after) {
            this.onLastLevel.emit();
          }
        }
      });
    }
    this.source = [];
  }

  enterCuenta() {
    if (this.listaAbierta && this.source.length > 0) {
      this.buscarBlur = false;
      const cuenta = this.source[0];
      let cuentas = cuenta.claveGeneral.split(' ');
      this.myForm.controls["Level1"].setValue("");
      this.myForm.controls["Level2"].setValue("");
      this.myForm.controls["Level3"].setValue("");
      this.myForm.controls["Level4"].setValue("");
      this.myForm.controls["Level5"].setValue("");
      cuentas.forEach((str: string, index: number) => {
        this.myForm.controls[`Level${(index + 1)}`].setValue(str);
      });
    }
    this.listaAbierta = false;
    this.source = [];
  }

  getClaveGeneral(): string {

    let clave = "";

    if (this.myForm.value.Level1) {
      clave += this.myForm.value.Level1;
    }

    if (this.myForm.value.Level2) {
      clave += ` ${this.myForm.value.Level2}`;
    }

    if (this.myForm.value.Level3) {
      clave += ` ${this.myForm.value.Level3}`;
    }

    if (this.myForm.value.Level4) {
      clave += ` ${this.myForm.value.Level4}`;
    }

    if (this.myForm.value.Level5) {
      clave += ` ${this.myForm.value.Level5}`;
    }
    return clave;
  }

  getClaveGeneralFiltroReporte(): string {

    let clave = "";

    if (this.myForm.value.Level1) {
      clave += this.myForm.value.Level1;
    } else {
      clave += " ";
    }

    if (this.myForm.value.Level2) {
      clave += `_${this.myForm.value.Level2}`;
    } else {
      clave += "_ "
    }

    if (this.myForm.value.Level3) {
      clave += `_${this.myForm.value.Level3}`;
    } else {
      clave += "_ "
    }

    if (this.myForm.value.Level4) {
      clave += `_${this.myForm.value.Level4}`;
    } else {
      clave += "_ ";
    }

    if (this.myForm.value.Level5) {
      clave += `_${this.myForm.value.Level5}`;
    } else {
      clave += "_ ";
    }
    return clave;
  }



  search(clave: string, level: number) {
    if (this.captureMode && this.idEmpresa > 0) {
      this.contaService.existsAccount(this.idEmpresa, clave).subscribe((existe) => {
        if (!existe) {
          this.eventsService.publish('home:showAlert', { message: 'La cuenta indicada no existe, por favor verifique.', cancelButton: false });
          this.myForm.controls["Level" + level].setValue('');
          this.onSelect.emit(null);
        } else {
          this.contaService.getCuentaNombre(this.idEmpresa, clave).subscribe((result) => {
            this.onSelect.emit(result.message);
          });
          this.idEmpresa = this.idEmpresa === 0 ? this.info.empresa.numero : this.idEmpresa;
          this.contaService.isLastLevelAccount(this.idEmpresa, clave).subscribe((isLastLevel) => {
            if (isLastLevel) {
              this.onLastLevel.emit();
            }
          });
        }
      })
    }
  }

  constructor(private fb: FormBuilder,
    private http: HttpClient,
    private utileService: UtilsService,
    private ms: ModalService,
    private contaService: ContabilidadService) { }
  writeValue(obj: any): void {
    if (obj) {
      if (this.caracterNivel.length > 0) {
        let niveles: string[] = obj.split(this.caracterNivel);
        if (niveles.length > 0) {
          let i: number = 0;

          i = 0;
          niveles.forEach(item => {
            i++;
            this.myForm.controls["Level" + i].setValue(item);
          })
        }
      }
    }


  }
  onChange = (value: string) => {
    this.onChangue.emit(value)
  };


  onTouched = () => { };

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {

  }


  public myForm: FormGroup = this.fb.group({
    Level1: [''],
    Level2: [''],
    Level3: [''],
    Level4: [''],
    Level5: ['']
  })

  getEntity(): any {
    return this.myForm.value;
  }

  getText(pos: number) {
    let text = '';

    switch (pos) {
      case 1:
        const t1: any = this.txt1;
        text = t1.tagInput.nativeElement?.value;
        break;
      case 2:
        const t2: any = this.txt1;
        text = t2.tagInput.nativeElement?.value;
        break;
      case 3:
        const t3: any = this.txt1;
        text = t3.tagInput.nativeElement?.value;
        break;
      case 4:
        const t4: any = this.txt1;
        text = t4.tagInput.nativeElement?.value;
        break;
      case 5:
        const t5: any = this.txt1;
        text = t5.tagInput.nativeElement?.value;
        break;
    }


    if (!text) return "";
    return text;
  }

  find(e: any, pos: number) {
    const text: any = this.getText(pos);
    if (!isNaN(text)) {
      this.listaAbierta = false;
      this.source = [];
      return;
    }

    if (e.keyCode == "27" || e.keyCode == "37" || e.keyCode == "39" || e.keyCode == "38" || e.keyCode == "40") {
      return;
    }

    if (text.length <= 3) {
      this.source = [];
      this.listaAbierta = false;
      return;
    }

    let params = new HttpParams().set("idEmpresa", this.info.empresa.numero).set("nombre", text);
    switch (this.type) {
      case 'Rubro':
        this.http.get<Rubro[]>(`${this.baseUrl}/Contabilidad/ObtenerRubrosPorNombre`, { params }).subscribe((lista) => {
          this.source = lista;
          this.makeList(pos);
        })
        break;
      case 'RubroER':
        this.http.get<RubroER[]>(`${this.baseUrl}/Contabilidad/ObtenerRubrosERPorNombre`, { params }).subscribe((lista) => {
          this.source = lista;
          this.makeList(pos);
        })
        break;
      case 'ActivoFijo':
        this.http.get<ActivoFijo[]>(`${this.baseUrl}/Contabilidad/ObtenerActivosFijosPorNombre`, { params }).subscribe((lista) => {
          this.source = lista;
          this.makeList(pos);
        })
        break;
      case 'CentroCosto':
        this.http.get<CentroCosto[]>(`${this.baseUrl}/Contabilidad/ObtenerCentrosCostosPorNombre`, { params }).subscribe((lista) => {
          this.source = lista;
          this.makeList(pos);
        })
        break;
      case 'FamiliaProducto':
        this.http.get<FamiliaProducto[]>(`${this.baseUrl}/Inventarios/ObtenerFamiliasPorNombre`, { params }).subscribe((lista) => {
          this.source = lista;
          this.makeList(pos);
        })
        break;

      case 'CuentaContable':
        params = new HttpParams().set("idEmpresa", this.info.empresa.numero).set("nombre", text).set("esBusquedaCombo", true);

        this.http.get<CuentaContable[]>(`${this.baseUrl}/Contabilidad/ObtenerCuentasContablesPorNombre`, { params }).subscribe((lista) => {
          this.source = lista;
          this.makeList(pos);
        })
        break;
    };

  }

  // getOffset(elem: HTMLInputElement): Coordinates {
  //   var box = elem.getBoundingClientRect();
  //   var left = window.scrollX !== undefined ? window.scrollX :
  //     (document.documentElement || document.body.parentNode || document.body).scrollLeft;
  //   var top = window.scrollY !== undefined ? window.scrollY :
  //     (document.documentElement || document.body.parentNode || document.body).scrollTop;

  //   top += elem.offsetHeight;
  //   return { left: box.left + left, top: box.top + top };
  // }

  getOffset(): Coordinates {
    let elem = document.querySelector("body");
    var box = elem!.getBoundingClientRect();
    var left = window.scrollX !== undefined ? window.scrollX :
      (document.documentElement || document.body.parentNode || document.body).scrollLeft;
    var top = window.scrollY !== undefined ? window.scrollY :
      (document.documentElement || document.body.parentNode || document.body).scrollTop;

    top += elem!.offsetHeight;
    return { left: box.left + left, top: box.top + top };
  }


  getItemHtml(pos: number): any {
    let text = '';

    switch (pos) {
      case 1:
        const t1: any = this.txt1;
        return t1.tagInput.nativeElement;
      case 2:
        const t2: any = this.txt1;
        return t2.tagInput.nativeElement;
      case 3:
        const t3: any = this.txt1;
        return t3.tagInput.nativeElement;
      case 4:
        const t4: any = this.txt1;
        return t1.tagInput.nativeElement;
      case 5:
        const t5: any = this.txt1;
        return t5.tagInput.nativeElement;
    }
    return null;
  }

  makeList(pos: number) {

    let cords = this.getOffset();
    cords.top = cords.top - 850;

    if (this.appSettings.appSidebarMinified) {
      cords.left = cords.left - 100;
    } else {
      cords.left = cords.left - 150;
    }
    this.cords = cords;

    if (this.leftBusqueda > 0) {
      this.cords.left = this.leftBusqueda;

    }

    //const box = this.getOffset(this.getItemHtml(pos));
    // this.boxPos = box;
    if (this.source.length > 0) {
      this.listaAbierta = true;
    }
  }



  get getListStyle(): string {
    if (this.boxPos && this.listaAbierta) {
      let style = `position: absolute;box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;z-index:999;margin-top:25px;width:351px;margin-left:118px;`;
      return style;
    }
    return 'display:none;';

  }

  mousedown(e: any, item: any) {
    e?.preventDefault();
    this.source = [item];
    this.blur({
      after: item.claveGeneral, before: ''
    }, 1);
  }

  escape() {
    this.onEscape.emit();
  }

  openSearch() {
    const b: any = this.ctrlSeleccionar;
    this.ms.openModal(b, (e: any) => {
      if (e) {
        this.myForm.reset({ Level1: '', Level2: '', Level3: '', Level4: '', Level5: '', });
        const claves = e.claveGeneral.split(' ');
        claves.forEach((element: any, index: number) => {
          this.myForm.controls["Level" + (index + 1)].setValue(element);
        });
        this.onChangue.emit(e);
      }
    }, 'widthfamilia')
  }

  cords: any = null;
  idUnico: string = '';
  selectedIndex: number = -1;
  clickTablaBusqueda(item: any) {
    if (item) {
      this.myForm.reset({ Level1: '', Level2: '', Level3: '', Level4: '', Level5: '', });
      const claves = item.claveGeneral.split(' ');
      claves.forEach((element: any, index: number) => {
        this.myForm.controls["Level" + (index + 1)].setValue(element);
      });
      this.cords = null;
      this.listaAbierta = false;
      this.onChangue.emit(item);
      this.selectedIndex = -1;
    }
  }


  upInput(e: any) {
    e.stopPropagation();
    if (this.source.length > 0) {
      let row = null;
      if (e.keyCode == "38") {
        if (this.selectedIndex > 0) {
          this.selectedIndex = this.selectedIndex - 1;
          row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`)
        }
      }
      if (row) {
        row.scrollIntoView({ block: "center" });
      }
      return;
    }
  }

  downInput(e: any) {
    e.stopPropagation();
    if (this.source.length > 0) {
      let row = null;
      if (e.keyCode == "40") {
        if (this.selectedIndex < this.source.length - 1) {
          this.selectedIndex = this.selectedIndex + 1;
          row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`)
        }
      }
      if (row) {
        row.scrollIntoView({ block: "center" });
      }
      return;
    }
  }

  enterInput(e: any) {
    let value = '';
    if (this.source.length > 0) {
      value = this.source[this.selectedIndex];
      setTimeout(() => {
        this.clickTablaBusqueda(value);
        this.cords = null;
        this.listaAbierta = false;
      }, 100);
    }
  }
}
