import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, QueryList, Renderer2, ViewChild, ViewChildren, inject } from '@angular/core';
import { ContainerBaseService } from '../../services/container-base.service';
import { FormGroup } from '@angular/forms';
import { ActiveButtons, ButtonParam, EventReturn } from '../../interfaces/container-base.interface';
import Swal from 'sweetalert2';
import { ComboBoxEntity } from '../../interfaces/combo-text.interface';
import { TextBoxComponent } from '../text-box/text-box.component';
import { ComboBoxService } from '../../services/combo-box.service';

import { AppSettings } from 'src/app/home/services/app-settings.service';
import { EventsService } from 'src/app/service/events.service';
import { SearchConfiguration } from '../../../service/interfaces/data-search.interface';
import { ModalService } from 'src/app/service/modal.service';
import { Router } from '@angular/router';
import { UtilsService } from 'src/app/service/utils.service';
import { AlertResponse } from '../../interfaces/alert.interface';
import { AlertService } from '../../services/alert-base.service';
import { orderBy } from 'lodash';
import { SignalrService } from 'src/app/service/signalr.service';

@Component({
  selector: 'app-container-base',
  templateUrl: './container-base.component.html',
  styleUrls: ['./container-base.component.scss']
})
export class ContainerBaseComponent implements OnInit, AfterViewInit {

  timeId = new Date().getTime();

  @Input()
  public isReport: boolean = false;
  @Input()
  public idComponent: string = this.timeId.toString();
  @Input()
  public printManual: boolean = false;
  @Input()
  public title: string = "titulo de la pantalla";
  @Input()
  public subTitle: string = "Administración del catálogo";
  @Input()
  public icon: string = "fa fa-building";
  @Input()
  public hasBar: boolean = true;
  @Input()
  public isMobile: boolean = false;
  @Input()
  public hasManagedDelete: boolean = false;
  @Input()
  public hasExitButton: boolean = false;
  @Input()
  public hasCounter: boolean = true;
  @Input()
  public searchItemsCombo: boolean = true;
  @Input()
  public getLast: boolean = true;
  @Input()
  public isAsync: boolean = false;
  @Input()
  public applyFormValidations: boolean = true;
  @Input()
  public entityName: string = "";
  @Input()
  public filterQuery: string = "";
  /*
    URL = url de canal de youtube
    https://www.youtube.com/channel/UClfYM4Mu-u9Njlm4JVxYZWg
  */
  @Input()
  public urlVideo: string = "";
  showVideo: boolean = false;
  @Input()
  public entity: FormGroup = new FormGroup({});
  @Output()
  public onItemSearched: EventEmitter<any> = new EventEmitter();
  @Output()
  public onItemsComboSearched: EventEmitter<any> = new EventEmitter();
  @Output()
  public onItemsLoadFirstTime: EventEmitter<any> = new EventEmitter();
  @Output()
  public onClickBarButton: EventEmitter<any> = new EventEmitter();
  @Output()
  public onDelete: EventEmitter<any> = new EventEmitter();
  private eventsService = inject(EventsService);
  private signalrService = inject(SignalrService);
  zoom: number = 0;
  initZoom: number = 0;


  @Input()
  public activeButtons: ActiveButtons = {
    new: true,
    delete: true,
    return: true,
    save: true,
    first: true,
    left: true,
    right: true,
    last: true,
    search: true,
    print: true,
    all: true,
    export: false,
    exit: false
  };

  /* Buttons activity */
  @Output()
  public onBeforeSave: EventEmitter<any> = new EventEmitter();
  @Output()
  public onAfterSave: EventEmitter<any> = new EventEmitter();
  @Output()
  public onSaveError: EventEmitter<any> = new EventEmitter();

  loading: boolean = false;
  saving: boolean = false;
  isNewItem: boolean = false;
  scrollTop: any;
  public appSettings = inject(AppSettings);
  private utilsService = inject(UtilsService);

  public searchConfiguration!: SearchConfiguration;
  count: number = 0;

  @ViewChild('content')
  public ctrlBusqueda!: ElementRef<HTMLElement>;
  @ViewChild('alertObj')
  public alertObj!: ElementRef<HTMLElement>;
  @ViewChild('container')
  public container!: ElementRef<HTMLElement>;

  @ViewChildren(TextBoxComponent)
  childsTextBox!: QueryList<ElementRef>;
  constructor(private cbService: ContainerBaseService,
    private cboService: ComboBoxService,
    private mService: ModalService,
    private router: Router,
    private alert: AlertService) {
    this.eventsService.subscribe(("container-base:height"), () => {
      this.restarHeight(window.innerHeight);
    });
  }
  ngAfterViewInit(): void {
    this.setButtons();
  }

  @HostListener('scroll', ['$event'])
  onScroll(event: any) {
    this.scrollTop = (this.appSettings.appSidebarMinified) ? event.srcElement.scrollTop + 40 : 0;
    if (typeof (Storage) !== 'undefined') {
      localStorage.setItem('sidebarScroll', event.srcElement.scrollTop);
    }
  }

  getCount() {
    if (this.entityName) {
      this.cbService.getCounter(this.entityName, this.filterQuery).subscribe((total) => {
        this.count = total;
      })
    }
  }

  onClickButton(params: ButtonParam) {
    let entrarClick = true;
    switch (params.name) {
      case 'delete':
        !this.hasManagedDelete && this.deleteConfirm();
        break;
      case 'first':
        entrarClick = this.navigate('first');
        break;
      case 'left':
        entrarClick = this.navigate('left');
        break;
      case 'right':
        entrarClick = this.navigate('right');
        break;
      case 'last':
        entrarClick = this.navigate('last');
        break;
      case 'return':
        if (this.getLast) this.isNewItem = false;
        this.getLastEntry();
        break;
      case 'new':
        this.getEmptyEntity();
        break;
      case 'save':
        this.save();
        break;
      case 'search':
        this.openSearch();
        break;
      case 'print':
        this.print(false);
        return;
        break;
      case 'export':
        this.print(true);
        break;
      case 'exit':
        this.mService.closeModal(null);
        break;
    };
    if (entrarClick) {
      this.onClickBarButton.emit(params.name);
    }
  }

  print(exportar: boolean) {
    if (this.entityName && !this.printManual) {
      this.eventsService.publish('home:isLoading', { isLoading: true });
      this.cbService.printEntity(this.entityName, exportar).subscribe((result) => {
        if (result.success) {
          if (exportar) {
            let link = document.createElement('a');
            link.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + encodeURIComponent(result.message);
            link.setAttribute('download', 'Reporte.xlsx');
            link.style.display = 'none';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
            let pdfBlob = this.utilsService.base64ToBlob(result.message, 'application/pdf');
            let urlBlobPdf = window.URL.createObjectURL(pdfBlob);
            localStorage.setItem("pdfblob", urlBlobPdf);

            const url1 = this.router.serializeUrl(this.router.createUrlTree(['/reportes'], {
              queryParams: {
                // base64: result.message,
                entity: this.entityName,
                title: this.title
              }
            }));
            window.open(url1, '_blank');
            this.eventsService.publish('home:isLoading', { isLoading: false });
          }
        }
      });
    } else {
      this.onClickBarButton.emit('print');
    }
  }

  @Input()
  set setIsNew(isnew: boolean) {
    this.isNewItem = isnew;
  }

  openSearch() {
    if (this.searchConfiguration) {
      const b: any = this.ctrlBusqueda;
      this.mService.openModal(b, (e: any) => {
        if (e) {
          this.loading = true;
          this.cbService.getEntityById(this.entityName, e.Id).subscribe((ent) => {
            this.loading = false;
            this.onItemSearched.emit(ent);
          });
        }
      }, 'xl')
    }
  }

  get getPropertys(): string {
    if (this.searchConfiguration) {
      let props = "";
      this.searchConfiguration.propertys.forEach((prop) => {
        props += `${prop.name}|${prop.type},`
      })

      if (props.length > 0) {
        props = props.substring(0, props.length - 1);
        return props;
      }

    }
    return "";
  }

  get getEntityName(): any {
    if (this.searchConfiguration) {
      return this.searchConfiguration.entityName;
    }
    return "";
  }


  get getOrders(): any {
    if (this.searchConfiguration) {
      let props = "";
      this.searchConfiguration.orders.forEach((prop) => {
        props += `${prop.name}|${prop.type},`
      })

      if (props.length > 0) {
        props = props.substring(0, props.length - 1);
        return props;
      }

    }
    return "";
  }

  get getFilter(): any {
    if (this.searchConfiguration) {
      return this.searchConfiguration.filter;
    }
    return "";
  }

  get getColumns(): any {
    if (this.searchConfiguration) {
      return this.searchConfiguration.columns;
    }
    return [];
  }

  save() {
    if (this.entity.valid || !this.applyFormValidations) {
      this.onBeforeSave.emit({
        entity: this.entity.value,
        callback: (isValid: boolean) => { if (isValid) { this.saveEntity() } }
      });
    } else {
      let throwError: boolean = false;
      for (const [key, ctrl] of Object.entries(this.entity.controls)) {
        if (ctrl.errors) {
          for (const [keyError, err] of Object.entries(ctrl.errors)) {
            switch (keyError) {
              case 'pattern':
                this.eventsService.publish('home:showAlert', { message: `El valor del campo ${key} no cumple con el patrón requerido.`, icon: 'fa-triangle-exclamation text-yellow', cancelButton: false });
                break;
              case 'required':
                throwError = true;
                this.eventsService.publish('home:showAlert', { message: `Debe de indicar todos los campos marcados en rojo.`, icon: 'fa-triangle-exclamation text-yellow', cancelButton: false });

                return;
              case 'min':
                this.eventsService.publish('home:showAlert', { message: `Debe de indicar un valor mayor ${key}, valor mínimo: ${err.min}.`, icon: 'fa-triangle-exclamation text-yellow', cancelButton: false });
                return;
            }
          }
          return;
        }
      }
    }
  }

  deleteConfirm() {
    this.eventsService.publish('home:showAlert', {
      message: '¿Desea eliminar el registro indicado?',
      onConfirm: (r: AlertResponse) => {
        if (r.isAccept) {
          if (this.entityName) {
            this.deleteEntity();
          } else {
            this.onDelete.emit();
          }
        }
      }
    });
  }

  deleteEntity() {
    //borramos
    let idEnt = 0;
    if (this.entity.value.Id) { idEnt = this.entity.value.Id; }
    if (this.entity.value.id) { idEnt = this.entity.value.id; }
    this.cbService.deleteEntity(this.entityName, idEnt)
      .subscribe(res => {
        //recargamos el combo
        if (this.searchItemsCombo) {
          this.getItemsByName((list) => {
            //cargamos la última entidad
            this.getLastEntry();
            this.getCount();
            Swal.fire({ position: 'center', icon: 'success', title: 'Registro eliminado', showConfirmButton: false, timer: 1000 })
            this.onDelete.emit();
          });
        } else {
          this.getCount();
          Swal.fire({ position: 'center', icon: 'success', title: 'Registro eliminado', showConfirmButton: false, timer: 1000 })
          this.onDelete.emit();
        }
      });
  }

  navigate(type: string): boolean {
    if (this.entity.value.Id > 0 && this.entityName) {
      this.cbService.getNavigateItem(this.entityName, type, this.entity.value.Clave).subscribe(item => {
        if (item) {
          this.onItemSearched.emit(item);
        }
      });
      return true;
    } else {
      this.onClickBarButton.emit(type);
      return false
    }
  }

  abrirVideo() {
    if (this.urlVideo) {
      window.open(this.urlVideo);
    }else{
      this.eventsService.publish('home:showAlert', { message: "No hay una URL de ayuda activada para esta pantalla, posiblemente está siendo actualizada.", cancelButton: false });
    }
  }

  //Save the entity in the database and return new entity value
  saveEntity() {
    this.saving = true;
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.cbService.saveEntity(this.entityName, JSON.stringify(this.entity.value), this.isAsync)
      .subscribe((item) => {
        this.saving = false;
        this.eventsService.publish('home:isLoading', { isLoading: false });
        this.isNewItem = false;
        if (item.success) {
          const i = eval(`[${item.message}]`);
          this.onAfterSave.emit(i[0]);
          Swal.fire({ position: 'center', icon: 'success', title: 'Se guardó correctamente', showConfirmButton: false, timer: 1000 })
          if (this.searchItemsCombo) {
            this.getItemsByName(() => { });
          }
          this.getCount();
        } else {
          this.onSaveError.emit();
          this.eventsService.publish('home:showAlert', { message: item.message, icon: 'fa-triangle-exclamation text-yellow', cancelButton: false });
        }
      },
        error => {
          this.saving = false;
          this.eventsService.publish('home:isLoading', { isLoading: false });
          this.eventsService.publish('home:showAlert', { message: 'Ocurrió un error la guardar.', icon: 'fa-triangle-exclamation text-yellow', cancelButton: false });

        });
  }

  getEmptyEntity() {
    if (this.entityName) {
      this.isNewItem = true;
      this.cbService.getEmptyEntity(this.entityName)
        .subscribe((ent) => {
          this.getNextNumber(ent);
        });
    }
  }

  getNextNumber(ent: any) {
    this.cbService.getNextNumber(this.entityName, this.filterQuery)
      .subscribe((next) => {
        if (next) {
          this.onItemSearched.emit({ ...ent, Clave: parseInt(next) });
        } else {
          this.onItemSearched.emit(ent);
        }
      });
  }

  getItemsByName(callback: (i: ComboBoxEntity[]) => void) {
    this.cbService.getItemsByName(this.entityName, this.aplicarPermisos, true)
      .subscribe((items) => {
        items.map(P => {
          P.ClaveNombre = `${P.Clave} ${P.Nombre}`
        });
        this.onItemsComboSearched.emit(items);
        callback(items);
      });
  }

  getLastEntry() {
    if (this.getLast) {
      this.cbService.getLastEntity(this.entityName, this.filterQuery)
        .subscribe((ent) => {
          this.loading = false;
          this.onItemSearched.emit(ent);
          this.onItemsLoadFirstTime.emit();
          if (!ent) {
            this.getEmptyEntity();
          }
        });
    } else {
      this.loading = false;
    }
  }

  @Input()
  set setFilterQuery(value: string) {
    this.filterQuery = value;
    this.searchConfiguration = this.mService.GetSearchConfiguration(this.entityName, this.filterQuery);
  }

  @Input()
  esPopup: boolean = false;

  @Input()
  hasLineHelp: boolean = true;

  @Input()
  hasHeader: boolean = true;

  @Input()
  aplicarPermisos: boolean = false;

  @Input()
  containerInnerHeight: number = window.innerHeight;

  get getStyle(): string {
    let style = "";
    if (this.containerInnerHeight > 0) {
      style = `height:${this.containerInnerHeight}px;`;
    }
    return style;
  }

  get getZoomValue(): number {
    return this.initZoom;
  }

  get getZoom(): string {
    let s = "";
    if (this.zoom > 0) {
      s = `zoom: ${100 + this.zoom}%`;
    }
    return s;
  }

  ngOnInit(): void {
    const zoom = localStorage.getItem("zoom");
    if (zoom) {
      this.zoom = parseFloat(zoom);
      this.initZoom = this.zoom;
    }
    this.restarHeight(window.innerHeight);
    if (this.entityName) {
      this.loading = true;
      this.searchConfiguration = this.mService.GetSearchConfiguration(this.entityName, this.filterQuery);
      this.getCount();
      if (this.searchItemsCombo) {
        //Obtiene los items del combo clave
        this.cbService.getItemsByName(this.entityName, this.aplicarPermisos, true)
          .subscribe((items) => {
            items.map(P => { P.ClaveNombre = `${P.Clave} ${P.Nombre}` });
            this.onItemsComboSearched.emit(items);
            //Busca la última entidad
            this.getLastEntry();
          });
      } else {
        this.getLastEntry();
      }
    }
    this.loadActionPermission();

    this.signalrService.startConnectionMenuHub((type: string, args: any) => {
      switch (type) {
        case "menu":
          setTimeout(() => {
            this.loadActionPermission();
          }, 3000);
          break;
      }
    });
  }

  loadActionPermission() {
    this.eventsService.publish('home:loadActionPermission', (permisos: any) => {
      this.showGuardarEliminar(permisos);
    });
  }

  showGuardarEliminar(permisos: any[]) {
    try {
      if (this.isReport) return;
      const tabActive = document.querySelector(".tabs-pantallas.tab-pane.show");
      if (tabActive) {
        let id = tabActive?.id.split("-");
        let p = null;
        for (const p1 of permisos) {
          if (p1.url === id[0]) {
            p = p1;
            this.activeButtons.save = p.url === "capturapolizas" ? false : p.guardar;
            this.activeButtons.delete = p.eliminar;
            break;
          } else {
            if (p1.submenu) {
              this.showGuardarEliminar(p1.submenu);
            }
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  setButtons() {
    if (this.activeButtons.all != undefined) {
      this.activeButtons = {
        new: this.activeButtons.new != undefined ? this.activeButtons.new : this.activeButtons.all,
        delete: this.activeButtons.delete != undefined ? this.activeButtons.delete : this.activeButtons.delete,
        return: this.activeButtons.return != undefined ? this.activeButtons.return : this.activeButtons.return,
        save: this.activeButtons.save != undefined ? this.activeButtons.save : this.activeButtons.all,
        first: this.activeButtons.first != undefined ? this.activeButtons.first : this.activeButtons.all,
        left: this.activeButtons.left != undefined ? this.activeButtons.left : this.activeButtons.all,
        right: this.activeButtons.right != undefined ? this.activeButtons.right : this.activeButtons.all,
        last: this.activeButtons.last != undefined ? this.activeButtons.last : this.activeButtons.all,
        search: this.activeButtons.search != undefined ? this.activeButtons.search : this.activeButtons.all,
        print: this.activeButtons.print != undefined ? this.activeButtons.print : this.activeButtons.all,
        export: this.activeButtons.export != undefined ? this.activeButtons.export : this.activeButtons.export,
        all: this.activeButtons.all,
        exit: this.hasExitButton
      }
    }
  }

  intervals: any[] = [];

  setCaretMember() {
    let variant: boolean = true;
    const inputs = document.querySelectorAll('.form-control.form-control-sm');
    inputs.forEach((input: any) => {

      // input!.addEventListener('blur', () => {
      //   this.intervals.forEach((i)=>{
      //     clearInterval(i);
      //   })
      //   this.intervals = [];
      // });

      input!.addEventListener('focus', () => {
        input.setSelectionRange(0, 1);
        // let inter = setInterval(() => {
        //   const s = input.selectionStart;
        //   input.setSelectionRange(s, s + (variant ? 1 : 0));
        //   variant = !variant;
        //   console.log(variant)
        // }, 150);
        // this.intervals = [...this.intervals, inter]
      });

      input!.addEventListener('keydown', (e: any) => {
        const s = input.selectionStart;
        if (e.keyCode == 8) {
          input.setSelectionRange(s - 1, s);
        }
      }, false);

      input!.addEventListener('keyup', (e: any) => {
        const s = input.selectionStart;
        if (e.keyCode == 8) {
          input.setSelectionRange(s, s + 1);
          e.preventDefault();
          return;
        }
        if (e.keyCode != 13) {
          if (e.keyCode == 37) {
            input.setSelectionRange(s - 1, s);
          } else {
            input.setSelectionRange(s, s + 1);
          }
        }
      }, false);
      input!.addEventListener('keypress', (e: any) => {
        const s = input.selectionStart;
        if (e.keyCode == 8) {
          e.preventDefault();
          return;
        }
        if (e.keyCode != 13) {
          input.setSelectionRange(s, s + 1);
        }
      }, false);
    });

  }

  @HostListener('window:resize', ['$event'])
  onChangeHeight(event: any) {
    this.restarHeight(event.target.innerHeight);
  }

  restarHeight(innerHeight: number) {
    if (!this.esPopup) {
      let cantidadRestarTop = 265 - (this.hasBar ? 0 : 61);
      let cantidadRestarLeft = 225 - (this.hasBar ? 0 : 47);
      let restar = localStorage["isTop"] === "true" ? cantidadRestarTop : cantidadRestarLeft;
      restar = this.isMobile ? 105 : restar;
      this.containerInnerHeight = innerHeight - restar;
    }
  }

  setZoom(p: any) {
    this.zoom = parseFloat(p.srcElement.value);
    localStorage.setItem("zoom", String(this.zoom));
  }

  blurElement() {
    if (document.activeElement) {
      const el: any = document.activeElement;
      el.blur();
    }
  }

  keydown(e: any) {
    if (e.altKey) {
      switch (e.keyCode) {
        case 78: this.getEmptyEntity(); break;
        case 82:

          this.isNewItem = false;
          this.getLastEntry();
          break;
        case 88: this.deleteConfirm(); break;
        case 71: this.blurElement(); this.save(); break;

        case 80: this.navigate('first'); break;
        case 65: this.navigate('left'); break;
        case 83: this.navigate('right'); break;
        case 85: this.navigate('last'); break;
        case 66: this.openSearch(); break;
        case 73: this.print(false); break;
      }
    } else if (e.keyCode == 13 || e.keyCode == 40) {
      if (e.keyCode == 40 && this.cboService.isOpen()) {
        return;
      }
      this.navigateChilds("next", e.keyCode == 13);
    } else if (e.keyCode == 27 || e.keyCode == 38) {
      if (e.keyCode == 38 && this.cboService.isOpen()) {
        return;
      }
      this.navigateChilds("back");
    }
  }

  navigateChilds(type: string, isEnter: boolean = false) {

    const ele: any = this.container.nativeElement;
    const doc = ele.children[0];

    let texts = doc.querySelectorAll('.form-control.form-control-sm');
    let checks = doc.querySelectorAll('.form-check-input.combo-box');
    let buttons = doc.querySelectorAll('.file-upload-button.btn.btn-primary');
    let listBox = doc.querySelectorAll('.form-select.form-select-sm');
    let textArea = doc.querySelectorAll('.form-textarea');

    let combined: any[] = [];
    texts.forEach((elem: any) => combined.push(elem));
    checks.forEach((elem: any) => combined.push(elem));
    buttons.forEach((elem: any) => combined.push(elem));
    listBox.forEach((elem: any) => combined.push(elem));
    textArea.forEach((elem: any) => combined.push(elem));
    if (type == 'next') {
      combined = orderBy(combined, ["tabIndex"], ["asc"]);
    } else {
      combined = orderBy(combined, ["tabIndex"], ["desc"]);
    }
    const el: any = document.activeElement;
    if (!isEnter) {
      let typeahead = el.getAttribute("ng-reflect-ngb-typeahead");
      if (typeahead) return;
    }
    let tabbuscar = el.tabIndex;
    if (tabbuscar > 0) {
      let enviar = true;
      combined.forEach((elem: any) => {
        if (type == 'next') {
          if (elem.tabIndex > 0 && enviar) {
            if (elem.tabIndex > tabbuscar) {
              if (!elem.disabled) {
                enviar = false;
                elem.focus();
                if (elem.select) {
                  elem.select();
                }
              }
            }
          }
        } else if (type = "back") {
          if (elem.tabIndex > 0 && enviar) {
            if (elem.tabIndex == tabbuscar - 1) {
              if (!elem.disabled) {
                enviar = false;

                elem.focus();
                if (elem.select) {
                  elem.select();
                }
              } else {
                tabbuscar = elem.tabIndex;
              }
            }
          }
        }
      })
    }
  }

  compare(a: any, b: any) {
    if (a.tabIndex < b.tabIndex) {
      return -1;
    }
    if (a.tabIndex > b.tabIndex) {
      return 1;
    }
    return 0;
  }

  get isLoading(): boolean {
    return this.loading;
  }
}
