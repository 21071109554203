<app-container-base [getLast]="false" (onDelete)="onDelete()" [activeButtons]="activeButtons" [entity]="myForm"
  [printManual]="true" (onClickBarButton)="clickBoton($event)" (onBeforeSave)="onBeforeSave($event)"
  (onAfterSave)="onAfterSave($event)" entityName="CentroCosto" title="Centros de Costos" icon="fa fa-folder-tree"
  subTitle="Administra los Centros de Costos">
  <div class="screen-content">
    <span *ngIf="saving" class="loaderfact"><i class="fas fa-spinner fa-spin spin"></i></span>
    <div style="height: 41px;padding: 2px;">

      <div style="margin-left: 5px;" *ngIf="showAddFamily" (click)="addLevel()" class="btn btn-primary"><i
          class="fa fa-lg me-1 fa-plus"></i>
        Agregar Centro [raiz]</div>

      <div style="margin-left: 5px;" (click)="addSubLevel()" *ngIf="showAddSubFam" class="btn btn-primary"><i
          class="fa fa-lg me-1 fa-plus"></i>
        Agregar Sub-centro a <span>[{{selectedTree.nombre | uppercase}}]</span></div>
    </div>
    <form [formGroup]="myForm" autocomplete="off">
      <div class="d-flex" style="height: 100%;">

        <components-ui-tree-view (onLoad)="onLoad()" [setIsNew]="getReload" (onSelectedItem)="onSelectedItem($event)"
          [type]="'CentroCosto'" />

        <div style="width: 70%;padding-left: 5px;">
          <app-panel-base title="Información general" style="height: 450px;width: 637px;"
            *ngIf="this.myForm.value.clave>0">
            <div class="panel-content">
              <components-ui-number-box [tabIndex]="1" [label]="'Clave'" formControlName="clave" [widthTextColumns]="4"
                [widthLabelColumns]="3" [maxLength]="longClave" [enabled]="myForm.value.id==0" [required]="true" />

              <components-ui-text-box [tabIndex]="2" [label]="'Nombre'" formControlName="nombre" [widthTextColumns]="4"
                [widthLabelColumns]="3" [required]="true" #txtNombre />

              <components-ui-text-box [tabIndex]="3" [label]="'Abreviación'" formControlName="abreviacion"
                [widthTextColumns]="4" [widthLabelColumns]="3" />

              <component-ui-check-box [tabIndex]="9" [widthLabelColumns]="3" formControlName="baja"
                [label]="'¿Dar de baja?'" />

            </div>
          </app-panel-base>

        </div>
      </div>
    </form>
  </div>
</app-container-base>
