<app-container-base (onItemsComboSearched)="onItemsComboSearched($event)" (onItemSearched)="onItemSearched($event)"
  (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)" [entity]="myForm"
  entityName="CuentaBancaria" title="Cuentas bancarias" icon="fa fa-building"
  subTitle="Cuentas bancarias de las empresas">
  <div class="screen-content">
    <span *ngIf="saving" class="loaderfact"><i class="fas fa-spinner fa-spin spin"></i></span>
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Información">
        <div class="panel-content">
          <component-ui-combo-box (onNewCatalog)="onNewCatalog($event)" (onChangueEntity)="onChangueEntity($event)"
            [isCatalog]="true" formControlName="Id" [isNewItem]="getIsNew" [setItems]="getItemsCombo" [label]="'Cuenta'"
            entityName="CuentaBancaria" [tabIndex]="1" [listProperty]="'ClaveNombre'" [widthTextColumns]="1"
            [widthLabelColumns]="2" />
          <components-ui-text-box [label]="'Nombre'" [required]="true" formControlName="Nombre" [tabIndex]="2"
            [widthTextColumns]="3" [widthLabelColumns]="2" #txtNombre [maxLength]="50" />

          <component-ui-combo-box [label]="'Banco SAT'" [required]="true" formControlName="Banco" [tabIndex]="4"
            [zeroMask]="3" entityName="c_Banco" [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="8"
            (onSelectedItem)="selectedItem($event, 'Banco')" [listProperty]="'ClaveNombre'" />



          <components-ui-text-box [label]="'No de cuenta'" [required]="true" formControlName="NumeroCuenta"
            [tabIndex]="5" [widthTextColumns]="3" [widthLabelColumns]="2" [maxLength]="50" />

          <!-- <div class="card-header h6 mb-1 bg-none p-2 text-orange">
            <i class="fa fa-circle-info fa-lg fa-fw"></i>
            Autenticación en la API bancaria
          </div>
          <div class="form-group row">
            <div class="col-sm-2">
              &nbsp;
            </div>
            <div class="col-sm-10">
              <div (click)="getBanks()" style="margin-left: -15px;margin-bottom: 5px;margin-top: 3px;"
                class="btn btn-primary btn-xs">Ver
                Bancos
              </div>
            </div>
          </div> -->
<!--
          <components-ui-text-box [label]="'Código banco API'" formControlName="CodigoBancoPrometeo" [tabIndex]="6"
            [widthTextColumns]="3" [widthLabelColumns]="2" [maxLength]="50" />

          <components-ui-text-box [tabIndex]="7" [maxLength]="100" [label]="'Usuario'" formControlName="Usuario"
            [widthTextColumns]="2" [widthLabelColumns]="2" />

          <components-ui-password-box [tabIndex]="8" [maxLength]="100" [label]="'Contraseña'" formControlName="Password"
            [widthTextColumns]="2" [widthLabelColumns]="2" />

          <components-ui-text-box [label]="'Moneda'" formControlName="Moneda" [tabIndex]="9" [widthTextColumns]="3"
            [widthLabelColumns]="2" [maxLength]="3" /> -->

        </div>
      </app-panel-base>
    </form>

    <div style="position:relative; top:-187px; left: 83px;box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;width: 700px;"
      class="bg-light m-2" cdkDrag *ngIf="showBanks">
      <div class="widget rounded" cdkDragHandle style="margin-bottom: 0px;" data-bs-theme="">
        <div class="widget-header" style="cursor:move;">
          <h4 class="widget-header-title text-center">Bancos participantes</h4>
        </div>
      </div>
      <div class="tab-content panel p-3 rounded-0 rounded-bottom;height:300px">
        <div class="table-responsive">
          <ng-scrollbar style="height: 450px;">
            <table class="table table-bordered mb-0 table-striped">
              <thead>
                <tr>
                  <th></th>
                  <th>Banco</th>
                  <th>Código</th>
                  <th>País</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of list">
                  <td>
                    <div (click)="selectBank(item)" class="btn btn-success">Seleccionar</div>
                  </td>
                  <td>{{item.name}}</td>
                  <td>{{item.code}}</td>
                  <td>{{item.country}}</td>
                </tr>
              </tbody>
            </table>
          </ng-scrollbar>
        </div>
      </div>
      <div style="text-align: right; margin-top: 2px;margin-right: 5px;">
        <button (click)="closeBanks()" class="btn btn-danger">Cerrar</button>
      </div>
    </div>

  </div>
</app-container-base>
