import { Component, ElementRef, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { ReportFilter, ReportFilterOrderList, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ReportsService } from 'src/app/service/reports.service';
import { UtilsService } from 'src/app/service/utils.service';


@Component({
  selector: 'verificador-compras-page',
  templateUrl: './verificador-compras-page.component.html',
  styles: [
  ]
})
export class VerificadorComprasPageComponent {

  menuId: string = "verificadordecompras";

  @ViewChild('txtNombreReporte')
  public txtNombreReporte!: ElementRef;
//Serie-Folio-Estatus
  //public ordenadoLista: ReportFilterOrderList[] = [{ Nombre: 'Empresa-Sucursal-TipoComprobante', Orden: 'empClave,sucClave,doc.Clave,c.FechaEmision,c.id' }]


  public ordenadoLista: ReportFilterOrderList[] = [
    { Nombre: 'Ninguno', Orden: '[usu.Clave],[doc.Clave],[clasec.Clave],[prov.Clave],c.SerieString,c.folio', Id: 0 },
    { Nombre: 'Empresa-Sucursal', Orden: 'emp.Clave,suc.Clave,[dc.Clave],[usu.Clave],[clasec.Clave],[prov.clave]', Id: 1 }]


    public luegoPorLista: ReportFilterOrderList[] = [
      { Nombre: 'Ninguno', Orden: '', Id: 0 },
      { Nombre: 'Proveedor', Orden: 'prov.Clave', Id: 1 },
      { Nombre: 'Usuario elabora', Orden: 'usu.Clave', Id: 2 },
      { Nombre: 'Tipo Comprobante ', Orden: 'doc.Clave', Id: 3 },
      { Nombre: 'Clase Compra', Orden: 'clasec.Clave', Id: 4 }
    ]



  private reportsService =inject(ReportsService)

  public filtrosDiarioVentas: ReportFilter =
    {
      menuId:this.menuId,
      ReportHeader: {} as ReportHeader,
      Desglose: 'a Detalle',
      DesgloseLista: [{Nombre:'a Detalle'},
                      {Nombre:'a Totales'}],
      OrdenadoLista:this.ordenadoLista,
      LuegoPorLista:this.luegoPorLista,
      Fecha1: new Date(),
      Fecha2: new Date(),
      NombreReporte: '',
      TituloVisor:'Verificador de Compras',
      NombreExcel:'Verificador de Compras.xlsx',
      FilterOptions: [
        { Campo: 'emp.Clave', Etiqueta: 'Empresa', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Empresa" },
        { Campo: 'suc.Clave', Etiqueta: 'Sucursal', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Sucursal" },
        { Campo: 'doc.Clave', Etiqueta: 'Documento de compra', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "DocumentoCompra" },
        { Campo: 'prov.Clave', Etiqueta: 'Proveedor', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Proveedor" },
        { Campo: 'mon.Clave', Etiqueta: 'Moneda', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Moneda" },
        { Campo: 'clasec.Clave', Etiqueta: 'Clase de compra', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "ClaseCompra" },
        { Campo: 'usu.Clave', Etiqueta: 'Usuario elabora', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Usuario" },
        { Campo: 'c.Vigente', Etiqueta: 'Vigente', Tipo: TypeFilter.list, ListaItems: [{Nombre: 'Sin selección' }, {Nombre: 'Vigentes' }, {Nombre: 'No vigentes' }] }
      ]
    };


  public myForm: FormGroup = this.fb.group({
    Fecha1: new Date(),
    Fecha2: new Date(),
    NombreReporte: '',
    Opc1: [false],

  });

  activeButtons: ActiveButtons = {
    all: false,
    print: true,
  }


  constructor(private fb: FormBuilder, private utils: UtilsService) { }

  ngOnInit(): void {
    this.myForm.get('NombreReporte')?.setValue('VERIFICADOR DE COMPRAS ' + this.rangoFechas);
      this.focus('txtNombreReporte');
  }

  onChangeFecha(value: any) {
    this.myForm.get('NombreReporte')?.setValue('VERIFICADOR DE COMPRAS ' + this.rangoFechas);
  }

  get rangoFechas() {
    return this.utils.getEtiquetaRangoPorFechas(this.myForm.value.Fecha1, this.myForm.value.Fecha2, 2).toUpperCase();
  }


  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombreReporte;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  changeselec(event: any) {
    this.filtrosDiarioVentas = event;
  }



  clickBoton(event: string) {
    const ReporteDiarioDeVentas = this.myForm.value;
    this.filtrosDiarioVentas.ReportHeader = this.myForm.value;
    this.reportsService.printReport(this.filtrosDiarioVentas,'/Compras/ReporteVerificadorDeCompras');
  }
}
