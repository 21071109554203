<app-container-base [hasCounter]="false" [entity]="myForm" icon="fa fa-list-check" [hasBar]="false"
  subTitle="Consulta y Re-impresión de Ordenes de Carga">
  <div class="screen-content">
    <div>
      <form [formGroup]="myForm" autocomplete="off">
        <div class="d-flex mt-3">
          <div class="me-2 " style="width: 355px;">
            <app-panel-base title="Búsqueda de la Orden de Carga" [style]="'width:355px;height:175px;'">
              <div class="panel-content">

                <components-ui-number-box (onClicIcon)="enterFolio()" (blur)="enterFolio()" [tabIndex]="1"
                  formControlName="folio" label='Folio' [tabIndex]="1" [widthLabelColumns]="4" [widthTextColumns]="5"
                  [enableIcon]="true" #txtFolio />

              </div>
            </app-panel-base>
          </div>
          <div class="me-2 " style="width: 240px;">
            <app-panel-base title="Otra información" [style]="'width:240px;height:175px;'">
              <div class="panel-content d-flex" style="flex-direction:column;">
                <div *ngIf="myForm.value.enCarga" class="btn btn-sm btn-white mb-10px me-2 text-start"><i
                    class="fa fa-clock text-orange fa-fw fa-lg me-2 "></i>En
                  Proceso de Carga</div>
                <div *ngIf="myForm.value.entregado" class="btn btn-sm btn-white mb-10px me-2 text-start"><i
                    class="fa fa-circle-check text-green fa-fw fa-lg me-2"></i>Listo y Entregado</div>
                <!-- <div *ngIf="myForm.value.id>0 && !myForm.value.fechaCancelacion"
                  class="btn btn-sm btn-white mb-10px me-2 text-start"><i
                    class="fa fa-calendar-xmark text-danger fa-fw fa-lg me-2"></i>Cancelar</div> -->
              </div>
            </app-panel-base>
          </div>
          <div style="width: 240px;">
            <app-panel-base title="Impresiones" [style]="'width:240px;height:175px;'">
              <div *ngIf="myForm.value.id>0" class="panel-content d-flex" style="flex-direction:column;">
                <div (click)="imprimirOrden()" class="btn btn-sm btn-white mb-10px me-2 text-start"><i
                    class="fa fa-print fa-fw fa-lg me-2 "></i>Imprimir Orden De Carga</div>
                <div *ngIf="myForm.value.entregado" (click)="imprimirSalida()" class="btn btn-sm btn-white mb-10px me-2 text-start"><i
                    class="fa fa-print fa-fw fa-lg me-2 "></i>Imprimir Salida</div>
              </div>
            </app-panel-base>
          </div>
        </div>

        <app-panel-base title="General" style="width: 850px;">
          <div class="panel-content">
            <div class="text-center" *ngIf="myForm.value.id > 0">
              <h1 class="text-start ">Serie y Folio: {{myForm.value.serie}}-{{myForm.value.folio}}</h1>
              <h1 class="text-start ">Fecha Emisión: {{myForm.value.fechaEmision}}</h1>
            </div>
            <hr>
            <h2 *ngIf="myForm.value.fechaCancelacion" class="text-start ">Fecha Cancelación:
              {{myForm.value.fechaCancelacion}}</h2>
            <div class="form-group row">
              <div class="col-md-6">
                <component-ui-combo-box [enabled]="false" label="Vehiculo" [inputProperty]="'Nombre'" [zeroMask]="3"
                  [entityName]="'Unidad'" [tabIndex]="2" [widthLabelColumns]="4" [widthTextColumns]="6"
                  [widthRightColumns]="0" formControlName="unidad" #cboVehiculo />
              </div>
              <div class="col-md-6">
                <component-ui-combo-box [enabled]="false" label="Chofer" [inputProperty]="'Nombre'" [zeroMask]="3"
                  [entityName]="'Chofer'" [tabIndex]="3" [widthLabelColumns]="4" [widthTextColumns]="6"
                  [widthRightColumns]="0" formControlName="chofer" />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <components-ui-number-box [formatoMoneda]="true" [enabled]="false" [tabIndex]="4" [maxLength]="500"
                  [label]="'Cantidad '" [widthLabelColumns]="4" [widthTextColumns]="6"
                  formControlName="capacidadCarga" />
              </div>
              <div class="col-md-6">
                <component-ui-combo-box [enabled]="false" label="Ayudante" [inputProperty]="'Nombre'" [zeroMask]="3"
                  formControlName="ayudante" [entityName]="'Chofer'" [tabIndex]="5" [widthLabelColumns]="4"
                  [widthTextColumns]="6" [widthRightColumns]="0" />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <components-ui-number-box [formatoMoneda]="true" [enabled]="false" [tabIndex]="6" [maxLength]="500"
                  [label]="'Cargado'" [widthLabelColumns]="4" [widthTextColumns]="6" formControlName="cargado" />
              </div>
              <div class="col-md-6">
                <component-ui-combo-box [enabled]="false" label="Ayudante 2" [inputProperty]="'Nombre'" [zeroMask]="3"
                  [entityName]="'Chofer'" [tabIndex]="7" [widthLabelColumns]="4" [widthTextColumns]="6"
                  formControlName="ayudante2" [widthRightColumns]="0" />

              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <components-ui-number-box [formatoMoneda]="true" [enabled]="false" [tabIndex]="8" [maxLength]="500"
                  [label]="'Disponible'" [widthLabelColumns]="4" [widthTextColumns]="6" formControlName="disponible" />
              </div>
              <div class="col-md-6">
                <components-ui-number-box [formatoMoneda]="true" [enabled]="false" [tabIndex]="9" [maxLength]="500"
                  [label]="'Odómetro'" [widthLabelColumns]="4" [widthTextColumns]="6" formControlName="odometro" />

              </div>
            </div>
          </div>
        </app-panel-base>


        <app-panel-base *ngIf="myForm.value.id>0" title="Listado de documentos en la orden de carga/salida"
          style="width: 850px;">
          <div class="panel-content">
            <div *ngIf=" myForm.value.id>0" class="accordion" id="accordion">
              <div *ngFor="let item of listadoDocumentos; let i = index;" class="accordion-item border-0">
                <div class="accordion-header" id="headingOne">
                  <button class="accordion-button bg-gray-400 text-black px-3 pt-10px pb-10px pointer-cursor collapsed"
                    type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#accordionItem'+i">
                    <i class="fa fa-file-export fa-fw text-red me-2 fa-lg text-center"></i>
                    <b>{{item.Serie}}-{{item.Folio}}</b> | Número de registros {{item.Detalle.length}}
                  </button>
                </div>
                <div [id]="'accordionItem'+i" class="accordion-collapse collapse collapse" data-bs-parent="#accordion">
                  <div class="accordion-body bg-gray-200 text-white text-start p-1 ">

                      <table class="blueTable" style="width:800px;" >
                        <thead style="position: sticky;top:0;">
                          <tr>
                            <th style="width: 30px;">Código</th>
                            <th style="width: 120px;">Descripción</th>
                            <th style="width: 30px;">Cantidad</th>
                            <th style="width: 50px;">Unidad</th>
                            <th style="width: 80px;">Conversiones</th>
                          </tr>
                        </thead>
                        <tbody class="bg-light">
                          <tr [style]=" indexEditing == index ?  'background-color: #ffdbb7;': ''"
                            *ngFor="let item of item.Detalle; let index = index;">
                            <td style="text-align: leftr;color: black;">
                              {{ item.Clave }}
                            </td>
                            <td style="text-align: left;color: black;margin-left:2px;">
                              {{ item.Descripcion }}
                            </td>
                            <td style="text-align:right;color: black;margin-right:2px;">
                                {{ item.Cantidad | number:'1.2-6' }}
                            </td>
                            <td style="text-align:left;color: black;margin-left:2px;">
                                {{item.Unidad.nombre}}
                            </td>
                            <td style="text-align:left;color: black;margin-left:2px;">
                                {{item.TextoConversiones}}
                            </td>
                          </tr>
                        </tbody>
                      </table>

                    <!-- <gui-grid [source]="item.Detalle" [autoResizeWidth]="true" [virtualScroll]="true" [rowHeight]="20"
                      [rowSelection]="false" [theme]="'fabric'" [maxHeight]="300">
                      <gui-grid-column [align]="GuiColumnAlign.CENTER" header="Código" [width]="100"
                        [cellEditing]="false">
                        <ng-template let-item="item">
                          {{ item.Clave }}
                        </ng-template>
                      </gui-grid-column>
                      <gui-grid-column [align]="GuiColumnAlign.LEFT" header="Descripción" [width]="200"
                        [cellEditing]="false">
                        <ng-template let-item="item">
                          {{ item.Descripcion }}
                        </ng-template>
                      </gui-grid-column>
                      <gui-grid-column header="Cantidad" [align]="GuiColumnAlign.RIGHT" [width]="100"
                        [cellEditing]="false">
                        <ng-template let-item="item">
                          {{ item.Cantidad | number:'1.2-6' }}
                        </ng-template>
                      </gui-grid-column>
                      <gui-grid-column header="Unidad" [align]="GuiColumnAlign.CENTER" [width]="100"
                        [cellEditing]="false">
                        <ng-template let-item="item">
                          {{item.Unidad.nombre}}
                        </ng-template>
                      </gui-grid-column>
                      <gui-grid-column header="Conversiones" [align]="GuiColumnAlign.CENTER" [width]="100"
                        [cellEditing]="false">
                        <ng-template let-item="item">
                          {{item.TextoConversiones}}
                        </ng-template>
                      </gui-grid-column>
                    </gui-grid> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </app-panel-base>

        <app-panel-base title="Usuarios" [style]="'width:850px;'" *ngIf="myForm.value.id>0">
          <div class="panel-content">
            <div class="widget-list mb-0" style="display: flex;">
              <div class="widget-list-item" style="width:25%;">
                <div class="widget-list-media">
                  <i class="fas fa-user-check fa-2x text-green"></i>
                </div>
                <div class="widget-list-content">
                  <h4 class="widget-list-title">{{myForm.value.usuarioElabora.Nombre}}</h4>
                  <p class="widget-list-desc">Usuario elaboró</p>
                </div>
              </div>
              <div *ngIf="myForm.value.usuarioCancela" class="widget-list-item" style="width:25%;">
                <div class="widget-list-media">
                  <i class="fas fa-user-xmark fa-2x text-red"></i>
                </div>
                <div class="widget-list-content">
                  <h4 class="widget-list-title">{{myForm.value.usuarioCancela.Nombre}}</h4>
                  <p class="widget-list-desc">Usuario canceló</p>
                </div>
              </div>
            </div>
          </div>
        </app-panel-base>

        <div class="cancelled-header animate__animated animate__bounceInDown" style="margin-top: -179px !important;"
          *ngIf="myForm.value.fechaCancelacion">
          <div class="cancelled">&nbsp;</div>
        </div>
      </form>
    </div>
  </div>
</app-container-base>
