import { Component, ElementRef, inject, OnInit, ViewChild } from '@angular/core';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';
import { Cheque, ChequeDetalle } from '../../interfaces/transferencia.interface';
import Swal from 'sweetalert2';
import { EventsService } from 'src/app/service/events.service';
import { UserLogged } from 'src/app/auth/interfaces';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { ComboBoxComponent } from 'src/app/component-ui/components/combo-box/combo-box.component';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { NumberBoxComponent } from 'src/app/component-ui/components/number-box/number-box.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FinanzasService } from '../../services/finanzas.service';
import { FacturacionService } from 'src/app/ventas/services/facturacion.service';
import { CompraService } from 'src/app/compras/services/compras.service';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { CuentasPorPagarService } from 'src/app/cuentas-por-pagar/services/cxp.service';
import { ModalService } from 'src/app/service/modal.service';
import { UtilsService } from 'src/app/service/utils.service';
import * as moment from 'moment';
import { SaldoCXP } from 'src/app/cuentas-por-pagar/interfaces/saldocxp.interface';
import { MovimientoPoliza } from 'src/app/contabilidad/interfaces/movimientopoliza.interface';
import { ContabilidadService } from 'src/app/contabilidad/services/contabilidad.service';
import { ReportsService } from 'src/app/service/reports.service';
import { AppMenuService } from 'src/app/home/services/app-menus.service';

@Component({
  selector: 'app-cheques-page',
  templateUrl: './cheques-page.component.html',
  styles: [
  ]
})
export class ChequesPageComponent implements OnInit {
  saldo: number = 0;
  movimientos: any[] = [];
  listaPoliza: MovimientoPoliza[] = [];
  private reportsService = inject(ReportsService);
  private eventsService = inject(EventsService);
  info: UserLogged = {} as UserLogged;
  activeButtons: ActiveButtons = {
    new: true,
    delete: false,
    return: false,
    save: true,
    first: false,
    left: false,
    right: false,
    last: false,
    search: false,
    print: true,
  }
  @ViewChild('cboTipoTrans')
  public cboTipoTrans!: ElementRef<ComboBoxComponent>;

  @ViewChild('txtProveedor')
  public txtProveedor!: ElementRef<TextBoxComponent>;

  @ViewChild('txtImporte')
  public txtImporte!: ElementRef<NumberBoxComponent>;

  @ViewChild('modalCancelacion')
  public modalCancelacion!: ElementRef<HTMLElement>;

  @ViewChild('ctrlPoliza')
  public ctrlPoliza!: ElementRef<any>;
  private menuService = inject(AppMenuService);
  idUnico: string = '';
  constructor(
    private fServices: FinanzasService,
    private fs: FacturacionService,
    private fb: FormBuilder,
    private compraService: CompraService,
    private contaService: ContabilidadService,
    private cService: ContainerBaseService,
    private cpService: CuentasPorPagarService,
    private ms: ModalService,
    private utileService: UtilsService,) {
  }

  public myForm: FormGroup = this.fb.group({
    Id: [0],
    Clave: [0],
    Nombre: [''],
    Empresa: [null],
    Tipo: [null],
    Folio: [0],
    FechaEmision: [Date],
    FechaTransferencia: [Date],
    FechaCancelacion: [null],
    FechaAplicacion: [null],
    Proveedor: [null],
    Concepto: [null],
    Beneficiario: [''],
    AbonoEnCuenta: [false],
    ColocarBeneficiario: [false],
    ImporteTransferencia: [0],
    Disponible: [0],
    Seleccionado: [0],
    Moneda: [null],
    TipoCambio: [0],
    NumeroPoliza: [0],
    TipoPoliza: [''],
    MotivoCancelacion: [''],
    Observaciones: [''],
    IdPoliza: [0],
    Detalle: [[]],
    UsuarioElabora: [null],
    UsuarioCancela: [null],
  });


  ngOnInit(): void {
    this.idUnico = String(new Date().getTime() * 10000);
    this.info = this.utileService.getUserLogged();
    setTimeout(() => {
      const t: any = this.cboTipoTrans;
      t.tagInput.nativeElement.focus();
    }, 100);
  }

  onSelectedItem(entity: any, tipo: string) {
    if (tipo == "Tipo") {
      if (entity) {
        const t: any = this.txtProveedor;
        t.tagInput.nativeElement.value = "";
        this.eventsService.publish('home:isLoading', { isLoading: true });
        this.myForm.controls[tipo].setValue(entity);
        this.getEmptyEntity(entity.Id);
      }
    } else {
      this.myForm.controls[tipo].setValue(entity);
    }
  }

  elementoSeleccionado(ent: any) {
    this.enterProveedor();
  }


  get getCancelDate(): string {
    if (this.myForm.value.FechaCancelacion) {
      return moment(this.myForm.value.FechaCancelacion).format("DD/MM/YYYY");
    }
    return "";
  }


  enterProveedor() {
    setTimeout(() => {
      const t: any = this.txtProveedor;
      const valueProv = t.tagInput.nativeElement.value;
      if (valueProv) {
        if (this.myForm.value.Proveedor?.Clave == Number(valueProv)) {
          return;
        }
      }

      if (t.tagInput.nativeElement.value) {
        this.eventsService.publish('home:isLoading', { isLoading: true });
        this.compraService.getProveedor(this.info.empresa!.numero, t.tagInput.nativeElement.value).subscribe((prov) => {
          if (prov) {
            this.cpService.getSaldo(this.info.empresa!.numero, prov.Id).subscribe((saldo) => {
              this.saldo = saldo;
              this.cpService.getProviderBalance(this.info.empresa!.numero, 0, prov.Id).subscribe((lista) => {
                this.eventsService.publish('home:isLoading', { isLoading: false });
                this.movimientos = lista;
                this.myForm.controls["Proveedor"].setValue(prov);
                this.myForm.controls["Beneficiario"].setValue(prov.Nombre);
                const t: any = this.txtImporte;
                t.tagInput.nativeElement.focus();
              })
            })
          } else {
            this.eventsService.publish('home:isLoading', { isLoading: false });
            this.saldo = 0;
            this.movimientos = [];
            const t: any = this.txtProveedor;
            t.tagInput.nativeElement.value = "";
            this.eventsService.publish('home:showAlert', { message: "No se encontró el Proveedor indicado.", cancelButton: false });
            this.myForm.controls["Proveedor"].setValue(null);
            this.myForm.controls["Beneficiario"].setValue("");
          }
        })
      }
    }, 50);
  }



  getEmptyEntity(idTipo: number) {
    this.cService.getEmptyEntity("Transferencia").subscribe((ent) => {
      this.initializeEntity(ent, idTipo)
    })
  }

  initializeEntity(ent: any, idTipo: number) {
    ent.Empresa = { Id: this.info.empresa!.numero };
    this.myForm.get("Id")?.setValue(0); // no mover esta linea ALL.
    ent.FechaEmision = new Date();
    ent.FechaTransferencia = new Date();
    ent.Tipo = { Id: idTipo };
    this.getNextFolio(ent);
  }

  getNextFolio(ent: Cheque) {
    this.fServices.getSiguienteFolioCheque(this.info.empresa!.numero, ent.Tipo.Id).subscribe((folio) => {
      ent.Folio = folio;
      this.getDate(ent);
    })
  }

  getDate(ent: Cheque) {
    this.fs.ObtenerFechaPorSucursal(this.info.sucursal!.numero).subscribe((result) => {
      const f = result.message.split('T')[0].split('-');
      const t = result.message.split('T')[1].split(':');
      ent.FechaEmision = new Date(parseInt(f[0]), parseInt(f[1]) - 1, parseInt(f[2]), parseInt(t[0]), parseInt(t[1]), parseInt(t[2]));
      ent.FechaTransferencia = ent.FechaEmision;
      this.eventsService.publish('home:isLoading', { isLoading: false });
      this.myForm.reset(ent);
      const ctrlPoliza: any = this.ctrlPoliza;
      ctrlPoliza.setMovimientos([], new Date());
      this.listaPoliza = [];
      setTimeout(() => {
        const t: any = this.txtProveedor;
        t.tagInput.nativeElement.focus();
      }, 100);
    })
  }

  get getTotalUsado(): number {
    let usado = 0;
    this.movimientos.forEach((element: SaldoCXP) => { usado += element.abono; });
    return usado;
  }

  get getDiferencia(): number {
    return Number(this.myForm.value.ImporteTransferencia - this.getTotalUsado);
  }

  mark(index: number, event: any) {
    const limite = this.getDiferencia;
    const elem: SaldoCXP = this.movimientos[index];

    if (this.myForm.value.ImporteTransferencia <= 0) {
      this.eventsService.publish('home:showAlert', { message: "Debe de indicar el Importe del Cheque.", cancelButton: false });
      event.preventDefault();
      elem.seleccionado = false;
      this.movimientos[index] = elem;
      this.movimientos = [...this.movimientos];
      return;
    }
    elem.seleccionado = !elem.seleccionado;
    if (elem.seleccionado) {
      if (elem.saldo <= limite) {
        elem.abono = elem.saldo;
        elem.observaciones = "Liquida";
      } else if (limite > 0) {
        elem.abono = limite;
        elem.observaciones = "Parcial";
      } else {
        elem.seleccionado = false;
        elem.abono = 0;
        event.preventDefault();
      }
    } else {
      elem.observaciones = "";
      elem.abono = 0
    }
    this.myForm.controls["Seleccionado"].setValue(this.getTotalUsado);
    this.myForm.controls["Disponible"].setValue(this.getDiferencia);
    this.movimientos[index] = elem;
    this.movimientos = [...this.movimientos];
  }

  blurFolio(values: any) {
    if (values.after != values.before) {
      this.findEntityByParams(values.after);
    }
  }

  findEntityByParams(value: any) {
    if (value) {
      const ent = this.myForm.value;
      this.eventsService.publish('home:isLoading', { isLoading: true });
      this.fServices.findChequeByParams(ent.Empresa.Id, ent.Tipo.Id, value).subscribe((result) => {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        if (result.success) {
          const trans: Cheque = JSON.parse(result.message);
          this.setEntity(trans);
        } else {
          this.setEntity(null);
        }
      });
    } else {
      this.new();
    }
  }

  setEntity(trans: Cheque | null) {
    this.movimientos = [];
    if (!trans) {
      this.new();
    } else {
      this.resetTransferencia(trans);
    }
  }

  onClickBarButton(button: string): void {
    switch (button) {
      case "new": this.new(); break;
      case "save": this.save(); break;
      case "print": this.print(); break;
    }
  }

  new() {
    const ent: Cheque = this.myForm.value;
    if (ent.Tipo) {
      this.movimientos = [];
      const t: any = this.txtProveedor;
      t.tagInput.nativeElement.value = "";
      this.eventsService.publish('home:isLoading', { isLoading: true });
      this.getEmptyEntity(ent.Tipo.Id);
    } else {
      this.eventsService.publish('home:showAlert', { message: "Primero indique el Tipo de Pago que se va a realizar.", cancelButton: false });
    }
  }

  save(validarCxp: boolean = true, validarPoliza: boolean = true) {
    const ent: Cheque = this.myForm.value;
    if (!ent.Tipo) {
      this.eventsService.publish('home:showAlert', { message: "Primero indique el Tipo de Pago que se va a realizar.", cancelButton: false });
      return;
    }
    if (!ent.Proveedor) {
      this.eventsService.publish('home:showAlert', { message: "Primero indique el Proveedor.", cancelButton: false });
      return;
    }
    if (ent.Folio <= 0) {
      this.eventsService.publish('home:showAlert', { message: "Primero indique el Folio.", cancelButton: false });
      return;
    }
    if (!ent.FechaTransferencia) {
      this.eventsService.publish('home:showAlert', { message: "Primero indique la Fecha.", cancelButton: false });
      return;
    }
    if (!ent.Concepto) {
      this.eventsService.publish('home:showAlert', { message: "Primero indique el Concepto.", cancelButton: false });
      return;
    }

    if (!ent.Beneficiario && ent.ColocarBeneficiario) {
      this.eventsService.publish('home:showAlert', { message: "Debe de indicar el Beneficiario.", cancelButton: false });
      return;
    }

    if (ent.ImporteTransferencia <= 0) {
      this.eventsService.publish('home:showAlert', { message: "Debe de indicar el Importe del Cheque.", cancelButton: false });
      return;
    }

    if (!ent.Moneda) {
      this.eventsService.publish('home:showAlert', { message: "Debe de indicar la Moneda.", cancelButton: false });
      return;
    }

    let movs: SaldoCXP[] = this.movimientos.filter(P => P.abono > 0);
    if (movs.length == 0 && validarCxp) {
      this.eventsService.publish('home:showAlert', {
        message: "No ha seleccionado ningun Documento de Cuentas Por Pagar ¿Desea Continuar?", onConfirm: (data: AlertResponse) => {
          if (data.isAccept) {
            this.save(false);
          }
        }
      });
      return
    }

    const ctrlPoliza: any = this.ctrlPoliza;
    const movsPoliza: MovimientoPoliza[] = ctrlPoliza.getMovimientos;

    if (movsPoliza.length == 0) {
      this.eventsService.publish('home:showAlert', { message: "No ha capturado la Póliza.", cancelButton: false });
      return
    }

    const difPoliza: number = ctrlPoliza.getDiffMXN;

    if (difPoliza != 0 && validarPoliza) {
      this.eventsService.publish('home:showAlert', {
        message: "La Póliza capturada no cuadra ¿Desea Continuar?", onConfirm: (data: AlertResponse) => {
          if (data.isAccept) {
            this.save(false, false);
          }
        }
      });
      return
    }

    let agregar: ChequeDetalle[] = [];

    movs.forEach((i) => {

      let n: ChequeDetalle = {
        Id: 0,
        Seleccionado: true,
        Sucursal: i.sucursal,
        Serie: i.serie,
        Folio: i.folio,
        FacturaProveedor: i.facturaProveedor,
        EmisionString: i.emisionString,
        VencimientoString: i.vencimientoString,
        Emision: i.emision,
        Vencimiento: i.vencimiento,
        TipoCambio: i.tipoCambio,
        Moneda: i.moneda,
        Total: i.total,
        Saldo: i.saldo,
        Abonado: i.abono,
        Observaciones: i.observaciones,
      };
      agregar = [...agregar, n]
    });

    ent.Detalle = agregar;

    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.fServices.guardarCheque(ent, movsPoliza).subscribe((result) => {
      if (result.success) {
        this.fServices.findChequeById(Number(result.message)).subscribe((result) => {
          this.eventsService.publish('home:isLoading', { isLoading: false });
          Swal.fire({ position: 'center', icon: 'success', title: 'Se guardó correctamente', showConfirmButton: false, timer: 1000 }).then(() => {
            const trans: Cheque = JSON.parse(result.message);
            this.resetTransferencia(trans, true);
          });
        })
      } else {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        this.eventsService.publish('home:showAlert', { message: `${result.message}`, cancelButton: false, icon: ' fa-triangle-exclamation text-yellow' });
      }
    });
  }

  resetTransferencia(trans: Cheque, esGuardar: boolean = false) {
    this.movimientos = [];
    let agregar: SaldoCXP[] = [];
    trans.Detalle.forEach((i) => {
      let n: any = {
        seleccionado: true,
        sucursal: { nombre: i.Sucursal.Nombre },
        cuenta: null,
        serie: i.Serie,
        folio: i.Folio,
        serieFolio: `${i.Serie}-${i.Folio}`,
        facturaProveedor: i.FacturaProveedor,
        moneda: { nombre: i.Moneda.Nombre },
        emision: i.Emision,
        vencimiento: i.Vencimiento,
        emisionString: i.EmisionString,
        vencimientoString: i.VencimientoString,
        total: i.Total,
        saldo: i.Saldo,
        abono: i.Abonado,
        observaciones: i.Observaciones,
      };
      agregar = [...agregar, n]
    })
    this.movimientos = [...agregar]
    const ctrlPoliza: any = this.ctrlPoliza;
    this.myForm.reset(trans);
    if (trans.Id > 0) {
      this.contaService.getPolicyByCheque(trans.Id).subscribe((lista) => {
        ctrlPoliza.setMovimientos(lista, trans.FechaTransferencia);
        this.listaPoliza = lista;
        if (lista.length > 0 && esGuardar) {
          this.print();
        }
      });
    } else {
      this.listaPoliza = [];
      ctrlPoliza.setMovimientos([], new Date());
    }
    const t: any = this.txtProveedor;
    t.tagInput.nativeElement.value = trans.Proveedor.Clave;
  }

  cancelar() {
    const b: any = this.modalCancelacion;
    this.ms.openModal(b, (e: any) => {
      if (e) {
        this.proceedCancel(e.MotivoCancelacion);
      }
    })
  }

  verPoliza() {
    let item = this.menuService.getComponentPages().find((c: any) => c.url === 'capturapolizas');
    if (item) {
      this.eventsService.publish('home:openNewTab', { url: "capturapolizas", inputs: { idCheque: this.myForm.value.Id } });
    } else {
      this.eventsService.publish('home:showAlert', { message: `No tiene permiso para ver en pantalla.`, cancelButton: false });

    }

  }

  proceedCancel(motivo: string) {

    this.eventsService.publish('home:showAlert', {
      message: '¿Está seguro de proceder con la cancelación del Cheque?',
      onConfirm: (r: AlertResponse) => {
        if (r.isAccept) {
          this.eventsService.publish('home:isLoading', { isLoading: true });
          this.fServices.cancelarCheque(this.myForm.value.Id, motivo).subscribe((result) => {
            if (result.success) {
              this.eventsService.publish('home:isLoading', { isLoading: false });
              this.fServices.findChequeById(this.myForm.value.Id).subscribe((resultB) => {
                this.eventsService.publish('home:isLoading', { isLoading: false });
                Swal.fire({ position: 'center', icon: 'success', title: 'Se canceló correctamente', showConfirmButton: false, timer: 1000 }).then(() => {
                  const trans: Cheque = JSON.parse(resultB.message);
                  this.resetTransferencia(trans);
                });
              })
            } else {
              this.eventsService.publish('home:isLoading', { isLoading: false });
              this.eventsService.publish('home:showAlert', { message: `${result.message}`, cancelButton: false, icon: ' fa-triangle-exclamation text-yellow' });
            }
          });
        }
      }
    });
  }

  print() {
    if (this.listaPoliza.length > 0) {
      this.enviarImprimir();
    } else {
      this.eventsService.publish('home:showAlert', { message: 'No hay una Póliza relacionada a la Tranferencia.', cancelButton: false });
    }
  }

  enviarImprimir(validarMoneda: boolean = true, esMxn: boolean = true, imprimirLeyendaMMN: boolean = false) {

    let tiposCambiosL = this.listaPoliza.filter(P => P.tipoCambio != 0).length;

    if (tiposCambiosL > 0 && validarMoneda) {
      this.eventsService.publish('home:showAlert', {
        textAccept: 'Pesos Mexicanos',
        textCancel: 'Moneda Extranjera',
        message: "Se ha detectado Asientos Contables con Tipo de Cambio, ¿Como desea imprimir?.", onConfirm: (data: AlertResponse) => {
          this.enviarImprimir(false, data.isAccept, data.isAccept);
        }
      });
    } else {
      const mov: any = this.listaPoliza[0];
      let body = {
        idEmpresa: this.info.empresa!.numero,
        fecha: moment(mov.fecha).format("DD/MM/YYYY"),
        idTipoPoliza: mov.tipoPoliza!.id,
        folio: mov.folio,
        esMxn: esMxn,
        imprimirLeyendaMN: imprimirLeyendaMMN
      };
      this.reportsService.printReportWithBody(body, "/contabilidad/reportepoliza", "Impresión de Póliza")
    }
  }


}
