import { Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { ConfiguracionService } from '../../service/configuracion.service';
import { SucursalesPermiso, Usuario } from '../../interfaces/usuario.interface';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbDateAdapter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { Rol } from '../../interfaces/rol.interface';
import { Puesto } from '../../interfaces/puesto.interface';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { EnumDataType, TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { EventReturn } from 'src/app/component-ui/interfaces/container-base.interface';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { Empresa } from '../../interfaces/empresa.interface';
import { Sucursal } from '../../interfaces/sucursal.interface';
import { BaseService } from '../../../service/base.service';
import { SeleccionEmpresa } from 'src/app/components/interfaces';
import { MenuItem } from 'src/app/component-ui/interfaces/checkboxlist.interface';
import * as _ from 'lodash';
import { Result } from 'src/app/auth/interfaces';
import Swal from 'sweetalert2';
import { EventsService } from 'src/app/service/events.service';
import { FileUploadService } from 'src/app/component-ui/services/file-upload-box.service';
import { ReportsService } from '../../../service/reports.service';
import { ModalService } from 'src/app/service/modal.service';

@Component({
  selector: 'usuario-page',
  templateUrl: './usuario-page.component.html',
  styleUrls: ['./usuario-page.component.scss'
  ],
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }],
})

export class UsuarioPageComponent implements OnInit {

  @ViewChild('empresasSucursalesTree')
  public empresasSucursalesTree!: ElementRef<HTMLElement>;

  @ViewChild('txtLogin')
  public txtLogin!: ElementRef<TextBoxComponent>;

  public EDT = EnumDataType;
  public enableContrasena: boolean = false;
  public itemsCombo: ComboBoxEntity[] = [];
  public checkCambiarContrasena: boolean = false


  empresas: MenuItem[] = []
  sucursalesPermisos: SucursalesPermiso[] = [];

  value9: string[] = [];

  public myForm: FormGroup = this.fb.group({
    Id: [0],
    Clave: [0],
    Alias: ['', Validators.required],
    Login: ['', Validators.required],
    Email: ['', Validators.pattern(this.configService.RegExEmail)],
    Nombre: ['', Validators.required],
    Contrasena: [''],
    SolicitarCambioContrasena: [false],
    Baja: [false],
    EsVendedor: [false],
    EsCajero: [false],
    Asientos: [[]],
    EsDistribuidor: [false],
    NoEditarEncabezadoPoliza: [false],
    HoraInicioLabor: [''],
    HoraFinLabor: [''],
    DocumentosPuedeCancelar: [''],
    NoValidarPreciosVentas: [false],
    NoExpiraSesion: [false],
    Rol: [{} as Rol, Validators.required],
    Puesto: [{} as Puesto, [Validators.required]],
    JefeInmediato: [{} as Usuario],
    ConfContrasena: [''],
    checkCambiarContrasena: [false],
    EsVendedorForaneo: [false],
    Sustituto: [{} as Usuario],
    Empresa: [{} as Empresa],
    EmpresaPertenece: [{} as Empresa],
    SucursalPertenece: [{} as Sucursal],
    PuedeActualizarBaseDatos: [false],
    SucursalesPermisos: [{} as SucursalesPermiso[]],
    EsCobrador:[false]
  }, {
    validators: [
      this.isFieldMinLength('Contrasena'),
      this.isFieldOneEqualFieldTwo('Contrasena', 'ConfContrasena'),
    ]
  }
  )

  @ViewChild('txtNombre')
  public txtNombre!: ElementRef<TextBoxComponent>;

  @ViewChild('confcontrasena')
  public confcontrasena!: ElementRef<TextBoxComponent>;


  private modalService = inject(ModalService);
  private baseService = inject(BaseService);
  private fileUploadService = inject(FileUploadService);
  private reportsService = inject(ReportsService);

  constructor(private fb: FormBuilder, private router: Router,
    private containerService: ContainerBaseService,
    private configService: ConfiguracionService) {
  }

  ngOnInit(): void { }

  public isFieldMinLength(field1: string) {

    return (formGroup: AbstractControl): ValidationErrors | null => {

      if (!this.myForm) {
        return null;
      }

      const fieldValue1 = this.myForm.get(field1)?.value;

      if (fieldValue1?.length < 6) {
        this.myForm.get(field1)?.setErrors({ notMinLength: true });
        return { notEqual: true }
      } else {
        return null;
      }
    }

  }

  public isFieldOneEqualFieldTwo(field1: string, field2: string) {

    return (formGroup: AbstractControl): ValidationErrors | null => {
      if (!this.myForm) {
        return null;
      }

      const fieldValue1 = this.myForm.get(field1)?.value;
      const fieldValue2 = this.myForm.get(field2)?.value;

      if (fieldValue1 !== fieldValue2) {
        this.myForm.get(field2)?.setErrors({ notEqual: true });
        return { notEqual: true }
      }

      this.myForm.get(field2)?.setErrors(null);
      return null;
    }
  }

  isValidField(field: string) {
    return this.myForm.controls[field].errors;
  }

  onNewCatalog(next: number) {
    this.containerService.getEmptyEntity("Usuario").subscribe((elem) => {
      elem.Clave = next;
      this.myForm.reset(elem);
      console.log(this.myForm.value)
      this.focus("txtNombre")
    })
  }

  clickBoton(value: any) {
    if (value == "print") {
      this.reportsService.printReportWithoutFilters("/config/ImprimirUsuarios/", "Reporte de usuarios");
      // this.configService.imprimirUsuarios("Reporte de usuarios.pdf").subscribe({
      //   next: (result: any) => {
      //     if (result.success) {
      //       var fileblob = this.b64toBlob(result.message, 'application/pdf');
      //       var url = window.URL.createObjectURL(fileblob);
      //       window.open(url, 'reporte.pdf');
      //     }
      //   },
      //   error: (err: Error) => {
      //     console.log(err);
      //   }
      // });
    }
  }

  b64toBlob = (b64Data: any, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  selectedRol(entity: ComboBoxEntity) {
    this.myForm.controls["Rol"].setValue(entity);
  }
  selectedSustituto(entity: ComboBoxEntity) {
    this.myForm.controls["Sustituto"].setValue(entity);
  }
  selectedEmpresa(entity: ComboBoxEntity) {
    this.myForm.controls["EmpresaPertenece"].setValue(entity);
  }

  selectedSucursal(entity: ComboBoxEntity) {
    this.myForm.controls["SucursalPertenece"].setValue(entity);
  }

  selectedJefe(entity: ComboBoxEntity) {
    this.myForm.controls["JefeInmediato"].setValue(entity);
  }

  selectedPuesto(entity: ComboBoxEntity) {
    this.myForm.controls["Puesto"].setValue(entity);
  }

  onChangePass(value: any) {
    this.enableContrasena = value.currentTarget.checked;
  }

  cambiarcheck(value: boolean) {
    this.myForm.controls["checkCambiarContrasena"].setValue(false);
    this.enableContrasena = false;
  }

  onAfterSave(entity: Usuario) {
    if (this.imgFile) {
      this.fileUploadService.uploadProfilePhoto(this.imgFile, entity.Id, (urlImage: string) => {
        this.cargarImagenPerfil({ RutaImagenPerfil: urlImage })
      });
    }
    entity.Contrasena = null;
    this.myForm.reset(entity);
  }

  get getItemsCombo(): ComboBoxEntity[] {
    return this.itemsCombo;
  }

  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombre;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  onChangeEntity(ent: any) {
    if (ent) {
      ent.Contrasena = null;
      this.checkCambiarContrasena = false;
      this.enableContrasena = false;
      this.cargarImagenPerfil(ent)
      this.myForm.reset(ent);
    }
    this.focus('txtNombre')
  }

  onItemSearched(entity: Usuario): void {
    if (entity) {
      entity.Contrasena = null;
      let suc: any[] = [];
      if (entity.SucursalesPermisos) {
        entity.SucursalesPermisos!.forEach(element => {
          // let itemAdd = this.fb.group({
          //   Baja: element.Baja,
          //   Eliminado: element.Eliminado,
          //   Id: element.Id,
          //   Sucursal: element.Sucursal,
          //   Padre: element.Padre
          // })
          // this.sucursalesPermisos.push(itemAdd);
          suc.push(element.Sucursal.Id);
        });
      } else {
        // if (entity.SucursalesPermisos == null) {
        //    entity.SucursalesPermisos = this.sucursalesPermisos[];
        //  }
      }
      this.cargarImagenPerfil(entity);
      this.myForm.reset(entity);
    }
    this.focus("txtNombre");
  }

  onBeforeSave(param: EventReturn) {
    //Aquí valido mi pantalla, que tenga lo que tenga que tener
    this.cambiarcheck(false);
    // const ent: Usuario = this.myForm.value;
    param.callback(true);
  }

  get getIsNew(): any {
    return this.myForm.value;
  }

  get getFilterSucursal(): string {
    if (this.myForm.value.EmpresaPertenece) {
      return `Empresa.Id = ${this.myForm.value.EmpresaPertenece.Id}`;
    }
    return "Id = 9999";
  }

  get getFiltroUsuario(): string {
    let filtro: string = "";
    if (this.myForm.controls["Id"].value) {
      filtro = " Id <> " + this.myForm.controls["Id"].value;
    }
    return filtro;
  }

  //Entra aquí cuando el container trae la lista de objetos a mostrar en el combo clave
  onItemsComboSearched(items: ComboBoxEntity[]): void {
    this.itemsCombo = items;
  }

  onCloseAsiento(resp: any) {
    if (resp) {
      this.myForm.controls["Asientos"].setValue([...resp.items]);
    }
  }

  openEmpresasSucursales() {
    this.empresas = [];
    this.baseService.getEmpresasSucursales().subscribe((result: SeleccionEmpresa[]) => {
      for (const item of result) {
        let sucursales: MenuItem[] = [];
        for (const s of item.sucursales) {
          sucursales.push({
            Id: s.id,
            Seleccionado: false,
            IsExpanded: false,
            Guardar: false,
            Eliminar: false,
            Nombre: s.nombre,
            Icono: "building",
            Img: "",
            SubMenus: []
          })
        }

        this.empresas.push({
          Id: item.id,
          Seleccionado: false,
          IsExpanded: false,
          Guardar: false,
          Eliminar: false,
          Nombre: item.nombre,
          Icono: "building",
          Img: "",
          SubMenus: sucursales,
        })
      }

      this.sucursalesPermisos = _.cloneDeep(this.myForm.value.SucursalesPermisos);
      if (this.myForm.value.SucursalesPermisos) {
        for (const e of this.empresas) {
          let encontroSucursal: boolean = false;
          for (const s of e.SubMenus!) {
            if (this.myForm.value.SucursalesPermisos?.filter((x: any) => x.Sucursal.Id === s.Id).length > 0) {
              s.Seleccionado = true;
              encontroSucursal = true;
            } else {
              s.Seleccionado = false;
            }
          }
          e.Seleccionado = encontroSucursal;
        }
      }
      this.modalService.openModal(this.empresasSucursalesTree, null, 'md');
    })
  }

  accept() {
    this.modalService.closeModal(null);
  }

  onSelect(item: any) {
    item.IsExpanded = !item.IsExpanded;
    this.empresas = [...this.empresas];
  }

  getCaret(item: any, lastLevel: boolean = false): string {
    let ddclass: string = `fas fa-${(item.IsExpanded ? 'caret-down' : 'caret-right')}  fa-lg`;
    if (lastLevel) {
      ddclass = " mleft";
    }
    return ddclass;
  }

  seleccionar(item: MenuItem) {
    item.Seleccionado = !item.Seleccionado;
    if (item.SubMenus) {
      item.SubMenus.map((item2) => {
        item2.Seleccionado = item.Seleccionado

        if (item2.SubMenus) {
          item2.SubMenus.map((item3) => {
            item3.Seleccionado = item.Seleccionado;
          });
        }
        return item2;
      });
    }
    this.empresas = [...this.empresas];

    let sucursalesPermiso: SucursalesPermiso[] = this.sucursalesPermisos ? this.sucursalesPermisos : [];
    let sucursalesSeleccionadas: SucursalesPermiso[] = []
    for (const e of this.empresas) {
      for (const s of e.SubMenus!.filter(x => x.Seleccionado)) {
        let sp = sucursalesPermiso.find(x => x.Sucursal.Id === s.Id);
        let sSelect = sp ? sp : {
          Id: 0,
          Sucursal: { Id: s.Id },
          Eliminado: false,
          Baja: false,
        }
        sucursalesSeleccionadas = [...sucursalesSeleccionadas, sSelect];
      }
    }
    this.myForm.controls["SucursalesPermisos"].setValue(sucursalesSeleccionadas);
  }

  nombreUsuario: string = "";
  validarUsuarioExistente(event: any) {
    this.nombreUsuario = this.myForm.get("Login")?.value
    if (event.after) {
      let id = this.myForm.controls["Id"].value ? this.myForm.controls["Id"].value : 0;
      this.configService.validaUsuarioExistente(id, event.after).subscribe({
        next: (result: Result) => {
          if (result.success) {
            Swal.fire({
              position: 'center',
              icon: 'warning',
              title: "Dato Inválido",
              text: 'El campo Login ya existe en la base de datos.',
              confirmButtonText: "Aceptar",
              confirmButtonColor: "#3085d6",
              allowEscapeKey: false,
            }).then((result) => {
              setTimeout(() => {
                (this.txtLogin as any).tagInput.nativeElement.focus();
              }, 300);
            });
          }
          console.log(result.success);
        },
        error: (err) => {
          console.log(err);
        }
      });
    }
  }

  imgUrl: string = "";
  imgFile: any = null;
  onSelectFileImage(event: any) {
    this.imgUrl = event.photoUrl;
    this.imgFile = event.file;
  }

  cargarImagenPerfil(entidad: any) {
    this.imgUrl = "";
    this.nombreUsuario = "";
    if (entidad.RutaImagenPerfil) {
      this.obtenerImagenPerfil(entidad.RutaImagenPerfil);
    } else {
      this.nombreUsuario = entidad.Login;
    }
  }

  obtenerImagenPerfil(ruta: string) {
    this.configService.obtenerImagenPerfil(ruta.replaceAll("\\", "|")).subscribe({
      next: (resp: Result) => {
        console.log(resp);
        this.imgUrl = "data:image/png;base64, " + resp.message;
      },
      error: (err: Error) => {
        console.log(err.message)
      }
    })
  }

  public delete() {
    this.imgUrl = '';
  }
}
