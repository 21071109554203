import { Injectable, inject } from '@angular/core';
import * as signalR from "@microsoft/signalr"
import { AuthService } from '../auth/services/auth.service';
import { environment } from 'src/environments/environment';
import { Notification } from './interfaces/notification.interface';
import Swal from 'sweetalert2';
import { CookieService } from 'ngx-cookie-service';
import { UtilsService } from './utils.service';
import { Router } from '@angular/router';
import { EventsService } from './events.service';

@Injectable({
  providedIn: 'root'
})
export class SignalrService {

  private readonly baseUrl: string = environment.baseUrlApi;
  private hubConnection!: signalR.HubConnection;
  private menuHubConnection!: signalR.HubConnection;
  private cookieService = inject(CookieService);
  private utilsService = inject(UtilsService);
  private eventsService = inject(EventsService);
  private router = inject(Router);
  constructor() { }


  public startConnectionMenuHub = (onChangue: any) => {
    this.menuHubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${this.baseUrl.replace("/api", "")}/NotifyMenu`, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets
      }).build();

    this.menuHubConnection
      .start()
      .then(() => this.subscribeToMenuChangue(onChangue))
      .catch(err => console.log("Error connecting to NotifyMenu:" + err));
    this.menuHubConnection.onclose((error?: Error) => {
      this.eventsService.publish('signalr:close');
    })
  }

  public subscribeToSession(userId: number) {
    console.log(this.hubConnection.state);
    setTimeout(() => {
      if (this.hubConnection.state === signalR.HubConnectionState.Connecting) {
        this.subscribeToSession(userId);
      } else {
        let subdomain = window.location.host;//.split(".")[0];
        let identifier = userId;
        this.hubConnection.invoke("SuscribeToSession", identifier, subdomain);
      }
    }, 700);
  }


  public addMenuChangueListener = (onChangue: any) => {
    this.menuHubConnection.on('SendNotificationChangue', (idRol: number) => {
      onChangue("menu", { idRol });
    })
  }

  public addTipoCambioListener = (onChangue: any) => {
    this.menuHubConnection.on('SendTipoCambioChangue', () => {
      onChangue("tipoCambio", null);
    })
  }

  public addBuzonListener = (onChangue: any) => {
    this.menuHubConnection.on('SendBuzonChangue', () => {
      onChangue("buzon", null);
    })
  }

  public addMessageListener = (onChangue: any) => {
    this.menuHubConnection.on('SendMessageToUser', (mensaje: string, idUsuario: number) => {
      onChangue("mensaje", { mensaje, idUsuario });
    })
  }
  public addUsuarioChangueListener = (onChangue: any) => {
    this.menuHubConnection.on('SendUsuarioChangue', (idUsuario: number) => {
      onChangue("usuariocambio", { idUsuario });
    })
  }

  public addDownloadXmlSatChangueListener = (onChangue: any) => {
    this.menuHubConnection.on('SendDownloadXmlSatChangue', (mensaje: string, esImagen: boolean, idUsuario: number, esError: boolean, encontrados: number, porcentajeGuardado: number, porcentajeDescargado: number) => {
      onChangue("downloadXmlSat", { mensaje, esImagen, idUsuario, hub: this.menuHubConnection, esError, encontrados, porcentajeGuardado, porcentajeDescargado });
    });
  }




  public addNotificacionesListener = (onChangue: any) => {
    this.menuHubConnection.on('SendNotificacion', (mensaje: string, idUsuario: number) => {
      onChangue("notificacion", { mensaje, idUsuario });
    })
  }

  public addCargaEntregaListener = (onChangue: any) => {
    this.menuHubConnection.on('SendCargaEntregaChange', (empresaid:number, sucursalid:number) => {
      onChangue("cargaentrega", {empresaid, sucursalid});
    })
  }

  public subscribeToMenuChangue(onChangue: any) {
    setTimeout(() => {
      if (this.menuHubConnection.state === signalR.HubConnectionState.Connecting) {
        this.subscribeToMenuChangue(onChangue);
      } else {
        this.menuHubConnection.invoke("SusbscribeMenuChangues");
        setTimeout(() => {
          this.addMenuChangueListener(onChangue);
          this.addTipoCambioListener(onChangue);
          this.addBuzonListener(onChangue);
          this.addMessageListener(onChangue);
          this.addNotificacionesListener(onChangue);
          this.addUsuarioChangueListener(onChangue);
          this.addDownloadXmlSatChangueListener(onChangue);
          this.addCargaEntregaListener(onChangue);

        }
          , 1000);

      }
    }, 700);
  }

  public startConnection = () => {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${this.baseUrl.replace("/api", "")}/Notify`, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets
      }).build();

    this.hubConnection
      .start()
      .then(() => console.log("Connection started Notify"))
      .catch(err => console.log("Error:" + err));
  }

  public addSessionUserListener = () => {
    this.hubConnection.on('CloseSession', (notification: Notification) => {
      if (localStorage['iniciandoSesion'] !== "true") {
        this.closeSession(notification);
      }
    })
  }


  closeSession(notification: Notification) {
    this.eliminarDatosSesion();
    console.log(notification);
    Swal.fire({
      title: 'Sesión Expirada',
      text: notification.message,
      icon: 'warning',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Aceptar',
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(['/auth']);
      }
    });
  }

  public subscribeToRefreshSessions(empresaId: number) {
    this.hubConnection.invoke("SubscribeToRefreshSessions", empresaId);
  }

  public addRefreshListSessionsListener = () => {
    this.hubConnection.on('RefreshListSessions', (notification: Notification) => {
      this.eventsService.publish('home:refreshSessions', { isLoading: true });
    })
  }

  eliminarDatosSesion() {
    let user = this.utilsService.getUserLogged();
    if (!user.recordar) {
      user = {
        ambienteId: 0,
        numero: 0,
        clave: 0,
        nombreUsuario: "",
        nombreCompletoUsuario: "",
        password: "",
        recordar: false,
        sucursal: { numero: 0, clave: 0, nombre: "", rfc: "", alias: "" },
        empresa: { numero: 0, clave: 0, nombre: "", rfc: "", alias: "" }
      };
    }
    localStorage.setItem("userLogged", JSON.stringify(user));
    this.cookieService.deleteAll('/');
  }
}
