import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { ReportFilter, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ReportsService } from 'src/app/service/reports.service';

@Component({
  selector: 'app-ingresos-por-periodo',
  templateUrl: './ingresos-por-periodo.component.html',
  styleUrls: ['./ingresos-por-periodo.component.scss']
})
export class IngresosPorPeriodoComponent {
  @ViewChild('txtNombreReporte')
  public txtNombreReporte!: ElementRef;

  private frmBuilder = inject(FormBuilder);
  private reportsService = inject(ReportsService);

  activeButtons: ActiveButtons = {
    all: false,
    print: true,
  }

  ngOnInit(): void {
    let fecha = new Date();
    setTimeout(() => {
      const txt: any = this.txtNombreReporte;
      txt.tagInput.nativeElement.focus();
      this.contenidoSeleccionado = this.contenidos[0];
      this.ordenadoSeleccionado = this.ordenados[0];
    }, 300);
  }

  contenidos: ComboBoxEntity[] = [
    { Id: 1, Nombre: "A Totales", Clave: 1, ClaveNombre: "01 A Totales" },
    { Id: 2, Nombre: "A Detalle", Clave: 2, ClaveNombre: "02 A Detalle" },
  ];

  ordenados: ComboBoxEntity[] = [
    { Id: 1, Nombre: "Cobrador", Clave: 1, ClaveNombre: "01 Cobrador" },
    { Id: 2, Nombre: "Sucursal", Clave: 2, ClaveNombre: "02 Sucursal" },
    { Id: 3, Nombre: "Cuenta de CXC", Clave: 3, ClaveNombre: "03 Cuenta de CXC" },
  ];

  public frmIngresosPeriodo: FormGroup = this.frmBuilder.group({
    NombreReporte: ["INGRESOS POR PERÍODO", [Validators.required]],
    Fecha1: [new Date(), [Validators.required]],
    Fecha2: [new Date(), [Validators.required]],
    Num1: [3],
    Num2: [0]
  });

  public filtrosIngresosPeriodo: ReportFilter =
    {
      menuId: 'ingresosperiodo',
      ReportHeader: {
        Fecha1: new Date(),
        Fecha2: new Date(),
        NombreReporte: 'Ingresos Por Período'
      } as ReportHeader,
      Fecha1: new Date(),
      Fecha2: new Date(),
      NombreReporte: 'Ingresos Por Período',
      TituloVisor: 'Ingresos Por Período',
      NombreExcel: 'Ingresos Por Período.xlsx',
      FilterOptions: [
        { Campo: 'emp.Clave', Etiqueta: 'Emrpesa', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Cliente" },
        { Campo: 'suc.Clave', Etiqueta: 'Sucursal', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Sucursal" },
        { Campo: 'cuenta.Clave', Etiqueta: 'Cuenta', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "CuentaCxp" },
        { Campo: 'cliente.Clave', Etiqueta: 'Cliente', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Cliente" },
        { Campo: 'concepto.Clave', Etiqueta: 'Concepto', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "ConceptoCxc" }
      ]
    };

  contenidoSeleccionado?: ComboBoxEntity;
  get selectContenidoItem(): ComboBoxEntity | null {
    return this.contenidoSeleccionado!;
  }

  ordenadoSeleccionado?: ComboBoxEntity;
  get selectOrdenadoItem(): ComboBoxEntity | null {
    return this.ordenadoSeleccionado!;
  }

  selectedCombo(entity: any, field: string) {
    if (field === "Num1") {
      this.contenidoSeleccionado = entity;
    } else {
      this.ordenadoSeleccionado = entity;
    }
    this.frmIngresosPeriodo.get(field)?.setValue(entity?.Id);
  }

  changeselec(event: any) {
    this.filtrosIngresosPeriodo = event;
  }

  imprimir(event: any) {
    this.filtrosIngresosPeriodo.ReportHeader = this.frmIngresosPeriodo.value;
    this.reportsService.printReport(this.filtrosIngresosPeriodo, '/CuentasPorCobrar/ReporteIngresosPeriodo');
  }

}
