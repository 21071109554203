<div class="form-group row">
  <label *ngIf="withLabel" style="text-align: right;" class="col-sm-{{widthLabelColumns}} col-form-label ">{{label}}{{
    label.length>0?separator:''}}
    {{label.length==0?"&nbsp;":""}}
  </label>
  <div class="d-flex col-sm-{{widthTextColumns}}">
    <form [formGroup]="myForm" autocomplete="off">
      <div class="d-flex">
        <components-ui-text-box (keydown)="find($event,1)" (keydown.escape)="escape()" [helpLine]="helpLine" [isLevel]="true"
          [enabled]="enabled" [widthTextColumns]="12" (blur)="blur($event,1)" (keydown.f2)="openSearch()"
          [tabIndex]="tabIndex" [label]="''" formControlName="Level1" [widthLabelColumns]="0" [separator]="''"
          [textFilter]="this.textFilter" #txt1 (keydown.arrowup)="upInput($event)"
          (keydown.arrowdown)="downInput($event)" (keydown.enter)="enterInput($event)" />
        <components-ui-text-box (keydown)="find($event,2)" [helpLine]="helpLine" [isLevel]="true" [enabled]="enabled"
          [widthTextColumns]="12" (blur)="blur($event,2)" (keydown.f2)="openSearch()" [tabIndex]="tabIndex+1"
          [label]="''" formControlName="Level2" [widthLabelColumns]="0" [separator]="''" [textFilter]="this.textFilter"
          #txt2 />
        <components-ui-text-box (keydown)="find($event,3)" [helpLine]="helpLine" [isLevel]="true" [enabled]="enabled"
          [widthTextColumns]="12" (blur)="blur($event,3)" (keydown.f2)="openSearch()" [tabIndex]="tabIndex+2"
          [label]="''" formControlName="Level3" [widthLabelColumns]="0" [separator]="''" [textFilter]="this.textFilter"
          #txt3 />
        <components-ui-text-box (keydown)="find($event,4)" [helpLine]="helpLine" [isLevel]="true" [enabled]="enabled"
          [widthTextColumns]="12" (blur)="blur($event,4)" (keydown.f2)="openSearch()" [tabIndex]="tabIndex+3"
          [label]="''" formControlName="Level4" [widthLabelColumns]="0" [separator]="''" [textFilter]="this.textFilter"
          #txt4 />
        <components-ui-text-box (keydown)="find($event,5)" [helpLine]="helpLine" [isLevel]="true" [enabled]="enabled"
          [widthTextColumns]="12" (blur)="blur($event,5)" (keydown.f2)="openSearch()" [tabIndex]="tabIndex+4"
          [label]="''" formControlName="Level5" [widthLabelColumns]="0" [separator]="''" [textFilter]="this.textFilter"
          #txt5 />
      </div>
    </form>
  </div>


  <!-- <ng-scrollbar style="max-height:200px;background-color: #ffffff;" [style]="getListStyle"
    *ngIf="listaAbierta && source.length>0">
    <div class="todolist" style="max-height:200px;">
      <div (mousedown)="mousedown($event, item)" *ngFor="let item of source; let i = index;" class="todolist-item">
        <div class="todolist-input">
          <span>{{item.claveGeneral}}</span>
        </div>
        <label style="cursor:pointer;" class="todolist-label" [for]="'todolist'+ i">{{item.nombre}}</label>
      </div>
    </div>
  </ng-scrollbar> -->


  <app-panel-base *ngIf="source.length>0 && cords && listaAbierta" title="Búsqueda de {{type}}"
    [style]="'font-family: monospace; position: absolute; z-index:9;left:'+cords.left+'px;top:'+cords.top+'px;width:800px;box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;z-index: 99999'">
    <div class="panel-content">
      <ng-scrollbar style="height: 400px;">
        <table class="table table-sm table-bordered table-busqueda"
          style="border: 1px solid rgb(164, 164, 164);height: 38px;table-layout: fixed !important;">
          <thead>
            <tr>
              <th style="width:250px;" class="pt-0 pb-0">Clave</th>
              <th style="width:500px;" class="pt-0 pb-0">Nombre</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of source; let i = index;" style="cursor:pointer;" (click)="clickTablaBusqueda(item)"
              [id]="'row-search' + idUnico + '_'+i" [class]="selectedIndex == i ? 'bg-blue-800 pulse':''">
              <td [class]="selectedIndex == i ? 'text-white':''" [style]="'width:100px;'" class="text-truncate">
                {{item.claveGeneral}}</td>
              <td [class]="selectedIndex == i ? 'text-white':''" [style]="'width:100px;'" class="text-truncate">
                {{item.nombre}}</td>
            </tr>
          </tbody>
        </table>
        <!-- <div class="todolist" style="max-height:200px;">
          <div (mousedown)="mousedown($event, item)" *ngFor="let item of source; let i = index;" class="todolist-item">
            <div class="todolist-input">
              <span>{{item.claveGeneral}}</span>
            </div>
            <label style="cursor:pointer;" class="todolist-label" [for]="'todolist'+ i">{{item.nombre}}</label>
          </div>
        </div> -->
      </ng-scrollbar>
    </div>
  </app-panel-base>



  <ng-template let-modal let-c="close" #seleccionar>
    <app-seleccionar-tree [type]="type" #seleccionar />
  </ng-template>
</div>
