<app-container-base (onItemsComboSearched)="onItemsComboSearched($event)" (onItemSearched)="onItemSearched($event)"
  (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)" [entity]="myForm" entityName="UbicacionProductoTerminado"
  title="Ubicaciones" icon="fa fa-book-open-reader" subTitle="Administra las Ubicaciones de Productos Terminados.">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Información">
        <div class="panel-content">
          <component-ui-combo-box (onNewCatalog)="onNewCatalog($event)" (onChangueEntity)="onChangueEntity($event)"
            [isCatalog]="true" formControlName="Id" [isNewItem]="getIsNew" [setItems]="getItemsCombo"
            [label]="'Ubicación'" entityName="UbicacionProductoTerminado" [tabIndex]="1" [listProperty]="'ClaveNombre'"
            [widthTextColumns]="1" [widthLabelColumns]="2" />
          <components-ui-text-box [label]="'Nombre'" [required]="true" formControlName="Nombre" [tabIndex]="2"
            [widthTextColumns]="3" [widthLabelColumns]="2" #txtNombre [maxLength]="50" />

          <component-ui-combo-box label='Almacén' formControlName="Almacen" entityName="Almacen" [tabIndex]="3"
            [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="8" [required]="true"
            (onSelectedItem)="selectedCombo($event,'Almacen')" [zeroMask]="3" />

        </div>
      </app-panel-base>
    </form>
  </div>
</app-container-base>
