<app-container-base [hasCounter]="false" icon="fa fa-money-bill-1" [hasBar]="false" subTitle="Utilerias Complit">
  <div class="screen-content">
    <app-panel-base title="Descargas">
      <div class="panel-content">
        <div class="d-flex">
          <div class="card text-center w-350px me-10px">
            <div class="card-header fw-bold">
              Java JDK
            </div>
            <div class="card-body">
              <img src="../../../../assets/img/logo/java-logo.png" alt="" class="w-100px">
              <p class="card-text">
                Herramienta necesaria para el funcionamiento de la utilería QZ Tray para Complit.
                Se recomienda instalar la JDK 11 o superior para su correcto funcionamiento.
              </p>
              <a href="https://www.oracle.com/java/technologies/downloads/?er=221886" target="_blank"
                class="btn btn-sm bg-red text-white">
                <i class="fas fa-download"></i>
                Descargar
              </a>
            </div>
          </div>

          <div class="card text-center w-350px">
            <div class="card-header fw-bold">
              QZ Tray
            </div>
            <div class="card-body">
              <img src="../../../../assets/img/logo/qz-logo.png" alt="" class="w-100px logo-qz-utils">
              <p class="card-text">
                Utilería para impresión directa de documentos generados en Complit como Tickets de ventas, Reportes,
                Códigos de barras entre otros.</p>
              <a class="btn btn-sm bg-green text-white" (click)="descargarQZ()">
                <i class="fas fa-download"></i>
                Descargar
              </a>
            </div>
          </div>
        </div>
      </div>
    </app-panel-base>
  </div>
</app-container-base>
