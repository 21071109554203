<app-container-base [hasCounter]="false" icon="fa fa-money-bill-1" [hasBar]="true" [activeButtons]="activeButtons"
  (onClickBarButton)="clickBoton($event)" subTitle="Estado de cuenta contable">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <div class="row">
        <div class="col-md-8">
          <app-panel-base title="Filtros">
            <div class="panel-content">
              <div class="row">
              </div>
              <div class="row">
                <div class="col-md-8">
                  <app-level-text-box [label]="'Cuenta'" [widthTextColumns]="12" [tabIndex]="1"
                     #busquedaNiveles [allowRanges]="true" [type]="'CuentaContable'"
                    (onChangue)="onChangueCuenta($event)" [leftBusqueda]="350" (onLastLevel)="onLastLevelCuenta()"
                    [setClaveGeneral]="getClaveGeneral"  (onSelect)="onSelectCuenta($event)"
                    [idEmpresa]="this.usuarioLoggeado.empresa!.numero"  [captureMode]="true" style="margin-top:10px;"
                    (onEscape)="escapeCuenta()"
                    label="Cuenta" [widthLabelColumns]="4" [widthTextColumns]="8" #txtCuentaEdoCta />
                </div>
                <div class="col-md-4">
                  <div style="width:100%;text-align: left;">
                    <div *ngIf="this.nombreCuenta.length> 0" class=" badge bg-dark rounded-0 text-truncate fs-6">
                      {{this.nombreCuenta| uppercase}}
                    </div>
                  </div>
                </div>


              </div>
              <div class="row">
                <div class="col-md-4">
                  <component-ui-combo-box label='Año' [tabIndex]="3" [zeroMask]="3"
                    [selectManualItem]="selectAnioManualItem" [setItemsList]="this.listaAnios" [widthTextColumns]="4"
                    [widthLabelColumns]="8" (onSelectedItem)="selectedCombo($event,'anio')" [inputProperty]="'Nombre'"
                    [listProperty]="'Nombre'" #txtAnioEdoCta />
                </div>
                <div class="col-md-4">
                  <component-ui-check-box label='En moneda nacional' formControlName="esMXN" [tabIndex]="2"
                    [widthLabelColumns]="6" [widthTextColumns]="1" [tabIndex]="3"
                    (onClick)="onclicMonedaNacional($event)" />
                </div>
              </div>
              <div class="row">
                <div class="col-md-1">
                </div>
                <div class="col-md-8">
                  <div class="col-lg-4" style="text-align: right;">
                    <a (click)="buscar()" class="btn btn-blue me-5px mt-2"><i
                        class="fa {{(searching ?'fa-circle-notch fa-spin':'fa-magnifying-glass')}}"></i> Buscar </a>
                  </div>
                </div>
              </div>
            </div>
          </app-panel-base>
        </div>
      </div>
      <div class="screen-content">
        <div class="row">
          <div class="col-md-8">
            <app-panel-base title="Saldos">
              <div class="panel-content">
                <ul class="nav nav-tabs">
                  <li class="nav-item">
                    <a href="#default-tab-1" data-bs-toggle="tab" class="nav-link active">
                      <span class="d-sm-none">Tab 1</span>
                      <span class="d-sm-block d-none">Saldos</span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a href="#default-tab-2" data-bs-toggle="tab" class="nav-link">
                      <span class="d-sm-none">Tab 2</span>
                      <span class="d-sm-block d-none">Movimientos</span>
                    </a>
                  </li>
                </ul>
                <div class="tab-content panel rounded-0 p-3 m-0">
                  <!-- BEGIN tab-pane -->
                  <div class="tab-pane fade active show" id="default-tab-1">
                    <div class="panel-content">
                      <table class="table table-bordered table-sm">
                        <thead>
                          <tr class="header">
                            <th class="w-50px">Mes</th>
                            <th class="w-100px">Anterior</th>
                            <th class="w-100px">Cargos</th>
                            <th class="w-100px">Abonos</th>
                            <th class="w-100px">Actual</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of listaMeses" class="table-info">
                            <td>{{item.mes}}</td>
                            <td class="text-end">{{item.anterior |number:'1.2-6'}}</td>
                            <td class="text-end">{{item.cargos |number:'1.2-6'}}</td>
                            <td class="text-end">{{item.abonos |number:'1.2-6'}}</td>
                            <td class="text-end">{{item.actual |number:'1.2-6'}}</td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="totales" *ngIf="this.totalCargos != 0 || this.totalAbonos != 0">
                        <div style="display: flex;">
                          <h5 style="width:35%;" class="subtotales">Totales</h5>
                          <h5 style="width:24%;" class="subtotales">{{this.totalCargos| number:'1.2-6'}}</h5>
                          <h5 style="width:21%;" class="subtotales">{{this.totalAbonos| number:'1.2-6'}}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="default-tab-2">
                    <div class="row">
                      <div class="col-md-8">
                        <div class="card border-0" style="width: 760px;height: 350px;">
                          <div class="card-body">
                            <ul class="nav nav-pills" style="cursor: pointer;margin-top: -16px;">
                              <li (click)="changeMonth(1)" class="nav-item"> <a data-bs-toggle="tab"
                                  class="{{getIsMonthSelected(1)}}">
                                  <i class="fa {{(getisSearching(1) ?'fa-circle-notch fa-spin':'')}}"></i>
                                  Enero</a>
                              </li>
                              <li (click)="changeMonth(2)" class="nav-item"> <a data-bs-toggle="tab"
                                  class="{{getIsMonthSelected(2)}}">
                                  <i class="fa {{(getisSearching(2) ?'fa-circle-notch fa-spin':'')}}"></i>
                                  Febrero</a>
                              </li>
                              <li (click)="changeMonth(3)" class="nav-item"> <a data-bs-toggle="tab"
                                  class="{{getIsMonthSelected(3)}}">
                                  <i class="fa {{(getisSearching(3) ?'fa-circle-notch fa-spin':'')}}"></i>
                                  Marzo</a>
                              </li>
                              <li (click)="changeMonth(4)" class="nav-item"> <a data-bs-toggle="tab"
                                  class="{{getIsMonthSelected(4)}}">
                                  <i class="fa {{(getisSearching(4) ?'fa-circle-notch fa-spin':'')}}"></i>
                                  Abril</a>
                              </li>
                              <li (click)="changeMonth(5)" class="nav-item"> <a data-bs-toggle="tab"
                                  class="{{getIsMonthSelected(5)}}">
                                  <i class="fa {{(getisSearching(5) ?'fa-circle-notch fa-spin':'')}}"></i>
                                  Mayo</a>
                              </li>
                              <li (click)="changeMonth(6)" class="nav-item"> <a data-bs-toggle="tab"
                                  class="{{getIsMonthSelected(6)}}">
                                  <i class="fa {{(getisSearching(6) ?'fa-circle-notch fa-spin':'')}}"></i>
                                  Junio</a>
                              </li>
                              <li (click)="changeMonth(7)" class="nav-item"> <a data-bs-toggle="tab"
                                  class="{{getIsMonthSelected(7)}}">
                                  <i class="fa {{(getisSearching(7) ?'fa-circle-notch fa-spin':'')}}"></i>
                                  Julio</a>
                              </li>
                              <li (click)="changeMonth(8)" class="nav-item"> <a data-bs-toggle="tab"
                                  class="{{getIsMonthSelected(8)}}">
                                  <i class="fa {{(getisSearching(8) ?'fa-circle-notch fa-spin':'')}}"></i>
                                  Agosto</a>
                              </li>
                              <li (click)="changeMonth(9)" class="nav-item"> <a data-bs-toggle="tab"
                                  class="{{getIsMonthSelected(9)}}">
                                  <i class="fa {{(getisSearching(9) ?'fa-circle-notch fa-spin':'')}}"></i>
                                  Septiembre</a>
                              </li>
                              <li (click)="changeMonth(10)" class="nav-item"> <a data-bs-toggle="tab"
                                  class="{{getIsMonthSelected(10)}}">
                                  <i class="fa {{(getisSearching(10) ?'fa-circle-notch fa-spin':'')}}"></i>
                                  Octubre</a> </li>
                              <li (click)="changeMonth(11)" class="nav-item"> <a data-bs-toggle="tab"
                                  class="{{getIsMonthSelected(11)}}">
                                  <i class="fa {{(getisSearching(11) ?'fa-circle-notch fa-spin':'')}}"></i>
                                  Noviembre</a> </li>
                              <li (click)="changeMonth(12)" class="nav-item"> <a data-bs-toggle="tab"
                                  class="{{getIsMonthSelected(12)}}">
                                  <i class="fa {{(getisSearching(12) ?'fa-circle-notch fa-spin':'')}}"></i>
                                  Diciembre</a> </li>
                            </ul>
                            <table class="table table-bordered table-sm">
                              <thead>
                                <tr class="header">
                                  <th class="w-150px">Póliza</th>
                                  <th class="w-200px">Concepto</th>
                                  <th class="w-100px">Debe</th>
                                  <th class="w-100px">Haber</th>
                                </tr>
                              </thead>
                            </table>
                            <ng-scrollbar style="height: 250px;width: 1300px;">
                              <table class="table table-bordered table-sm">
                                <tbody>
                                  <tr *ngFor="let item of listaMovimientos" class="table-info">
                                    <td class="w-150px">{{item.poliza}}</td>
                                    <td class="text-start w-200px">{{item.concepto}}</td>
                                    <td class="text-end w-100px">{{item.debe|number:'1.2-6'}}</td>
                                    <td class="text-end w-100px">{{item.haber |number:'1.2-6'}}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </ng-scrollbar>
                            <div class="totales" *ngIf="this.totalCargos != 0 || this.totalAbonos != 0">
                              <div style="display: flex;">
                                <h5 style="width:60%;" class="subtotales">Totales</h5>
                                <h5 style="width:22%;" class="subtotales">{{this.totalCargosMes| number:'1.2-6'}}</h5>
                                <h5 style="width:18%;" class="subtotales">{{this.totalAbonosMes| number:'1.2-6'}}</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </app-panel-base>
          </div>
        </div>
      </div>
      <ng-template #reporteEdoCuenta let-modal let-c="close">
        <reporte-estado-de-cuenta-contable-page [idEmpresa]="this.empresa!.Id" [cuenta]="this.cuenta" />
      </ng-template>
    </form>
  </div>
</app-container-base>
