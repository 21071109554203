import { Component, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';
import { EventsService } from 'src/app/service/events.service';
import { ModalService } from 'src/app/service/modal.service';

@Component({
  selector: 'app-opcion-cancelacion-vale-page',
  templateUrl: './opcion-cancelacion-vale-page.component.html',
  styles: [
  ]
})

export class OpcionCancelacionValePageComponent {



  private eventsService = inject(EventsService);


  public myForm: FormGroup = this.fb.group({
    MotivoCancelacion: ['', Validators.required],
  })

  constructor(private fb: FormBuilder,
    private mService: ModalService) {
  }

  accept() {
    if (!this.myForm.value.MotivoCancelacion) {
      this.eventsService.publish('home:showAlert', {
        message: 'Indique el motivo de cancelación.',
        onConfirm: (data: AlertResponse) => { }
      });
      return;
    }
    this.mService.closeModal(this.myForm.value);
  }

  closeModal() {
    this.mService.closeModal(null);
  }

  ngOnInit(): void {
    const ent = {
      MotivoCancelacion: '',
    };
    this.myForm.reset(ent);
  }


}
