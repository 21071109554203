import { Component, ElementRef, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { LevelType, ReportFilter, ReportFilterOrderList, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { EventsService } from 'src/app/service/events.service';
import { ReportsService } from 'src/app/service/reports.service';
import { UtilsService } from 'src/app/service/utils.service';

@Component({
  templateUrl: './relaciones-analiticas-page.component.html',
  styles: [
  ]
})
export class RelacionesAnaliticasComponent {

  private utils = inject(UtilsService);
  private fb =  inject(FormBuilder)
  private reportsService =  inject(ReportsService);
  private eventsService = inject(EventsService);


  @ViewChild('txtNombreReporte')
  public txtNombreReporte!: ElementRef;

  menuId: string = "relacionesanaliticas";

  public ordenadoLista: ReportFilterOrderList[] = [{ Nombre: 'Empresa-Sucursal-Cuenta', Orden: 'empClave,sucClave' }]

  public filtrosReporte: ReportFilter =
    {
      menuId: this.menuId,
      EsContabilidad:true,
      ReportHeader: {} as ReportHeader,
      Desglose: 'a Detalle',
      DesgloseLista: [{ Nombre: 'a Detalle' },{Nombre: 'a Totales'}],
      OrdenadoLista: this.ordenadoLista,
      NombreReporte: '',
      TituloVisor: 'Relaciones analíticas',
      NombreExcel: 'Relaciones analíticas.xlsx',
      FilterOptions: [
        { Campo: 'emp.Clave', Etiqueta: 'Empresa', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Empresa" },
        { Campo: 'mpactual.ClaveCuentaNivel', Etiqueta: 'Cuenta contable', Tipo: TypeFilter.levels, TipoNiveles: LevelType.account },
        { Campo: 'mpactual.ClaveCentroCostoNivel', Etiqueta: 'Centros de Costos', Tipo: TypeFilter.levels, TipoNiveles: LevelType.costAccount },
      ]
    };


  public myForm: FormGroup = this.fb.group({
    Fecha1: new Date(),
    Fecha2: new Date(),
    NombreReporte: '',
    Opc1:false,
    Opc2:false,
    Opc3:false,
    Opc4:false
  });

  activeButtons: ActiveButtons = {
    all: false,
    print: true,
  }

  constructor() { }

  ngOnInit(): void {
    this.myForm.get('NombreReporte')?.setValue('RELACIONES ANALÍTICAS ' + this.rangoFechas);
    this.focus('txtNombreReporte');
  }

  get rangoFechas() {
    return this.utils.getEtiquetaRangoPorFechas(this.myForm.value.Fecha1, this.myForm.value.Fecha2, 2).toUpperCase();
  }

  onChangeFecha(event: any) {
    this.myForm.get('NombreReporte')?.setValue('RELACIONES ANALÍTICAS ' + this.rangoFechas);
  }

  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombreReporte;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }


  changeselec(event: any) {
    this.filtrosReporte = event;
  }

  clickBoton(event: string) {
      this.filtrosReporte.ReportHeader = this.myForm.value;
      this.reportsService.printReport(this.filtrosReporte, '/Contabilidad/ReporteRelacionesAnaliticas');
  }
}
