<app-container-base subTitle="Existencias de Paquetes/Lotes" (onClickBarButton)="clickBoton()" [isReport]="true"
  [activeButtons]="activeButtons" icon="fas fa-list-check" [hasCounter]="false">
  <div class="screen-content">
    <form [formGroup]="frmExistenciasPaquetesLotes" autocomplete="off">
      <app-panel-base title="Generales">
        <div class="panel-content">

          <div class="col-md-7">
            <components-ui-text-box helpLine="Nombre para el encabezado del reporte impreso." label='Nombre del Reporte'
              formControlName="NombreReporte" [tabIndex]="1" [widthLabelColumns]="4" [widthTextColumns]="8"
              [maxLength]="100" #txtNombreReporte />
          </div>


          <div class="col-md-7">
            <components-ui-date-box helpLine="Fecha límite para consulta de registros." label='Fecha al'
              formControlName="Fecha1" [tabIndex]="2" [widthLabelColumns]="4" [widthTextColumns]="8"
              [required]="true"></components-ui-date-box>
          </div>


          <div class="col-md-7">
            <component-ui-combo-box label='Detalle' [tabIndex]="3" [zeroMask]="2" [setItemsList]="this.detalles"
              [tabIndex]="3" [widthTextColumns]="4" [widthLabelColumns]="4"
              (onSelectedItem)="selectedCombo($event,'Tipo')" inputProperty="ClaveNombre" listProperty="ClaveNombre"
              [selectManualItem]="selectDetalleItem" formControlName="Num1"
              helpLine="Tipo del detalle para la impresión del reporte." />
          </div>

        </div>
      </app-panel-base>
      <app-panel-base title="Generales">
        <div class="panel-content">
          <component-ui-selection-filter [(FiltrosSeleccion)]="this.filtrosExistenciasPaquetesLotes" [tabIndex]="8"
            (FiltrosSeleccionChange)="changeselec($event)"></component-ui-selection-filter>
        </div>
      </app-panel-base>
    </form>
  </div>
</app-container-base>
