import { Component, ElementRef, ViewChild, inject } from '@angular/core';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { ActiveButtons, EventReturn } from 'src/app/component-ui/interfaces/container-base.interface';
import { environment } from 'src/environments/environment';
import { c_CodigoAgrupador } from '../../interfaces/tipopoliza.interface';
import { Moneda } from 'src/app/configuracion/interfaces/moneda.interface';
import { CuentaBancaria, c_Banco } from 'src/app/Finanzas/interfaces/c_banco.interface';
import { CuentaContable, TipoCuentaContable } from '../../interfaces/cuentacontable.interface';
import { Empresa } from 'src/app/configuracion/interfaces/empresa.interface';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { UtilsService } from 'src/app/service/utils.service';
import Swal from 'sweetalert2';
import { ContabilidadService } from '../../services/contabilidad.service';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { ReportsService } from 'src/app/service/reports.service';
import { ReportFilter, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { Result } from 'src/app/auth/interfaces';
import { EventsService } from 'src/app/service/events.service';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';
import { ModalService } from 'src/app/service/modal.service';
import { Rubro } from '../../interfaces/rubro.interface';
import { AppSettings } from 'src/app/home/services/app-settings.service';

@Component({
  selector: 'app-cuenta-contable-page',
  templateUrl: './cuenta-contable-page.component.html',
  styles: [
  ]
})
export class CuentaContablePageComponent {

  @ViewChild('modalRubro')
  public modalRubro!: ElementRef<HTMLElement>;
  public appSettings = inject(AppSettings);
  @ViewChild('txtNombre')
  public txtNombre!: ElementRef<TextBoxComponent>;
  private eventsService = inject(EventsService);
  info: any;
  file: any;
  longClave: number = 0;
  preguntarBalance: boolean = false;
  saving: boolean = false;
  private readonly baseUrl: string = environment.baseUrlApi;
  selectedTree: any = null;
  entityAfterSave: any = null;
  reload: boolean = false;
  reloadDelete: boolean = false;
  cargarCuentasBase: boolean = false;
  activeButtons: ActiveButtons = {
    new: false,
    delete: true,
    return: false,
    save: true,
    first: false,
    left: false,
    right: false,
    last: false,
    search: false,
    print: true,
  }
  public myForm: FormGroup = this.fb.group({
    id: [0],
    clave: [0],
    nombre: [''],
    abreviacion: [''],
    numeroNivel: [0],
    orden: [0],
    claveGeneral: [''],
    codigoAgrupdor: [{} as c_CodigoAgrupador],
    tipoCuenta: [{} as TipoCuentaContable],
    operacionAdicional: [null],
    moneda: [{} as Moneda],
    banco: [{} as c_Banco],
    cuentaBancaria: [''],
    deudora: [false],
    esActivoFijo: [false],
    acreedora: [false],
    aceptaNegativos: [false],
    excluirContabilidadElectronica: [false],
    padre: [{} as CuentaContable],
    empresa: [{} as Empresa],
    baja: [false],
    hijos: [{} as CuentaContable[]]
  });

  private reportsService = inject(ReportsService);

  constructor(private http: HttpClient,
    private fb: FormBuilder,
    private containerService: ContainerBaseService,
    private contaService: ContabilidadService,
    private ms: ModalService,
    private utileService: UtilsService) {
  }

  ngOnInit(): void {
    this.info = this.utileService.getUserLogged();
    this.myForm.reset({});
    this.mostrarBotonCargarCuentas();
  }

  mostrarBotonCargarCuentas() {
    this.containerService.getCounter("CuentaContable").subscribe({
      next: (result: number) => {
        this.cargarCuentasBase = result === 0;
      },
      error: err => console.log(err)
    });
  }

  selectedObjeto(entity: ComboBoxEntity, type: string) {
    this.myForm.controls[type].setValue(entity);
  }

  onLoad() {
    this.reload = false;
    this.entityAfterSave = null;
  }

  onDelete() {
    this.entityAfterSave = null;
    this.reloadDelete = true;
    setTimeout(() => {
      this.reloadDelete = false;
    }, 500);
  }

  get getReload(): boolean {
    return this.reload;
  }

  get getReloadDelete(): boolean {
    return this.reloadDelete;
  }

  get getEntityAfterSave(): CuentaContable {
    return this.entityAfterSave;
  }

  cambiarClave() {

  }

  addSubLevel() {
    let level = this.selectedTree.numeroNivel + 1;
    let idPadre = this.selectedTree.id;
    this.contaService.getLongLevel(this.info.empresa.numero, level).subscribe((long) => {
      this.longClave = long;
      this.contaService.getNextClave(this.info.empresa.numero, level, idPadre).subscribe((clave) => {
        this.containerService.getEmptyEntityNoJson("CuentaContable").subscribe((elem) => {
          elem.clave = clave;
          elem.numeroNivel = level;
          elem.padre = { id: idPadre };
          this.myForm.reset(elem);
          setTimeout(() => {
            this.focus("txtNombre")
          }, 150);
        })
      })
    });
  }

  addLevel() {
    let level = 1;
    let idPadre = 0;
    if (this.selectedTree) {
      level = this.selectedTree.numeroNivel;
    }

    this.contaService.getLongLevel(this.info.empresa.numero, level).subscribe((long) => {
      this.longClave = long;
      this.contaService.getNextClave(this.info.empresa.numero, level, idPadre).subscribe((clave) => {
        this.containerService.getEmptyEntityNoJson("CuentaContable").subscribe((elem) => {
          elem.clave = clave;
          elem.numeroNivel = 1;
          this.myForm.reset(elem);
          setTimeout(() => {
            this.focus("txtNombre")
          }, 150);
        })
      })
    })
  }

  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombre;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  get showAddFamily(): boolean {

    if (this.selectedTree) {
      if (this.selectedTree.padre) {
        return false;
      }
    }
    return true;
  }

  get showAddSubFam(): boolean {

    if (this.selectedTree) {
      if (this.selectedTree.numeroNivel <= 3) {
        return true;
      }
    }
    return false;
  }

  public filtrosCuentasContables: ReportFilter = {
    ReportHeader: {
      Fecha1: new Date(),
      Fecha2: new Date(),
      NombreReporte: 'Cuentas Contables'
    } as ReportHeader,
    NombreReporte: 'Cuentas Contables',
    TituloVisor: 'Cuentas Contables',
    NombreExcel: 'CuentasContables.xlsx',
  };
  imprimir(event: any) {
    if (event === "print") {
      this.reportsService.printReport(this.filtrosCuentasContables, '/Contabilidad/ReporteCuentasContables');
    }
  }

  onSelectedItem(item: CuentaContable) {
    this.selectedTree = { ...item };
    this.myForm.reset(item);
    setTimeout(() => {
      this.focus("txtNombre")
    }, 150);
  }

  onBeforeSave(param: EventReturn) {
    const ent = this.myForm.value;
    if (!ent) { param.callback(false); return; }
    if (ent.clave == 0) { param.callback(false); return; }
    if (ent.id == 0) { this.preguntarBalance = true; }
    param.callback(true);
  }

  onAfterSave(entity: any) {
    this.mostrarBotonCargarCuentas();
    this.myForm.reset(entity);
    this.selectedTree = null;
    this.entityAfterSave = entity;
    this.reload = true;
    if (entity.NumeroNivel == 1 && this.preguntarBalance) {
      setTimeout(() => {
        this.eventsService.publish('home:showAlert', {
          textAccept: "Si",
          textCancel: "No",
          message: `¿Desea agregar la cuenta "${entity.ClaveGeneral}" a la configuraciónde Balance en este momento?`, onConfirm: (data: AlertResponse) => {
            if (data.isAccept) {
              setTimeout(() => {
                this.abrirSelecionarRubro(entity.Id);
              }, 150);
            }
          }
        });
      }, 1000);
    }
    this.preguntarBalance = false;

  }

  abrirSelecionarRubro(idCuenta: number) {
    const b: any = this.modalRubro;
    this.ms.openModal(b, (e: Rubro) => {
      if (e) {
        this.contaService.agregarCuentaRubro(idCuenta, e.id).subscribe((result) => {
          if (result.success) {
            Swal.fire({ position: 'center', icon: 'success', title: 'Se agregó a balance correctamente', showConfirmButton: false, timer: 1000 });
          } else {
            this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
          }
        })
      }
    }, 'widthfamilia');
  }

  cargarCatalogoBase() {
    this.utileService.isLoad(true);
    this.contaService.cargarCatalogoBase().subscribe({
      next: (result: Result) => {
        this.utileService.isLoad(false);
        this.reload = true;
        this.cargarCuentasBase = false;
        Swal.fire({ position: 'center', icon: 'success', title: result.message, showConfirmButton: false, timer: 1000 });
      },
      error: (err: Error) => {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: err.message,
          showConfirmButton: false,
          timer: 5000,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Aceptar'
        });
        this.utileService.isLoad(false);
        console.error(err)
      }
    });
  }
}
