<div class="card text-center border-0" cdkDrag
style="width: 540px;box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;">
<div style="cursor: move;" cdkDragHandle class="card-header fw-bold">Cambiar Vendedor a Documento</div>
<app-panel-base title="Seleccione el Vendedor que quedará asignado a este documento">
  <div class="panel-content">
    <component-ui-combo-box [inputProperty]="'Nombre'" [tabIndex]="999999" [entityName]="'Usuario'"
      label="Vendedor" [widthTextColumns]="6" [widthLabelColumns]="4" [widthRightColumns]="0" #txtVendedor/>
  </div>
</app-panel-base>
<div class="card-footer fw-bold">
  <div style="text-align: right; margin-top: 5px;">
    <button style="margin-right: 5px;" (click)="aceptarCambiarVendedor()" class="btn btn-success"
      #txtAceptar>Aceptar</button>
    <button (click)="closeModal()" class="btn btn-danger" #txtCancelar>Cancelar</button>
  </div>
</div>
</div>
