import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { Result } from 'src/app/auth/interfaces';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { environment } from 'src/environments/environment';
import { AcumuladoSinAnticiposMensual, AnticipoDisponibleDTO, CfdiEstadoSAT, ExistenciaProducto, HistoricoVenta, RelacionResponse, Venta, VentaRelacionDTO } from '../interfaces/venta.interface';
import { FilterOptions, ReportFilter, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ClasificacionProducto, Producto } from 'src/app/Inventarios/interfaces/producto.interface';
import { FamiliaProducto } from 'src/app/Inventarios/interfaces/familia.interface';
import { AperturaCaja, Caja } from '../interfaces/caja.interface';
import { ConsultaProduccion, Diseno, OrdenManufactura } from 'src/app/Manufactura/interfaces/manufacura.interface';
import { ReportsService } from 'src/app/service/reports.service';
import { FacturaPendienteRep, ReciboElectronicoPago } from 'src/app/Inventarios/interfaces/rep.interface';
import { ConstanciaRetencion } from '../interfaces/retenciones.interface';
import { ConceptosCarga, EntregaNuestra } from '../interfaces/cargaentrega.interface';
import { PresupuestoVentaSucursalDTO, PresupuestoVentaVendedorDTO } from '../interfaces/presupuestos.interface';
import { OrdenCarga } from '../interfaces/ordencarga.interface';
import { EventsService } from 'src/app/service/events.service';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';
import * as moment from 'moment';
import { PrecioDTO } from '../interfaces/precio.interface';
import { TokenPrecio } from 'src/app/configuracion/interfaces/tokens.interface';
import { SolicitudCancelacionInternaDTO } from '../interfaces/solicitudcancelacioninterna.interface';
import { startOfDay } from 'date-fns';


@Injectable({ providedIn: 'root' })
export class CorteCajaService {

  constructor(private http: HttpClient, private reportsService: ReportsService) {

  }
  private readonly baseUrl: string = environment.baseUrlApi;
  private eventsService = inject(EventsService);

  ///fechaInicio: DD/MM/YYYY
  ///fechaFin: DD/MM/YYYY
  buscarCortesCaja(idSucursal: number, fechaInicio: string, fechaFin: string): Observable<Result> {
    const params = new HttpParams()
      .set("idSucursal", idSucursal)
      .set("fechaInicio", fechaInicio)
      .set("fechaFin", fechaFin);
    return this.http.get<Result>(`${this.baseUrl}/Ventas/BuscarCortes`, { params })
  }

  buscarCortePorId(idCorte: number): Observable<Result> {
    const params = new HttpParams()
      .set("idCorte", idCorte);
    return this.http.get<Result>(`${this.baseUrl}/Ventas/BuscarCortePorId`, { params })
  }

  desbloquearCorte(idCorte: number, observaciones: string): Observable<Result> {
    const params = new HttpParams()
      .set("idCorte", idCorte)
      .set("observaciones", observaciones);
    return this.http.get<Result>(`${this.baseUrl}/Ventas/DesbloquearCorte`, { params })
  }
}
