
import { AfterViewInit, Component, ElementRef, EventEmitter, inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FamiliaProducto } from 'src/app/Inventarios/interfaces/familia.interface';
import { ActivoFijo } from 'src/app/contabilidad/interfaces/activofijo.interface';
import { CentroCosto } from 'src/app/contabilidad/interfaces/centrocosto.interface';
import { CuentaContable } from 'src/app/contabilidad/interfaces/cuentacontable.interface';
import { Rubro, RubroER } from 'src/app/contabilidad/interfaces/rubro.interface';
import { UtilsService } from 'src/app/service/utils.service';
import { environment } from 'src/environments/environment';
import { LevelType } from '../../interfaces/selection-filter.interface';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppSettings } from 'src/app/home/services/app-settings.service';

@Component({
  selector: 'components-ui-tree-view',
  templateUrl: './tree-view.component.html',
  styleUrls: ['./tree-view.component.scss']
})

export class TreeViewComponent implements OnInit, AfterViewInit {


  @ViewChild('txtBuscar')
  public txtBuscar!: ElementRef<any>;

  @Input()
  public onlyLevelOne: boolean = false;
  @Input()
  public multiSelect: boolean = false;
  public appSettings = inject(AppSettings);
  source: any[] = [];
  info: any;
  selectedItem: any = null;
  private readonly baseUrl: string = environment.baseUrlApi;
  constructor(private http: HttpClient, private utileService: UtilsService) { }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.txtBuscar?.nativeElement?.focus();
    }, 250);
  }

  ngOnInit(): void {
    this.info = this.utileService.getUserLogged();
    this.getItemsByLevel(1, 0);
    this.onLoad.emit();
  }

  @Input()
  public type: string = "";
  @Output()
  public onSelectedItem: EventEmitter<any> = new EventEmitter();
  @Output()
  public onSelectedItemMultiple: EventEmitter<any> = new EventEmitter();
  @Output()
  public onEscape: EventEmitter<any> = new EventEmitter();
  @Output()
  public onLoad: EventEmitter<any> = new EventEmitter();
  @Input()
  set setIsNew(isnew: boolean) {
    if (isnew) {
      if (!this.selectedItem) {
        this.getItemsByLevel(1, 0);
      } else {
        this.getItemsByLevel(this.selectedItem.numeroNivel, 0, (l: any) => {
        });
      }
      this.onLoad.emit();
    }
  }

  @Input()
  set setIsDelete(isDelete: boolean) {
    if (isDelete) {
      this.selectedItem = null;
      this.getItemsByLevel(1, 0);
    }
  }

  @Input()
  set setEntityAfterSave(ent: any) {
    if (ent) {
      this.selectedItem = ent;
      this.setSelectedOnView(ent);
      this.onLoad.emit();
    }
  }

  getCaret(item: any): string {

    if (item.numeroHijos > 0) {
      if (!this.onlyLevelOne) {
        return `fas fa-${(item.isExpanded ? 'caret-down' : 'caret-right')}  fa-lg`
      }
    }

    return 'tree-empty'

    //return "";
  }

  setSelectedOnView(sel: any) {
    this.source.map((item, index) => {
      if (item.id == sel.id) {
        this.source[index] = { ...sel };
        this.source[index].isSelected = true;
        this.onSelectedItem.emit(this.source[index]);
      }
      if (this.source[index].hijos) {
        this.source[index].hijos.map((hijo: any, inHijo: number) => {
          if (hijo.id == sel.id) {
            this.source[index].hijos[inHijo] = { ...sel };
            this.source[index].hijos[inHijo].isSelected = true;
            this.onSelectedItem.emit(this.source[index].hijos[inHijo]);
          }
          if (this.source[index].hijos[inHijo].hijos) {
            this.source[index].hijos[inHijo].hijos.map((h3: any, i3Hijo: number) => {
              if (h3.id == sel.id) {
                this.source[index].hijos[inHijo].hijos[i3Hijo] = { ...sel };
                this.source[index].hijos[inHijo].hijos[i3Hijo].isSelected = true;
                this.onSelectedItem.emit(this.source[index].hijos[inHijo].hijos[i3Hijo]);
              }
            })
          }
        })
      }
    });
    this.source = [...this.source];
  }


  getText() {
    const text = this.txtBuscar?.nativeElement?.value;
    if (!text) return "";
    return text;
  }

  find() {
    const text = this.getText();
    if (!text) {
      this.getItemsByLevel(1, 0);
      return
    }

    const params = new HttpParams().set("idEmpresa", this.info.empresa.numero).set("nombre", text);
    switch (this.type) {
      case 'Rubro':
        this.http.get<Rubro[]>(`${this.baseUrl}/Contabilidad/ObtenerRubrosPorNombre`, { params }).subscribe((lista) => {
          this.source = lista;
        })
        break;
      case 'RubroER':
        this.http.get<RubroER[]>(`${this.baseUrl}/Contabilidad/ObtenerRubrosERPorNombre`, { params }).subscribe((lista) => {
          this.source = lista;
        })
        break;
      case 'ActivoFijo':
        this.http.get<ActivoFijo[]>(`${this.baseUrl}/Contabilidad/ObtenerActivosFijosPorNombre`, { params }).subscribe((lista) => {
          this.source = lista;
        })
        break;
      case 'CentroCosto':
        this.http.get<CentroCosto[]>(`${this.baseUrl}/Contabilidad/ObtenerCentrosCostosPorNombre`, { params }).subscribe((lista) => {
          this.source = lista;
        })
        break;
      case 'FamiliaProducto':
        //case LevelType.productFamily:
        this.http.get<FamiliaProducto[]>(`${this.baseUrl}/Inventarios/ObtenerFamiliasPorNombre`, { params }).subscribe((lista) => {
          this.source = lista;
        })
        break;

      case 'CuentaContable':
        this.http.get<CuentaContable[]>(`${this.baseUrl}/Contabilidad/ObtenerCuentasContablesPorNombre`, { params }).subscribe((lista) => {
          this.source = lista;
        })
        break;
    };

  }

  escape() {
    this.onEscape.emit();
  }

  addSelection(item: any, index: number) {
    this.source[index].seleccionado = !this.source[index].seleccionado;
    this.source = [...this.source];

    const seleccionados = this.source.filter(P => P.seleccionado);
    this.onSelectedItemMultiple.emit(seleccionados);
  }

  getItem(id: number, cb: any) {
    const params = new HttpParams().set("id", id);
    switch (this.type) {
      case 'Rubro':
        this.http.get<Rubro>(`${this.baseUrl}/Contabilidad/ObtenerRubroPorId`, { params }).subscribe((item) => {
          cb(item);
        })
        break;
      case 'RubroER':
        this.http.get<RubroER>(`${this.baseUrl}/Contabilidad/ObtenerRubroERPorId`, { params }).subscribe((item) => {
          cb(item);
        })
        break;
      case 'ActivoFijo':
        this.http.get<ActivoFijo>(`${this.baseUrl}/Contabilidad/ObtenerActivoFijoPorId`, { params }).subscribe((item) => {
          cb(item);
        })
        break;
      case 'CentroCosto':
        this.http.get<CentroCosto>(`${this.baseUrl}/Contabilidad/ObtenerCentroCostoPorId`, { params }).subscribe((item) => {
          cb(item);
        })
        break;
      case 'FamiliaProducto':
        //case LevelType.productFamily:
        this.http.get<FamiliaProducto>(`${this.baseUrl}/Inventarios/ObtenerFamiliaPorId`, { params }).subscribe((item) => {
          cb(item);
        })
        break;

      case 'CuentaContable':
        this.http.get<CuentaContable>(`${this.baseUrl}/Contabilidad/ObtenerCuentaContablePorId`, { params }).subscribe((item) => {
          cb(item);
        })
        break;
    };

  }

  onSelect(item: any) {
    this.getItem(item.id, (ib: any) => {
      //TODO:
      //item = ib;
      this.selectedItem = item;


      if (this.type == 'CuentaContable') {
        if (item.codigoAgrupdor) {
          item.codigoAgrupdor.Id = item.codigoAgrupdor.id;
          item.codigoAgrupdor.Clave = item.codigoAgrupdor.clave;
          item.codigoAgrupdor.Nombre = item.codigoAgrupdor.nombre;

          ib.codigoAgrupdor = item.codigoAgrupdor;
        }
        if (item.tipoCuenta) {
          item.tipoCuenta.Id = item.tipoCuenta.id;
          item.tipoCuenta.Clave = item.tipoCuenta.clave;
          item.tipoCuenta.Nombre = item.tipoCuenta.nombre;
          ib.tipoCuenta = item.tipoCuenta;
        }
        if (item.moneda) {
          item.moneda.Id = item.moneda.id;
          item.moneda.Clave = item.moneda.clave;
          item.moneda.Nombre = item.moneda.nombre;
          ib.moneda = item.moneda;
        }
        if (item.banco) {
          item.banco.Id = item.banco.id;
          item.banco.Clave = item.banco.clave;
          item.banco.Nombre = item.banco.nombre;
          ib.banco = item.banco;
        }
      }
      this.onSelectedItem.emit(ib);
      this.source.map((i) => {
        i.isSelected = false;
        if (i.hijos) {
          i.hijos.map((hijo: any) => {
            hijo.isSelected = false;
            if (hijo.hijos) {
              hijo.hijos.map((hijo3: any) => {
                hijo3.isSelected = false;
              });
            }
          })
        }
      })
      item.isExpanded = !item.isExpanded;
      item.isSelected = !item.isSelected;
      this.source = [...this.source];
      if (this.onlyLevelOne) { return; }
      this.getItemsByLevel(0, item.id, (l: any[]) => { item.hijos = l; });

    });

  }

  onSelect2(item: any, padre: any) {
    this.getItem(item.id, (ib: any) => {
      //TODO:
      //item = ib;
      this.selectedItem = { ...item, padre: padre };
      this.onSelectedItem.emit({ ...ib, padre: padre });
      this.source.map((i) => { i.isSelected = false; })
      padre.isSelected = false;
      padre.hijos.map((i: any) => {
        i.isSelected = i.id == item.id;
      })
      item.isExpanded = !item.isExpanded;
      this.source = [...this.source];
      this.getItemsByLevel(0, item.id, (l: any[]) => { item.hijos = l; });
    });

  }

  getItemsByLevel(level: number, idPadre: number, cb: any = null) {
    const params = new HttpParams().set("idEmpresa", this.info.empresa.numero).set("nivel", level).set("idPadre", idPadre);
    switch (this.type) {

      case 'Rubro':
        this.http.get<Rubro[]>(`${this.baseUrl}/Contabilidad/ObtenerRubrosPorNivel`, { params }).subscribe((lista) => {
          if (level == 1) {
            this.source = lista;
          }
          cb && cb(lista);
        })
        break;
      case 'RubroER':
        this.http.get<RubroER[]>(`${this.baseUrl}/Contabilidad/ObtenerRubrosERPorNivel`, { params }).subscribe((lista) => {
          if (level == 1) {
            this.source = lista;
          }
          cb && cb(lista);
        })
        break;

      case 'ActivoFijo':
        this.http.get<ActivoFijo[]>(`${this.baseUrl}/Contabilidad/ObtenerActivosFijosPorNivel`, { params }).subscribe((lista) => {
          if (level == 1) {
            this.source = lista;
          }
          cb && cb(lista);
        })
        break;

      case 'CentroCosto':
        this.http.get<CentroCosto[]>(`${this.baseUrl}/Contabilidad/ObtenerCentrosCostosPorNivel`, { params }).subscribe((lista) => {
          if (level == 1) {
            this.source = lista;
          }
          cb && cb(lista);
        })
        break;

      case 'FamiliaProducto':
        //case LevelType.productFamily:
        this.http.get<FamiliaProducto[]>(`${this.baseUrl}/Inventarios/ObtenerFamiliasPorNivel`, { params }).subscribe((lista) => {
          if (level == 1) {
            this.source = lista;
          }
          cb && cb(lista);
        })
        break;

      case 'CuentaContable':
        this.http.get<CuentaContable[]>(`${this.baseUrl}/Contabilidad/ObtenerCuentasContablesPorNivel`, { params }).subscribe((lista) => {
          if (level == 1) {
            this.source = lista;
          }
          cb && cb(lista);
        })
        break;
    };

  }

}
