import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, inject, OnInit, ViewChild, AfterContentChecked } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { isThisYear } from 'date-fns';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { LevelType, ReportFilter, ReportFilterOrderList, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ReportsService } from 'src/app/service/reports.service';
import { UtilsService } from 'src/app/service/utils.service';

@Component({
  selector: 'mas-vendidos',
  templateUrl: './mas-vendidos.component.html',
})
export class MasVendidosComponent implements OnInit, AfterViewInit {


  @ViewChild('txtNombreReporte')
  public txtNombreReporte!: ElementRef;

  private _utils = inject(UtilsService);

  menuId: string = "masvendidos";
  filtrosDiarioVentasPredefinidos: ReportFilter = {} as ReportFilter;

  public ordenadoLista: ReportFilterOrderList[] = [
    { Nombre: 'Cantidad (Menor a mayor)', Orden: '[famClave],[venclave],[cliclave],[sucursalclave],Vendido asc', Id: 0 },
    { Nombre: 'Cantidad (Mayor a menor)', Orden: '[famClave],[venclave],[cliclave],[sucursalclave],vendido desc', Id: 1 },
    { Nombre: 'Importe (Menor a mayor)', Orden: '[famClave],[venclave],[cliclave],[sucursalclave],importe asc', Id: 2 },
    { Nombre: 'Importe (Mayor a menor)', Orden: '[famClave],[venclave],[cliclave],[sucursalclave],importe desc', Id: 3 }
  ]

  public luegoPorLista: ReportFilterOrderList[] = [
    { Nombre: 'Ninguno', Orden: '', Id: 0 },
    { Nombre: 'Sucursal', Orden: 'sucursalclave', Id: 1, Agrupador: "suc.Clave" },
    { Nombre: 'Cliente', Orden: 'cliclave', Id: 2, Agrupador: "cli.Clave" },
    { Nombre: 'Vendedor', Orden: 'venclave', Id: 3, Agrupador: "ven.Clave" },
    { Nombre: 'Familia', Orden: 'famClave', Id: 4, Agrupador: "fam.Clave" }
  ]


  public filtrosMasVendidos: ReportFilter =
    {
      menuId: this.menuId,
      ReportHeader: {} as ReportHeader,
      OrdenadoLista: this.ordenadoLista,
      LuegoPorLista: this.luegoPorLista,
      Fecha1: new Date(),
      Fecha2: new Date(),
      NombreReporte: '',
      TituloVisor: 'Reporte de Productos Mas Vendidos',
      NombreExcel: 'Reporte de Productos Mas Vendidos.xlsx',
      FilterOptions: [
        { Campo: 'emp.Clave', Etiqueta: 'Empresa', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Empresa" },
        { Campo: 'suc.Clave', Etiqueta: 'Sucursal', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Sucursal" },
        { Campo: 'cli.Clave', Etiqueta: 'Cliente', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Cliente" },
        { Campo: 'cli.RFC', Etiqueta: 'RFC', Tipo: TypeFilter.text },
        { Campo: 'ven.Clave', Etiqueta: 'Vendedor Factura', Tipo: TypeFilter.number, Entidad: "Usuario", Filtro: "EsVendedor = true" },
        { Campo: 'pro.Clave', Etiqueta: 'Producto', Tipo: TypeFilter.text, Entidad: "Producto" },
        { Campo: 'fam.ClaveGeneral', Etiqueta: 'Familias', Tipo: TypeFilter.levels, TipoNiveles: LevelType.productFamily, HelpLine: "Familias a de las que se buscarán los registros." }
      ]
    };


  public myForm: FormGroup = this.fb.group({
    Fecha1: new Date(),
    Fecha2: new Date(),
    NombreReporte: '',
    Num1: 0
  });

  activeButtons: ActiveButtons = {
    all: false,
    print: true,
  }

  constructor(private fb: FormBuilder, private reportsService: ReportsService) { }
  ngAfterViewInit(): void {

    let noProd = this.myForm.get("Num1")?.value;
    if (noProd == 0) {
      this.myForm.get("Num1")?.setValue(20);
    }

  }

  ngOnInit(): void {
    let fecha = new Date();
    this.myForm.get('Fecha1')?.setValue(fecha);
    let valor: number = this.myForm.get("Num1")?.value;
    if (valor == 0) { valor = 20 };
    this.myForm.get('NombreReporte')?.setValue(`TOP ${valor} MÁS VENDIDOS ` + this.rangoFechas);
    this.focus('txtNombreReporte');
  }

  blurNumeroProductos(event: any) {
    let valor: any = event.after != "" ? event.after : "0";
    valor = parseInt(valor);
    if (valor == 0) {
      valor = 20;
      this.myForm.get("Num1")?.setValue(20);
    }
    this.myForm.get('NombreReporte')?.setValue(`TOP ${valor} MÁS VENDIDOS ` + this.rangoFechas);


  }


  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombreReporte;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  onChangeFecha(value: any) {
    const valor: number = this.myForm.get("Num1")?.value;
    this.myForm.get('NombreReporte')?.setValue(`TOP ${valor} MÁS VENDIDOS ` + this.rangoFechas);
  }

  get rangoFechas(): string {
    return this._utils.getEtiquetaRangoPorFechas(this.myForm.value.Fecha1, this.myForm.value.Fecha2, 2).toUpperCase();
  }


  changeselec(event: any) {
    this.filtrosMasVendidos = event;
  }

  clickBoton(event: string) {
    this.filtrosMasVendidos.ReportHeader = this.myForm.value;
    this.reportsService.printReport(this.filtrosMasVendidos, '/Ventas/ReportesProductosMasVendidos');
  }

  filtrosCargados(event: any) {
    if (event) {
      if (event.Num1) {
        if (event.Num1 == 0) {
          this.myForm.get("Num1")?.setValue(20);
        }
      }
    }
  }


}
