<div class="card text-center border-0" cdkDrag style="width: 1000px;box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;">
  <div style="cursor: move;" cdkDragHandle class="card-header fw-bold">Configuración de Diseño Final</div>
  <div style="height: 600px;">
    <app-container-base subTitle="" [containerInnerHeight]="0" [esPopup]="true" [hasHeader]="false" [hasBar]="false"
      [hasCounter]="false">
      <div class="screen-content">

        <ul [class]="'nav nav-tabs m-2 ' + (appSettings.appDarkMode ? 'bg-light' : '')  " role="tablist">
          <li class="nav-item" role="presentation">
            <a href="#diseno-default-tab-1" data-bs-toggle="tab" class="nav-link text-dark active" aria-selected="true"
              role="tab">
              <span class="d-sm-block d-none">
                Seleccion de Diseños
              </span>
            </a>
          </li>
          <li class="nav-item" role="presentation">
            <a href="#diseno-default-tab-2" data-bs-toggle="tab" class="nav-link text-dark" aria-selected="false"
              role="tab">
              <span class="d-sm-block d-none">Resumén de Componentes ({{getSeleccionados}} Diseños)</span>
            </a>
          </li>
        </ul>

        <div class="tab-content panel rounded-0 me-2 ms-2 mt-0">
          <div id="diseno-default-tab-1" class="tab-pane fade active show" role="tabpanel">
            <div class="widget-input-container">
              <div class="widget-input-box">
                <input (keydown)="keydown($event)" type="text" class="form-control"
                  placeholder="Teclee y presione enter para buscar" #txtBuscar>
              </div>
            </div>
            <ng-scrollbar style="height: 400px;">
              <div [class]="'todolist ' + (appSettings.appDarkMode ? 'bg-light' : ' ')">
                <div style="font-weight:bold;" *ngFor="let item of disenos; let i = index" class="todolist-item">
                  <div class="todolist-input">
                    <div class="form-check">
                      <input [id]="'todolist1' + i" (click)="seleccionar(i, $event)"
                        class="form-check-input" type="checkbox" [checked]="item.Seleccionado" value="">
                    </div>
                  </div>
                  <label class="todolist-label text-start text-dark" [for]="'todolist1' + i">{{item.Clave}} -
                    {{item.Nombre}}</label>
                </div>
              </div>
            </ng-scrollbar>
          </div>
          <div id="diseno-default-tab-2" class="tab-pane fade" role="tabpanel">
            <ng-scrollbar style="height: 400px;">
              <div class="timeline">
                <div *ngFor="let rem of getSeleccionadosLista" class="timeline-item">
                  <div class="timeline-time">
                    <span class="date">{{rem.Nombre | uppercase}}</span>

                  </div>
                  <div class="timeline-icon">
                    <a href="javascript:;">&nbsp;</a>
                  </div>
                  <div class="timeline-content">
                    <div>
                      <div class="mb-1">
                        <table class="blueTable" style="width:690px;">
                          <thead style="position: sticky;top:0;">
                            <tr>
                              <th style="width: 100px;">Clave</th>
                              <th style="width: 200px;">Producto</th>
                              <th style="width: 80px;text-align:right;">Cantidad</th>
                              <th style="width: 150px;text-align:center;">Almacén</th>
                              <th style="width: 80px;text-align:center;">Unidad</th>
                              <th style="width: 80px;text-align:right;">Existencia</th>
                            </tr>
                          </thead>
                          <tbody class="bg-light">
                            <tr [style]="'padding-top:5px;'" *ngFor="let item of rem.Detalle; let index = index;">
                              <td class="text-dark" style="width:100px">
                                {{item.Producto!.Clave}}
                              </td>
                              <td class="text-dark" style="width:200px">
                                {{item.Producto!.Nombre}}
                              </td>
                              <td class="text-dark" style="width:80px;text-align: right;">
                                {{item.Cantidad | number:'1.2-6'}}
                              </td>
                              <td style="text-align:center;">{{item.Almacen?.Nombre}}</td>
                              <td style="text-align:center;">{{item.Producto?.Unidad?.Nombre}}</td>
                              <td class="text-end" style="padding-right: 5px;">{{item.Existencia | number: '1.2-6'}}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-scrollbar>
          </div>
        </div>
        <div style="text-align: right; margin-top: 5px;">
          <button style="margin-right: 5px;" (click)="accept()" class="btn btn-success">Aceptar</button>
          <button (click)="closeModal()" class="btn btn-danger"> Cancelar</button>
        </div>

      </div>
    </app-container-base>
  </div>
</div>
