<div class="card text-center border-0" cdkDrag style="width: 1100px;box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;">
  <div style="cursor: move;" cdkDragHandle class="card-header fw-bold">Listado de Ordenes de Producción</div>
  <div class="card-body bg-light">
    <div class="mb-2">
      <ng-scrollbar style="height: 300px; width:1100px; border-bottom: 1px solid #AAAAAA;">
        <table class="blueTable" style="width:1000x;">
          <thead style="position: sticky;top:0;">
            <tr>
              <th style="width: 55px;"></th>
              <th style="width: 55px;"></th>
              <th style="width: 80px; text-align: center;">SerieFolio</th>
              <th style="width: 80px; text-align: center;">Emisión</th>
              <th style="width: 350px;">Producto</th>
              <th style="width: 70px;text-align: right;">Cantidad</th>
              <th style="width: 80px;text-align: center;">Cancelación</th>
            </tr>
          </thead>
          <tbody class="bg-light">
            <tr [style]="'padding-top:5px;'" *ngFor="let item of items; let index = index;">
              <td style="text-align: center;width: 55px;">
                <i (click)="cancelar(item)" *ngIf="!item.fechaCancelacion && !soloConsulta" class="fa fa-ban fa-lg text-red"
                  style="font-weight: bold; cursor: pointer;"></i>
              </td>
              <td (click)="imprimir(item)" style="text-align: center;width: 55px;">
                <i class="fa fa-print fa-lg" style="font-weight: bold; cursor: pointer;"></i>
              </td>
              <td class="text-dark" style="width:80px;text-align: center;">
                {{item.serie}}{{item.folio}}
              </td>
              <td class="text-dark" style="width:80px;text-align: center;">
                {{getDate(item.fechaEmision) }}
              </td>
              <td class="text-dark" style="width:350px;">
                {{item.producto.clave}} => {{item.descripcion}}
              </td>
              <td class="text-dark" style="width:70px;text-align: right;">
                {{item.cantidad|number:'1.2-6' }}
              </td>
              <td class="text-dark" style="width:80px;text-align: center;">
                {{getDate(item.fechaCancelacion)}}
              </td>
            </tr>
          </tbody>
        </table>
      </ng-scrollbar>
      <ng-template #modalCancelacion let-modal let-c="close">
        <app-popup-cancelar-orden-page />
      </ng-template>
    </div>
  </div>
  <div class="card-footer fw-bold">
    <div style="text-align: right; margin-top: 5px;">
      <button (click)="closeModal()" class="btn btn-danger"> Cancelar</button>
    </div>
  </div>
</div>
