<div class="card text-center border-0" cdkDrag style="box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;">
  <div class="card-header fw-bold" style="cursor: move;" cdkDragHandle>
    <span *ngIf="!esPlantilla">
      Búsqueda de Pólizas [{{getMontName}} - {{anio}}]
    </span>
    {{esPlantilla ? 'Plantillas de póliza' : ''}}
  </div>
  <div class="card-body" style="min-height: 320px; max-height: 320px;">
    <div *ngIf="!esPlantilla" class="card border-0"
      style="width: 780px;height: 30px;margin-bottom: 5px;border-radius: 0px; border:1px dashed black;">
      <div class="card-body">
        <ul class="nav nav-pills" style="cursor: pointer;margin-top: -16px;">
          <li (click)="changueMont(1)" class="nav-item"> <a data-bs-toggle="tab"
              class="{{getIsMonthSelected(1)}}">ENE</a>
          </li>
          <li (click)="changueMont(2)" class="nav-item"> <a data-bs-toggle="tab"
              class="{{getIsMonthSelected(2)}}">FEB</a>
          </li>
          <li (click)="changueMont(3)" class="nav-item"> <a data-bs-toggle="tab"
              class="{{getIsMonthSelected(3)}}">MAR</a>
          </li>
          <li (click)="changueMont(4)" class="nav-item"> <a data-bs-toggle="tab"
              class="{{getIsMonthSelected(4)}}">ABR</a>
          </li>
          <li (click)="changueMont(5)" class="nav-item"> <a data-bs-toggle="tab"
              class="{{getIsMonthSelected(5)}}">MAY</a>
          </li>
          <li (click)="changueMont(6)" class="nav-item"> <a data-bs-toggle="tab"
              class="{{getIsMonthSelected(6)}}">JUN</a>
          </li>
          <li (click)="changueMont(7)" class="nav-item"> <a data-bs-toggle="tab"
              class="{{getIsMonthSelected(7)}}">JUL</a>
          </li>
          <li (click)="changueMont(8)" class="nav-item"> <a data-bs-toggle="tab"
              class="{{getIsMonthSelected(8)}}">AGO</a>
          </li>
          <li (click)="changueMont(9)" class="nav-item"> <a data-bs-toggle="tab"
              class="{{getIsMonthSelected(9)}}">SEP</a>
          </li>
          <li (click)="changueMont(10)" class="nav-item"> <a data-bs-toggle="tab"
              class="{{getIsMonthSelected(10)}}">OCT</a> </li>
          <li (click)="changueMont(11)" class="nav-item"> <a data-bs-toggle="tab"
              class="{{getIsMonthSelected(11)}}">NOV</a> </li>
          <li (click)="changueMont(12)" class="nav-item"> <a data-bs-toggle="tab"
              class="{{getIsMonthSelected(12)}}">DIC</a> </li>
        </ul>
      </div>
    </div>
    <input (keydown.enter)="enterSearch()" placeholder="Escriba y presione enter para buscar" type="text"
      class="form-control" #txtBuscar>
    <hr>

    <ng-scrollbar style="height: 150px;border-bottom: 1px solid #AAAAAA;">
      <table class="blueTable">
        <thead style="position: sticky;top:0;">
          <tr>
            <th style="width: 50px;"></th>
            <th style="width: 100px;">Póliza</th>
            <th style="width: 100px;">Fecha</th>
            <th style="width: 100px;">Concepto</th>
            <th style="width: 100px;">Total</th>
            <th style="width: 100px;">Usuario</th>
          </tr>
        </thead>
        <tbody  class="bg-light">
          <tr *ngFor="let item of listaPolizas">
            <td class="text-center"><i (click)="seleccionar(item)" style="cursor: pointer;"
                class="fas fa-eye fa-lg"></i>
            </td>
            <td class="text-truncate text-center">{{item.numeroPoliza}}</td>
            <td class="text-truncate text-center"> {{ getDate(item.fecha) }}</td>
            <td class="text-truncate">{{item.concepto}}</td>
            <td class="text-truncate text-end pe-2"> {{ item.importe|number:'1.2'}}</td>
            <td>{{item.usuarioNombre}}</td>
          </tr>
        </tbody>
      </table>
    </ng-scrollbar>
    <span style="font-weight: bold;">Total Pólizas: {{listaPolizas.length | number}}</span>
  </div>
  <div class="card-footer fw-bold">
    <div style="text-align: right; margin-top: 5px;">
      <button (click)="closeModal()" class="btn btn-danger">Cancelar</button>
    </div>
  </div>
</div>
