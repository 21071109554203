import { Component, ElementRef, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { CuentaCxp, ItemEstadoCuentaCXP } from '../../interfaces/cuentacxp.interface';
import { CuentasPorPagarService } from '../../services/cxp.service';
import { GuiColumnAlign } from '@generic-ui/ngx-grid';
import { ModalService } from 'src/app/service/modal.service';
import { Proveedor } from '../../../compras/interfaces/proveedores.interface';
import { ReportHeader } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { SearchConfiguration } from 'src/app/service/interfaces/data-search.interface';
import { UserLogged } from 'src/app/auth/interfaces';
import { UtilsService } from 'src/app/service/utils.service';
import Swal from 'sweetalert2';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';

type NewType = Proveedor;

@Component({
  templateUrl: './estado-cuenta-cxp-page.component.html',
  styles: [
  ]
})
export class EstadoCuentaCxpPageComponent {

  @ViewChild('content')
  public ctrlBusqueda!: ElementRef<HTMLElement>;

  @ViewChild('cuenta')
  public ctrlCuenta!: ElementRef<HTMLElement>;

  @ViewChild('gridEdoCta')
  public gridEdoCta!: ElementRef<HTMLElement>;

  @ViewChild('reporteEdoCuenta')
  public reporteEdoCuenta!: ElementRef<HTMLElement>;


  private ms = inject(ModalService);
  private cxpService = inject(CuentasPorPagarService);
  private utilsService = inject(UtilsService);
  private fb = inject(FormBuilder);
  private containerBaseService = inject(ContainerBaseService)

  listaAnios: any[] = [];
  anioSeleccionado?: ComboBoxEntity;

  public GuiColumnAlign = GuiColumnAlign;

  loading: boolean = false;
  proveedorSeleccionado: any = { Id: 0 };
  nombreEmpresa: string = "";
  nombreProveedor: string = "";
  usuarioLoggeado!: UserLogged;
  listaMeses: ItemEstadoCuentaCXP[] = []
  searchConfigurationProveedor!: SearchConfiguration;

  public myForm: FormGroup = this.fb.group({
    anio: 0,
    cuenta: [{} as CuentaCxp],
    proveedorClave: 0,
    proveedorId: 0,
    reporteHeader: {} as ReportHeader,
    proveedor: [{} as Proveedor]
  });

  activeButtons: ActiveButtons = {
    all: false,
    print: true,
  }

  constructor() {
  }

  selectedCombo(entity: ComboBoxEntity, field: string) {
    this.myForm.controls[field].setValue(entity);
  }

  get selectAnioManualItem(): ComboBoxEntity | null {
    return this.anioSeleccionado!;
  }

  get getPropertys(): string {
    if (this.searchConfigurationProveedor) {
      let props = "";
      this.searchConfigurationProveedor.propertys.forEach((prop) => {
        props += `${prop.name}|${prop.type},`
      })

      if (props.length > 0) {
        props = props.substring(0, props.length - 1);
        return props;
      }
    }
    return "";
  }

  get getFilter(): any {
    if (this.searchConfigurationProveedor) {
      return this.searchConfigurationProveedor.filter;
    }
    return "";
  }

  get getColumns(): any {
    if (this.searchConfigurationProveedor) {
      return this.searchConfigurationProveedor.columns;
    }
    return [];
  }


  ngOnInit(): void {

    this.usuarioLoggeado = this.utilsService.getUserLogged();
    this.nombreEmpresa = this.usuarioLoggeado.empresa!.nombre;
    // this.formPagosCxC.controls["Empresa"].setValue({ Id: this.usuarioLoggeado.empresa?.numero });
    this.searchConfigurationProveedor = this.ms.GetSearchConfiguration("Cliente", `Empresa.Id = ${this.usuarioLoggeado.empresa!.numero}`);
    this.focus('cuenta');

    this.cxpService.obtenerListaDeAniosconMovimientosDeCXP().subscribe(result => {
      if (result) {
        this.loading = false;
        if (result.length > 0) {
          result.forEach((element: number) => {
            this.listaAnios.push({ Id: element, Nombre: element.toString() as string, Clave: element });
          });
        } else {
          const anio: number = new Date().getUTCFullYear();
          this.listaAnios.push({ Id: anio, Nombre: anio.toString(), Clave: anio })
        }
        this.anioSeleccionado = this.listaAnios[0];
      }
    })
  }

  focus(field: string) {
    setTimeout(() => {
      let txt: any
      if (field == 'cuenta') {
        txt = this.ctrlCuenta;
      } else if (field == 'grid') {
        txt = this.gridEdoCta;
      }
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  elementoSeleccionado(elemento: any) {
    this.myForm.get('proveedorClave')?.setValue(elemento);
    this.buscarProveedorPorClave();
  }


  buscarProveedor() {
    if (this.myForm.value.cuenta == null) {
      Swal.fire({ icon: 'info', text: 'Debe seleccionar una cuenta de cxp.' });
      return;
    }

    if (this.myForm.value.anio.Id == 0) {
      Swal.fire({ icon: 'info', text: 'Debe seleccionar una año para consultar el estado de cuenta.' });
      return;
    }

    const b: any = this.ctrlBusqueda;
    this.ms.openModal(b, (proveedor: any) => {
      if (proveedor) {
        this.loading = true;
        this.proveedorSeleccionado = proveedor;
        this.nombreProveedor = `${proveedor.Clave} ${proveedor.Nombre}`;
        this.getEstadoDeCuenta(proveedor.Id, this.myForm.get('cuenta')?.value.Id, this.anioSeleccionado!.Id);
        this.myForm.controls['proveedorClave'].setValue(proveedor.Clave);
        this.myForm.controls['proveedorId'].setValue(proveedor.Id);
        this.myForm.controls['proveedor'].setValue(proveedor);
      }
    }, 'lg')
  }

  getEstadoDeCuenta(proveedor: number, cuenta: number, anio: number) {
    this.cxpService.getEstadoDeCuenta(proveedor, cuenta, this.myForm.value.anio.Id).subscribe(result => {
      this.listaMeses = result;
      this.focus('grid');
    })
  }

  buscarProveedorPorClave() {
    const provId: any = this.myForm.get('proveedorClave')?.value;
    if (provId) {
      this.containerBaseService.getEntityByClave("Proveedor", provId).subscribe(proveedor => {
        this.proveedorSeleccionado = proveedor;
        this.getEstadoDeCuenta(proveedor.Id, this.myForm.get('cuenta')?.value.Id, this.anioSeleccionado!.Id);
        this.myForm.controls['proveedorClave'].setValue(proveedor.Clave);
        this.myForm.controls['proveedorId'].setValue(proveedor.Id);
        this.myForm.controls['proveedor'].setValue(proveedor);
      });
    }
  }

  clickBoton(event: string) {
    let provId: number = 0;
    if (this.proveedorSeleccionado) {
      provId = this.proveedorSeleccionado.Id;
    }
    if (provId == 0) {
      Swal.fire({ icon: 'info', text: 'Debe seleccionar un proveedor.' });
      return;
    }
    const b: any = this.reporteEdoCuenta;
    this.ms.openModal(b, (e: any) => {
      if (e != null && e != undefined) {
        this.myForm.controls["reportHeader"].setValue(e);
      }
    }, 'md')
  }

}
