<app-container-base (onItemsComboSearched)="onItemsComboSearched($event)" (onItemSearched)="onItemSearched($event)"
  (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)" [entity]="myForm" entityName="Rol"
  title="Puestos" icon="fa fa-building" subTitle="Administra la configuración de roles..." [printManual]="true"
  (onClickBarButton)="clickBarButton($event)">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Información">
        <div class="panel-content">
          <div class="form-group row">
            <div class="col-md-4">
              <component-ui-combo-box (onNewCatalog)="onNewCatalog($event)" (onChangueEntity)="onChangueEntity($event)"
                [isCatalog]="true" formControlName="Id" [isNewItem]="getIsNew" [setItems]="getItemsCombo"
                [label]="'Rol'" entityName="Rol" [tabIndex]="1" [listProperty]="'ClaveNombre'" [widthTextColumns]="3"
                [widthLabelColumns]="6" />
            </div>
            <div class="col-md-4">
              <component-ui-check-box label='Baja' [widthLabelColumns]="2" formControlName="Baja" [widthTextColumns]="4"
                [tabIndex]="2" />
            </div>
          </div>
          <components-ui-text-box label='Nombre' [required]="true" formControlName="Nombre" [tabIndex]="3"
            [widthTextColumns]="3" [widthLabelColumns]="2" #txtNombre [maxLength]="50" />

          <components-ui-text-box label='Documentos Ventas' formControlName="ComprobantesVentasPermisos"
            [enableIcon]="true" [Entidad]="'DocumentoVenta'" [tabIndex]="4" [widthTextColumns]="3"
            [widthLabelColumns]="2" [maxLength]="500" />

          <components-ui-text-box label='Documentos Compras' formControlName="ComprobantesVentasCompras" [tabIndex]="5"
            [enableIcon]="true" [Entidad]="'DocumentoCompra'" [widthTextColumns]="3" [widthLabelColumns]="2"
            [maxLength]="500" />

          <components-ui-text-box label='Documentos Inventario' formControlName="ComprobantesInventarios" [tabIndex]="6"
            [enableIcon]="true" [Entidad]="'TipoDocumentoInventario'" [widthTextColumns]="3" [widthLabelColumns]="2"
            [maxLength]="500" />

          <components-ui-text-box label='Series Ventas' formControlName="SeriesVentas" [enableIcon]="true"
            [Entidad]="'SerieVenta'" [tabIndex]="7" [widthTextColumns]="3" [widthLabelColumns]="2" [maxLength]="500" />

          <components-ui-text-box label='Series Compras' formControlName="SeriesCompras" [enableIcon]="true"
            [Entidad]="'SerieCompra'" [tabIndex]="8" [widthTextColumns]="3" [widthLabelColumns]="2" [maxLength]="500" />

          <components-ui-text-box label='Series Inventarios' formControlName="SeriesInventarios" [enableIcon]="true"
            [Entidad]="'SerieInventario'" [tabIndex]="9" [widthTextColumns]="3" [widthLabelColumns]="2" [maxLength]="500" />

          <component-ui-check-box label='Es gerente supervisor' [widthLabelColumns]="2"
            formControlName="EsGerenteSupervisor" [widthTextColumns]="4" [tabIndex]="10" />
        </div>
      </app-panel-base>

      <app-panel-base title="Permisos asignados">
        <div class="panel-content">

          <div class="card-header h6 mb-1 bg-none p-2 text-orange">
            <i class="fa fa-money-bill fa-lg fa-fw me-1"></i>
            Ventas
          </div>

          <component-ui-check-box label='Reimprimir documentos' [widthLabelColumns]="3"
            formControlName="ReimprimirComprobantesVentas" [widthTextColumns]="4" [tabIndex]="11" />

          <component-ui-check-box label='Bloquear folios' [widthLabelColumns]="3" formControlName="BloquearFolio"
            [widthTextColumns]="4" [tabIndex]="12" />

          <component-ui-check-box label='Desbloquear cortes' [widthLabelColumns]="3" formControlName="DesbloquearCortes"
            [widthTextColumns]="4" [tabIndex]="13" />

          <component-ui-check-box label='Permitir Cancelar' [widthLabelColumns]="3"
            formControlName="PermitirCancelarVentas" [widthTextColumns]="4" [tabIndex]="14" />

          <component-ui-check-box label='Cambiar Vendedor' [widthLabelColumns]="3" formControlName="CambiarVendedor"
            [widthTextColumns]="4" [tabIndex]="15" />

          <component-ui-check-box label='Cambiar Entrega' [widthLabelColumns]="3" formControlName="CambiarEntrega"
            [widthTextColumns]="4" [tabIndex]="16" />

          <component-ui-check-box label='Cambiar Clase Venta' [widthLabelColumns]="3"
            formControlName="CambiarCuentaVenta" [widthTextColumns]="4" [tabIndex]="17" />

          <div class="card-header h6 mb-1 bg-none p-2 text-orange">
            <i class="fas fa-bus-simple fa-lg fa-fw me-1"></i>
            Entregas a domicilio
          </div>
          <div class="col-md-3">
            <component-ui-check-box label='Puede Cancelar Salidas por Ventas' [widthLabelColumns]="8"
              formControlName="PuedeCancelarSalidaPorVenta" [widthTextColumns]="4" [tabIndex]="18"
              helpLine="Habilite esta opción si permitirá cancelar Salidas por Venta en el Módulo de Entregas a Domicilio" />
          </div>

          <div class="card-header h6 mb-1 bg-none p-2 text-orange">
            <i class="fa fa-user-clock fa-lg fa-fw me-1"></i>
            Cuentas Por Cobrar
          </div>
          <div class="form-group row">
            <div class="col-md-3">
              <component-ui-check-box label='No activar opciones crédito' [widthLabelColumns]="8"
                formControlName="NoActivarOpcionesCredito" [widthTextColumns]="4" [tabIndex]="19" />
            </div>
          </div>

          <div class="card-header h6 mb-1 bg-none p-2 text-orange">
            <i class="fa fa-truck-ramp-box fa-lg fa-fw me-1"></i>
            Inventarios
          </div>
          <div class="form-group row">
            <div class="col-md-3">
              <component-ui-check-box label='No ver costos' [widthLabelColumns]="8" formControlName="NoVerCostos"
                [widthTextColumns]="4" [tabIndex]="20" />
            </div>
          </div>

          <div class="card-header h6 mb-1 bg-none p-2 text-orange">
            <i class="fa fa-scale-unbalanced-flip fa-lg fa-fw me-1"></i>
            Contabilidad
          </div>
          <div class="form-group row">
            <div class="col-md-3">
              <component-ui-check-box label='Reemplazar pólizas' [widthLabelColumns]="8"
                formControlName="ReemplazarPolizas" [widthTextColumns]="4" [tabIndex]="21" />
            </div>
            <div class="col-md-3">
              <component-ui-check-box label='No aplica fecha proceso' [widthLabelColumns]="7"
                formControlName="NoAplicarFechaProceso" [widthTextColumns]="4" [tabIndex]="22" />
            </div>
          </div>

          <div class="card-header h6 mb-1 bg-none p-2 text-orange">
            <i class="fa fa-chart-pie fa-lg fa-fw me-1"></i>
            Tableros
          </div>


          <component-ui-check-box label='Ver Tablero General' [widthLabelColumns]="2" formControlName="VerTableroVentas"
            [widthTextColumns]="4" [tabIndex]="23" />
          <component-ui-check-box label='Ver Tablero de PowerBI' [widthLabelColumns]="2"
            formControlName="VerTableroPowerBI" [widthTextColumns]="4" [tabIndex]="24" />

          <components-ui-text-box label='URL' [enabled]="myForm.value.VerTableroPowerBI" formControlName="UrlPowerBI"
            [tabIndex]="25" [widthTextColumns]="10" [widthLabelColumns]="2" [maxLength]="1000" />
          <component-ui-check-box label='Ver Tablero de Gastos' [widthLabelColumns]="2"
            formControlName="VerTableroGastos" [widthTextColumns]="4" [tabIndex]="26" />

          <component-ui-check-box label='Ver Tablero de Mis Ventas' [widthLabelColumns]="2"
            formControlName="VerTableroMisVentas" [widthTextColumns]="4" [tabIndex]="27" />

          <div class="card-header h6 mb-1 bg-none p-2 text-orange">
            <i class="fa fa-chart-pie fa-lg fa-fw me-1"></i>
            Contabilidad
          </div>

          <component-ui-check-box label='Ver Tablero Estado Resultados' [widthLabelColumns]="2"
            formControlName="VerEstadoResultados" [widthTextColumns]="4" [tabIndex]="28" />

          <component-ui-check-box label='Ver Tablero Balance' [widthLabelColumns]="2" formControlName="VerBalance"
            [widthTextColumns]="4" [tabIndex]="29" />

          <div class="card-header h6 mb-1 bg-none p-2 text-orange">
            <i class="fa fa-chart-pie fa-lg fa-fw me-1"></i>
            Chat
          </div>
          <component-ui-check-box label='Ver Chat' [widthLabelColumns]="2" formControlName="VerChat"
            [widthTextColumns]="4" [tabIndex]="30" />

          <component-ui-check-box label='Puede envíar mensaje' [enabled]="myForm.value.VerChat" [widthLabelColumns]="2"
            formControlName="EnviarMensajesChat" [widthTextColumns]="4" [tabIndex]="31" />
        </div>
      </app-panel-base>
      <app-panel-base title="Permisos al menú de COMPLIT">
        <div class="panel-content">
          <div class="btn-group">
            <div class="dropdown">
              <button class="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                aria-expanded="false">
                Todos
                <i class="fa fa-caret-down"></i>
              </button>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" (click)="todos(true)">Opción del menú</a></li>
                <li><a class="dropdown-item" (click)="todosEliminar(true)">Acción Eliminar</a></li>
                <li><a class="dropdown-item" (click)="todosGuardar(true)">Acción Guardar</a></li>
              </ul>
            </div>

            <div class="dropdown">
              <button class="btn btn-primary dropdown-toggle ms-5px" type="button" data-bs-toggle="dropdown"
                aria-expanded="false">
                Ninguno
                <i class="fa fa-caret-down"></i>
              </button>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" (click)="todos(false)">Opción del menú</a></li>
                <li><a class="dropdown-item" (click)="todosEliminar(false)">Acción Eliminar</a></li>
                <li><a class="dropdown-item" (click)="todosGuardar(false)">Acción Guardar</a></li>
              </ul>
            </div>
          </div>

          <div class="tree-container-rol bg-white">
            <ng-scrollbar style="height: 450px;width: 100%;">
              <div *ngFor="let item of menus; let i = index" style="padding-bottom: 5px;">
                <i (click)="onSelect(item)" [class]="getCaret(item)"
                  style="cursor: pointer;margin-left: 4px;width:10px;color: #348fe2;"></i>
                <input (click)="seleccionar(item)" [checked]="item.Seleccionado" class="form-check-input"
                  type="checkbox" style="margin-left: 4px;" />
                <i *ngIf="item.Icono" style="margin-left: 5px; width:18px;text-align: center;"
                  class="fas fa-{{item.Icono}} fa-lg"></i>
                <a *ngIf="item.Img"><img style="width: 17px;margin-left: 6px;" src="{{item.Img}}"></a>
                <span class="text-dark tree-item" (click)="onSelect(item)"
                  style="margin-left: 5px;padding-top: 3px;cursor: pointer;">{{item.Nombre}}</span>

                <div *ngIf="item.IsExpanded" class="animate__animated animate__fadeIn" style="padding-left: 50px;">
                  <div *ngFor="let item2 of item.SubMenus;">
                    <i (click)="onSelect(item2)" [class]="getCaret(item2, item2.SubMenus!.length==0)"
                      style="cursor: pointer;margin-left: 4px;width:10px;color: #348fe2;"></i>
                    <input (click)="seleccionar(item2)" [checked]="item2.Seleccionado" class="form-check-input mb-1"
                      type="checkbox" style="margin-left: 4px;" />
                    <span class="text-dark tree-item" (click)="onSelect(item2)"
                      style="margin-left: 5px;padding-top: 3px;cursor: pointer;width:350px;">{{item2.Nombre}}</span>
                    <i *ngIf="item2.SubMenus!.length==0"
                      class="fas fa-lg fa-fw fa-trash {{item2.Eliminar ? 'text-red-600': ''}}"
                      (click)="activarAccion(item2, 0)" style="margin-left:4px;cursor: pointer;"></i>
                    <i *ngIf="item2.SubMenus!.length==0 && showIcon(item2)"
                      class="fas fa-lg fa-fw fa-floppy-disk {{item2.Guardar ? 'text-blue-600': ''}}"
                      (click)="activarAccion(item2, 1)" style="margin-left:4px;cursor: pointer;"></i>
                    <div *ngIf="item2.IsExpanded" class="animate__animated animate__fadeIn" style="padding-left: 50px;">
                      <div *ngFor="let item3 of item2.SubMenus;">
                        <i *ngIf="item3.SubMenus!.length>0"
                          style="cursor: pointer;margin-left: 4px;width:10px;color: #348fe2;"></i>
                        <input (click)="seleccionar(item3)" [checked]="item3.Seleccionado" class="form-check-input mb-1"
                          type="checkbox" style="margin-left: 4px;" />
                        <span class="text-dark tree-item"
                          style="margin-left: 5px;padding-top: 3px;cursor: pointer;width: 350px;">{{item3.Nombre}}</span>
                        <i *ngIf="item3.SubMenus!.length==0"
                          class="fas fa-lg fa-fw fa-trash {{item3.Eliminar}} {{item3.Eliminar ? 'text-red-600': ''}}"
                          (click)="activarAccion(item3, 0)" style="margin-left:4px;cursor: pointer;"></i>
                        <i *ngIf="item3.SubMenus!.length==0 && showIcon(item3)"
                          class="fas fa-lg fa-fw fa-floppy-disk {{item3.Guardar ? 'text-blue-600': ''}}"
                          (click)="activarAccion(item3, 1)" style="margin-left:4px;cursor: pointer;"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-scrollbar>
          </div>
        </div>
      </app-panel-base>
    </form>
  </div>
</app-container-base>
