import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from '../components/components.module';
import { ComponentUiModule } from '../component-ui/component-ui.module';
import { NgbTimepickerModule, NgbTooltip, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { DateBoxComponent } from '../component-ui/components/date-box/date-box.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { ClaseVentaPageComponent } from './pages/clase-venta-page/clase-venta-page.component';
import { TiposClientesPageComponent } from './pages/tipos-clientes-page/tipos-clientes-page.component';
import { ClasesClientesPageComponent } from './pages/clases-clientes-page/clases-clientes-page.component';
import { FormasPagoPageComponent } from './pages/formas-pago-page/formas-pago-page.component';
import { CondicionesPagosPageComponent } from './pages/condiciones-pagos-page/condiciones-pagos-page.component';
import { ClientesPageComponent } from './pages/clientes-page/clientes-page.component';
import { DocumentosVentasPageComponent } from './pages/documentos-ventas-page/documentos-ventas-page.component';
import { SeriesVentasPageComponent } from './pages/series-ventas-page/series-ventas-page.component';
import { FacturacionPageComponent } from './pages/facturacion-page/facturacion-page.component';
import { GuiGridModule } from '@generic-ui/ngx-grid';
import { DiarioVentasPageComponent } from './pages/reports/diario-ventas-page/diario-ventas-page.component';
import { FormaPagoPageComponent } from './pages/forma-pago-page/forma-pago-page.component';
import { OpcionCancelacionPageComponent } from './pages/opcion-cancelacion-page/opcion-cancelacion-page.component';
import { CfdiRelacionadoPageComponent } from './pages/cfdi-relacionado-page/cfdi-relacionado-page.component';
import { ComentarioGlobalPageComponent } from './pages/comentario-global-page/comentario-global-page.component';
import { NgxEditorModule } from 'ngx-editor';
import { FacturaGlobalPageComponent } from './pages/factura-global-page/factura-global-page.component';
import { CopiarComprobantesPageComponent } from './pages/copiar-comprobantes-page/copiar-comprobantes-page.component';
import { CargaListaPrecioComponent } from './pages/carga-lista-precio/carga-lista-precio.component';
import { ListaPrecioComponent } from './pages/lista-precio/lista-precio.component';
import { ConversionesPageComponent } from './pages/conversiones-page/conversiones-page.component';
import { VerificadorVentasPageComponent } from './pages/reports/verificador-ventas/verificador-ventas-page.component';
import { AnticiposDisponiblesPageComponent } from './pages/anticipos-disponibles-page/anticipos-disponibles-page.component';
import { AnticiposFacturadosPageComponent } from './pages/reports/anticipos-facturados-page/anticipos-facturados-page.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { CuentaBancariaClientePageComponent } from './pages/cuenta-bancaria-cliente-page/cuenta-bancaria-cliente-page.component';
import { ChoferesPageComponent } from './pages/choferes-page/choferes-page.component';
import { UnidadesPageComponent } from './pages/unidades-page/unidades-page.component';
import { CajaPageComponent } from './pages/caja-page/caja-page.component';
import { AperturaCajaPageComponent } from './pages/apertura-caja-page/apertura-caja-page.component';
import { ImpresionListaPreciosPageComponent } from './pages/reports/impresion-lista-precios-page/impresion-lista-precios-page.component';
import { CartaPortePageComponent } from './pages/carta-porte-page/carta-porte-page.component';
import { CtrlEdicionUbicacionComponent } from './pages/ctrl-edicion-ubicacion/ctrl-edicion-ubicacion.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CtrlEdicionTipoFiguraComponent } from './pages/ctrl-edicion-tipo-figura/ctrl-edicion-tipo-figura.component';
import { CtrlEdicionMercanciaComponent } from './pages/ctrl-edicion-mercancia/ctrl-edicion-mercancia.component';
import { ConfigVentasPageComponent } from './pages/config-ventas-page/config-ventas-page.component';
import { TipoServicioUnidadPageComponent } from './pages/tipo-servicio-unidad-page/tipo-servicio-unidad-page.component';
import { CatalogoClientesPageComponent } from './pages/reports/catalogo-clientes/catalogo-clientes-page.component';
import { DireccionEntregaPageComponent } from './pages/direccion-entrega-page/direccion-entrega-page.component';
import { HistoricoRemisionesPageComponent } from './pages/historico-remisiones-page/historico-remisiones-page.component';
import { SaldoFacturaResguardoPageComponent } from './pages/saldo-factura-resguardo-page/saldo-factura-resguardo-page.component';
import { PresupuestosVendedoresPageComponent } from './pages/presupuestos-vendedores-page/presupuestos-vendedores-page.component';
import { PresupuestosSucursalesPageComponent } from './pages/presupuestos-sucursales-page/presupuestos-sucursales-page.component';
import { TipoDeCambioPageComponent } from './pages/tipo-de-cambio-page/tipo-de-cambio-page.component';
import { CtrlEdicionCopiarPresupuestosComponent } from './pages/ctrl-edicion-copiar-presupuestos/ctrl-edicion-copiar-presupuestos.component';
import { InventarioModule } from '../Inventarios/inventario.module';
import { CtrlHistoricoClientePageComponent } from './pages/ctrl-historico-cliente-page/ctrl-historico-cliente-page.component';
import { SeriesRepPageComponent } from './pages/series-rep-page/series-rep-page.component';
import { ReciboElecPagoPageComponent } from './pages/recibo-elec-pago-page/recibo-elec-pago-page.component';
import { VenatanaRepPageComponent } from './pages/venatana-rep-page/venatana-rep-page.component';
import { CargaEntregaPageComponent } from './pages/carga-entrega-page/carga-entrega-page.component';
import { ConfigCargaEntregaPageComponent } from './pages/config-carga-entrega-page/config-carga-entrega-page.component';
import { OrdenesCargaPageComponent } from './pages/ordenes-carga-page/ordenes-carga-page.component';
import { CtrlEdicionCargaEntregaComponent } from './pages/ctrl-edicion-carga-entrega/ctrl-edicion-carga-entrega.component';
import { ComplementoCartaPortePageComponent } from './pages/complemento-carta-porte-page/complemento-carta-porte-page.component';
import { ConsignadoPageComponent } from './pages/consignado-page/consignado-page.component';
import { AltaConsignadoPageComponent } from './pages/alta-consignado-page/alta-consignado-page.component';
import { PlantillasCartaPortePageComponent } from './pages/plantillas-carta-porte-page/plantillas-carta-porte-page.component';
import { NeumaticosPageComponent } from './pages/neumaticos-page/neumaticos-page.component';
import { UbicacionNeumaticoPageComponent } from './pages/ubicacion-neumatico-page/ubicacion-neumatico-page.component';
import { DiarioRepsPageComponent } from './pages/reports/diario-reps-page/diario-reps-page.component';
import { CtrlEdicionCargaEntregaRegresarProductosComponent } from './pages/ctrl-edicion-carga-entrega-regresarProductos-page/ctrl-edicion-carga-entrega-regresarProductos-page.component';
import { VerificadorFacturasEntregadasADomicilioComponent } from './pages/reports/verificador-facturas-entregadas-a-domicilio/verificador-facturas-entregadas-a-domicilio.component';
import { FormulacionPageComponent } from './pages/formulacion-page/formulacion-page.component';
import { DatosNotaCreditoPageComponent } from './pages/datos-nota-credito-page/datos-nota-credito-page.component';
import { TipoGastoUnidadesComponent } from './pages/tipo-gasto-unidades/tipo-gasto-unidades.component';
import { MotivoReImpresionPageComponent } from './pages/motivo-re-impresion-page/motivo-re-impresion-page.component';
import { DatosTarjetaPageComponent } from './pages/datos-tarjeta-page/datos-tarjeta-page.component';
import { DatosTransferenciaPageComponent } from './pages/datos-transferencia-page/datos-transferencia-page.component';
import { DatosChequePageComponent } from './pages/datos-cheque-page/datos-cheque-page.component';
import { CorteCajaPageComponent } from './pages/corte-caja-page/corte-caja-page.component';
import { ConteoEfectivoComponent } from './pages/conteo-efectivo/conteo-efectivo.component';
import { DirectivesModule } from '../directives/directives.module';
import { TiposRetirosComponent } from './pages/tipos-retiros/tipos-retiros.component';
import { RetirosCajaComponent } from './pages/retiros-caja/retiros-caja.component';
import { FinanzasModule } from '../Finanzas/fianzas.module';
import { TerminalesComponent } from './pages/terminales/terminales.component';
import { DatosVentaAfectaPageComponent } from './pages/datos-venta-afecta-page/datos-venta-afecta-page.component';
import { ClientesPorVendedorComponent } from './pages/clientes-por-vendedor/clientes-por-vendedor.component';
import { VentaRelacionesPageComponent } from './pages/venta-relaciones-page/venta-relaciones-page.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { AgregarProductoListaPageComponent } from './pages/agregar-producto-lista-page/agregar-producto-lista-page.component';
import { CancelacionDocumentosVentasPageComponent } from './pages/cancelacion-documentos-ventas-page/cancelacion-documentos-ventas-page.component';
import { OpcionCancelacionSalidaporventaComponent } from './pages/opcion-cancelacion-salidaporventa/opcion-cancelacion-salidaporventa.component';
import { VerificadorTonalejeCargaEntregaComponent } from './pages/verificador-tonaleje-carga-entrega/verificador-tonaleje-carga-entrega.component';
import { PredefinidosClientesPageComponent } from './pages/predefinidos-clientes-page/predefinidos-clientes-page.component';
import { CambiarVendedorFacturaPageComponent } from './pages/cambiar-vendedor-factura-page/cambiar-vendedor-factura-page.component';
import { VerificadorSalidasPorVentaComponent } from './pages/reports/verificador-salidas-por-venta/verificador-salidas-por-venta.component';
import { OpcionArchivarCargaEntregaComponent } from './pages/opcion-archivar-carga-entrega/opcion-archivar-carga-entrega.component';
import { CtrlDisenosProduccionPageComponent } from './pages/ctrl-disenos-produccion-page/ctrl-disenos-produccion-page.component';
import { SeleccionCorteCajaComponent } from './pages/seleccion-corte-caja/seleccion-corte-caja.component';
import { ManufacturaModule } from '../Manufactura/manufactura.module';
import { TransferenciasComponent } from './pages/transferencias/transferencias.component';
import { ChequesComponent } from './pages/cheques/cheques.component';
import { InfoGeneralProductoPageComponent } from './pages/info-general-producto-page/info-general-producto-page.component';
import { CambiarClaseFacturaPageComponent } from './pages/cambiar-clase-factura-page/cambiar-clase-factura-page.component';
import { MasVendidosComponent } from './pages/reports/mas-vendidos/mas-vendidos.component';
import { InfoGeneralClientePageComponent } from './pages/info-general-cliente/info-general-cliente-page.component';
import { InformeUtilidadPageComponent } from './pages/reports/informe-utilidad-page/informe-utilidad-page.component';

@NgModule({
  declarations: [
    ClaseVentaPageComponent,
    TiposClientesPageComponent,
    ClasesClientesPageComponent,
    FormasPagoPageComponent,
    CondicionesPagosPageComponent,
    ClientesPageComponent,
    DocumentosVentasPageComponent,
    SeriesVentasPageComponent,
    FacturacionPageComponent,
    DiarioVentasPageComponent,
    CatalogoClientesPageComponent,
    FormaPagoPageComponent,
    OpcionCancelacionPageComponent,
    CfdiRelacionadoPageComponent,
    ComentarioGlobalPageComponent,
    FacturaGlobalPageComponent,
    CopiarComprobantesPageComponent,
    CargaListaPrecioComponent,
    ListaPrecioComponent,
    ConversionesPageComponent,
    VerificadorVentasPageComponent,
    AnticiposDisponiblesPageComponent,
    AnticiposFacturadosPageComponent,
    CuentaBancariaClientePageComponent,
    ChoferesPageComponent,
    UnidadesPageComponent,
    CajaPageComponent,
    AperturaCajaPageComponent,
    ImpresionListaPreciosPageComponent,
    CartaPortePageComponent,
    CtrlEdicionUbicacionComponent,
    CtrlEdicionTipoFiguraComponent,
    CtrlEdicionMercanciaComponent,
    ConfigVentasPageComponent,
    TipoServicioUnidadPageComponent,
    DireccionEntregaPageComponent,
    HistoricoRemisionesPageComponent,
    SaldoFacturaResguardoPageComponent,
    PresupuestosVendedoresPageComponent,
    PresupuestosSucursalesPageComponent,
    CtrlEdicionCopiarPresupuestosComponent,
    CtrlHistoricoClientePageComponent,
    TipoDeCambioPageComponent,
    SeriesRepPageComponent,
    ReciboElecPagoPageComponent,
    VenatanaRepPageComponent,
    CargaEntregaPageComponent,
    ConfigCargaEntregaPageComponent,
    OrdenesCargaPageComponent,
    CtrlEdicionCargaEntregaComponent,
    ComplementoCartaPortePageComponent,
    ConsignadoPageComponent,
    AltaConsignadoPageComponent,
    PlantillasCartaPortePageComponent,
    NeumaticosPageComponent,
    UbicacionNeumaticoPageComponent,
    DiarioRepsPageComponent,
    CtrlEdicionCargaEntregaRegresarProductosComponent,
    VerificadorFacturasEntregadasADomicilioComponent,
    FormulacionPageComponent,
    DatosNotaCreditoPageComponent,
    TipoGastoUnidadesComponent,
    MotivoReImpresionPageComponent,
    DatosTarjetaPageComponent,
    DatosTransferenciaPageComponent,
    DatosChequePageComponent,
    CorteCajaPageComponent,
    ConteoEfectivoComponent,
    TiposRetirosComponent,
    RetirosCajaComponent,
    TerminalesComponent,
    DatosVentaAfectaPageComponent,
    ClientesPorVendedorComponent,
    VentaRelacionesPageComponent,
    AgregarProductoListaPageComponent,
    CancelacionDocumentosVentasPageComponent,
    OpcionCancelacionSalidaporventaComponent,
    VerificadorTonalejeCargaEntregaComponent,
    PredefinidosClientesPageComponent,
    CambiarVendedorFacturaPageComponent,
    VerificadorSalidasPorVentaComponent,
    OpcionArchivarCargaEntregaComponent,
    CtrlDisenosProduccionPageComponent,
    SeleccionCorteCajaComponent,
    TransferenciasComponent,
    ChequesComponent,
    InfoGeneralProductoPageComponent,
    CambiarClaseFacturaPageComponent,
    MasVendidosComponent,
    InfoGeneralClientePageComponent,
    InformeUtilidadPageComponent

  ],
  exports:[
    ConversionesPageComponent,
    DireccionEntregaPageComponent,
    OpcionCancelacionPageComponent,
    CfdiRelacionadoPageComponent
  ],
  imports: [
    ScrollingModule,
    ComponentsModule,
    CommonModule,
    FormsModule,
    ComponentUiModule,
    ReactiveFormsModule,
    NgbTimepickerModule,
    DateBoxComponent,
    NgxDaterangepickerMd.forRoot(),
    GuiGridModule,
    NgxEditorModule,
    NgScrollbarModule,
    DragDropModule,
    NgbTooltip,
    DirectivesModule,
  ],
})
export class VentasModule { }
