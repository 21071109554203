import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { LevelType, ReportFilter, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ReportsService } from 'src/app/service/reports.service';

@Component({
  selector: 'app-verificador-estados-cuentas',
  templateUrl: './verificador-estados-cuentas.component.html',
  styleUrls: ['./verificador-estados-cuentas.component.scss']
})
export class VerificadorEstadosCuentasComponent {
  @ViewChild('txtNombreReporte')
  public txtNombreReporte!: ElementRef;


  activeButtons: ActiveButtons = {
    all: false,
    print: true,
  }

  menuId: string = "verificadorestadoscuentas";

  private frmBuilder = inject(FormBuilder);
  private reportsService = inject(ReportsService);

  public frmVerificadorEstadoCuentas: FormGroup = this.frmBuilder.group({
    Fecha1: new Date(),
    Fecha2: new Date(),
    Num1: [],
    Num2: [],
    NombreReporte: ['VERIFICADOR DE ESTADOS DE CUENTA', Validators.required],
    Orden: []
  });

  tiposConciliacion: ComboBoxEntity[] = [
    { Id: 1, Nombre: "Nosotros", Clave: 1, ClaveNombre: "01 Nosotros" },
    { Id: 2, Nombre: "Ellos", Clave: 2, ClaveNombre: "02 Ellos" },
  ];

  estadosConciliacion: ComboBoxEntity[] = [
    { Id: 1, Nombre: "Conciliados", Clave: 1, ClaveNombre: "01 Conciliados" },
    { Id: 2, Nombre: "No Conciliados", Clave: 2, ClaveNombre: "02 No Conciliados" },
  ];


  tipoSeleccionado?: ComboBoxEntity;
  estadoSeleccionado?: ComboBoxEntity;

  get selectEstadoItem(): ComboBoxEntity | null {
    return this.estadoSeleccionado!;
  }

  get selectTipoItem(): ComboBoxEntity | null {
    return this.tipoSeleccionado!;
  }

  ngOnInit(): void {
    setTimeout(() => {
      const txt: any = this.txtNombreReporte;
      txt.tagInput.nativeElement.focus();
      this.tipoSeleccionado = this.tiposConciliacion[0];
      this.estadoSeleccionado = this.estadosConciliacion[0];
    }, 300);
  }


  selectedCombo(entity: any, tipo: string) {
    if (tipo === "Num1") {
      this.tipoSeleccionado = entity;
    } else {
      this.estadoSeleccionado = entity;
    }
    this.frmVerificadorEstadoCuentas.get(tipo)?.setValue(entity?.Id);
  }

  public filtrosVerificadorEstadosCuenta: ReportFilter =
    {
      menuId: this.menuId,
      ReportHeader: {
        Fecha1: new Date(),
        Fecha2: new Date(),
        NombreReporte: 'VERIFICADOR DE ESTADOS DE CUENTA'
      } as ReportHeader,
      Fecha1: new Date(),
      Fecha2: new Date(),
      NombreReporte: 'Verificador de Estados de Cuenta',
      TituloVisor: 'Verificador de Estados de Cuenta',
      NombreExcel: 'VerificadordeEstadosdeCuenta.xlsx',
      FilterOptions: [
        { Campo: 'e.Clave', Etiqueta: 'Empresa', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Empresa" },
        { Campo: 'mp.ClaveCuentaNivel', Etiqueta: 'Cuenta Contable', Tipo: TypeFilter.levels, TipoNiveles: LevelType.account },
      ]
    };


  changeselec(event: any) {
    this.filtrosVerificadorEstadosCuenta = event;
  }

  imprimir(event: any) {
    this.filtrosVerificadorEstadosCuenta.ReportHeader = this.frmVerificadorEstadoCuentas.value;
    this.reportsService.printReport(this.filtrosVerificadorEstadosCuenta, '/Tesoreria/ImprimirMovimientosContabilidad');
  }
}
