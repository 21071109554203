import { Component, ElementRef, inject, OnInit, ViewChild } from '@angular/core';
import { EventsService } from 'src/app/service/events.service';
import { ModalService } from 'src/app/service/modal.service';

@Component({
  selector: 'app-cambiar-vendedor-factura-page',
  templateUrl: './cambiar-vendedor-factura-page.component.html',
  styles: [
  ]
})
export class CambiarVendedorFacturaPageComponent implements OnInit {

  @ViewChild('txtVendedor')
  public txtVendedor!: ElementRef<any>;
  private eventsService = inject(EventsService);

  constructor(private ms: ModalService) { }

  ngOnInit(): void {

    setTimeout(() => {
      const txt: any = this.txtVendedor;
      txt.tagInput.nativeElement.focus()
    }, 150);

  }


  closeModal() {
    this.ms.closeModal(null);
  }


  aceptarCambiarVendedor() {
    const t: any = this.txtVendedor;
    if (t.selectedItem) {
      this.ms.closeModal(t.selectedItem);
    } else {
      this.eventsService.publish('home:showAlert', { message: "Debe de seleccionar el Vendedor.", cancelButton: false });
    }
  }

}
