<app-container-base
  subTitle="Auditoría de CFDIS PPD, verifique que todos sus CFDI Recibidos PPD tengan todos sus Recibos Electrónicos de Pago"
  icon="fas fa-folder-tree" [hasCounter]="false" [hasBar]="false" idComponent="file-manager-component">
  <div class="screen-content" [style.height.px]="scrollHeihgt">
    <div class="file-manager h-100" [ngClass]="{ 'file-manager-sidebar-mobile-toggled': mobileSidebarToggled }">

      <div class="p-2">
        <form [formGroup]="myForm" autocomplete="off">
          <div class="row">
            <div class="col-2">
              <div class="row">
                <div class="col-6">
                  <input type="date" class="form-control" formControlName="fechaInicioString">
                </div>
                <div class="col-6">
                  <input type="date" class="form-control" formControlName="fechaFinString">
                </div>
              </div>
            </div>
            <div class="col-2">
              <div class="position-relative">
                <input type="text" class="form-control ps-35px rounded-3" formControlName="busqueda"
                  placeholder="Texto a buscar" #txtBuscarCfdi>
                <button class="btn position-absolute start-0 top-0 shadow-none"><i
                    class="fa fa-search fa-lg text-orange"></i></button>
              </div>
            </div>
            <div class="col-2">
              <a (click)="search()" class="btn btn-primary btn-rounded px-4 btn-sm d-block mt-3">Buscar</a>
            </div>
          </div>

        </form>
      </div>

      <div class="file-manager-container">
        <div class="file-manager-content d-flex flex-column">
          <div class="row">
            <div class="col-lg-4">
              <div class="btn-group me-2 m-2 w-200px">
                <div (click)="selTodos()" type="button" [class]="'btn btn-sm btn-white' + (!todos?'':' active')"><i
                    [class]="'fa'+(!todos?'r':'')+' fa-fw fa-'+ (todos?'check':'square')+' ms-n1'"></i>Todos</div>
                <div (click)="selTodos()" type="button" [class]="'btn btn-sm btn-white' + (!ninguno?'':' active')">
                  <i [class]="'fa'+(!ninguno?'r':'')+' fa-fw fa-'+ (ninguno?'check':'square')+' ms-n1'"></i>
                  Ningúno
                </div>
              </div>
            </div>
            <div class="col-lg-5">
              <div class="btn-group me-2 m-2">
                <button (click)="descargarZip()" [disabled]="getActivarOpciones" type="button"
                  class="btn shadow-none text-body border-0"><i class="fa fa-lg me-1 fa-download text-green"></i>
                  Descargar .zip
                </button>
                <button (click)="validarEstado()" [disabled]="getActivarOpciones" type="button"
                  class="btn shadow-none text-body border-0"><i class="fa fa-lg me-1 fa-hamsa text-primary"></i>
                  Validar Estado
                </button>

                <button (click)="imprimirSeleccionados()" [disabled]="getActivarOpciones" type="button"
                  class="btn shadow-none text-body border-0"><i class="fa fa-lg me-1 fa-file-pdf text-red"></i>
                  Imprimir
                </button>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="file-manager-sidebar-footer">
                <div class="d-flex align-items-center">
                  <div class="flex-1 ps-3 small">
                    <div class="fw-bold">Registros: <b class="text-body">{{lista.length | number}}</b><b
                        class="text-body"></b></div>
                  </div>
                </div>
              </div>
            </div>

          </div>


          <div class="flex-1 overflow-hidden ms-2">

            <table class="blueTable" style="width:1270px;">
              <thead style="position: sticky;top:0;">
                <tr>
                  <th style="width: 30px;">Sel</th>
                  <th style="width: 30px;">PDF</th>
                  <th style="width: 30px;">XML</th>
                  <th style="width: 30px;">Vig</th>
                  <th style="width: 30px;">MP</th>
                  <th
                    [style]="'cursor: pointer;width:80px !important;'+((lastOrder=='tipoDeComprobante'?'background-color:orange;color:#ffffff;':''))"
                    (click)="ordenar('tipoDeComprobante')" class="px-10px">Tipo
                    <i *ngIf="lastOrder=='tipoDeComprobante'"
                      [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAsc=='asc'?'down':'up')+'-wide-short float-end'"></i>
                  </th>
                  <th
                    [style]="'cursor: pointer;width:200px !important;'+((lastOrder=='uuid'?'background-color:orange;color:#ffffff;':''))"
                    (click)="ordenar('uuid')" class="px-10px">UUID<i *ngIf="lastOrder=='uuid'"
                      [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAsc=='asc'?'down':'up')+'-wide-short float-end'"></i>
                  </th>
                  <th
                    [style]="'cursor: pointer;width:200px !important;'+((lastOrder=='emisorRfc'?'background-color:orange;color:#ffffff;':''))"
                    (click)="ordenar('emisorRfc')" class="px-10px">Emisor<i *ngIf="lastOrder=='emisorRfc'"
                      [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAsc=='asc'?'down':'up')+'-wide-short float-end'"></i>
                  </th>
                  <th
                    [style]="'cursor: pointer;width:100px;'+((lastOrder=='serieFolio'?'background-color:orange;color:#ffffff;':''))"
                    (click)="ordenar('serieFolio')" class="px-10px text-center">Serie/Folio<i
                      *ngIf="lastOrder=='serieFolio'"
                      [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAsc=='asc'?'down':'up')+'-wide-short float-end'"></i>
                  </th>
                  <th
                    [style]="'cursor: pointer;width:80px;'+((lastOrder=='fechaEmision'?'background-color:orange;color:#ffffff;':''))"
                    (click)="ordenar('fechaEmision')" class="px-10px text-center">Emisión<i
                      *ngIf="lastOrder=='fechaEmision'"
                      [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAsc=='asc'?'down':'up')+'-wide-short float-end'"></i>
                  </th>
                  <th class="text-end"
                    [style]="'cursor: pointer;width:80px;'+((lastOrder=='total'?'background-color:orange;color:#ffffff;':''))"
                    (click)="ordenar('total')">Total Factura<i *ngIf="lastOrder=='total'"
                      [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAsc=='asc'?'down':'up')+'-wide-short float-end'"></i>
                  </th>
                  <!-- <th class="text-end"
                    [style]="'cursor: pointer;width:80px;'+((lastOrder=='contabilizado'?'background-color:orange;color:#ffffff;':''))"
                    (click)="ordenar('contabilizado')">Contabilidad<i *ngIf="lastOrder=='contabilizado'"
                      [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAsc=='asc'?'down':'up')+'-wide-short float-end'"></i>
                  </th> -->
                  <th class="text-end"
                    [style]="'cursor: pointer;width:80px;'+((lastOrder=='totalImporteReps'?'background-color:orange;color:#ffffff;':''))"
                    (click)="ordenar('totalImporteReps')">Importe REPS<i *ngIf="lastOrder=='totalImporteReps'"
                      [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAsc=='asc'?'down':'up')+'-wide-short float-end'"></i>
                  </th>
                </tr>
              </thead>
            </table>
            <cdk-virtual-scroll-viewport itemSize="20" style="height: 100%;border-bottom: 1px solid #AAAAAA;">
              <table class="blueTable" style="width:1270px;">
                <tbody class="bg-light" style="font-family: math;">
                  <tr *cdkVirtualFor="let item of lista; let i = index;"
                    [style]="item.seleccionado ? 'background-color: #ebff01a1;': ''">
                    <td class="text-center" style="width:30px !important;">
                      <input (click)="seleccionar(i)" class="form-check-input" type="checkbox"
                        [checked]="item.seleccionado" value="">
                    </td>
                    <td class="text-center" style="width:30px !important;"><i (click)="print(item.uuid)"
                        class="fa fa-file-pdf text-red fa-lg" style="cursor:pointer;"></i></td>
                    <td class="text-center" style="width:30px !important;"><i (click)="downloadXML(item.uuid)"
                        class="fa fa-file-code text-warning fa-lg" style="cursor:pointer;"></i></td>
                    <td class="text-center" style="width:30px;"> <i
                        [class]="'fas fa-lg fa-fw me-10px  '+(item.vigente?'fa-circle-check text-green':'fa-circle-xmark text-red')+' '"></i>
                    </td>
                    <td class="text-center" style="width:30px;"> {{ item.metodoDePago | uppercase }}</td>
                    <td class="text-center" style="width:80px;"> {{ item.tipoDeComprobante | uppercase }}</td>
                    <td style="width:200px;padding-left: 5px;">{{item.uuid | uppercase}}</td>

                    <td style="width:200px;padding-left: 5px;">{{item.emisorRfc}}/{{item.emisorNombre}}</td>
                    <td class="text-center" style="width:100px;padding-left: 5px;">{{item.serieFolio}}</td>
                    <td style="width:80px;" class="text-center">{{item.fechaEmision | date: 'dd/MM/yyy'}}</td>
                    <td style="width:80px;padding-right: 5px;" class="text-end">{{item.total| number:'1.2'}}</td>
                    <!-- <td style="width:80px;padding-right: 5px;" class="text-end">{{item.contabilizado| number:'1.2'}}</td> -->
                    <td style="width:80px;padding-right: 5px;" class="text-end">{{item.totalImporteReps| number:'1.2'}}</td>
                  </tr>
                </tbody>
              </table>
            </cdk-virtual-scroll-viewport>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-container-base>
