import { Component, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { LevelType, ReportFilter, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { EventsService } from 'src/app/service/events.service';
import { ReportsService } from 'src/app/service/reports.service';
import { UtilsService } from 'src/app/service/utils.service';

@Component({
  templateUrl: './diario-general.page.component.html',
  styleUrls: ['./diario-general.page.component.scss']
})
export class DiarioGeneralPageComponent {
  private frmBuilder = inject(FormBuilder);
  private reportsService = inject(ReportsService);
  private _utils = inject(UtilsService);
  private eventsService = inject(EventsService);

  menuId: string = "diario-general";


  public filtrosDiarioGeneral: ReportFilter =
    {
      menuId: this.menuId,
      EsContabilidad: true,
      ReportHeader: {} as ReportHeader,
      Fecha1: new Date(),
      Desglose: 'A Totales',
      Fecha2: new Date(),
      NombreReporte: 'Diario General',
      TituloVisor: 'Diario General',
      NombreExcel: 'DiarioGeneral.xlsx',
      DesgloseLista: [{ Nombre: "A Totales" }, { Nombre: "A Detalle" }],
      FilterOptions: [
        { Campo: 'emp.Clave', Etiqueta: 'Empresa', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Empresa" },
        { Campo: 'tp.Clave', Etiqueta: 'Tipo de Póliza', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "TipoPoliza" },
        { Campo: 'mp.Folio', Etiqueta: 'Número de Póliza', Tipo: TypeFilter.number },
        { Campo: 'mp.ClaveCuentaNivel', Etiqueta: 'Cuenta Contable', Tipo: TypeFilter.levels, TipoNiveles: LevelType.account },
        { Campo: 'mp.ClaveCentroCostoNivel', Etiqueta: 'Centros de costos', Tipo: TypeFilter.levels, TipoNiveles: LevelType.costAccount },
        { Campo: 'u.Clave', Etiqueta: 'Usuario', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Usuario" },
        { Campo: 'mp.Referencia', Etiqueta: 'Referencia', Tipo: TypeFilter.text },
        { Campo: 'mp.ConceptoDetalle', Etiqueta: 'Concepto Detalle', Tipo: TypeFilter.text },
      ]
    };


  public frmCostoVentas: FormGroup = this.frmBuilder.group({
    Fecha1: new Date(),
    Fecha2: new Date(),
    NombreReporte: ['DIARIO GENERAL', Validators.required],
    Opc1:false
  });


  contenidoSeleccionado?: ComboBoxEntity;

  activeButtons: ActiveButtons = {
    all: false,
    print: true,
  }

  get selectContenidoManualItem(): ComboBoxEntity | null {
    return this.contenidoSeleccionado!;
  }

  onChangeFecha(value: any) {
    this.frmCostoVentas.get('NombreReporte')?.setValue('DIARIO GENERAL ' + this.rangoFechas);
  }

  get rangoFechas(): string {
    return this._utils.getEtiquetaRangoPorFechas(this.frmCostoVentas.value.Fecha1, this.frmCostoVentas.value.Fecha2, 2).toUpperCase();
  }

  ngOnInit() {
    this.frmCostoVentas.get('NombreReporte')?.setValue('DIARIO DE GENERAL ' + this.rangoFechas);
  }


  selectedCombo(entity: any, tipo: string) {
    this.frmCostoVentas.controls[tipo].setValue(entity);
  }

  changeSelect(event: any) {
    this.filtrosDiarioGeneral = event;
  }

  imprimir(event: any) {
    this.filtrosDiarioGeneral.ReportHeader = this.frmCostoVentas.value;
    this.reportsService.printReport(this.filtrosDiarioGeneral, '/Contabilidad/ReporteDiarioGeneral');
  }
}
