import { Component, inject, Input, OnInit } from '@angular/core';
import { VentaFormaPagoDatosTarjeta } from '../../interfaces/venta.interface';
import { ModalService } from 'src/app/service/modal.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EventsService } from 'src/app/service/events.service';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { en } from '@fullcalendar/core/internal-common';
import { TerminalBancaria } from 'src/app/Finanzas/interfaces/terminalbancaria.interface';
import { FacturacionService } from '../../services/facturacion.service';
import { UtilsService } from 'src/app/service/utils.service';
import { UserLogged } from 'src/app/auth/interfaces';
import * as moment from 'moment';

@Component({
  selector: 'app-datos-tarjeta-page',
  templateUrl: './datos-tarjeta-page.component.html',
  styles: [
  ]
})
export class DatosTarjetaPageComponent implements OnInit {

  @Input()
  public item: VentaFormaPagoDatosTarjeta | null = null;
  @Input()
  public idSucursal: number = 0;
  info: UserLogged | null = null;
  private eventsService = inject(EventsService);
  public myForm: FormGroup = this.fb.group({
    Id: [0],
    Banco: [null],
    CuentaBancaria: [null],
    Debito: [false],
    Credito: [false],
    TerminalBancaria: [null],
    Importe: [0],
    NumeroLote: '',
    Referencia: '',
  })

  constructor(private ms: ModalService, private fb: FormBuilder, private containerService: ContainerBaseService, private fs: FacturacionService, private utilsService: UtilsService) {
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.info = this.utilsService.getUserLogged();
      this.myForm.reset({ ...this.item })
    }, 150);
  }

  onSelectedItem(entity: any, tipo: string) {
    this.myForm.controls[tipo].setValue(entity);
    if (tipo == "TerminalBancaria") {
      if (entity) {
        this.eventsService.publish('home:isLoading', { isLoading: true });
        this.containerService.getEntityById("TerminalBancaria", entity.Id).subscribe((obj: TerminalBancaria) => {
          this.myForm.controls["Banco"].setValue(obj.Banco);
          this.myForm.controls["CuentaBancaria"].setValue(obj.CuentaBancaria);
          this.eventsService.publish('home:isLoading', { isLoading: false });
          this.fs.obtenerNumeroLoteTerminal(this.info!.numero, moment(new Date()).format("YYYYMMDD"), entity.Id).subscribe((lote) => {
            this.myForm.controls["NumeroLote"].setValue(lote.toString());
          })

        })
      } else {
        this.myForm.controls["Banco"].setValue(null);
        this.myForm.controls["CuentaBancaria"].setValue(null);
      }
    }
  }

  accept() {
    const ent = this.myForm.value;
    if (!ent.TerminalBancaria) {
      this.eventsService.publish('home:showAlert', { message: `Debe de indicar la Terminal.`, cancelButton: false });
      return;
    }
    if (!ent.NumeroLote) {
      this.eventsService.publish('home:showAlert', { message: `Debe de indicar el Número de Lote de la Terminal según el corte de la misma.`, cancelButton: false });
      return;
    }
    this.ms.closeModal(ent);
  }

  closeModal() {
    this.ms.closeModal(null);
  }


}
