import { Component, ElementRef, inject, OnInit, ViewChild } from '@angular/core';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { SolicitudCancelacionInternaDTO } from '../../interfaces/solicitudcancelacioninterna.interface';
import { FacturacionService } from '../../services/facturacion.service';
import { EventsService } from 'src/app/service/events.service';
import { UserLogged } from 'src/app/auth/interfaces';
import { UtilsService } from 'src/app/service/utils.service';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';
import Swal from 'sweetalert2';
import { CfdiEstadoSAT, InvoicesStatus } from '../../interfaces/venta.interface';
import { ModalService } from 'src/app/service/modal.service';
import * as moment from 'moment';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-cancelacion-documentos-ventas-page',
  templateUrl: './cancelacion-documentos-ventas-page.component.html',
  styles: [
  ]
})
export class CancelacionDocumentosVentasPageComponent implements OnInit {

  activeButtons: ActiveButtons = {
    all: false,
  }
  @ViewChild('modalRelacionados')
  public modalRelacionados!: ElementRef<HTMLElement>;
  uuidsRelacionadosHijos: InvoicesStatus[] = [];
  uuidsRelacionadosPadres: InvoicesStatus[] = [];
  private eventsService = inject(EventsService);
  private ms = inject(ModalService);
  info: UserLogged = {} as UserLogged;
  source: any[] = [];
  constructor(private fs: FacturacionService, private utileService: UtilsService) { }

  ngOnInit(): void {
    this.info = this.utileService.getUserLogged();
    this.cargarCancelaciones();
  }



  cancelar(item: any) {
    this.eventsService.publish('home:showAlert', {
      message: `¿Está seguro de <b>CANCELAR EL DOCUMENTO <span>${item.serieString}${item.folio}</span></b>?`, onConfirm: (data: AlertResponse) => {
        if (data.isAccept) {
          this.verifyStatus(item);
        }
      }
    });
  }

  verifyStatus(item: any) {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.fs.verificarEstadoSAT(item.idVenta).subscribe((result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result.success) {
        const r: CfdiEstadoSAT = JSON.parse(result.message);
        if (r.EsCancelable == "No cancelable") {
          this.verUUIDSRelacionados(item);
          return;
        }
        if (r.Estado == "No Encontrado") {
          this.eventsService.publish('home:showAlert', { message: `El CFDI no fue encontrado en los servidores del SAT, por favor intente de nuevo mas tarde.`, cancelButton: false });
          return;
        }
        this.procederCancelar(item);
      } else {
        this.eventsService.publish('home:showAlert', {
          message: "No se pudo consultar el estatus del CFDI ¿desea continuar con la cancelación?", onConfirm: (data: AlertResponse) => {
            if (data.isAccept) {
              const r: CfdiEstadoSAT = JSON.parse(result.message);
              setTimeout(() => { this.procederCancelar(item); }, 151);
            }
          }
        });
      }
    });
  }

  procederCancelar(e: any) {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.fs.enviarCancelacionComprobante(e.fechaSolicitoCancelacion, e.idVenta, e.motivoCancelacionSolicitud, e.motivoCancelacionSAT ? e.motivoCancelacionSAT.id : 0, e.uuidSustituye, e.id).subscribe((result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result.success) {
        this.cargarCancelaciones();
      } else {
        this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
      }

    });


  }

  verUUIDSRelacionados(item: any) {
    this.uuidsRelacionadosHijos = [];
    this.uuidsRelacionadosPadres = [];
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.fs.consultarUUUIDSRelacionados(item.idVenta).subscribe((response) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (response) {
        if (!response.error) {
          this.eventsService.publish('home:showAlert', {
            message: "El documento no es Cancelable porqué está relacionado a otros CFDIS, de clic en Aceptar para ver los UUIDS relacionados.", onConfirm: (data: AlertResponse) => {
              this.uuidsRelacionadosHijos = response.uuidsRelacionadosHijos ? response.uuidsRelacionadosHijos : [];
              this.uuidsRelacionadosPadres = response.uuidsRelacionadosPadres ? response.uuidsRelacionadosPadres : [];
              setTimeout(() => { this.openRelacionados(); }, 100);
            }
          });
        } else {
          this.eventsService.publish('home:showAlert', { message: response.error, cancelButton: false });
        }
      } else {
        this.eventsService.publish('home:showAlert', { message: "Ocurrio un error al consultar el Comprobante en el SAT.", cancelButton: false });
      }
    });
  }

  openRelacionados() {
    const b: any = this.modalRelacionados;
    this.ms.openModal(b, (e: any) => {
      this.uuidsRelacionadosHijos = [];
      this.uuidsRelacionadosPadres = [];
    })
  }

  cerrarRelaciones() {
    this.ms.closeModal(null);
  }

  rechazarCancelacion(id: number) {
    this.eventsService.publish('home:showAlert', {
      message: "¿Está seguro de <b>RECHAZAR LA CANCELACIÓN AL USUARIO</b>?", onConfirm: (data: AlertResponse) => {
        if (data.isAccept) {
          Swal.fire({
            title: "Indique el MOTIVO del Rechazo",
            input: "text",
            inputAttributes: { autocapitalize: "off" },
            showCancelButton: true,
            confirmButtonText: "Rechazar",
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
          }).then((result) => {
            if (result.isConfirmed) {
              if (result.value) {
                this.eventsService.publish('home:isLoading', { isLoading: true });
                this.fs.rechazarSolicitudInterna(id, result.value).subscribe((result) => {
                  this.eventsService.publish('home:isLoading', { isLoading: false });
                  if (result.success) {
                    this.cargarCancelaciones();
                    Swal.fire({ position: 'center', icon: 'success', title: 'Se rechazó correctamente', showConfirmButton: false, timer: 2000 })
                  } else {
                    this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
                  }
                })


              } else {
                this.eventsService.publish('home:showAlert', { message: "Debe de indicar un Motivo de rechazo.", cancelButton: false, icon: 'fa-triangle-exclamation text-yellow' });
              }
            }
          });
        }
      }
    });

  }

  liberar(idVenta: number, idSol: number) {
    this.eventsService.publish('home:showAlert', {
      message: "¿Está seguro de <b>CANCELAR SOLAMENTE EL INVENTARIO</b>?", onConfirm: (data: AlertResponse) => {
        if (data.isAccept) {
          //this.eventsService.publish('home:isLoading', { isLoading: true });

        }
      }
    });

  }

  print(id: number) {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.fs.imprimirDocumentoVenta(id, true).subscribe((result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result.success) {
        this.fs.printDocument(this.fs.base64ToArrayBuffer(result.message));
      } else {
        this.eventsService.publish('home:showAlert', { message: `${result.message}`, cancelButton: false });
      }
    });


  }

  cargarCancelaciones() {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.fs.obtenerSolicitudesCancelacionInternas(this.info.empresa!.numero, this.info.sucursal!.numero).subscribe((lista) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      this.source = lista;
    });
  }

}
