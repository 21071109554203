import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, inject, Input, OnInit, ViewChild } from '@angular/core';
import { ModalService } from 'src/app/service/modal.service';
import { environment } from 'src/environments/environment';

import { GuiCellEdit, GuiCellView, GuiColumnAlign, GuiDataType } from '@generic-ui/ngx-grid';


import Swal from 'sweetalert2';
import { CompraFormaPago } from '../../interfaces/compra.interface';
import { c_FormaPago } from 'src/app/ventas/interfaces/formapago.interface';
import { AppSettings } from 'src/app/home/services/app-settings.service';

@Component({
  selector: 'app-compra-forma-pago-page',
  templateUrl: './compra-forma-pago-page.component.html',
  styleUrls: []
})

export class CompraFormaPagoPageComponent implements OnInit {

  private readonly baseUrl: string = environment.baseUrlApi;

  constructor(private http: HttpClient,
    private mService: ModalService) {
  }

  public GuiColumnAlign = GuiColumnAlign;
  public GuiCellView = GuiCellView;
  public GuiDataType = GuiDataType;
  indexEditing = -1;
  public appSettings = inject(AppSettings);

  payments: c_FormaPago[] = [];
  @Input()
  formasPago: CompraFormaPago[] = [];
  @Input()
  total: number = 0;



  source: Array<CompraFormaPago> = [];
  blockPayment: boolean = false;


  ngOnInit(): void {
    if (!this.formasPago) {
      this.formasPago = [];
    } else {
      if (this.formasPago.length > 0) {
        if (this.formasPago[0].FormaPago?.Id == 22) {
          this.blockPayment = true;
        }
      }
    }
    this.sourceReset([...this.formasPago]);
    this.getPayments();
  }

  deleteRow(index: number) {
    if (this.blockPayment) return;
    this.source.splice(index, 1);
    this.source = [...this.source];
  }


  selectPayment(index: number, item: any) {
    const selected: number = parseInt(item.target.value);
    this.source[index].FormaPago = this.payments.filter(P => P.Id == selected)[0];
    this.source = [...this.source]
  }

  blurImporte(e: any, index: number) {
    e.target.classList.remove(this.getEditorClass());
    let importe = Number(e.target.value);
    if (!importe) {
      e.target.value = "0.00"
      importe = 0;
      if (this.source[index].FormaPago) {
        this.source.splice(index, 1);
      }
      this.source = [...this.source];
      this.sourceReset(this.source.filter(P => P.FormaPago));
      return;
    }
    this.source[index].Importe = importe;
    this.source = [...this.source];
  }

  closeModal() {
    this.mService.closeModal(null);
  }
  getEditorClass() {
    return this.appSettings.appDarkMode ? 'focus-editor-grid-dark' : 'focus-editor-grid';
  }

  focusImporte(e: any) {
    e.target.classList.add(this.getEditorClass());
  }

  enter(e: any) {
    e.target.blur();
  }



  get getDiff(): number {
    let dif = this.total - this.getTotalCapturado;
    if (dif < 0) dif = 0;
    return dif;
  }

  get getTotalCapturado(): number {
    let importe: number = 0;
    this.source.filter(P => P.Importe!> 0).forEach((i) => { importe += parseFloat(i.Importe!.toString()) });
    return importe;
  }

  accept() {
    let importe: number = 0;
    this.source.filter(P => P.FormaPago).forEach((i) => { importe += parseFloat(i.Importe!.toString()) });

    if (importe > this.total) {
      Swal.fire({ text: `Las formas de pago son mayor al total del Documento.`, icon: 'error', });
      return;
   }

    this.mService.closeModal(this.source.filter(P => P.FormaPago));
  }


  sourceEdited(e: any) {

    // if (this.columnEditing == 1) {
    //   this.searchProduct(e.after.NoIdentificacion);
    // }

    // if (this.columnEditing == 3 || this.columnEditing == 5 || this.columnEditing == 6) {
    //   this.calcTax();
    // }

  }

  cellEditing: GuiCellEdit = {
    enabled: true,
    rowEdit: (value: any, item: CompraFormaPago, index: number) => {
      this.indexEditing = -1;
      if (index > 0) {
        if (!this.source[index - 1].FormaPago) {
          return false;
        }
        if (this.source[index - 1].FormaPago!.Id == 0) {
          return false;
        }
      }
      if (item.Importe == 0) {
        if (this.getDiff > 0) {
          this.source[index].Importe = this.getDiff;
        } else {
          return false;
        }
      }
      this.indexEditing = index;
      return true;
    },

    cellEdit: (value: any, item: any, index: number) => {
      return true;
    }
  }

  cellEditSubmitted() {
    if (!this.source[this.indexEditing].FormaPago) {
      this.source[this.indexEditing].FormaPago = this.payments[0];
      this.source = [...this.source];
    }
  }

  sourceReset(formasPago: any = []) {
    this.source = formasPago;
    for (let index = 0; index < 10; index++) {
      this.source = [...this.source, {
        Id: 0,
        FormaPago: null,
        FormaPagoNombre: '',
        Importe: 0,
      }]
    }
    this.source = [...this.source];
  }


  getPayments() {
    return this.http.get<c_FormaPago[]>(`${this.baseUrl}/Ventas/ObtenerFormasPagoActivas`).subscribe((lista) => {
      this.payments = lista;
    })
  }

  clickButton(item: c_FormaPago) {
    this.mService.closeModal(item);
  }



}
