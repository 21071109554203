<div class="card text-center border-0" cdkDrag
  style="z-index:2;box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; position: absolute;top:80px;width: 950px;left: 360px;">
  <div class="card-header fw-bold" style="cursor: move;" cdkDragHandle>
    <span>
      {{'Captura de la producción' | titlecase}}
    </span>
  </div>
  <div class="card-body">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Indique la cantidad a producir" style="height: 200px;">
        <div class="panel-content">
          <components-ui-text-box [tabIndex]="501" [maxLength]="100" [required]="true" [label]="'Referencia'"
            helpLine="Indique la referencia que acompaña la producción a elaborar" [widthTextColumns]="10"
            [widthLabelColumns]="2" #txtRef />
          <div class="mb-1">
            <ng-scrollbar style="height: 130px; width:830px; border-bottom: 1px solid #AAAAAA;">
              <table class="blueTable" style="width:820px;">
                <thead style="position: sticky;top:0;">
                  <tr>
                    <th style="width: 250px;">Producto</th>
                    <th style="width: 80px;">Unidad</th>
                    <th style="width: 80px;">Solicitado</th>
                    <th style="width: 80px;">Producido</th>
                    <th style="width: 80px;">Pendiente</th>
                    <th style="width: 40px;">Producir</th>
                  </tr>
                </thead>
                <tbody class="bg-light">
                  <tr
                    [style]=" indexEditing == index ?  'background-color: #ffdbb7;padding-top:5px;': 'padding-top:5px;'"
                    *ngFor="let item of lista; let index = index;">
                    <td class="text-dark" style="width:70px">
                      {{item.producto ? (item.producto.clave + " => " + item.producto.nombre) : '' }}
                    </td>
                    <td class="text-dark" style="width:70px">
                      {{item.unidad.nombre }}
                    </td>
                    <td class="text-dark" style="width:70px;padding-right:5px;">
                      {{item.cantidad > 0 ? (item.cantidad|number:'1.2-6') : '' }}
                    </td>
                    <td class="text-dark" style="width:70px;padding-right:5px;">
                      {{item.cantidadProducida|number:'1.2-6'}}
                    </td>
                    <td class="text-dark" style="width:70px;margin-right:5px;">
                      {{item.cantidadPendiente > 0 ? (item.cantidadPendiente|number:'1.2-6') : '' }}
                    </td>
                    <td style="width: 70px;">
                      <input (keydown)="keyDownCantidad($event)" (keydown.escape)="escapeCantidad($event)"
                        (keydown.arrowup)="up($event,5)" (keydown.arrowdown)="down($event,5)"
                        (keydown.enter)="enterCantidad($event)" class="grid-editor text-dark"
                        [id]="'txt_'+index+'_5' + idUnico" (blur)="blurCantidad($event)" type="number"
                        (focus)="focusCantidad(index, $event, item)"
                        [style]="'width:100%;text-align:right; padding-right:5px'"
                        [value]="item.producto ? item.producir: 0" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </ng-scrollbar>
          </div>
        </div>
      </app-panel-base>
      <app-panel-base title="Observaciones a imprimir" style="max-height: 150px;">
        <div class="panel-content">

          <textarea class="form-control" formControlName="Observaciones" rows="3" #txtDescription></textarea>

          <!-- <div class="NgxEditor__Wrapper border-1" style="border: 1px solid !important;text-align: left;">
            <ngx-editor-menu [editor]="editor"> </ngx-editor-menu>
            <ngx-editor [editor]="editor" [ngModel]="html" [disabled]="false" formControlName="Observaciones"
              [placeholder]="'Observaciones para incluir en la Orden de Producción.'"></ngx-editor>
          </div> -->
        </div>
      </app-panel-base>
    </form>
  </div>
  <div class="card-footer fw-bold">
    <div style="text-align: right; margin-top: 5px;" *ngIf="!generando">
      <div (click)="accept()" class="btn btn-success me-1">Procesar</div>
      <div (click)="closeModal()" class="btn btn-danger  me-1">Cerrar</div>
    </div>
  </div>
</div>
