import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, inject, OnInit, ViewChild } from '@angular/core';
import { ExistenciaAlmacenDTO } from 'src/app/Inventarios/interfaces/producto.interface';
import { ListaPrecioConsultaDTO } from '../../interfaces/listaprecio.interface';
import { ProveedorDTO } from 'src/app/compras/interfaces/proveedores.interface';
import { EventsService } from 'src/app/service/events.service';
import { SearchConfiguration } from 'src/app/service/interfaces/data-search.interface';
import { UserLogged } from 'src/app/auth/interfaces';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalService } from 'src/app/service/modal.service';
import { UtilsService } from 'src/app/service/utils.service';
import { FacturacionService } from '../../services/facturacion.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Cliente } from '../../interfaces/cliente.interface';
import { environment } from 'src/environments/environment';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';
import { HistoricoVenta } from '../../interfaces/venta.interface';

@Component({
  selector: 'app-info-general-cliente',
  templateUrl: './info-general-cliente-page.component.html',
  styleUrls: ['./info-general-cliente-page.component.scss']
})
export class InfoGeneralClientePageComponent implements OnInit {

  listaFacturas: HistoricoVenta[] = [];
  listaPrecios: ListaPrecioConsultaDTO[] = [];
  listaProveedores: ProveedorDTO[] = [];
  ultimaFechaCompra: string = "";
  existenciaGlobal: number = 0;
  private readonly baseUrl: string = environment.baseUrlApi;
  private eventsService = inject(EventsService);
  @ViewChild('busquedaProductos')
  public ctrlBusquedaProductos!: ElementRef<HTMLElement>;
  searchConfigurationCliente: SearchConfiguration | null = null;
  info: UserLogged = {} as UserLogged;

  public myForm: FormGroup = this.fb.group({
    Cliente: [null],
    Clave: [''],
    Nombre: [''],
    RFC: [''],
    UltimaFactura: [''],
    Vendedor: [''],
    Saldo: [0],
  });

  constructor(private fb: FormBuilder, private ms: ModalService, private utileService: UtilsService, private fs: FacturacionService, private http: HttpClient) {
  }

  get getCustomerColumns(): any {
    if (this.searchConfigurationCliente) {
      return this.searchConfigurationCliente.columns;
    }
    return [];
  }

  get getCustomerPropertys(): string {
    if (this.searchConfigurationCliente) {
      let props = "";
      this.searchConfigurationCliente.propertys.forEach((prop) => {
        props += `${prop.name}|${prop.type},`
      })

      if (props.length > 0) {
        props = props.substring(0, props.length - 1);
        return props;
      }

    }
    return "";
  }

  openCustomerSearch() {
    const b: any = this.ctrlBusquedaProductos;
    this.ms.openModal(b, (e: any) => {
      if (e) {
        //this.eventsService.publish('home:isLoading', { isLoading: true });

        //        this.loading = true;
        const params = new HttpParams().set("entidad", "Cliente").set("clave", e.Clave).set("filtro", "");
        this.http.get<Cliente>(`${this.baseUrl}/Base/ObtenerEntidadPorClave`, { params }).subscribe((cliente) => {
          if (cliente) {
            this.myForm.controls["Cliente"].setValue(cliente);
            this.myForm.controls["Clave"].setValue(cliente.Clave);
            this.myForm.controls["Nombre"].setValue(cliente.Nombre);
            this.myForm.controls["RFC"].setValue(cliente.Rfc);
            if (cliente.Vendedor) {
              this.myForm.controls["Vendedor"].setValue(cliente.Vendedor.Nombre);
            }

            // item.Cliente = cliente;
            // item.ClienteClave = cliente.Clave.toString();
            // item.ClienteNombre = cliente.Nombre;
          } else {
            this.eventsService.publish('home:showAlert', {
              textAccept: "Aceptar",
              cancelButton: false,
              message: "No se encontró el Cliente indicado.",
              onConfirm: (data: AlertResponse) => {
                this.myForm.controls["Cliente"].setValue(null);
                this.myForm.controls["Clave"].setValue(0);
                this.myForm.controls["Nombre"].setValue('');
                this.myForm.controls["RFC"].setValue('');
                this.myForm.controls["Vendedor"].setValue('');
                // this.source[index].ClienteClave = "";

                // this.source[index].ClienteNombre = "";
                // this.source[index].Cliente = null;
                // this.source = [...this.source];
                // this.initEditor(index, this.colCliente);
              }
            });
            return;
          }
        });

        // this.fs.buscarProducto(this.info.empresa!.numero, this.info.sucursal!.numero, 0, true, e.Clave).subscribe((producto) => {
        // this.fs.buscarCliente(this.info.empresa!.numero, this.info.sucursal!.numero, 0, true, e.Clave).subscribe((producto) => {
        //   if (producto) {
        //     this.myForm.controls["Producto"].setValue(producto);
        //     this.myForm.controls["Clave"].setValue(producto.Clave);
        //     this.myForm.controls["Nombre"].setValue(producto.Nombre);
        //     this.myForm.controls["CodigoBarras"].setValue(producto.CodigoBarras);
        //     this.myForm.controls["UnidadMedida"].setValue(producto.Unidad.Nombre);
        //     this.myForm.controls["UltimoCosto"].setValue(producto.UltimoCosto);
        //     this.fs.obtenerExistenciasSucursales(producto.Id).subscribe((lista) => {
        //       this.listaExistencias = lista;
        //       this.listaExistencias.forEach((i) => { this.existenciaGlobal += Number(i.existencia); })
        //       this.fs.obtenerPrecios(producto.Id).subscribe((precios) => {
        //         this.listaPrecios = precios;
        //         this.fs.obtenerProveedores(producto.Id).subscribe((proveedores) => {
        //           this.listaProveedores = proveedores;
        //           this.fs.obtenerUltimaFechaCompra(producto.Id).subscribe((uf) => {
        //             this.eventsService.publish('home:isLoading', { isLoading: false });
        //             this.ultimaFechaCompra = uf.message;
        //             this.rellenarGrids();
        //           });
        //         });
        //       });
        //     })
        //   }
        // });
      }
    }, 'xl')
  }

  rellenarGrids() {
    let totalExistencias = 0;
    let totalPrecios = 0;


    if (this.listaFacturas.length < 10) {
      while (totalExistencias <= 10) {
        this.listaFacturas.push({
          serie: '',
          folio: 0,
          sucursalNombre: '',
          documentoNombre: '',
          serieFolio: '',
          fechaEmision: new Date(),
          fechaCancelacion: new Date(),
          usuarioNombre: '',
          total: 0,
          idVenta: 0,
          vendedorNombre:''
        })
        totalExistencias++;
      }
    }

    if (this.listaPrecios.length < 10) {
      while (totalPrecios <= 10) {
        this.listaPrecios.push({
          listaclave: -1,
          listaNombre: "",
          divisor: -1,
          costoReposicion: -1,
          precioMinimo: -1,
          precioMaximo: -1,
        })
        totalPrecios++;
      }
    }
  }

  ngOnInit(): void {
    this.rellenarGrids();
    this.info = this.utileService.getUserLogged();
    this.searchConfigurationCliente = this.ms.GetSearchConfiguration("Cliente", `Empresa.Id = ${this.info.empresa!.numero}`);
    setTimeout(() => {
      this.openCustomerSearch();
    }, 150);
  }



}
