import { Sucursal } from 'src/app/configuracion/interfaces/sucursal.interface';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild, HostListener, inject, AfterContentChecked, isDevMode, QueryList } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GuiCellEdit, GuiCellView, GuiColumnAlign, GuiDataType, GuiInfoPanel, GuiRowDetail, GuiRowStyle, GuiSorting } from '@generic-ui/ngx-grid';
import { ComboBoxEntity, Coordinates } from 'src/app/component-ui/interfaces/combo-text.interface';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';

import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { Empresa } from '../../../configuracion/interfaces/empresa.interface';
import { AnticipoDisponibleDTO, CfdiEstadoSAT, CfdiRelacionado, Consignado, ConsultaHistoricoResguardo, ConsultaSaldoResguardo, ExistenciaProductoPaquete, FacturaAnticipoRelacionada, InvoicesStatus, Venta, VentaConcepto, VentaConceptoImpuesto, VentaFormaPago, VentaFormulacion, c_EstadoSAT, c_MotivoCancelacion } from '../../interfaces/venta.interface';
import { ClaseVenta, MetodoPago } from '../../interfaces/claseventa.interface';
import { SerieVenta } from '../../interfaces/serieventa.interface';
import { NumberBoxComponent } from 'src/app/component-ui/components/number-box/number-box.component';

import { c_UsoCfdi, Cliente } from '../../interfaces/cliente.interface';
import { CondicionPago } from '../../interfaces/condicionpago.interface';
import { Direccion } from 'src/app/configuracion/interfaces/direccion.interface';

import { Usuario } from 'src/app/configuracion/interfaces/usuario.interface';
import { MapType } from '@angular/compiler';
import { Producto, ProductoUnidad } from 'src/app/Inventarios/interfaces/producto.interface';
import { ModalService } from 'src/app/service/modal.service';
import { SearchConfiguration } from 'src/app/service/interfaces/data-search.interface';
import { Result, UserLogged } from 'src/app/auth/interfaces';
import { FacturacionService } from '../../services/facturacion.service';
import { c_FormaPago } from '../../interfaces/formapago.interface';
import { EventsService } from 'src/app/service/events.service';
import * as moment from 'moment';
import { Moneda } from 'src/app/configuracion/interfaces/moneda.interface';
import { LimitesService } from '../../services/sat.services';
import { UtilsService } from 'src/app/service/utils.service';
import { DocumentoVenta } from '../../interfaces/documentoventa.interface';
import { BaseService } from '../../../service/base.service';
import { Caja } from '../../interfaces/caja.interface';
import { ConfiguracionVenta } from '../../interfaces/configventa.interface';
import { ResguardoService } from 'src/app/resguardos/services/resguardo.servuice';
import { AppMenuService } from 'src/app/home/services/app-menus.service';
import { PanelBaseDropDownMenu } from 'src/app/component-ui/interfaces/panelbase.interface';
import { CartaPorte } from '../../interfaces/cartaporte.interface';
import { CartaPorteService } from '../../services/cartaporte.service';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';
import { Unidad } from '../../interfaces/unidad.interface';
import { Chofer } from '../../interfaces/chofer.interface';
import { ColumnaGrid } from 'src/app/component-ui/interfaces/grid.interface';
import { AppSettings } from 'src/app/home/services/app-settings.service';
import { PrecioDTO } from '../../interfaces/precio.interface';
import { PrinterService } from '../../services/printer.service';
import { TabsNavService } from 'src/app/components/services/tabs-nav.service';
import { EventImpl } from '@fullcalendar/core/internal';
import { trim } from 'lodash';
import { EntregaNuestra } from '../../interfaces/cargaentrega.interface';
import { FilterOptions, ReportFilter, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ReportsService } from 'src/app/service/reports.service';
import { id } from '@swimlane/ngx-charts';
import { Diseno } from 'src/app/Manufactura/interfaces/manufacura.interface';
import { ListadoChequesPostfechadosPageComponent } from '../../../cuentas-por-cobrar/pages/reports/listado-cheques-postfechados-page/listado-cheques-postfechados-page.component';

@Component({
  selector: 'app-facturacion-page',
  templateUrl: './facturacion-page.component.html',
  //styleUrls: ['./facturacion-page.component.scss']
})
export class FacturacionPageComponent implements OnInit {
  private baseService = inject(BaseService);
  private printerService = inject(PrinterService);
  public appSettings = inject(AppSettings);
  private tabsNavService = inject(TabsNavService);
  info: UserLogged = {} as UserLogged;
  anticipoDisponible: number = 0;
  abriendoClientes: boolean = false;
  listaHistoricoRemisiones: ConsultaHistoricoResguardo[] = [];
  empresaSucursalEstimulo: boolean = false;
  esEscapeProducto: boolean = false;
  esEnterProducto: boolean = false;
  esTicket: boolean = false;
  nombreConsignado: string = "";
  idDisenoBase: number = 0;
  listaSaldosEntregar: ConsultaSaldoResguardo[] = [];
  uuidsRelacionadosHijos: InvoicesStatus[] = [];
  uuidsRelacionadosPadres: InvoicesStatus[] = [];
  menuItems: PanelBaseDropDownMenu[] = [
    { Id: 'historico', Nombre: 'Histórico Ventas' },
    { Id: 'saldos', Nombre: 'Saldos CXC' }
  ]
  listaComprobantes: ComboBoxEntity[] = [];
  listaCajas: ComboBoxEntity[] = [];
  listaSeries: ComboBoxEntity[] = [];
  conversiones: ProductoUnidad[] = [];
  existencia: number = 0;
  mascara: string = "";
  esMonedaExtranjera: boolean = false;
  enviarFocoPrecio: boolean = false;
  permisoCambiarVendedor: boolean = false;
  permisoCambiarCuentaVenta: boolean = false;
  permisoCambiarEntrega: boolean = false;
  enviarFocoDescuento: boolean = false;
  enviarFocoCantidad: boolean = false;
  enviarOtroRenglon: boolean = false;
  busquedaAbierta: boolean = false;
  solicitandoToken: boolean = false;
  statusImpresora: string = "Sin Iniciar";
  statusImpresoraColor: string = "bg-red";
  unidadFinal: string = '';
  idUnico: string = '';
  idsDisenosSeleccionados: string = '';
  sourceProducts: any[] = [];
  sourcePrecios: PrecioDTO[] = [];
  cords: any = null;
  activeButtons: ActiveButtons = {
    new: true,
    delete: false,
    return: false,
    save: true,
    first: true,
    left: true,
    right: true,
    last: true,
    search: false,
    print: true,
  }

  private eventsService = inject(EventsService);
  private menuService = inject(AppMenuService);
  private reportsService = inject(ReportsService);
  private readonly baseUrl: string = environment.baseUrlApi;

  ventaFormulacion: VentaFormulacion | null = null;
  blockCombos: boolean = false;
  address: string = "";
  saving: boolean = false;
  prcentajeAvanceResguardo: number = 0;
  showDescription: boolean = false;
  esManufactura: boolean = false;
  utilizaSegundoNombre: boolean = false;
  mostrarEntregaNuestra: boolean = false;
  mostrarListas: boolean = false;
  loading: boolean = false;
  listaOrdenes: any[] = [];
  estadoSAT: CfdiEstadoSAT | null = null;
  documentosPuedeCopiar: string = '';
  idsSeleccionados = "";
  configuracion: ConfiguracionVenta | null = null;
  rowEditandoDescricionAdic: number = 0;
  selectedIndex: number = -1;
  envio: any;
  _isDevMode: boolean = true;
  tipoComentario: number = 0;
  cargandoInicio: boolean = false;
  @ViewChild('txtCliente')
  public txtCliente!: ElementRef<NumberBoxComponent>;
  @ViewChild('txtChofer')
  public txtChofer!: ElementRef<NumberBoxComponent>;
  @ViewChild('cboConsignado')
  public cboConsignado!: ElementRef<NumberBoxComponent>;
  @ViewChild('ctrlConversiones')
  public ctrlConversiones!: ElementRef<HTMLElement>;
  @ViewChild('anticiposDisponibles')
  public anticiposDisponibles!: ElementRef<HTMLElement>;
  @ViewChild('ctrlExistencias')
  public ctrlExistencias!: ElementRef<HTMLElement>;
  @ViewChild('ctrlFormulacion')
  public ctrlFormulacion!: ElementRef<HTMLElement>;
  @ViewChild('ctrlHistorico')
  public ctrlHistorico!: ElementRef<HTMLElement>;
  @ViewChild('busquedaProductos')
  public ctrlBusquedaProductos!: ElementRef<HTMLElement>;
  @ViewChild('ctrlNotaCredito')
  public ctrlNotaCredito!: ElementRef<HTMLElement>;
  @ViewChild('content')
  public ctrlBusqueda!: ElementRef<HTMLElement>;
  @ViewChild('modalFormaPago')
  public ctrlFormaPago!: ElementRef<HTMLElement>;
  @ViewChild('modalCartaPorte')
  public modalCartaPorte!: ElementRef<HTMLElement>;
  @ViewChild('modalDireccion')
  public modalDireccion!: ElementRef<HTMLElement>;
  @ViewChild('txtDescription')
  public txtDescription!: ElementRef<HTMLElement>;
  @ViewChild('cboComp')
  public cboComp!: ElementRef<any>;
  @ViewChild('cboClase')
  public cboClase!: ElementRef<any>;
  @ViewChild('cboSerie')
  public cboSerie!: ElementRef<any>;
  @ViewChild('modalCancelOptiones')
  public modalCancelOptiones!: ElementRef<HTMLElement>;
  @ViewChild('modalRelacionados')
  public modalRelacionados!: ElementRef<HTMLElement>;
  @ViewChild('modalConisgnado')
  public modalConisgnado!: ElementRef<HTMLElement>;
  @ViewChild('cfdiRelacionado')
  public cfdiRelacionado!: ElementRef<HTMLElement>;
  @ViewChild('comentarioGlobal')
  public comentarioGlobal!: ElementRef<HTMLElement>;
  @ViewChild('copiarComprobante')
  public copiarComprobante!: ElementRef<HTMLElement>;
  @ViewChild('ctrlCatClientes')
  public ctrlCatClientes!: ElementRef<HTMLElement>;
  @ViewChild('modalRelaciones')
  public modalRelaciones!: ElementRef<HTMLElement>;
  @ViewChild('ctrlHistRemisiones')
  public ctrlHistRemisiones!: ElementRef<HTMLElement>;
  @ViewChild('ctrlSaldosResguardo')
  public ctrlSaldosResguardo!: ElementRef<HTMLElement>;
  @ViewChild('ctrlCambiarVendedor')
  public ctrlCambiarVendedor!: ElementRef<HTMLElement>;
  @ViewChild('ctrlCambiarCuenta')
  public ctrlCambiarCuenta!: ElementRef<HTMLElement>;
  @ViewChild('ctrlDisenos')
  public ctrlDisenos!: ElementRef<HTMLElement>;
  @ViewChild('ctrlListado')
  public ctrlListado!: ElementRef<HTMLElement>;
  @ViewChild('modalArchivarFactura')
  public modalArchivarFactura!: ElementRef<ElementRef>;




  public myForm: FormGroup = this.fb.group({
    Id: [0],
    Caja: [{} as Caja],
    Empresa: [{} as Empresa, Validators.required],
    Sucursal: [{} as Sucursal, Validators.required],
    DocumentoVenta: [{} as DocumentoVenta, Validators.required],
    Serie: [{} as SerieVenta, Validators.required],
    Consignado: [{} as Consignado],
    SerieString: [''],
    Folio: [0],
    OrdenCompra: [''],
    OrdenCompraFecha: [null],
    CartaPorteId: [0],
    Direccion: [{} as Direccion],
    FechaEmision: [new Date()],
    FechaEmisionString: [''],
    FechaVencimiento: [new Date()],
    FechaCompromisoEntrega: [null],
    FechaCancelacion: [],
    FormasPago: [{} as c_FormaPago[]],
    Vehiculo: [{} as Unidad],
    Chofer: [{} as Chofer],
    Cliente: [{} as Cliente],
    CartaPorte: [null],
    CartaPorteString: [''],
    ComentarioSolicitudCancelacion: [''],
    Clase: [{} as ClaseVenta, Validators.required],
    DescripcionAdicional: [''],
    MetodoPago: [{} as MetodoPago],
    UsoCfdi: [{} as c_UsoCfdi],
    Condicion: [{} as CondicionPago],
    Vendedor: [{} as Usuario],
    UsuarioModifica: [{} as Usuario],
    Cajero: [{} as Usuario],
    EstadoSAT: [{} as c_EstadoSAT],
    Impreso: [false],
    EntregaNuestra: [false],
    TieneTokenCredito: [false],
    CanceloDecretoIVA: [false],
    ArchivarCargaEntrega: [false],
    FechaProgramadaEnvio: [null],
    ComentarioEnvio: [''],
    SuspendioCreditoAlFacturar: [false],
    SubTotal: [0],
    UsuarioAlta: [{} as Usuario],
    UsuarioCancela: [{} as Usuario],
    SubTotalAntesDescuento: [0],
    SubTotalNotaCredito: [0],
    SubTotalGrabaIvaTrasladado: [0],
    SubTotalGrabaIvaRetenido: [0],
    SubTotalGrabaIsrRetenido: [0],
    SubTotalGrabaIeps: [0],
    SubTotalGrabaIvaCero: [0],
    SubTotalGrabaIvaExento: [0],
    PorcentajeUtilidad: [0],
    ImporteUtilidad: [0],
    PorcentajeUtilidadInventario: [0],
    ImporteUtilidadInventario: [0],
    TotalCosto: [0],
    TotalCostoInventario: [0],
    Total: [0],
    TotalAntesDescuento: [0],
    TotalDescuento: [0],
    TotalReciboPago: [0],
    TotalNotaCredito: [0],
    TotalIvaTrasladado: [0],
    TotalIvaRetenido: [0],
    TotalIsrRetenido: [0],
    TotalIeps: [0],
    TotalAnticipoAplicado: [0],
    IdOrdenManufactura: [0],
    DocumentoVentaOrigina: [null],
    SerieOriginaNotaCredito: [""],
    FolioOriginaNotaCredito: [0],
    TotalImpuestosTrasladados: [0],
    MotivoCancelacion: [''],
    TieneSolicitudCancelacion: [false],
    TieneSolicitudCancelacionAceptada: [false],
    TieneSolicitudCancelacionRechazada: [false],
    TipoNotaCredito: [null],
    MotivoCancelacionSAT: [{} as c_MotivoCancelacion],
    CfdisRelacionados: [{} as CfdiRelacionado[]],
    FacturasAnticipos: [{} as FacturaAnticipoRelacionada[]],
    UUID: [''],
    Observaciones: [''],
    ObservacionesCargaEntrega: [''],
    Moneda: [{} as Moneda],
    TipoCambio: [0],
    EsDevolucion: [false],
    EsBonificacion: [false],
    EsFacturaGlobal: [false],
    EnFacturaGlobal: [false],
    EnFacturaNormal: [false],
    EsFacturaAnticipo: [false],
    EsNotaCreditoAnticipo: [false],
    EnviadoWebTicket: [false],
    EsResguardo: [false],
    IdFacturaNormal: [0],
    IdFacturaGlobal: [0],
    IdNotaCreditoAnticipo: [0],
    IdsCopiados: [''],
    Vigente: [false],
    TipoRelacionNotaCredito: [null],
    UUIDOriginaNotaCredito: [''],
    SucursalOriginaNotaCredito: [null],
    EsFacturaMigrada: [false],
    AfectaNotaCredito: [[]]
  })




  constructor(
    private fServices: FacturacionService,
    private rServices: ResguardoService,
    private cpService: CartaPorteService,
    private fb: FormBuilder,
    private cService: ContainerBaseService,
    private http: HttpClient,
    private ms: ModalService,
    private satService: LimitesService,
    private utileService: UtilsService,) {
  }

  sorting: GuiSorting = {
    enabled: true
  };

  columnas: ColumnaGrid[] = [
    {
      titulo: '', width: 50, esEditor: false, tipoEditor: 'text', enabled: true, render: (row: any) => {
        return '';
      }
    },
    { titulo: 'Producto', width: 100, esEditor: true, tipoEditor: 'text', enabled: true, render: (row: any) => { } },
    { titulo: 'Descripción', width: 200, esEditor: false, tipoEditor: 'text', enabled: true, render: (row: any) => { } },
    { titulo: 'Cantidad', width: 100, esEditor: true, tipoEditor: 'number', enabled: true, render: (row: any) => { } },
    { titulo: 'Unidad', width: 100, esEditor: false, tipoEditor: 'text', enabled: true, render: (row: any) => { } },
    { titulo: 'Conversión', width: 100, esEditor: false, tipoEditor: 'text', enabled: true, render: (row: any) => { } },
    { titulo: 'Precio', width: 60, esEditor: true, tipoEditor: 'number', enabled: true, render: (row: any) => { } },
    { titulo: 'Desto', width: 60, esEditor: true, tipoEditor: 'number', enabled: false, render: (row: any) => { } },
    { titulo: 'Paquete', width: 100, esEditor: false, tipoEditor: 'text', enabled: true, render: (row: any) => { } },
    { titulo: 'Subtotal', width: 100, esEditor: false, tipoEditor: 'text', enabled: true, render: (row: any) => { } },
  ];

  public GuiColumnAlign = GuiColumnAlign;
  public GuiCellView = GuiCellView;
  public GuiDataType = GuiDataType;
  searchConfigurationCliente: SearchConfiguration | null = null;
  searchConfigurationProducto: SearchConfiguration | null = null;
  indexEditing: number = -1;
  columnEditing: number = -1;
  navigateColumns: boolean = false;
  numeroTokens: number = 0;





  /*Grid events */
  cellEditing: GuiCellEdit = {
    enabled: true,
    rowEdit: (value: any, item: VentaConcepto, index: number) => {
      this.indexEditing = -1;

      const countAnticipo = this.getCountAnticipo();
      if (!item.NoIdentificacion && countAnticipo == 1) {
        return false;
      }

      if (this.myForm.value.FechaCancelacion) {
        return false;
      }

      if (this.myForm.value.EnFacturaGlobal || this.myForm.value.EnFacturaNormal) {
        this.eventsService.publish('home:showAlert', { message: "Este ticket ya fue facturado, no se puede modificar.", cancelButton: false });
        return false;
      }


      if (this.myForm.value.Cliente?.Id == 0 || !this.myForm.value.Cliente) {
        this.eventsService.publish('home:showAlert', {
          message: "Primero indique un Cliente.", cancelButton: false, onConfirm: (data: AlertResponse) => {
            this.focusCliente();
          }
        });
        return false;
      }




      if (index > 0) {
        if (!this.source[index - 1].Producto) {
          return false;
        }
        if (this.source[index - 1].Producto!.Id == 0) {
          return false;
        }
      }

      const docto: ComboBoxEntity = this.listaComprobantes.filter(P => P.Id == this.myForm.value.DocumentoVenta.Id)[0];
      if (docto.ObligarCopiar) {
        if (!this.source[index].Producto || this.source[index].Producto?.Id == 0) {
          this.eventsService.publish('home:showAlert', { message: "No se pueden agregar Productos, debe de copiar.", cancelButton: false });
          return false;
        }
      }



      this.indexEditing = index;

      if (index == 0) {
        return true;
      }

      return true;
    },

    cellEdit: (value: any, item: any, index: number) => {

      // setTimeout(() => {
      //   console.log({
      //     value,
      //     item,
      //     index,
      //     column: this.columnEditing
      //   })
      // }, 101);

      return true;
    }
  }

  source: Array<VentaConcepto> = [];

  changueDescription(i: number, item: VentaConcepto) {
    if (item.Producto?.ActualizarDescrpcion) {
      this.indexEditing = i;
      this.rowEditandoDescricionAdic = i;
      this.myForm.controls["DescripcionAdicional"].setValue(item.Descripcion);
      this.showDescription = true;
      setTimeout(() => {
        this.txtDescription.nativeElement.focus();
      }, 150);
    }
  }

  focusCliente() {
    const txt: any = this.txtCliente;
    txt.tagInput.nativeElement.focus()
  }

  /*Nuevos eventos de uso del grid*/

  escapeProducto(e: any) {
    let index = this.indexEditing;
    setTimeout(() => {
      this.esEscapeProducto = true;
      const item = { ...this.source[index] };
      if (!item.Producto) {
        e.target.value = "";
      } else {
        e.target.value = item.Producto.Clave;
      }
      if (index > 0) {
        this.indexEditing = index - 1;

        this.initEditor(this.indexEditing, 1);

      } else {
        const txt: any = this.txtChofer;
        txt.tagInput.nativeElement.focus()
      }
    }, 50);
  }

  blurProducto(e: any) {
    let index = this.indexEditing;
    e.target.classList.remove(this.getEditorClass());

    setTimeout(() => {
      this.cords = null;
      if (this.esEscapeProducto) {
        this.esEnterProducto = false;
        this.esEscapeProducto = false;
        return;
      }
      let item = { ...this.source[index] };
      if (item.Producto) {

        if (!e.target.value) {
          e.target.value = item.Producto.Clave;
          setTimeout(() => {
            this.initEditor(index, 1);
          }, 150);
          return;
        }

        if (item.Producto.Clave != e.target.value) {
          this.searchProduct(e.target.value, index);
        } else {
          if (item.Producto.ActivaFormulacion && item.Producto.ActivaFormulacionPieCubico && !this.esEscapeProducto && this.esEnterProducto) {
            this.openFormulacion();
          } else if (this.enviarFocoCantidad) {
            this.enviarFocoCantidad = false;
            this.initEditor(index, 3);
          }
        }
      } else {
        this.searchProduct(e.target.value, index);
      }
      this.esEnterProducto = false;
      this.esEscapeProducto = false;
    }, 100);

  }


  blurCantidad(e: any) {

    e.target.classList.remove(this.getEditorClass());

    const cantidad = parseFloat(e.target.value);
    let item = this.source[this.indexEditing];
    if (parseFloat(String(item.Cantidad)) == cantidad) {
      if (this.enviarFocoPrecio) {
        this.enviarFocoPrecio = false;
        this.initEditor(this.indexEditing, 6);
      }
      return;
    };
    if (item.Producto?.EsParaAnticipo) {
      item.Cantidad = 1;
    }

    if (item.Conversiones) {
      if (item.Conversiones.length > 0) {
        let totalEnConversiones = 0;
        item.Conversiones.forEach((i) => totalEnConversiones += i.CantidadUnidadConcepto);
        if (cantidad != totalEnConversiones) {
          item.Cantidad = item.Cantidad;
        } else {
          item.Cantidad = cantidad;
        }
      } else {
        if (!item.Producto?.EsParaAnticipo) {
          item.Cantidad = cantidad;
        }
        item.Conversiones = [];
        item.TextoConversiones = "";
      }
    } else {
      if (!item.Producto?.EsParaAnticipo) {
        item.Cantidad = cantidad;
      }
      item.Conversiones = [];
      item.TextoConversiones = "";
    }
    this.source[this.indexEditing] = { ...item };
    this.source = [...this.source];
    setTimeout(() => {
      this.modificarDisenos(null, false);
      this.calcTax(true);
    }, 50);
  }

  enterCantidad(e: any) {
    let cant: number = 0;
    if (e.target.value) {
      cant = parseFloat(e.target.value);
    }
    if (cant == 0) {
      e.preventDefault();
      return;
    }

    this.enviarFocoPrecio = true;
    e.target.blur();
  }

  enterPrecio(e: any) {
    this.enviarFocoDescuento = true;
    e.target.blur();
  }

  enterDescuento(e: any) {
    this.enviarOtroRenglon = true;
    e.target.blur();
  }

  escapeDescuento() {
    this.initEditor(this.indexEditing, 6);
  }

  keyDownCantidad(e: any) {
    const noDecimales: boolean = this.source[this.indexEditing].Producto!.NoDecimalesCantidad;
    if (e.key === '.' && noDecimales) { e.preventDefault(); }
  }

  escapeCantidad() {
    this.initEditor(this.indexEditing, 1);
  }

  up(e: any, celda: number) {
    if (this.sourceProducts.length > 0) {
      let row = null;
      if (e.keyCode == "38") {
        e.preventDefault();
        if (this.selectedIndex > 0) {
          this.selectedIndex = this.selectedIndex - 1;
          row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`)
        }
      }
      if (row) {
        row.scrollIntoView({ block: "center" });
      }
      return;
    }
    e.preventDefault();
    if (this.indexEditing > 0) {
      if (celda == 0) {
        this.esEscapeProducto = true;
      }
      e.target.blur();
      setTimeout(() => {
        this.indexEditing = this.indexEditing - 1;
        this.initEditor(this.indexEditing, celda + 1);
        this.esEscapeProducto = false;
      }, 50);
    } else {
      this.initEditor(this.indexEditing, 1);
    }
  }

  getLastItem(): number {
    return this.source.filter(P => P.Producto && P.Cantidad > 0).length;
  }


  down(e: any, celda: number) {
    e.preventDefault();

    if (this.sourceProducts.length > 0) {
      let row = null;
      if (e.keyCode == "40") {
        e.preventDefault();
        if (this.selectedIndex < this.sourceProducts.length - 1) {
          this.selectedIndex = this.selectedIndex + 1;
          row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`)
        }
      }
      if (row) {
        row.scrollIntoView({ block: "center" });
      }
      return;

    }


    let c = this.getLastItem();
    if (this.indexEditing < c - 1) {
      e.target.blur();
      setTimeout(() => {
        this.indexEditing = this.indexEditing + 1;
        this.initEditor(this.indexEditing, celda + 1);
      }, 50);
    } else {
      if (this.indexEditing + 1 < c + 1) {
        this.esEscapeProducto = true;
        this.indexEditing = this.indexEditing + 1;
        this.initEditor(this.indexEditing, 1);
      }
    }
  }

  getOffset(elem: HTMLInputElement): Coordinates {
    var box = elem.getBoundingClientRect();
    var left = window.scrollX !== undefined ? window.scrollX :
      (document.documentElement || document.body.parentNode || document.body).scrollLeft;
    var top = window.scrollY !== undefined ? window.scrollY :
      (document.documentElement || document.body.parentNode || document.body).scrollTop;

    top += elem.offsetHeight;
    return { left: box.left + left, top: box.top + top };
  }

  escapePrecio() {
    this.initEditor(this.indexEditing, 3);
  }

  keyDownRow(e: any, index: number) {
    // if (e.ctrlKey && e.keyCode == "46") {
    //   e.preventDefault();
    //   this.deleteRow(index);
    // }
  }


  clicTabla(e: any, item: any) {
    const t: any = document.activeElement;
    t.value = item.Clave;
    const input: any = document.getElementById(`txt_${this.indexEditing}_0${this.idUnico}`)!;
    if (input) {
      input.value = item.Clave;
      this.enviarFocoCantidad = true;
    }
  }


  buscarProductos(e: any) {
    if (e.target.value == "" || !isNaN(e.target.value.replace(/\s/g, ""))) {
      this.cords = null;
      this.sourceProducts = [];
      return;
    }

    if (this.selectedIndex == -1) {
      this.selectedIndex = 0;
    }

    if (e.keyCode == "27" || e.keyCode == "37" || e.keyCode == "39" || e.keyCode == "38" || e.keyCode == "40") {
      return;
    }
    let cords = this.getOffset(e.target);
    cords.top = cords.top - 400;

    if (this.appSettings.appSidebarMinified) {
      cords.left = cords.left + 25;
    } else {
      cords.left = cords.left - 290;
    }
    this.cords = cords;

    if (!this.myForm.value.Cliente) { return }
    let row = null;
    this.fServices.busquedaProductos(e.target.value, this.myForm.value.Cliente.Id).subscribe((result) => {
      const lista = JSON.parse(result.message);
      this.sourceProducts = lista;
      if (this.sourceProducts.length > 0) {
        this.selectedIndex = 0;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`);
        if (row) {
          row.scrollIntoView({ block: "center" });
        }
      } else {
        this.selectedIndex = -1;
      }
    });
  }

  keyDownProducto(e: any) {
    if (e.keyCode == 9) {
      e.preventDefault();
      this.enterProducto(e);
    }
  }

  enterProducto(e: any) {
    this.esEnterProducto = true;
    this.enviarFocoCantidad = true;
    let value = '';
    if (this.sourceProducts.length > 0) {
      value = this.sourceProducts[this.selectedIndex].Clave;
    } else {
      value = e.target.value;
    }
    e.target.value = value;
    if (value) {
      e.target.blur();
    }
  }

  blurDescuento(e: any) {
    e.target.classList.remove(this.getEditorClass());
    let descuento = parseFloat(e.target.value);
    let item = { ...this.source[this.indexEditing] };
    if (!item.Producto) { return; }
    if (descuento != item.DescuentoPorcentaje) {
      let min: number = this.source[this.indexEditing].Producto!.PrecioMinimo;
      let precioActual = item.Producto!.Precio > 0 ? item.Producto!.Precio : item.ValorUnitario;
      if (item.ValorUnitarioAntesDescuento > 0) {
        precioActual = item.ValorUnitarioAntesDescuento;
      }
      let valUnitario: number = 0;
      let valorUnitarioAntesDescuento = 0;
      let porcentajeDescuento: number = 0;
      let importeDescuento: number = 0;
      if (descuento > 0) {
        if (this.configuracion!.MaximoDescuento > 0) {
          if (descuento > this.configuracion!.MaximoDescuento) {
            descuento = this.configuracion!.MaximoDescuento;
          }
          if (item.Producto.MaximoPorcentajeDescuento > 0) {
            if (descuento > item.Producto.MaximoPorcentajeDescuento) {
              descuento = item.Producto.MaximoPorcentajeDescuento;
            }
          }
        }
        valorUnitarioAntesDescuento = precioActual;
        porcentajeDescuento = descuento;
        valUnitario = parseFloat((precioActual * (1 - (descuento / 100))).toFixed(2));
        importeDescuento = precioActual - valUnitario;
      } else {
        valorUnitarioAntesDescuento = 0;
        valUnitario = item.ValorUnitarioAntesDescuento;
        porcentajeDescuento = 0;
        importeDescuento = 0;
      }
      if (valUnitario < min) {
        valUnitario = precioActual;
        porcentajeDescuento = 0;
        importeDescuento = 0;
      }
      this.source[this.indexEditing].ValorUnitario = valUnitario;
      this.source[this.indexEditing].ValorUnitarioAntesDescuento = valorUnitarioAntesDescuento;
      e.target.value = porcentajeDescuento;
      this.source[this.indexEditing].DescuentoPorcentaje = porcentajeDescuento;
      this.source[this.indexEditing].DescuentoImporte = importeDescuento;
      this.source = [...this.source];

      this.calcTax(true);

    } else {
      if (this.enviarOtroRenglon) {
        this.indexEditing = this.indexEditing + 1;
        this.initEditor(this.indexEditing, 1);
        this.enviarOtroRenglon = false;
      }
    }

  }

  blurPrecio(e: any) {
    e.target.classList.remove(this.getEditorClass());

    let precio = parseFloat(e.target.value);
    if (!precio) {
      e.target.value = "0.00"
      precio = 0;
    }
    let item = { ...this.source[this.indexEditing] };
    if (item.ValorUnitario == precio) {
      if (this.enviarFocoDescuento) {
        this.enviarFocoDescuento = false;
        if (!this.configuracion?.NoModificarDescuento) {
          this.initEditor(this.indexEditing, 7);
        } else {
          this.indexEditing = this.indexEditing + 1;
          this.initEditor(this.indexEditing, 1);
        }
      }
      return;
    }

    if (item.Producto) {
      let min: number = item.Producto!.PrecioMinimo;
      let precioOriginal: number = item.Producto!.Precio;
      let costoRep: number = item.Producto!.UltimoCosto;
      let porcentajeDescuento: number = 0;
      let importeDescuento: number = 0;
      if (precio < min) {
        this.solicitandoToken = true;
        if (precio > costoRep) {
          //Se solicita tonen para precio normal
          this.eventsService.publish('home:showAlert', {
            icon: 'fa-lock text-yellow',
            message: 'Se requiere token para vender abajo del Precio Mínimo, COMPLIT buscará token disponible para ti, NO ocupas ingresarlos de forma manual, presiona Aceptar para buscar Token.',
            onConfirm: (r: AlertResponse) => {
              this.solicitandoToken = false;
              if (r.isAccept) {
                this.eventsService.publish('home:isLoading', { isLoading: true });
                this.fServices.getTokenPrecio(precioOriginal, precio, precioOriginal - precio, `${this.myForm.value.SerieString}${this.myForm.value.Folio}`).subscribe((token) => {
                  this.eventsService.publish('home:isLoading', { isLoading: false });
                  if (token) {
                    this.getTokens();
                    item.TieneToken = true;
                    item.IdToken = token.id;

                    item.ValorUnitario = precio;
                    let dif: number = precioOriginal - precio;
                    porcentajeDescuento = parseFloat(((dif / precioOriginal) * 100).toFixed(2));
                    importeDescuento = precioOriginal - parseFloat((precioOriginal * ((100 - (porcentajeDescuento)) / 100)).toFixed(2));
                    importeDescuento = parseFloat(importeDescuento.toFixed(2));
                    item.DescuentoPorcentaje = porcentajeDescuento;
                    item.ImporteAntesDescuento = importeDescuento;
                    this.source[this.indexEditing] = { ...item };
                    this.source = [...this.source];
                    this.calcTax(true);

                    this.indexEditing = this.indexEditing + 1
                    setTimeout(() => { this.initEditor(this.indexEditing, 1); }, 70);
                  } else {
                    this.eventsService.publish('home:showAlert', {
                      message: "No se encontró ningún token para usar.", cancelButton: false, onConfirm: (data: AlertResponse) => {
                        item.ValorUnitario = min;
                        item.TieneToken = false;
                        item.IdToken = 0;
                        let dif: number = precioOriginal - min;
                        porcentajeDescuento = parseFloat(((dif / precioOriginal) * 100).toFixed(2));
                        importeDescuento = precioOriginal - parseFloat((precioOriginal * ((100 - (porcentajeDescuento)) / 100)).toFixed(2));
                        importeDescuento = parseFloat(importeDescuento.toFixed(2));
                        item.DescuentoPorcentaje = porcentajeDescuento;
                        item.ImporteAntesDescuento = importeDescuento;
                        this.source[this.indexEditing] = { ...item };
                        this.source = [...this.source];
                        this.calcTax(true);
                        setTimeout(() => { this.initEditor(this.indexEditing, 7); }, 50);
                      }
                    });
                  }
                });
              } else {
                item.ValorUnitario = min;
                item.TieneToken = false;
                item.IdToken = 0;
                let dif: number = precioOriginal - min;
                porcentajeDescuento = parseFloat(((dif / precioOriginal) * 100).toFixed(2));
                importeDescuento = precioOriginal - parseFloat((precioOriginal * ((100 - (porcentajeDescuento)) / 100)).toFixed(2));
                importeDescuento = parseFloat(importeDescuento.toFixed(2));
                item.DescuentoPorcentaje = porcentajeDescuento;
                item.ImporteAntesDescuento = importeDescuento;
                this.source[this.indexEditing] = { ...item };
                this.source = [...this.source];
                this.calcTax(true);
                setTimeout(() => { this.initEditor(this.indexEditing, 7); }, 50);
              }
            }
          });
          return;
        } else {
          //Se solicita tonen para precio abajo del COSTO
          this.eventsService.publish('home:showAlert', {
            icon: 'fa-lock text-yellow',
            message: 'Se requiere token para vender abajo o igual al costo de reposición, COMPLIT buscará token disponibles para ti, NO ocupas ingresarlos de forma manual, presiona Aceptar para buscar Token.',
            onConfirm: (r: AlertResponse) => {
              this.solicitandoToken = false;
              if (r.isAccept) {


                this.eventsService.publish('home:isLoading', { isLoading: true });
                this.fServices.getTokenCosto(precioOriginal, precio, precioOriginal - precio, `${this.myForm.value.SerieString}${this.myForm.value.Folio}`).subscribe((token) => {
                  this.eventsService.publish('home:isLoading', { isLoading: false });
                  if (token) {
                    this.getTokens();
                    item.TieneToken = true;
                    item.IdToken = token.id;

                    item.ValorUnitario = precio;
                    let dif: number = precioOriginal - precio;
                    porcentajeDescuento = parseFloat(((dif / precioOriginal) * 100).toFixed(2));
                    importeDescuento = precioOriginal - parseFloat((precioOriginal * ((100 - (porcentajeDescuento)) / 100)).toFixed(2));
                    importeDescuento = parseFloat(importeDescuento.toFixed(2));
                    item.DescuentoPorcentaje = porcentajeDescuento;
                    item.ImporteAntesDescuento = importeDescuento;
                    this.source[this.indexEditing] = { ...item };
                    this.source = [...this.source];
                    this.calcTax(true);

                    this.indexEditing = this.indexEditing + 1
                    setTimeout(() => { this.initEditor(this.indexEditing, 1); }, 70);
                  } else {
                    this.eventsService.publish('home:showAlert', {
                      message: "No se encontró ningún token de costos para usar.", cancelButton: false, onConfirm: (data: AlertResponse) => {
                        item.ValorUnitario = min;
                        item.TieneToken = false;
                        item.IdToken = 0;
                        let dif: number = precioOriginal - min;
                        porcentajeDescuento = parseFloat(((dif / precioOriginal) * 100).toFixed(2));
                        importeDescuento = precioOriginal - parseFloat((precioOriginal * ((100 - (porcentajeDescuento)) / 100)).toFixed(2));
                        importeDescuento = parseFloat(importeDescuento.toFixed(2));
                        item.DescuentoPorcentaje = porcentajeDescuento;
                        item.ImporteAntesDescuento = importeDescuento;
                        this.source[this.indexEditing] = { ...item };
                        this.source = [...this.source];
                        this.calcTax(true);
                        setTimeout(() => { this.initEditor(this.indexEditing, 7); }, 50);
                      }
                    });
                  }
                });


              } else {
                item.ValorUnitario = min;
                item.TieneToken = false;
                item.IdToken = 0;
                let dif: number = precioOriginal - min;
                porcentajeDescuento = parseFloat(((dif / precioOriginal) * 100).toFixed(2));
                importeDescuento = precioOriginal - parseFloat((precioOriginal * ((100 - (porcentajeDescuento)) / 100)).toFixed(2));
                importeDescuento = parseFloat(importeDescuento.toFixed(2));
                item.DescuentoPorcentaje = porcentajeDescuento;
                item.ImporteAntesDescuento = importeDescuento;
                this.source[this.indexEditing] = { ...item };
                this.source = [...this.source];
                this.calcTax(true);
                setTimeout(() => { this.initEditor(this.selectedIndex, 7); }, 90);
              }
            }
          });
          return;
        }
      } else if ((precio != precioOriginal) && precioOriginal > 0) {
        let dif: number = precioOriginal - precio;
        if (dif > 0) {
          porcentajeDescuento = parseFloat(((dif / precioOriginal) * 100).toFixed(2));
          importeDescuento = precioOriginal - parseFloat((precioOriginal * ((100 - (porcentajeDescuento)) / 100)).toFixed(2));
          importeDescuento = parseFloat(importeDescuento.toFixed(2));
          item.ValorUnitario = parseFloat(e.target.value);
        } else {
          porcentajeDescuento = 0;
          importeDescuento = 0;
          item.ValorUnitario = precio;
        }
        item.DescuentoPorcentaje = porcentajeDescuento;
        item.ImporteAntesDescuento = importeDescuento;
      } else {
        item.ValorUnitario = precio;
      }
    } else {
      item.ValorUnitario = 0;
      item.DescuentoImporte = item.DescuentoPorcentaje = 0;
    }
    this.source[this.indexEditing] = { ...item };
    this.source = [...this.source];
    this.calcTax(true);
  }

  setIndexEdit(index: number, e: any, item: VentaConcepto) {

    /*Esto es lo nuevo*/

    //this.indexEditing = -1;

    const countAnticipo = this.getCountAnticipo();
    if (!item.NoIdentificacion && countAnticipo == 1) {
      this.indexEditing = -1;
      e.target.blur();
      return;
    }

    if (this.myForm.value.FechaCancelacion) {
      this.indexEditing = -1;
      e.target.blur();
      return;
    }

    if (this.myForm.value.EnFacturaGlobal || this.myForm.value.EnFacturaNormal) {
      this.eventsService.publish('home:showAlert', { message: "Este ticket ya fue facturado, no se puede modificar.", cancelButton: false });
      this.indexEditing = -1;
      e.target.blur();
      return;
    }


    if (this.myForm.value.Cliente?.Id == 0 || !this.myForm.value.Cliente) {
      this.eventsService.publish('home:showAlert', {
        message: "Primero indique un Cliente.", cancelButton: false, onConfirm: (data: AlertResponse) => {
          this.focusCliente();
        }
      });
      this.indexEditing = -1;
      e.target.blur();
      return;
    }

    const docto: ComboBoxEntity = this.listaComprobantes.filter(P => P.Id == this.myForm.value.DocumentoVenta.Id)[0];
    if (docto.ObligarCopiar) {
      if (!item.Producto || item.Producto?.Id == 0) {
        this.eventsService.publish('home:showAlert', { message: "No se pueden agregar Productos, debe de copiar.", cancelButton: false });
        this.indexEditing = -1;
        e.target.blur();
        return;
      }
    }

    /**/



    this.indexEditing = index;
    e.target.select();
    e.target.classList.add(this.getEditorClass());
  }

  getEditorClass() {
    return this.appSettings.appDarkMode ? 'focus-editor-grid-dark' : 'focus-editor-grid';
  }

  /**/

  focusProducto(index: number, e: any, item: VentaConcepto) {
    this.setIndexEdit(index, e, item);
    let c = this.getLastItem();

    if (c == 0 && index > 0) {
      e.target.blur();
      return;
    }

    if ((index >= c + 1) && c > 0) {
      e.target.blur();
    }
  }


  totalLetras() {
    return this.utileService.NumerosaLetras(this.myForm.value.Total)
  }

  initEditor(row: number, col: number) {
    col = col - 1;
    const input = document.getElementById(`txt_${row}_${col}${this.idUnico}`)!;
    if (input) input.focus();


    // const elem: any = this.getElemEditor(row, col);
    // if (elem) {
    //   setTimeout(() => {
    //     elem.firstElementChild?.click();
    //   }, 250);
    // }
  }

  setPorcentajeResguardo(v: Venta) {
    if (v.EsResguardo) {
      let p = 0;
      if (v.CantidadEntregadaGlobal > 0 && v.CantidadGlobal > 0) {
        p = (v.CantidadEntregadaGlobal * 100) / v.CantidadGlobal;
      }
      this.prcentajeAvanceResguardo = p;
    } else {
      this.prcentajeAvanceResguardo = 0;
    }
  }

  rowStyle: GuiRowStyle = {
    styleFunction: (data: VentaConcepto, index: number) => {
      if (data.Producto) {
        if (data.NumeroLotePaquete) {
          return 'font-weight: bold;background-color:#d3f1d3;';
        }
      }
      return '';
    }
  };

  getShowRow(index: number, item: any): boolean {

    if (index == 0) return true;

    return false;
  }

  clicMenu(value: string) {
    if (this.myForm.value.Cliente?.Id > 0) {
      if (value == "historico") {
        this.openHistorico();
      } else {

        this.eventsService.publish('home:isLoading', { isLoading: true });
        let reportHeader: ReportHeader = {
          NombreReporte: '',
          Dato1: '',
          Fecha1: new Date(),
          Fecha2: new Date(),
          Num1: 0,
          Num2: 0,
          Opc1: false,
          Opc2: false,
          Opc3: false,
          Opc4: false,
          Opc5: false
        }
        let filtroOrdenCarga: ReportFilter = {
          ReportHeader: {} as ReportHeader,
          TituloVisor: 'Antigüedad de saldos',
          NombreExcel: 'Antigüedad de saldos.xlsx',
          Ordenado: 'empclave,succlave,cliclave,Abreviacion,FechaEmision,FechaVencimiento ',
          NombreReporte: '',
          DesgloseId: 1,
          FilterOptions: [
            { Campo: 'cli.Clave', Etiqueta: 'Cliente', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Cliente" },
            { Campo: 'emp.Clave', Etiqueta: 'Empresa', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Empresa" },
          ]
        };

        let item = filtroOrdenCarga.FilterOptions?.find(p => p.Campo == 'cli.Clave');
        if (item) {
          item.Valor = String(this.myForm.value.Cliente?.Clave);
        }

        item = filtroOrdenCarga.FilterOptions?.find(p => p.Campo == 'emp.Clave');
        if (item) {
          item.Valor = String(this.info.empresa?.clave);
        }


        filtroOrdenCarga.ReportHeader = reportHeader;
        this.reportsService.printReport(filtroOrdenCarga, '/CuentasPorCobrar/ReporteAntiguedadDeSaldos');
      }
    } else {
      this.eventsService.publish('home:showAlert', { message: "Primero indique un Cliente.", cancelButton: false });
    }
  }
  /**/



  ngOnInit(): void {
    this.tabsNavService.getIsOpenTab("cortecaja", (resp: any) => {
      console.log(resp)
      if (resp) {
        this.eventsService.publish('home:showAlert', {
          message: `No se puede mantener abiertas las pantallas de Corte de Caja y Facturación al mismo tiempo.`, cancelButton: false, onConfirm: (data: AlertResponse) => {
            this.tabsNavService.closeCurrentTab();
          }
        });
      }
    })

    this._isDevMode = isDevMode();
    this.appSettings.appSidebarMinified = true;
    this.idUnico = String(new Date().getTime() * 10000);
    this.info = this.utileService.getUserLogged();
    this.fServices.obtenerMascaraProducto(this.info.empresa!.numero).subscribe((result) => { this.mascara = result.message });
    this.searchConfigurationCliente = this.ms.GetSearchConfiguration("Cliente", `Empresa.Id = ${this.info.empresa!.numero}`);
    this.searchConfigurationProducto = this.ms.GetSearchConfiguration("Producto", `Empresa.Id = ${this.info.empresa!.numero}`);
    this.fServices.utilizaSegundoNombre(this.info.sucursal!.numero).subscribe((usa) => {
      this.utilizaSegundoNombre = usa;
    })
    this.sourceReset();
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.getComps();
    this.obtenerConfiguracionVenta();
    this.fServices.ObetenerAplicaEstimulo(this.info.empresa!.numero, this.info.sucursal!.numero).subscribe((aplica) => { this.empresaSucursalEstimulo = aplica; })
    this.getTokens();

    this.cService.obtenerValorPropiedad("Usuario", "Rol.CambiarVendedor", this.info.numero).subscribe((value) => {
      this.permisoCambiarVendedor = value.toLowerCase() == "true";
    });

    this.cService.obtenerValorPropiedad("Usuario", "Rol.CambiarCuentaVenta", this.info.numero).subscribe((value) => {
      this.permisoCambiarCuentaVenta = value.toLowerCase() == "true";
    });

    this.cService.obtenerValorPropiedad("Usuario", "Rol.CambiarEntrega", this.info.numero).subscribe((value) => {
      this.permisoCambiarEntrega = value.toLowerCase() == "true";
    });
  }

  get getItemsCajas(): ComboBoxEntity[] {
    return this.listaCajas;
  }

  getTokens() {
    this.fServices.getTokensPorUsuario(this.info.numero).subscribe((num) => { this.numeroTokens = num; })
  }


  obtenerConfiguracionVenta() {
    this.cService.getLastEntity("ConfiguracionVenta", `Sucursal.Id = ${this.info.sucursal?.numero}`).subscribe((ent) => {
      if (!ent) {
        this.cService.getEmptyEntity("ConfiguracionVenta").subscribe((newE) => {
          this.configuracion = newE;
        });
      } else {
        this.configuracion = ent;
      }

      this.fServices.obtenerCajasAbiertas(this.info.sucursal!.numero).subscribe((result) => {
        if (result.success) {
          const l: ComboBoxEntity[] = JSON.parse(result.message);
          this.listaCajas = l;
        } else {
          this.listaCajas = [];
        }
        if (this.listaCajas.length == 0 && this.configuracion?.UsarModoPuntoDeVenta) {
          this.eventsService.publish('home:showAlert', {
            cancelButton: false,
            message: `Para poder iniciar, primero debe de hacer la APERTURA DE CAJA. `,
            onConfirm: (data: AlertResponse) => {
              this.tabsNavService.closeCurrentTab();
            }
          });
        }
      });

    });
  }


  changueTitle(title: string) {
    this.eventsService.publish('home:changeTabTitle', { tabTitle: `Emitiendo => [${title}]`, url: "facturacion" });
  }

  sourceReset(conceptos: any = []) {
    this.source = conceptos;
    for (let index = 0; index < 50; index++) {
      this.source = [...this.source, {
        Id: 0,
        Producto: null,
        TieneToken: false,
        IdToken: 0,
        Cantidad: 0,
        NoIdentificacion: '',
        Unidad: null,
        ClaveProdServ: '',
        ClaveUnidad: '',
        UnidadNombre: '',
        ObjetoImpuesto: '',
        Descripcion: '',
        DescripcionProduccion: '',
        TextoConversiones: '',
        ValorUnitario: 0,
        ValorUnitarioAntesDescuento: 0,
        DescuentoPorcentaje: 0,
        DescuentoImporte: 0,
        Importe: 0,
        ImporteAntesDescuento: 0,
        TotalIvaTrasladado: 0,
        TotalIvaRetenido: 0,
        TotalIsrRetenido: 0,
        TotalIeps: 0,
        TextoFormulacion: '',
        Total: 0,
        Impuestos: [],
        UltimoCosto: 0,
        CantidadEntregada: 0,
        CantidadRestante: 0,
        NumeroPedimento: '',
        NumeroLotePaquete: '',
        DescripcionConvesion: '',
        IdDetalleBackOrder: 0,
        Disenos: [],
        GrosorFormulacion: 0,
        AnchoFormulacion: 0,
        LargoFormulacion: 0,
        PiezasFormulacion: 0,
        DivisorFormulacion: 0,
        Existencia: 0,
        UltimoCostoInventario: 0,
        PorcentajeUtilidad: 0,
        ImporteUtilidad: 0,
        PorcentajeUtilidadInventario: 0,
        TotalCosto: 0,
        ImporteUtilidadInventario: 0,
        TotalCostoInventario: 0,
        Conversiones: [],
      }]

    }
  }
  get getFormasPago(): VentaFormaPago[] {

    if (this.myForm.value.FormasPago)
      return [...this.myForm.value.FormasPago]

    return [];
  }

  get getSolicitaCancelar(): boolean {
    if (this.configuracion) {
      if (this.configuracion.UsarProcesoSolicitudCancelacion && this.myForm.value.Id > 0 && !this.myForm.value.FechaCancelacion) {
        const tipos = `,${this.configuracion.DocumentosAplicanSolicitudCancelacion},`.trim();
        const t: any = this.cboComp;
        if (tipos.indexOf(`,${t.selectedItem.Clave},`) != -1) {
          return true;
        }
      }
    }
    return false;
  }

  get getItemsSerie(): ComboBoxEntity[] {
    return this.listaSeries;
  }

  get getItemsComps(): ComboBoxEntity[] {
    return this.listaComprobantes;
  }

  get getEntity(): Venta {
    return this.myForm.value;
  }

  get getFilter(): any {
    // if (this.searchConfigurationCliente) {
    //   return this.searchConfigurationCliente.filter;
    // }
    return "";
  }

  get getFilterClase(): string {
    let filter = "";
    if (this.myForm.value.Cliente) {
      if (this.myForm.value.Cliente.Id > 0) {
        if (this.myForm.value.Cliente.ClaseVenta?.Id > 0) {
          filter += ` Id = ${this.myForm.value.Cliente.ClaseVenta?.Id} `;
        }
        if (this.myForm.value.Cliente.ClaseVenta2?.Id > 0) {
          filter += ` or Id = ${this.myForm.value.Cliente.ClaseVenta2?.Id} `;
        }
        if (this.myForm.value.Cliente.ClaseVenta3?.Id > 0) {
          filter += ` or Id = ${this.myForm.value.Cliente.ClaseVenta3?.Id} `;
        }
      }
    }

    return filter;
  }

  get getProductsColumns(): any {
    if (this.searchConfigurationProducto) {
      return this.searchConfigurationProducto.columns;
    }
    return [];
  }

  get getProductsPropertys(): string {
    if (this.searchConfigurationProducto) {
      let props = "";
      this.searchConfigurationProducto.propertys.forEach((prop) => {
        props += `${prop.name}|${prop.type},`
      })

      if (props.length > 0) {
        props = props.substring(0, props.length - 1);
        return props;
      }

    }
    return "";
  }


  get getColumns(): any {
    if (this.searchConfigurationCliente) {
      return this.searchConfigurationCliente.columns;
    }
    return [];
  }

  get getPropertys(): string {
    if (this.searchConfigurationCliente) {
      let props = "";
      this.searchConfigurationCliente.propertys.forEach((prop) => {
        props += `${prop.name}|${prop.type},`
      })

      if (props.length > 0) {
        props = props.substring(0, props.length - 1);
        return props;
      }

    }
    return "";
  }

  get getEstadoSAT(): CfdiEstadoSAT | null {
    return this.estadoSAT;
  }

  get getCancelDate(): string {
    if (this.myForm.value.FechaCancelacion) {
      return moment(this.myForm.value.FechaCancelacion).format("DD/MM/YYYY");
    }
    return "";
  }

  get getFacturado(): boolean {
    return this.myForm.value.EnFacturaGlobal || this.myForm.value.EnFacturaNormal;
  }

  aplicaDescuentoDecretoFranja(detalle: VentaConcepto) {
    if (this.empresaSucursalEstimulo) {
      try {

        let aplicaClaveProdServ = detalle.Producto?.AplicaEstimulo;
        let claveArticuloServ = detalle.Producto?.ClaveSAT;
        if (!aplicaClaveProdServ) {
          if (this.myForm.value.Cliente.Rfc.toUpperCase() == "XAXX010101000" && claveArticuloServ == "01010101") {
            aplicaClaveProdServ = true;
          }
        }
        return aplicaClaveProdServ;
      } catch {
      }
    }

    return false;
  }

  esPersonaMoral() {
    return this.myForm.value.Cliente.Rfc.length == 12;
  }

  calcTax(desglosar: boolean = false, calcularIVa16Siempre: boolean = false) {
    let ent: Venta = this.myForm.value;
    ent.SubTotal = 0;
    ent.SubTotalGrabaIvaTrasladado = 0;
    ent.SubTotalGrabaIvaExento = 0;
    ent.SubTotalGrabaIvaCero = 0;
    ent.TotalIvaTrasladado = 0;
    ent.TotalIvaRetenido = 0;
    ent.TotalIsrRetenido = 0;
    ent.Total = 0;
    ent.CanceloDecretoIVA = calcularIVa16Siempre;
    let totalConcepto: number = 0, subTotalVenta = 0, totalConceptos = 0;
    this.source.map((concepto, index) => {
      if (concepto.Producto) {
        if (concepto.Producto.Id > 0) {
          const imp = concepto.Producto!.Impuesto;
          if (concepto.ValorUnitario > 0) {
            concepto.TotalIvaTrasladado = 0;
            concepto.TotalIvaRetenido = 0;
            concepto.TotalIsrRetenido = 0;
            concepto.Total = 0;
            if (!concepto.Impuestos) {
              concepto.Impuestos = [];
            }
            concepto.Importe = parseFloat((concepto.Cantidad * concepto.ValorUnitario).toFixed(2));
            totalConcepto = concepto.Importe;


            if (imp) {
              //#region Calculo de IVA Trasladado
              if (imp.IVATrasladado) {
                let tasa = imp.IVATrasladado.TasaCuota;

                if (tasa >= 16) {
                  if (this.aplicaDescuentoDecretoFranja(concepto)) {
                    tasa = tasa / 2;
                  }
                }
                if (tasa == 8 && calcularIVa16Siempre) {
                  tasa = 16;
                }

                let propIVA: number = 0;
                if (imp.IncluidoEnPrecioIvaTrasladado) {
                  propIVA = 100 + (tasa);
                }

                if (propIVA > 0 && desglosar) {
                  propIVA = propIVA / 100;
                  concepto.ValorUnitario = parseFloat((concepto.ValorUnitario / propIVA).toFixed(6));
                  concepto.Importe = parseFloat((concepto.Cantidad * concepto.ValorUnitario).toFixed(6));
                  totalConcepto = concepto.Importe;
                  subTotalVenta += concepto.Importe;
                } else {
                  subTotalVenta += concepto.Importe;
                }

                const tipoImpuesto = imp.IVATrasladado.Factor.Clave == "3" ? "EXENTO" : "IVA";
                const impIvaExists = concepto.Impuestos.filter(P => P.TipoImpuesto == tipoImpuesto && P.TasaOCuota == tasa);
                let idImpIva = 0;
                if (impIvaExists.length > 0) {
                  idImpIva = impIvaExists[0].Id;
                  concepto.Impuestos = concepto.Impuestos.filter(P => P.Id != idImpIva);
                }

                let impIva: VentaConceptoImpuesto = {
                  Id: idImpIva,
                  TipoImpuesto: tipoImpuesto,
                  Importe: parseFloat((concepto.Importe * (tasa / 100)).toFixed(3)),
                  TasaOCuota: tasa,
                  TipoFactor: imp.IVATrasladado.Factor,
                  Impuesto: imp,
                  Base: concepto.Importe,
                };

                const limiteInferior = this.satService.GetLowerLimit_TraslateAmount(impIva.Base, this.satService.GetNumberdecimal(impIva.Base.toString()), impIva.TasaOCuota / 100, 6);
                const limiteSuperior = this.satService.GetUpperLimit_TraslateAmount(impIva.Base, this.satService.GetNumberdecimal(impIva.Base.toString()), impIva.TasaOCuota / 100, 6);
                if (impIva.Importe < limiteInferior) {
                  impIva.Importe = limiteInferior;
                } else if (impIva.Importe > limiteSuperior) {
                  impIva.Importe = limiteSuperior;
                }

                concepto.Impuestos = [...concepto.Impuestos, impIva];

                concepto.ObjetoImpuesto = "02";
                concepto.TotalIvaTrasladado += impIva.Importe;
                totalConcepto += concepto.TotalIvaTrasladado;

                if (imp.IVATrasladado.Factor.Clave != "3") {
                  if (imp.IVATrasladado.TasaCuota > 0) {
                    ent.SubTotalGrabaIvaTrasladado += concepto.Importe;
                  } else {
                    ent.SubTotalGrabaIvaCero += concepto.Importe;
                  }
                } else {
                  ent.SubTotalGrabaIvaExento += concepto.Importe;
                }
                ent.TotalIvaTrasladado += concepto.TotalIvaTrasladado;

              }

              //#endregion

              //#region Calculo del IVA Retenido
              if (imp.IVARetenido && this.esPersonaMoral()) {
                if (!imp.IncluidoEnPrecioIVARetenido) {
                  let impIvaRet: VentaConceptoImpuesto = {
                    Id: 0,
                    TipoImpuesto: "IVARET",
                    Importe: parseFloat((concepto.Importe * (imp.IVARetenido.TasaCuota / 100)).toFixed(2)),
                    TasaOCuota: imp.IVARetenido.TasaCuota,
                    TipoFactor: imp.IVARetenido.Factor,
                    Impuesto: imp,
                    Base: concepto.Importe,
                  };
                  const limiteInferior = this.satService.GetLowerLimit_TraslateAmount(impIvaRet.Base, this.satService.GetNumberdecimal(impIvaRet.Base.toString()), impIvaRet.TasaOCuota / 100, 2);
                  const limiteSuperior = this.satService.GetUpperLimit_TraslateAmount(impIvaRet.Base, this.satService.GetNumberdecimal(impIvaRet.Base.toString()), impIvaRet.TasaOCuota / 100, 2);
                  if (impIvaRet.Importe < limiteInferior) {
                    impIvaRet.Importe = limiteInferior;
                  } else if (impIvaRet.Importe > limiteSuperior) {
                    impIvaRet.Importe = limiteSuperior;
                  }
                  concepto.Impuestos = [...concepto.Impuestos, impIvaRet];
                  concepto.ObjetoImpuesto = "02";
                  concepto.TotalIvaRetenido += impIvaRet.Importe;

                  totalConcepto -= concepto.TotalIvaRetenido;

                  ent.SubTotalGrabaIvaRetenido += concepto.Importe;
                  ent.TotalIvaRetenido += concepto.TotalIvaRetenido;
                }
              }
              //#endregion

              //#region Calculo del ISR Retenido
              if (imp.ISRRetenido && this.esPersonaMoral()) {
                if (imp.IncluidoEnPrecioISRRetenido) {
                  let proporcion = 0;
                  proporcion = 100 - imp.ISRRetenido.TasaCuota;
                  proporcion = proporcion / 100;
                  // concepto.ValorUnitario = parseFloat((concepto.ValorUnitario * (1 + (imp.ISRRetenido.TasaCuota / 100))).toFixed(6));
                  concepto.ValorUnitario = parseFloat((concepto.ValorUnitario / proporcion).toFixed(6));
                  concepto.Importe = parseFloat((concepto.Cantidad * concepto.ValorUnitario).toFixed(6));
                  //totalConcepto = concepto.Importe;
                  subTotalVenta = concepto.Importe;
                  if (ent.SubTotalGrabaIvaCero > 0) {
                    ent.SubTotalGrabaIvaCero = subTotalVenta;
                  }
                }
                let impIsrRet: VentaConceptoImpuesto = {
                  Id: 0,
                  TipoImpuesto: "ISRRET",
                  Importe: parseFloat((concepto.Importe * (imp.ISRRetenido.TasaCuota / 100)).toFixed(2)),
                  TasaOCuota: imp.ISRRetenido.TasaCuota,
                  TipoFactor: imp.ISRRetenido.Factor,
                  Impuesto: imp,
                  Base: concepto.Importe,
                };

                const limiteInferior = this.satService.GetLowerLimit_TraslateAmount(impIsrRet.Base, this.satService.GetNumberdecimal(impIsrRet.Base.toString()), impIsrRet.TasaOCuota / 100, 2);
                const limiteSuperior = this.satService.GetUpperLimit_TraslateAmount(impIsrRet.Base, this.satService.GetNumberdecimal(impIsrRet.Base.toString()), impIsrRet.TasaOCuota / 100, 2);
                if (impIsrRet.Importe < limiteInferior) {
                  impIsrRet.Importe = limiteInferior;
                } else if (impIsrRet.Importe > limiteSuperior) {
                  impIsrRet.Importe = limiteSuperior;
                }
                concepto.Impuestos = [...concepto.Impuestos, impIsrRet];
                concepto.ObjetoImpuesto = "02";
                concepto.TotalIsrRetenido += impIsrRet.Importe;
                if (!imp.IncluidoEnPrecioISRRetenido) {
                  totalConcepto -= concepto.TotalIsrRetenido;
                }

                ent.SubTotalGrabaIsrRetenido += concepto.Importe;
                ent.TotalIsrRetenido += concepto.TotalIsrRetenido;

              }
              //#endregion


              //ent.SubTotal += parseFloat(subTotalVenta.toFixed(2));
              concepto.Total += this.round(totalConcepto);
              totalConceptos += totalConcepto;
              //ent.Total += parseFloat(totalConcepto.toFixed(2));
            }
          } else {
            concepto.Impuestos = [];
          }
        }
      }
    });
    ent.SubTotal = this.round(subTotalVenta);
    ent.Total = this.round(totalConceptos);
    this.myForm.reset(ent);
    this.source = [...this.source];
    setTimeout(() => {
      if (this.enviarFocoPrecio) {
        this.initEditor(this.indexEditing, 6);
        this.enviarFocoPrecio = false;
      }
      if (this.enviarFocoDescuento) {
        if (this.configuracion?.NoModificarDescuento) {
          this.indexEditing = this.indexEditing + 1;
          this.initEditor(this.indexEditing, 1);
        } else {
          this.initEditor(this.indexEditing, 7);
        }
        this.enviarFocoDescuento = false;

      }
      if (this.enviarFocoCantidad) {
        this.initEditor(this.indexEditing, 3);
        this.enviarFocoCantidad = false;
      }
      if (this.enviarOtroRenglon) {
        this.initEditor(this.indexEditing + 1, 1);
        this.enviarOtroRenglon = false;
      }
    }, 50);
  }

  round(num: number) {
    return Math.round((num + Number.EPSILON) * 100) / 100
  }

  openNotaCredito(cb: any) {
    //verificamos si es una nota de credito
    setTimeout(() => {
      const t: any = this.cboComp;
      if (t.selectedItem.TipoDeComprobante == "E") {
        const b: any = this.ctrlNotaCredito;
        this.ms.openModal(b, (e: any) => {
          if (e) {
            this.myForm.controls["TipoNotaCredito"].setValue(e.TipoNotaCredito);
            this.myForm.controls["AfectaNotaCredito"].setValue(e.Afecta);
            this.myForm.controls["EsFacturaMigrada"].setValue(e.EsFacturaMigrada);
            this.myForm.controls["SucursalOriginaNotaCredito"].setValue(e.SucursalOriginaNotaCredito);
            this.myForm.controls["TipoRelacionNotaCredito"].setValue(e.TipoRelacionNotaCredito);
            this.myForm.controls["UUIDOriginaNotaCredito"].setValue(e.UUIDOriginaNotaCredito);
            this.myForm.controls["DocumentoVentaOrigina"].setValue(e.DocumentoVentaOrigina);
            this.myForm.controls["SerieOriginaNotaCredito"].setValue(e.Serie);
            this.myForm.controls["FolioOriginaNotaCredito"].setValue(e.Folio);
            if (cb) {
              cb(e);
            }
          }
        }, 'sm')
      } else {
        if (cb) {
          cb(null);
        }
      }
    }, 50);
  }

  openDisenos() {
    debugger
    const b: any = this.ctrlDisenos;
    let itemE = this.source[this.indexEditing];
    this.idsDisenosSeleccionados = "";
    if (itemE.Disenos) {
      itemE.Disenos.forEach((i) => {
        this.idsDisenosSeleccionados += `${i.Diseno.Id},`;
      });
      if (this.idsDisenosSeleccionados.length > 0) {
        this.idsDisenosSeleccionados = this.idsDisenosSeleccionados.substring(0, this.idsDisenosSeleccionados.length - 1);
      }
    }

    this.ms.openModal(b, (e: Diseno[] | null) => {
      if (e) {
        let item = this.source[this.indexEditing];
        item.DescripcionProduccion = "";
        item.Disenos = [];
        this.modificarDisenos(e, true);
      }
    }, "lg");
  }

  modificarDisenos(e: Diseno[] | null, iniciar: boolean) {
    let item = this.source[this.indexEditing];
    if (e == null) {
      if (item.Disenos) {
        e = [];
        item.Disenos.forEach((i) => {
          e = [...e!, i.Diseno];
        })
      }
    }
    item.DescripcionProduccion = "";
    item.Disenos = [];
    if (e) {
      e.forEach((diseno: Diseno) => {
        item.Disenos = [...item.Disenos, { Id: 0, Diseno: diseno, Cantidad: diseno.Cantidad }];
        // item.DescripcionProduccion += `*  Diseño: ${diseno.Nombre}\n`;
        // diseno.Detalle.forEach((det) => {
        //   item.DescripcionProduccion += `   > ${det.Cantidad* item.Cantidad} ${det.Producto?.Unidad.Nombre} de ${det.Producto?.Nombre}\n`;
        // });
      });
      if (iniciar) {
        this.initEditor(this.indexEditing, 3);
      }
    }
  }

  openFormulacion() {
    const b: any = this.ctrlFormulacion;
    let item: VentaConcepto = this.source[this.indexEditing];
    let vf: VentaFormulacion = {
      GrosorFormulacion: item.GrosorFormulacion,
      AnchoFormulacion: item.AnchoFormulacion,
      LargoFormulacion: item.LargoFormulacion,
      PiezasFormulacion: item.PiezasFormulacion,
      DivisorFormulacion: item.Producto!.DivisorFormulacion,
    };
    this.ventaFormulacion = { ...vf };
    this.ms.openModal(b, (e: VentaFormulacion | null) => {
      if (e) {
        this.source[this.indexEditing].GrosorFormulacion = e.GrosorFormulacion;
        this.source[this.indexEditing].AnchoFormulacion = e.AnchoFormulacion;
        this.source[this.indexEditing].LargoFormulacion = e.LargoFormulacion;
        this.source[this.indexEditing].PiezasFormulacion = e.PiezasFormulacion;
        if (e.DivisorFormulacion > 0) {
          let cant = ((e.GrosorFormulacion * e.AnchoFormulacion * e.LargoFormulacion) * e.PiezasFormulacion) / e.DivisorFormulacion;
          this.source[this.indexEditing].Cantidad = cant;
          this.enviarFocoPrecio = true;
          this.calcTax();
        }
      } else {
        this.initEditor(this.indexEditing, 3);
      }

    }, 'sm')

  }

  searchProduct(value: string, indexBuscado: number = -1) {
    if (!value || this.busquedaAbierta) {
      // if (this.myForm.value.Total > 0 && this.navigateColumns) {
      //   this.save();
      // }
      return;
    };
    value = this.fServices.aplciarMascara(value, this.mascara);
    this.loading = true;

    this.fServices.buscarProducto(this.info.empresa!.numero, this.info.sucursal!.numero, this.myForm.value.Cliente.Id, true, value).subscribe((producto) => {
      this.loading = false;
      if (producto) {
        let item = this.source[this.indexEditing];
        if (this.configuracion) {
          if (producto.Precio == 0 && this.configuracion.NoPermitirPrecioCero) {
            this.eventsService.publish('home:showAlert', {
              message: `El Producto no tiene precio, verifique.`, cancelButton: false, onConfirm: (data: AlertResponse) => {
                this.source.splice(this.indexEditing, 1);
                this.source = [...this.source];
                this.calcTax();
                setTimeout(() => {
                  this.initEditor(this.indexEditing, 1);
                }, 150);
              }
            });
            return;
          }
        }
        item.NumeroLotePaquete = "";
        item.NumeroPedimento = "";
        item.Producto = producto;
        item.NoIdentificacion = producto.Clave;
        if (!this.utilizaSegundoNombre) {
          item.Descripcion = producto.Nombre;
        } else {
          item.Descripcion = producto.SegundoNombre;
        }
        item.UltimoCosto = producto.UltimoCosto;
        item.UltimoCostoInventario = producto.UltimoCostoInventario;
        item.Cantidad = 0;
        item.DescuentoPorcentaje = 0;
        item.DescuentoImporte = 0;
        item.Producto.MaximoPorcentajeDescuento = producto.MaximoPorcentajeDescuento;
        item.ValorUnitario = producto.Precio;
        item.Unidad = producto.Unidad;
        item.UnidadNombre = producto.Unidad.Nombre;
        item.ClaveUnidad = producto.Unidad.Clave;
        item.ClaveProdServ = producto.ClaveSAT;
        if (producto.EsParaAnticipo) {
          item.Cantidad = 1;
        }

        this.source = [...this.source];
        this.showDescription = producto.ActualizarDescrpcion && !producto.ActualizarDescrpcionBajoDemanda;
        this.myForm.controls["DescripcionAdicional"].setValue(producto.Nombre);
        if (producto.Inventariable && producto.Almacen) {
          this.fServices.getExistencia(producto.Id, this.myForm.value.Sucursal.Id, producto.Almacen.Id).subscribe((existencia) => {
            this.existencia = existencia;
            setTimeout(() => {
              this.source[this.indexEditing].Existencia = existencia;
              this.source = [...this.source];
            }, 150);
          });
        }

        if (!producto.ActualizarDescrpcion || (producto.ActualizarDescrpcion && producto.ActualizarDescrpcionBajoDemanda)) {
          if (producto.OtrasUnidades && producto.OtrasUnidades?.length > 0 && producto.AplicaVentas) {
            this.conversiones = producto.OtrasUnidades;
            this.unidadFinal = item.UnidadNombre;
            this.enviarFocoCantidad = false;
            this.openConvertions((e: any) => this.aceptConversions(e));
          } else if (producto.ActivaFormulacion && producto.ActivaFormulacionPieCubico) {
            this.openFormulacion();
          } else {
            this.conversiones = [];
            this.unidadFinal = '';
            if (!producto.ManejaPaquetesLotes) {
              this.validarDisenos();
            } else {
              this.openExistencias();
            }
          }
        } else {
          this.navigateColumns = true;
          this.rowEditandoDescricionAdic = this.indexEditing;
          setTimeout(() => {
            this.txtDescription.nativeElement.focus();
          }, 150);
        }
        this.calcTax();
      } else {

        this.eventsService.publish('home:showAlert', {
          message: "No se encontró el Producto indicado.", cancelButton: false, onConfirm: (data: AlertResponse) => {
            this.source.splice(this.indexEditing, 1);
            this.source = [...this.source];
            this.calcTax();
            setTimeout(() => {
              this.initEditor(this.indexEditing, 1);
            }, 150);
          }
        });

      }

    })

  }

  aceptConversions(e: any, isclick: boolean = false) {
    this.conversiones = [];
    this.unidadFinal = '';
    let item = this.source[this.indexEditing];
    if (e) {
      if (e.cantidad > 0) {
        item.Conversiones = e.items;
        item.TextoConversiones = e.conversiones;
        item.Cantidad = e.cantidad;
      } else {
        item.Conversiones = [];
        item.TextoConversiones = '';
      }
      this.source = [...this.source];
      this.calcTax();
      !isclick && this.initEditor(this.indexEditing, e.cantidad == 0 ? 3 : 6);
    } else {
      this.source = [...this.source];
      !isclick && this.initEditor(this.indexEditing, 3);
    }
  }

  acceptDescription() {
    this.source[this.rowEditandoDescricionAdic].Descripcion = this.myForm.value.DescripcionAdicional;
    this.showDescription = false;
    this.source = [...this.source];
    this.navigateColumns && this.initEditor(this.rowEditandoDescricionAdic, 3);
    this.validarDisenos();
  }

  validarDisenos() {
    const p: Producto | null = this.source[this.rowEditandoDescricionAdic].Producto;
    if (p?.Compuesto && p?.SolicitarDisenosFactura) {
      this.idDisenoBase = 0;
      if (p.DisenoProduccion != null) {
        this.idDisenoBase = p.DisenoProduccion.Id;
      }
      this.openDisenos();
    }
  }

  cancelDescription() {
    this.showDescription = false;
    this.navigateColumns && this.initEditor(this.rowEditandoDescricionAdic, 1);
    this.validarDisenos();
  }

  onClickBarButton(button: string): void {
    switch (button) {
      case "new": this.new(); break;
      case "save": this.save(); break;
      case "print": this.print(); break;
      case "first": this.navigate(button); break;
      case "left": this.navigate(button); break;
      case "right": this.navigate(button); break;
      case "last": this.navigate(button); break;
    }
  }
  navigate(type: string) {
    this.findEntityByParamsNavigate(type);
  }

  findEntityByParamsNavigate(type: string) {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    const params = new HttpParams()
      .set("idEmpresa", this.myForm.value.Empresa.Id)
      .set("idSucursal", this.myForm.value.Sucursal.Id)
      .set("idDoct", this.myForm.value.DocumentoVenta.Id)
      .set("serie", this.myForm.value.SerieString)
      .set("folio", this.myForm.value.Folio)
      .set("tipo", type);
    this.http.get<Venta>(`${this.baseUrl}/Ventas/ObtenerVentaPorParametrosNavegacion`, { params }).subscribe((venta) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      this.setEntity(venta);
    });
  }

  onCloseSaldosResg() {
    this.listaSaldosEntregar = [];
  }

  get RemisionSerieFolio() {
    return this.myForm.value.SerieString + '/' + this.myForm.value.Folio;
  }

  onCloseHistRem(idRem: any) {
    this.listaHistoricoRemisiones = [];
    if (idRem) {
      let item = this.menuService.getComponentPages().find((c: any) => c.url === 'remisionresguardo');
      if (item) {
        this.eventsService.publish('home:openNewTab', { url: "remisionresguardo", inputs: { idRemision: idRem } });
      } else {
        this.eventsService.publish('home:showAlert', { message: `No tiene permiso para ver en pantalla.`, cancelButton: false });

      }
    }
  }


  verSaldoResguardo() {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.rServices.getSaldosEntregar(this.myForm.value.Id).subscribe((lista) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      this.listaSaldosEntregar = lista;
      this.openSaldosRemisiones();
    });
  }

  verHistoricoRemisiones() {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.rServices.getHistoricoRemisiones(this.myForm.value.Id).subscribe((lista) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (lista.length > 0) {
        this.listaHistoricoRemisiones = lista;
        this.openHistoricoRemisiones();
      } else {
        this.eventsService.publish('home:showAlert', { message: `No hay histórico para mostrar.`, cancelButton: false });
      }
    })
  }

  setEntity(venta: Venta) {
    this.listaHistoricoRemisiones = [];
    if (!venta) {
      this.new();
      this.prcentajeAvanceResguardo = 0;
    } else {
      let ent = this.myForm.value;
      ent = { ...venta };
      this.sourceReset(ent.Conceptos);
      ent.FechaEmision = moment(ent.FechaEmision).toDate();
      ent.Conceptos.map((item: VentaConcepto) => {
        if (item.Unidad) {
          item.UnidadNombre = item.Unidad!.Nombre;
        } else {
          item.UnidadNombre = '';
        }
      });
      if (ent.Cliente) {
        this.getCustomerAdvanceBalance(ent.Empresa.Id, ent.Cliente.Id);
        const txt: any = this.txtCliente;
        this.setAdress(ent.Cliente.Direccion);
        txt.tagInput.nativeElement.value = venta.Cliente.Clave;
      }
      this.setPorcentajeResguardo(venta);
      this.myForm.reset(ent);
    }
  }

  get getCantidad(): number {
    let x = 0;
    this.source.filter(P => P.Producto && P.Cantidad != 0).forEach((i) => { if (i.Producto?.Inventariable) x += parseFloat(String(i.Cantidad)); });
    return parseFloat(x.toFixed(2));
  }

  get getPartidas(): number {
    return this.source.filter(P => P.Producto && P.Cantidad != 0).length;
  }


  get getKilos(): number {
    let kilos = 0;
    this.source.filter(P => P.Producto && P.Cantidad != 0).forEach((i) => {
      kilos += Number((Number(i.Cantidad) * Number(i.Producto?.PesoEnKilos)).toFixed(6));
    })
    return kilos;
  }

  ObtenerEnviosPorOrden() {
    this.listaOrdenes = [];
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.fServices.obtenerOrdenesGeneradasPorPreOrden(this.myForm.value.IdOrdenManufactura).subscribe((lista: any) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      this.listaOrdenes = lista;
      if (lista.length > 0) {
        const b: any = this.ctrlListado;
        this.ms.openModal(b, (e: any) => {
        }, 'xl')
      } else {
        this.eventsService.publish('home:showAlert', { message: `No se encontraron Ordenes de Producción generadas para la Factura actual.`, cancelButton: false });
      }

    })
  }

  deleteRow(index: number) {
    this.eventsService.publish('home:showAlert', {
      message: '¿Desea eliminar el renglón?',
      onConfirm: (r: AlertResponse) => {
        if (r.isAccept) {
          this.source.splice(index, 1);
          this.source = [...this.source];
          this.calcTax();
          setTimeout(() => {
            this.initEditor(this.indexEditing, 1);
          }, 150);
        }
      }
    });

  }

  new() {
    this.existencia = 0;
    this.anticipoDisponible = 0;
    this.blockCombos = false;
    const ent: Venta = this.myForm.value;
    this.getEmptyEntity(ent.Serie.Id, ent.DocumentoVenta.Id);
    this.sourceReset();
    const txt: any = this.txtCliente;
    txt.tagInput.nativeElement.value = '';
  }

  enterFechaOrden() {
    this.initEditor(0, 1);
  }

  mostrarStatusTicketera: boolean = false;
  onSelectedItem(entity: any, tipo: string) {
    switch (tipo) {
      case "Serie":
        this.fServices.imprimeTicket(entity.Id).subscribe((rTicket: Result) => {
          if (rTicket.success) {
            this.initPrinterService(rTicket.message);
            this.mostrarStatusTicketera = true;
          } else {
            this.mostrarStatusTicketera = false;
          }
          let entrar = true;
          if (this.myForm.value.Serie) {
            if (this.myForm.value.Serie.Id == entity.Id) {
              entrar = false;
            }
          }
          if (entrar && !this.cargandoInicio) {
            this.getEmptyEntity(entity.Id, this.myForm.value.DocumentoVenta.Id);
          }
          this.cargandoInicio = false;
        });
        break;
      case "DocumentoVenta":
        this.myForm.controls[tipo].setValue(entity);
        this.getSeries(entity.Id);
        this.changueTitle(entity.Nombre);
        this.documentosPuedeCopiar = entity.DocumentosPuedeCopiar;
        break;
      case "Moneda":
        this.esMonedaExtranjera = entity.EsExtranjera;
        if (this.esMonedaExtranjera) {
          this.fServices.obtenerTipoCambioVentas(moment(this.myForm.value.FechaEmision).format("DD/MM/YYYY")).subscribe((tc) => {
            this.myForm.controls["TipoCambio"].setValue(tc);
          })
        } else {
          this.myForm.controls["TipoCambio"].setValue(0);
        }
        this.myForm.controls[tipo].setValue(entity);

        break;
      case "Condicion":
      case "UsoCfdi":
      case "Vehiculo":
      case "Chofer":
      case "Caja":
      case "Consignado":
        this.myForm.controls[tipo].setValue(entity);

        break;
      case "Clase":
        const t: any = this.cboClase;
        let fechaVencimiento = new Date();
        this.myForm.controls["FormasPago"].setValue([]);
        if (t.selectedItem.metodopago == 2) {
          if (t.selectedItem.condicionpago) {
            this.myForm.controls["Condicion"].setValue(t.selectedItem.condicionpago);
            if (t.selectedItem.condicionpago.Dias > 0) {
              fechaVencimiento = this.addDays(fechaVencimiento, t.selectedItem.condicionpago.Dias);
            }
          } else if (this.myForm.value.Cliente.CondicionDePago.Dias > 0) {
            this.myForm.controls["Condicion"].setValue(this.myForm.value.Cliente.CondicionDePago);
            fechaVencimiento = this.addDays(fechaVencimiento, this.myForm.value.Cliente.CondicionDePago.Dias);
          }
        } else if (t.selectedItem.condicionpago) {
          this.myForm.controls["Condicion"].setValue(t.selectedItem.condicionpago);
          fechaVencimiento = this.addDays(fechaVencimiento, t.selectedItem.condicionpago.Dias);
        }
        this.myForm.controls["FechaVencimiento"].setValue(fechaVencimiento);
        this.myForm.controls[tipo].setValue(entity);
        break;
      case "Vendedor":
        this.myForm.controls[tipo].setValue(entity);
        break;
      case "Cajero":
        this.myForm.controls[tipo].setValue(entity);
        break;
    }

  }

  //TODO: Validar si esta impreso que no permita imprimir, a menos que el rol diga que si

  print(cb: any = null) {
    let ent = this.myForm.value;
    if (ent.Id == 0) {
      this.eventsService.publish('home:showAlert', { message: `Primero debe de guardar el Documento.`, cancelButton: false });
      return;
    }
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.fServices.canPrint(this.myForm.value.DocumentoVenta.Id, this.myForm.value.Id).subscribe((result) => {
      if (result.success) {
        //validar si se imprime en ticketera
        this.fServices.imprimeTicket(ent.Serie.Id).subscribe((rTicket) => {
          if (rTicket.success) {
            if (rTicket.message) {
              this.fServices.obtenerTicket(ent.Id).subscribe((ticket) => {
                this.eventsService.publish('home:isLoading', { isLoading: false });
                let copias: any[] = atob(ticket.message).split("FIN_COPIA");
                copias.reverse();
                copias.forEach((t) => {
                  if (t.length > 0) {
                    this.printTicket(t, rTicket.message);
                  }
                })
                this.myForm.controls["Impreso"].setValue(true);
              })
            } else {
              this.eventsService.publish('home:isLoading', { isLoading: false });
              this.eventsService.publish('home:showAlert', { message: `No se ha indicado el nombre de la ticketera.`, cancelButton: false });
            }

          } else {
            this.imprimirPDF(ent, cb);
          }
        });
      } else {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        if (cb) {
          this.new();
        } else {
          this.eventsService.publish('home:showAlert', { message: `No está permitida la reimpresión, verifique con el administrador.`, cancelButton: false });
        }
      }
    })
  }

  imprimirPDF(ent: any, cb: any = null, mostrarNoValido: boolean = false, validarConsignado: boolean = true, imprimirEntrega: boolean = true) {
    if (ent.Id == 0) {
      this.eventsService.publish('home:showAlert', { message: `No se encontró el documento para imprimirlo.`, cancelButton: false });
      return;
    }
    if (mostrarNoValido) {
      this.eventsService.publish('home:isLoading', { isLoading: true });
    }

    if (ent.Consignado && validarConsignado) {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      this.eventsService.publish('home:showAlert', {
        textAccept: "Si",
        textCancel: "No",
        message: "¿Desea imprimir los datos de Entrega?", onConfirm: (data: AlertResponse) => {
          this.imprimirPDF(ent, cb, mostrarNoValido, false, data.isAccept);
        }
      });
      return
    } else {
      this.eventsService.publish('home:isLoading', { isLoading: true });
      this.fServices.imprimirDocumento(ent.Id, mostrarNoValido, imprimirEntrega).subscribe((result) => {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        if (result.success) {
          const serie: ComboBoxEntity = this.listaSeries.filter(P => P.Id == this.myForm.value.Serie.Id)[0];
          if (!serie.UsaImpresora) {
            this.downloadPdf(result.message);
          } else if (serie.UsaImpresora) {
            if (serie.NombreImpresora) {
              //Aqui va el código para envíar a la impresora
              //En el result.message está el b64 del .PDF
              this.printerService.printPdfB64(result.message, serie.NombreImpresora);
            } else {
              this.eventsService.publish('home:showAlert', { message: `No ha definido el nombre de la impresora en la Serie de Ventas, verifique con el administrador.`, cancelButton: false });
            }
          }
          if (!mostrarNoValido) {
            ent.Impreso = true;
            this.myForm.reset(ent);
            cb && cb();
          }
        } else {
          this.eventsService.publish('home:showAlert', { message: `${result.message}`, cancelButton: false });
        }
      })
    }
  }

  printNC(id: number) {
    let ent = this.myForm.value;
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.fServices.imprimirDocumentoVenta(id).subscribe((result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result.success) {
        this.downloadPdf(result.message);
        ent.Impreso = true;
        this.myForm.reset(ent);
      } else {
        this.eventsService.publish('home:showAlert', { message: `${result.message}`, cancelButton: false });
      }
    });
  }

  clicImprimirSalidaPorVenta(id: number) {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.fServices.puedeReImprimirSalidaPorVenta(this.myForm.value.Id).subscribe((result) => {
      if (result) {
        this.fServices.imprimirSalidaPorVenta(id).subscribe((result) => {
          this.eventsService.publish('home:isLoading', { isLoading: false });
          if (result.success) {
            this.downloadPdf(result.message);
          } else {
            this.eventsService.publish('home:showAlert', { message: `${result.message}`, cancelButton: false });
          }
        });
      } else {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        this.eventsService.publish('home:showAlert', { message: "No está permitida la reimpresión, verifique con el administrador.", cancelButton: false });
      }
    });
  }

  downloadPdf(base64: string) {
    this.fServices.printDocument(this.fServices.base64ToArrayBuffer(base64));
  }

  clicPrinted(id: number) {
    this.fServices.puedeReimprimir(this.info.numero).subscribe((puede) => {
      if (puede) {
        this.eventsService.publish('home:isLoading', { isLoading: true });
        this.fServices.setDocumentPrinted(id, false).subscribe((result) => {
          this.eventsService.publish('home:isLoading', { isLoading: false });
          this.myForm.controls["Impreso"].setValue(false);
          this.eventsService.publish('home:showAlert', { message: `${result.message}`, cancelButton: false });
        })
      } else {
        this.eventsService.publish('home:showAlert', { message: `El Usuario actual no tiene permiso para marcar el Documento como "No Impreso".`, cancelButton: false });
      }
    });

  }

  mostrarListasF() {
    this.mostrarListas = true;
    this.fServices.getPreciosLista().subscribe((precios) => {
      this.sourcePrecios = precios;
    });
  }

  openCfdiRelations() {
    const b: any = this.cfdiRelacionado;
    this.ms.openModal(b, (e: any) => {
      if (e) {
        this.myForm.value.CfdisRelacionados = [];
        const uuids = e.UUIDS.split(',');
        uuids.forEach((el: string) => {
          this.myForm.value.CfdisRelacionados = [...this.myForm.value.CfdisRelacionados, {
            UUID: el,
            TipoRelacion: e.TipoRelacion
          }]
        });
      }
    })
  }

  openHistoricoRemisiones() {
    const b: any = this.ctrlHistRemisiones;
    this.ms.openModal(b, (e: any) => {
      if (e) {
        this.onCloseHistRem(e);
      }
    }, 'lg')
  }

  closeModal() {
    this.ms.closeModal(null);
  }

  openSaldosRemisiones() {
    const b: any = this.ctrlSaldosResguardo;
    this.ms.openModal(b, (e: any) => {
      this.onCloseSaldosResg();
    }, 'lg')
  }

  openRelaciones() {
    const b: any = this.modalRelaciones;
    this.ms.openModal(b, (e: any) => {

    }, 'lg')
  }

  openComments(tipo: number) {
    this.tipoComentario = tipo;
    const b: any = this.comentarioGlobal;
    this.ms.openModal(b, (e: any) => {
      if (e != null && e != undefined) {
        if (tipo == 1) {
          this.myForm.controls["Observaciones"].setValue(e);
        } else {
          if (this.myForm.value.Id == 0) {
            this.myForm.controls["ObservacionesCargaEntrega"].setValue(e);
          } else {
            this.eventsService.publish('home:showAlert', {
              message: "¿Deseas actualizar los Comentarios para la Entrega a Domicilio?", onConfirm: (data: AlertResponse) => {
                if (data.isAccept) {
                  this.eventsService.publish('home:isLoading', { isLoading: true });
                  this.fServices.actualizarObservacionesEntregaDomicilio(this.myForm.value.Id, e).subscribe((result) => {
                    this.eventsService.publish('home:isLoading', { isLoading: false });
                    Swal.fire({ position: 'center', icon: 'success', title: 'Se actualizó correctamente las Observaciones para Carga y Entrega', showConfirmButton: false, timer: 1000 }).then(() => {
                      this.myForm.controls["ObservacionesCargaEntrega"].setValue(e);
                    });
                  })
                }
              }
            });
          }
        }
      }
    }, 'md')
  }


  openCopy() {
    const b: any = this.copiarComprobante;
    this.ms.openModal(b, (e: any) => {
      if (e) {
        this.myForm.controls["IdsCopiados"].setValue(e.idsCopy);
        this.sourceReset(e.conceptos);
        this.calcTax();
      }
    }, 'lg')
  }

  getCountAnticipo(): number {

    const filtro = this.source.filter(P => P.Cantidad > 0);
    const conceptos: VentaConcepto[] = [...filtro];
    let countAnt = conceptos.filter(P => P.Producto?.EsParaAnticipo).length;
    return countAnt;
  }

  isValidConcepts(throwMessage: boolean = true): boolean {


    const conceptos: VentaConcepto[] = [...this.source.filter(P => P.Cantidad > 0)];

    let countAnt = conceptos.filter(P => P.Producto?.EsParaAnticipo).length;

    if (countAnt > 1 && throwMessage) {

      this.eventsService.publish('home:showAlert', { message: 'Solo puede existir un concepto de Anticipo, verifique.', cancelButton: false });
      return false;
    }
    return true;
  }

  get getEsAnticipo(): boolean {
    return this.esAnticipo();
  }

  esAnticipo() {
    const conceptos: VentaConcepto[] = [...this.source.filter(P => P.Cantidad > 0)];

    let countAnt = conceptos.filter(P => P.Producto?.EsParaAnticipo).length;
    return countAnt == 1;
  }

  save(validar: boolean = true) {
    this.existencia = 0;
    if (this.saving) return;
    let ent = this.myForm.value;
    ent.Conceptos = this.source.filter(P => P.Cantidad > 0);

    let cPRecios = this.source.filter(P => P.Cantidad > 0 && (P.ValorUnitario <= 0 || !P.ValorUnitario)).length;
    let cCantidades = this.source.filter(P => P.Cantidad <= 0 && P.Producto).length;
    let cCostos = this.source.filter(P => P.UltimoCosto > 0 && (P.ValorUnitario <= P.UltimoCosto) && P.Producto && P.IdToken == 0).length;

    let countAnticipos = this.source.filter(P => P.Producto?.EsParaAnticipo).length;
    let countNoAnticipos = this.source.filter(P => !P.Producto?.EsParaAnticipo && P.Producto).length;


    let countPaquetes = this.source.filter(P => P.Producto && P.Producto.ManejaPaquetesLotes && !P.NumeroLotePaquete).length;

    if (countPaquetes > 0) {
      this.eventsService.publish('home:showAlert', { message: 'Hay Productos que manejan Paquete y no seleccionó el Paquete, verifique.', cancelButton: false });
      return;
    }

    if (countAnticipos > 1) {
      this.eventsService.publish('home:showAlert', { message: 'Solo se permite colocar una sola vez un concepto de Anticipos, verifique.', cancelButton: false });
      return;
    }

    if (countAnticipos > 0 && countNoAnticipos > 0) {
      this.eventsService.publish('home:showAlert', { message: 'No se permite capturar conceptos de anticipo con otros conceptos, los anticipos deben de facturarse de manera individual por razones fiscales, verifique.', cancelButton: false });
      return;
    }

    if (cCostos > 0) {
      this.eventsService.publish('home:showAlert', { message: 'Hay Productos con precio menor o igual al costo de reposición y no se permite la emisión (se requiere token), verifique.', cancelButton: false });
      return;
    }

    if (cCantidades > 0) {
      this.eventsService.publish('home:showAlert', { message: 'Hay Productos con cantidad menor o igual a cero, verifique.', cancelButton: false });
      return;
    }

    if (cPRecios > 0) {
      this.eventsService.publish('home:showAlert', { message: 'Hay Productos con precio CERO, verifique.', cancelButton: false });
      return;
    }
    if (!this.isValidConcepts()) { return; }

    if (!ent.Vendedor || ent.Vendedor?.Id == 0) {
      this.eventsService.publish('home:showAlert', { message: 'Debe de indicar un vendedor.', cancelButton: false });
      return;
    }

    if (ent.FechaCancelacion) {
      this.eventsService.publish('home:showAlert', { message: 'No se puede modificar un Documento de Venta CANCELADO.', cancelButton: false });

      return;
    }

    if (ent.Conceptos.length == 0) {
      this.eventsService.publish('home:showAlert', { message: 'Debe de indicar los conceptos del documento.', cancelButton: false });

      return;
    }

    if (ent.Cliente?.Id == 0 || !ent.Cliente) {
      this.eventsService.publish('home:showAlert', { message: 'Debe de indicar el Cliente.', cancelButton: false });

      return;
    }

    if (ent.EnFacturaGlobal) {
      this.eventsService.publish('home:showAlert', { message: 'Este ticket ya fue facturado, no se puede modificar.', cancelButton: false });

      return;
    }

    if (this.esManufactura && !ent.FechaCompromisoEntrega) {
      this.eventsService.publish('home:showAlert', { message: 'Indique la fecha de entrega del producto terminado.', cancelButton: false });
      return;
    }

    if (this.configuracion!.UsarModoPuntoDeVenta && !ent.Caja) {
      if (this.listaCajas.length > 0) {
        const c = this.listaCajas[0];
        ent.Caja = {
          Id: c.Id,
          Clave: c.Clave,
          Nombre: c.Nombre,
        };
      }
      if (!ent.Caja) {
        this.eventsService.publish('home:showAlert', { message: 'Debe seleccionar una Caja.', cancelButton: false });
        return;
      }
    }

    if (validar && this.anticipoDisponible > 0 && this.getAnticipoAplicado == 0 && countAnticipos == 0) {
      const t: any = this.cboComp;
      if (t.selectedItem.TipoDeComprobante == "I") {
        this.eventsService.publish('home:showAlert', {
          textAccept: "Si, Regresar y aplicar anticipos",
          textCancel: "No, continuar sin aplicar",
          message: "El Cliente tiene anticipo disponible que no se ha aplicado, ¿desea continuar sin aplicar?",
          onConfirm: (data: AlertResponse) => {
            if (!data.isAccept) {
              setTimeout(() => {
                this.save(false);
              }, 50);
            } else {
              setTimeout(() => {
                this.openBalanceAnt(() => {
                  this.save();
                });
              }, 50);
            }
          }
        });
        return;
      }
    }

    this.setPayments();

    this.openPaymentsDetails((payments: VentaFormaPago[] | null) => {
      if (payments) {
        this.backorder(() => {
          this.verificarEntregaNuestra(() => {
            this.verificaNotaCredito(() => {
              this.eventsService.publish('home:isLoading', { isLoading: true });
              ent = { ...this.myForm.value };
              ent.FechaEmisionString = moment(ent.FechaEmision).format("YYYY-MM-DDTHH:mm:ss");
              ent.Conceptos = this.source.filter(P => P.Cantidad > 0);
              ent.FormasPago = payments;
              ent.TotalKilos = this.getKilos;
              if (!ent.EntregaNuestra) ent.ArchivarCargaEntrega = false;
              if (ent.Id == 0) ent.Vigente = true;
              this.envio = ent;
              this.http.post<Result>(`${this.baseUrl}/Ventas/GuardarVenta`, ent).subscribe((result) => {
                if (result.success) {
                  this.prcentajeAvanceResguardo = 0;
                  this.listaHistoricoRemisiones = [];
                  const v: Venta = JSON.parse(result.message);
                  this.getCustomerAdvanceBalance(v.Empresa.Id, v.Cliente.Id);
                  this.fServices.findEntityById(v.Id).subscribe((venta) => {
                    this.eventsService.publish('home:isLoading', { isLoading: false });
                    this.myForm.reset(venta);
                    Swal.fire({ position: 'center', icon: 'success', title: 'Se guardó correctamente', showConfirmButton: false, timer: 1000 }).then(() => {
                      if (this.configuracion!.ImprimirAlGrabar) {
                        this.print(() => {
                          this.sendEmail();
                        });
                      } else {
                        this.sendEmail();
                      }
                    });
                  })

                } else {
                  this.eventsService.publish('home:isLoading', { isLoading: false });
                  this.eventsService.publish('home:showAlert', { message: `${result.message}`, cancelButton: false, icon: ' fa-triangle-exclamation text-yellow' });
                }
              })
            });
          });
        });
      }
    });
  }

  backorder(cb: any) {
    const ent = this.myForm.value;
    const comp = this.listaComprobantes.filter(P => P.Id == ent.DocumentoVenta.Id)[0];
    if ((comp.LlevaBackOrder && !comp.NoPreguntarResguardo) && !this.esAnticipo()) {
      this.eventsService.publish('home:showAlert', {
        textAccept: comp.TextoAfirmacion2,
        textCancel: comp.TextoAfirmacion1,
        icon: '',
        message: `<b>${comp.TextoPregunta}</b>`, onConfirm: (data: AlertResponse) => {
          if (data.isAccept) {
            this.myForm.controls["EsResguardo"].setValue(true);
            cb()
          } else if (data.isCancel) {
            this.myForm.controls["EsResguardo"].setValue(false);
            cb()
          }
        }
      });
    } else {
      if ((comp.LlevaBackOrder && comp.NoPreguntarResguardo) && !this.esAnticipo()) {
        this.myForm.controls["EsResguardo"].setValue(true);
        cb();
      } else {
        cb();
      }
    }
  }

  get getFiltroConsignado(): string {
    if (this.myForm.value.Cliente) {
      if (this.myForm.value.Cliente.Id > 0) {
        return `IdClientePertenece = ${this.myForm.value.Cliente.Id}`;
      }
    }
    return `IdClientePertenece = 0`;
  }

  get getAnticipoAplicado(): number {
    let suma = 0;

    if (this.myForm.value.FacturasAnticipos) {
      if (this.myForm.value.FacturasAnticipos.length > 0) {
        this.myForm.value.FacturasAnticipos.forEach((el: FacturaAnticipoRelacionada) => {
          suma += el.ImporteAplicado;
        });
      }
    }

    return suma;
  }

  setPayments() {
    if (this.myForm.value.Cliente) {
      const t: any = this.cboClase;
      let metodopago = t.selectedItem.metodopago;
      if (!metodopago) {
        if (this.myForm.value.Clase.Metodo) {
          metodopago = this.myForm.value.Clase.Metodo.Id;
        }
      }

      if (this.myForm.value.FormasPago && this.getAnticipoAplicado > 0) {
        this.myForm.value.FormasPago = null;
      }

      if (metodopago == 1) {
        if (!this.myForm.value.FormasPago || this.myForm.value.FormasPago?.length == 0) {
          this.myForm.controls["FormasPago"].setValue([{
            Id: 0,
            FormaPago: this.myForm.value.Cliente.FormaDePago.Id == 22 ? { Id: 1, Clave: '01', Nombre: 'Efectivo' } : this.myForm.value.Cliente.FormaDePago,
            FormaPagoNombre: this.myForm.value.Cliente.FormaDePago.Id == 22 ? 'Efectivo' : this.myForm.value.Cliente.FormaDePago.Nombre,
            Importe: this.utileService.round(this.myForm.value.Total - this.getAnticipoAplicado),
          }]);
        }
      }


      if (metodopago == 2) {
        this.myForm.controls["FormasPago"].setValue([{
          Id: 0,
          FormaPago: { Id: 22, Clave: '99', Nombre: 'Por definir' },
          FormaPagoNombre: "Por Definir",
          Importe: this.utileService.round(this.myForm.value.Total - this.getAnticipoAplicado),
        }]);
      }

    }
  }

  openPaymentsSearch() {
    this.setPayments();
    this.openPaymentsDetails((e: any) => {
      if (e) {
        this.myForm.controls["FormasPago"].setValue(e);
      }
    })
  }

  openDireccionEntrega() {
    const ent: Venta = this.myForm.value;
    if (ent.Cliente?.Id > 0) {
      const b: any = this.modalDireccion;
      this.ms.openModal(b, (e: any) => {
        if (e) {
          e.Id = 0;
          e.Baja = false;
          e.Eliminado = false;

          this.myForm.controls["Direccion"].setValue(e);
        }
      }, 'xl')
    } else {
      this.eventsService.publish('home:showAlert', { message: `Primero indique un Cliente.`, cancelButton: false });
    }
  }

  openCartaPorte() {
    const ent: Venta = this.myForm.value;
    if (ent.CartaPorteId > 0) {
      this.eventsService.publish('home:isLoading', { isLoading: true });
      this.cpService.getById(ent.CartaPorteId).subscribe((result) => {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        this.myForm.controls["CartaPorteString"].setValue(result.message);
        setTimeout(() => { this.openCP(); }, 150);
      });
    } else {
      this.openCP();
    }
  }

  openCP() {
    const b: any = this.modalCartaPorte;
    this.ms.openModal(b, (e: any) => {
      if (e) {
        const str = JSON.stringify(e);
        this.myForm.controls["CartaPorteString"].setValue(str);
      }
    })
  }

  get getCartaPorte(): CartaPorte | null {
    if (this.myForm.value.CartaPorteString) {
      const c: CartaPorte = JSON.parse(this.myForm.value.CartaPorteString)
      return c;
    }
    return null
  }

  verificaNotaCredito(cb: any) {
    //validamos si es nota de credito, si no
    this.openNotaCredito(cb);
  }

  imprimirRelacionado(item: InvoicesStatus) {
    if (item.idVenta > 0) {
      this.imprimirPDF({ Id: item.idVenta }, null, true);
    } else if (item.idRecibo > 0) {
      this.printRecibo(item.idRecibo);
    }
  }

  printRecibo(idRep: number) {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.fServices.printRep(idRep, () => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
    });
  }

  verificarEntregaNuestra(cb: any) {
    const sel: boolean = this.myForm.value.EntregaNuestra;
    if (this.mostrarEntregaNuestra && !this.myForm.value.EsResguardo && !this.esAnticipo()) {
      const textoHTML = '<div class="card text-center w-350px mt-3"><div class="card-header fw-bold">Verifique la siguiente información</div><div class="card-body"><i class="fas fa-cart-flatbed-suitcase fa-7x mb-3"></i><p class="card-text pulse fs-15px">Si el cliente se llevará la mercancía, asegurese de que se llevará todo en este momento, en caso de que no se lo lleve completamente, envíar a carga y entrega como si nosotros se lo fueramos a entregar.</p></div></div>';
      this.eventsService.publish('home:showAlert', {
        icon: '',
        textAccept: "Si, Continuar",
        message: sel ? 'Ha seleccionado que <b>SI se entrega por nuestra cuenta</b><br><span class="text-red-600"> Si la entrega la haremos otro día, seleccione la opción de Archivar entrega</span><br>¿Continuar?' : `<b>Ha seleccionado que <b>NO se entrega por nuestra cuenta</b> ¿Continuar?</b><br>${textoHTML}`,
        onConfirm: (data: AlertResponse) => {
          if (data.isAccept) {
            cb && cb();
          }
        }
      });
    } else {
      if (this.myForm.value.EsResguardo) {
        this.myForm.controls["EntregaNuestra"].setValue(false);
        this.myForm.controls["ArchivarCargaEntrega"].setValue(false);
      }
      cb && cb();
    }
  }

  openPaymentsDetails(cb: any) {
    const b: any = this.ctrlFormaPago;
    this.ms.openModal(b, (e: any) => {
      cb && cb(e);
    })
  }

  openBalanceAnt(cb: any = null) {

    if (this.anticipoDisponible == 0) {
      this.eventsService.publish('home:showAlert', { message: `No hay anticipos disponibles para el Cliente indicado.`, cancelButton: false });
      return;
    }

    if (this.myForm.value.Total == 0) {
      this.eventsService.publish('home:showAlert', { message: `El documento debe ser mayor a Cero para poder relacionar un Anticipo.`, cancelButton: false });
      return;
    }

    this.fServices.getDoctoType(this.myForm.value.DocumentoVenta.Id).subscribe((result) => {

      if (result.message != "I") {
        this.eventsService.publish('home:showAlert', { message: `Solo se puede relacionar un Anticipo a una Factura."`, cancelButton: false });
        return;
      }

      if (this.myForm.value.Id > 0) {
        this.eventsService.publish('home:showAlert', { message: `Indique un nuevo Documento para poder relacionar.`, cancelButton: false });
        return;
      }

      const b: any = this.anticiposDisponibles;
      this.ms.openModal(b, (lista: any) => {
        const ent = this.myForm.value;
        ent.FacturasAnticipos = [];

        lista.forEach((el: AnticipoDisponibleDTO) => {
          ent.FacturasAnticipos = [...ent.FacturasAnticipos, {
            UUIDFacturaRelacionada: el.uuidFacturaAnticipo,
            //IdFacturaRelacionada: el.idFacturaAnticipo,
            ImporteAplicado: el.utilizar
          }]
        });
        this.myForm.controls["FacturasAnticipos"].setValue(ent.FacturasAnticipos);
        //cb && cb(e);
      }, 'lg')
    });
  }

  conversionClick(index: number) {
    this.indexEditing = index;
    let item = this.source[this.indexEditing];
    this.conversiones = item.Producto!.OtrasUnidades;
    this.unidadFinal = item.UnidadNombre;
    this.openConvertions((e: any) => this.aceptConversions(e, true));
  }

  openConvertions(cb: any) {
    const b: any = this.ctrlConversiones;
    this.ms.openModal(b, (e: any) => {
      cb && cb(e);
    })
  }


  openCustomerCatalog() {
    const b: any = this.ctrlCatClientes;
    this.ms.openModal(b, (e: any) => {

    }, 'xl')
  }

  imprimirOrdenManufactura() {
    this.fServices.imprimirOrdenManufactura(this.myForm.value.IdOrdenManufactura);
  }

  solicitarCancelacion() {
    this.canCancelSAT();
  }

  cambiarCuenta() {
    this.eventsService.publish('home:showAlert', {
      message: "¿Está seguro de cambiar la Clase de Venta de esta Factura?", onConfirm: (data: AlertResponse) => {
        if (data.isAccept) {
          const b: any = this.ctrlCambiarCuenta;
          setTimeout(() => {
            this.ms.openModal(b, (e: any) => {
              if (e) {
                if (e.Id == this.myForm.value.Clase?.Id) {
                  this.eventsService.publish('home:showAlert', { message: "La Clase de Venta es igual a la ya indicado en la Factura actual.", cancelButton: false });
                } else {
                  const idActual = this.myForm.value.Clase?.Id;
                  this.eventsService.publish('home:isLoading', { isLoading: true });
                  this.fServices.actualizarClaseVentaFactura(idActual, e.Id, this.myForm.value.Id).subscribe((result) => {
                    this.eventsService.publish('home:isLoading', { isLoading: false });
                    if (result.success) {
                      this.myForm.controls["Clase"].setValue(e);
                      Swal.fire({ position: 'center', icon: 'success', title: 'Se actualizó la Clase de Venta.', showConfirmButton: false, timer: 2000 }).then(() => { });
                    } else {
                      this.eventsService.publish('home:showAlert', { message: `${result.message}`, cancelButton: false, icon: ' fa-triangle-exclamation text-yellow' });
                    }
                  });
                }
              }
            });

          }, 100);

        }
      }
    });
  }

  cambiarVendedor() {
    this.eventsService.publish('home:showAlert', {
      message: "¿Está seguro de cambiar el Vendedor de este documento?", onConfirm: (data: AlertResponse) => {
        if (data.isAccept) {
          const b: any = this.ctrlCambiarVendedor;
          setTimeout(() => {
            this.ms.openModal(b, (e: any) => {
              if (e) {
                if (e.Id == this.myForm.value.Vendedor?.Id) {
                  this.eventsService.publish('home:showAlert', { message: "El Vendedor seleccionado es igual al ya indicado en el documento.", cancelButton: false });
                } else {
                  this.myForm.controls["Vendedor"].setValue(e);
                  this.eventsService.publish('home:isLoading', { isLoading: true });
                  this.fServices.actualizarVendedorFactura(e.Id, this.myForm.value.Id).subscribe((result) => {
                    this.eventsService.publish('home:isLoading', { isLoading: false });
                    if (result.success) {
                      Swal.fire({ position: 'center', icon: 'success', title: 'Se actualizó el vendedor.', showConfirmButton: false, timer: 2000 }).then(() => { });
                    } else {
                      this.eventsService.publish('home:showAlert', { message: `${result.message}`, cancelButton: false, icon: ' fa-triangle-exclamation text-yellow' });
                    }
                  });
                }
              }
            });

          }, 100);

        }
      }
    });
  }

  cambiarEntrega() {
    this.fServices.puedeReImprimirSalidaPorVenta(this.myForm.value.Id).subscribe((result) => {
      if (result) {
        const esNuestra = this.myForm.value.EntregaNuestra;
        this.eventsService.publish('home:showAlert', {
          message: `¿Está seguro de cambiar a ${(esNuestra ? '<b>EL CLIENTE SE LO LLEVA</b>' : '<b>NOSOTROS LO ENTREGAMOS</b>')}?`, onConfirm: (data: AlertResponse) => {
            if (data.isAccept) {
              this.eventsService.publish('home:isLoading', { isLoading: true });
              if (!esNuestra) {
                this.fServices.cambiarCargaEntregaNuestra(this.myForm.value.Id).subscribe((res) => {
                  this.eventsService.publish('home:isLoading', { isLoading: false });
                  if (res.success) {
                    this.findEntityByParams()
                    Swal.fire({ position: 'center', icon: 'success', title: 'Se cambió el Tipo de Entrega.', showConfirmButton: false, timer: 2000 }).then(() => { });
                  } else {
                    this.eventsService.publish('home:showAlert', { message: `Ocurrió un error al hacer el cambio de entrega: ${res.message}.`, cancelButton: false, icon: ' fa-triangle-exclamation text-yellow' });
                  }
                });
              } else {
                this.fServices.cambiarCargaEntregaClienteLleva(this.myForm.value.Id).subscribe((res) => {
                  this.eventsService.publish('home:isLoading', { isLoading: false });
                  if (res.success) {
                    this.findEntityByParams()
                    Swal.fire({ position: 'center', icon: 'success', title: 'Se cambió el Tipo de Entrega.', showConfirmButton: false, timer: 2000 }).then(() => { });
                  } else {
                    this.eventsService.publish('home:showAlert', { message: `Ocurrió un error al hacer el cambio de entrega: ${res.message}.`, cancelButton: false, icon: ' fa-triangle-exclamation text-yellow' });
                  }
                });
              }
            }
          }
        });
      } else {
        this.eventsService.publish('home:showAlert', { message: `No se puede hacer el cambio ya que la salida por venta ya fue impresa.`, cancelButton: false, icon: ' fa-triangle-exclamation text-yellow' });
      }
    });

  }

  canCancelSAT() {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    const params = new HttpParams().set("idVenta", this.myForm.value.Id);
    this.http.get<Result>(`${this.baseUrl}/Ventas/OcupaCancelarSAT`, { params }).subscribe((result) => {
      if (result.success) {
        this.verifyStatus();
      } else {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        this.openCancel();
      }
    })
  }

  cancelarIVA8() {
    let entrar = false;
    this.source.map((concepto, index) => {
      if (concepto.Producto) {
        if (concepto.Producto.Id > 0) {
          if (concepto.Impuestos) {
            concepto.Impuestos.map((imp) => {
              if (imp.TasaOCuota == 8) {
                entrar = true;
              }
            });
          }
        }
      }
    });
    if (entrar) {

      this.eventsService.publish('home:showAlert', {
        message: "Todo lo que tenga IVA = 8% se cambiará a 16% ¿Está seguro de continuar?", onConfirm: (data: AlertResponse) => {
          if (data.isAccept) {
            this.source.map((concepto, index) => {
              concepto.Impuestos = [];
            });
            this.myForm.controls["CanceloDecretoIVA"].setValue(true);
            this.calcTax(true, true);
          }
        }
      });
    } else {
      this.eventsService.publish('home:showAlert', { message: "No se encontraron impuestos al 8%.", cancelButton: false });
    }

  }

  verUUIDSRelacionados(enviarMensaje: boolean = false) {
    this.uuidsRelacionadosHijos = [];
    this.uuidsRelacionadosPadres = [];
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.fServices.consultarUUUIDSRelacionados(this.myForm.value.Id).subscribe((response) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (response) {
        if (!response.error) {
          if (enviarMensaje) {
            this.eventsService.publish('home:showAlert', {
              message: "El documento no es Cancelable porqué está relacionado a otros CFDIS, de clic en Aceptar para ver los UUIDS relacionados.", onConfirm: (data: AlertResponse) => {
                this.uuidsRelacionadosHijos = response.uuidsRelacionadosHijos ? response.uuidsRelacionadosHijos : [];
                this.uuidsRelacionadosPadres = response.uuidsRelacionadosPadres ? response.uuidsRelacionadosPadres : [];
                setTimeout(() => { this.openRelacionados(); }, 100);
              }
            });
          } else {
            //Aquí mostramos la ventanita con las relaciones padres e hijos
            if (!response.uuidsRelacionadosHijos && !response.uuidsRelacionadosPadres) {
              this.eventsService.publish('home:showAlert', { message: "El CFDI actual no tienen relación con otros CFDI.", cancelButton: false });
            } else {
              this.uuidsRelacionadosHijos = response.uuidsRelacionadosHijos ? response.uuidsRelacionadosHijos : [];
              this.uuidsRelacionadosPadres = response.uuidsRelacionadosPadres ? response.uuidsRelacionadosPadres : [];
              setTimeout(() => { this.openRelacionados(); }, 100);
            }
          }
        } else {
          this.eventsService.publish('home:showAlert', { message: response.error, cancelButton: false });
        }
      } else {
        this.eventsService.publish('home:showAlert', { message: "Ocurrio un error al consultar el Comprobante en el SAT.", cancelButton: false });
      }
    });
  }

  openRelacionados() {
    const b: any = this.modalRelacionados;
    this.ms.openModal(b, (e: any) => {
      this.uuidsRelacionadosHijos = [];
      this.uuidsRelacionadosPadres = [];
    })
  }

  cerrarRelaciones() {
    this.ms.closeModal(null);
  }

  verEstadoSAT() {
    const t: any = this.cboComp;
    if (t.selectedItem.TipoDeComprobante == "I" || t.selectedItem.TipoDeComprobante == "E" || t.selectedItem.TipoDeComprobante == "T") {
      this.eventsService.publish('home:isLoading', { isLoading: true });
      this.fServices.verificarEstadoSAT(this.myForm.value.Id).subscribe((result) => {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        if (result.success) {
          const r: CfdiEstadoSAT = JSON.parse(result.message);
          let div = `<p style="text-align:center;"><strong>Así se ve el CFDI en el SAT:</strong></p>
                        <p><strong>Estado: </strong>${r.Estado}</p>
                        <p><strong>Es Cancelable: </strong>${r.EsCancelable}</p>
                        <p><strong>Estatus de Cancelación: </strong>${r.EstatusCancelacion}</p>`
          this.eventsService.publish('home:showAlert', { message: div, cancelButton: false });
        } else {
          this.eventsService.publish('home:showAlert', { message: `No se pudo consultar el estatus del CFDI, posiblemente no se ha enviado al SAT por el PAC, recuerda que el PAC tiene hasta 48 horas para envíar los XMLS.`, cancelButton: false });
        }
      });
    } else {
      this.eventsService.publish('home:showAlert', { message: `Esta opción no aplica para el documento actual.`, cancelButton: false });
    }

  }

  verifyStatus() {
    this.eventsService.publish('home:isLoading', { isLoading: true });

    this.fServices.verificarEstadoSAT(this.myForm.value.Id).subscribe((result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result.success) {
        const r: CfdiEstadoSAT = JSON.parse(result.message);
        if (r.EsCancelable == "No cancelable") {
          //TODO: Consultamos los CFDIS relacionados y no mostramos el mensaje anterior
          this.verUUIDSRelacionados(true);
          return;
        }
        if (r.Estado == "No Encontrado") {
          this.eventsService.publish('home:showAlert', { message: `El CFDI no fue encontrado en los servidores del SAT, por favor intente de nuevo mas tarde.`, cancelButton: false });
          return;
        }
        this.estadoSAT = r;
        this.openCancel();
      } else {

        this.eventsService.publish('home:showAlert', {
          message: "No se pudo consultar el estatus del CFDI ¿desea continuar con la cancelación?", onConfirm: (data: AlertResponse) => {
            if (data.isAccept) {
              const r: CfdiEstadoSAT = JSON.parse(result.message);
              this.estadoSAT = r;
              setTimeout(() => { this.openCancel(); }, 151);
            }
          }
        });
      }
    });
  }

  proceedCancel(e: any) {
    let entrarAut: boolean = false;
    if (this.configuracion?.UsarProcesoSolicitudCancelacion) {
      const t: any = this.cboComp;
      let str = (`,${this.configuracion.DocumentosAplicanSolicitudCancelacion},`).trim();
      if (str.includes(`,${t.selectedItem.Clave},`)) {
        entrarAut = true;
      }
    }
    if (entrarAut) {
      this.eventsService.publish('home:showAlert', {
        message: "Se requiere que un Supervisor autorice ¿desea continuar con la cancelación?", onConfirm: (data: AlertResponse) => {
          if (data.isAccept) {

            const date = moment(e.Fecha).format("DD/MM/YYYY HH:mm:ss");
            this.eventsService.publish('home:isLoading', { isLoading: true });
            this.fServices.enviarSolicitudCancelacion(this.myForm.value.Id, e.MotivoCancelacion, e.MotivoSAT ? e.MotivoSAT.Id : 0, e.UUID, date).subscribe((result) => {
              this.eventsService.publish('home:isLoading', { isLoading: false });
              if (result.success) {
                Swal.fire({ position: 'center', icon: 'success', title: 'Se envió la solicitud a un Supervisor', showConfirmButton: false, timer: 3000 }).then(() => {
                  this.myForm.controls["TieneSolicitudCancelacion"].setValue(true);
                });
              } else {
                this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
              }
            });

          }
        }
      });


    } else {
      this.eventsService.publish('home:isLoading', { isLoading: true });
      const date = moment(e.Fecha).format("DD/MM/YYYY HH:mm:ss");
      const params = new HttpParams()
        .set("id", this.myForm.value.Id)
        .set("motivoCancelacion", e.MotivoCancelacion)
        .set("idMotivoSAT", e.MotivoSAT ? e.MotivoSAT.Id : 0)
        .set("fechaCancelacion", date)
        .set("uuidSustituye", e.UUID);
      this.http.get<Result>(`${this.baseUrl}/Ventas/CancelarComprobante`, { params }).subscribe((result) => {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        if (result.success) {
          this.estadoSAT = null;
          this.findEntityByParams()
        } else {
          this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
        }
      });
    }
  }


  openCancel(cb: any = null) {

    if (this.myForm.value.EnFacturaGlobal) {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      this.eventsService.publish('home:showAlert', { message: "El documento no puede cancelarse ya que está en una factura global.", cancelButton: false });
      return;
    }

    if (this.myForm.value.EnFacturaNormal) {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      this.eventsService.publish('home:showAlert', { message: "El documento no puede cancelarse ya que está facturado.", cancelButton: false });
      return;
    }

    const b: any = this.modalCancelOptiones;
    this.ms.openModal(b, (e: any) => {
      if (e) {
        if (cb) {
          cb();
        } else {
          this.proceedCancel(e)
        }
      }
    })
  }

  cancelarUUID() {
    Swal.fire({
      title: "Cancelar UUID:",
      input: "text",
      inputValue: "",
      inputAttributes: { autocapitalize: "off" },
      showCancelButton: true,
      confirmButtonText: "Enviar Cancelación",
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        if (result.value) {
          this.eventsService.publish('home:isLoading', { isLoading: true });
          this.fServices.cancelarUUID(result.value).subscribe((result) => {
            this.eventsService.publish('home:isLoading', { isLoading: false });
            if (result.success) {
              this.eventsService.publish('home:showAlert', { message: "Se ha cancelado", cancelButton: false });
            } else {
              this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
            }
          })
        } else {
          this.eventsService.publish('home:showAlert', { message: "Debe de indicar el UUID a cancelar.", cancelButton: false, icon: 'fa-triangle-exclamation text-yellow' });
        }
      }
    });

  }

  sendEmail() {
    if (this.myForm.value.Id > 0) {
      this.eventsService.publish('home:isLoading', { isLoading: true });
      this.fServices.obtenerCorreosCliente(this.myForm.value.Cliente.Id).subscribe((result) => {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        const correos = result.message;
        Swal.fire({
          title: "Envíar email para:",
          input: "text",
          inputValue: correos,
          inputAttributes: { autocapitalize: "off" },
          showCancelButton: true,
          confirmButtonText: "Enviar",
          showLoaderOnConfirm: true,
          allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
          if (result.isConfirmed) {
            if (result.value) {
              this.eventsService.publish('home:isLoading', { isLoading: true });
              this.fServices.sendEmail(this.myForm.value.Id, result.value).subscribe((result) => {
                this.eventsService.publish('home:isLoading', { isLoading: false });
                if (result.success) {
                  this.eventsService.publish('home:showAlert', { message: "Se ha enviado por e-mail", cancelButton: false });
                } else {
                  this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
                }
              });
            } else {
              this.eventsService.publish('home:showAlert', { message: "Debe de indicar por lo menos un e-mail.", cancelButton: false, icon: 'fa-triangle-exclamation text-yellow' });
            }
          }
        });
      });
    } else {
      this.eventsService.publish('home:showAlert', { message: "El documento no se ha guardado.", cancelButton: false });
    }
  }

  downloadFiles() {
    if (this.myForm.value.Id > 0) {
      this.eventsService.publish('home:isLoading', { isLoading: true });
      this.fServices.canPrint(this.myForm.value.DocumentoVenta.Id, this.myForm.value.Id).subscribe((result) => {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        if (result.success) {
          this.fServices.downloadFiles(this.myForm.value.Id);
        }
      })

    } else {
      this.eventsService.publish('home:showAlert', { message: "El documento no se ha guardado.", cancelButton: false });
    }
  }


  get getIdDocto(): number {
    return this.myForm.value.DocumentoVenta.Id;
  }



  getComps() {
    const params = new HttpParams().set("idEmpresa", this.info.empresa!.numero).set("idSucursal", this.info.sucursal!.numero);
    return this.http.get<ComboBoxEntity[]>(`${this.baseUrl}/Ventas/ObtenerListaComprobantes`, { params }).subscribe((lista) => {
      if (lista.length > 0) {
        this.listaComprobantes = lista;
        this.listaComprobantes.map(P => { P.ClaveNombre = `${String(P.Clave).padStart(3, '0')} ${P.Nombre}` });
        if (lista.length == 1) {
          this.documentosPuedeCopiar = lista[0].DocumentosPuedeCopiar!;
          this.getSeries(lista[0].Id);
          this.changueTitle(lista[0].Nombre);
        } else {
          this.eventsService.publish('home:isLoading', { isLoading: false });
          const t: any = this.cboComp;
          setTimeout(() => {
            t.tagInput.nativeElement.focus();
          }, 100);
        }
      } else {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        this.eventsService.publish('home:showAlert', { message: "No se encontró ningúna Serie de Ventas configurada para la Emp/Suc actuales.", cancelButton: false });
      }
    })
  }

  getSeries(idDocumento: number) {
    const txt: any = this.cboSerie;
    txt.tagInput.nativeElement.value = "";
    this.fServices.getSeries(this.info.empresa!.numero, this.info.sucursal!.numero, idDocumento).subscribe((lista) => {
      if (lista.length > 0) {
        this.listaSeries = lista;
        if (lista.length == 1) {
          this.cargandoInicio = true;
          this.getEmptyEntity(this.listaSeries[0].Id, idDocumento);
        } else {
          this.eventsService.publish('home:isLoading', { isLoading: false });
          setTimeout(() => {
            let ent = this.myForm.value;
            ent.Empresa = { Id: this.info.empresa!.numero };
            ent.Sucursal = { Id: this.info.sucursal!.numero };
            ent.FechaVencimiento = new Date();
            ent.DocumentoVenta = { Id: idDocumento };
            txt.tagInput.nativeElement.value = '';
            this.sourceReset();
            txt.tagInput.nativeElement.focus()
            this.myForm.reset(ent);
          }, 150);
        }
      }
    })


  }

  getEmptyEntity(idSerie: number = 0, idDocto: number = 0) {
    this.cService.getEmptyEntity("Venta").subscribe((ent) => {
      this.initializeEntity(ent, idSerie, idDocto)
    })
  }

  getNextFolio(ent: Venta) {
    const params = new HttpParams().set("idEmpresa", ent.Empresa.Id)
      .set("idSucursal", ent.Sucursal.Id)
      .set("idDocumento", ent.DocumentoVenta.Id)
      .set("serie", ent.SerieString);
    this.http.get<number>(`${this.baseUrl}/Ventas/ObtenerSiguienteFolio`, { params }).subscribe((folio) => {
      ent.Folio = folio;
      this.getDate(ent);
    })
  }

  getDate(ent: Venta) {
    this.fServices.ObtenerFechaPorSucursal(ent.Sucursal.Id).subscribe((result) => {
      //2024-01-29T10:23:49
      const f = result.message.split('T')[0].split('-');
      const t = result.message.split('T')[1].split(':');
      ent.FechaEmision = new Date(parseInt(f[0]), parseInt(f[1]) - 1, parseInt(f[2]), parseInt(t[0]), parseInt(t[1]), parseInt(t[2]));
      this.eventsService.publish('home:isLoading', { isLoading: false });
      const docto = (this.listaComprobantes.filter(P => P.Id == ent.DocumentoVenta.Id).length > 0) ? this.listaComprobantes.filter(P => P.Id == ent.DocumentoVenta.Id)[0] : null;
      if (docto) {
        if (docto.Clasificacion == 3) {
          this.esTicket = true;
          this.searchCustomer("", true, true);
        }
      }
      this.myForm.reset(ent);
      setTimeout(() => {
        this.focusCliente();
      }, 150);
    })

  }

  blurCustomer(values: any) {
    if (values.after != values.before) {
      if (!isNaN(values.after)) {
        this.searchCustomer(values.after, false, true);
      } else {
        const txt: any = this.txtCliente;
        txt.tagInput.nativeElement.value = values.before;
      }
    }
  }

  enterCliente() {
    const txt: any = this.txtCliente;
    let claveCliente: string = "";
    if (this.myForm.value.Cliente) {
      claveCliente = String(this.myForm.value.Cliente.Clave)
      if (claveCliente == txt.tagInput.nativeElement.value) return;
    }
    this.searchCustomer(txt.tagInput.nativeElement.value, false, true);
  }

  blurFolio(values: any) {
    if (values.after != values.before) {
      this.findEntityByParams();
    }
  }

  findEntityByParams() {
    const ent = this.myForm.value;
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.fServices.findEntityByParams(ent.Empresa.Id, ent.Sucursal.Id, ent.DocumentoVenta.Id, ent.SerieString, ent.Folio).subscribe((venta) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      this.setEntity(venta);
    });
  }

  addDays(date: Date, days: number) {
    date.setDate(date.getDate() + days);
    return date;
  }

  getCustomerAdvanceBalance(idEmp: number, idCli: number) {
    this.fServices.getCustomerAdvanceBalance(idEmp, idCli).subscribe((saldo) => this.anticipoDisponible = saldo);
  }

  searchCustomer(clave: string, isTicket: boolean = false, sendFocus: boolean = false) {
    this.blockCombos = true;
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.baseService.getClientePorClave(this.myForm.value.Empresa.Id, Number(clave), isTicket).subscribe((cliente) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      let ent = this.myForm.value;
      if (cliente) {
        ent.Cliente = cliente;
        ent.UsoCfdi = cliente.UsoCfdi;
        const t: any = this.cboComp;
        if (t.selectedItem.TipoDeComprobante == "E") {
          ent.UsoCfdi = {
            Id: 2,
            Clave: 'G02',
            Nombre: 'Devoluciones, descuentos o bonificaciones'
          };
        }
        if (cliente.Direccion) {
          if (!cliente.Direccion.NombreRecibe) {
            cliente.Direccion.NombreRecibe = "";
          }
        }
        ent.Direccion = cliente.Direccion;
        ent.Condicion = cliente.CondicionDePago;
        ent.Clase = cliente.ClaseVenta;
        ent.Vendedor = cliente.Vendedor;
        ent.Moneda = cliente.Moneda;
        this.esMonedaExtranjera = cliente.Moneda.EsExtranjera;
        ent.FechaVencimiento = new Date();
        if (cliente.ClaseVenta.CondicionPago) {
          if (cliente.ClaseVenta.CondicionPago.Dias > 0) {
            ent.FechaVencimiento = this.addDays(ent.FechaVencimiento, cliente.ClaseVenta.CondicionPago.Dias);
          } else {
            ent.Condicion = cliente.ClaseVenta.CondicionPago;
          }
        } else if (cliente.CondicionDePago.Dias > 0) {
          ent.FechaVencimiento = this.addDays(ent.FechaVencimiento, cliente.CondicionDePago.Dias);
        }
        this.setAdress(ent.Cliente.Direccion);
        const txt: any = this.txtCliente;
        txt.tagInput.nativeElement.value = cliente.Clave;

        const docto: ComboBoxEntity = this.listaComprobantes.filter(P => P.Id == this.myForm.value.DocumentoVenta.Id)[0];
        if (docto.ObligarCopiar && ent.Id == 0) {
        }
        //sendFocus && this.initEditor(0, 1);
        // if (sendFocus) {
        //   const txt: any = this.cboConsignado;
        //   txt.tagInput.nativeElement.focus()
        // }
        if (this.myForm.value.Id == 0) {
          setTimeout(() => {
            this.initEditor(0, 1);
          }, 100);
        }

        this.getCustomerAdvanceBalance(ent.Empresa.Id, cliente.Id);
      } else {
        this.anticipoDisponible = 0;
        ent.Cliente = null;
        ent.UsoCfdi = null;
        ent.Direccion = null;
        ent.Condicion = null;
        ent.Clase = null;
        ent.Vendedor = null;
        ent.Moneda = null;
        this.setAdress(undefined);
        const txt: any = this.txtCliente;
        txt.tagInput.nativeElement.value = "";
        if (!isTicket) {
          setTimeout(() => { this.eventsService.publish('home:showAlert', { icon: ' fa-triangle-exclamation text-yellow', message: "No se encontró el cliente indicado.", cancelButton: false }); }, 150);
        }
      }
      this.myForm.reset(ent);
    })
  }

  get getBlockCombos(): boolean {
    return this.blockCombos;
  }




  setAdress(address: Direccion | undefined) {
    if (!address) {
      this.address = "";
    } else {
      this.address = `${address.Calle} ${address.NumeroExterior}, ${address.Colonia} ${address.Localidad}, ${address.Estado} ${address.Pais}, CP: ${address.CodigoPostal}`
    }

  }

  clicIcon(value: any) {
    this.openCustomerSearch();
  }


  clicConsignado() {
    const ent = this.myForm.value;
    if (ent.Cliente?.Id > 0) {
      const b: any = this.modalConisgnado;
      this.ms.openModal(b, (e: any) => {

      }, 'xl')

    } else {
      this.eventsService.publish('home:showAlert', { message: "Primero debe de indicar un Cliente.", cancelButton: false });
    }
  }

  openHistorico() {
    const b: any = this.ctrlHistorico;
    this.ms.openModal(b, (e: any) => {
      if (e) {
        if (e.ver) {
          this.eventsService.publish('home:isLoading', { isLoading: true });
          this.fServices.findEntityById(e.id).subscribe((venta) => {
            this.eventsService.publish('home:isLoading', { isLoading: false });
            this.setEntity(venta);
          })
        } else if (e.copiar) {

        }
      }
    }, 'xl')

  }

  clicExistencia(index: number) {
    this.indexEditing = index;
    const prod = this.source[index].Producto;
    if (prod?.Inventariable && prod?.ManejaPaquetesLotes) {
      this.openExistencias();
    }
  }

  openExistencias() {
    if (this.source[this.indexEditing].Producto?.Inventariable) {
      const b: any = this.ctrlExistencias;
      this.ms.openModal(b, (e: ExistenciaProductoPaquete | null) => {
        if (e) {
          this.source[this.indexEditing].Cantidad = e.existencia;
          this.source[this.indexEditing].NumeroLotePaquete = e.identificador;
        } else {
          this.source[this.indexEditing].NumeroLotePaquete = '';
        }
        this.calcTax();
        setTimeout(() => {
          this.initEditor(this.indexEditing, 3);
        }, 50);

      }, 'xl')
    } else {
      this.eventsService.publish('home:showAlert', { message: "El Producto no es inventariable.", cancelButton: false });
    }
  }

  openProductsSearch() {
    if (this.busquedaAbierta) return;
    this.busquedaAbierta = true;
    const b: any = this.ctrlBusquedaProductos;
    this.ms.openModal(b, (e: any) => {
      this.busquedaAbierta = false;
      this.searchProduct(e.Clave);
    }, 'xl')
  }

  openCustomerSearch() {
    const b: any = this.ctrlBusqueda;
    this.ms.openModal(b, (e: any) => {
      this.searchCustomer(e.Clave);
    }, 'lg')
  }


  initializeEntity(ent: any, idSerie: number = 0, idDocto: number = 0) {
    ent.Empresa = { Id: this.info.empresa!.numero };
    ent.Sucursal = { Id: this.info.sucursal!.numero };
    ent.FechaVencimiento = new Date();

    if (this.listaCajas.length > 0) {
      const c = this.listaCajas[0];
      ent.Caja = {
        Id: c.Id,
        Clave: c.Clave,
        Nombre: c.Nombre,
      };
    }
    if (idSerie > 0) {
      const serie = this.listaSeries.filter((P) => P.Id == idSerie)[0];
      ent.Serie = { Id: idSerie };
      ent.SerieString = serie.Serie;
      this.esManufactura = serie.EsManufactura!;
      if (serie.EsManufactura) {
        ent.FechaCompromisoEntrega = new Date();
      }
      this.mostrarEntregaNuestra = serie.SolicitarEntregaNuestra!;
      setTimeout(() => {
        this.onSelectedItem(serie, "Serie");
      }, 300);
    }
    ent.DocumentoVenta = { Id: idDocto };
    const txt: any = this.txtCliente;
    txt.tagInput.nativeElement.value = '';
    this.sourceReset();
    this.getNextFolio(ent);
  }

  elementoSeleccionado(elemento: any) {
    this.searchCustomer(elemento.Clave, false, true);
  }

  initPrinterService(nombreImpresora: string = "") {
    this.printerService.initPrinterService((resp: any) => {
      console.log("La respuesta del QZ Tray: " + resp)
      if (resp) {
        if (nombreImpresora) {
          this.printerService.findPrinter(nombreImpresora, (resp: any) => {
            console.log(resp);
            if (!resp) {
              this.eventsService.publish('home:showAlert', {
                cancelButton: false,
                message: `La impresora <strong>${nombreImpresora}</strong> no está instalada en este equipo, favor de verificar para poder continuar con la emisión.`,
                onConfirm: (data: AlertResponse) => {
                  this.tabsNavService.closeCurrentTab();
                }
              });
            } else {
              this.printerService.printerStatus((resp: any) => {
                console.log("El Status de la impresora desde la pantalla de facturación: " + resp.texto);
                this.statusImpresora = resp.texto;
                this.statusImpresoraColor = resp.success ? "bg-green" : "bg-red";
              });
            }
          });
        }
      }
    });
  }



  openArchivar(value: boolean) {

    if (value) {
      const b: any = this.modalArchivarFactura;
      this.ms.openModal(b, (e: any) => {
        if (e) {
          this.proceedArchivar(e)
        }
      });
    } else {
      this.myForm.get('FechaProgramadaEnvio')?.setValue(null);
      this.myForm.get('ComentarioEnvio')?.setValue("");
    }
  }

  proceedArchivar(e: any) {
    this.myForm.get('FechaProgramadaEnvio')?.setValue(e.Fecha);
    this.myForm.get('ComentarioEnvio')?.setValue(e.Comentario);
  }


  printTicket(t: string, imp: string) {
    let ticket = '<div style="margin-left: -10px;font-size:10px; font-family: arial; text-align: center; margin-bottom: 5px;">' +
      '    <table style="font-size:10px; width: 100%;text-align: center;">' +
      '        <tr><th style="font-size:15px;padding: 0;"> KN MATERIALES, S.A. DE C.V. </th></tr>' +
      '        <tr><td style="padding: 0;">NORMAN BORLAUG PROLONG SUR S/N, JARDINES DEL VALLE </td></tr>' +
      '        <tr><td style="padding: 0;"> CIUDAD OBREGÓN, CAJEME, SONORA </td></tr>' +
      '        <tr><td style="padding: 0;"> MÉXICO, C.P. 85196</td></tr>' +
      '        <tr><td style="padding: 0;">(644) 416-0333, (644) 416-1717</td></td>' +
      '        <tr><td style="padding: 0;"> Lugar de Expedición: CIUDAD OBREGÓN, SONORA</td></tr>' +
      '    </table>' +
      '</div>' +
      '<div style="margin-left: -10px;  font-family: arial; margin-bottom: 10px;">' +
      '    <table style="font-size:10px;letter-spacing: -0.5pt; width: 100%;">' +
      '        <tr><td colspan="4"><p style="border: 0.5px solid; width: 100%;margin: 0;"></p></td></tr>' +
      '        <tr><td style="width:40px">Código</td><td style="width:65px; text-align: end;">Cantidad</td>' +
      '            <td style="text-align: right;width:40px">Precio</td>' +
      '            <td style="padding-left: 5px;text-align: right;width:45px;">Importe</td>' +
      '        </tr>' +
      '        <tr>' +
      '            <td>11020001</td>' +
      '            <td colspan="3"> COLOR PARA CEMENTO CAFÉ</td>' +
      '        </tr>' +
      '        <tr>' +
      '            <td></td>' +
      '            <td style="text-align: end;">20</td>' +
      '            <td style="text-align: end;">55.63</td>' +
      '            <td style="text-align: end;">1,112.60</td>' +
      '        </tr>' +
      '        <tr>' +
      '            <td colspan="4">' +
      '                <p style="border: .5px solid; width: 100%; margin: 0;"></p>' +
      '            </td>' +
      '        </tr>' +
      '<tr>' +
      '    <td colspan="2" style="text-align: end;">Subtotal</td>' +
      '    <td colspan="2" style="text-align: end;">1,056.97</td>' +
      '</tr>' +
      '<tr>' +
      '    <td colspan="2" style="text-align: end;">IVA 16%</td>' +
      '    <td colspan="2" style="text-align: end;">169.12</td>' +
      '</tr>' +
      '<tr>' +
      '    <td colspan="2" style="text-align: end;">Total</td>' +
      '    <td colspan="2" style="text-align: end;">1,226.09</td>' +
      '</tr>' +
      '    </table>' +
      '    <p style="font-size:10px; margin-bottom: 20px;">UN MIL DOSCIENTOS VEINTISÉIS PESOS 09/100 M.N.</p>' +
      '    <p style="font-size:10px; text-align: center;">.</p>' +
      '</div>'
    this.printerService.printTicket(t, imp);
  }
}
