import { Component, ElementRef, ViewChild } from '@angular/core';
import { UserLogged } from 'src/app/auth/interfaces';
import { EnumDataType, TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { Empresa } from 'src/app/configuracion/interfaces/empresa.interface';
import { ClaseCompra, ClaseProveedor, ClasificacionProveedor, CondicionPagoProveedor, TipoProveedor } from '../../interfaces/clasecompra.interface';
import { Direccion } from 'src/app/configuracion/interfaces/direccion.interface';
import { c_RegimenFiscal, c_UsoCfdi } from 'src/app/ventas/interfaces/cliente.interface';
import { Moneda } from 'src/app/configuracion/interfaces/moneda.interface';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfiguracionService } from 'src/app/configuracion/service/configuracion.service';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { UtilsService } from 'src/app/service/utils.service';
import { ModalService } from 'src/app/service/modal.service';
import { EventReturn } from 'src/app/component-ui/interfaces/container-base.interface';
import { Proveedor } from '../../interfaces/proveedores.interface';
import { c_FormaPago } from 'src/app/ventas/interfaces/formapago.interface';
import { SearchConfiguration } from 'src/app/service/interfaces/data-search.interface';

@Component({
  selector: 'app-proveedores-page',
  templateUrl: './proveedores-page.component.html',
  styles: [
  ]
})
export class ProveedoresPageComponent {

  public itemsCombo: ComboBoxEntity[] = [];
  filter = "";
  info: UserLogged = { ambienteId: 0, numero: 0, clave: 0, nombreUsuario: "", nombreCompletoUsuario: "" };
  public EDT = EnumDataType;

  private newEntityObj = {
    Id: [0],
    Clave: [0],
    Nombre: [''],
    Rfc: ['', [Validators.required, Validators.pattern(this.configuracionService.RegExRfc)]],
    Empresa: [{} as Empresa],
    Clase: [{} as ClaseProveedor],
    Clasificacion: [{} as ClasificacionProveedor],
    Tipo: [{} as TipoProveedor],
    CondicionDePago: [{} as CondicionPagoProveedor],
    Direccion: [{} as Direccion],
    RegimenFiscal: [{} as c_RegimenFiscal],
    TipoTercero: [null],
    TipoOperacion: [null],
    NumRegIdTrib: [''],
    PaisResidencia: [null],
    Nacionalidad: [null],
    ClaseCompra: [{} as ClaseCompra],
    FormaDePago: [{} as c_FormaPago],
    Moneda: [{} as Moneda],
    Telefonos: [''],
    Correos: [''],
    Asientos: [[]],
    EsProntoPago: [false],
    NoGeneraDiot: [false],
    Baja: [false],
    DiasEntrega: [0],
    DiasEntregaMaxima: [0],
    DiasSeguridad: [0],
    SobrePedido: [true],
    CicloRevision: [0],
    PorcDescuentoProntoPago: 0
  };

  public myForm: FormGroup = this.fb.group(this.newEntityObj);

  @ViewChild('txtNombre')
  public txtNombre!: ElementRef<TextBoxComponent>;

  @ViewChild('txtClave')
  public txtClave!: ElementRef<TextBoxComponent>;


  constructor(private configuracionService: ConfiguracionService,
    private fb: FormBuilder, private containerService: ContainerBaseService,
    private utilsService: UtilsService,
    private ms: ModalService) {
  }
  ngOnInit(): void {
    this.info = this.utilsService.getUserLogged();
    this.filter = `Empresa.Id = ${this.info.empresa!.numero}`

    this.searchConfigurationProveedor = this.ms.GetSearchConfiguration("Proveedor", `Empresa.Id = ${this.info.empresa!.numero}`);

  }

  onBeforeSave(param: EventReturn) {
    param.callback(true);
  }

  onAfterSave(entity: Proveedor) {
    this.myForm.get('Id')?.setValue(entity.Id)
    //this.myForm.reset(entity);
  }

  onCloseAsiento(resp: any) {
    if (resp) {
      this.myForm.controls["Asientos"].setValue([...resp.items]);
    }
  }

  onItemSearched(ent: Proveedor): void {
    if (ent) {
      if (ent.Empresa) {
        this.filter = `Empresa.Id = ${ent.Empresa.Id}`;
      } else {
        this.filter = '';
        if (this.myForm.value) {
          if (this.myForm.value.Empresa) {
            ent.Empresa = this.myForm.value.Empresa;
            if (!ent.Empresa["Id"]) {
              this.myForm.controls["Empresa"].setValue({ Id: this.info.empresa!.numero, Clave: this.info.empresa!.clave, Nombre: this.info.empresa!.nombre })
              ent.Empresa = this.myForm.value.Empresa;
              this.filter = `Empresa.Id = ${this.info.empresa!.numero}`;
            } else {
              this.filter = `Empresa.Id = ${this.myForm.value.Empresa.Id}`
            }
          }
        }
      }
      this.myForm.reset(ent);
      this.focus('txtNombre')
    }
  }

  enterField(e: any) {
    const txt: any = this.txtClave;
    txt.tagInput.nativeElement.blur();
  }


  blur(values: any) {
    const txt: any = this.txtClave;
    if (isNaN(Number(txt.value))) return;
    if (!txt.value) {
      this.containerService.getLastEntity("Proveedor", this.filter).subscribe((ent) => {
        this.onChangueEntity(ent);
      });
      return;
    }
    if (values.before != values.after) {
      this.searchByField(txt.value);
    }
  }

  searchByField(value: any) {
    this.containerService.getEntityByClave("Proveedor", parseInt(value), this.filter).subscribe((ent) => {
      if (ent) {
        this.onChangueEntity(ent);
      } else {
        this.containerService.getEmptyEntity("Proveedor")
          .subscribe((ent) => {
            this.onChangueEntity({ ...ent, Clave: parseInt(value) });
          });
      }
    });
  }

  get getIsNew(): any {
    return this.myForm.value.Id == 0;
  }

  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombre;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  onChangueEntity(ent: any) {
    if (ent) {
      this.onItemSearched(ent);
    }
  }

  get getFilter(): string {
    return this.filter;
  }

  get getEnableText(): boolean {

    if (this.myForm.value) {
      if (this.myForm.value.Id == 0) {
        return false;
      }
    }

    return true;
  }

  onItemsComboSearched(items: ComboBoxEntity[]): void {
    this.itemsCombo = items;
  }

  get getItemsCombo(): ComboBoxEntity[] {
    return this.itemsCombo;
  }

  get getIsBaseCustomer(): boolean {
    if (this.myForm.value) {
      return this.myForm.value.ClienteBaseSistema;
    }
    return false;
  }

  get getFiltro(): string {
    if (this.myForm.value.Rfc) {
      if (this.myForm.value.Rfc?.length == 12) {
        return "Moral = true";
      } else if (this.myForm.value.Rfc?.length == 13) {
        return "Fisica = true";
      }
    }
    return "";
  }

  selectedCombo(entity: ComboBoxEntity, tipo: string) {
    this.myForm.controls[tipo].setValue(entity);
  }

  habilitarCicloRevision: boolean = false;
  clickChkSobrePedido(event: any) {
    this.habilitarCicloRevision = !this.habilitarCicloRevision;
    if (!this.habilitarCicloRevision) {
      this.myForm.controls["CicloRevision"].setValue(0);
    }
  }

  elementoSeleccionado(elemento: any) {
    setTimeout(() => {
      this.searchByField(elemento.Clave);
    }, 300);
  }


  searchConfigurationProveedor: SearchConfiguration | null = null;
  @ViewChild('ctrlBusqueda')
  public ctrlBusqueda!: ElementRef<HTMLElement>;
  get getColumns(): any {
    if (this.searchConfigurationProveedor) {
      return this.searchConfigurationProveedor.columns;
    }
    return [];
  }

  get getPropertys(): string {
    if (this.searchConfigurationProveedor) {
      let props = "";
      this.searchConfigurationProveedor.propertys.forEach((prop) => {
        props += `${prop.name}|${prop.type},`
      })

      if (props.length > 0) {
        props = props.substring(0, props.length - 1);
        return props;
      }

    }
    return "";
  }



  busquedaRegistro() {
    const b: any = this.ctrlBusqueda;
    this.ms.openModal(b, (e: any) => {
      if (e) {
        this.searchByField(e.Clave);
      }
    }, 'lg')
  }

}
