import { Component, ElementRef, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserLogged } from 'src/app/auth/interfaces';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { ReportFilter, ReportFilterContentList, ReportFilterListItem, ReportFilterOrderList, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { CuentasPorCobrarService } from 'src/app/cuentas-por-cobrar/services/cxc.service';
import { ReportsService } from 'src/app/service/reports.service';
import { UtilsService } from 'src/app/service/utils.service';

@Component({
  selector: 'verificador-cargos-abonos-cxc',
  templateUrl: './verificador-cargos-abonos-cxc.component.html',
  styles: [
  ]
})
export class VerificadorCargosAbonosCXCComponent {

  @ViewChild('txtNombreReporte')
  public txtNombreReporte!: ElementRef;
  menuId: string = "verificadorcargosabonoscxc";


  listapoliza: ReportFilterListItem[] = [];

  //public ordenadoLista: ReportFilterOrderList[] = [{ Nombre: 'Tipo movimiento-Fecha', Orden: 'movcxc.Clave,cab.fecha' }]

  public ordenadoLista: ReportFilterOrderList[] = [
    { Nombre: 'Ninguno', Orden: '[cta.Clave],[cob.Clave],cli.clave,seriestring,folio', Id: 0 },
    { Nombre: 'Empresa-Sucursal', Orden: 'emp.clave,suc.clave,[cta.Clave],[cob.Clave],cli.Nombre,seriestring,folio', Id: 1 },
    { Nombre: 'Cliente Alfabético', Orden: '[cta.Clave],[cob.Clave],cli.Nombre,seriestring,folio', Id: 1 },
    { Nombre: 'Cliente Numérico', Orden: '[cta.Clave],[cob.Clave],cli.Clave,seriestring,folio', Id: 1 }
  ]

  public luegoPorLista: ReportFilterOrderList[] = [
    { Nombre: 'Cliente', Orden: 'cli.Clave', Id: 0,Agrupador:"cli.Clave" },
    { Nombre: 'Cuenta de Cxc', Orden: 'cta.Clave', Id: 1,Agrupador:"cta.Clave" },
    { Nombre: 'Cobrador', Orden: 'cob.Clave', Id: 2,Agrupador:"cob.Clave" }]


  public filtrosDiarioVentas: ReportFilter =
    {
      menuId:this.menuId,
      ReportHeader: {} as ReportHeader,
      Desglose: 'a Detalle',
      DesgloseLista: [{ Nombre: 'a Detalle', Id:1 },
      { Nombre: 'a Totales', Id:2 }],
      OrdenadoLista: this.ordenadoLista,
      LuegoPorLista: this.luegoPorLista,
      Fecha1: new Date(),
      Fecha2: new Date(),
      NombreReporte: '',
      TituloVisor: 'Verificados de cargos y abonos',
      NombreExcel: 'Verificados de cargos y abonos.xlsx',
      FilterOptions: [
        { Campo: 'emp.Clave', Etiqueta: 'Empresa', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Empresa" },
        { Campo: 'suc.Clave', Etiqueta: 'Sucursal', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Sucursal" },
        { Campo: 'movcxc.Clave', Etiqueta: 'Tipo movimiento', Tipo: TypeFilter.entityNumber, Entidad: "TipoMovimientoCXC" },
        { Campo: 'cta.Clave', Etiqueta: 'Cuenta', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "CuentaCxc" },
        { Campo: 'cli.Clave', Etiqueta: 'Cliente', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Cliente",EsCliente:true },
        { Campo: 'cliCab.Clave', Etiqueta: 'Cliente Cabecera', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Cliente", EsClienteCabecera:true },
        { Campo: 'conc.Clave', Etiqueta: 'Concepto', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "ConceptoCxc" },
        { Campo: 'cob.Clave', Etiqueta: 'Cobrador', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Usuario",Filtro: "EsVendedor = true" },
        { Campo: 'det.SerieOrigina', Etiqueta: 'Serie', Tipo: TypeFilter.text },
        { Campo: 'det.FolioOrigina', Etiqueta: 'Folio', Tipo: TypeFilter.number },
        { Campo: '(det.SerieAfecta + convert(varchar(100),det.FolioAfecta))', Etiqueta: 'Comprobante afecta', Tipo: TypeFilter.text },
        { Campo: 'IIF(det.ImporteCargo>0,det.ImporteCargo,det.ImporteAbono)', Etiqueta: 'Importe', Tipo: TypeFilter.number },
        { Campo: 'convert(int,fpago.Clave)', Etiqueta: 'Forma de pago', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "c_FormaPago" },
        { Campo: 'pag.CtaBeneficiario', Etiqueta: 'Número de cuenta de pago', Tipo: TypeFilter.text }
      ]
    };

  public myForm: FormGroup = this.fb.group({
    Fecha1: new Date(),
    Fecha2: new Date(),
    NombreReporte: '',
    Opc1: [false],
    Opc2: [false],
    Opc3: [false],
    Opc4: [false]
  });

  activeButtons: ActiveButtons = {
    all: false,
    print: true,
  }

  constructor(private fb: FormBuilder, private _utils: UtilsService, private reportsService: ReportsService) { }

  ngOnInit(): void {
    const fecha: string = this._utils.Date2Str(new Date(), 5);
    this.myForm.get('NombreReporte')?.setValue('VERIFICADOR DE MOVIMIENTOS DE CUENTAS POR COBRAR');
    this.focus('txtNombreReporte');
  }

  get listaTipoPoliza() {
    return this.listapoliza;
  }

  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombreReporte;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  changeselec(event: any) {
    this.filtrosDiarioVentas = event;
  }

  clickBoton(event: string) {
    this.filtrosDiarioVentas.ReportHeader = this.myForm.value;
    this.reportsService.printReport(this.filtrosDiarioVentas, '/CuentasPorCobrar/ReporteCargosyAbonosCXC');
  }
}
