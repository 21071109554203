<div class="card text-center border-0" cdkDrag style="width: 950px;box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;">
  <div style="cursor: move;" cdkDragHandle class="card-header fw-bold" (keydown.escape)="onKeydownPrincipal($event)">Existencias {{producto}}</div>
  <div class="card-body bg-light">
    <app-panel-base title="Existencia por Sucursal" style="height: 600px;">
      <div class="panel-content" >
        <div *ngIf="!manejaPaquetes">
          <ng-scrollbar style="height: 440px;border-bottom: 1px solid #AAAAAA;">
            <table class="blueTable">
              <thead style="position: sticky;top:0;">
                <tr>
                  <th style="width: 150px;">Sucursal</th>
                  <th style="width: 100px;">Existencia</th>
                </tr>
              </thead>
              <tbody  class="bg-light">
                <tr *ngFor="let item of lista">
                  <td style="width: 150px;"> {{item.claveSucursal + ' ' + item.nombreSucursal}}</td>
                  <td style="width: 100px;"> {{item.existencia|number:'1.2-6' }}</td>
                </tr>
              </tbody>
            </table>
          </ng-scrollbar>
          <span class="badge bg-success rounded-0 fs-15px d-block mt-2">Total Existencia:
            {{total|number:'1.2-6'}}</span>
        </div>
        <div *ngIf="manejaPaquetes">
          <div class="widget-input-container">
            <div class="widget-input-box">
              <input (keydown)="keydown($event)" type="text" class="form-control"
                placeholder="Teclee y presione enter para buscar" #txtBuscar>
            </div>
            <div class="widget-input-icon">
              <a class="text-body text-opacity-50" data-id="widget-elm" data-light-class="text-body text-opacity-50"
                data-dark-class="text-white">
                <i (click)="clicBuscar()" class="fa fa-magnifying-glass-location" style="cursor: pointer;"></i>
              </a>
            </div>
          </div>
          <ng-scrollbar style="height: 440px;border-bottom: 1px solid #AAAAAA;">
            <table class="blueTable">
              <thead style="position: sticky;top:0;">
                <tr>
                  <th style="width: 50px;"></th>
                  <th style="width: 100px;">Sucursal</th>
                  <th style="width: 100px;">Almacen</th>
                  <th style="width: 100px;">Fecha</th>
                  <th style="width: 100px;">Paquete</th>
                  <th style="width: 100px;">Existencia</th>
                  <th style="width: 100px;">F/Prov</th>
                  <!-- <th style="width: 100px;">Referencia</th> -->
                </tr>
              </thead>
              <tbody  class="bg-light">
                <tr *ngFor="let item of listaPaquetes">
                  <td> <a (click)="seleccionar(item)" class="btn btn-primary  btn-xs"
                      style="height: 17px; width: 100%; border-radius: 0;">Sel</a></td>
                  <td>{{item.nombreSucursal}}</td>
                  <td>{{item.nombreAlmacen}}</td>
                  <td>{{item.fecha}}</td>
                  <td>{{item.identificador}}</td>
                  <td>{{item.existencia|number:'1.2-6' }}</td>
                  <td>{{item.facturaProveedor}}</td>
                  <!-- <td>{{item.referencia}}</td> -->
                </tr>
              </tbody>
            </table>
          </ng-scrollbar>
          <span class="badge bg-success fs-15px rounded-0 d-block mt-2">Total Paquetes:
            {{this.listaPaquetes.length|number:'1.2-6'}}</span>
          <span class="badge bg-success fs-15px rounded-0 d-block mt-2">Total Existencia:
            {{total|number:'1.2-6'}}</span>
        </div>
      </div>
    </app-panel-base>
  </div>
  <div class="card-footer fw-bold">
    <div style="text-align: right; margin-top: 5px;">
      <div (click)="closeModal()" class="btn btn-danger" #txtCancelar>Cerrar</div>
    </div>
  </div>
</div>
