import { Injectable, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalConfig, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SearchConfiguration } from './interfaces/data-search.interface';

@Injectable({ providedIn: 'root' })
export class ModalService implements OnInit {




  constructor(private config: NgbModalConfig, private modalService: NgbModal) {
    this.config.backdrop = 'static';
    this.config.keyboard = false;
    this.config.animation = false;
  }
  ngOnInit(): void {

  }

  onClose: any[] = [];
  modalRefs: NgbModalRef[] = [];
  openModal(content: any, cb: any, size: string = "md", isTop: boolean = false, fullscreen: boolean = false) {
    const ref = this.modalService.open(content, { fullscreen: fullscreen, centered: true, size: size, windowClass: isTop ? "modal-top" : "" });
    this.modalRefs = [...this.modalRefs, ref];
    this.onClose = [...this.onClose, cb];
  }

  closeModal(entity: any | null) {
    this.modalRefs[this.modalRefs.length - 1].close();
    this.modalRefs.pop();
    const closeEvnt: any = this.onClose[this.onClose.length - 1];
    closeEvnt(entity);
    this.onClose.pop();
  }

  /* Get configuration of entity search */
  GetSearchConfiguration(entity: string, filter: string): SearchConfiguration {

    let config!: SearchConfiguration;

    switch (entity) {
      case "c_Unidad":
        return {
          entityName: entity,
          columns: [
            { prop: 'Clave', width: 80 },
            { prop: 'Nombre', width: 550 },
            { prop: 'Abreviacion', width: 100 },
          ],
          propertys: [
            { name: 'Id', type: 'number' },
            { name: 'Clave', type: 'string' },
            { name: 'Nombre', type: 'string' },
            { name: 'Abreviacion', type: 'string' },
          ],
          filter: filter,
          orders: [
            { name: 'nombre', type: 'desc' }
          ],
          onSelect: () => {

          }
        }
        break;
      case "c_Colonia":
        return {
          entityName: entity,
          columns: [
            { prop: 'Clave', width: 80 },
            { prop: 'Nombre', width: 550 },
          ],
          propertys: [
            { name: 'Id', type: 'number' },
            { name: 'Clave', type: 'string' },
            { name: 'Nombre', type: 'string' },
          ],
          filter: filter,
          orders: [
            { name: 'nombre', type: 'desc' }
          ],
          onSelect: () => {

          }
        }
        break;
      case "c_ClaveProdServ":
        return {
          entityName: entity,
          columns: [
            { prop: 'Clave', width: 80 },
            { prop: 'Nombre', width: 550 },
          ],
          propertys: [
            { name: 'Id', type: 'number' },
            { name: 'Clave', type: 'string' },
            { name: 'Nombre', type: 'string' },
          ],
          filter: filter,
          orders: [
            { name: 'nombre', type: 'desc' }
          ],
          onSelect: () => {

          }
        }
        break;
      case "Proveedor":
        return {
          entityName: entity,
          columns: [
            { prop: 'Clave', width: 80 },
            { prop: 'Nombre', width: 250 },
            { prop: 'Rfc', width: 120, name: 'RFC' },
          ],
          propertys: [
            { name: 'Id', type: 'number' },
            { name: 'Clave', type: 'number' },
            { name: 'Nombre', type: 'string' },
            { name: 'Rfc', type: 'string' },
          ],
          filter: filter,
          orders: [
            { name: 'nombre', type: 'desc' }
          ],
          onSelect: () => {

          }
        }
      case "Cliente":
        return {
          entityName: entity,
          columns: [
            { prop: 'Clave', width: 80 },
            { prop: 'Nombre', width: 250 },
            { prop: 'Rfc', width: 120, name: 'RFC' },
            { prop: 'Correos', width: 120, name: 'Correos' },
            { prop: 'Comentarios', width: 190, name: 'Comentarios' },
          ],
          propertys: [
            { name: 'Id', type: 'number' },
            { name: 'Clave', type: 'number' },
            { name: 'Nombre', type: 'string' },
            { name: 'Rfc', type: 'string' },
            { name: 'Correos', type: 'string' },
            { name: 'Telefonos', type: 'string' },
            { name: 'Comentarios', type: 'string' },
          ],
          filter: filter,
          orders: [
            { name: 'nombre', type: 'desc' }
          ],
          onSelect: () => {

          }
        }
      case "DocumentoVenta":
        return {
          entityName: entity,
          columns: [
            { prop: 'Clave', width: 80 },
            { prop: 'Nombre', width: 250 },
            { prop: 'TextoImprimir', width: 120, name: 'Imprimir' },
            { prop: 'cla', width: 120, name: 'Clasificación' },
            { prop: 'tcom', width: 120, name: 'Tipo Comprobante' },
            { prop: 'HorasVigencia', width: 120, name: 'Vigencia en horas' },

          ],
          propertys: [
            { name: 'Id', type: 'number' },
            { name: 'Clave', type: 'number' },
            { name: 'Nombre', type: 'string' },
            { name: 'TextoImprimir', type: 'string' },
            { name: 'Clasificacion.Nombre as cla', type: 'string' },
            { name: 'TipoDeComprobante.Nombre as tcom', type: 'string' },
            { name: 'HorasVigencia', type: 'number' },

          ],
          filter: filter,
          orders: [
            { name: 'nombre', type: 'asc' }
          ],
          onSelect: () => {

          }
        }
      case "TipoImpuesto":
        return {
          entityName: entity,
          columns: [
            { prop: 'Clave', width: 80 },
            { prop: 'Nombre', width: 250 },
            { prop: 'Imprimir', width: 120, name: 'Imprimir' },
            { prop: 'fa', width: 120, name: 'Factor' },
            { prop: 'oimp', width: 120, name: 'Objeto Impuesto' },
            { prop: 'tipo', width: 120, name: 'Tipo' },
            { prop: 'TasaCuota', width: 120, name: 'Tasa/Cuota' },

          ],
          propertys: [
            { name: 'Id', type: 'number' },
            { name: 'Clave', type: 'number' },
            { name: 'Nombre', type: 'string' },
            { name: 'Imprimir', type: 'string' },
            { name: 'Factor.Nombre as fa', type: 'string' },
            { name: 'ObjetoImpuesto.Nombre as oimp', type: 'string' },
            { name: 'Tipo.Nombre as tipo', type: 'string' },
            { name: 'TasaCuota', type: 'number' },

          ],
          filter: filter,
          orders: [
            { name: 'nombre', type: 'asc' }
          ],
          onSelect: () => {

          }
        }
      case "Usuario":
        return {
          entityName: entity,
          columns: [
            { prop: 'Clave', width: 80 },
            { prop: 'Nombre', width: 200 },
            { prop: 'Alias', width: 80, name: 'Alias' },
            { prop: 'puesto', width: 120, name: 'Puesto' },
            { prop: 'rol', width: 120, name: 'Rol' },
            { prop: 'jefe', width: 120, name: 'Jefe' },

          ],
          propertys: [
            { name: 'Id', type: 'number' },
            { name: 'Clave', type: 'number' },
            { name: 'Nombre', type: 'string' },
            { name: 'Alias', type: 'string' },
            { name: 'Puesto.Nombre as puesto', type: 'string' },
            { name: 'Rol.Nombre as rol', type: 'string' },
            { name: 'JefeInmediato.Nombre as jefe', type: 'string' },

          ],
          filter: filter,
          orders: [
            { name: 'nombre', type: 'asc' }
          ],
          onSelect: () => {

          }
        }
      case "Producto":
        return {
          entityName: entity,
          columns: [
            { prop: 'Clave', width: 80 },
            { prop: 'Nombre', width: 200 },
            { prop: 'SegundoNombre', width: 200, name: 'Segundo Nombre' },
            { prop: 'unidadnombre', width: 120, name: 'Unidad' },
            { prop: 'ClaveSAT', width: 120, name: 'ClaveSAT' },
            { prop: 'ClaveSATNombre', width: 120, name: 'ClaveSATNombre' },
            { prop: 'Codigo Barras', width: 100, name: 'CodigoBarras' },
            { prop: 'clasificacionnombre', width: 120, name: 'Clasificacion' },
          ],
          propertys: [
            { name: 'Id', type: 'number' },
            { name: 'Clave', type: 'string' },
            { name: 'Nombre', type: 'string' },
            { name: 'SegundoNombre', type: 'string' },
            { name: 'Unidad.Nombre as unidadnombre', type: 'string' },
            { name: 'ClaveSAT', type: 'string' },
            { name: 'ClaveSATNombre', type: 'string' },
            { name: 'CodigoBarras', type: 'string' },
            { name: 'Clasificacion.Nombre as clasificacionnombre', type: 'string' },

          ],
          filter: filter,
          orders: [
            { name: 'nombre', type: 'asc' }
          ],
          onSelect: () => {

          }
        }
      case "CuentaBancaria":
        return {
          entityName: entity,
          columns: [
            { prop: 'Clave', width: 80 },
            { prop: 'Nombre', width: 250 },
            { prop: 'NumeroCuenta', width: 250, name: "N° Cuenta" },
          ],
          propertys: [
            { name: 'Id', type: 'number' },
            { name: 'Clave', type: 'number' },
            { name: 'Nombre', type: 'string' },
            { name: 'NumeroCuenta', type: 'string' },
          ],
          filter: filter,
          orders: [
            { name: 'nombre', type: 'asc' }
          ],
          onSelect: () => {

          }
        }
      case "VisitasSearchMobile": //Para busqueda de Prospectos y/o clientes en la pantalla de Registro de Visitas (CRM Mobile)
        return {
          entityName: entity,
          columns: [
            { prop: 'Nombre', width: 250 },
          ],
          propertys: [
            { name: 'Id', type: 'number' },
            { name: 'Clave', type: 'number' },
            { name: 'Nombre', type: 'string' },
            { name: 'Rfc', type: 'string' },
          ],
          filter: filter,
          orders: [
            { name: 'nombre', type: 'desc' }
          ],
          onSelect: () => {

          }
        }
      case "MedioEntrada":
        return {
          entityName: entity,
          columns: [
            { prop: 'Nombre', width: 250 },
          ],
          propertys: [
            { name: 'Id', type: 'number' },
            { name: 'Clave', type: 'number' },
            { name: 'Nombre', type: 'string' },
          ],
          filter: filter,
          orders: [
            { name: 'nombre', type: 'desc' }
          ],
          onSelect: () => {

          }
        }
    }


    return {
      entityName: entity,
      columns: [
        { prop: 'Clave', width: 80 },
        { prop: 'Nombre', width: 250 },
      ],
      propertys: [
        { name: 'Id', type: 'number' },
        { name: 'Clave', type: 'number' },
        { name: 'Nombre', type: 'string' },
      ],
      filter: filter,
      orders: [
        { name: 'nombre', type: 'asc' }
      ],
      onSelect: () => {

      }
    }


    return config;
  }



}
