import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CentroCosto } from '../../interfaces/centrocosto.interface';
import { Empresa } from 'src/app/configuracion/interfaces/empresa.interface';
import { HttpClient } from '@angular/common/http';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { ContabilidadService } from '../../services/contabilidad.service';
import { UtilsService } from 'src/app/service/utils.service';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { ActiveButtons, EventReturn } from 'src/app/component-ui/interfaces/container-base.interface';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { environment } from 'src/environments/environment';
import { CentroCostoService } from '../../services/centrocosto.service';
import { ReportsService } from 'src/app/service/reports.service';

@Component({
  selector: 'app-centro-costo-page',
  templateUrl: './centro-costo-page.component.html',
  styles: [
  ]
})
export class CentroCostoPageComponent {
  @ViewChild('txtNombre')
  public txtNombre!: ElementRef<TextBoxComponent>;

  info: any;
  file: any;
  longClave: number = 0;
  saving: boolean = false;
  private readonly baseUrl: string = environment.baseUrlApi;
  selectedTree: any = null;
  entityAfterSave: any = null;
  reload: boolean = false;
  activeButtons: ActiveButtons = {
    new: false,
    delete: true,
    return: false,
    save: true,
    first: false,
    left: false,
    right: false,
    last: false,
    search: false,
    print: true,
  }

  public myForm: FormGroup = this.fb.group({
    id: [0],
    clave: [0],
    nombre: [''],
    abreviacion: [''],
    numeroNivel: [0],
    orden: [0],
    claveGeneral: [''],
    empresa: [{} as Empresa],
    padre: [{} as CentroCosto],
    eliminado: [false],
    baja: [false],
  });

  private reportsService = inject(ReportsService);

  constructor(private http: HttpClient,
    private fb: FormBuilder,
    private containerService: ContainerBaseService,
    private centroCostoService: CentroCostoService,
    private utileService: UtilsService) {
  }

  ngOnInit(): void {
    this.info = this.utileService.getUserLogged();
    this.myForm.reset({});
  }

  selectedObjeto(entity: ComboBoxEntity, type: string) {
    this.myForm.controls[type].setValue(entity);
  }

  onLoad() {
    this.reload = false;
    this.entityAfterSave = null;
  }

  onDelete() {
    this.reload = true;
    this.entityAfterSave = null;
  }

  get getReload(): boolean {
    return this.reload;
  }

  get getEntityAfterSave(): CentroCosto {
    return this.entityAfterSave;
  }

  addSubLevel() {
    let level = this.selectedTree.numeroNivel + 1;
    let idPadre = this.selectedTree.id;
    this.centroCostoService.getLongLevel(this.info.empresa.numero, level).subscribe((long) => {
      this.longClave = long;
      this.centroCostoService.getNextClave(this.info.empresa.numero, level, idPadre).subscribe((clave) => {
        this.containerService.getEmptyEntityNoJson("CentroCosto").subscribe((elem) => {
          elem.clave = clave;
          elem.numeroNivel = level;
          elem.padre = { id: idPadre };
          this.myForm.reset(elem);
          setTimeout(() => {
            this.focus("txtNombre")
          }, 150);
        })
      })
    });

  }


  addLevel() {
    let level = 1;
    let idPadre = 0;
    if (this.selectedTree) {
      level = this.selectedTree.numeroNivel;
    }

    this.centroCostoService.getLongLevel(this.info.empresa.numero, level).subscribe((long) => {
      this.longClave = long;
      this.centroCostoService.getNextClave(this.info.empresa.numero, level, idPadre).subscribe((clave) => {
        this.containerService.getEmptyEntityNoJson("CentroCosto").subscribe((elem) => {
          elem.clave = clave;
          elem.numeroNivel = 1;
          this.myForm.reset(elem);
          setTimeout(() => {
            this.focus("txtNombre")
          }, 150);
        })
      })
    })
  }

  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombre;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  get showAddFamily(): boolean {

    if (this.selectedTree) {
      if (this.selectedTree.padre) {
        return false;
      }
    }
    return true;
  }

  get showAddSubFam(): boolean {

    if (this.selectedTree) {
      if (this.selectedTree.numeroNivel <= 3) {
        return true;
      }
    }
    return false;
  }

  onSelectedItem(item: CentroCosto) {
    this.selectedTree = { ...item };
    this.myForm.reset(item);
    setTimeout(() => {
      this.focus("txtNombre")
    }, 150);
  }

  onBeforeSave(param: EventReturn) {
    const ent = this.myForm.value;
    if (!ent) { param.callback(false); return; }
    if (ent.clave == 0) { param.callback(false); return; }
    param.callback(true);
  }

  onAfterSave(entity: CentroCosto) {
    this.myForm.reset(entity);
    this.selectedTree = null;
    this.entityAfterSave = entity;
    this.reload = true;
  }

  clickBoton(value: any) {
    if (value == "print") {
      this.reportsService.printReportWithoutFilters("/contabilidad/ReporteCentrosCostos/", "Reporte de Centros de Costos");
    }
  }
}
