import { Empresa } from './../../../configuracion/interfaces/empresa.interface';
import { Sucursal } from './../../../configuracion/interfaces/sucursal.interface';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { AppSettings } from '../../../home/services/app-settings.service';
import { FacturacionService } from '../../services/facturacion.service';
import { ClasificacionProducto, Producto, ProductoSeleccionado } from 'src/app/Inventarios/interfaces/producto.interface';
import { UtilsService } from 'src/app/service/utils.service';
import { NumberBoxComponent } from '../../../component-ui/components/number-box/number-box.component';
import { map, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { FamiliaProducto } from 'src/app/Inventarios/interfaces/familia.interface';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Caja } from '../../interfaces/caja.interface';
import { Cliente, c_UsoCfdi } from '../../interfaces/cliente.interface';
import * as moment from 'moment';
import { DocumentoVenta } from '../../interfaces/documentoventa.interface';
import { SerieVenta } from '../../interfaces/serieventa.interface';
import { CfdiRelacionado, FacturaAnticipoRelacionada, Venta, VentaConcepto, VentaConceptoImpuesto, c_EstadoSAT, c_MotivoCancelacion } from '../../interfaces/venta.interface';
import { c_FormaPago } from '../../interfaces/formapago.interface';
import { ClaseVenta, MetodoPago } from '../../interfaces/claseventa.interface';
import { CondicionPago } from '../../interfaces/condicionpago.interface';
import { Usuario } from 'src/app/configuracion/interfaces/usuario.interface';
import { Moneda } from 'src/app/configuracion/interfaces/moneda.interface';
import { LimitesService } from '../../services/sat.services';
import { TabsNavService } from 'src/app/components/services/tabs-nav.service';
import { Result, UserLogged } from 'src/app/auth/interfaces';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';

@Component({
  selector: 'customer-order',
  templateUrl: './customer-order.html'
})

export class PosCustomerOrderPage implements OnDestroy, OnInit {
  private readonly baseUrl: string = environment.baseUrlApi;
  saving: boolean = false;
  posMobileSidebarToggled = false;
  familias: FamiliaProducto[] = [];
  subfamilias: FamiliaProducto[] = [];
  info: UserLogged = {} as UserLogged;
  selectedButton = "";
  selectedProduct: ProductoSeleccionado | null = null;
  listItems: VentaConcepto[] = [];
  listProducts: any[] = [];
  payments: c_FormaPago[] = [];
  showPayments: string = "";
  private tabsNavService = inject(TabsNavService);

  @ViewChild('txtLector')
  public txtLector!: ElementRef<HTMLElement>;

  togglePosMobileSidebar() {
    this.appSettings.appEmpty = false;
    this.posMobileSidebarToggled = !this.posMobileSidebarToggled;
  }


  public myForm: FormGroup = this.fb.group({
    Id: [0],
    Caja: [{} as Caja],
    Empresa: [{} as Empresa, Validators.required],
    Sucursal: [{} as Sucursal, Validators.required],
    DocumentoVenta: [{} as DocumentoVenta, Validators.required],
    Serie: [{} as SerieVenta, Validators.required],
    SerieString: [''],
    Folio: [0],
    FechaEmision: [new Date()],
    FechaVencimiento: [new Date()],
    FechaCancelacion: [],
    FormasPago: [{} as c_FormaPago[]],
    Cliente: [{} as Cliente],
    Clase: [{} as ClaseVenta, Validators.required],
    DescripcionAdicional: [''],
    MetodoPago: [{} as MetodoPago],
    UsoCfdi: [{} as c_UsoCfdi],
    Condicion: [{} as CondicionPago],
    Vendedor: [{} as Usuario],
    Cajero: [{} as Usuario],
    EstadoSAT: [{} as c_EstadoSAT],
    Impreso: [false],
    SubTotal: [0],
    UsuarioAlta: [{} as Usuario],
    UsuarioCancela: [{} as Usuario],
    SubTotalAntesDescuento: [0],
    SubTotalNotaCredito: [0],
    SubTotalGrabaIvaTrasladado: [0],
    SubTotalGrabaIvaRetenido: [0],
    SubTotalGrabaIsrRetenido: [0],
    SubTotalGrabaIeps: [0],
    SubTotalGrabaIvaCero: [0],
    SubTotalGrabaIvaExento: [0],
    Total: [0],
    TotalAntesDescuento: [0],
    TotalDescuento: [0],
    TotalReciboPago: [0],
    TotalNotaCredito: [0],
    TotalIvaTrasladado: [0],
    TotalIvaRetenido: [0],
    TotalIsrRetenido: [0],
    TotalIeps: [0],
    TotalImpuestosTrasladados: [0],
    MotivoCancelacion: [''],
    MotivoCancelacionSAT: [{} as c_MotivoCancelacion],
    CfdisRelacionados: [{} as CfdiRelacionado[]],
    FacturasAnticipos: [{} as FacturaAnticipoRelacionada[]],
    UUID: [''],
    Observaciones: [''],
    Moneda: [{} as Moneda],
    TipoCambio: [0],
    EsFacturaGlobal: [false],
    EnFacturaGlobal: [false],
    EnFacturaNormal: [false],
    EsFacturaAnticipo: [false],
    EsNotaCreditoAnticipo: [false],
    EnviadoWebTicket: [false],
    EsResguardo: [false],
    IdFacturaNormal: [0],
    IdFacturaGlobal: [0],
    IdNotaCreditoAnticipo: [0],
    IdsCopiados: ['']
  })


  constructor(public appSettings: AppSettings,
    public fService: FacturacionService,
    public utilService: UtilsService,
    private http: HttpClient,
    private cService: ContainerBaseService,
    private satService: LimitesService,
    private fb: FormBuilder,) {

  }

  ngOnInit() {
    this.info = this.utilService.getUserLogged();
    this.appSettings.appEmpty = true;
    this.getOpenBox();
  }



  getPosSeries() {
    this.fService.getPosSeries(this.info.empresa!.numero, this.info.sucursal!.numero).subscribe((result) => {

      if (result.success) {
        if (result.message.length > 0) {
          const serie = JSON.parse(result.message);
          this.myForm.controls["DocumentoVenta"].setValue(serie.DocumentoVenta);
          this.myForm.controls["Serie"].setValue(serie);
          this.myForm.controls["SerieString"].setValue(serie.Serie);

          this.myForm.controls["CfdisRelacionados"].setValue(null);
          this.myForm.controls["EstadoSAT"].setValue(null);
          this.myForm.controls["FacturasAnticipos"].setValue(null);
          this.myForm.controls["MetodoPago"].setValue(null);
          this.myForm.controls["MotivoCancelacionSAT"].setValue(null);
          this.myForm.controls["UsuarioAlta"].setValue(null);
          this.myForm.controls["UsuarioCancela"].setValue(null);

          this.getPayments();
          this.fService.getNextFolio(this.info.empresa!.numero, this.info.sucursal!.numero, this.myForm.value.DocumentoVenta.Id, this.myForm.value.SerieString).subscribe((folio) => {
            this.myForm.controls["Folio"].setValue(folio);
            //Tabla fija PUE
            this.searchCustomer('', true);
          })

        } else {
          Swal.fire({
            text: "Primero debe Configurar una Serie de tickets para POS.",
            icon: 'info',
          }).then(() => {
            this.tabsNavService.closeCurrentTab();
          })
        }
      }


    })
  }

  getPayments() {
    return this.http.get<c_FormaPago[]>(`${this.baseUrl}/Ventas/ObtenerFormasPagoActivas`).subscribe((lista) => {
      this.payments = lista;
    })
  }

  getOpenBox() {
    this.saving = true;
    this.fService.getOpenBox(this.info.sucursal!.numero, this.info.numero, moment(new Date).format("DD/MM/YYYY")).subscribe((result) => {
      this.saving = false;
      if (result.success) {
        if (result.message.length > 0) {
          const caja = JSON.parse(result.message);
          this.myForm.controls["Empresa"].setValue({ Id: this.info.empresa!.numero, Clave: this.info.empresa!.clave, Nombre: this.info.empresa!.nombre });
          this.myForm.controls["Sucursal"].setValue({ Id: this.info.sucursal!.numero, Clave: this.info.sucursal!.clave, Nombre: this.info.sucursal!.nombre });
          this.myForm.controls["Caja"].setValue(caja);
          this.myForm.controls["FechaEmision"].setValue(new Date());
          this.myForm.controls["FechaVencimiento"].setValue(new Date());
          this.fService.getProductsFamily(this.info.empresa!.numero, 0).subscribe((result) => {
            this.familias = result;
            this.getPosSeries();
          })
        } else {
          Swal.fire({
            text: "Primero debe de hacer la apertura de la Caja.",
            icon: 'info',
          }).then(() => {
            this.tabsNavService.closeCurrentTab();
          })
        }
      } else {
        Swal.fire({
          text: result.message,
          icon: 'error',
        }).then(() => {
          this.lectorFocus();
        })
      }
    })
  }


  lectorFocus() {
    this.txtLector.nativeElement.focus();
  }

  ngOnDestroy() {
    this.appSettings.appEmpty = false;
    this.appSettings.appContentFullHeight = false;
  }


  searchCustomer(clave: string, isTicket: boolean = false) {
    const params = new HttpParams().set("idEmpresa", this.info.empresa!.numero).set("esParaTickets", isTicket).set("clave", clave);
    this.saving = true;
    this.http.get<Cliente>(`${this.baseUrl}/Ventas/BuscarClientePorClave`, { params }).subscribe((cliente) => {
      this.saving = false;
      if (cliente) {
        this.myForm.controls["Cliente"].setValue(cliente);
        this.myForm.controls["Clase"].setValue(cliente.ClaseVenta);
        this.myForm.controls["Vendedor"].setValue(cliente.Vendedor);
        this.myForm.controls["Moneda"].setValue(cliente.Moneda);
        this.myForm.controls["UsoCfdi"].setValue(cliente.UsoCfdi);
        this.myForm.controls["Condicion"].setValue(cliente.CondicionDePago);
        this.myForm.controls["Cajero"].setValue({ Id: this.info.numero, Clave: this.info.clave });
        this.lectorFocus();
      } else {
        Swal.fire({ text: "No se encontró el cliente indicado.", icon: 'error', }).then(() => {
          this.tabsNavService.closeCurrentTab();
        });
      }
    })
  }

  setIsSelected(name: string, idFamilia: number) {
    this.selectedButton = name;
    this.subfamilias = [];
    this.listProducts = [];
    this.fService.getProductsFamily(this.info.empresa!.numero, idFamilia).subscribe((result) => {
      this.subfamilias = result;
      if (result.length == 0) {
        this.getProductsByFamily(idFamilia);
      } else {
        this.lectorFocus();
      }
    })
  }

  cancelModal() {
    this.selectedProduct = null;
    this.lectorFocus();
  }

  selectProduct(p: Producto) {
    this.selectedProduct = {
      Producto: p,
      Cantidad: p.CantidadPOS == 0 ? 1 : p.CantidadPOS,
      Precio: p.Precio,
      Importe: p.Precio * (p.CantidadPOS == 0 ? 1 : p.CantidadPOS)
    };
  }

  searchProduct(clave: string = "", cant: number = 0) {
    const lector: any = this.txtLector.nativeElement;
    let value: string = lector.value;
    if (clave) {
      value = clave;
    }
    if (!value) return;
    let cantidad = 1;
    if (cant > 0) {
      cantidad = cant;
    }
    if (value.includes('*')) {
      let items = value.split("*");
      if (items.length == 2) {
        cantidad = parseFloat(items[0]);
        value = items[1];
      } else {
        return;
      }
    }
    const params = new HttpParams().set("idEmpresa", this.info.empresa!.numero).set("idSucursal", this.info.sucursal!.numero).set("idCliente", 0).set("clave", value);
    return this.http.get<Producto>(`${this.baseUrl}/Ventas/ObtenerProducto`, { params }).subscribe((producto) => {
      //this.loading = false;
      if (producto) {
        this.addItem(producto, cantidad);
      } else {
        Swal.fire({
          text: "No se encontró el Producto indicado.",
          icon: 'error',
        }).then(() => {
          this.lectorFocus();
        })
      }
      lector.value = "";
    });

  }

  setQuantityAdded(sum: boolean, index: number) {
    let item = this.listItems[index];
    if (sum) {
      item.Cantidad += item.Producto!.CantidadPOS == 0 ? 1 : item.Producto!.CantidadPOS;
    } else {
      if (item.Cantidad > 1) {
        item.Cantidad -= item.Producto!.CantidadPOS == 0 ? 1 : item.Producto!.CantidadPOS;
      }
    }
    item.Importe = item.Cantidad * item.ValorUnitario;
    this.calcTax();
    this.listItems = [...this.listItems];
    this.lectorFocus();
  }

  setQuantity(sum: boolean) {
    if (sum) {
      this.selectedProduct!.Cantidad += this.selectedProduct!.Producto.CantidadPOS == 0 ? 1 : this.selectedProduct!.Producto.CantidadPOS;
    } else {
      if (this.selectedProduct!.Cantidad > 1) {
        this.selectedProduct!.Cantidad -= this.selectedProduct!.Producto.CantidadPOS == 0 ? 1 : this.selectedProduct!.Producto.CantidadPOS;
      }
    }
    this.selectedProduct!.Importe = this.selectedProduct!.Cantidad * this.selectedProduct!.Precio;
  }

  getProductsByFamily(idFamilia: number) {
    this.fService.getProductsByFamily(this.info.empresa!.numero, this.info.sucursal!.numero, idFamilia).subscribe((result) => {
      let list = eval(result.message);
      list.map((i: any) => {
        i.CantidadSeleccionada = 0;
        return i;
      })
      this.listProducts = list;
      this.lectorFocus();
    })
  }

  getProductsByClass(idClass: number) {
    this.fService.getProductsByClass(this.info.empresa!.numero, idClass).subscribe((result) => {
      let list = eval(result.message);
      list.map((i: any) => {
        i.CantidadSeleccionada = 0;
        return i;
      })
      this.listProducts = list;
    })
  }

  accept() {
    this.searchProduct(this.selectedProduct?.Producto.Clave, this.selectedProduct?.Cantidad);
    this.selectedProduct = null;
    this.setScroll();
    this.lectorFocus();
  }

  setScroll() {
    setTimeout(() => {
      let objDiv = document.getElementById("ticketList");
      objDiv!.scrollTop = objDiv!.scrollHeight;
    }, 50);
  }

  deleteAll() {
    Swal.fire({
      title: '¿Desea eliminar todo?',
      showDenyButton: true,
      confirmButtonText: 'Si',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.listItems = [];
      }
      this.lectorFocus();
    })
  }

  deleteItem(index: number) {

    Swal.fire({
      title: '¿Desea eliminar el item?',
      showDenyButton: true,
      confirmButtonText: 'Si',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.listItems.splice(index, 1);
        this.listItems = [...this.listItems];
      }
      this.lectorFocus();
    })

  }

  calcTax(desglosar: boolean = false) {
    let ent: Venta = this.myForm.value;
    ent.SubTotal = 0;
    ent.SubTotalGrabaIvaTrasladado = 0;
    ent.SubTotalGrabaIvaExento = 0;
    ent.SubTotalGrabaIvaCero = 0;
    ent.TotalIvaTrasladado = 0;
    ent.TotalIvaRetenido = 0;
    ent.TotalIsrRetenido = 0;
    ent.Total = 0;
    let totalConcepto: number = 0, subTotalVenta = 0, totalConceptos = 0;
    this.listItems.map((concepto, index) => {
      if (concepto.Producto) {
        if (concepto.Producto.Id > 0) {
          const imp = concepto.Producto!.Impuesto;
          if (concepto.ValorUnitario > 0) {
            concepto.TotalIvaTrasladado = 0;
            concepto.TotalIvaRetenido = 0;
            concepto.TotalIsrRetenido = 0;
            concepto.Total = 0;
            if (!concepto.Impuestos) {
              concepto.Impuestos = [];
            }
            concepto.Importe = parseFloat((concepto.Cantidad * concepto.ValorUnitario).toFixed(2));
            totalConcepto = concepto.Importe;


            if (imp) {
              //#region Calculo de IVA Trasladado
              if (imp.IVATrasladado) {
                let propIVA: number = 0;

                // if (tieneIVaRetIncluido) {
                //   propIVA = propIVA - _impuestoActual.IVARetenido.Tasa;
                // }

                // if (tieneISRRetIncluido) {
                //   propIVA = propIVA - _impuestoActual.ISRRetenido.Tasa;
                // }

                // if (propIVA > 0 && _impuestoActual.IEPSTrasladado == null) {
                //   propIVA = propIVA / 100;
                //   ctlPrecioBase.Text = (precioBaseEscrito / propIVA).ToString();
                //   ctrlImporte.Text = (decimal.Parse(ctlPrecioBase.Text) * cantidad).ToString("");
                // }

                if (imp.IncluidoEnPrecioIvaTrasladado) {
                  propIVA = 100 + (imp.IVATrasladado.TasaCuota);
                }

                if (propIVA > 0 && desglosar) {
                  propIVA = propIVA / 100;
                  concepto.ValorUnitario = parseFloat((concepto.ValorUnitario / propIVA).toFixed(6));
                  concepto.Importe = parseFloat((concepto.Cantidad * concepto.ValorUnitario).toFixed(6));
                  totalConcepto = concepto.Importe;
                  subTotalVenta += concepto.Importe;
                } else {
                  subTotalVenta += concepto.Importe;
                }

                const tipoImpuesto = imp.IVATrasladado.Factor.Clave == "3" ? "EXENTO" : "IVA";
                const impIvaExists = concepto.Impuestos.filter(P => P.TipoImpuesto == tipoImpuesto && P.TasaOCuota == imp.IVATrasladado.TasaCuota);
                let idImpIva = 0;
                if (impIvaExists.length > 0) {
                  idImpIva = impIvaExists[0].Id;
                  concepto.Impuestos = concepto.Impuestos.filter(P => P.Id != idImpIva);
                }

                let impIva: VentaConceptoImpuesto = {
                  Id: idImpIva,
                  TipoImpuesto: tipoImpuesto,
                  Importe: parseFloat((concepto.Importe * (imp.IVATrasladado.TasaCuota / 100)).toFixed(3)),
                  TasaOCuota: imp.IVATrasladado.TasaCuota,
                  TipoFactor: imp.IVATrasladado.Factor,
                  Impuesto: imp,
                  Base: concepto.Importe,
                };

                const limiteInferior = this.satService.GetLowerLimit_TraslateAmount(impIva.Base, this.satService.GetNumberdecimal(impIva.Base.toString()), impIva.TasaOCuota / 100, 6);
                const limiteSuperior = this.satService.GetUpperLimit_TraslateAmount(impIva.Base, this.satService.GetNumberdecimal(impIva.Base.toString()), impIva.TasaOCuota / 100, 6);
                if (impIva.Importe < limiteInferior) {
                  impIva.Importe = limiteInferior;
                } else if (impIva.Importe > limiteSuperior) {
                  impIva.Importe = limiteSuperior;
                }

                concepto.Impuestos = [...concepto.Impuestos, impIva];

                concepto.ObjetoImpuesto = "02";
                concepto.TotalIvaTrasladado += impIva.Importe;

                totalConcepto += concepto.TotalIvaTrasladado;

                if (imp.IVATrasladado.Factor.Clave != "3") {
                  if (imp.IVATrasladado.TasaCuota > 0) {
                    ent.SubTotalGrabaIvaTrasladado += concepto.Importe;
                  } else {
                    ent.SubTotalGrabaIvaCero += concepto.Importe;
                  }
                } else {
                  ent.SubTotalGrabaIvaExento += concepto.Importe;
                }
                ent.TotalIvaTrasladado += concepto.TotalIvaTrasladado;

              }

              //#endregion

              //#region Calculo del IVA Retenido
              if (imp.IVARetenido) {
                if (!imp.IncluidoEnPrecioIVARetenido) {
                  let impIvaRet: VentaConceptoImpuesto = {
                    Id: 0,
                    TipoImpuesto: "IVARET",
                    Importe: parseFloat((concepto.Importe * (imp.IVARetenido.TasaCuota / 100)).toFixed(2)),
                    TasaOCuota: imp.IVARetenido.TasaCuota,
                    TipoFactor: imp.IVARetenido.Factor,
                    Impuesto: imp,
                    Base: concepto.Importe,
                  };
                  const limiteInferior = this.satService.GetLowerLimit_TraslateAmount(impIvaRet.Base, this.satService.GetNumberdecimal(impIvaRet.Base.toString()), impIvaRet.TasaOCuota / 100, 2);
                  const limiteSuperior = this.satService.GetUpperLimit_TraslateAmount(impIvaRet.Base, this.satService.GetNumberdecimal(impIvaRet.Base.toString()), impIvaRet.TasaOCuota / 100, 2);
                  if (impIvaRet.Importe < limiteInferior) {
                    impIvaRet.Importe = limiteInferior;
                  } else if (impIvaRet.Importe > limiteSuperior) {
                    impIvaRet.Importe = limiteSuperior;
                  }
                  concepto.Impuestos = [...concepto.Impuestos, impIvaRet];
                  concepto.ObjetoImpuesto = "02";
                  concepto.TotalIvaRetenido += impIvaRet.Importe;

                  totalConcepto -= concepto.TotalIvaRetenido;

                  ent.SubTotalGrabaIvaRetenido += concepto.Importe;
                  ent.TotalIvaRetenido += concepto.TotalIvaRetenido;
                }
              }
              //#endregion

              //#region Calculo del ISR Retenido
              if (imp.ISRRetenido) {
                if (!imp.IncluidoEnPrecioISRRetenido) {
                  let impIsrRet: VentaConceptoImpuesto = {
                    Id: 0,
                    TipoImpuesto: "ISRRET",
                    Importe: parseFloat((concepto.Importe * (imp.ISRRetenido.TasaCuota / 100)).toFixed(2)),
                    TasaOCuota: imp.ISRRetenido.TasaCuota,
                    TipoFactor: imp.ISRRetenido.Factor,
                    Impuesto: imp,
                    Base: concepto.Importe,
                  };

                  const limiteInferior = this.satService.GetLowerLimit_TraslateAmount(impIsrRet.Base, this.satService.GetNumberdecimal(impIsrRet.Base.toString()), impIsrRet.TasaOCuota / 100, 2);
                  const limiteSuperior = this.satService.GetUpperLimit_TraslateAmount(impIsrRet.Base, this.satService.GetNumberdecimal(impIsrRet.Base.toString()), impIsrRet.TasaOCuota / 100, 2);
                  if (impIsrRet.Importe < limiteInferior) {
                    impIsrRet.Importe = limiteInferior;
                  } else if (impIsrRet.Importe > limiteSuperior) {
                    impIsrRet.Importe = limiteSuperior;
                  }
                  concepto.Impuestos = [...concepto.Impuestos, impIsrRet];
                  concepto.ObjetoImpuesto = "02";
                  concepto.TotalIsrRetenido += impIsrRet.Importe;

                  totalConcepto -= concepto.TotalIsrRetenido;

                  ent.SubTotalGrabaIsrRetenido += concepto.Importe;
                  ent.TotalIsrRetenido += concepto.TotalIsrRetenido;
                }
              }
              //#endregion


              //ent.SubTotal += parseFloat(subTotalVenta.toFixed(2));
              concepto.Total += this.round(totalConcepto);
              totalConceptos += totalConcepto;
              //ent.Total += parseFloat(totalConcepto.toFixed(2));
            }
          } else {
            concepto.Impuestos = [];
          }
        }
      }
    });
    ent.SubTotal = this.round(subTotalVenta);
    ent.Total = this.round(totalConceptos);
    this.myForm.reset(ent);
    this.listItems = [...this.listItems];

  }

  round(num: number) {
    return Math.round((num + Number.EPSILON) * 100) / 100
  }

  addItem(p: Producto, cantidad: number = 0) {
    let cant = p.CantidadPOS == 0 ? 1 : p.CantidadPOS;
    if (cantidad > 0) {
      cant = cantidad;
    }
    const ps = {
      Id: 0,
      Producto: p,
      Cantidad: cant,
      NoIdentificacion: p.Clave,
      Unidad: p.Unidad,
      ClaveProdServ: p.ClaveSAT,
      ClaveUnidad: p.Unidad.Clave,
      UnidadNombre: p.Unidad.Nombre,
      ObjetoImpuesto: '',
      Descripcion: p.Nombre,
      TextoConversiones: '',
      ValorUnitario: p.Precio,
      ValorUnitarioAntesDescuento: 0,
      DescuentoPorcentaje: 0,
      DescuentoImporte: 0,
      Importe: cant * p.Precio,
      ImporteAntesDescuento: 0,
      TotalIvaTrasladado: 0,
      TotalIvaRetenido: 0,
      TotalIsrRetenido: 0,
      TotalIeps: 0,
      Total: 0,
      Impuestos: [],
      Conversiones: [],
      Disenos: [],
      CantidadEntregada: 0,
      CantidadRestante: 0,
      DescripcionConvesion: '',
      NumeroPedimento: '',
      DescripcionProduccion: '',
      NumeroLotePaquete: '',
      IdDetalleBackOrder: 0,
      GrosorFormulacion: 0,
      AnchoFormulacion: 0,
      LargoFormulacion: 0,
      PiezasFormulacion: 0,
      DivisorFormulacion: 0,
      UltimoCosto: 0,
      Existencia: 0,
      TieneToken: false,
      PorcentajeUtilidad: 0,
      ImporteUtilidad: 0,
      UltimoCostoInventario: 0,
      TotalCosto: 0,
      PorcentajeUtilidadInventario: 0,
      ImporteUtilidadInventario: 0,
      TotalCostoInventario: 0,
      IdToken: 0,
      TextoFormulacion: ''
    }
    this.listItems = [...this.listItems, ps];
    this.selectedProduct = null;
    this.calcTax();
    this.setScroll();
    this.lectorFocus();
  }

  get getImps(): number {
    let total: number = 0;
    this.listItems.forEach((i) => total += i.TotalIvaTrasladado);
    return total;
  }

  get getSubTotal(): number {
    let total: number = 0;
    this.listItems.forEach((i) => total += i.Importe);
    return total;
  }

  get getTotal(): number {
    let total: number = 0;
    this.listItems.forEach((i) => total += i.Total);
    return total;
  }

  cancelPayments() {
    this.showPayments = "";
  }

  getEmptyEntity(idSerie: number = 0, idDocto: number = 0) {
    this.cService.getEmptyEntity("Venta").subscribe((ent) => {
      this.initializeEntity(ent, idSerie, idDocto)
    })
  }

  initializeEntity(ent: any, idSerie: number = 0, idDocto: number = 0) {
    ent.Empresa = { Id: this.info.empresa!.numero };
    ent.Sucursal = { Id: this.info.sucursal!.numero };
    ent.FechaVencimiento = new Date();
    this.getPosSeries();
  }

  reset() {
    this.listItems = [];
    this.listProducts = [];
    this.subfamilias = [];
    const ent: Venta = this.myForm.value;
    this.getEmptyEntity(ent.Serie.Id, ent.DocumentoVenta.Id);
  }

  save(payment: c_FormaPago) {
    this.showPayments = "";
    this.saving = true;
    let ent = this.myForm.value;
    ent = this.myForm.value;
    ent.Conceptos = this.listItems;
    ent.FormasPago = [{
      FormaPago: payment,
      Importe: ent.Total
    }];
    this.http.post<Result>(`${this.baseUrl}/Ventas/GuardarVenta`, ent).subscribe((result) => {
      this.saving = false;
      if (result.success) {
        const v: Venta = JSON.parse(result.message);
        this.myForm.reset(v);
        Swal.fire({ position: 'center', icon: 'success', title: 'Se guardó correctamente', showConfirmButton: false, timer: 1000 }).then(() => {
          this.reset();
        });
      } else {
        Swal.fire({ text: `${result.message}`, icon: 'info', })
      }
    })
  }

  ShowPayments() {
    if (this.myForm.value.Total > 0) {
      this.showPayments = "show"
    } else {
      Swal.fire({
        text: "Indique los conceptos de la venta.",
        icon: 'info',
      }).then(() => {
        this.lectorFocus();
      })
    }
  }

  get getStyleModal(): string {
    let c = "padding-top:250px;background-color:#0000008c;"
    if (this.showPayments == "show") {
      c += "display:block;";
    } else {
      c += "display:none;";
    }
    return c;
  }

  get getShowPayments(): string {
    return this.showPayments;
  }

}
