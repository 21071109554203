<div class="card text-center border-0" cdkDrag
  style="z-index:2;box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; width: 720px; position: fixed;left: 30%;top:30%;">
  <div class="card-header fw-bold" style="cursor: move;" cdkDragHandle>
    <span>
      Archivar entrega Cliente
    </span>
  </div>
  <app-panel-base title="Archivar entrega Cliente">
    <div class="panel-content">
      <form [formGroup]="myForm" autocomplete="off">
        <div class="row form-group">
          <div class="col-md-6">
          <components-ui-date-box [tabIndex]="50" formControlName="Fecha" label="Fecha recordatorio/p envío"
            [widthLabelColumns]="6" [widthTextColumns]="6" [required]="true"  (KeyDown.enter)="enterFecha($event)" [required]="true"/>
          </div>
          <div class="col-md-4">
            <components-ui-time-box label="Hora" formControlName="Hora" [tabIndex]="51"
            [widthTextColumns]="4" [widthLabelColumns]="3" #txtHoraArchivar (KeyDown.enter)="enterHora($event)" [required]="true"></components-ui-time-box>
          </div>
        </div>
        <div class="row form-group">
          <components-ui-text-box [tabIndex]="52" formControlName="Comentario" [maxlength]="2000" label="Comentario"
            [widthLabelColumns]="3" [widthTextColumns]="9" #txtComentarioArchivar (KeyDown.enter)="enterComentario($event)" />
        </div>
        <div style="text-align: right; margin-top: 5px;">
          <button (click)="accept()" class="btn btn-success" style="margin-right: 5px;" #botonArchivarAceptar tabindex="53">Aceptar</button>
          <button (click)="closeModal()" class="btn btn-danger" tabindex="54">Cancelar</button>
        </div>
      </form>
    </div>
  </app-panel-base>
</div>
