import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { environment } from 'src/environments/environment';
import { Compra, PendienteEntregarCompra } from '../interfaces/compra.interface';
import { Result } from 'src/app/auth/interfaces';
import { Proveedor } from '../interfaces/proveedores.interface';

@Injectable({ providedIn: 'root' })
export class CompraService {
  private readonly baseUrl: string = environment.baseUrlApi;
  constructor(private http: HttpClient) { }

  getSeries(idEmp: number, idSuc: number, idDocto: number) {
    const params = new HttpParams().set("idEmpresa", idEmp).set("idSucursal", idSuc).set("idDocumento", idDocto);
    return this.http.get<ComboBoxEntity[]>(`${this.baseUrl}/Compras/ObtenerListaSeries`, { params });
  }

  getPendienteEntregar(idCompra: number) {
    const params = new HttpParams().set("idCompra", idCompra);
    return this.http.get<PendienteEntregarCompra[]>(`${this.baseUrl}/Compras/ObtenerPendienteEntregar`, { params });
  }

  getProveedor(idEmpresa: number, clave: string) {
    const params = new HttpParams().set("idEmpresa", idEmpresa).set("clave", clave);
    return this.http.get<Proveedor>(`${this.baseUrl}/Compras/BuscarProveedorPorClave`, { params });
  }


  findEntityByParamsToCopy(idEmp: number, idSuc: number, idDocto: number, serie: string, folio: number) {
    const params = new HttpParams()
      .set("idEmpresa", idEmp)
      .set("idSucursal", idSuc)
      .set("idDoct", idDocto)
      .set("serie", serie)
      .set("folio", folio)
    return this.http.get<Compra>(`${this.baseUrl}/Compras/ObtenerCompraPorParametrosCopiar`, { params });
  }

  llevaBackOrder(idDocumento: number) {
    const params = new HttpParams().set("idDocumento", idDocumento);
    return this.http.get<boolean>(`${this.baseUrl}/Compras/LlevaBackOrder`, { params })
  }

  afectaBackOrder(idDocumento: number) {
    const params = new HttpParams().set("idDocumento", idDocumento);
    return this.http.get<boolean>(`${this.baseUrl}/Compras/AfectaBackOrder`, { params })
  }

  obtenerSucursaleS(idEmpresa: number) {
    const params = new HttpParams().set("entidad", "Sucursal").set("filterQuery", `Empresa.Id = ${idEmpresa}`);
    return this.http.get<ComboBoxEntity[]>(`${this.baseUrl}/Base/ObtenerListaCombo`, { params })
  }

  getNextFolio(idEmpresa: number, idSucursal: number, idDocto: number, serie: string): Observable<number> {
    const params = new HttpParams().set("idEmpresa", idEmpresa)
      .set("idSucursal", idSucursal)
      .set("idDocumento", idDocto)
      .set("serie", serie);
    return this.http.get<number>(`${this.baseUrl}/Compras/ObtenerSiguienteFolio`, { params });
  }


  findEntityByParams(idEmp: number, idSuc: number, idDocto: number, serie: string, folio: number, cancelados: boolean = true) {
    const params = new HttpParams()
      .set("idEmpresa", idEmp)
      .set("idSucursal", idSuc)
      .set("idDoct", idDocto)
      .set("serie", serie)
      .set("folio", folio)
    return this.http.get<Compra>(`${this.baseUrl}/Compras/ObtenerCompraPorParametros`, { params });
  }

  sendEmail(id: number, correos: string) {
    const params = new HttpParams().set("idCompra", id).set("correos", correos);
    return this.http.get<Result>(`${this.baseUrl}/Compras/EnviarPorEmail`, { params });
  }

  obtenerCorreosProveedor(idProveedor: number): Observable<Result> {
    const params = new HttpParams().set("idProveedor", idProveedor);
    return this.http.get<Result>(`${this.baseUrl}/Compras/ObtenerCorreoPorProveedor`, { params });
  }




}
