import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { ReportFilter, ReportFilterContentList, ReportFilterOrderList, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { UtilsService } from 'src/app/service/utils.service';
import { ReportsService } from '../../../../service/reports.service';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';

@Component({
  selector: 'app-diario-pagos',
  templateUrl: './diario-pagos.component.html',
  styleUrls: ['./diario-pagos.component.scss']
})
export class DiarioPagosComponent {
  @ViewChild('txtNombreReporte') public txtNombreReporte!: ElementRef<TextBoxComponent>;
  activeButtons: ActiveButtons = {
    all: false,
    print: true,
  }

  private fb = inject(FormBuilder);
  private utils = inject(UtilsService);
  private reportsService = inject(ReportsService);
  menuId: string = "diariopagos";


  ordenados: ComboBoxEntity[] = [
    { Id: 1, Nombre: "Nombre del Cliente", Clave: 1, ClaveNombre: "01 Nombre de Cliente" },
    { Id: 2, Nombre: "Clave del Cliente", Clave: 2, ClaveNombre: "02 Clave del Cliente" },
  ];


  public frmDiarioPagos: FormGroup = this.fb.group({
    Fecha1: new Date(),
    Fecha2: new Date(),
    NombreReporte: 'DIARIO DE PAGOS ' + this.utils.getEtiquetaRangoPorFechas(new Date(), new Date(), 2).toUpperCase(),
    Opc1: [false],
    Opc2: [false],
    Opc3: [false],
    Opc4: [false],
    Opc5: [false],
    Opc6: [false],
    Opc7: [false],
    Opc8: [false],
    Num1: [0],
    Num2: [0],
    Num3: [0]
  });

  ngOnInit() {
    setTimeout(() => {
      this.focus();
    }, 200);
  }

  focus() {
    const txt: any = this.txtNombreReporte;
    txt.tagInput.nativeElement.focus()
  }

  ordenadoLista: ReportFilterOrderList[] = [
    {
      Nombre: 'Nombre del Cliente',
      Orden: 'cli.Nombre'
    },
    {
      Nombre: 'Clave del Cliente',
      Orden: 'cli.Clave'
    }
  ]

  desgloseLista: ReportFilterContentList[] = [
    {
      Id: 1,
      Nombre: "A Detalle"
    }, {
      Id: 2,
      Nombre: "A Totales"
    },
  ]

  public filtrosReporte: ReportFilter =
    {
      menuId: this.menuId,
      OrdenadoLista: this.ordenadoLista,
      Desglose: "A Detalle",
      DesgloseLista: this.desgloseLista,
      ReportHeader: {
        NombreReporte: 'REPORTE DIARIO DE PAGOS'
      } as ReportHeader,
      TituloVisor: 'REPORTE DIARIO DE PAGOS',
      NombreExcel: 'DiarioPagos.xlsx',
      FilterOptions: [
        { Campo: 'emp.Clave', Etiqueta: 'Empresa', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Empresa", MultiClave: true },
        { Campo: 'suc.Clave', Etiqueta: 'Sucursal', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Sucursal", MultiClave: true },
        { Campo: 'cta.Clave', Etiqueta: 'Cuenta', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "CuentaCxc", MultiClave: true },
        { Campo: 'cli.Clave', Etiqueta: 'Cliente', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Cliente", MultiClave: true },
        { Campo: 'con.Clave', Etiqueta: 'Concepto', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "ConceptoCxc", MultiClave: true },
        { Campo: 'det.Serie', Etiqueta: 'Serie Comp', Tipo: TypeFilter.number, },
        { Campo: 'det.Folio', Etiqueta: 'Folio Comp', Tipo: TypeFilter.number, },
        { Campo: 'pago.Folio', Etiqueta: 'Folio Pago', Tipo: TypeFilter.number, },
        { Campo: 'pago.ImporteAbonar', Etiqueta: 'Importe', Tipo: TypeFilter.number, },
        { Campo: 'pago.CtaBeneficiario', Etiqueta: 'Cta Beneficiaria', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "CuentaBancaria", MultiClave: false, PropiedadRetorna: "NumeroCuenta" },
        { Campo: 'fp.Clave', Etiqueta: 'Forma de pago', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "c_FormaPago", MultiClave: true },
        { Campo: 'u.Clave', Etiqueta: 'Usuario Elabora', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Usuario", MultiClave: true },
        { Campo: 'pago.NumeroOperacion', Etiqueta: 'Número Operación', Tipo: TypeFilter.text, },

      ]
    };

  changeselec(event: any) {
    this.filtrosReporte = event;
  }

  clickBoton(event: string) {
    this.filtrosReporte.ReportHeader = this.frmDiarioPagos.value;
    this.reportsService.printReport(this.filtrosReporte, '/CuentasPorCobrar/ReporteDiarioPagos');
  }

  onChangeFecha(value: any) {
    this.frmDiarioPagos.get('NombreReporte')?.setValue('DIARIO DE PAGOS ' + this.rangoFechas);
  }

  get rangoFechas() {
    return this.utils.getEtiquetaRangoPorFechas(this.frmDiarioPagos.value.Fecha1, this.frmDiarioPagos.value.Fecha2, 2).toUpperCase();
  }

  ordenado?: ComboBoxEntity;
  selectedCombo(entity: any) {
    this.ordenado = entity;
    this.frmDiarioPagos.get('Num1')?.setValue(this.ordenado?.Id);
  }


  get selectOrdenadoItem(): ComboBoxEntity | null {
    return this.ordenado!;
  }
}
