<app-container-base subTitle="Ingresos por Período" (onClickBarButton)="imprimir($event)"
  [activeButtons]="activeButtons" [isReport]="true" icon=" fas fa-list-check" [hasCounter]="false">
  <div class="screen-content">
    <form [formGroup]="frmIngresosPeriodo" autocomplete="off">
      <app-panel-base title="Generales">
        <div class="panel-content">
          <div class="row">
            <div class="col-md-12">
              <components-ui-text-box [required]="true" label='Nombre del Reporte' formControlName="NombreReporte"
                [tabIndex]="1" [widthLabelColumns]="2" [widthTextColumns]="10" [maxLength]="100" #txtNombreReporte />
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <components-ui-date-box label='Fecha inicio' formControlName="Fecha1" [tabIndex]="2"
                [widthLabelColumns]="6" [widthTextColumns]="4" [required]="true"></components-ui-date-box>
            </div>
            <div class="col-md-4">
              <components-ui-date-box label='Fecha Fin' formControlName="Fecha2" [tabIndex]="3" [widthLabelColumns]="3"
                [widthTextColumns]="4" [required]="true"></components-ui-date-box>
            </div>
          </div>
          <!-- <div class="row">
            <div class="col-md-4">
              <component-ui-combo-box label='Contenido' [tabIndex]="3" [zeroMask]="2" [setItemsList]="this.contenidos"
                [tabIndex]="3" [widthTextColumns]="4" [widthLabelColumns]="6"
                (onSelectedItem)="selectedCombo($event,'Num1')" inputProperty="ClaveNombre" listProperty="ClaveNombre"
                [selectManualItem]="selectContenidoItem" formControlName="Num1"
                helpLine="Contenido de la información para la impresión del reporte." />
            </div>
          </div> -->
          <div class="row">
            <div class="col-md-4">
              <component-ui-combo-box label='Ordenado' [tabIndex]="4" [zeroMask]="2" [setItemsList]="this.ordenados"
                [tabIndex]="3" [widthTextColumns]="4" [widthLabelColumns]="6"
                (onSelectedItem)="selectedCombo($event,'Num2')" inputProperty="ClaveNombre" listProperty="ClaveNombre"
                [selectManualItem]="selectOrdenadoItem" formControlName="Num2"
                helpLine="Ordenado de la información para la impresión del reporte." />
            </div>
          </div>
        </div>
      </app-panel-base>
      <app-panel-base title="Filtros">
        <div class="panel-content">
          <component-ui-selection-filter [(FiltrosSeleccion)]="this.filtrosIngresosPeriodo" [tabIndex]="5"
            (FiltrosSeleccionChange)="changeselec($event)"
            [ReportHeader]="this.frmIngresosPeriodo"></component-ui-selection-filter>
        </div>
      </app-panel-base>
    </form>
  </div>
</app-container-base>
