<app-container-base (onItemsComboSearched)="onItemsComboSearched($event)" (onItemSearched)="onItemSearched($event)"
  [entity]="myForm" (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)"
  (onClickBarButton)="clickBoton($event)" [printManual]="true" entityName="Usuario" title="Usuarios"
  icon="fa fa-building" subTitle="Administra la configuración de usuarios...">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Información">
        <div class="panel-content">
          <div class="row form-group">
            <div class="col-md-6">
              <component-ui-combo-box (onNewCatalog)="onNewCatalog($event)" [label]="'Usuario'"
                (onChangueEntity)="onChangeEntity($event)" formControlName="Id" [isCatalog]="true"
                [isNewItem]="getIsNew" [zeroMask]="3" [setItems]="getItemsCombo" [tabIndex]="1"
                [listProperty]="'ClaveNombre'" [widthTextColumns]="2" [widthLabelColumns]="4" entityName="Usuario" />
              <components-ui-text-box [label]="'Nombre completo'" formControlName="Nombre" [tabIndex]="2"
                [widthTextColumns]="8" [widthLabelColumns]="4" [required]="true" #txtNombre [maxLength]="70" />
              <components-ui-text-box [label]="'Alias'" formControlName="Alias" [tabIndex]="3" [widthTextColumns]="8"
                [widthLabelColumns]="4" [required]="true" [maxLength]="50" />
              <components-ui-text-box [label]="'Email'" [dataType]="EDT.EMAIL" formControlName="Email" [tabIndex]="4"
                [widthTextColumns]="8" [widthLabelColumns]="4" [maxLength]="50" />
            </div>
            <div class="col-md-6">
              <div class="d-flex justify-content-center">
                <photo-avatar [photoUrl]="imgUrl" [name]="nombreUsuario" size="xxl"
                  (onChange)="onSelectFileImage($event)" [isImgProfile]="true"></photo-avatar>
              </div>
            </div>
          </div>
        </div>
      </app-panel-base>

      <ul class="nav nav-tabs m-2 bg-light" role="tablist">
        <li class="nav-item" role="presentation">
          <a href="#usuario-default-tab-1" data-bs-toggle="tab" class="nav-link text-dark active" aria-selected="true" role="tab">
            <span class="d-sm-block d-none">
              Seguridad
            </span>
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a href="#usuario-default-tab-2" data-bs-toggle="tab" class="nav-link text-dark" aria-selected="false" role="tab">
            <span class="d-sm-block d-none">Clasificaciones</span>
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a href="#usuario-default-tab-3" data-bs-toggle="tab" class="nav-link text-dark" aria-selected="false" role="tab">
            <span class="d-sm-block d-none">Contabilidad</span>
          </a>
        </li>
      </ul>

      <div class="tab-content panel rounded-0 me-2 ms-2 mt-0">
        <div id="usuario-default-tab-1" class="tab-pane fade active show" role="tabpanel">

          <app-panel-base title="Seguridad">
            <div class="panel-content">
              <div class="row form-group">
                <div class="col-md-6">
                  <components-ui-text-box [label]="'Login'" #txtLogin formControlName="Login" [tabIndex]="5"
                    [widthTextColumns]="4" [widthLabelColumns]="4" [required]="true" [maxLength]="50"
                    (blur)="validarUsuarioExistente($event)" />
                </div>
                <div class="col-md-4">
                  <component-ui-check-box [label]="'Sol. cambio de contraseña'"
                    formControlName="SolicitarCambioContrasena" [tabIndex]="6" [widthLabelColumns]="6"
                    [widthTextColumns]="4" />
                </div>
                <div class="col-md-2">
                  <component-ui-check-box [label]="'No expira sesión'" formControlName="NoExpiraSesion" [tabIndex]="7"
                    [widthTextColumns]="4" [widthLabelColumns]="7" />
                </div>
              </div>
              <div class="row form-group">
                <div class="col-md-1">
                  <div class="form-check form-switch mb-2">
                    <!-- [checked]="this.checkCambiarContrasena" -->
                    <input formControlName="checkCambiarContrasena" class="form-check-input" type="checkbox"
                      id="flexSwitchCheckDefault" (change)="onChangePass($event)" [tabIndex]="8">
                    <label class="form-check-label" for="flexSwitchCheckDefault">Cambiar</label>
                  </div>
                </div>
                <div class="col-md-3">
                  <components-ui-password-box [enabled]="this.enableContrasena" [label]="'Contraseña'"
                    formControlName="Contrasena" [tabIndex]="9" [widthTextColumns]="8" [widthLabelColumns]="4"
                    [maxLength]="50" />
                </div>
                <div class="row form-group">
                  <div class="col-md-6">
                    <span *ngIf="isValidField('Contrasena')" class="form-text text-danger">
                      Las contraseña debe tener mas de 6 caracteres de longitud.
                    </span>
                  </div>
                </div>
              </div>
              <div class="row form-group">
                <div class="col-md-6">
                  <components-ui-password-box formControlName="ConfContrasena" [enabled]="this.enableContrasena"
                    [label]="'Confirmar contraseña'" [tabIndex]="10" [widthTextColumns]="4" [widthLabelColumns]="4"
                    [maxLength]="50" />
                </div>
              </div>
              <div class="row form-group mb-1">
                <div class="col-md-6">
                  <span *ngIf="isValidField('ConfContrasena')" class="form-text text-danger">
                    Las contraseñas deben de ser iguales
                  </span>
                </div>
              </div>
              <components-ui-time-box label="Hora inicia labor" formControlName="HoraInicioLabor" [tabIndex]="11"
                [widthTextColumns]="1" [widthLabelColumns]="2"></components-ui-time-box>
              <components-ui-time-box label="Hora fin labor" formControlName="HoraFinLabor" [tabIndex]="12"
                [widthTextColumns]="1" [widthLabelColumns]="2"></components-ui-time-box>
              <div class="form-group row">
                <div class="col-sm-2">
                  <label class="col-sm-12 col-form-label tb-right">Empresas y Sucursales:</label>
                </div>
                <div class="col-sm-4" style="padding-left:0px;">
                  <div (click)="openEmpresasSucursales()" class="btn btn-orange btn-xs" style='margin-bottom: 5px;'>
                    <i class="fa fa-folder-tree"></i>
                    Mostrar listado
                  </div>
                </div>
              </div>
              <div class="row form-group">
                <div class="col-md-6">
                  <components-ui-text-box [label]="'Documentos Puede Cancelar'" [enableIcon]="true"
                    [Entidad]="'DocumentoVenta'" formControlName="DocumentosPuedeCancelar" [tabIndex]="13"
                    [widthTextColumns]="4" [widthLabelColumns]="4" [required]="false" [maxLength]="500" />
                </div>
              </div>
            </div>
          </app-panel-base>
        </div>
        <div id="usuario-default-tab-2" class="tab-pane fade" role="tabpanel">
          <app-panel-base title="Clasificación y opciones">
            <div class="panel-content">
              <div class="row form-group">
                <div class="col-md-6">
                  <component-ui-combo-box [required]="true" [label]="'Rol'" formControlName="Rol" [tabIndex]="14"
                    [zeroMask]="2" entityName="Rol" [widthTextColumns]="3" [widthLabelColumns]="4"
                    [widthRightColumns]="2" (onSelectedItem)="selectedRol($event)" [listProperty]="'ClaveNombre'" />
                </div>
                <div class="col-md-4">
                  <component-ui-check-box [label]="'Es Vendedor'" formControlName="EsVendedor" [tabIndex]="15"
                    [widthTextColumns]="4" [widthLabelColumns]="6" />
                </div>
                <div class="col-md-2">
                  <component-ui-check-box [label]="'Es Cajero'" formControlName="EsCajero" [tabIndex]="16"
                    [widthTextColumns]="4" [widthLabelColumns]="6" />
                </div>
              </div>
              <div class="row form-group">
                <div class="col-md-6">
                  <component-ui-combo-box [required]="true" [label]="'Puesto'" formControlName="Puesto" [tabIndex]="17"
                    [zeroMask]="2" entityName="Puesto" [widthTextColumns]="3" [widthLabelColumns]="4"
                    [widthRightColumns]="2" (onSelectedItem)="selectedPuesto($event)" />
                </div>
                <div class="col-md-4">
                  <component-ui-check-box [label]="'No validar precio de vta.'" formControlName="NoExpiraSesion"
                    [tabIndex]="18" [widthTextColumns]="6" [widthLabelColumns]="6" />
                </div>
                <div class="col-md-2">
                  <component-ui-check-box [label]="'Es Cobrador'" formControlName="EsCobrador" [tabIndex]="19"
                    [widthTextColumns]="4" [widthLabelColumns]="6" />
                </div>
              </div>
              <div class="row form-group">
                <div class="col-md-6">
                  <component-ui-combo-box [label]="'Jefe inmediato'" formControlName="JefeInmediato" [tabIndex]="20"
                    [zeroMask]="3" entityName="Usuario" [widthTextColumns]="3" [widthLabelColumns]="4"
                    [widthRightColumns]="2" (onSelectedItem)="selectedJefe($event)" [filterQuery]="getFiltroUsuario" />
                  <component-ui-combo-box [label]="'Sustituto'" formControlName="Sustituto" [tabIndex]="21"
                    [zeroMask]="3" entityName="Usuario" [widthTextColumns]="3" [widthLabelColumns]="4"
                    [widthRightColumns]="2" (onSelectedItem)="selectedSustituto($event)"
                    [filterQuery]="getFiltroUsuario" />
                  <component-ui-combo-box [label]="'Empresa'" formControlName="EmpresaPertenece" [tabIndex]="22"
                    [zeroMask]="3" entityName="Empresa" [widthTextColumns]="3" [widthLabelColumns]="4"
                    [widthRightColumns]="2" (onSelectedItem)="selectedEmpresa($event)" />
                  <component-ui-combo-box [label]="'Sucursal'" [filterQuery]="getFilterSucursal"
                    formControlName="SucursalPertenece" [tabIndex]="23" [zeroMask]="3" entityName="Sucursal"
                    [widthTextColumns]="3" [widthLabelColumns]="4" [widthRightColumns]="2"
                    (onSelectedItem)="selectedSucursal($event)" />
                </div>
              </div>
              <component-ui-check-box [label]="'Puede actualizar base de datos'"
                formControlName="PuedeActualizarBaseDatos" [tabIndex]="24" [widthTextColumns]="3"
                [widthLabelColumns]="2" />
              <component-ui-check-box [label]="'Es Distribuidor COMPLIT'" formControlName="EsDistribuidor"
                [tabIndex]="25" [widthTextColumns]="3" [widthLabelColumns]="2" />

              <component-ui-check-box [label]="'No permitir editar encabezados de póliza'"
                helpLine="No se permirá que el Usuario pueda editar encabezados de pólizas contables."
                formControlName="NoEditarEncabezadoPoliza" [tabIndex]="26" [widthTextColumns]="3"
                [widthLabelColumns]="2" />

              <component-ui-check-box [label]="'¿Dado de baja?'" formControlName="Baja" [tabIndex]="27"
                [widthTextColumns]="3" [widthLabelColumns]="2" />

              <component-ui-check-box [label]="'¿Es Vendedor Foraneo?'" formControlName="EsVendedorForaneo"
                [tabIndex]="28"
                helpLine="Si esta opción está seleccionada, el usuario al ingresar al sistema se le mostrará solo el menú de CRM de forma responsiva."
                [widthTextColumns]="3" [widthLabelColumns]="2" />

            </div>
          </app-panel-base>
        </div>
        <div id="usuario-default-tab-3" class="tab-pane fade" role="tabpanel">
          <app-panel-base title="Asientos contables por póliza a generar">
            <div class="panel-content">
              <app-ctrl-asiento-contable [Asientos]="myForm.value.Asientos" (onClose)="onCloseAsiento($event)" />
            </div>
          </app-panel-base>
        </div>
      </div>


    </form>
  </div>
</app-container-base>

<ng-template #empresasSucursalesTree let-modal let-c="close">
  <div class="bg-light animate__animated animate__backInDown animate__faster">
    <app-panel-base title="Empresas y Sucursales">
      <div class="panel-content">
        <div class="tree-container-rol bg-white">
          <ng-scrollbar style="height: 450px;">
            <div *ngFor="let item of empresas; let i = index" class="item-list-empresa">
              <i (click)="onSelect(item)" [class]="getCaret(item)"
                style="cursor: pointer;margin-left: 4px;width:10px;color: #348fe2;"></i>
              <input (click)="seleccionar(item)" [checked]="item.Seleccionado" class="form-check-input" type="checkbox"
                style="margin-left: 4px;" />
              <i *ngIf="item.Icono" style="margin-left: 5px; width:18px;text-align: center;"
                class="fas fa-{{item.Icono}} fa-lg"></i>
              <a *ngIf="item.Img"><img style="width: 17px;margin-left: 6px;" src="{{item.Img}}"></a>
              <span class="text-dark tree-item" (click)="onSelect(item)"
                style="margin-left: 5px;padding-top: 3px;cursor: pointer;">{{item.Nombre}}</span>

              <div *ngIf="item.IsExpanded" class="animate__animated animate__fadeIn animate__faster"
                style="padding-left: 50px;">
                <div *ngFor="let item2 of item.SubMenus;">
                  <i (click)="onSelect(item2)" [class]="getCaret(item2, item2.SubMenus!.length==0)"
                    style="cursor: pointer;margin-left: 4px;width:10px;color: #348fe2;"></i>
                  <input (click)="seleccionar(item2)" [checked]="item2.Seleccionado" class="form-check-input mb-1"
                    type="checkbox" style="margin-left: 4px;" />
                  <i *ngIf="item.Icono" style="margin-left: 5px; width:18px;text-align: center;"
                    class="far fa-{{item2.Icono}} fa-lg"></i>
                  <span class="text-dark tree-item" (click)="onSelect(item2)"
                    style="margin-left: 5px;padding-top: 3px;cursor: pointer;">{{item2.Nombre}}</span>
                </div>
              </div>
            </div>
          </ng-scrollbar>
        </div>

        <div style="text-align: right; margin-top: 5px;">
          <button (click)="accept()" class="btn btn-success me-5px">Aceptar</button>
        </div>
      </div>
    </app-panel-base>
  </div>
</ng-template>
