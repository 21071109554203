import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { DateBoxComponent } from 'src/app/component-ui/components/date-box/date-box.component';
import { FacturacionService } from 'src/app/ventas/services/facturacion.service';
import { OrdenManufactura } from '../../interfaces/manufacura.interface';
import { EventsService } from 'src/app/service/events.service';
import { ModalService } from 'src/app/service/modal.service';

@Component({
  selector: 'app-busqueda-orden-page',
  templateUrl: './busqueda-orden-page.component.html',
  styles: [
  ]
})
export class BusquedaOrdenPageComponent implements OnInit {
  @ViewChild('txtFecha')
  public txtFecha!: ElementRef<DateBoxComponent>;
  private eventsService = inject(EventsService);
  @Output()
  public onClose: EventEmitter<any> = new EventEmitter();

  lista: any[] = [];
  indexEditing: number = 0;

  public myForm: FormGroup = this.fb.group({
    FechaInicio: [Date],
    FechaFin: [Date],
    Factura: [''],
    Cliente: [''],
  })

  constructor(private fServices: FacturacionService, private fb: FormBuilder, private ms: ModalService) { }

  ngOnInit(): void {
    const f = {
      FechaInicio: new Date(),
      FechaFin: new Date(),
      Factura: '',
      Cliente: ''
    }
    this.myForm.reset(f);

    setTimeout(() => {
      const txt: any = this.txtFecha;
      txt.tagInput.nativeElement.focus();
    }, 150);

  }

  buscar() {
    const elem = this.myForm.value;
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.fServices.getHistoricoOrdenes(
      moment(elem.FechaInicio).format("YYYYMMDD"),
      moment(elem.FechaFin).format("YYYYMMDD"),
      elem.Cliente,
      elem.Factura, true).subscribe((lista) => {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        this.lista = lista;
      })
  }

  seleccionar(orden: OrdenManufactura) {
    this.ms.closeModal(orden);
  }

  getDate(fecha: any): string {
    if (fecha) {
      return moment(fecha).format("DD/MM/YYYY HH:mm:ss")
    }
    return "";
  }

  closeModal() {
    this.ms.closeModal(null);
  }
}
