<div class="card text-center border-0" cdkDrag
  style="box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;">
  <div class="card-header fw-bold" style="cursor: move;" cdkDragHandle>
    <span>
      {{'Indique la cantidad a envíar a producir' | uppercase}}
    </span>
  </div>
  <div class="card-body">
    <input type="number" #text>
  </div>
  <div class="card-footer fw-bold">
    <div style="text-align: right; margin-top: 5px;">
      <div (click)="accept()" class="btn btn-success me-1">Aceptar</div>
      <button (click)="closeModal()" class="btn btn-danger">Cerrar</button>
    </div>
  </div>
</div>
