import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { thru } from 'lodash';
import * as moment from 'moment';
import { UserLogged } from 'src/app/auth/interfaces';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { FilterOptions, ReportFilter, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { TabsNavService } from 'src/app/components/services/tabs-nav.service';
import { Empresa } from 'src/app/configuracion/interfaces/empresa.interface';
import { Usuario } from 'src/app/configuracion/interfaces/usuario.interface';
import { BaseService } from 'src/app/service/base.service';
import { EventsService } from 'src/app/service/events.service';
import { ModalService } from 'src/app/service/modal.service';
import { ReportsService } from 'src/app/service/reports.service';
import { UtilsService } from 'src/app/service/utils.service';
import { CfdiRelacionadoContancia, ConstanciaRetencion, ReceptorRetencion, RetencionComplementoDividendo, SerieConstanciaRetencion, TipoComplementoRetencion, TipoDividendoUtilidad, TipoSociedad, c_CveRetenc, c_TipoImpuesto, c_TipoPagoRet } from 'src/app/ventas/interfaces/retenciones.interface';
import { FacturacionService } from 'src/app/ventas/services/facturacion.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-constancia-pagos-page',
  templateUrl: './constancia-pagos-page.component.html',
  styles: [
  ]
})
export class ConstanciaPagosPageComponent implements OnInit {
  reportsService = inject(ReportsService)

  @ViewChild('cboSerie')
  public cboSerie!: ElementRef<any>;
  @ViewChild('cboReceptor')
  public cboReceptor!: ElementRef<any>;
  @ViewChild('cfdiRelacionado')
  public cfdiRelacionado!: ElementRef<HTMLElement>;
  @ViewChild('modalCancelOptiones')
  public modalCancelOptiones!: ElementRef<HTMLElement>;
  private readonly baseUrl: string = environment.baseUrlApi;
  private tabsNavService = inject(TabsNavService);
  private eventsService = inject(EventsService);
  private baseService = inject(BaseService);
  private info: UserLogged = {} as UserLogged;
  public listaSeries: ComboBoxEntity[] = [];
  activeButtons: ActiveButtons = {
    new: true,
    delete: false,
    return: false,
    save: true,
    first: true,
    left: true,
    right: true,
    last: true,
    search: false,
    print: true,
  }

  private newEntityObj = {
    Id: [0],
    Empresa: [{} as Empresa],
    Serie: [{} as SerieConstanciaRetencion],
    SerieString: [''],
    Folio: [0],
    FechaEmision: [Date],
    FechaEmisionString: [''],
    MotivoCancelacion: [''],
    UUIDSustituye: [''],
    FechaCancelacion: [null],
    UsuarioAlta: [{} as Usuario],
    UsuarioAltaId: [0],
    UsuarioCancela: [{} as Usuario],
    UsuarioCancelaId: [0],
    UUID: [''],
    Ejercicio: [0],
    MesInicial: [0],
    MesFinal: [0],
    Retencion: [{} as c_CveRetenc],
    Receptor: [{} as ReceptorRetencion],
    TipoComplemento: [{} as TipoComplementoRetencion],
    ComplementoDividendo: [{} as RetencionComplementoDividendo],
    TipoImpuesto: [{} as c_TipoImpuesto],
    TipoPagoRetencion: [{} as c_TipoPagoRet],
    CfdisRelacionados: [[]],
    MontoTotGrav: [0],
    MontoTotExent: [0],
    MontoTotRet: [0],
    MontoTotOperacion: [0],
  };

  public myForm: FormGroup = this.fb.group(this.newEntityObj);
  public myFormDividendo: FormGroup = this.fb.group({
    Id: [0],
    TipoDividendoUtilidad: [{} as TipoDividendoUtilidad],
    MontISRAcredRetMexico: [0],
    MontISRAcredRetExtranjero: [0],
    TipoSociedad: [{} as TipoSociedad],
    RetencionExtranjero: [0],
    MontISRAcredNal: [0],
    MontDivAcumNal: [0],
    MontDivAcumExtranjero: [0],
  });


  public filtroConctancia: ReportFilter =
    {
      ReportHeader: {} as ReportHeader,
      TituloVisor: 'Constancia de retenciones',
      NombreReporte: '',
      NombreExcel: '',
      FilterOptions: [
        { Campo: 'constancia.Id', Etiqueta: '', Tipo: TypeFilter.number },
      ]
    };


  constructor(private fb: FormBuilder, private cService: ContainerBaseService, private ms: ModalService, private utileService: UtilsService, private fService: FacturacionService, private http: HttpClient) { }

  get esNuevo() {
    return this.myForm.value.Id == 0;
  }

  onSelectedItemDiv(entity: any, type: string) {

    this.myFormDividendo.controls[type].setValue(entity);

  }

  onSelectedItem(entity: any, type: string) {
    if (type == "Serie") {
      let entrar = true;
      if (this.myForm.value.Serie) {
        if (this.myForm.value.Serie.Id == entity.Id) {
          entrar = false;
        }
      }
      if (entrar) {
        //this.getEmptyEntity(entity.Id);
      }
    } else {
      this.myForm.controls[type].setValue(entity);
    }
  }



  blurFolio(values: any) {
    if (values.after != values.before) {
      this.findEntityByParams();
    }
  }

  findEntityByParams() {
    const ent = this.myForm.value;
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.fService.findRetByParams(ent.Empresa.Id, ent.SerieString, ent.Folio).subscribe((rep) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      this.setEntity(rep);
    });
  }

  setEntity(venta: ConstanciaRetencion) {
    if (!venta) {
      this.new();
    } else {
      let ent = this.myForm.value;
      ent = { ...venta };
      this.myForm.reset(ent);
      if (ent.ComplementoDividendo) {
        this.myFormDividendo.reset(ent.ComplementoDividendo);
      }
    }
  }

  new() {
    const ent: ConstanciaRetencion = this.myForm.value;
    this.getEmptyEntity(ent.Serie!.Id);
    this.focusReceptor();
  }

  openCfdiRelations() {
    const b: any = this.cfdiRelacionado;
    this.ms.openModal(b, (e: any) => {
      if (e) {
        this.myForm.value.CfdisRelacionados = [];
        const uuids = e.UUIDS.split(',');
        uuids.forEach((el: string) => {
          this.myForm.value.CfdisRelacionados = [...this.myForm.value.CfdisRelacionados, {
            UUID: el,
            TipoRelacion: e.TipoRelacion
          }]
        });
      }
    })
  }

  cancelar() {
    const b: any = this.modalCancelOptiones;
    this.ms.openModal(b, (e: any) => {
      if (e) {
        this.eventsService.publish('home:isLoading', { isLoading: true });
        const claveCancelacion = e.MotivoSAT.Clave
        this.fService.CancelarRetencion(this.myForm.value.Id, e.MotivoCancelacion, claveCancelacion, e.UUID).subscribe((result) => {
          this.eventsService.publish('home:isLoading', { isLoading: false });
          if (result.success) {
            Swal.fire({ position: 'center', icon: 'success', title: 'Se canceló correctamente', showConfirmButton: false, timer: 2000 });
            this.findEntityByParams();
          } else {
            this.eventsService.publish('home:showAlert', {
              message: result.message,
              cancelButton: false,
            });
          }
        });
      }
    })

  }

  reload(id: number) {
    this.fService.findRetById(id).subscribe((result) => {
      this.setEntity(result);
    });
  }

  onClickBarButton(button: string): void {
    switch (button) {
      case "new": this.new(); break;
      case "save": this.save(); break;
      case "print": this.print(); break;
      case "first": this.navigate(button); break;
      case "left": this.navigate(button); break;
      case "right": this.navigate(button); break;
      case "last": this.navigate(button); break;
    }
  }
  get getItemsSerie(): ComboBoxEntity[] {
    return this.listaSeries;
  }

  navigate(type: string) {
    this.findEntityByParamsNavigate(type);
  }

  findEntityByParamsNavigate(type: string) {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    const params = new HttpParams()
      .set("idEmpresa", this.myForm.value.Empresa.Id)
      .set("serie", this.myForm.value.SerieString)
      .set("folio", this.myForm.value.Folio)
      .set("tipo", type);
    this.http.get<ConstanciaRetencion>(`${this.baseUrl}/Ventas/ObtenerRetPorParametrosNavegacion`, { params }).subscribe((venta) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      this.setEntity(venta);
    });
  }

  downloadFiles() {
    if (this.myForm.value.Id > 0) {
      this.fService.downloadFilesRet(this.myForm.value.Id);
    } else {
      this.eventsService.publish('home:showAlert', {
        message: 'El REP no se ha guardado',
        cancelButton: false,
      });
    }
  }

  save() {
    let ent = this.myForm.value;
    if (this.myForm.valid) {
      if (ent.TipoComplemento?.Id != 2) {
        this.eventsService.publish('home:showAlert', {
          message: 'Indique un Complemento válido.',
          cancelButton: false,
        });
        return;
      }
      if (ent.Id > 0) {
        this.eventsService.publish('home:showAlert', {
          message: 'No se puede actualizar, solo cancelar.',
          cancelButton: false,
        });
        return;
      }

      ent.FechaEmisionString = moment(ent.FechaEmision).format("YYYY-MM-DDTHH:mm:ss");
      this.eventsService.publish('home:isLoading', { isLoading: true });
      ent.ComplementoDividendo = this.myFormDividendo.value;
      console.log(ent)
      this.fService.saveRet(ent).subscribe((result) => {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        if (result.success) {
          Swal.fire({ position: 'center', icon: 'success', title: 'Se guardó correctamente', showConfirmButton: false, timer: 1000 }).then(() => {
            const v: ConstanciaRetencion = JSON.parse(result.message);
            this.myForm.reset(v);
            if (v.ComplementoDividendo) {
              this.myFormDividendo.reset(v.ComplementoDividendo);
            }
            this.print();
            // this.print(() => {
            //   this.sendEmail();
            // });
          });
        } else {
          this.eventsService.publish('home:showAlert', {
            message: result.message,
            cancelButton: false,
          });
        }
      });
    }
  }

  calcTotal(value: any) {
    setTimeout(() => {
      let totRet = 0;
      const ent = this.myForm.value;
      totRet += parseFloat(ent.MontoTotGrav);
      totRet += parseFloat(ent.MontoTotExent);
      this.myForm.controls["MontoTotOperacion"].setValue(totRet);
    }, 150);
  }

  calcularRet(value: any) {
    setTimeout(() => {
      let totRet = 0;
      const ent = this.myFormDividendo.value;
      totRet += parseFloat(ent.MontISRAcredRetMexico);
      totRet += parseFloat(ent.MontISRAcredRetExtranjero);
      this.myForm.controls["MontoTotRet"].setValue(totRet);
    }, 150);
  }

  initializeEntity(ent: any, idSerie: number = 0) {
    ent.Empresa = { Id: this.info.empresa!.numero, Clave: this.info.empresa!.clave, Nombre: this.info.empresa!.nombre };
    ent.Ejercicio = new Date().getFullYear();
    ent.MesInicial = new Date().getMonth() + 1;
    ent.MesFinal = new Date().getMonth() + 1;
    if (idSerie > 0) {
      const serie = this.listaSeries.filter((P) => P.Id == idSerie)[0];
      ent.Serie = { Id: idSerie };
      ent.SerieString = serie.Serie;
    }
    this.getNextFolio(ent);
  }

  getNextFolio(ent: ConstanciaRetencion) {
    this.fService.getNextFolioRet(ent.Empresa!.Id, ent.SerieString).subscribe((folio) => {
      ent.Folio = folio;
      this.getDate(ent);
    })
  }

  getDate(ent: ConstanciaRetencion) {
    this.fService.ObtenerFechaPorSucursal(this.info.sucursal!.numero).subscribe((result) => {
      //2024-01-29T10:23:49
      const f = result.message.split('T')[0].split('-');
      const t = result.message.split('T')[1].split(':');
      ent.FechaEmision = new Date(parseInt(f[0]), parseInt(f[1]) - 1, parseInt(f[2]), parseInt(t[0]), parseInt(t[1]), parseInt(t[2]));
      this.eventsService.publish('home:isLoading', { isLoading: false });
      this.myForm.reset(ent);
      this.cService.getEmptyEntity("RetencionComplementoDividendo").subscribe((entd) => {
        this.myFormDividendo.reset(entd);
      });
      this.focusReceptor();
    })
  }

  focusReceptor() {
    const txt: any = this.cboReceptor;
    txt.tagInput.nativeElement.focus()
  }

  getEmptyEntity(idSerie: number = 0) {
    this.cService.getEmptyEntity("ConstanciaRetencion").subscribe((ent) => {
      this.initializeEntity(ent, idSerie)
    })
  }

  getSeries() {
    const txt: any = this.cboSerie;
    txt.tagInput.nativeElement.value = "";
    this.fService.getSeriesRet(this.info.empresa!.numero).subscribe((lista) => {
      if (lista.length > 0) {
        this.listaSeries = lista;
        if (lista.length == 1) {
          this.getEmptyEntity(this.listaSeries[0].Id);
        } else {
          this.eventsService.publish('home:isLoading', { isLoading: false });
          setTimeout(() => {
            let ent = this.myForm.value;
            ent.Empresa = { Id: this.info.empresa!.numero };
            txt.tagInput.nativeElement.value = '';
            txt.tagInput.nativeElement.focus()
            this.myForm.reset(ent);
          }, 150);
        }
      } else {


        this.eventsService.publish('home:showAlert', {
          message: "Primero debe Configurar una Serie de Constancias de Pago y Retenciones.",
          cancelButton: false,
          onConfirm: (data: AlertResponse) => {
            this.tabsNavService.closeCurrentTab();
          }
        });
      }
    })
  }

  ngOnInit(): void {
    this.info = this.utileService.getUserLogged();
    setTimeout(() => {
      this.getSeries();
    }, 250);
  }



  print() {
    if (this.myForm.value.Id == 0) {
      this.eventsService.publish('home:showAlert', {
        message: "Es necesario que primero guarde su captura antes de poder imprimirlo, por favor verifique.",
        cancelButton: false
      });
      return;
    }


    let reportHeader: ReportHeader = {
      Fecha1: this.myForm.get('FechaEmision')?.value,
      Fecha2: this.myForm.get('FechaEmision')?.value,
      NombreReporte: '',
      Dato1: '',
      Opc1: true,
    }
    let filtro: FilterOptions | undefined = this.filtroConctancia.FilterOptions?.find(p => p.Campo == 'constancia.Id');
    if (filtro) {
      filtro!.Valor = String(this.myForm.get('Id')?.value);
    }
    this.filtroConctancia.ReportHeader = reportHeader;
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.reportsService.printReport(this.filtroConctancia, '/Ventas/ImprimirConstanciaRetenciones', false, () => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
    });

    //this.reportsService.printReport(this.filtroConctancia, '/Ventas/ImprimirConstanciaRetencionesFormato');

  }




}

