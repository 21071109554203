import { Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserLogged } from 'src/app/auth/interfaces';
import { DateBoxComponent } from 'src/app/component-ui/components/date-box/date-box.component';
import { EnumDataType } from 'src/app/component-ui/components/text-box/text-box.component';
import { Empresa } from 'src/app/configuracion/interfaces/empresa.interface';
import { EventsService } from 'src/app/service/events.service';
import { UtilsService } from 'src/app/service/utils.service';
import { LevelTextBoxComponent } from '../../../component-ui/components/level-text-box/level-text-box.component';
import { EfiscoService } from '../../services/efisco.service';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { c_Municipio } from '../../../ventas/interfaces/cartaporte.interface';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';

@Component({
  selector: 'app-descarga-xml-captcha-page',
  templateUrl: './descarga-xml-captcha-page.component.html',
  styles: [
  ]
})
export class DescargaXmlCaptchaPageComponent implements OnInit {

  saving: boolean = false;
  public EDT = EnumDataType;
  info: UserLogged = {} as UserLogged;
  @ViewChild('txtFechaInicio')
  public txtFechaInicio!: ElementRef<DateBoxComponent>;

  private eventsService = inject(EventsService);

  constructor(private fb: FormBuilder, private utilsService: UtilsService, private eService: EfiscoService) { }

  public myForm: FormGroup = this.fb.group({
    Empresa: [{} as Empresa],
    FechaInicio: [Date, Validators.required],
    FechaFin: [Date, Validators.required],
    RfcEmisor: [''],
    UUID: [''],
    TipoDeComprobante: [null],
    Emitidos: [false],
    Recibidos: [true],
  })

  ngOnInit(): void {
    document.getElementsByTagName
    this.info = this.utilsService.getUserLogged();
    this.myForm.reset({
      Empresa: { Id: this.info.empresa!.numero },
      FechaInicio: new Date(),
      FechaFin: new Date(),
      RfcEmisor: '',
      UUID: '',
      Emitidos: false,
      Recibidos: true,
    })
    setTimeout(() => {
      const t: any = this.txtFechaInicio;
      t.tagInput.nativeElement.focus();
    }, 150);
  }

  clickEmitido(checked: boolean) {
    if (checked) {
      this.myForm.controls["Emitidos"].setValue(true);
      this.myForm.controls["Recibidos"].setValue(false);
      this.myForm.controls["RfcEmisor"].setValue("");
      this.myForm.controls["UUID"].setValue("");
    } else {
      setTimeout(() => {
        this.myForm.controls["Emitidos"].setValue(true);
      }, 50);
    }
  }

  clickRecibido(checked: boolean) {
    if (checked) {
      this.myForm.controls["Recibidos"].setValue(true);
      this.myForm.controls["Emitidos"].setValue(false);
    } else {
      setTimeout(() => {
        this.myForm.controls["Recibidos"].setValue(true);
      }, 50);
    }
  }

  selectedCombo(entity: ComboBoxEntity, tipo: string) {
    this.myForm.controls[tipo].setValue(entity);
  }

  solicitar() {
    let tieneUUID: boolean = false;
    let tieneRFC: boolean = false;
    if (this.myForm.value.RfcEmisor) {
      if (this.myForm.value.RfcEmisor.length > 0) {
        if (this.myForm.value.RfcEmisor.length != 12 && this.myForm.value.RfcEmisor.length != 13) {
          this.eventsService.publish('home:showAlert', { message: "Debe de indicar el RFC del Proveedor completo.", cancelButton: false });
          return;
        }
        tieneUUID = true;
      }
    }
    if (this.myForm.value.UUID) {
      if (this.myForm.value.UUID.length > 0) {
        if (this.myForm.value.UUID.length != 36) {
          this.eventsService.publish('home:showAlert', { message: "El UUID indicado es erroneo, debe de contener 36 caracteres.", cancelButton: false });
          return;
        }
        tieneRFC = true;
      }
    }

    if (tieneUUID && tieneRFC) {
      this.eventsService.publish('home:showAlert', { message: "No puede indicar los 2 filtros, RFC del Emisor y UUID, indique solo uno, la página del SAT no permite ambos filtros.", cancelButton: false });
      return;
    }

    this.saving = true;
    const ent = this.myForm.value;
    let tipoComp = "";
    debugger
    if (ent.TipoDeComprobante) {
      tipoComp = ent.TipoDeComprobante.Clave;
    }
    this.eService.descargarCaptcha(this.info.empresa!.numero, moment(ent.FechaInicio).format("DD/MM/YYYY"),
      moment(ent.FechaFin).format("DD/MM/YYYY"), ent.RfcEmisor, ent.UUID, ent.Recibidos, tipoComp).subscribe((result) => {
        this.saving = false;
        if (result.success) {
          Swal.fire({ position: 'center', icon: 'success', title: `Se han descargado los CFDIS ${(ent.Recibidos ? 'Recibidos' : 'Emitidos')}.`, showConfirmButton: true, }).then(() => {
          });
        } else {
          if (result.message != "CANCELADO") {
            this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
          }
        }
      });
  }
}
