import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { LevelType, ReportFilter, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ReportsService } from 'src/app/service/reports.service';

@Component({
  selector: 'diario-documentos-inventario',
  templateUrl: './verificador-documentos-inventario.component.html',
  styleUrls: ['./verificador-documentos-inventario.component.scss']
})
export class VerificadorDocumentosInventarioComponent {
  @ViewChild('txtNombreReporte')
  public txtNombreReporte!: ElementRef;

  private frmBuilder = inject(FormBuilder);
  private reportsService = inject(ReportsService);

  activeButtons: ActiveButtons = {
    all: false,
    print: true,
  }

  agrupados: ComboBoxEntity[] = [
    { Id: 1, Nombre: "Documento", Clave: 1, ClaveNombre: "01 Documento" },
    { Id: 2, Nombre: "Sucursal", Clave: 2, ClaveNombre: "02 Sucursal" },
    { Id: 3, Nombre: "Sucursal - Documento", Clave: 3, ClaveNombre: "03 Sucursal - Documento" },
    { Id: 4, Nombre: "Ninguno", Clave: 4, ClaveNombre: "04 Ninguno" },
  ];

  contenidos: ComboBoxEntity[] = [
    { Id: 1, Nombre: "A Detalle", Clave: 1, ClaveNombre: "01 A Detalle" },
    { Id: 2, Nombre: "A Totales", Clave: 2, ClaveNombre: "02 A Totales" },
  ];

  estados: ComboBoxEntity[] = [
    { Id: 1, Nombre: "Vigente", Clave: 1, ClaveNombre: "01 Vigente" },
    { Id: 2, Nombre: "Cancelado", Clave: 2, ClaveNombre: "02 Cancelado" },
    { Id: 3, Nombre: "Todos", Clave: 3, ClaveNombre: "03 Todos" },
  ];

  ngOnInit(): void {
    setTimeout(() => {
      const txt: any = this.txtNombreReporte;
      txt.tagInput.nativeElement.focus();
      this.contenidoSeleccionado = this.contenidos[0];
      this.agrupadoSeleccionado = this.agrupados[0];
      this.estadoSeleccionado = this.estados[0];
    }, 300);
  }

  public frmVerificadorDocumentosInventario: FormGroup = this.frmBuilder.group({
    NombreReporte: ["VERIFICADOR DE DOCUMENTOS DE INVENTARIO", [Validators.required]],
    Fecha1: [new Date(), [Validators.required]],
    Fecha2: [new Date(), [Validators.required]],
    Num1: [0],
    Num2: [0],
    Num3: [0],
  });

  public filtrosVerificadorDocumentosInventario: ReportFilter =
    {
      menuId: 'verificadordocsinventario',
      ReportHeader: {
        Fecha1: new Date(),
        Fecha2: new Date(),
        NombreReporte: 'Ingresos Por Período'
      } as ReportHeader,
      Fecha1: new Date(),
      Fecha2: new Date(),
      NombreReporte: 'Ingresos Por Período',
      TituloVisor: 'Ingresos Por Período',
      NombreExcel: 'Ingresos Por Período.xlsx',
      FilterOptions: [
        { Campo: 'emp.Clave', Etiqueta: 'Emrpesa', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Cliente" },
        { Campo: 'suc.Clave', Etiqueta: 'Sucursal', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Sucursal" },
        { Campo: 'prod.Clave', Etiqueta: 'Producto', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Producto" },
        { Campo: 'fam.ClaveGeneral', Etiqueta: 'Familias', Tipo: TypeFilter.levels, TipoNiveles: LevelType.productFamily },
        { Campo: 'doc.SerieString', Etiqueta: 'Serie', Tipo: TypeFilter.text },
        { Campo: 'doc.Folio', Etiqueta: 'Folio', Tipo: TypeFilter.number },
        { Campo: 'elabora.Clave', Etiqueta: 'Usuario Elabora', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Usuario" },
        { Campo: 'cancela.Clave', Etiqueta: 'Usuario Cancela', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Usuario" }
      ]
    };

  agrupadoSeleccionado?: ComboBoxEntity;
  get selectAgrupadoItem(): ComboBoxEntity | null {
    return this.agrupadoSeleccionado!;
  }

  estadoSeleccionado?: ComboBoxEntity;
  get selectEstadoItem(): ComboBoxEntity | null {
    return this.estadoSeleccionado!;
  }

  contenidoSeleccionado?: ComboBoxEntity;
  get selectContenidoItem(): ComboBoxEntity | null {
    return this.contenidoSeleccionado!;
  }

  selectedCombo(entity: any, field: string) {
    switch (field) {
      case "Num1":
        this.agrupadoSeleccionado = entity;
        break;
      case "Num2":
        this.contenidoSeleccionado = entity;
        break;
      default:
        this.estadoSeleccionado = entity;
        break;
    }
    this.frmVerificadorDocumentosInventario.get(field)?.setValue(entity?.Id);
  }

  changeselec(event: any) {
    this.filtrosVerificadorDocumentosInventario = event;
  }

  imprimir(event: any) {
    this.filtrosVerificadorDocumentosInventario.ReportHeader = this.frmVerificadorDocumentosInventario.value;
    this.reportsService.printReport(this.filtrosVerificadorDocumentosInventario, '/Inventarios/DiarioDocumentosInventario');
  }
}
