import { Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserLogged } from 'src/app/auth/interfaces';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { EventsService } from 'src/app/service/events.service';
import { FinanzasService } from '../../services/finanzas.service';
import { ModalService } from 'src/app/service/modal.service';
import { UtilsService } from 'src/app/service/utils.service';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';
import { TabsNavService } from 'src/app/components/services/tabs-nav.service';
import { ConfiguracionConciliacion, MovimientoConciliacion } from '../../interfaces/conciliacion.interface';
import * as moment from 'moment';
import { FileUploadService } from '../../../component-ui/services/file-upload-box.service';
import { orderBy } from 'lodash';
import { aC } from '@fullcalendar/core/internal-common';
import Swal from 'sweetalert2';
import { ReportsService } from 'src/app/service/reports.service';
import { ReportFilter, ReportHeader } from 'src/app/component-ui/interfaces/selection-filter.interface';

@Component({
  selector: 'app-estado-cuenta-conciliacion-page',
  templateUrl: './estado-cuenta-conciliacion-page.component.html',
  styles: [
  ]
})
export class EstadoCuentaConciliacionPageComponent implements OnInit {


  info: UserLogged = {} as UserLogged;
  seleccionado: number = 2;
  private eventsService = inject(EventsService);
  private tabsNavService = inject(TabsNavService);
  private reportsService = inject(ReportsService);
  private config: any;
  titulo: string = "";
  listaNosotros: MovimientoConciliacion[] = [];
  listaEllos: MovimientoConciliacion[] = [];
  listaAnteriores: MovimientoConciliacion[] = [];
  @ViewChild('txtFile')
  public txtFile!: ElementRef<HTMLInputElement>;


  public myForm: FormGroup = this.fb.group({
    Anterior: [0],
    Cargos: [0],
    Abonos: [0],
    Actual: [0],
  });

  ordenadosNosotros = [
    { name: 'tipoNumeroPoliza', order: 'desc' },
    { name: 'fecha', order: 'desc' },
    { name: 'referencia', order: 'desc' },
    { name: 'concepto', order: 'desc' },
    { name: 'importeCargo', order: 'desc' },
    { name: 'importeAbono', order: 'desc' },
    { name: 'conciliado', order: 'desc' },
  ];
  ordenadosEllos = [
    { name: 'tipoNumeroPoliza', order: 'desc' },
    { name: 'fecha', order: 'desc' },
    { name: 'referencia', order: 'desc' },
    { name: 'concepto', order: 'desc' },
    { name: 'importeCargo', order: 'desc' },
    { name: 'importeAbono', order: 'desc' },
    { name: 'conciliado', order: 'desc' },
  ];

  lastOrderNosotros: string = "";
  lastOrderAscNosotros: string = "";

  lastOrderEllos: string = "";
  lastOrderAscEllos: string = "";

  constructor(private fb: FormBuilder,
    private containerService: ContainerBaseService,
    private fs: FinanzasService,
    private ms: ModalService,
    private fuse: FileUploadService,
    private utileService: UtilsService) { }

  ngOnInit(): void {

    this.tabsNavService.getIsOpenTab("configConciliacion", (resp: any) => {
      if (resp) {
        this.eventsService.publish('home:showAlert', {
          message: `Cierre la pantalla de Configuración de Conciliación primero.`, cancelButton: false, onConfirm: (data: AlertResponse) => {
            this.tabsNavService.closeCurrentTab();
          }
        });
      } else {
        this.info = this.utileService.getUserLogged();
        this.obtenerUltimaConfiguracion();
      }
    })



  }

  ordenarNosotros(campo: string) {
    this.lastOrderNosotros = campo;
    let order = this.ordenadosNosotros.filter(P => P.name == campo)[0];
    let ordenado = orderBy(this.listaNosotros, [campo], [order.order == "desc" ? "asc" : "desc"]);
    order.order == "asc" ? order.order = "desc" : order.order = "asc";
    this.lastOrderAscNosotros = order.order;
    this.listaNosotros = [...ordenado];
  }

  ordenarEllos(campo: string) {
    this.lastOrderEllos = campo;
    let order = this.ordenadosEllos.filter(P => P.name == campo)[0];
    let ordenado = orderBy(this.listaEllos, [campo], [order.order == "desc" ? "asc" : "desc"]);
    order.order == "asc" ? order.order = "desc" : order.order = "asc";
    this.lastOrderAscEllos = order.order;
    this.listaEllos = [...ordenado];
  }

  obtenerUltimaConfiguracion() {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.fs.obtenerConfiguracionConciliacion(this.info.empresa!.numero, this.info.numero).subscribe((result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result.success) {
        if (result.message.length > 0) {
          const ent = JSON.parse(result.message);
          this.config = { ...ent };
          this.titulo = `Conciliando: ${this.config?.Cuenta?.Nombre}  Del ${moment(this.config?.FechaInicio).format("DD/MM/YYYY")} al ${moment(this.config?.FechaFin).format("DD/MM/YYYY")}`;
          this.importarContabilidad();
        } else {
          this.enviarMensajeError();
        }
      } else {
        this.enviarMensajeError();
      }
    });
  }

  blurSaldoAnterior() {
    if (this.seleccionado == 2) {
      if (this.listaNosotros.length > 0) {
        let anterior = this.myForm.value.Anterior;
        let cargos = this.myForm.value.Cargos;
        let abonos = this.myForm.value.Abonos;
        let actual = this.utileService.round(anterior + cargos - abonos);
        this.myForm.controls["Actual"].setValue(actual);
        this.myForm.controls["Anterior"].setValue(anterior);
        this.eventsService.publish('home:isLoading', { isLoading: true });
        let ids = "";
        this.listaNosotros.map((item) => {
          ids += `${item.id},`;
          item.saldoAnterior = anterior;
          item.saldoActual = actual;
          return item;
        });
        ids = ids.substring(0, ids.length - 1);
        this.fs.actualizarMovimientos(ids, anterior, cargos, abonos, actual).subscribe((result) => {
          this.eventsService.publish('home:isLoading', { isLoading: false });
        });
      }
    } else if (this.seleccionado == 3) {
      if (this.listaEllos.length > 0) {
        let anterior = this.myForm.value.Anterior;
        let cargos = this.myForm.value.Cargos;
        let abonos = this.myForm.value.Abonos;
        let actual = this.utileService.round(anterior - cargos + abonos);
        this.myForm.controls["Actual"].setValue(actual);
        this.myForm.controls["Anterior"].setValue(anterior);
        this.eventsService.publish('home:isLoading', { isLoading: true });
        let ids = "";
        this.listaEllos.map((item) => {
          ids += `${item.id},`;
          item.saldoAnterior = anterior;
          item.saldoActual = actual;
          return item;
        });
        ids = ids.substring(0, ids.length - 1);
        this.fs.actualizarMovimientos(ids, anterior, cargos, abonos, actual).subscribe((result) => {
          this.eventsService.publish('home:isLoading', { isLoading: false });
        });
      }
    }
  }

  seleccionar(tipo: number) {
    this.seleccionado = tipo;
    this.myForm.controls["Anterior"].setValue(0);
    this.myForm.controls["Cargos"].setValue(0)
    this.myForm.controls["Abonos"].setValue(0);
    this.myForm.controls["Actual"].setValue(0);
    if (tipo == 1) {
      const m: MovimientoConciliacion = this.listaAnteriores[0];
      this.myForm.controls["Anterior"].setValue(m.saldoAnterior);
      this.myForm.controls["Cargos"].setValue(m.sumaCargos);
      this.myForm.controls["Abonos"].setValue(m.sumaAbonos);
      this.myForm.controls["Actual"].setValue(m.saldoActual);
    }

    if (tipo == 2) {
      const m: MovimientoConciliacion = this.listaNosotros[0];
      this.myForm.controls["Anterior"].setValue(m.saldoAnterior);
      this.myForm.controls["Cargos"].setValue(m.sumaCargos);
      this.myForm.controls["Abonos"].setValue(m.sumaAbonos);
      this.myForm.controls["Actual"].setValue(m.saldoActual);
    }

    if (tipo == 3) {
      const m: MovimientoConciliacion = this.listaEllos[0];
      this.myForm.controls["Anterior"].setValue(m.saldoAnterior);
      this.myForm.controls["Cargos"].setValue(m.sumaCargos);
      this.myForm.controls["Abonos"].setValue(m.sumaAbonos);
      this.myForm.controls["Actual"].setValue(m.saldoActual);
    }

  }

  importarContabilidad() {
    this.myForm.controls["Anterior"].setValue(0);
    this.myForm.controls["Cargos"].setValue(0)
    this.myForm.controls["Abonos"].setValue(0);
    this.myForm.controls["Actual"].setValue(0);
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.fs.obtenerMovimientosContabilidad(2).subscribe((lista) => {
      this.listaNosotros = lista;
      this.fs.obtenerMovimientosContabilidad(3).subscribe((listaEllos) => {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        this.listaEllos = listaEllos;
        if (this.seleccionado == 3) {
          if (this.listaEllos.length > 0) {
            const m: MovimientoConciliacion = this.listaEllos[0];
            this.myForm.controls["Anterior"].setValue(m.saldoAnterior);
            this.myForm.controls["Cargos"].setValue(m.sumaCargos);
            this.myForm.controls["Abonos"].setValue(m.sumaAbonos);
            this.myForm.controls["Actual"].setValue(m.saldoActual);
          }
        } else if (this.seleccionado == 2) {
          if (this.listaNosotros.length > 0) {
            const m: MovimientoConciliacion = this.listaNosotros[0];
            this.myForm.controls["Anterior"].setValue(m.saldoAnterior);
            this.myForm.controls["Cargos"].setValue(m.sumaCargos);
            this.myForm.controls["Abonos"].setValue(m.sumaAbonos);
            this.myForm.controls["Actual"].setValue(m.saldoActual);
          }
        }
      });
    });
  }

  abrirManual() {
    this.tabsNavService.closeCurrentTab();
    this.eventsService.publish('home:openPage', 86302);
  }

  eliminarConciliacion() {
    if (this.listaEllos.length > 0) {
      this.eventsService.publish('home:showAlert', {
        message: 'Se eliminarán los movimientos del periodo y se desconciliaran, ¿desea continuar?',
        onConfirm: (r: AlertResponse) => {
          if (r.isAccept) {
            this.eventsService.publish('home:isLoading', { isLoading: true });
            this.fs.eliminarConciliacion().subscribe((result) => {
              this.importarContabilidad();
            })
          }
        }
      });
    } else {
      this.eventsService.publish('home:showAlert', { message: `No hay registros para borrar.`, cancelButton: false });
    }
  }

  get getConciliarCargosNosotros(): number {
    let t = 0;
    this.listaNosotros.forEach((item) => {
      if (!item.conciliado) {
        t += item.importeCargo;
      }
    })
    return t;
  }

  get getConciliarAbonosNosotros(): number {
    let t = 0;
    this.listaNosotros.forEach((item) => {
      if (!item.conciliado) {
        t += item.importeAbono;
      }
    })
    return t;
  }

  get getConciliarCargosEllos(): number {
    let t = 0;
    this.listaEllos.forEach((item) => {
      if (!item.conciliado) {
        t += item.importeCargo;
      }
    })
    return t;
  }

  get getConciliarAbonosEllos(): number {
    let t = 0;
    this.listaEllos.forEach((item) => {
      if (!item.conciliado) {
        t += item.importeAbono;
      }
    })
    return t;
  }


  sendImportData(file: string) {
    this.myForm.controls["Anterior"].setValue(0);
    this.myForm.controls["Cargos"].setValue(0)
    this.myForm.controls["Abonos"].setValue(0);
    this.myForm.controls["Actual"].setValue(0);
    this.fs.sendConciliacionImportacion(file).subscribe((result) => {
      if (result.success) {
        Swal.fire({ position: 'center', icon: 'success', title: 'Se subió el archivo y se concilió de forma automática, verifique los movimientos', showConfirmButton: false, timer: 3000 }).then(() => {
          this.importarContabilidad();
        });
      } else {
        this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
      }
    })

  }

  downloadFileExample() {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.fs.decargarEjemploConciliacion().subscribe((d: any) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (d) {
        let blob = new Blob([d], { type: "text/csv" });
        let url = window.URL.createObjectURL(blob);
        let pwa = window.open(url);
        if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
          this.eventsService.publish('home:showAlert', { message: "Tienes bloqueadas las descargas de esta página, habilitalas.", cancelButton: false });
        }
      }

    });

  }

  importarArchivo() {
    if (this.listaEllos.length > 0) {
      this.eventsService.publish('home:showAlert', {
        message: 'Se eliminarán los movimientos del periodo y se desconciliaran, ¿desea continuar?',
        onConfirm: (r: AlertResponse) => {
          if (r.isAccept) {
            this.txtFile.nativeElement.click();
          }
        }
      });
    } else {
      this.txtFile.nativeElement.click();
    }
  }

  onchange(event: any) {
    const file = event.target.files.length > 0 ? event.target.files[0] : null
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.fuse.upload(file, 0, 'Utilerias/UploadFile', () => {
      this.sendImportData(file.name);
    });

  }

  enviarMensajeError() {
    this.eventsService.publish('home:showAlert', {
      message: "No se encontró configuración para poder iniciar el proceso de conciliación, de clic en aceptar para configurar.", cancelButton: false, onConfirm: (data: AlertResponse) => {
        this.tabsNavService.closeCurrentTab();
        this.eventsService.publish('home:openPage', 86100);
      }
    });
  }

  filtros: ReportFilter = {} as ReportFilter;
  imprimir() {
    let seleccionados = "";
    let fecha1 = new Date();
    let fecha2 = new Date();
    if (this.seleccionado === 2) {
      seleccionados = this.listaNosotros.map(x => x.id).toString();
      let listaNosotros = orderBy(this.listaNosotros, ["fecha"], ["asc"]);
      fecha1 = listaNosotros[0].fecha;
      fecha2 = listaNosotros[listaNosotros.length - 1].fecha;

    } else if (this.seleccionado === 3) {
      seleccionados = this.listaEllos.map(x => x.id).toString();
      let listaEllos = orderBy(this.listaEllos, ["fecha"], ["asc"]);
      fecha1 = listaEllos[0].fecha;
      fecha2 = listaEllos[listaEllos.length - 1].fecha;
    }

    let nombreReporte = "Estado de cuenta " + (this.seleccionado === 2 ? "Nosotros" : (this.seleccionado === 3 ? "Ellos" : "Anteriores"));
    this.filtros = {
      ReportHeader: {
        NombreReporte: seleccionados,
        Num1: this.seleccionado,
        Fecha1: fecha1,
        Fecha2: fecha2
      } as ReportHeader,
      TituloVisor: nombreReporte,
      Fecha1: this.myForm.value.fechaInicioString,
      Fecha2: this.myForm.value.fechaFinString,
      NombreExcel: nombreReporte + ".xlsx",
      FilterOptions: []
    }
    this.reportsService.printReport(this.filtros, '/Tesoreria/ImprimirMovimientosContabilidad');
  }
}
