<app-container-base [getLast]="false" (onDelete)="onDelete()" [activeButtons]="activeButtons" [entity]="myForm"
  (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)" entityName="ActivoFijo"
  title="Cuentas de Activos Fijos" icon="fa fa-folder-tree" subTitle="Administra las Cuentas de Activos Fijos"
  (onClickBarButton)="imprimir($event)" [printManual]="true">
  <div class="screen-content">
    <span *ngIf="saving" class="loaderfact"><i class="fas fa-spinner fa-spin spin"></i></span>
    <div style="height: 41px;padding: 2px;">

      <div style="margin-left: 5px;" *ngIf="showAddFamily" (click)="addLevel()" class="btn btn-primary"><i
          class="fa fa-lg me-1 fa-plus"></i>
        Agregar Cuenta Activo [raiz]</div>

      <div style="margin-left: 5px;" (click)="addSubLevel()" *ngIf="showAddSubFam" class="btn btn-primary"><i
          class="fa fa-lg me-1 fa-plus"></i>
        Agregar Sub Cuenta a <span>[{{selectedTree.nombre | uppercase}}]</span></div>
    </div>
    <form [formGroup]="myForm" autocomplete="off">
      <div class="d-flex" style="height: 100%;">

        <components-ui-tree-view (onLoad)="onLoad()" [setIsNew]="getReload" (onSelectedItem)="onSelectedItem($event)"
          [type]="'ActivoFijo'" />

        <div style="width: 70%;padding-left: 5px;">
          <app-panel-base title="Información general" style="height: 450px;" *ngIf="this.myForm.value.clave>0">
            <div class="panel-content">
              <components-ui-number-box [tabIndex]="1" [label]="'Clave'" formControlName="clave" [widthTextColumns]="1"
                [widthLabelColumns]="3" [maxLength]="longClave" [enabled]="myForm.value.id==0" [required]="true" />

              <components-ui-text-box [tabIndex]="2" [label]="'Nombre'" formControlName="nombre" [widthTextColumns]="4"
                [widthLabelColumns]="3" [required]="true" #txtNombre />

              <components-ui-text-box [maxLength]="50" [tabIndex]="3" [label]="'Abreviación'"
                formControlName="abreviacion" [widthTextColumns]="4" [widthLabelColumns]="3" />

              <component-ui-check-box [tabIndex]="4" [widthLabelColumns]="3" formControlName="baja"
                [label]="'¿Dar de baja?'" />

              <ul class="nav nav-tabs mt-3 bg-light" role="tablist">
                <li class="nav-item" role="presentation"><a href="#default-tab-1" data-bs-toggle="tab"
                    class="nav-link text-dark active" aria-selected="true" role="tab"><span class="d-sm-block d-none">Generales
                      del activo</span></a></li>
                <li class="nav-item" role="presentation"><a href="#default-tab-2" data-bs-toggle="tab" class="nav-link text-dark"
                    aria-selected="false" role="tab" tabindex="-1"><span class="d-sm-block d-none">Adicional</span></a>
                </li>
                <li *ngIf="myForm.value.numeroNivel<=1" class="nav-item" role="presentation"><a href="#default-tab-3" data-bs-toggle="tab" class="nav-link text-dark"
                    aria-selected="false" role="tab" tabindex="-1"><span
                      class="d-sm-block d-none">Contabilidad</span></a></li>
                <!-- <li class="nav-item" role="presentation"><a href="#default-tab-4" data-bs-toggle="tab" class="nav-link"
                    aria-selected="false" role="tab" tabindex="-1"><span class="d-sm-block d-none">Documentos</span></a>
                </li> -->
              </ul>
              <div class="tab-content panel rounded-0 p-3 m-0">
                <div id="default-tab-1" class="tab-pane fade active show" role="tabpanel">
                  <blockquote class="blockquote">
                    <p>Indique toda la información del activo fijo para su correcto cálculo.</p>
                  </blockquote>
                  <figcaption class="blockquote-footer">Esta información solo se asigna en el último nivel del Activo
                    Fijo que corresponde al activo directamente.</figcaption>
                  <div class="d-flex" style="flex-direction: row;">
                    <div style="width: 280px;">
                      <components-ui-date-box label="Calcular al" [tabIndex]="5" formControlName="calcularAl"
                        [widthLabelColumns]="5" [widthTextColumns]="6" />
                      <components-ui-date-box label="Adquirida el" [tabIndex]="6" formControlName="fechaAdquisicion"
                        [widthLabelColumns]="5" [widthTextColumns]="6" />
                      <components-ui-date-box label="Dada de baja el" [tabIndex]="7" formControlName="fechaBaja"
                        [widthLabelColumns]="5" [widthTextColumns]="6" />
                    </div>
                    <div style="width: 160px;padding-top: 25px;">
                      <components-ui-text-box [maxLength]="20" [tabIndex]="8" [label]="'Póliza'"
                        formControlName="polizaAdquisicion" [widthTextColumns]="7" [widthLabelColumns]="5" />
                      <components-ui-text-box [maxLength]="20" [tabIndex]="9" [label]="'Póliza'"
                        formControlName="polizaBaja" [widthTextColumns]="7" [widthLabelColumns]="5" />
                    </div>
                    <div style="width: 25%;padding-top: 25px;">
                      <components-ui-date-box label="Fecha póliza" [tabIndex]="10"
                        formControlName="fechaPolizaAdquisicion" [widthLabelColumns]="5" [widthTextColumns]="6" />
                      <components-ui-date-box label="Fecha póliza" [tabIndex]="11" formControlName="fechaPolizaBaja"
                        [widthLabelColumns]="5" [widthTextColumns]="6" />
                    </div>
                  </div>
                  <hr>
                  <div class="d-flex" style="flex-direction: row;">
                    <div style="width: 280px;">
                      <components-ui-number-box [maxLength]="20" [tabIndex]="12" [label]="'Importe deducible'"
                        formControlName="valorDeducible" [widthTextColumns]="4" [widthLabelColumns]="7" />

                      <components-ui-number-box [maxLength]="20" [tabIndex]="13" [label]="'Importe no deducible'"
                        formControlName="valorNoDeducible" [widthTextColumns]="4" [widthLabelColumns]="7" />

                      <components-ui-number-box [maxLength]="3" [tabIndex]="14" [label]="'% anual depreciable'"
                        formControlName="porcentajeAnualDepreciacion" [widthTextColumns]="4" [widthLabelColumns]="7" />

                      <components-ui-number-box [maxLength]="2" [tabIndex]="15" [label]="'Años de vida útil'"
                        formControlName="aniosVidaUtil" [widthTextColumns]="4" [widthLabelColumns]="7" />
                    </div>
                    <div style="width:  280px;">
                      <components-ui-number-box [maxLength]="20" [tabIndex]="16" [label]="'Valor total'"
                        formControlName="valorTotal" [widthTextColumns]="4" [widthLabelColumns]="7" />

                      <components-ui-number-box [maxLength]="20" [tabIndex]="17" [label]="'Deducir inmediato'"
                        formControlName="deduccionInmediata" [widthTextColumns]="4" [widthLabelColumns]="7" />

                      <components-ui-number-box [maxLength]="20" [tabIndex]="18" [label]="'Valor de reposición'"
                        formControlName="valorReposicion" [widthTextColumns]="4" [widthLabelColumns]="7" />

                      <components-ui-number-box [maxLength]="20" [tabIndex]="19" [label]="'Acumulado'"
                        formControlName="depreciacionAcumuladaAnioAnterior" [widthTextColumns]="4"
                        [widthLabelColumns]="7" />
                    </div>
                  </div>
                </div>
                <div id="default-tab-2" class="tab-pane fade" role="tabpanel">
                  <blockquote class="blockquote">
                    <p>Indique la información adicional para dar seguimiento</p>
                  </blockquote>
                  <figcaption class="blockquote-footer">Esta información solo se asigna en el último nivel del Activo
                    Fijo que corresponde al activo directamente.</figcaption>

                  <components-ui-text-box [maxLength]="20" [tabIndex]="20" [label]="'Autoriza'"
                    formControlName="autoriza" [widthTextColumns]="7" [widthLabelColumns]="2" />

                  <components-ui-text-box [maxLength]="20" [tabIndex]="21" [label]="'Factura'" formControlName="factura"
                    [widthTextColumns]="7" [widthLabelColumns]="2" />

                  <components-ui-text-box [maxLength]="20" [tabIndex]="22" [label]="'Proveedor'"
                    formControlName="proveedor" [widthTextColumns]="7" [widthLabelColumns]="2" />

                  <components-ui-text-box [maxLength]="20" [tabIndex]="23" [label]="'Área de la empresa'"
                    formControlName="area" [widthTextColumns]="7" [widthLabelColumns]="2" />

                </div>
                <div *ngIf="myForm.value.numeroNivel<=1" id="default-tab-3" class="tab-pane fade" role="tabpanel">
                  <blockquote class="blockquote">
                    <p>Indique las Cuentas Contables de Cargo y Abono cuando se calcúle la depreciación.</p>
                  </blockquote>
                  <figcaption class="blockquote-footer">Esta información solo se asigna en el Primer nivel del Activo
                    Fijo</figcaption>
                  <div class="d-flex">
                    <div class="row" style="width:50%">
                      <div class="col-md-8" style="padding-left:0px;">
                        <div class="card bg-success border-0 text-white">
                          <div class="card-body">
                            <h4 class="card-title">Cuenta Cargo Gastos</h4>
                            <p class="card-text">{{getChargeName}}</p>
                            <div (click)="abrirSelecionarCuenta('cuentaCargo')" class="btn btn-sm btn-primary">
                              Seleccionar
                              Cuenta de Cargo</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row" style="width:50%">
                      <div class="col-md-8" style="padding-left:0px;">
                        <div class="card bg-success border-0 text-white">
                          <div class="card-body">
                            <h4 class="card-title">Cuenta Abono Depreciación</h4>
                            <p class="card-text">{{getAbbName}}</p>
                            <div (click)="abrirSelecionarCuenta('cuentaAbono')" class="btn btn-sm btn-primary">
                              Seleccionar
                              Cuenta de Abono</div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>


                </div>
                <!-- <div id="default-tab-4" class="tab-pane fade" role="tabpanel">
                  <blockquote class="blockquote">
                    <p>Seleccione los documentos relacionados al Activo Fijo.</p>
                  </blockquote>
                  <figcaption class="blockquote-footer">Esta información solo se asigna en el último nivel del Activo Fijo que corresponde al activo directamente.</figcaption>
                </div> -->
              </div>


            </div>
          </app-panel-base>

        </div>
      </div>
    </form>
  </div>
  <ng-template let-modal let-c="close" #seleccionarCuenta>
    <app-seleccionar-tree [type]="'CuentaContable'" #seleccionarCuenta />
  </ng-template>
</app-container-base>
