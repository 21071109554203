import { Component, inject, Input, OnInit } from '@angular/core';
import { ModalService } from 'src/app/service/modal.service';
import { AnticipoDisponibleDTO } from '../../interfaces/venta.interface';
import { GuiCellEdit } from '@generic-ui/ngx-grid';
import { FacturacionService } from '../../services/facturacion.service';
import * as moment from 'moment';
import { EventsService } from 'src/app/service/events.service';
import { UtileriasComplitPageComponent } from '../../../sistema/pages/utilerias-complit-page/utilerias-complit-page.component';

@Component({
  selector: 'app-anticipos-disponibles-page',
  templateUrl: './anticipos-disponibles-page.component.html',
  styles: [
  ]
})

export class AnticiposDisponiblesPageComponent implements OnInit {
  private eventsService = inject(EventsService);
  @Input()
  public idCliente: number = 0;
  @Input()
  public idEmpresa: number = 0;
  @Input()
  public totalAplicar: number = 0;


  searching = true;
  source: any[] = [];
  indexEditing: number = -1;

  public constructor(private mService: ModalService, private fService: FacturacionService) {

  }

  ngOnInit(): void {
    this.fService.getBalance(this.idCliente, this.idEmpresa).subscribe((lista) => {
      this.searching = false;
      this.source = lista;
    })
  }

  mark(index: number) {
    const limite = this.getDiferencia;
    const elem = this.source[index];
    elem.seleccionado = !elem.seleccionado;
    if (elem.seleccionado) {
      if (elem.disponible <= limite) {
        elem.utilizar = elem.disponible;
        elem.disponible = 0;
      } else {
        elem.utilizar = limite;
        let dispAntes = elem.disponible;
        elem.disponible = dispAntes - elem.utilizar;
      }
    } else {
      elem.disponible += elem.utilizar;
      elem.utilizar = 0;
    }
    if (elem.utilizar == 0) {
      elem.seleccionado = false;
    }
    this.source = [...this.source];
  }

  get getTotalUsado(): number {
    let usado = 0;
    this.source.forEach(element => { usado += element.utilizar; });
    return usado;
  }

  get getDiferencia(): number {
    return Number(this.totalAplicar - this.getTotalUsado);
  }

  get getTotalAplicar(): number {
    return 0;
  }

  getDate(date: any): string {
    return moment(date).format("DD/MM/YY");
  }

  accept() {
    let utilizar: any[] = this.source.filter(P => P.utilizar > 0);
    if (utilizar.length == 0) {
      this.eventsService.publish('home:showAlert', { icon: ' fa-triangle-exclamation text-yellow', message: "No ha seleccionado ninguna Factura a utilizar.", cancelButton: false });
      return;
    }
    this.mService.closeModal(utilizar);
  }
  closeModal() {
    this.mService.closeModal(null);
  }

  cellEditing: GuiCellEdit = {
    enabled: true,
    rowEdit: (value: any, item: AnticipoDisponibleDTO, index: number) => {
      this.indexEditing = -1;
      return true;
    },

    cellEdit: (value: any, item: any, index: number) => {
      return true;
    }
  }

  cellEditEntered(e: any) {

  }

  cellEditSubmitted() {


  }

  sourceEdited(e: any) {
  }

}
