import { Component, ElementRef, inject, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';
import { EventsService } from 'src/app/service/events.service';
import { ModalService } from 'src/app/service/modal.service';
import { CorteCajaEfectivoEntregado } from '../../interfaces/cortecaja.interface';
import * as _ from 'lodash';

@Component({
  selector: 'retiros-caja',
  templateUrl: './retiros-caja.component.html',
  styleUrls: ['./retiros-caja.component.scss']
})
export class RetirosCajaComponent {


  @Input()
  retiros: CorteCajaEfectivoEntregado[] = [];
  retiro: CorteCajaEfectivoEntregado = {} as CorteCajaEfectivoEntregado;

  retirosClone: CorteCajaEfectivoEntregado[] = [];

  @ViewChild('modalAgregarRetiroCaja')
  public modalAgregarRetiroCaja!: ElementRef<HTMLElement>;

  private modalService = inject(ModalService);
  private eventsService = inject(EventsService);
  private fb = inject(FormBuilder);
  sumaImportes: number = 0;


  ngOnInit() {
    this.retirosClone = _.cloneDeep(this.retiros);
    this.sumarImportes();
  }

  sumarImportes() {
    this.sumaImportes = _.sumBy(this.retirosClone, "Importe");
  }

  public agregarRetiroCajaFrm: FormGroup = this.fb.group({
    Id: [0],
    Importe: [0, [Validators.required]],
    Tipo: [0, [Validators.required]],
    Banco: [0, [Validators.required]],
    CuentaBancaria: [0, [Validators.required]],
    Referencia: ["", [Validators.required]],
  })

  agregar() {
    this.modalService.openModal(this.modalAgregarRetiroCaja, () => { });
  }

  closeAgregarRetiro() {
    this.modalService.closeModal(null);
    this.agregarRetiroCajaFrm.reset();
  }

  closeListaRetiros(accept: boolean = true) {
    this.modalService.closeModal(accept ? this.retirosClone : this.retiros);
  }

  onSelectedItem(entity: any, tipo: string) {
    switch (tipo) {
      case "TipoRetiro":
        this.retiro = {
          ...this.retiro,
          Tipo: entity
        }
        break
      case "Banco":
        this.retiro = {
          ...this.retiro,
          Banco: entity
        }
        break
      case "CuentaBancaria":
        this.retiro = {
          ...this.retiro,
          CuentaBancaria: entity
        }
        break
      default:
        break;
    }
  }

  accept(isEnterInput: boolean = false, event: any = null) {
    let importe = this.agregarRetiroCajaFrm.controls['Importe'].value;
    let referencia = isEnterInput ? event.target.value : this.agregarRetiroCajaFrm.controls['Referencia'].value;
    if ((!importe || !referencia) || !this.retiro || (!this.retiro!.Tipo && !this.retiro!.Banco || !this.retiro!.CuentaBancaria)) {
      this.eventsService.publish('home:showAlert', { message: "Debe indicar todos los campos indicados con asterisco en rojo.", cancelButton: false });
      return;
    }
    this.retiro = {
      ...this.retiro,
      Importe: importe,
      Referencia: referencia
    }
    if (this.retiro!.Id) {
      this.retirosClone = this.retirosClone.map((r: any) => {
        if (r.Id === this.retiro.Id) {
          return this.retiro;
        } return r;
      })
    } else {
      this.retiro = {
        ...this.retiro,
        Id: 0,
      }
      this.retirosClone.push(this.retiro);
    }
    this.closeAgregarRetiro();
    this.retiro = {} as CorteCajaEfectivoEntregado;
    this.sumarImportes();
  }

  deleteRetiro(retiro: CorteCajaEfectivoEntregado) {
    this.eventsService.publish('home:showAlert', {
      message: '¿Desea eliminar el renglón?',
      onConfirm: (r: AlertResponse) => {
        if (r.isAccept) {
          this.retirosClone = this.retirosClone.filter((r: any) => r.Id !== retiro.Id);
          this.sumarImportes();
        }
      }
    });
  }

  editRetiro(retiro: CorteCajaEfectivoEntregado, index: number) {
    this.modalService.openModal(this.modalAgregarRetiroCaja, () => {
    });
    setTimeout(() => {
      this.agregarRetiroCajaFrm.reset(retiro);
      this.retiro = retiro;
    }, 0);
  }
}
