<app-container-base [hasCounter]="false" icon="fa fa-money-bill-1" [hasBar]="true" [activeButtons]="activeButtons"
  (onClickBarButton)="clickBoton($event)" subTitle="Estado de cuenta por proveedor">
  <div class="screen-content" style="width: 1045px;">
    <form [formGroup]="myForm" autocomplete="off">
      <div class="row">
        <div class="col-md-8">
          <app-panel-base title="Filtos del estado de cuenta del proveedor">
            <div class="panel-content">
              <div class="row">
                <div class="col-md-10">
                  <component-ui-combo-box [label]="'Cuenta a revisar'" [tabIndex]="1" [zeroMask]="2"
                    entityName="CuentaCxp" formControlName="cuenta" (onSelectedItem)="selectedCombo($event,'cuenta')"
                    [widthTextColumns]="3" [required]="true" [widthLabelColumns]="3" [widthRightColumns]="5"
                    [listProperty]="'ClaveNombre'" #cuenta />
                  </div>
              </div>

              <div class="d-flex mt-1">
                <div style="width:33%;">
                  <components-ui-text-box [tabIndex]="2" [placeHolder]="'F2: buscar'" label="Proveedor"
                    [enableIcon]="true" formControlName="proveedorClave" [widthLabelColumns]="7"
                     (onClicIcon)="buscarProveedor()" [widthTextColumns]="5" entidadBusqueda="Proveedor"
                    (blur)="buscarProveedorPorClave()" (elementoSeleccionado)="elementoSeleccionado($event)" #txtProveedor />

                    <!-- <components-ui-text-box (blur)="blurProv($event)"
                          (onClickIcon)="clicIcon($event)" label="Proveedor" [widthLabelColumns]="8" [tabIndex]="4"
                          (keydown.enter)="enterProv()" [widthTextColumns]="4"
                          entidadBusqueda="Proveedor" (elementoSeleccionado)="elementoSeleccionado($event)"
                          #txtProveedor /> -->

                </div>
                <div *ngIf="myForm.value.proveedor?.Id>0">
                  <div class="fs-13px lh-12 mb-2px fw-bold text-dark">[{{this.myForm.value.proveedor.Rfc |
                    uppercase}}]
                    {{ this.myForm.value.proveedor.Nombre | uppercase}}
                  </div>
                </div>
              </div>
              <div class="col-md-10">
              <component-ui-combo-box label='Año' [tabIndex]="2" [zeroMask]="2"
                [selectManualItem]="selectAnioManualItem" [setItemsList]="this.listaAnios" [widthTextColumns]="3"
                [widthLabelColumns]="3" (onSelectedItem)="selectedCombo($event,'anio')" [inputProperty]="'Nombre'"
                [listProperty]="'Nombre'" />
              </div>
            </div>
          </app-panel-base>
          <div class="panel-content">
            <table class="table table-bordered table-sm">
              <thead>
                <tr class="header">
                  <th class="text-left w-150px">Mes</th>
                  <th class="text-left w-150px">Anterior</th>
                  <th class="text-left w-150px">Cargos</th>
                  <th class="text-left w-150px">Abonos</th>
                  <th class="text-left w-150px">Actual</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of listaMeses" class="table-info">
                  <td class="text-left">{{item.mes}}</td>
                  <td class="text-right">{{item.anterior |number:'1.2-6'}}</td>
                  <td class="text-right">{{item.cargos |number:'1.2-6'}}</td>
                  <td class="text-right">{{item.abonos |number:'1.2-6'}}</td>
                  <td class="text-right">{{item.actual|number:'1.2-6'}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <ng-template #content let-modal let-c="close">
        <app-data-search titulo="Búsqueda de Proveedores" [columns]="getColumns" [entityName]="'Proveedor'"
          [filter]="getFilter" [propertys]="getPropertys" [orders]="'Nombre|asc'" #busqueda />
      </ng-template>
      <ng-template #reporteEdoCuenta let-modal let-c="close">
        <reporte-estado-de-cuenta-cxp [idProveedor]="this.proveedorSeleccionado" [anio]="this.anioSeleccionado!"
          [idCuenta]=" this.myForm.value.cuenta.Id" />
      </ng-template>
    </form>
  </div>
</app-container-base>
