<div (keydown)="keydown($event)" class="card text-center border-0" cdkDrag
  style="width: 600px;box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;">
  <div style="cursor: move;" cdkDragHandle class="card-header fw-bold">Agregar Producto a Diseño de Producción
  </div>
  <div class="">
    <app-container-base subTitle="" [containerInnerHeight]="0" [esPopup]="true" [hasHeader]="false" [hasBar]="false"
      [hasCounter]="false">
      <div class="screen-content">
        <app-panel-base title="Componente del diseño">
          <div class="panel-content">
            <form [formGroup]="myForm" autocomplete="off">
              <div class="d-flex">
                <div style="width:315px;margin-right: -35px;">
                  <components-ui-text-box (keydown.escape)="closeModal()" [required]="true"
                    (blur)="blurProducto($event)" [tabIndex]="1" [maxLength]="15" (onClicIcon)="openProdSearch()"
                    (keydown.f2)="openProdSearch()" [enableIcon]="true" [label]="'Producto'" [widthTextColumns]="5"
                    [widthLabelColumns]="6" #txtProducto />
                </div>
                <span style="height: 22px; padding: 7px; width: 150x;"
                  class="badge bg-info rounded-0">{{getNombreProducto |
                  uppercase}}</span>
              </div>
              <components-ui-number-box formControlName="Cantidad" [tabIndex]="2" [required]="true" [maxLength]="15"
                [label]="'Cantidad'" [widthTextColumns]="2" [widthLabelColumns]="4" #txtUnidades />

              <component-ui-combo-box [required]="true" [label]="'Almacén'" [widthList]="300" formControlName="Almacen"
                [tabIndex]="3" [entityName]="'Almacen'" [widthTextColumns]="2" [widthLabelColumns]="4"
                [widthRightColumns]="6" (onSelectedItem)="selectedCombo($event,'Almacen')"
                [listProperty]="'ClaveNombre'" />

              <component-ui-combo-box filterQuery="EsSalida = true" [required]="true" [label]="'Concepto Salida'"
                [widthList]="300" formControlName="ConceptoSalida" [tabIndex]="4" [entityName]="'ConceptoAlmacen'"
                [widthTextColumns]="2" [widthLabelColumns]="4" [widthRightColumns]="6"
                (onSelectedItem)="selectedCombo($event,'ConceptoSalida')" [listProperty]="'ClaveNombre'" />
            </form>
          </div>
        </app-panel-base>
      </div>
    </app-container-base>


    <ng-template #busquedaProductos let-modal let-c="close">
      <app-data-search titulo="Búsqueda de Producto" [columns]="getProductsColumns" [entityName]="'Producto'"
        [filter]="getFilter" [propertys]="getProductsPropertys" [orders]="'Nombre|asc'" #busquedaProductos />
    </ng-template>

    <ng-template #busquedaAlmacen let-modal let-c="close">
      <app-data-search titulo="Búsqueda de Almacenes" [columns]="getAlmacenColumns" [entityName]="'Almacen'"
        [filter]="getAlmacenFilter" [propertys]="getAlmacenPropertys" [orders]="'Nombre|asc'" #busquedaAlmacen />
    </ng-template>

    <ng-template #busquedaConceptoAlmacen let-modal let-c="close">
      <app-data-search titulo="Búsqueda de Conceptos de salida de Almacen" [columns]="getConceptoAlmacenColumns"
        [entityName]="'ConceptoAlmacen'" [filter]="getConceptoAlmacenFilter" [propertys]="getConceptoAlmacenPropertys"
        [orders]="'Nombre|asc'" #busquedaConceptoAlmacen />
    </ng-template>
  </div>
  <div class="card-footer fw-bold">
    <div style="text-align: right; margin-top: 5px;">
      <div style="margin-right: 5px;" (click)="accept()" class="btn btn-success" #txtAceptar>Aceptar
      </div>
      <div (click)="closeModal()" class="btn btn-danger" #txtCancelar>Cancelar</div>
    </div>
  </div>
</div>
