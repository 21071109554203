<app-container-base [printManual]="true" (onClickBarButton)="clickButtonBar($event)"
  (onItemsComboSearched)="onItemsComboSearched($event)" (onItemSearched)="onItemSearched($event)"
  (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)" [entity]="myForm" entityName="Diseno"
  title="Diseños de Producción" icon="fa fa-building" subTitle="Administra los diferentes diseños de Producción.">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Información">
        <div class="panel-content">
          <component-ui-combo-box (onNewCatalog)="onNewCatalog($event)" (onChangueEntity)="onChangueEntity($event)"
            [widthList]="700" [isCatalog]="true" formControlName="Id" [isNewItem]="getIsNew" [setItems]="getItemsCombo"
            [label]="'Diseño'" entityName="Diseno" [tabIndex]="1" [listProperty]="'ClaveNombre'" [widthTextColumns]="2"
            [widthLabelColumns]="2" />
          <components-ui-text-box [label]="'Nombre'" [required]="true" formControlName="Nombre" [tabIndex]="2"
            [widthTextColumns]="5" [widthLabelColumns]="2" #txtNombre [maxLength]="50" />

          <component-ui-check-box (onClick)="clicBase($event)" [label]="'Diseño Base'" formControlName="EsBase"
            [tabIndex]="3" [widthTextColumns]="3" [widthLabelColumns]="2" />

          <component-ui-check-box (onClick)="clicEspecial($event)" [label]="'Diseño Especial'"
            formControlName="EsEspecial" [tabIndex]="4" [widthTextColumns]="3" [widthLabelColumns]="2" />

          <component-ui-check-box [label]="'¿Dado de baja?'" formControlName="Baja" [tabIndex]="5"
            [widthTextColumns]="3" [widthLabelColumns]="2" />

        </div>
      </app-panel-base>
      <app-panel-base title="Lista de Componentes del diseño">
        <div class="panel-content">
          <div class="pt-2 pb-2 text-start" style="width:200px;margin-left: 155px;">
            <a (click)="onDblClickRow(-1)" class="btn btn-primary btn-rounded px-4 rounded-pill"><i
                class="fa fa-plus fa-lg me-2 ms-n2"></i>Agregar</a>
          </div>
          <ng-scrollbar style="height: 300px;border-bottom: 1px solid #AAAAAA;width:1000px;">
            <table class="blueTable" style="width:1000px">
              <thead style="position: sticky;top:0;">
                <tr>
                  <th style="width: 50px;"> </th>
                  <th style="width: 50px;"> </th>
                  <th style="width: 250px;">Producto</th>
                  <th style="width: 70px;text-align: right;">Cantidad</th>
                  <th style="width: 100px;text-align: center">Unidad</th>
                  <th style="width: 200px;">Almacen</th>
                  <th style="width: 200px;">Concepto</th>
                </tr>
              </thead>
              <tbody class="bg-light">
                <tr [style]=" indexEditing == index ?  'background-color: #ffdbb7;': ''"
                  *ngFor="let item of listaCompuestos; let index = index;">
                  <td style="text-align: center;">
                    <i (click)="deleteRowCompuesto(index)" *ngIf="item.Producto" class="fa fa-rectangle-xmark fa-1x"
                      style="color:red;font-weight: bold; cursor: pointer;"></i>
                  </td>
                  <td style="text-align: center;">
                    <i (click)="onDblClickRow(index)" *ngIf="item.Producto" class="fa fa-pencil text-yellow fa-lg"
                      style="color:red;font-weight: bold; cursor: pointer;"></i>
                  </td>
                  <td>
                    <span style="margin-left: 3px;">{{item.Producto ?
                      (item.Producto.Clave + " => " + item.Producto.Nombre) : '' }}</span>
                  </td>
                  <td style="text-align:right;">
                    <span style="margin-left: 3px;">{{item.Cantidad > 0 ?
                      (item.Cantidad|number:'1.2-6') : '' }}</span>
                  </td>
                  <td style="text-align: center;">
                    <span style="margin-left: 3px;">{{item.Producto ?
                      (item.Producto.Unidad ? item.Producto.Unidad.Nombre : '') : '' }}</span>
                  </td>
                  <td>
                    <span style="margin-left: 3px;">{{item.Almacen ? (item.Almacen.Nombre) : '' }}</span>
                  </td>
                  <td>
                    <span style="margin-left: 3px;">{{item.ConceptoSalida ? (item.ConceptoSalida.Nombre) : '' }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </ng-scrollbar>
        </div>
        <ng-template #ctrlEdicion let-modal let-c="close">
          <ctrl-edicion-producto-compuesto-page *ngIf="DisenoActual" [item]="getSelectedCompuestoActual" />
        </ng-template>
      </app-panel-base>
    </form>
  </div>
</app-container-base>

<ng-template #modalImpresionDisenos let-modal let-c="close">
  <impresion-disenos></impresion-disenos>
</ng-template>
