import { Component, ElementRef, inject, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';
import { EventsService } from 'src/app/service/events.service';
import { ModalService } from 'src/app/service/modal.service';

@Component({
  selector: 'cheques',
  templateUrl: './cheques.component.html',
  styleUrls: ['./cheques.component.scss']
})
export class ChequesComponent {
  @ViewChild('modalAgregarCheque')
  public modalAgregarCheque!: ElementRef<HTMLElement>;

  private fb = inject(FormBuilder);
  private modalService = inject(ModalService);
  private eventsService = inject(EventsService);
  @Input()
  cheques: any[] = [];
  chequesClone: any[] = [];
  cheque: any = {} as any;


  public agregarChequeFrm: FormGroup = this.fb.group({
    Id: [0],
    Banco: [0, [Validators.required]],
    CuentaBancaria: [0, [Validators.required]],
    Fecha: [new Date()],
    ImporteAplicar: [''],
    ImporteOriginal: [''],
    NumeroCheque: [''],
    NumeroAutorizacion: [''],
    CuentaProteccion: [''],
    Referencia: ["", [Validators.required]],
  });

  ngOnInit() {
    this.chequesClone = _.cloneDeep(this.cheques);
    this.sumarImportes();
  }

  close() {
    this.modalService.closeModal(null);
  }

  agregar() {
    this.modalService.openModal(this.modalAgregarCheque, () => {
    });
  }

  sumaImportes: number = 0;
  sumarImportes() {
    this.sumaImportes = _.sumBy(this.chequesClone, "ImporteAplicar");
  }

  deleteCheque(terminal: any) {
    if (terminal.IdVenta > 0) {
      this.eventsService.publish('home:showAlert', {
        message: 'No es posible eliminar el cheque.',
      });
      return;
    }

    this.eventsService.publish('home:showAlert', {
      message: '¿Desea eliminar el renglón?',
      onConfirm: (r: AlertResponse) => {
        if (r.isAccept) {
          this.chequesClone = this.chequesClone.filter((r: any) => r.Id !== terminal.Id);
          this.sumarImportes();
        }
      }
    });
  }

  editCheque(cheque: any, index: number) {
    this.modalService.openModal(this.modalAgregarCheque, () => { });
    setTimeout(() => {
      this.agregarChequeFrm.reset(cheque);
      this.cheque = cheque;
    }, 0);
  }

  closeListaCheques(accept: boolean = true) {
    this.modalService.closeModal(accept ? this.chequesClone : this.cheques);
  }

  onSelectedItem(entity: any, tipo: string) {
    this.agregarChequeFrm.get(tipo)?.setValue(entity);
  }

  accept(isEnterInput: boolean = false, event: any = null) {
    this.cheque = this.agregarChequeFrm.value;
    if (!this.cheque || (!this.cheque.Banco || !this.cheque.CuentaBancaria || !this.cheque.Fecha || !this.cheque.ImporteAplicar || !this.cheque.ImporteOriginal || !this.cheque.NumeroCheque || !this.cheque.NumeroAutorizacion || !this.cheque.CuentaProteccion || !this.cheque.Referencia)) {
      this.eventsService.publish('home:showAlert', { message: "Debe indicar todos los campos indicados con asterisco en rojo.", cancelButton: false });
      return;
    }

    if (this.cheque!.Id) {
      this.chequesClone = this.chequesClone.map((r: any) => {
        if (r.Id === this.cheque.Id) {
          return this.cheque;
        } return r;
      })
    } else {
      this.cheque = {
        ...this.cheque,
        Id: 0,
      }
      this.chequesClone.push(this.cheque);
    }
    this.closeAgregarCheque();
    this.cheque = {};
    this.sumarImportes();
  }

  closeAgregarCheque() {
    this.modalService.closeModal(null);
    this.agregarChequeFrm.reset();
  }

  onChangeFechasCorte(event: any) {
    this.agregarChequeFrm.get("FechaDeposito")?.setValue(event);
  }
}
