import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { ReportFilter, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { EventsService } from 'src/app/service/events.service';
import { ReportsService } from 'src/app/service/reports.service';

@Component({
  selector: 'app-historico-facturas',
  templateUrl: './historico-facturas.component.html',
  styleUrls: ['./historico-facturas.component.scss']
})
export class HistoricoFacturasComponent {
  @ViewChild('txtNombreReporte')
  public txtNombreReporte!: ElementRef<TextBoxComponent>;
  private reportsService = inject(ReportsService);
  private eventsService = inject(EventsService);
  contenidoSeleccionado?: ComboBoxEntity;
  tipoSeleccionado?: ComboBoxEntity;
  activeButtons: ActiveButtons = {
    all: false,
    print: true,
  }

  private frmBuilder = inject(FormBuilder);

  public frmHistoricoFacturas: FormGroup = this.frmBuilder.group({
    NombreReporte: ["HISTÓRICO DE FACTURAS", [Validators.required]]
  });

  ngOnInit() {
    setTimeout(() => {
      this.focus()
    }, 300);
  }

  focus() {
    setTimeout(() => {
      const txt: any = this.txtNombreReporte;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  public filtrosAuditoriaCredito: ReportFilter =
    {
      menuId: 'historicofacturas',
      ReportHeader: {
        Fecha1: new Date(),
        Fecha2: new Date(),
        NombreReporte: 'Histórico de Facturas'
      } as ReportHeader,
      Fecha1: new Date(),
      Fecha2: new Date(),
      NombreReporte: 'Histórico de Facturas',
      TituloVisor: 'Histórico de Facturas',
      NombreExcel: 'Historico_de_Facturas.xlsx',
      FilterOptions: [
        { Campo: 'emp.Clave', Etiqueta: 'Empresa', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Empresa" },
        { Campo: 'suc.Clave', Etiqueta: 'Sucursal', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Sucursal" },
        { Campo: 'cliente.Clave', Etiqueta: 'Cliente', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Cliente" },
        { Campo: 'det.SerieOrigina', Etiqueta: 'Serie', Tipo: TypeFilter.text },
        { Campo: 'det.FolioOrigina', Etiqueta: 'Folio', Tipo: TypeFilter.number },
        { Campo: 'cxc.Clave', Etiqueta: 'Cuenta', Tipo: TypeFilter.text, BotonBusqueda: true, Entidad: "CuentaCxp"  },
      ]
    };

  imprimir(event: any) {
    if (this.filtrosAuditoriaCredito.FilterOptions![2].Valor || (this.filtrosAuditoriaCredito.FilterOptions![4].Valor)) {
      this.filtrosAuditoriaCredito.ReportHeader = this.frmHistoricoFacturas.value;
      this.reportsService.printReport(this.filtrosAuditoriaCredito, '/CuentasPorCobrar/ReporteHistoricoFacturas');
    } else {
      this.eventsService.publish('home:showAlert',
        {
          message: 'Debe indicar un Cliente o Folio para imprimir el reporte.',
          icon: 'fa-triangle-exclamation text-yellow', cancelButton: false
        });
    }
  }

  changeselec(event: any) {
    this.filtrosAuditoriaCredito = event;
  }

  selectedCombo(entity: any, tipo: string) {
    if (tipo === "contenido") {
      this.filtrosAuditoriaCredito.ReportHeader.Num1 = entity.Id;
    } else {
      this.filtrosAuditoriaCredito.ReportHeader.Num2 = entity.Id;
    }
  }

  get selectContenidoItem(): ComboBoxEntity | null {
    return this.contenidoSeleccionado!;
  }

  get selectTipoItem(): ComboBoxEntity | null {
    return this.tipoSeleccionado!;
  }
}
