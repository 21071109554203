<app-container-base (onSaveError)="onSaveError()" (onItemsComboSearched)="onItemsComboSearched($event)"
  (onItemsLoadFirstTime)="onItemsLoadFirstTime()" (onItemSearched)="onItemSearched($event)" [aplicarPermisos]="true"
  (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)" [entity]="myForm" entityName="Empresa"
  title="Empresas" icon="fa fa-building" subTitle="Administra la configuración de empresas...">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Información">
        <div class="panel-content">
          <component-ui-combo-box [zeroMask]="2" formControlName="Id" [isNewItem]="getIsNew"
            (onNewCatalog)="onNewCatalog($event)" (onChangueEntity)="onChangueEntity($event)" [setItems]="getItemsCombo"
            [isCatalog]="true" [label]="'Empresa'" entityName="Empresa" [tabIndex]="1" [listProperty]="'ClaveNombre'"
            [widthTextColumns]="1" [widthLabelColumns]="2" />
          <components-ui-text-box
            helpLine="Nombre de la empresa según la constancia de situación fiscal, 500 caracteres." [maxLength]="500"
            [upperCase]="true" [required]="true" [label]="'Nombre'" formControlName="Nombre" [tabIndex]="2"
            [widthTextColumns]="4" [widthLabelColumns]="2" #txtNombre />
          <components-ui-text-box
            helpLine="RFC de la empresa, tal cual está en la constancia de situación fiscal, 12-13 caracteres"
            [tabIndex]="3" [maxLength]="13" [upperCase]="true" [required]="true" [label]="'RFC'" [dataType]="EDT.RFC"
            formControlName="Rfc" [widthTextColumns]="2" [widthLabelColumns]="2" />
          <components-ui-text-box helpLine="Indique la CURP, se imprime en Estados Financieros" [maxLength]="18"
            [upperCase]="true" [label]="'CURP'" [dataType]="EDT.CURP" formControlName="Curp" [tabIndex]="4"
            [widthTextColumns]="2" [widthLabelColumns]="2" />
          <components-ui-number-box [enabled]="false" [maxLength]="6" [label]="'Usuarios Concurrentes'"
            formControlName="NumeroUsuarios" [widthTextColumns]="1" [widthLabelColumns]="2" />
          <component-ui-file-upload-box
            helpLine="Indique el logotipo de la empresa, saldrá impreso en los CFDIS emitidos."
            (onChange)="onChangeFile($event,3)" [text]="myForm.value.RutaLogo" [label]="'Logotipo'" [tabIndex]="5"
            [widthTextColumns]="4" [widthLabelColumns]="2" />

          <component-ui-check-box [label]="'¿Dado de baja?'" formControlName="Baja" [tabIndex]="6"
            [widthTextColumns]="3" [widthLabelColumns]="2" />
        </div>
      </app-panel-base>

      <ul class="nav nav-tabs m-2 bg-light" role="tablist">
        <li class="nav-item" role="presentation">
          <a href="#default-tab-1" data-bs-toggle="tab" class="nav-link text-dark active" aria-selected="true"
            role="tab">
            <span class="d-sm-block d-none">
              Dirección
            </span>
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a href="#default-tab-2" data-bs-toggle="tab" class="nav-link text-dark" aria-selected="false" role="tab">
            <span class="d-sm-block d-none">Fiscal</span>
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a href="#default-tab-3" data-bs-toggle="tab" class="nav-link text-dark" aria-selected="false" role="tab">
            <span class="d-sm-block d-none">Timbrado</span>
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a href="#default-tab-4" data-bs-toggle="tab" class="nav-link text-dark" aria-selected="false" role="tab">
            <span class="d-sm-block d-none">Descargas XMLS</span>
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a href="#default-tab-5" data-bs-toggle="tab" class="nav-link text-dark" aria-selected="false" role="tab">
            <span class="d-sm-block d-none">Contabilidad</span>
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a href="#default-tab-6" data-bs-toggle="tab" class="nav-link text-dark" aria-selected="false" role="tab">
            <span class="d-sm-block d-none">E-mail VENTAS</span>
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a href="#default-tab-7" data-bs-toggle="tab" class="nav-link text-dark" aria-selected="false" role="tab">
            <span class="d-sm-block d-none">E-mail COMPRAS</span>
          </a>
        </li>
      </ul>

      <div class="tab-content panel rounded-0 p-3 me-2 ms-2 mt-0">
        <div id="default-tab-1" class="tab-pane fade active show" role="tabpanel">
          <component-ui-address [collapsable]="true" [tabIndex]="21" formControlName="Direccion"></component-ui-address>
        </div>
        <div id="default-tab-2" class="tab-pane" role="tabpanel">
          <app-panel-base title="Fiscal">
            <div class="panel-content">

              <component-ui-combo-box
                helpLine="Régimen según la constancia de situación fiscal, se usa para timbrar los CFDI"
                [required]="true" [label]="'Régimen Fiscal'" [widthList]="300" formControlName="RegimenFiscal"
                [filterQuery]="getFiltro" [tabIndex]="7" [entityName]="'c_RegimenFiscal'" [widthTextColumns]="2"
                [widthLabelColumns]="2" [widthRightColumns]="8" (onSelectedItem)="selectedCombo($event,'RegimenFiscal')"
                [listProperty]="'ClaveNombre'" />

              <component-ui-combo-box helpLine="Indique el tipo de costeo que se usará para valiar el inventario"
                [required]="true" [label]="'Tipo Costeo'" [widthList]="300" formControlName="TipoCosteo" [tabIndex]="8"
                [entityName]="'TipoCosteo'" [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="8"
                (onSelectedItem)="selectedCombo($event,'TipoCosteo')" [listProperty]="'ClaveNombre'" />

              <component-ui-combo-box
                helpLine="Seleccione la empresa de donde se tomarán todos los catálogos, osea que compartirán los catálogos en general"
                [filterQuery]="getFilter" [label]="'Usar catálogo de'" [widthList]="300"
                formControlName="EmpresaCatalogos" [tabIndex]="9" [entityName]="'Empresa'" [widthTextColumns]="2"
                [widthLabelColumns]="2" [widthRightColumns]="8"
                (onSelectedItem)="selectedCombo($event,'EmpresaCatalogos')" [listProperty]="'ClaveNombre'" />

              <components-ui-text-box
                helpLine="Indique el nombre del Representante Legal, este aparecerá en los Estados Financieros, 150 caracteres"
                [maxLength]="150" [label]="'Representante legal'" formControlName="RepresentanteLegal" [tabIndex]="10"
                [widthTextColumns]="4" [widthLabelColumns]="2" />
              <component-ui-check-box
                helpLine="Seleccione si tiene activo el permiso para vender con el 50% de descuento en la tasa del IVA al 16%"
                [tabIndex]="11" [widthLabelColumns]="2" formControlName="AplicaDecretoFranjaFronteriza"
                [widthTextColumns]="7" [label]="'Decreto franja fronteriza'" />

              <component-ui-check-box
                helpLine="El sistema no permitirá cancelar CFDIS que pertenezcan a otro mes, solo los del mes en curso."
                [tabIndex]="12" [widthLabelColumns]="2" formControlName="CancelarSoloMesActual" [widthTextColumns]="7"
                [label]="'Solo cancelar mes y año en curso'" />

              <component-ui-check-box
                helpLine="Al capturar la Carta Porte, el sistema oculta campos que son para exportación" [tabIndex]="13"
                [widthLabelColumns]="2" formControlName="CartaPorteNacional" [widthTextColumns]="7"
                [label]="'Solo usar Carta Porte nacional'" />
            </div>

          </app-panel-base>
        </div>
        <div id="default-tab-3" class="tab-pane" role="tabpanel">
          <app-panel-base title="Timbrado  Smarter WEB (PAC Principal)" [camelCase]="false">
            <div class="panel-content">
              <component-ui-check-box
                helpLine="Indica que el timbrado que se usará será en modo demostración y no consumira timbres"
                [tabIndex]="14" [widthLabelColumns]="2" formControlName="PacDemo" [label]="'PAC demo'" />
              <components-ui-text-box [maxLength]="60" [tabIndex]="15" [required]="true" [label]="'Usuario'"
                formControlName="UsuarioPac" [helpLine]="'Usuario asignado para timbrar, 60 caracteres'"
                [widthTextColumns]="2" [widthLabelColumns]="2" />

              <components-ui-password-box [maxLength]="100"
                [helpLine]="'Contraseña asignada para timbrar, 100 caracteres'" [required]="true" [label]="'Contraseña'"
                formControlName="ContrasenaPac" [tabIndex]="16" [widthTextColumns]="2" [widthLabelColumns]="2" />

              <components-ui-number-box
                [helpLine]="'Indique el mínimo de timbres a tener y que el sistema nos alerte, Ejemplo: 1000'"
                [maxLength]="6" [label]="'Mínimo de timbres'" formControlName="MinimoTimbres" [tabIndex]="17"
                [widthTextColumns]="1" [widthLabelColumns]="2" />

              <components-ui-password-box [required]="true" helpLine="Contraseña del CSD, 20 caracteres"
                [maxLength]="20" [label]="'Contraseña'" formControlName="ContrasenaCsd" [tabIndex]="18"
                [widthTextColumns]="2" [widthLabelColumns]="2" />
              <component-ui-file-upload-box helpLine="archivo .key para poder timbrar"
                (onChange)="onChangeFile($event,1)" accepts=".key,.KEY" [text]="myForm.value.RutaKey" [label]="'.key'"
                [tabIndex]="19" [widthTextColumns]="4" [widthLabelColumns]="2" />
              <component-ui-file-upload-box helpLine="archivo .cer para poder timbrar"
                (onChange)="onChangeFile($event,2)" accepts=".cer,.CER" [text]="myForm.value.RutaCsd" [label]="'.cer'"
                [tabIndex]="20" [widthTextColumns]="4" [widthLabelColumns]="2" />
              <components-ui-text-box
                helpLine="Token infinito generado en la página del PAC, sirve para no autenticar en cada timbrado, acelerando el proceso de timbrado, 5000 caracteres"
                [tabIndex]="21" [maxLength]="5000" [label]="'Token infinito'" formControlName="TokenInfinito"
                [widthTextColumns]="10" [widthLabelColumns]="2" />

            </div>
          </app-panel-base>
          <app-panel-base title="Timbrado  Digital Factura (PAC alterno en caso de caida del PAC principal)"
            [camelCase]="false">
            <div class="panel-content">
            </div>
          </app-panel-base>
        </div>
        <div id="default-tab-4" class="tab-pane" role="tabpanel">
          <app-panel-base title="Decargas">
            <div class="panel-content">
              <div class="card-header h6 mb-1 bg-none p-2 text-orange">
                <i class="fa fa-circle-info fa-lg fa-fw"></i>
                Clave CIEC, se utiliza para la descarga con captcha
              </div>
              <components-ui-password-box
                helpLine="Indique la la clave CIEC para poder descargar XML del SAT solicitando captcha"
                [maxLength]="20" [label]="'Clave CIEC'" formControlName="ClaveCiec" [tabIndex]="32"
                [widthTextColumns]="2" [widthLabelColumns]="2" />
              <div class="card-header h6 mb-1 bg-none p-2 text-orange">
                <i class="fa fa-circle-info fa-lg fa-fw"></i>
                Archivos .csd y .key de la FIEL, se utiliza para la descarga de 200,000 Xmls por periodo
              </div>
              <components-ui-password-box helpLine="Indique la contraseña del CSD para la FIEL" [maxLength]="20"
                [label]="'Contraseña'" formControlName="passFiel" [tabIndex]="32" [widthTextColumns]="2"
                [widthLabelColumns]="2" />
              <component-ui-file-upload-box helpLine="archivo.key para poder descargar XMLS del SAT"
                (onChange)="onChangeFile($event,4)" accepts=".key,.KEY" [text]="myForm.value.RutaKeyFiel"
                [label]="'.key'" [tabIndex]="33" [widthTextColumns]="4" [widthLabelColumns]="2" />
              <component-ui-file-upload-box helpLine="archivo.cer para poder descargar XMLS del SAT"
                (onChange)="onChangeFile($event,5)" accepts=".cer,.CER" [text]="myForm.value.RutaCsdFiel"
                [label]="'.cer'" [tabIndex]="34" [widthTextColumns]="4" [widthLabelColumns]="2" />
            </div>
          </app-panel-base>
        </div>
        <div id="default-tab-5" class="tab-pane" role="tabpanel">
          <app-panel-base title="Contabilidad">
            <div class="panel-content">
              <div class="card-header h6 mb-1 bg-none p-2 text-orange">
                <i class="fa fa-circle-info fa-lg fa-fw"></i>
                Estructuras de los catálogos, indique el número de dígitos por cada nivel, separado por un guión
              </div>
              <components-ui-text-box
                helpLine="Estructura de niveles que se utilizará, cada número representa la cantidad de digitos que aceptará, separados por un guión que representa el nivel, 100 caracteres"
                [maxLength]="100" [label]="'Cuentas Contables'" formControlName="EstructuraCuentasContables"
                [tabIndex]="35" [widthTextColumns]="2" [widthLabelColumns]="2" [isEstructura]="true"
                [required]="true" />
              <components-ui-text-box
                helpLine="Estructura de niveles que se utilizará, cada número representa la cantidad de digitos que aceptará, separados por un guión que representa el nivel, 100 caracteres"
                [maxLength]="100" [label]="'Activos Fijos'" formControlName="EstructuraActivosFijos" [tabIndex]="36"
                [widthTextColumns]="2" [widthLabelColumns]="2" [isEstructura]="true" [required]="true" />
              <components-ui-text-box
                helpLine="Estructura de niveles que se utilizará, cada número representa la cantidad de digitos que aceptará, separados por un guión que representa el nivel, 100 caracteres"
                [maxLength]="100" [label]="'Familias de Productos'" formControlName="EstructuraFamilias" [tabIndex]="37"
                [widthTextColumns]="2" [widthLabelColumns]="2" [isEstructura]="true" [required]="true" />
              <components-ui-text-box
                helpLine="Estructura de niveles que se utilizará, cada número representa la cantidad de digitos que aceptará, separados por un guión que representa el nivel, 100 caracteres"
                [maxLength]="100" [label]="'Centros de Costos'" formControlName="EstructuraCentrosCostos"
                [tabIndex]="38" [widthTextColumns]="2" [widthLabelColumns]="2" [isEstructura]="true"
                [required]="true" />
              <components-ui-text-box
                helpLine="Estructura de la clave de productos que se utilizará, cada número representa la cantidad de digitos que aceptará, separados por un guión que representa el nivel, 50 caracteres, ejemplo: 4-3-3"
                [maxLength]="100" [label]="'Mascara Productos'" formControlName="EstructuraProductos" [tabIndex]="39"
                [widthTextColumns]="2" [widthLabelColumns]="2" [isEstructura]="true" />

              <div class="card-header h6 mb-1 bg-none p-2 text-orange">
                <i class="fa fa-circle-info fa-lg fa-fw"></i>
                Póizas automáticas, indique si las pólizas se van a generar por cada movimiento generado
              </div>
              <component-ui-check-box
                helpLine="Indica que cada vez que se registre un movimiento dentro de COMPLIT, este será contabilizado de manera inmediata (transaccional)"
                [tabIndex]="40" [widthLabelColumns]="2" formControlName="GenerarPolizasPorTransaccion"
                [widthTextColumns]="7" [label]="'Genera pólizas por movimiento'" />

            </div>
          </app-panel-base>
        </div>
        <div id="default-tab-6" class="tab-pane" role="tabpanel">
          <app-panel-base
            title="Configuración para el envió de correos de Comprobantes Emitidos (Facturas, Pedidos, Cotizaciones)">
            <div class="panel-content">
              <div formGroupName="ConfiguracionCorreo">
                <input type="hidden" formControlName="Id">
                <components-ui-text-box
                  helpLine="Indique el servidor que se usará para el envío de correos electrónicos, 100 caracteres"
                  [tabIndex]="42" [maxLength]="100" [label]="'ServidorSMTP'" formControlName="ServidorSMTP"
                  [widthTextColumns]="2" [widthLabelColumns]="2" />
                <components-ui-number-box helpLine="Puerto a utilizar según el Servidor SMTP, 10 números"
                  [tabIndex]="43" [maxLength]="10" [label]="'Puerto'" formControlName="Puerto" [widthTextColumns]="2"
                  [widthLabelColumns]="2" />
                <component-ui-check-box helpLine="Seleccione si el Servidor SMTP requiere SSL" [tabIndex]="44"
                  [widthLabelColumns]="2" formControlName="SSL" [widthTextColumns]="7" [label]="'SSL'" />
                <component-ui-check-box
                  helpLine="Seleccione si el Servidor SMTP requiere la versión 1.2 para la seguridad" [tabIndex]="45"
                  [widthLabelColumns]="2" formControlName="TLS12" [widthTextColumns]="7" [label]="'TLS 1.2'" />
                <components-ui-text-box helpLine="Usuario o correo electrónico, 100 caracteres" [tabIndex]="46"
                  [maxLength]="100" [label]="'Usuario'" formControlName="Usuario" [widthTextColumns]="2"
                  [widthLabelColumns]="2" />
                <components-ui-password-box helpLine="Indique la contraseña del correo electrónico, 100 caracteres"
                  [tabIndex]="47" [maxLength]="100" [label]="'Contraseña'" formControlName="Contrasena"
                  [widthTextColumns]="2" [widthLabelColumns]="2" />
                <div class="NgxEditor__Wrapper border-1" style="border: 1px solid !important;">
                  <ngx-editor-menu [editor]="editor"> </ngx-editor-menu>
                  <ngx-editor [editor]="editor" [ngModel]="html" [disabled]="false" formControlName="CuerpoCorreo"
                    [placeholder]="'Cuerpo del correo que se le mandará al cliente.'"></ngx-editor>
                </div>
              </div>
            </div>
          </app-panel-base>
        </div>
        <div id="default-tab-7" class="tab-pane" role="tabpanel">
          <app-panel-base
            title="Configuración para el envió de correos de Comprobantes de COMPRAS (Ordenes, Entradas, etc.)">
            <div class="panel-content">
              <div formGroupName="ConfiguracionCorreoCompra">
                <input type="hidden" formControlName="Id">
                <components-ui-text-box
                  helpLine="Indique el servidor que se usará para el envío de correos electrónicos, 100 caracteres"
                  [tabIndex]="42" [maxLength]="100" [label]="'ServidorSMTP'" formControlName="ServidorSMTP"
                  [widthTextColumns]="2" [widthLabelColumns]="2" />
                <components-ui-number-box helpLine="Puerto a utilizar según el Servidor SMTP, 10 números"
                  [tabIndex]="43" [maxLength]="10" [label]="'Puerto'" formControlName="Puerto" [widthTextColumns]="2"
                  [widthLabelColumns]="2" />
                <component-ui-check-box helpLine="Seleccione si el Servidor SMTP requiere SSL" [tabIndex]="44"
                  [widthLabelColumns]="2" formControlName="SSL" [widthTextColumns]="7" [label]="'SSL'" />
                <component-ui-check-box
                  helpLine="Seleccione si el Servidor SMTP requiere la versión 1.2 para la seguridad" [tabIndex]="45"
                  [widthLabelColumns]="2" formControlName="TLS12" [widthTextColumns]="7" [label]="'TLS 1.2'" />
                <components-ui-text-box helpLine="Usuario o correo electrónico, 100 caracteres" [tabIndex]="46"
                  [maxLength]="100" [label]="'Usuario'" formControlName="Usuario" [widthTextColumns]="2"
                  [widthLabelColumns]="2" />
                <components-ui-password-box helpLine="Indique la contraseña del correo electrónico, 100 caracteres"
                  [tabIndex]="47" [maxLength]="100" [label]="'Contraseña'" formControlName="Contrasena"
                  [widthTextColumns]="2" [widthLabelColumns]="2" />
                <div class="NgxEditor__Wrapper border-1" style="border: 1px solid !important;">
                  <ngx-editor-menu [editor]="editorCompra"> </ngx-editor-menu>
                  <ngx-editor [editor]="editorCompra" [ngModel]="htmlCompra" [disabled]="false"
                    formControlName="CuerpoCorreo"
                    [placeholder]="'Cuerpo del correo que se le mandará al Proveedor.'"></ngx-editor>
                </div>
              </div>
            </div>
          </app-panel-base>
        </div>
      </div>






    </form>
  </div>
</app-container-base>
