<app-container-base [hasCounter]="false" icon="fa fa-bars-staggered" [hasBar]="true" [setIsNew]="esNuevo"
  [getLast]="false" subTitle="Presupuestos por Vendedor" [hasManagedDelete]="true"
  (onClickBarButton)="onClickBarButton($event)" [activeButtons]="activeButtons">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base *ngIf="sourceVendedores.length>0 && cords" title="Búsqueda de Sucursales"
        style="position: absolute; z-index:9;margin-left:250px;margin-top:80px;width:450px;box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;">
        <div class="panel-content">
          <ng-scrollbar style="height: 400px;">
            <table class="table table-sm table-bordered"
              style="border: 1px solid rgb(164, 164, 164);height: 38px;table-layout: fixed !important;">
              <thead style="position: sticky;top:0;font-weight: 700 !important;background-color: #ff8000;">
                <tr>
                  <th style="font-weight:700;color:#ffffff;width:200px;" class="pt-0 pb-0">Sucursal</th>
                </tr>
              </thead>
              <tbody>
                <tr style="cursor:pointer;width:200px;" (click)="clicTablaVen($event, item)"
                  [id]="'row-search' + idUnico + '_'+i" [class]="selectedIndex == i ? 'bg-blue-800 pulse':''"
                  *ngFor="let item of sourceVendedores; let i = index;">
                  <td
                    [style]="'width:100px;'+(selectedIndex == i ? 'color:#ffffff;font-weight:bold;':(item.Precio>0?'':'color:red;'))"
                    class="text-truncate"> {{item.Clave}} - {{item.Nombre}}</td>
                </tr>
              </tbody>
            </table>
          </ng-scrollbar>
        </div>
      </app-panel-base>
      <app-panel-base title="Información del Presupuesto" [style]="'width:650px'">
        <div class="panel-content">
          <div class="d-flex">
            <component-ui-combo-box [setItemsList]="listaAnios" listProperty='Nombre' label="Año" [tabIndex]="2"
              [inputProperty]="'Nombre'" (onSelectedItem)="selectAnio($event)" [widthLabelColumns]="11"
              [widthTextColumns]="11" [topLabel]="true" [selectManualItem]="getAnio" />

            <component-ui-combo-box [setItemsList]="listaMeses" listProperty='Nombre' label="Mes" [tabIndex]="2"
              [inputProperty]="'Nombre'" (onSelectedItem)="selectMes($event)" [selectManualItem]="getMes"
              (onKeyPress)="onKeyPressMes($event)" (onKeyDown)="onKeyDownMes($event)" [widthLabelColumns]="12"
              [widthTextColumns]="12" [topLabel]="true" #periodoPptoVendedor />
          </div>
        </div>
      </app-panel-base>
      <div class="invoice-detail" id="divPresupuestosVendedores">
        <app-panel-base title="Detalle de Metas por Vendedor" [style]="'width:650px'" [itemsMenuDropDown]="menuItems"
          (onClicMenu)="clicMenu($event)">
          <div class="panel-content">
            <ng-scrollbar style="height: 300px;border-bottom: 1px solid #AAAAAA;">
              <table class="blueTable" style="width:600px;">
                <thead style="position: sticky;top:0;">
                  <tr>
                    <th style="width: 50px;text-align:right; padding-right:4px;">No.</th>
                    <th style="width: 150px;text-align:left; padding-left:4px;">Nombre</th>
                    <th style="width: 50px;text-align:right; padding-right:4px;">Meta</th>
                  </tr>
                </thead>
                <tbody class="bg-light">
                  <tr [style]=" indexEditing == index ?  'background-color: #ffdbb7;': ''"
                    (keydown)="keyDownRow($event, index)" *ngFor="let item of sourceGrid; let index = index;">
                    <td style="width: 80px;min-width:80px;max-width: 80px;"><input (keyup)="buscarVendedor($event)"
                        (keydown.escape)="escapeVendedor($event)" (keydown.arrowup)="up($event,0)"
                        (keydown.arrowdown)="down($event,0)" style="text-align: right;height: 22px;"
                        (keydown.enter)="enterVendedor($event)" class="grid-editor text-dark"
                        [id]="'txt_'+index+'_0' + idUnico" (focus)="focusVendedor(index, $event, item)"
                        (blur)="blurVendedor($event)" type="text" [value]="item.Clave ? item.Clave: ''" (keydown)="navegarVendedor($event)"
                        [style]="'width:100%;text-align:right;padding-right:5px;'" />
                    </td>
                    <td class="text-dark" style="padding-left: 5px;">{{item.Vendedor?item.Vendedor!.Nombre:''}}
                    <td>
                      <input (keydown.arrowup)="up($event,5)" (keydown.arrowdown)="down($event,5)"
                        (keydown.escape)="escapeMeta($event)" *ngIf="item.Vendedor" (keydown.enter)="enterMeta($event)"
                        class="grid-editor text-dark" [id]="'txt_'+index+'_2' + idUnico" (blur)="blurMeta($event)"
                        (focus)="setIndexEdit(index, $event, item)" type="number" style="text-align:right;height: 22px;"
                        [value]="item.Vendedor ? item.Meta: 0"
                        [style]="'width:100%;text-align:right;padding-right:5px;'" />

                    </td>
                  </tr>
                </tbody>
              </table>
            </ng-scrollbar>
            <div class="d-flex">
              <div style="width:80%;">
                <div class="widget-list mb-0" style="display: flex" *ngIf="myForm.value.UsuarioElabora">
                  <div class="widget-list-item" style="width:50%;">
                    <div class="widget-list-media">
                      <i class="fas fa-user-check fa-1x text-green"></i>
                    </div>
                    <div class="widget-list-content">
                      <h4 class="widget-list-title">{{myForm.value.UsuarioElabora.Nombre}}</h4>
                      <p class="widget-list-desc">Usuario elaboró</p>
                    </div>
                  </div>
                </div>
              </div>
              <form [formGroup]="myFormTotalesVen">
                <components-ui-text-box label='Total' formControlName="TotalPpto" [tabIndex]="3" [widthTextColumns]="8"
                  [enabled]="false" [alinearDerecha]="true" [widthLabelColumns]="4" [formatoMoneda]="true"
                  [maxLength]="70" />
              </form>
            </div>
          </div>
        </app-panel-base>
      </div>
    </form>
  </div>
  <ng-template #busquedaUsuariosPresupuesto let-modal let-c="close">
    <app-data-search titulo="Búsqueda de Vendedores" [columns]="getUserColumns" entityName='Usuario'
      [filter]="getUserFilter" [propertys]="getUserPropertys" [orders]="'Nombre|asc'" #busquedaUsuariosPresupuesto />
  </ng-template>

  <ng-template #copiarPresupuestoVendedor let-modal let-c="close">
    <ctrl-edicion-copiar-presupuestos [tipo]="1">
    </ctrl-edicion-copiar-presupuestos>
  </ng-template>
</app-container-base>
