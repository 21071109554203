<form [formGroup]="myForm" class="bg-white" autocomplete="off"
  style="padding: 5px;box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;">
  <app-panel-base title="Copiar documentos">
    <div class="panel-content">
      <div class="card border-0 mb-0">
        <div class="card-header h6 mb-0 bg-none p-0">
          <i class="fa fa-circle-info fa-lg fa-fw text-blue text-opacity-50 me-1"></i> Información para copiar
          comprobantes
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-lg-2">
              <component-ui-combo-box style="margin-right: 5px;" formControlName="Sucursal" [inputProperty]="'Nombre'"
                [setItemsList]="getItemsSucs" [topLabel]="true" label="Sucursal" [tabIndex]="1" [widthLabelColumns]="12"
                [widthTextColumns]="12" (onSelectedItem)="onSelectedItem($event,'Sucursal')" [widthRightColumns]="0"
                #cboSucursal />
            </div>
            <div class="col-lg-2">
              <component-ui-combo-box style="margin-right: 5px;" [filterQuery]="getFilter" entityName="DocumentoCompra"
                formControlName="DocumentoCompra" [topLabel]="true" label="Documento" [tabIndex]="2"
                [widthLabelColumns]="12" [widthTextColumns]="12"
                (onSelectedItem)="onSelectedItem($event,'DocumentoCompra')" [widthRightColumns]="0" #cboDocumento />
            </div>
            <div class="col-lg-2">
              <component-ui-combo-box style="width:15%; margin-right: 5px;" formControlName="Serie" [topLabel]="true"
                label='Serie' [inputProperty]="'Serie'" [setItemsList]="getItemsSerie" [tabIndex]="3"
                [widthLabelColumns]="12" (onSelectedItem)="onSelectedItem($event,'Serie')" [widthTextColumns]="12"
                [widthRightColumns]="0" #cboSerie />
            </div>
            <div class="col-lg-2">
              <components-ui-number-box style="width:15%;" [topLabel]="true" formControlName="Folio" label='Folio'
                [tabIndex]="4" [widthLabelColumns]="12" [widthTextColumns]="12" #txtFolio />
            </div>
            <div class="col-lg-4">
              <a (click)="searchDocument()" class="btn btn-blue me-5px mt-2"><i
                  class="fa {{(searching ?'fa-circle-notch fa-spin':'fa-magnifying-glass')}}"></i> Buscar</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </app-panel-base>
  <app-panel-base title="Documentos">
    <div class="panel-content">
      <div class="table-responsive mb-3" style="max-height: 220px; height: 220px; overflow-y:auto; border: 1px solid;">
        <table class="table table-sm table-hover">
          <thead style="background-color: #fd8204; position: sticky;top:0;color: #ffffff;">
            <tr>
              <th>
              </th>
              <th>Serie/Folio</th>
              <th>Fecha</th>
              <th>Proveedor</th>
              <th style="text-align:right;">SubTotal</th>
              <th style="text-align:right;">IVA</th>
              <th style="text-align:right;">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of documentos; let i = index">
              <td>
                <i (click)="deleteRow(i)" style="color:red;cursor:pointer;" class="fas fa-rectangle-xmark fa-lg"></i>
              </td>
              <td>{{item.SerieString}}-{{item.Folio}}</td>
              <td>{{getDate(item.FechaEmision)}}</td>
              <td>{{item.Proveedor ? item.Proveedor.Nombre:''}}</td>
              <td style="text-align:right;">{{item.SubTotal | number:'1.2-6'}}</td>
              <td style="text-align:right;">{{item.TotalIvaTrasladado | number:'1.2-6'}}</td>
              <td style="text-align:right;">{{item.Total | number:'1.2-6'}}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <table class="table table-borderless table-sm fw-bold m-0">
        <tbody>
          <tr>
            <td class="w-150px">Documentos</td>
            <td></td>
            <td class="text-end">{{documentos.length}}</td>
          </tr>
          <tr>
            <td class="w-150px">Subtotal</td>
            <td></td>
            <td class="text-end">${{getSubtotal | number:'1.2-6'}}</td>
          </tr>
          <tr>
            <td>IVA</td>
            <td></td>
            <td class="text-end">${{getIva | number:'1.2-6'}}</td>
          </tr>
          <tr>
            <td colspan="3">
              <hr class="m-0">
            </td>
          </tr>
          <tr>
            <td class="pb-2" colspan="2"><b>Total</b></td>
            <td class="text-end pb-2 text-decoration-underline bg-success-subtle ">
              <b>${{getTotal | number:'1.2-6'}}</b>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </app-panel-base>
  <div style="text-align: right; margin-top: 5px;margin-bottom: 5px;">
    <div *ngIf="documentos.length>0" (click)="accept()" class="btn btn-success" style="margin-right: 5px;">Aceptar</div>
    <div (click)="closeModal()" class="btn btn-danger">Cancelar</div>
  </div>
</form>
