

<div class="card text-center border-0" cdkDrag
  style="z-index:2;box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;; width: 600px; position: fixed;left: 30%;top:30%;">
  <div class="card-header fw-bold" style="cursor: move;" cdkDragHandle>
    <span>
      Configuración Para Reporte de Antigüedad de Saldos
    </span>
  </div>
  <form [formGroup]="myForm" autocomplete="off">
    <div class="invoice-detail" id="divDetalleCargaEntrega">
      <app-panel-base title="Selección de Rangos de antigüedad de saldos">
        <div class="panel-content">
          <div class="d-flex">
            <div class="row">
              <components-ui-text-box label='Período 1 ' [required]="true" formControlName="textoPeriodo1"
                [tabIndex]="100" style="width:250px" [widthTextColumns]="5" [widthLabelColumns]="5" />
              <components-ui-number-box label='de ' [required]="true" formControlName="periodo1Del" [tabIndex]="101"
                style="width:150px" [widthTextColumns]="6" [widthLabelColumns]="5" />
              <components-ui-number-box label='a ' [required]="true" formControlName="periodo1Al" [tabIndex]="102"
                style="width:150px" [widthTextColumns]="6" [widthLabelColumns]="5" />

            </div>
          </div>
          <div class="d-flex">
            <div class="row">
              <components-ui-text-box label='Período 2 ' [required]="true" formControlName="textoPeriodo2"
                [tabIndex]="103" style="width:250px" [widthTextColumns]="5" [widthLabelColumns]="5" />
              <components-ui-number-box label='de ' [required]="true" formControlName="periodo2Del" [tabIndex]="104"
                style="width:150px" [widthTextColumns]="6" [widthLabelColumns]="5" />
              <components-ui-number-box label='a ' [required]="true" formControlName="periodo2Al" [tabIndex]="105"
                style="width:150px" [widthTextColumns]="6" [widthLabelColumns]="5" />
            </div>
          </div>

          <div class="d-flex">
            <div class="row">
              <components-ui-text-box label='Período 3 ' [required]="true" formControlName="textoPeriodo3"
                [tabIndex]="106" style="width:250px" [widthTextColumns]="5" [widthLabelColumns]="5" />
              <components-ui-number-box label='de ' [required]="true" formControlName="periodo3Del" [tabIndex]="107"
                style="width:150px" [widthTextColumns]="6" [widthLabelColumns]="5" />
              <components-ui-number-box label='a ' [required]="true" formControlName="periodo3Al" [tabIndex]="108"
                style="width:150px" [widthTextColumns]="6" [widthLabelColumns]="5" />
            </div>
          </div>

          <div class="d-flex">
            <div class="row">
              <components-ui-text-box label='Período 4 ' [required]="true" formControlName="textoPeriodo4"
                [tabIndex]="109" style="width:250px" [widthTextColumns]="5" [widthLabelColumns]="5" />
              <components-ui-number-box label='de ' [required]="true" formControlName="periodo4Del" [tabIndex]="110"
                style="width:150px" [widthTextColumns]="6" [widthLabelColumns]="5" />
              <components-ui-number-box label='a ' [required]="true" formControlName="periodo4Al" [tabIndex]="111"
                style="width:150px" [widthTextColumns]="6" [widthLabelColumns]="5" />
            </div>
          </div>
          <div class="row">
          </div>
          <div class="mt-2">
          </div>
          <div class="card-footer fw-bold">
            <div class="d-flex">
              <div style="text-align: right; width: 100%; margin-top: 5px;">
                <button (click)="aceptar()" class="btn btn-success" [tabIndex]="112">Aceptar</button>
              </div>

              <div style="text-align: right; width: 20%;margin-top: 5px;">
                <button (click)="closeModal()" class="btn btn-primary" [tabIndex]="113">Cancelar</button>
              </div>
            </div>
          </div>
        </div>
      </app-panel-base>
    </div>
  </form>
</div>
