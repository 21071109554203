<div class="card text-center border-0" cdkDrag
  style="box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;width: 900px">
  <div class="card-header fw-bold" style="cursor: move;" cdkDragHandle>
    <span>
      {{'Búsqueda de pre-ordenes de producción' | titlecase}}
    </span>
  </div>
  <div class="card-body" style="min-height: 500px; max-height: 500px;">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Filtros de búsqueda">
        <div class="panel-content">
          <div class="row" style="margin-left:15px;">
            <div class="col-md-4">
              <components-ui-date-box label='Fecha inicio' formControlName="FechaInicio" [tabIndex]="100"
                [widthLabelColumns]="5" [widthTextColumns]="2" [required]="true" #txtFecha />
            </div>
            <div class="col-md-4">
              <components-ui-date-box label='Fecha Fin' formControlName="FechaFin" [tabIndex]="101"
                [widthLabelColumns]="5" [widthTextColumns]="2" [required]="true" />
            </div>
          </div>

          <components-ui-text-box [tabIndex]="102" [label]="'Cliente'" formControlName="Cliente" [widthTextColumns]="6"
            [enableIcon]="true" [widthLabelColumns]="2" Entidad="Cliente" [esParaFiltrar]="true"  />

          <components-ui-text-box [tabIndex]="103" [label]="'Serie/Folio Factura'" formControlName="Factura"
            [widthTextColumns]="6" [widthLabelColumns]="2" />

          <a (click)="buscar()" class="btn btn-white d-block"><i
              class="fa fa-search t-plus-1 text-danger fa-fw fa-lg"></i>Buscar</a>
        </div>
      </app-panel-base>
      <app-panel-base title="Registros encontrados" style="height: 295px;">
        <div class="panel-content">
          <ng-scrollbar style="height: 150px; width:830px; border-bottom: 1px solid #AAAAAA;">
            <table class="blueTable" style="width:820px;">
              <thead style="position: sticky;top:0;">
                <tr>
                  <th style="width: 45px;"></th>
                  <th style="width: 50px;">Sucursal</th>
                  <th style="width: 50px;">Factura</th>
                  <th style="width: 50px;">PreOrden</th>
                  <th style="width: 130px;">Cliente</th>
                  <th style="width: 70px;">Emisión</th>
                  <th style="width: 70px;">Cancelación</th>
                </tr>
              </thead>
              <tbody class="bg-light">
                <tr [style]="'padding-top:5px;'"
                  *ngFor="let item of lista; let index = index;">
                  <td style="text-align: center;">
                    <a (click)="seleccionar(item)" class="btn m-1 btn-sm btn-green btn-xs d-block"><i
                        class="fa fa-share-from-square fa-fw"></i>Mostrar</a>
                  </td>
                  <td class="text-dark" style="width:70px">
                    {{item.sucursal.clave}} - {{item.sucursal.nombre}}
                  </td>
                  <td class="text-dark" style="width:70px">
                    {{item.serieFolioDocumentoVentaOrigen}}
                  </td>
                  <td class="text-dark" style="width:70px">
                    {{item.serieString}} - {{item.folio}}
                  </td>
                  <td class="text-dark" style="width:70px">
                    {{item.cliente.nlave}} - {{item.cliente.nombre}}
                  </td>
                  <td class="text-dark" style="width:70px">
                    {{getDate(item.fechaEmision)}}
                  </td>
                  <td class="text-dark" style="width:70px">
                    {{getDate(item.fechaCancelacion)}}
                  </td>
                </tr>
              </tbody>
            </table>
          </ng-scrollbar>
          <!-- <gui-grid [source]="lista" [autoResizeWidth]="false" [width]="820" [virtualScroll]="true" [rowHeight]="30"
            [rowSelection]="false" [theme]="'fabric'" [maxHeight]="290">
            <gui-grid-column [width]="100" [cellEditing]="false">
              <ng-template let-item="item">
                <a (click)="seleccionar(item)" class="btn btn-sm btn-white"><i
                    class="fa fa-share-from-square t-plus-1 text-danger fa-fw fa-lg"></i>Mostrar</a>
              </ng-template>
            </gui-grid-column>
            <gui-grid-column header="Sucursal" [width]="100" [cellEditing]="false">
              <ng-template let-item="item">
                {{item.sucursal.clave}} - {{item.sucursal.nombre}}
              </ng-template>
            </gui-grid-column>
            <gui-grid-column header="Factura" field="serieFolioDocumentoVentaOrigen" [width]="100"
              [cellEditing]="false" />
            <gui-grid-column header="Orden" [width]="100" [cellEditing]="false">
              <ng-template let-item="item">
                {{item.serieString}} - {{item.folio}}
              </ng-template>
            </gui-grid-column>
            <gui-grid-column header="Cliente" [width]="200" [cellEditing]="false">
              <ng-template let-item="item">
                {{item.cliente.clave}} - {{item.cliente.nombre}}
              </ng-template>
            </gui-grid-column>
            <gui-grid-column header="Emisión" [width]="150" [cellEditing]="false">
              <ng-template let-item="item">
                {{getDate(item.fechaEmision)}}
              </ng-template>
            </gui-grid-column>
            <gui-grid-column header="Cancelación" [width]="200" [cellEditing]="false">
              <ng-template let-item="item">
                {{getDate(item.fechaCancelacion)}}
              </ng-template>
            </gui-grid-column>
          </gui-grid> -->
        </div>
      </app-panel-base>

    </form>
  </div>
  <div class="card-footer fw-bold">
    <div style="text-align: right; margin-top: 5px;">
      <div (click)="closeModal()" class="btn btn-danger">Cerrar</div>
    </div>
  </div>
</div>
