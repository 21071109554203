import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild, forwardRef, inject } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ComponentsUiService } from '../../services/components-ui.service';
import { CurrencyPipe } from '@angular/common';
import { AppSettings } from 'src/app/home/services/app-settings.service';

@Component({
  selector: 'components-ui-number-box',
  templateUrl: './number-box.component.html',
  styleUrls: ['./number-box.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberBoxComponent),
      multi: true,
    }
  ]
})
export class NumberBoxComponent {
  @Input()
  public aceptaPunto: boolean = true;
  @Input()
  public placeHolder: string = "";
  @Input()
  public widthLabelColumns: number = 2;
  @Input()
  public widthTextColumns: number = 10;
  @Input()
  public label: string = "Etiqueta";
  @Input()
  public separator: string = ":";
  @Input()
  public maxLength: number = 100;
  @Input()
  public tabIndex: number = 1;
  @Input()
  public required: boolean = false;
  @Input()
  public enableIcon: boolean = false;
  @Input()
  public enabled: boolean = true;
  @Input()
  public topLabel: boolean = false;
  @Input()
  public formatoMoneda: boolean = false;
  @Input()
  public editNegativo: boolean = false;
  @Input()
  public helpLine: string = "";
  @Input()
  public showLabel: boolean = true;
  @Input()
  public bold: boolean = false;
  @Output()
  public blur = new EventEmitter();

  @Output()
  public onFocus = new EventEmitter();

  @Input()
  public enableLink: boolean = false;

  @Output()
  public onClicLink = new EventEmitter();

  @Output()
  public onChangeValue = new EventEmitter();

  @Output()
  public onClicIcon = new EventEmitter();


  public appSettings = inject(AppSettings);
  @ViewChild('txtTagNumber')
  public tagInput!: ElementRef<HTMLInputElement>;

  value: number = 0;


  get IsRequired(): boolean {
    if (this.tagInput && this.required) {
      if (this.tagInput.nativeElement.value) {
        if (this.tagInput.nativeElement.value == "0") { return true; }
        return false;
      }
    }
    return this.required;
  }

  private isDisabled = false;
  inputField = new FormControl();
  onChange = (value: number) => {
    this.onChangeValue.emit(value);
  };

  onTouched = () => { };

  values: any = {
    before: 0,
    after: 0
  };

  constructor(private compsService: ComponentsUiService, private changeDetectorRef: ChangeDetectorRef,) {

  }


  onClick() {
    this.onClicIcon.emit();
  }

  onClickLink() {
    if (this.enableLink) {
      this.onClicLink.emit();
    }
  }

  ngDoCheck(): void {
  }
  ngAfterViewInit(): void {
    this.inputField.valueChanges.subscribe(value => {
      this.onChange(value!);
    });

    this.setDisabledState(this.isDisabled);
  }

  writeValue(val: number): void {
    this.inputField.setValue(val);
    this.value = val;
    if (val && this.formatoMoneda && !this.enabled) {
      setTimeout(() => {
        const formatter = new Intl.NumberFormat('en-US', {
          style: 'decimal',
          minimumFractionDigits: 2,
          maximumFractionDigits: 6
        });
        this.tagInput.nativeElement.type = "text";
        this.tagInput.nativeElement.value = `${formatter.format(val)}`
      }, 150);
    }
  }

  blurControl(value?: any) {
    this.tagInput.nativeElement.classList.remove("pulse-text");

    if (this.tagInput.nativeElement.value == "") {
      this.tagInput.nativeElement.value = "0";
    }

    this.writeValue(value ? parseFloat(value.toString()) : 0);
    this.values.after = value;
    this.blur.emit(this.values);
  }



  tomoFoco() {
    this.tagInput.nativeElement.classList.add("pulse-text");

    if (this.tagInput.nativeElement.value && this.formatoMoneda) {
      let val = String(this.tagInput.nativeElement.value).match(/[^ ,]+/g)!.join('');
      //val = String(val).match(/[^ $]+/g)!.join('')
      this.tagInput.nativeElement.value = val;
      this.tagInput.nativeElement.type = "number";
    }

    if (String(this.tagInput.nativeElement.value) == "0") {
      this.tagInput.nativeElement.value = "";
    }

    this.values.before = this.tagInput.nativeElement.value;


    let pes = document.querySelectorAll('.lineadeayuda');
    if (this.helpLine) {
      pes.forEach((elem: any) => { elem.innerHTML = this.helpLine; });
    } else {

      pes.forEach((elem: any) => { elem.innerHTML = "" });
    }

    this.onFocus.emit();
  }

  OnKeypress(e: any) {

    var key: any;
    key = String.fromCharCode(e.keyCode);

    if (this.tagInput.nativeElement.value.length >= this.maxLength && (e.keyCode > 47 && e.keyCode < 58)) {
      e.preventDefault();
      return
    }

    //let aceptaPunto: boolean = this.maxLength.toString().indexOf(".") < 0 ? false : true;
    if (!this.aceptaPunto && (e.keyCode === 46)) {
      e.preventDefault();
      return
    }


    if (e.keyCode === 45) { // validacion para negativo -
      if (!this.editNegativo) {
        e.preventDefault();
        return;
      } else {
        if (parseInt(this.tagInput.nativeElement.value.toString()) < 0) {
          e.preventDefault();
        }
      }
    }

  }

  keypress(e: any) {
    if (e.keyCode == 38 || e.keyCode == 40) { e.preventDefault(); }
  }



  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }
  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }
  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    this.changeDetectorRef.markForCheck();
  }

}
