<app-container-base subTitle="Informe de Utilidad" (onClickBarButton)="clickBoton($event)"
  [activeButtons]="activeButtons" [isReport]="true" icon=" fas fa-list-check" [hasCounter]="false">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Generales">
        <div class="panel-content">
          <div class="row">
            <components-ui-text-box label='Nombre del Reporte' formControlName="NombreReporte" [tabIndex]="1"
              [widthLabelColumns]="2" [widthTextColumns]="8" [maxLength]="100" (onChangeValue)="onChangeFecha($event)"
              #txtNombreReporte />
          </div>
          <div class="row">
            <div class="col-md-4">
              <components-ui-date-box label='Fecha inicio' formControlName="Fecha1" [tabIndex]="2"
                [widthLabelColumns]="6" [widthTextColumns]="3" [required]="true"
                (onChangeValue)="onChangeFecha($event)"></components-ui-date-box>
            </div>
            <div class="col-md-4">
              <components-ui-date-box label='Fecha Fin' formControlName="Fecha2" [tabIndex]="3" [widthLabelColumns]="3"
                [widthTextColumns]="3" [required]="true"
                (onChangeValue)="onChangeFecha($event)"></components-ui-date-box>
            </div>
          </div>
          <div class="card-header h6 mb-1 bg-none p-2 text-orange">
            <i class="fa fa-filter fa-lg fa-fw me-1"></i>
            Opción para indicar el Costo a utilizado para calcular la Utilidad
          </div>
          <div class="row">
            <component-ui-check-box [tabIndex]="30" [widthLabelColumns]="2" formControlName="Opc1"
              [label]="'Costo Reposición'" (onClick)= "clicCosto($event,'R')"/>
            <component-ui-check-box [tabIndex]="31" [widthLabelColumns]="2" formControlName="Opc2"
              [label]="'Costo Inventario'" (onClick)= "clicCosto($event,'I')"/>
          </div>
          {{this.myForm.value.Opc1|json}}
          {{this.myForm.value.Opc2|json}}
        </div>
      </app-panel-base>
      <app-panel-base title="Generales">
        <div class="panel-content">
          <component-ui-selection-filter [(FiltrosSeleccion)]="this.filtrosDiarioVentas" [tabIndex]="8"
            (FiltrosSeleccionChange)="changeselec($event)" [ReportHeader]="this.myForm"></component-ui-selection-filter>
        </div>
      </app-panel-base>
    </form>
  </div>
</app-container-base>
