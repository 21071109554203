import { Direccion } from './../../../configuracion/interfaces/direccion.interface';
import { Component, ElementRef, Input, OnInit, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EnumDataType } from 'src/app/component-ui/components/text-box/text-box.component';
import { EventsService } from 'src/app/service/events.service';
import { ModalService } from 'src/app/service/modal.service';
import { FacturacionService } from '../../services/facturacion.service';
import { Consignado } from '../../interfaces/venta.interface';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';

@Component({
  selector: 'app-alta-consignado-page',
  templateUrl: './alta-consignado-page.component.html',
  styles: [
  ]
})
export class AltaConsignadoPageComponent implements OnInit {
  public EDT = EnumDataType;
  private eventsService = inject(EventsService);
  @ViewChild('txtNombre')
  public txtNombre!: ElementRef<HTMLElement>;

  ngOnInit(): void {
    setTimeout(() => {
      const t: any = this.txtNombre;
      t.tagInput.nativeElement.focus();
    }, 150);
    if (this.idConsignado > 0) {
      this.eventsService.publish('home:isLoading', { isLoading: true });
      this.fs.getConsignadoPorId(this.idConsignado).subscribe((result) => {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        const c = JSON.parse(result.message);
        this.myForm.reset(c);
      });
    }
  }

  @Input()
  public idConsignado: number = 0;

  @Input()
  public idCliente: number = 0;

  public myForm: FormGroup = this.fb.group({
    Id: [0],
    Clave: [0],
    Nombre: [''],
    Rfc: [''],
    Direccion: [{} as Direccion],
    IdClientePertenece: [0],
  })

  constructor(private ms: ModalService, private fb: FormBuilder, private fs: FacturacionService) { }

  verificarCodigoPostal(fn: any) {
    let ent = this.myForm.value;
    if (!ent.Direccion.CodigoPostal) {
      this.eventsService.publish('home:showAlert', {
        message: "No ha indicado el código postal del consignado, ¿lo dejamos así?", cancelButton: true, textAccept: "Si",
        onConfirm: (data: AlertResponse) => {
          if (data.isAccept) {
            fn();
          }
        }
      });
      return;
    } else {
      fn();
    }
  }

  accept() {
    this.verificarCodigoPostal(() => {
      let ent = this.myForm.value;
      if (!ent.Nombre) {
        this.eventsService.publish('home:showAlert', { message: "Debe de indicar el Nombre.", cancelButton: false });
        return;
      }
      // if (!ent.Rfc) {
      //   this.eventsService.publish('home:showAlert', { message: "Debe de indicar el RFC.", cancelButton: false });
      //   return;
      // }
      if (!ent.Direccion) {
        this.eventsService.publish('home:showAlert', { message: "Debe de indicar la Dirección.", cancelButton: false });
        return;
      }

      if (!ent.Direccion.Pais) {
        this.eventsService.publish('home:showAlert', { message: "Debe de indicar el País.", cancelButton: false });
        return;
      }

      if (!ent.Direccion.NumeroExterior) {
        this.eventsService.publish('home:showAlert', { message: "Debe de indicar el Número Exterior.", cancelButton: false });
        return;
      }
      if (!ent.Direccion.Calle) {
        this.eventsService.publish('home:showAlert', { message: "Debe de indicar la Calle.", cancelButton: false });
        return;
      }
      if (!ent.Direccion.Colonia) {
        this.eventsService.publish('home:showAlert', { message: "Debe de indicar la Colonia.", cancelButton: false });
        return;
      }
      if (!ent.Direccion.Localidad) {
        this.eventsService.publish('home:showAlert', { message: "Debe de indicar la Localidad.", cancelButton: false });
        return;
      }
      if (!ent.Direccion.Municipio) {
        this.eventsService.publish('home:showAlert', { message: "Debe de indicar el Municipio.", cancelButton: false });
        return;
      }
      if (!ent.Direccion.Estado) {
        this.eventsService.publish('home:showAlert', { message: "Debe de indicar el Estado.", cancelButton: false });
        return;
      }
      ent.IdClientePertenece = this.idCliente;
      if (!ent.Direccion.Id) {
        ent.Direccion.Id = 0;
      }
      this.eventsService.publish('home:isLoading', { isLoading: true });
      this.fs.saveConsignados(JSON.stringify(ent)).subscribe((result) => {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        if (result.success) {
          this.ms.closeModal(true);
        } else {
          this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
        }
      });
    });
  }

  closeModal() {
    this.ms.closeModal(null);
  }
}
