import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { FilterOptions, LevelType, ReportFilter, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ReportsService } from 'src/app/service/reports.service';
import { InventarioService } from '../../services/inventario.service';
import { UtilsService } from 'src/app/service/utils.service';
import { Item } from '@generic-ui/ngx-grid/core/structure/source/src/api/item/item';
import Swal from 'sweetalert2';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';

@Component({
  templateUrl: './impresion-conteo.component.html',
  styleUrls: ['./impresion-conteo.component.scss'],
})
export class ImpresionConteoComponent implements OnInit {

  @ViewChild('txtNombreReporte') public txtNombreReporte!: ElementRef<TextBoxComponent>;

  private frmBuilder = inject(FormBuilder);
  private reportsService = inject(ReportsService);
  private inventarioService = inject(InventarioService);
  private utilsService = inject(UtilsService);

  activeButtons: ActiveButtons = {
    all: false,
    print: true,
    export: true
  }

  menuId: string = "impresion-conteo";


  public frmConteoInventario: FormGroup = this.frmBuilder.group({
    NombreReporte: ['CONTEO DE INVENTARIO', Validators.required],
    Opc1: [false],
    Opc2: [true]
  });

  ngOnInit(): void {
    setTimeout(() => {
      this.focus();
    }, 200);
  }

  focus() {
    const txt: any = this.txtNombreReporte;
    txt.tagInput.nativeElement.focus()
  }

  onClickCheckBox(ctrlName: string, value: any) {
    console.log(value)
    if (ctrlName === 'opc1') {
      this.frmConteoInventario.get('Opc1')?.setValue(value);
    } else {
      this.frmConteoInventario.get('Opc2')?.setValue(value);
    }
  }

  public filtrosConteoInventario: ReportFilter =
    {
      menuId : this.menuId,
      ReportHeader: {
        NombreReporte: 'CONTEO DE INVENTARIO'
      } as ReportHeader,
      TituloVisor: 'CONTEO DE INVENTARIO',
      NombreExcel: 'ImpresionConteoInventario.xlsx',
      FilterOptions: [
        { Campo: 'emp.Clave', Etiqueta: 'Empresa', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Empresa", MultiClave: false, HelpLine: "Clave de la Empresa a consultar (F2 para buscar)." },
        { Campo: 'suc.Clave', Etiqueta: 'Sucursal', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Sucursal", MultiClave: false, HelpLine: "Clave de la Sucursal a consultar (F2 para buscar)." },
        { Campo: 'alm.Clave', Etiqueta: 'Almacén', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Almacen", MultiClave: false, HelpLine: "Clave del Almacen a consultar (F2 para buscar)." },
        { Campo: 'clase.Clave', Etiqueta: 'Clasificación', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "ClasificacionProducto", HelpLine: "Clave de la Clasificación a consultar, separadas por coma (F2 para buscar)." },
        { Campo: 'fam.ClaveGeneral', Etiqueta: 'Familias', Tipo: TypeFilter.levels, TipoNiveles: LevelType.productFamily },
      ]
    };

  changeselec(event: any) {
    this.filtrosConteoInventario = event;
  }

  eventosBotones(event: any) {
    console.log(event);
    if (event === "print") {
      if (this.validarCampos(this.filtrosConteoInventario.FilterOptions!)) {
        this.filtrosConteoInventario.ReportHeader = this.frmConteoInventario.value;
        this.reportsService.printReport(this.filtrosConteoInventario, '/Inventarios/ReporteConteoInventario', false);
      }
    } else {
      this.inventarioService.getConteoInventarioParaCSV(this.filtrosConteoInventario).subscribe({
        next: resp => {
          console.log(resp);
          this.utilsService.exportarCSV("InventarioFisico", this.convertToCSV(resp));
        },
        error: err => console.log(err)
      });
    }
  }

  convertToCSV(source: any[]): string {
    const array = source;
    let str = 'Clave, Nombre, Conteo Físico';
    str += "\r\n";
    for (const item of source) {
      str += `${item.clave},${item.nombre}\r\n`;
    }
    return str;
  }

  validarCampos(filtros: FilterOptions[]) {
    for (const f of filtros) {
      switch (f.Campo) {
        case "emp.Clave":
        case "suc.Clave":
        case "alm.Clave":
          if (!f.Valor) {
            Swal.fire({
              text: `Debe indicar el campo ${f.Entidad}`,
              icon: 'warning',
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Aceptar'
            }).then((result: any) => {
              if (result.isConfirmed) {

              }
            });
            return false;
          }
          break;
        default:
          break;
      }
    }
    return true;
  }
}
