<app-container-base (onItemsComboSearched)="onItemsComboSearched($event)" (onItemSearched)="onItemSearched($event)"
  [activeButtons]="activeButtons" (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)"
  (onClickBarButton)="onClickBarButton($event)" [entity]="frmCorteCaja" [hasCounter]="false" icon="fa fa-user-check" [printManual]="true"
  subTitle="Corte de Caja">
  <div class="screen-content">
    <form class="frmCorteCaja" [formGroup]="frmCorteCaja" autocomplete="off" *ngIf="frmCorteCaja.value.Id >0">
      <app-panel-base title="General">
        <div class="panel-content">
          <div class="row">
            <div class="col-md-2">
              <button type="button" class="btn btn-primary btn-lg mb-10px" (click)="openModalCorte()">
                <i class="fa fa-cash-register"></i>
                Seleccionar Corte
              </button>
              <component-ui-combo-box label="Cuenta de Depósito" formControlName="CuentaBancaria" inputProperty="Nombre"
                [zeroMask]="3" [tabIndex]="1" [topLabel]="true" [widthLabelColumns]="12" [widthTextColumns]="12"
                [selectManualItem]="selectCuentaManualItem" (onSelectedItem)="onSelectedCuentaBancaria($event)"
                [entityName]="'CuentaBancaria'" />
            </div>
            <div class="col" *ngIf="frmCorteCaja.value.Id >0">
              <div class="note alert-primary">
                <div class="note-icon"><i class="fas fa-box-archive"></i></div>
                <div class="note-content">
                  <h4><b>Corte de caja</b></h4>
                  <span class="fs-15px">Encargado: {{frmCorteCaja.value.Encargado!.Nombre | uppercase}}</span><br>
                  <span class="fs-15px">Fecha: {{frmCorteCaja.value.Fecha | date: 'dd/MM/yyyy'}}</span>
                </div>
              </div>
            </div>
            <div class="col text-center" *ngIf="frmCorteCaja.value.Bloqueado">
              <i class="fas fa-lock text-yellow lock-corte"></i>
            </div>
          </div>
        </div>
      </app-panel-base>
      <div class="row m-0">
        <div class="col p-0">
          <app-panel-base title="Ingresos Caja">
            <div class="panel-content">
              <components-ui-number-box [bold]="true" [formatoMoneda]="true" [enabled]="false" label="Efectivo"
                [enableLink]="true" (onClicLink)="openConteoEfectivo()" formControlName="Efectivo" [tabIndex]="1"
                [widthTextColumns]="7" [widthLabelColumns]="5" />
              <components-ui-number-box [bold]="true" [enableLink]="true" [formatoMoneda]="true" [enabled]="false"
                label="Cheques" formControlName="TotalCheques" [tabIndex]="1" [widthTextColumns]="7"
                [widthLabelColumns]="5" (onClicLink)="openCheques()" />
              <components-ui-number-box [bold]="true" [enableLink]="true" [formatoMoneda]="true" [enabled]="false"
                label="Transferencias" formControlName="TotalTransferencias" [tabIndex]="1" [widthTextColumns]="7"
                (onClicLink)="openTransferencias()" [widthLabelColumns]="5" />
              <components-ui-number-box [bold]="true" [formatoMoneda]="true" [enabled]="false" label="Retiros de Caja"
                formControlName="TotalDepositosCajaGeneral" [enableLink]="true" (onClicLink)="openRetirosCaja()"
                [tabIndex]="1" [widthTextColumns]="7" [widthLabelColumns]="5" />
              <components-ui-number-box [bold]="true" [formatoMoneda]="true" [enabled]="false" label="Terminales"
                formControlName="TotalTerminales" [tabIndex]="1" [enableLink]="true" (onClicLink)="openTerminales()"
                [widthTextColumns]="7" [widthLabelColumns]="5" />
              <div style="min-height: 181px;">
                <table class="blueTable fs-14px" style="width: 400px; float: right;margin-right: 15px;">
                  <thead>
                    <tr>
                      <th colspan="2" class="text-center">Totales de terminales por banco</th>
                    </tr>
                    <tr>
                      <th class="text-end">Banco</th>
                      <th class="text-end">Importe</th>
                    </tr>
                  </thead>
                  <tbody class="bg-light">
                    <tr *ngFor="let t of terminalesAgrupadas; let i = index">
                      <td class="text-end">{{t.nombre}}</td>
                      <td class="text-end">{{t.importe | number: '1.2'}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <hr class="my-3">
              <components-ui-number-box [bold]="true" [formatoMoneda]="true" [enabled]="false" label="Fondo Fijo"
                formControlName="Fondo" [tabIndex]="1" [widthTextColumns]="7" [widthLabelColumns]="5" />
              <components-ui-number-box [bold]="true" [formatoMoneda]="true" [enabled]="false" label="Entregado"
                formControlName="TotalEntregado" [tabIndex]="1" [widthTextColumns]="7" [widthLabelColumns]="5" />
              <components-ui-number-box [bold]="true" [enableLink]="true" [formatoMoneda]="true" [enabled]="false"
                label="Facturas Pagadas de Otros Días" formControlName="TotalDocumentosPendientesPagadosCredito"
                [tabIndex]="1" [widthTextColumns]="7" [widthLabelColumns]="5" />
              <hr>
              <div class="widget widget-stats bg-green-600 mb-0 p-8px">
                <div class="stats-icon stats-icon-lg">
                  <i class="fa fa-cash-register fs-70px" style="top:-19px"></i>
                </div>
                <div class="stats-content">
                  <div class="stats-number m-0 fs-20px">TOTAL CAJA</div>
                  <div class="stats-progress progress m-0 w-50">
                    <div class="progress-bar" style="width: 0;"></div>
                  </div>
                  <div class="stats-number fs-20px m-0">${{frmCorteCaja.value.TotalDia | number: '1.2'}}</div>
                </div>
              </div>

            </div>
          </app-panel-base>
        </div>
        <div class="col p-0">
          <app-panel-base title="Ventas Contado">
            <div class="panel-content">
              <components-ui-number-box [bold]="true" [formatoMoneda]="true" [enabled]="false"
                label="(+) Ventas Sin Anticipos" formControlName="TotalVentasContado" [tabIndex]="1"
                [widthTextColumns]="8" [widthLabelColumns]="4" />
              <components-ui-number-box [bold]="true" [formatoMoneda]="true" [enabled]="false"
                [label]="labelAnticipoFacturado" formControlName="TotalAnticipoFacturado" [tabIndex]="1"
                [widthTextColumns]="8" [widthLabelColumns]="4" />
              <components-ui-number-box [bold]="true" [formatoMoneda]="true" [enabled]="false"
                [label]="labelAnticipoAplicadoContado" formControlName="TotalAnticipoAplicadoContado" [tabIndex]="1"
                [widthTextColumns]="8" [widthLabelColumns]="4" />
              <components-ui-number-box [bold]="true" [formatoMoneda]="true" [enabled]="false"
                [label]="labelNotasCreditoContado" formControlName="TotalNotasCreditoContado" [tabIndex]="1"
                [widthTextColumns]="8" [widthLabelColumns]="4" />
              <components-ui-number-box [bold]="true" [formatoMoneda]="true" [enabled]="false" label="TOTAL CONTADO"
                formControlName="TotalContado" [tabIndex]="1" [widthTextColumns]="8" [widthLabelColumns]="4" />
            </div>
          </app-panel-base>
          <app-panel-base title="Ventas Crédito">
            <div class="panel-content">
              <components-ui-number-box [bold]="true" [formatoMoneda]="true" [enabled]="false"
                label="(+) Ventas Crédito" formControlName="TotalVentasCredito" [tabIndex]="1" [widthTextColumns]="8"
                [widthLabelColumns]="4" />
              <components-ui-number-box [bold]="true" [formatoMoneda]="true" [enabled]="false"
                [label]="labelAnticipoAplicadoCredito" formControlName="TotalAnticipoAplicadoCredito" [tabIndex]="1"
                [widthTextColumns]="8" [widthLabelColumns]="4" />
              <components-ui-number-box [bold]="true" [formatoMoneda]="true" [enabled]="false"
                [label]="labelNotasCreditoCredito" formControlName="TotalNotasCredito" [tabIndex]="1"
                [widthTextColumns]="8" [widthLabelColumns]="4" />
              <components-ui-number-box [bold]="true" [formatoMoneda]="true" [enabled]="false" label="TOTAL CRÉDITO"
                formControlName="TotalCredito" [tabIndex]="1" [widthTextColumns]="8" [widthLabelColumns]="4" />
              <hr>
              <components-ui-number-box [bold]="true" [formatoMoneda]="true" [enabled]="false" label="TOTAL DECLARAR"
                formControlName="TotalContado" [tabIndex]="1" [widthTextColumns]="8" [widthLabelColumns]="4" />
            </div>
          </app-panel-base>
          <app-panel-base title="Observaciones del usuario">
            <div class="panel-content">
              <textarea class="form-control" formControlName="Observaciones" rows="5" #txtDescription></textarea>
            </div>
          </app-panel-base>
        </div>
      </div>
    </form>
  </div>
</app-container-base>

<ng-template #modalCorteCaja let-modal let-c="close">
  <seleccion-corte-caja></seleccion-corte-caja>
</ng-template>

<ng-template #modalConteoEfectivo let-modal let-c="close">
  <conteo-efectivo [conteo]="frmCorteCaja.value.ConteoEfectivo" />
</ng-template>

<ng-template #modalRetirosCaja let-modal let-c="close">
  <retiros-caja [retiros]="listaRetiros"></retiros-caja>
</ng-template>

<ng-template #modalTerminales let-modal let-c="close">
  <terminales [terminales]="listaTerminales"></terminales>
</ng-template>

<ng-template #modalTransferencias let-modal let-c="close">
  <transferencias [transferencias]="listaTransferencias"></transferencias>
</ng-template>

<ng-template #modalCheques let-modal let-c="close">
  <cheques [cheques]="listaCheques"></cheques>
</ng-template>
