<app-container-base [getLast]="false" (onDelete)="onDelete()" [activeButtons]="activeButtons" [entity]="myForm"
  (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)" entityName="CuentaContable"
  [printManual]="true" title="Cuentas Contables" icon="fa fa-folder-tree" subTitle="Administra las Cuentas Contables"
  (onClickBarButton)="imprimir($event)">
  <div class="screen-content">
    <span *ngIf="saving" class="loaderfact"><i class="fas fa-spinner fa-spin spin"></i></span>
    <div style="height: 41px;padding: 2px;">
      <div *ngIf="cargarCuentasBase" (click)="cargarCatalogoBase()" class="btn btn-primary ms-5px">
        <i class="fa fa-lg fa-clipboard-list"></i>
        Cargar Catálogo de Cuentas Base
      </div>

      <div style="margin-left: 5px;" *ngIf="showAddFamily" (click)="addLevel()" class="btn btn-primary"><i
          class="fa fa-lg me-1 fa-plus"></i>
        Agregar Cuenta [raiz]</div>

      <div style="margin-left: 5px;" (click)="addSubLevel()" *ngIf="showAddSubFam" class="btn btn-primary"><i
          class="fa fa-lg me-1 fa-plus"></i>
        Agregar Sub-cuenta a <span>[{{selectedTree.nombre | uppercase}}]</span></div>
    </div>
    <form [formGroup]="myForm" autocomplete="off">
      <div class="d-flex" style="height: 100%;">
        <components-ui-tree-view (onLoad)="onLoad()" [setIsDelete]="getReloadDelete" [setIsNew]="getReload"
          (onSelectedItem)="onSelectedItem($event)" [type]="'CuentaContable'" />

        <div style="width: 70%;padding-left: 5px;">
          <app-panel-base title="Datos de la Cuenta Contable" style="height: 439px;width: 637px;"
            *ngIf="this.myForm.value.clave>0">
            <div class="panel-content">

              <div class="card-header h6 mb-1 bg-none p-2 text-orange">
                <i class="fa fa-scale-unbalanced fa-lg fa-fw"></i>
                Información contable
              </div>
              <label [class]="'link'" *ngIf="myForm.value.Id>0" (click)="cambiarClave()"
                [style]="appSettings.appDarkMode ? 'color:#fe8e18;text-decoration:underline;':''">Cambiar Clave</label>
              <components-ui-number-box [tabIndex]="1" [label]="'Clave'" formControlName="clave" [widthTextColumns]="2"
                [widthLabelColumns]="4" [maxLength]="longClave" [enabled]="myForm.value.id==0" [required]="true" />

              <components-ui-text-box helpLine="Nombre de la Cuenta Contable, 100 caracteres." [tabIndex]="2"
                [label]="'Nombre'" formControlName="nombre" [widthTextColumns]="8" [widthLabelColumns]="4"
                [required]="true" #txtNombre />

              <components-ui-text-box helpLine="Abreviación del nombre de la Cuenta, 50 caracteres." [maxlength]="50"
                [tabIndex]="3" [label]="'Abreviación'" formControlName="abreviacion" [widthTextColumns]="4"
                [widthLabelColumns]="4" />

              <component-ui-combo-box [required]="true" helpLine="Tipo de Cuenta, Deudora/Acreedora"
                [label]="'Tipo de Cuenta'" formControlName="tipoCuenta" [tabIndex]="4" [zeroMask]="2" [widthList]="400"
                entityName="TipoCuentaContable" [widthTextColumns]="2" [widthLabelColumns]="4" [widthRightColumns]="6"
                (onSelectedItem)="selectedObjeto($event, 'tipoCuenta')" [listProperty]="'ClaveNombre'" />

              <component-ui-combo-box [label]="'Moneda'"
                helpLine="Moneda que maneja la Cuenta, deje vacio para ninguno." formControlName="moneda" [tabIndex]="5"
                [zeroMask]="2" [widthList]="400" entityName="Moneda" [widthTextColumns]="2" [widthLabelColumns]="4"
                [widthRightColumns]="6" (onSelectedItem)="selectedObjeto($event, 'moneda')"
                [listProperty]="'ClaveNombre'" />

              <component-ui-combo-box [label]="'Banco'" formControlName="banco"
                helpLine="Banco de la Cuenta, deje vacio para ningúno." [tabIndex]="6" [zeroMask]="2" [widthList]="400"
                entityName="c_Banco" [widthTextColumns]="2" [widthLabelColumns]="4" [widthRightColumns]="6"
                (onSelectedItem)="selectedObjeto($event, 'banco')" [listProperty]="'ClaveNombre'" />

              <components-ui-text-box [tabIndex]="7" [label]="'No de Cuenta'" formControlName="cuentaBancaria"
                helpLine="Número de cuenta bancaria." [widthTextColumns]="5" [widthLabelColumns]="4" />

              <component-ui-combo-box [label]="'Adicional'" formControlName="operacionAdicional" [tabIndex]="8"
                helpLine="Indique si la cuenta solicitará información adicional al momento de capturas." [zeroMask]="2"
                [widthList]="400" entityName="OperacionAdicionalCuentaContable" [widthTextColumns]="2"
                [widthLabelColumns]="4" [widthRightColumns]="6"
                (onSelectedItem)="selectedObjeto($event, 'operacionAdicional')" [listProperty]="'ClaveNombre'" />

              <component-ui-check-box [tabIndex]="9" [widthLabelColumns]="4" formControlName="aceptaNegativos"
                helpLine="Indique si la Cuenta NO aceptará negativos." [label]="'¿No Aceptar negativos?'" />

              <component-ui-check-box [tabIndex]="10" [widthLabelColumns]="4" formControlName="esActivoFijo"
                helpLine="Indique si es una Cuenta de Activo Fijo." [label]="'¿Es Activo Fijo?'" />

              <component-ui-check-box [tabIndex]="11" [widthLabelColumns]="4" formControlName="baja"
                helpLine="Seleccione para dar de baja y que no aparezca en capturas." [label]="'¿Dar de baja?'" />

              <div class="card-header h6 mb-1 bg-none p-2 text-orange">
                <i class="fa fa-magnifying-glass-dollar fa-lg fa-fw"></i>
                Información fiscal
              </div>

              <component-ui-combo-box [label]="'Código agrupador'" formControlName="codigoAgrupdor" [tabIndex]="12"
                [widthList]="400" entityName="c_CodigoAgrupador" [widthTextColumns]="2" [widthLabelColumns]="4"
                [widthRightColumns]="6" (onSelectedItem)="selectedObjeto($event, 'codigoAgrupdor')"
                helpLine="Seleccione el Código agrupador del SAT que le corresponde." [listProperty]="'ClaveNombre'" />

              <component-ui-check-box [tabIndex]="13" [widthLabelColumns]="4"
                helpLine="No muestra esta Cuenta en Contabilidad Electrónica."
                formControlName="excluirContabilidadElectronica" [label]="'Excluir de electrónica'" />

            </div>
          </app-panel-base>

        </div>
      </div>
      <ng-template let-modal let-c="close" #modalRubro>
        <app-seleccionar-tree [type]="'Rubro'" #modalRubro />
      </ng-template>
    </form>
  </div>
</app-container-base>
