<app-container-base (onClickBarButton)="onClickBarButton($event)" [setIsNew]="getIsNew" [activeButtons]="activeButtons"
  subTitle="Configuración de Conciliación" [hasCounter]="false" icon=" fas fa-bars-staggered">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Información para conciliar">
        <div class="panel-content">
          <div class="tab-pane fade active show" role="tabpanel">
            <h3 class="mt-10px"><i class="fa fa-calendar-check green"></i>
              Configuración de Conciliación</h3>
            <p>Esta configuración aplica para la Empresa/Usuario conectados actualmente, cada vez que se va conciliar
              una cuenta distinta,
              hay cambiar la configuración en este apartado, solo aplica para el Usuario actual.</p>
          </div>
          <hr>
          <div class="row">
            <div class="col-md-4">
              <components-ui-date-box helpLine="Fecha de inicio para conciliar" label='Fecha Del'
                formControlName="FechaInicio" [tabIndex]="1" [widthLabelColumns]="6" [widthTextColumns]="3"
                [required]="true" #txtFechaInicio></components-ui-date-box>
            </div>
            <div class="col-md-4">
              <components-ui-date-box label='Al' formControlName="FechaFin" [tabIndex]="2" [widthLabelColumns]="3"
                helpLine="Fecha fin para conciliar" [widthTextColumns]="3" [required]="true"></components-ui-date-box>
            </div>
          </div>
          <hr>
          <div class="row">
            <div class="col-md-12" style="padding-left:0px;">
              <div class="card bg-success border-0 text-white">
                <div class="card-body">
                  <h4 class="card-title">Seleccione una Cuenta de Bancos</h4>
                  <p class="card-text">{{getAccountName}}</p>
                  <div (click)="abrirSelecionarCuenta()" class="btn btn-sm btn-primary">Seleccionar Cuenta de Banco
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr>
          <div *ngIf="myForm.value.Usuario" class="widget-list mb-0" style="width: 100%; display: flex;">
            <div class="widget-list-item" style="width:25%;">
              <div class="widget-list-media">
                <i class="fas fa-user-check fa-2x text-green"></i>
              </div>
              <div class="widget-list-content">
                <h4 class="widget-list-title">{{myForm.value.Usuario.Nombre}}</h4>
                <p class="widget-list-desc">Usuario a conciliar</p>
              </div>
            </div>
          </div>
        </div>
      </app-panel-base>
      <ng-template let-modal let-c="close" #seleccionarFamilia>
        <app-seleccionar-tree [type]="'CuentaContable'" #seleccionarFamilia />
      </ng-template>

      <ng-template let-modal let-c="close" #ctrlOpciones>
        <div class="card text-center border-0" cdkDrag
          style="width: 540px;box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;">
          <div style="cursor: move;" cdkDragHandle class="card-header fw-bold">Seleccione el siguiente paso </div>
          <app-panel-base title="¿Desea abrir una de las siguientes opciones?">
            <div class="panel-content">
              <div class="list-group list-group-flush">
                <a (click)="closeOpciones(1)"
                  class="list-group-item list-group-item-action d-flex justify-content-between align-items-center text-ellipsis  rounded-bottom">
                  Estados de cuenta para conciliar
                </a>
                <a (click)="closeOpciones(3)"
                  class="list-group-item list-group-item-action d-flex justify-content-between align-items-center text-ellipsis rounded-bottom">
                  Conciliación manual
                </a>
                <!-- <a (click)="closeOpciones(4)"
                  class="list-group-item list-group-item-action d-flex justify-content-between align-items-center text-ellipsis rounded-bottom">
                  Resumen de conciliación
                </a> -->
              </div>
              <div style="text-align: right; margin-top: 5px;">
                <div (click)="closeOpciones(5)" class="btn btn-danger">Cerrar</div>
              </div>
            </div>
          </app-panel-base>
        </div>
      </ng-template>
    </form>
  </div>
</app-container-base>
