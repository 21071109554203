import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, inject } from "@angular/core";
import { ConceptoOrdenManufactura } from "src/app/Manufactura/interfaces/manufacura.interface";
import { EmpresaSucursalDto, Result, UserLogged } from "src/app/auth/interfaces";
import { ComboBoxComponent } from "src/app/component-ui/components/combo-box/combo-box.component";
import { TextBoxComponent } from "src/app/component-ui/components/text-box/text-box.component";
import { ComboBoxEntity, Coordinates } from "src/app/component-ui/interfaces/combo-text.interface";
import { EventsService } from "src/app/service/events.service";
import { InventarioService } from "../../services/inventario.service";
import { ContainerBaseService } from "src/app/component-ui/services/container-base.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { UtilsService } from "src/app/service/utils.service";
import { ModalService } from "src/app/service/modal.service";
import { ReportsService } from "src/app/service/reports.service";
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from "src/environments/environment";
import { SerieTraspaso, Traspaso, TraspasoDetalle } from '../../interfaces/traspaso.interface';
import { SearchConfiguration } from "src/app/service/interfaces/data-search.interface";
import { Producto, ProductoUnidad } from "../../interfaces/producto.interface";
import { FilterOptions, ReportFilter, ReportHeader, TypeFilter } from "src/app/component-ui/interfaces/selection-filter.interface";
import { Empresa } from "src/app/configuracion/interfaces/empresa.interface";
import { Sucursal } from "src/app/configuracion/interfaces/sucursal.interface";
import { Usuario } from "src/app/configuracion/interfaces/usuario.interface";
import Swal from "sweetalert2";
import { ActiveButtons } from "src/app/component-ui/interfaces/container-base.interface";
import { Observable } from "rxjs";
import * as moment from 'moment';
import { AlertResponse } from "src/app/component-ui/interfaces/alert.interface";
import { FacturacionService } from "src/app/ventas/services/facturacion.service";
import { DateBoxComponent } from "src/app/component-ui/components/date-box/date-box.component";
import { ExistenciaProductoPaquete } from "src/app/ventas/interfaces/venta.interface";


@Component({
  selector: 'app-traspaso-almacenes-page',
  templateUrl: './traspaso-almacenes-page.component.html',
})
export class TraspasoAlmacenesPageComponent implements OnInit, AfterViewInit {



  //referencia a elementos html de la pantalla
  @ViewChild('almacenSalida')
  public almacenSalida!: ElementRef<any>;
  @ViewChild('cboSerieAlm')
  public cboSerie!: ElementRef<ComboBoxComponent>;
  @ViewChild('txtFolioTrasAlm')
  public txtFolioTrasAlm!: ElementRef<TextBoxComponent>;
  @ViewChild('txtDescription')
  public txtDescription!: ElementRef<HTMLElement>;
  @ViewChild('busquedaProductosTraspasos')
  public ctrlBusquedaProductos!: ElementRef<HTMLElement>;

  @ViewChild('busquedaProductosEntradaTraspasos')
  public ctrlBusquedaProductosEntrada!: ElementRef<HTMLElement>;

  @ViewChild('ctrlConversiones')
  public ctrlConversiones!: ElementRef<HTMLElement>;

  @ViewChild('almacenDestino')
  public almacenDestino!: ElementRef<HTMLElement>;

  @ViewChild('modalCancelTraspasoAlmacenes')
  public modalCancelTraspasoAlmacenes!: ElementRef<HTMLElement>;

  @ViewChild('txtFechaEmisionTrasAlm')
  public txtFechaEmisionTrasAlm!: ElementRef<DateBoxComponent>;

  @ViewChild('ctrlExistenciasTrasAlm')
  public ctrlExistenciasTrasAlm!: ElementRef<HTMLElement>;









  //  variables globales del usuario
  info: UserLogged = {} as UserLogged;
  empresaActual: EmpresaSucursalDto | undefined = {} as EmpresaSucursalDto;
  sucursalActual: EmpresaSucursalDto | undefined = {} as EmpresaSucursalDto;
  //usuarioActual: UserLogged = {} as UserLogged;
  listaSeries: ComboBoxEntity[] = [];
  listaConceptos: ConceptoOrdenManufactura[] = [];
  serieActual: ComboBoxEntity = {} as ComboBoxEntity;
  noVerCostos: boolean = true;
  private readonly baseUrl: string = environment.baseUrlApi;
  usarSegundoNombreProducto: boolean = false;
  mascara: string = "";

  //* Injects
  private eventsService = inject(EventsService);
  private invService = inject(InventarioService);
  private cService = inject(ContainerBaseService);
  private fb = inject(FormBuilder);
  private utilsService = inject(UtilsService);
  private ms = inject(ModalService);
  private reportsService = inject(ReportsService)
  private http = inject(HttpClient);
  //private cd = inject(ChangeDetectorRef)



  //* Variables para el funcionamiento del grid
  source: Array<TraspasoDetalle> = [];
  showDescription: boolean = false;
  loading: boolean = false;
  sourceProducts: any[] = [];
  sourceProductsEntrada: any[] = [];
  searchConfigurationCliente: SearchConfiguration | null = null;
  searchConfigurationProducto: SearchConfiguration | null = null;
  indexEditing: number = -1;
  columnEditing: number = -1;
  navigateColumns: boolean = false;
  rowEditandoDescricionAdic: number = 0;
  colCantidad: number = 4;
  colProducto: number = 1;
  colProductoEntrada: number = 2;
  colDecripcion: number = 3;
  //editarProductoEquivalente: boolean = true;

  esEscapeProducto: boolean = false;
  esEscapeProductoEntrada: boolean = false;
  esEscapeCantidad: boolean = false;
  esEnterProducto: boolean = false
  esEnterProductoEntrada: boolean = false
  enviarFocoProducto: boolean = false
  enviarFocoProductoEntrada: boolean = false
  enviarFocoCantidad: boolean = false
  enviarOtroRenglon: boolean = false;
  cords: any = null;
  idUnico: string = '';
  selectedIndex: number = -1;






  //*control de flujo de la pantalla
  saving: boolean = false;
  existencia: number = 0;
  conversiones: ProductoUnidad[] = [];
  unidadFinal: string = '';
  abrirBusqueda: boolean = false;
  totalCantidad: number = 0;
  totalRegistros: number = 0;
  totalCosto: number = 0;



  public filtroTraspasos: ReportFilter =
    {
      ReportHeader: {} as ReportHeader,
      TituloVisor: 'Documento de inventario',
      NombreExcel: '',
      FilterOptions: [
        { Campo: 'movto.Id', Etiqueta: '', Tipo: TypeFilter.number },
      ]
    };




  public myForm: FormGroup = this.fb.group({
    Id: [0],
    Nombre: [''],
    Empresa: [{} as Empresa],
    Sucursal: [{} as Sucursal],
    Clave: [''],
    Folio: [0],
    Serie: [{} as SerieTraspaso],
    SerieString: [''],
    SucursalDestino: [{} as Sucursal],
    AlmacenSalida: [null],
    AlmacenDestino: [null],
    FechaEmision: [new Date()],
    FechaEmisionString: [new Date()],
    FechaCancelacionString: [''],
    FechaElabora: [new Date()],
    FechaRecepcion: [null],
    FechaCancelacion: [null],
    MotivoCancelacion: [''],
    Comentarios: [''],
    RecibidoEnDestino: [false],
    EsEntreAlmacenes: [false],
    EsEntreSucursales: [false],
    Total: [0],
    UsuarioRecibe: [{} as Usuario],
    UsuarioElabora: [null],
    UsuarioCancela: [{} as Usuario],
    Conceptos: [[] as TraspasoDetalle[]],
    DescripcionAdicional: [''],
    Eliminado: [false],
    Baja: [false],
  })




  constructor(private cb: ContainerBaseService, private fServices: FacturacionService) {
  }

  ngAfterViewInit(): void {
    let elem: Traspaso = this.myForm.value;
    elem.Empresa = { Id: this.empresaActual?.numero!, Clave: this.empresaActual?.clave!, Nombre: this.empresaActual?.nombre! } as Empresa;
    elem.Sucursal = { Id: this.sucursalActual?.numero!, Clave: this.sucursalActual?.clave, Nombre: this.sucursalActual?.nombre } as Sucursal;
    elem.FechaEmision = new Date();
    setTimeout(() => {
      this.myForm.reset(elem);
      const txtctrl: any = this.cboSerie;
      txtctrl.tagInput.nativeElement.focus();
    }, 150);
  }




  ngOnInit(): void {
    this.info = this.utilsService.getUserLogged();
    this.idUnico = String(new Date().getTime() * 10000);
    this.empresaActual = this.info.empresa;
    this.sucursalActual = this.info.sucursal;
    this.searchConfigurationProducto = this.ms.GetSearchConfiguration("Producto", `Empresa.Id = ${this.empresaActual?.numero}`);
    this.fServices.obtenerMascaraProducto(this.info.empresa!.numero).subscribe((result) => { this.mascara = result.message });
    this.sourceReset();


    this.cb.obtenerValorPropiedad("Usuario", "Rol.NoVerCostos", this.info.numero).subscribe((value) => {
      this.noVerCostos = value.toLowerCase() == "true";
    });

    this.cb.obtenerValorPropiedad("Sucursal", "UsarSegundoNombreProducto", this.sucursalActual?.numero!).subscribe((value) => {
      this.usarSegundoNombreProducto = value.toLowerCase() == "true";
    });



  }

  //* 1.- metodos de inicializacion de la pantalla

  get getExtras() {
    return " Serie + String(' ', 1) + Nombre as SerieNombre, Serie";
  }

  // get getEditarEquivalente() {
  //   //return this.editarProductoEquivalente;
  //   //return true;
  // }


  //*2.- inicializacion del grid y validaciones para permitir o no mover algo del grid.
  changueDescription(i: number, item: TraspasoDetalle) {
    if (this.myForm.value.Id > 0) {
      return;
    }
    if (item.Producto?.ActualizarDescrpcion) {
      this.rowEditandoDescricionAdic = i;
      this.indexEditing = i;
      this.showDescription = true;
      this.myForm.get("DescripcionAdicional")?.setValue(item.Descripcion);
      setTimeout(() => {
        this.txtDescription.nativeElement.focus();
      }, 150);
    }
  }

  get permiteEliminarDelGrid() {
    return !(this.myForm.value.Id > 0);
  }

  get permiteEditarDelGrid() {
    return !(this.myForm.value.Id > 0);
  }




  get getProductsColumns(): any {
    if (this.searchConfigurationProducto) {
      return this.searchConfigurationProducto.columns;
    }
    return [];
  }

  get getFilter(): any {
    if (this.searchConfigurationCliente) {
      return this.searchConfigurationCliente.filter;
    }
    return "";
  }

  get getNoVerCostos() {
    return this.noVerCostos
  }

  get getProductsPropertys(): string {
    if (this.searchConfigurationProducto) {
      let props = "";
      this.searchConfigurationProducto.propertys.forEach((prop) => {
        props += `${prop.name}|${prop.type},`
      })

      if (props.length > 0) {
        props = props.substring(0, props.length - 1);
        return props;
      }
    }
    return "";
  }


  // *2.- metodos de cambio para los campos principales

  selectAlmacenSalida(entity: ComboBoxEntity) {
    this.myForm.controls["AlmacenSalida"].setValue(entity);
    setTimeout(() => {
      const almDestino: any = this.almacenDestino;
      almDestino.tagInput.nativeElement.focus();
    }, 100);
  }

  selectSerie(entity: ComboBoxEntity) {
    this.myForm.controls["Serie"].setValue(entity);
    this.myForm.controls["SerieString"].setValue(entity.Serie);
    this.serieActual = entity;
    const ent = this.myForm.value;
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.getNextFolio(ent).subscribe(folio => {
      ent.Folio = folio;
      this.setEntity(ent);
      setTimeout(() => {
        this.fServices.ObtenerFechaPorSucursal(ent.Sucursal.Id).subscribe((result) => {
          const f = result.message.split('T')[0].split('-');
          const t = result.message.split('T')[1].split(':');
          ent.FechaEmision = new Date(parseInt(f[0]), parseInt(f[1]) - 1, parseInt(f[2]), parseInt(t[0]), parseInt(t[1]), parseInt(t[2]));
          this.myForm.controls["FechaEmision"].setValue(ent.FechaEmision);
          const txtFolio: any = this.txtFolioTrasAlm;
          txtFolio.tagInput.nativeElement.focus();
              this.eventsService.publish('home:isLoading', { isLoading: false });
        })
      }, 250);
    });
  }



  openExistencias() {
    if (this.source[this.indexEditing].Producto?.Inventariable) {
      const b: any = this.ctrlExistenciasTrasAlm;
      this.ms.openModal(b, (e: ExistenciaProductoPaquete | null) => {
        setTimeout(() => {
          this.initEditor(this.indexEditing, this.colCantidad);
        }, 50);
      }, 'xl');
    } else {
      this.eventsService.publish('home:showAlert', { message: "El Producto no es inventariable.", cancelButton: false });
    }
  }



  enterFecha(e: any) {
    e.preventDefault();
    if (this.myForm.value.Id == 0) {
      this.initEditor(0, 1);
    }
  }

  tabFecha(e: any) {
    e.preventDefault();
    if (this.myForm.value.Id == 0) {
      this.initEditor(0, 1);
    }
  }


  selectAlmacenDestino(entity: ComboBoxEntity) {
    this.myForm.controls["AlmacenDestino"].setValue(entity);
  }

  get getFiltroDestino(): string {
    let filtro: string = "";
    if (this.myForm.value.AlmacenSalida && this.myForm.value.AlmacenSalida.Id) {
      return ` Id != ${this.myForm.value.AlmacenSalida.Id} `;
    }
    return filtro;
  }

  get getFiltroSalida(): string {
    let filtro: string = "";
    if (this.myForm.value.AlmacenDestino && this.myForm.value.AlmacenDestino.Id) {
      return ` Id != ${this.myForm.value.AlmacenDestino.Id} `;
    }
    return filtro;
  }

  get esNuevo() {
    return this.myForm.value.Id == 0;
  }


  //Proceso de Cancelacion del traspaso

  openCancel() {
    const b: any = this.modalCancelTraspasoAlmacenes;
    this.ms.openModal(b, (e: any) => {
      if (e) {
        this.proceedCancel(e)
      }
    })
  }



  get getCancelDate(): string {
    if (this.myForm.value.FechaCancelacion) {
      return moment(this.myForm.value.FechaCancelacion).format("DD/MM/YYYY");
    }
    return "";
  }



  proceedCancel(e: any) {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    const date = moment(e.Fecha).format("YYYY-MM-DDTHH:mm:ss");
    const params = new HttpParams()
      .set("id", this.myForm.value.Id)
      .set("motivoCancelacion", e.MotivoCancelacion)
      .set("fechaCancelacion", date);
    return this.http.get<Result>(`${this.baseUrl}/Inventarios/CancelarTraspaso`, { params }).subscribe((result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result.success) {
        this.findEntityByParams()
      } else {
        this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
      }
    });
  }

  //* 4.- metodos de control de flujo del grid flujogrid
  //*Columna 1 Producto col1

  escapeProducto(e: any) {
    let index = this.indexEditing;
    this.esEscapeProducto = true;
    let item: TraspasoDetalle = this.source[index];
    e.target.value = item.Producto?.Clave ? item.Producto?.Clave : "";
    setTimeout(() => {
      this.esEscapeProducto = true;
      const item = { ...this.source[index] };
      if (!item.Producto) {
        e.target.value = "";
      } else {
        e.target.value = item.Producto.Clave;
      }
      if (index > 0) {
        this.indexEditing = index - 1;
        this.enviarFocoProducto = true;
        this.sendFocus();
      } else {
        const txt: any = this.txtFechaEmisionTrasAlm;
        txt.tagInput.nativeElement.focus()
      }
    }, 50);
  }
  focusProducto(index: number, e: any, item: TraspasoDetalle) {

    let c = this.getLastItem();
    if (index > 0 && index > (c)) {
      return;
    }
    this.setIndexEdit(index, e, item);
    if (c == 0 && index > 0) {
      e.target.blur();
      return;
    }
    if ((index >= c + 1) && c > 0) {
      e.target.blur();
    }
  }


  focusProductoEntrada(index: number, e: any, item: TraspasoDetalle) {
    let c = this.getLastItem();
    if (index > 0 && index > (c)) {
      return;
    }
    if (!item.AceptaEquivalente) {
      this.esEscapeProductoEntrada = true;
      e.target.blur();
      return;
    }

    this.setIndexEdit(index, e, item);

    if (c == 0 && index > 0) {
      e.target.blur();
      return;
    }

    if ((index >= c + 1) && c > 0) {
      e.target.blur();
    }
  }


  enterProducto(e: any) {
    this.esEnterProducto = true;
    this.enviarFocoCantidad = true;
    let value = '';
    if (this.sourceProducts.length > 0) {
      value = this.sourceProducts[this.selectedIndex].Clave;
    } else {
      value = e.target.value;
    }
    e.target.value = value;
    if (value) {
      e.target.blur();
    }
  }

  enterProductoEntrada(e: any) {
    this.esEnterProductoEntrada = true;
    this.enviarFocoCantidad = true;
    let value = '';
    if (this.sourceProductsEntrada.length > 0) {
      value = this.sourceProductsEntrada[this.selectedIndex].Clave;
    } else {
      value = e.target.value;
    }
    e.target.value = value;
    if (value) {
      e.target.blur();
    }
  }

  blurProducto(e: any, index: number) {
    //let index = this.indexEditing;
    e.target.classList.remove("focus-editor-grid");


    setTimeout(() => {
      this.cords = null;
      if (this.esEscapeProducto) {
        this.esEnterProducto = false;
        this.esEscapeProducto = false;
        return;
      }
      let c = this.getLastItem();
      if (index > c) {
        return;
      }
      let item: TraspasoDetalle = { ...this.source[index] };
      if (item.Producto) {
        if (!e.target.value) {
          e.target.value = item.Producto.Clave;
          this.enviarFocoProducto = true;
          this.sendFocus();
          return;
        }
        if (item.Producto.Clave != e.target.value) {
          this.searchProduct(e.target.value);
        } else {
          if (this.esEnterProducto) {
            if (item.AceptaEquivalente) {
              this.enviarFocoProductoEntrada = true;
              this.enviarFocoCantidad = false;
              this.sendFocus();
            } else {
              if (this.enviarFocoCantidad) {
                this.sendFocus();
              }
            }
          } else {
            if (this.enviarOtroRenglon) { //*aplica para el renglon 1
              this.sendFocus();
            }
          }
        }
      } else {
        if (e.target.value != "") {
          this.searchProduct(e.target.value);
        } else {
          e.target.classList.remove("focus-editor-grid");
        }
      }
      this.esEnterProducto = false;
      this.esEscapeProducto = false;
    }, 50);
  }


  blurProductoEntrada(e: any, index: number) {
    //let index = this.indexEditing;
    e.target.classList.remove("focus-editor-grid");
    setTimeout(() => {
      this.cords = null;
      if (this.esEscapeProductoEntrada) {
        this.esEnterProductoEntrada = false;
        this.esEscapeProductoEntrada = false;
        return;
      }
      let item = { ...this.source[index] };
      let c = this.getLastItem();
      if (index > c) {
        return;
      }
      if (item.ProductoEntrada) {
        if (!e.target.value) {
          e.target.value = item.ProductoEntrada.Clave;
          this.enviarFocoProductoEntrada = true;
          this.sendFocus();
          return;
        }

        if (item.ProductoEntrada.Clave != e.target.value) {
          this.searchProductEntrada(e.target.value);
        } else {
          if (this.enviarFocoCantidad) {
            this.sendFocus();
          }
        }
      } else {
        this.searchProductEntrada(e.target.value);
      }
      this.esEnterProductoEntrada = false;
      this.esEscapeProductoEntrada = false;
    }, 100);
  }


  buscarProducto(e: any) {
    if (e.target.value == "" || !isNaN(e.target.value.trim().replace(/\s/g, ""))) {
      this.cords = null;
      this.sourceProducts = [];
      return;
    }

    this.sourceProductsEntrada = [];
    if (this.selectedIndex == -1) {
      this.selectedIndex = 0;
    }
    let row = null;
    if (e.keyCode == "38") {
      e.preventDefault();
    }

    if (e.keyCode == "40") {
      e.preventDefault();
    }
    if (e.keyCode == "27" || e.keyCode == "37" || e.keyCode == "39" || e.keyCode == "38" || e.keyCode == "40") {
      return;
    }
    let cords = this.getOffset(e.target);
    cords.top = cords.top - 400;
    cords.left = cords.left - 290;
    this.cords = cords;
    //todo si no ha seleccionado el tipode movimineto o el folio
    this.fServices.busquedaProductos(e.target.value, 0).subscribe((result) => {
      const lista = JSON.parse(result.message);
      this.sourceProducts = lista;
      if (this.sourceProducts.length > 0) {
        this.selectedIndex = 0;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`);
        if (row) {
          row.scrollIntoView({ block: "center" });
        }

      } else {
        this.selectedIndex = -1;
      }
    });
  }

  clicTablaProducto(e: any, item: any) {
    const t: any = document.activeElement;
    t.value = item.Clave;
    const input: any = document.getElementById(`txt_${this.indexEditing}_2${this.idUnico}`)!;
    if (input) {
      input.value = item.Clave;
      this.enviarFocoCantidad = true;
    }
  }



  navegarProductos(e: any) {
    let row = null;
    if (e.keyCode == 40) {
      if (this.selectedIndex + 1 < this.sourceProducts.length) {
        this.selectedIndex++;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`)
        row!.scrollIntoView({ block: "center" });
      }
    } else if (e.keyCode == 38) {
      if (this.selectedIndex > 0) {
        this.selectedIndex--;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`)
        row!.scrollIntoView({ block: "center" });
      }
    }
  }


  //*Columna 2 ProductoEntrada col2

  escapeProductoEntrada(e: any) {
    let index = this.indexEditing;
    this.esEscapeProductoEntrada = true;
    let item: TraspasoDetalle = this.source[index];
    e.target.value = item.ProductoEntrada?.Clave ? item.ProductoEntrada?.Clave : "";
    this.initEditor(this.indexEditing, 1);
  }


  buscarProductoEntrada(e: any) {
    if (e.target.value == "" || !isNaN(e.target.value.trim().replace(/\s/g, ""))) {
      this.cords = null;
      this.sourceProductsEntrada = [];
      return;
    }
    this.sourceProducts = [];



    if (this.selectedIndex == -1) {
      this.selectedIndex = 0;
    }
    let row = null;
    if (e.keyCode == "38") {
      e.preventDefault();
    }

    // if (e.keyCode == "40") {
    //   e.preventDefault();
    //   if (this.selectedIndex < this.sourceProducts.length - 1) {
    //     this.selectedIndex = this.selectedIndex + 1;
    //     row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`)
    //   }
    // }
    // if (row) {
    //   row.scrollIntoView({ block: "center" });
    // }
    if (e.keyCode == "27" || e.keyCode == "37" || e.keyCode == "39" || e.keyCode == "38" || e.keyCode == "40") {
      return;
    }
    let cords = this.getOffset(e.target);
    cords.top = cords.top - 400;
    cords.left = cords.left - 290;
    this.cords = cords;

    //todo si no ha seleccionado el tipode movimineto o el folio
    //if (!this.myForm.value.Cliente) { return }
    this.fServices.busquedaProductos(e.target.value, 0).subscribe((result) => {
      const lista = JSON.parse(result.message);
      this.sourceProductsEntrada = lista;
      if (this.sourceProductsEntrada.length > 0) {
        this.selectedIndex = 0;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`);
        if (row) {
          row.scrollIntoView({ block: "center" });
        }

      } else {
        this.selectedIndex = -1;
      }
    });
  }



  navegarProductosEntrada(e: any) {
    let row = null;
    if (e.keyCode == 40) {
      if (this.selectedIndex + 1 < this.sourceProducts.length) {
        this.selectedIndex++;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`)
        row!.scrollIntoView({ block: "center" });
      }
    } else if (e.keyCode == 38) {
      if (this.selectedIndex > 0) {
        this.selectedIndex--;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`)
        row!.scrollIntoView({ block: "center" });
      }
    }
  }

  //*Columna 4 Canridad col4


  escapeCantidad(e: any) {
    this.esEscapeCantidad = true;
    let item: TraspasoDetalle = this.source[this.indexEditing];
    e.target.value = item.Cantidad ? item.Cantidad : 0;
    if (item.AceptaEquivalente) {
      this.initEditor(this.indexEditing, 2);
    } else {
      this.initEditor(this.indexEditing, 1);
    }
  }

  enterCantidad(e: any) {
    if (e.target.value == "" || parseInt(e.target.value) == 0) {
      return;
    }
    this.enviarOtroRenglon = true;
    e.target.blur();
  }

  blurCantidad(e: any) {
    e.target.classList.remove("focus-editor-grid");
    if (this.esEscapeCantidad) {
      this.esEscapeCantidad = false;
      return;
    }
    if (e.target.value == "" || parseInt(e.target.value) == 0 && !this.enterCantidad) {
      e.target.classList.remove("focus-editor-grid");
      return;
    }
    const cantidad = parseFloat(e.target.value);
    let item = this.source[this.indexEditing];
    if (parseFloat(String(item.Cantidad)) == cantidad) {
      if (this.enviarOtroRenglon) {
        this.sendFocus();
      }
      return;
    };
    if (item.Conversiones) {
      if (item.Conversiones.length > 0) {
        let totalEnConversiones = 0;
        item.Conversiones.forEach((i) => totalEnConversiones += i.CantidadUnidadConcepto);
        if (cantidad != totalEnConversiones) {
          item.Cantidad = totalEnConversiones;
        } else {
          item.Cantidad = cantidad;
        }
      } else {
        item.Cantidad = cantidad;
        item.Conversiones = [];
        item.TextoConversiones = "";
        //this.enviarOtroRenglon = true;
      }
    } else {
      item.Cantidad = cantidad;
      item.Conversiones = [];
      item.TextoConversiones = "";
      //this.enviarOtroRenglon = true;
    }
    this.source[this.indexEditing] = { ...item };
    this.source = [...this.source];
    this.sendFocus();
  }

  //* Mertodos genericos de Control de flujo del grid

  keyDownRow(e: any, index: number) {
    if (e.ctrlKey && e.keyCode == "46") {
      e.preventDefault();
      this.deleteRow(index);
    }
  }

  keyDownCantidad(e: any) {
    const noDecimales: boolean = this.source[this.indexEditing].Producto!.NoDecimalesCantidad;
    if (e.key === '.' && noDecimales) { e.preventDefault(); }
  }

  getOffset(elem: HTMLInputElement): Coordinates {
    var box = elem.getBoundingClientRect();
    var left = window.scrollX !== undefined ? window.scrollX :
      (document.documentElement || document.body.parentNode || document.body).scrollLeft;
    var top = window.scrollY !== undefined ? window.scrollY :
      (document.documentElement || document.body.parentNode || document.body).scrollTop;

    top += elem.offsetHeight;
    return { left: box.left + left, top: box.top + top };
  }

  setIndexEdit(index: number, e: any, item: TraspasoDetalle) {

    if (!this.myForm.value.AlmacenSalida.Id) {
      this.eventsService.publish('home:showAlert', {
        textAccept: "Aceptar",
        cancelButton: false,
        message: 'Favor de indicar el Almacén de Salida.',
        onConfirm: (data: AlertResponse) => {
          this.indexEditing = -1;
          const txt: any = this.almacenSalida;
          txt.tagInput.nativeElement.focus()
          return;
        }
      });
      return;
    }

    if (this.myForm.value.Codigo == 0) {
      this.eventsService.publish('home:showAlert', {
        textAccept: "Aceptar",
        cancelButton: false,
        message: 'Favor de indicar el Folio.',
        onConfirm: (data: AlertResponse) => {
          this.indexEditing = -1;
          const txt: any = this.txtFolioTrasAlm;
          txt.tagInput.nativeElement.focus()
          return;
        }
      });
      return;
    }


    if (!this.myForm.value.AlmacenDestino.Id) {
      this.eventsService.publish('home:showAlert', {
        textAccept: "Aceptar",
        cancelButton: false,
        message: 'Favor de indicar el Almacén Destino.',
        onConfirm: (data: AlertResponse) => {
          this.indexEditing = -1;
          const txt: any = this.almacenDestino;
          txt.tagInput.nativeElement.focus()
          return;
        }
      });
      return;
    }

    //todo revisar este codigo y ver como aplica con el nuevo grid
    if (!this.permiteEliminarDelGrid) {
      return;
    }


    //* si esta cancelado, no deberia de poder editar algo.
    if (this.myForm.value.FechaCancelacion) {
      this.indexEditing = -1;
      e.target.blur();
      return;
    }

    if (this.indexEditing == 3) {
      let aa = 0;
    }

    this.indexEditing = index;
    e.target.select();
    e.target.classList.add("focus-editor-grid");
  }






  up(e: any, celda: number) {
    e.preventDefault();
    if (celda == 0 && this.sourceProducts.length > 0) {
      return;
    }

    if (this.indexEditing > 0) {
      if (celda == 0) {
        this.esEscapeProducto = true;
      }
      e.target.blur();
      setTimeout(() => {
        this.indexEditing = this.indexEditing - 1;
        this.initEditor(this.indexEditing, celda + 1);
        this.esEscapeProducto = false;
      }, 50);
    } else {
      this.initEditor(this.indexEditing, 1);
    }
  }


  down(e: any, celda: number) {
    e.preventDefault();
    if (celda == 0 && this.sourceProducts.length > 0) {
      return;
    }

    let c = this.getLastItem();
    if (this.indexEditing < c - 1) {
      this.enviarOtroRenglon = false;
      e.target.blur();
      setTimeout(() => {
        this.indexEditing = this.indexEditing + 1;
        this.initEditor(this.indexEditing, celda + 1);
      }, 50);
    } else {
      if (this.indexEditing + 1 < c + 1) {
        this.enviarOtroRenglon = true;
        e.target.blur();
      }
    }
  }

  getLastItem(): number {
    return this.source.filter(P => P.Producto && P.Cantidad > 0).length;
  }

  sendFocus() {

    setTimeout(() => {
      if (this.enviarFocoCantidad) {
        this.initEditor(this.indexEditing, this.colCantidad);
        this.enviarFocoCantidad = false;
      }

      if (this.enviarFocoProducto) {
        this.initEditor(this.indexEditing, this.colProducto);
        this.enviarFocoProducto = false;
      }

      if (this.enviarFocoProductoEntrada) {
        this.initEditor(this.indexEditing, this.colProductoEntrada);
        this.enviarFocoProductoEntrada = false;
      }

      if (this.enviarOtroRenglon) {
        this.indexEditing = this.indexEditing + 1;
        this.initEditor(this.indexEditing, 1);
        console.log("send " + this.indexEditing)
        this.enviarOtroRenglon = false;
      }
    }, 50);
  }





  sourceReset(conceptos: any = []) {

    if (!conceptos) {
      this.source = [];
    } else {
      this.source = conceptos;
    }

    this.source!.map((item: TraspasoDetalle) => {
      if (item.ProductoEntrada) {
        item.NoIdentificacionEntrada = item.ProductoEntrada.Clave;
        item.AceptaEquivalente = true;
      } else {
        item.NoIdentificacionEntrada = '';
        item.AceptaEquivalente = false;
      }

    });

    for (let index = 0; index < 100; index++) {
      this.source = [...this.source, this.estructuraVacia()]
    }
  }

  estructuraVacia(): any {
    return {
      Id: 0,
      Producto: null,
      ProductoEntrada: null,
      NoIdentificacion: '',
      NoIdentificacionEntrada: '',
      Cantidad: 0,
      CantidadRestante: 0,
      CantidadEntregada: 0,
      Unidad: null,
      Descripcion: '',
      DescripcionConvesion: '',
      Costo: 0,
      Total: 0,
      TextoConversiones: '',
      UnidadNombre: '',
      Conversiones: [],
      AceptaEquivalente: false
    }
  }



  conversionClick(index: number) {
    if (this.myForm.value.Id > 0) {
      return;

    }
    this.indexEditing = index;
    let item = this.source[this.indexEditing];
    this.conversiones = item.Producto!.OtrasUnidades;
    this.unidadFinal = item.UnidadNombre;
    this.openConvertions((e: any) => this.aceptConversions(e, true));
  }

  deleteRow(indx: number) {
    if (this.myForm.value.Id > 0) {
      this.eventsService.publish('home:showAlert', { message: "El Traspaso no puede modificarse, proceda a Cancelar.", cancelButton: false });
      return;
    }

    this.eventsService.publish('home:showAlert', {
      message: '¿Desea eliminar el renglón?.',
      onConfirm: (r: AlertResponse) => {
        if (r.isAccept) {
          const idEliminar = this.source[indx].Id;
          if (idEliminar > 0) {
            this.eventsService.publish('home:isLoading', { isLoading: true });
            this.invService.eliminarTraspasoDetalle(idEliminar).subscribe((result) => {
              this.eventsService.publish('home:isLoading', { isLoading: false });
              this.source.splice(indx, 1);
              this.source = [...this.source];
              this.calcTotales();
              let ent: Traspaso = this.myForm.value;
              ent.Conceptos?.splice(indx, 1);
              this.myForm.reset(ent);
            })
          } else {
            this.source.splice(indx, 1);
            this.source = [...this.source];
            this.calcTotales();
            let ent: Traspaso = this.myForm.value;
            ent.Conceptos?.splice(indx, 1);
            this.myForm.reset(ent);
          }
        }
      }
    });
  }

  focoComentarios(value: any) {
    this.utilsService.setHelpLine("Indique un comentario para el movimiento de Traspaso.");
  }

  calcTotales() {
    let ent: Traspaso = this.myForm.value;
    ent.Total = 0;
    let totalConcepto: number = 0
    let count: number = 0;
    let regs: number = 0;

    this.source.map((concepto, index) => {
      if (concepto.Producto) {
        if (concepto.Producto.Id > 0) {
          concepto.Total = parseFloat((concepto.Cantidad * concepto.Costo).toFixed(6));
          concepto.CantidadRestante = concepto.Cantidad - concepto.CantidadEntregada;
          totalConcepto += concepto.Total;
          count += concepto.Cantidad;
          regs++;
        }
      }
    });

    //this.myFormTotales.get('TotalCosto')!.setValue(totalConcepto);
    this.totalCosto = totalConcepto;
    //this.myFormTotales.get('TotalRegistros')!.setValue(regs);
    this.totalRegistros = regs;
    //this.myFormTotales.get('TotalCantidad')!.setValue(count);
    this.totalCantidad = count
    ent.Total = totalConcepto;
    this.myForm.reset(ent);
    this.source = [...this.source];
  }

  initEditor(row: number, col: number) {
    col = col - 1;
    const input = document.getElementById(`txt_${row}_${col}${this.idUnico}`)!;
    if (input) input.focus();
  }

  openConvertions(cb: any) {
    const b: any = this.ctrlConversiones;
    this.ms.openModal(b, (e: any) => {
      cb && cb(e);
    })
  }

  aceptConversions(e: any, isclick: boolean = false) {
    this.conversiones = [];
    this.unidadFinal = '';
    let item = this.source[this.indexEditing];
    if (e) {
      if (e.cantidad > 0) {
        item.Conversiones = e.items;
        item.TextoConversiones = e.conversiones;
        item.Cantidad = e.cantidad;
        this.source = [...this.source];
        this.indexEditing++;
        !isclick && this.initEditor(this.indexEditing, this.colProducto);
      } else {
        item.Conversiones = [];
        item.TextoConversiones = '';
        !isclick && this.initEditor(this.indexEditing, this.colCantidad);
      }
      this.calcTotales();
    } else {
      this.source = [...this.source];
      !isclick && this.initEditor(this.indexEditing, this.colCantidad);
    }
  }


  //4.- matodos de consulta a el backend

  navigate(type: string) {
    this.findEntityByParamsNavigate(type);
  }

  findEntityByParamsNavigate(type: string) {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    const params = new HttpParams()
      .set("idEmpresa", this.myForm.value.Empresa.Id)
      .set("idSucursal", this.myForm.value.Sucursal.Id)
      .set("serie", this.myForm.value.SerieString)
      .set("folio", this.myForm.value.Folio)
      .set("tipo", type);
    this.http.get<Traspaso>(`${this.baseUrl}/Inventarios/ObtenerTraspasoPorParametrosNavegacion`, { params }).subscribe((traspaso) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      this.setEntity(traspaso);
    });
  }

  setEntity(traspaso: Traspaso) {
    if (!traspaso) {
      this.new();
    } else {
      let ent: Traspaso = this.myForm.value;

      ent.Empresa = { Id: ent.Empresa.Id } as Empresa
      ent.Serie = { Id: ent.Serie.Id } as SerieTraspaso
      ent.Sucursal = { Id: ent.Sucursal.Id } as Sucursal
      ent = { ...traspaso };
      this.sourceReset(ent.Conceptos);

      ent.Conceptos.map((item: TraspasoDetalle) => {
        if (item.Unidad) {
          item.UnidadNombre = item.Unidad!.Nombre;
        } else {
          item.UnidadNombre = '';
        }
      });
      this.calcTotales();
      this.myForm.reset(ent);

    }
  }

  new() {
    const elem: Traspaso = this.myForm.value;
    this.cService.getEmptyEntity("Traspaso").subscribe((ent) => {
      ent.Conceptos = [];
      this.sourceReset();
      ent.Empresa = { Id: this.info.empresa?.numero, Clave: this.info.empresa?.clave, Nombre: this.info.empresa?.nombre } as Empresa;
      ent.Sucursal = { Id: this.info.sucursal?.numero, Clave: this.info.sucursal?.clave, Nombre: this.info.sucursal?.nombre } as Sucursal;
      ent.FechaEmision = new Date();
      ent.Serie = { Id: this.serieActual.Id, Clave: this.serieActual.Clave, Nombre: this.serieActual.Nombre } as SerieTraspaso;;
      ent.SerieString = this.serieActual.Serie;
      this.getNextFolio(ent).subscribe(nuevoFolio => {
        ent.Folio = nuevoFolio;
        this.setEntity(ent);

        setTimeout(() => {
          this.fServices.ObtenerFechaPorSucursal(ent.Sucursal.Id).subscribe((result) => {
            const f = result.message.split('T')[0].split('-');
            const t = result.message.split('T')[1].split(':');
            ent.FechaEmision = new Date(parseInt(f[0]), parseInt(f[1]) - 1, parseInt(f[2]), parseInt(t[0]), parseInt(t[1]), parseInt(t[2]));
            this.myForm.controls["FechaEmision"].setValue(ent.FechaEmision);
            const txtAlmSalida: any = this.almacenSalida;
            txtAlmSalida.tagInput.nativeElement.focus();

          })
        }, 250);
      });
    });
  }


  getExistencia(producto: Producto, cb: any = null) {
    this.fServices.getExistencia(producto.Id, this.myForm.value.Sucursal.Id, producto.Almacen.Id).subscribe((existencia) => {
      this.existencia = existencia;
      setTimeout(() => {
        this.source[this.indexEditing].Existencia = existencia;
        this.source = [...this.source];
        if (cb) { cb(); }
      }, 150);
    });
  }


  getNextFolio(ent: Traspaso): Observable<any> {
    const params = new HttpParams().set("idEmpresa", ent.Empresa.Id)
      .set("idSucursal", ent.Sucursal.Id)
      .set("serie", ent.SerieString);
    return this.http.get<number>(`${this.baseUrl}/Inventarios/ObtenerSiguienteFolioTraspaso`, { params });
  }


  searchProductEntrada(value: string) {
    this.loading = true;
    value = this.fServices.aplciarMascara(value, this.mascara);
    const params = new HttpParams().set("idEmpresa", this.empresaActual!.numero).set("idSucursal", this.sucursalActual!.numero).set("idCliente", 0).set("clave", value);
    this.http.get<Producto>(`${this.baseUrl}/Ventas/ObtenerProducto`, { params }).subscribe((producto: Producto) => {
      this.loading = false;
      if (producto) {
        let item = this.source[this.indexEditing];
        item.ProductoEntrada = producto;
        item.NoIdentificacionEntrada = producto.Clave;
        this.source[this.indexEditing] = { ...item };
        this.source = [...this.source];
        this.enviarFocoCantidad = true;
        this.sendFocus();
      } else {
        this.eventsService.publish('home:showAlert', {
          message: "No se encontró el Producto Entrada indicado.", cancelButton: false, onConfirm: (data: AlertResponse) => {
            this.source[this.indexEditing].NoIdentificacionEntrada = "";
            this.source = [...this.source];
            this.enviarFocoProductoEntrada = true;
            this.sendFocus();
          }
        });
      }
    });
  }


  searchProduct(value: string) {

    this.loading = true;
    value = this.fServices.aplciarMascara(value, this.mascara);
    const params = new HttpParams().set("idEmpresa", this.empresaActual!.numero).set("idSucursal", this.sucursalActual!.numero).set("idCliente", 0).set("clave", value);
    this.http.get<Producto>(`${this.baseUrl}/Ventas/ObtenerProducto`, { params }).subscribe((producto: Producto) => {
      this.loading = false;
      if (producto) {
        if (!producto.Almacen) {
          this.eventsService.publish('home:showAlert', {
            message: 'El producto seleccionado no tiene configurado un Almacén, por favor verifique.',
            onConfirm: (r: AlertResponse) => {
              this.source[this.indexEditing].NoIdentificacion = "";
              this.source = [...this.source];
              this.initEditor(this.indexEditing, this.colProducto);
            }
          });
          return;
        }

        if (!producto.Inventariable) {
          this.eventsService.publish('home:showAlert', {
            message: 'El producto seleccionado no es Inventariable, por favor verifique.',
            onConfirm: (r: AlertResponse) => {
              this.source[this.indexEditing].NoIdentificacion = "";
              this.source = [...this.source];
              this.initEditor(this.indexEditing, this.colProducto);
            }
          });
          return;
        }


        this.invService.getUltimoCostoPorProducto(this.empresaActual!.numero, this.sucursalActual!.numero, producto.Almacen.Id, producto.Id).subscribe(prod => {
          let item = this.source[this.indexEditing];
          item.Producto = producto;
          item.NoIdentificacion = producto.Clave;
          if (!this.usarSegundoNombreProducto) {
            item.Descripcion = producto.Nombre;
          } else {
            item.Descripcion = producto.SegundoNombre;
          }
          item.Cantidad = 0;
          item.Unidad = producto.Unidad;

          if (prod != 0) {
            item.Costo = prod;
          } else {
            item.Costo = 0;
          }

          if (producto.ProductoEquivalente) {
            item.ProductoEntrada = producto.ProductoEquivalente;
            item.NoIdentificacionEntrada = producto.ProductoEquivalente.Clave;
            item.AceptaEquivalente = true;
          } else {
            item.ProductoEntrada = null;
            item.NoIdentificacionEntrada = "";
            item.AceptaEquivalente = false;
          }

          item.UnidadNombre = producto.Unidad.Nombre;
          this.source = [...this.source];
          this.showDescription = producto.ActualizarDescrpcion;
          this.myForm.controls["DescripcionAdicional"].setValue(producto.Nombre);


          if (!producto.ActualizarDescrpcion) {
            if (producto.OtrasUnidades && producto.OtrasUnidades?.length > 0 && producto.AplicaVentas) {
              this.getExistencia(producto);
              this.conversiones = producto.OtrasUnidades;
              this.unidadFinal = item.UnidadNombre;
              this.openConvertions((e: any) => this.aceptConversions(e));
            } else {
              this.conversiones = [];
              this.unidadFinal = '';
              if (!producto.ProductoEquivalente) {
                this.enviarFocoCantidad = true;
                this.getExistencia(producto, this.sendFocus());
              } else {
                this.enviarFocoProductoEntrada = true;
                this.getExistencia(producto, this.sendFocus());
              }
            }
          } else {
            this.navigateColumns = true;
            this.rowEditandoDescricionAdic = this.indexEditing;
            setTimeout(() => {
              this.getExistencia(producto), () => {
                this.txtDescription.nativeElement.focus();
              }
            }, 150);
          }
        });
      } else {
        this.eventsService.publish('home:showAlert', {
          message: "No se encontró el Producto indicado.", cancelButton: false, onConfirm: (data: AlertResponse) => {
            this.source[this.indexEditing].NoIdentificacion = "";
            this.source = [...this.source];
            this.enviarFocoProducto = true;
            this.sendFocus();
            //this.initEditor(this.indexEditing, this.colProducto);
          }
        });

      }
    })
  }

  save() {
    this.existencia = 0;
    if (this.saving) return;
    this.myForm.get('Clave')!.setValue(this.myForm.value.Folio);
    let ent: Traspaso = this.myForm.value;

    if (this.myForm.value.Id == 0) {
      ent.UsuarioElabora = { Id: this.info.numero, Clave: this.info.clave, Nombre: this.info.nombreUsuario } as Usuario;
      ent.FechaElabora = new Date();
    } else {
      this.eventsService.publish('home:showAlert', { message: "El Traspaso no puede modificarse, proceda a Cancelar.", cancelButton: false });
      return;
    }
    ent.EsEntreAlmacenes = true;
    ent.FechaEmisionString = moment(ent.FechaEmision).format("YYYY-MM-DDTHH:mm:ss");


    if (ent.AlmacenDestino?.Id == 0 || !ent.AlmacenDestino) {
      this.eventsService.publish('home:showAlert', { message: "Debe de indicar el Almacén destino.", cancelButton: false });
      return;
    }

    if (ent.SucursalDestino) {
      if (ent.SucursalDestino.Id == ent.Sucursal.Id) {
        this.eventsService.publish('home:showAlert', { message: "El Almacén destino no puede ser el mismo que el Almacén origen.", cancelButton: false });
        return;
      }
    }

    ent.Conceptos = this.source.filter(P => P.NoIdentificacion != '');

    if (ent.Conceptos.length == 0) {
      this.eventsService.publish('home:showAlert', { message: "Debe de indicar por lo menos un concepto en el Traspaso.", cancelButton: false });
      return;
    }


    if (ent.Conceptos.filter((P: any) => P.Cantidad <= 0).length > 0) {
      this.eventsService.publish('home:showAlert', { message: "No puede haber conceptos con cantidad menor o igual a cero, por favor verifique.", cancelButton: false });
      return;
    }




    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.http.post<Result>(`${this.baseUrl}/Inventarios/GuardarTraspaso`, ent).subscribe((result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result.success) {
        const t: Traspaso = JSON.parse(result.message);
        this.setEntity(t);
        Swal.fire({ position: 'center', icon: 'success', title: 'Se guardó correctamente', showConfirmButton: false, timer: 1000 }).then(() => {
        });
      } else {
        this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
      }
    })
  }



  acceptDescription() {
    this.source[this.rowEditandoDescricionAdic].Descripcion = this.myForm.value.DescripcionAdicional;
    this.showDescription = false;
    this.source = [...this.source];
    this.navigateColumns && this.initEditor(this.rowEditandoDescricionAdic, this.colCantidad);
  }

  cancelDescription() {
    this.showDescription = false;
    this.navigateColumns && this.initEditor(this.rowEditandoDescricionAdic, this.colProducto);
  }

  activeButtons: ActiveButtons = {
    new: true,
    delete: false,
    return: true,
    save: true,
    first: true,
    left: true,
    right: true,
    last: true,
    search: false,
    print: true,
  }

  onClickBarButton(button: string): void {
    switch (button) {
      case "new": this.new(); break;
      case "save": this.save(); break;
      case "print": this.print(); break;
      case "first": this.navigate(button); break;
      case "left": this.navigate(button); break;
      case "right": this.navigate(button); break;
      case "last": this.navigate(button); break;
      case "return": this.findEntityByParams();
        // setTimeout(() => {
        //   const txtFolio: any = this.txtFolio;
        //   txtFolio.tagInput.nativeElement.focus();
        // }, 250);
        break;
    }
  }



  print() {

    let reportHeader: ReportHeader = {
      Fecha1: this.myForm.get('FechaEmision')?.value,
      Fecha2: this.myForm.get('FechaEmision')?.value,
      NombreReporte: 'VERIFICADOR DE TRASPASOS ' + this.myForm.get('SerieString')?.value + this.myForm.get('Folio')?.value,
      Dato1: '',
      Opc1: true,
      Opc2: true
    }
    let filtro: FilterOptions | undefined = this.filtroTraspasos.FilterOptions?.find(p => p.Campo == 'tras.Id');
    if (filtro) {
      filtro!.Valor = String(this.myForm.get('Id')?.value);
    }
    this.filtroTraspasos.ReportHeader = reportHeader;
    this.reportsService.printReport(this.filtroTraspasos, '/Inventarios/ImprimirFormatoTraspaso');

  }



  // print() {
  //   if (this.myForm.value.Id == 0) {
  //     this.eventsService.publish('home:showAlert', { message: "Es necesario que primero guarde el Traspaso antes de poder imprimirlo, por favor verifique.", cancelButton: false });
  //     return;
  //   }

  //   let reportHeader: ReportHeader = {
  //     Fecha1: this.myForm.get('FechaEmision')?.value,
  //     Fecha2: this.myForm.get('FechaEmision')?.value,
  //     NombreReporte: 'VERIFICADOR DE TRASPASOS ' + this.myForm.get('SerieString')?.value + this.myForm.get('Folio')?.value,
  //     Dato1: '',
  //     Opc1: true,
  //     Opc2: false
  //   }
  //   let filtro: FilterOptions | undefined = this.filtroTraspasos.FilterOptions?.find(p => p.Campo == 'tras.Id');
  //   if (filtro) {
  //     filtro!.Valor = String(this.myForm.get('Id')?.value);
  //   }
  //   this.filtroTraspasos.ReportHeader = reportHeader;
  //   this.reportsService.printReport(this.filtroTraspasos, '/Inventarios/VerificadorDeTraspasos');
  // }

  blurFolio(values: any) {
    if (values.after != values.before) {
      this.findEntityByParams();
    }
  }

  findEntityByParams() {
    const ent = this.myForm.value;
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.invService.obtenerTraspaso(ent.Empresa.Id, ent.Sucursal.Id, ent.SerieString, ent.Folio).subscribe((traspaso) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      this.setEntity(traspaso);
    });
  }
}
