import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, inject } from '@angular/core';
import { UtilsService } from 'src/app/service/utils.service';
import { FacturacionService } from '../../services/facturacion.service';
import { ModalService } from 'src/app/service/modal.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EventsService } from 'src/app/service/events.service';
import { Result, UserLogged } from 'src/app/auth/interfaces';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';
import { OrdenCarga } from '../../interfaces/ordencarga.interface';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { _getFocusedElementPierceShadowDom } from '@angular/cdk/platform';

@Component({
  selector: 'ctrl-edicion-carga-entrega-regresar-productos',
  templateUrl: './ctrl-edicion-carga-entrega-regresarProductos-page.component.html'
})
export class CtrlEdicionCargaEntregaRegresarProductosComponent {

  @ViewChild('txtFolioSalida')
  public txtFolioSalida!: ElementRef<any>;

  @ViewChild('btnBuscarSalida', { static: false })
  btnBuscarSalida!: ElementRef;

  @Input()
  EsCancelacion: boolean = false

  @Output()
  public onClose: EventEmitter<any> = new EventEmitter();



  //* injects
  public utilsService = inject(UtilsService);
  public fService = inject(FacturacionService);
  private mService = inject(ModalService)
  private fb = inject(FormBuilder);
  private eventsService = inject(EventsService)
  private http = inject(HttpClient);
  private readonly baseUrl: string = environment.baseUrlApi;
  private ms = inject(ModalService);

  //* vaiables para el grid
  indexEditing: number = -1;
  columnEditing: number = -1;
  navigateColumns: boolean = false;
  sourceGrid: Array<any> = [];
  idUnico: string = '';
  esEscapeCantidad: boolean = false;
  enviarOtroRenglon: boolean = false;
  enviarFocoCantidad: boolean = false;
  colCantidad: number = 2;
  serieFolioSalida: string = "";
  usuarioElabora: string = "";
  tituloPantalla: string = "";
  tituloPanel: string = "";
  ordenCarga: OrdenCarga = {} as OrdenCarga;




  //*  eventos y parametros del componente
  @Input()
  public factura: any | null = null;

  @Input()
  public SoloConsulta: boolean = false;

  @ViewChild('modalCancelSalidaPorVenta')
  public modalCancelTraspasoAlmacenes!: ElementRef<HTMLElement>;



  //*  variables globales del usuario
  info: UserLogged = {} as UserLogged;

  public myForm: FormGroup = this.fb.group({
    Id: 0,
    serie: '',
    folio: 0,
    motivoCancelacion: ''
  });


  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    this.info = this.utilsService.getUserLogged();
    this.myForm.get('serie')?.setValue("SMV");

    if (this.EsCancelacion) {
      this.tituloPanel = "Cancelacion de Salida por Venta";
      this.tituloPantalla = "Cancelacion Salida por Venta";
    } else {
      this.tituloPanel = "Selección de Productos a Regresar";
      this.tituloPantalla = "Regresar Productos";
    }
    setTimeout(() => {
      const folio: any = this.txtFolioSalida;
      folio.tagInput.nativeElement.focus();
    }, 100);
  }

  //*2.- Control de flujo del grid

  escapeCantidad(e: any) {
    let index = this.indexEditing;
    this.esEscapeCantidad = true;
    let item: any = this.sourceGrid[this.indexEditing];
    e.target.value = item.cantidad ? item.cantidad : 0;

    setTimeout(() => {
      this.esEscapeCantidad = true;
      const item = { ...this.sourceGrid[index] };
      if (!item.producto) {
        e.target.value = "";
      } else {
        e.target.value = item.cantidad;
      }
      if (index > 0) {
        this.indexEditing = index - 1;
        this.enviarFocoCantidad = true;
        this.sendFocus(null);
      } else {
        const txt: any = this.txtFolioSalida;
        txt.tagInput.nativeElement.focus()
      }
    }, 50);

  }

  focusCantidad(index: number, e: any, item: any) {

    let c = this.getLastItem();
    if (index > 0 && index > (c - 1)) {
      return;
    }
    this.setIndexEdit(index, e, item);
    if (c == 0 && index > 0) {
      e.target.blur();
      return;
    }
    if ((index >= c + 1) && c > 0) {
      e.target.blur();
    }
  }


  enterCantidad(e: any) {
    let cantidadO: number = this.sourceGrid[this.indexEditing].total;
    let cantidad: number = 0;

    if (e.target.value == "" || parseInt(e.target.value) == 0) {
      cantidad = 0;
    } else {
      cantidad = parseInt(e.target.value);
    }

    if (cantidad <= 0) {
      this.eventsService.publish('home:showAlert', {
        textAccept: "Aceptar",
        cancelButton: false,
        message: `La cantidad no debe ser menor o gual a cero, por favor verifique.`,
        onConfirm: (data: AlertResponse) => {
          e.target.value = cantidadO;
          this.sourceGrid[this.indexEditing].cantidad = cantidadO;
          this.enviarFocoCantidad = true;
          this.initEditor(this.indexEditing, this.colCantidad);
          return;
        }
      });
      return;
    }

    if (cantidad > cantidadO) {
      this.eventsService.publish('home:showAlert', {
        textAccept: "Aceptar",
        cancelButton: false,
        message: `La cantidad máxima a regersar es de ${cantidadO}, por favor verifique.`,
        onConfirm: (data: AlertResponse) => {
          e.target.value = cantidadO;
          this.sourceGrid[this.indexEditing].cantidad = cantidadO;
          this.enviarFocoCantidad = true;
          this.sendFocus(e);
          return;
        }
      });
      return;
    }

    let c = this.getLastItem();
    if (this.indexEditing >= (c - 1)) {
      e.target.blur();
      return;
    }
    this.enviarOtroRenglon = true;
    e.target.blur();
  }

  blurCantidad(e: any) {
    e.target.classList.remove("focus-editor-grid");
    if (this.esEscapeCantidad) {
      this.esEscapeCantidad = false;
      return;
    }
    if (e.target.value == "" || parseInt(e.target.value) == 0 && !this.enterCantidad) {
      e.target.classList.remove("focus-editor-grid");
      return;
    }
    const cantidad = parseFloat(e.target.value);
    let item = this.sourceGrid[this.indexEditing];
    if (parseFloat(String(item.cantidad)) == cantidad) {
      if (this.enviarOtroRenglon) {
        this.sendFocus(null);
      }
      return;
    };
    item.cantidad = cantidad;
    this.sourceGrid[this.indexEditing] = { ...item };
    this.sourceGrid = [...this.sourceGrid];
    this.sendFocus(null);
  }



  initEditor(row: number, col: number) {
    col = col - 1;
    const input = document.getElementById(`txt_${row}_${col}${this.idUnico}`)!;
    if (input) input.focus();
  }

  keyDownCantidad(e: any) {
    const noDecimales: boolean = this.sourceGrid[this.indexEditing].producto!.NoDecimalesCantidad;
    if (e.key === '.' && noDecimales) { e.preventDefault(); }
  }


  getLastItem(): number {
    return this.sourceGrid.filter(P => P.producto && P.total > 0).length;
  }

  sendFocus(e: any) {
    setTimeout(() => {
      if (this.enviarFocoCantidad) {
        this.initEditor(this.indexEditing, this.colCantidad);
        this.enviarFocoCantidad = false;
      }
      if (this.enviarOtroRenglon) {
        if (this.indexEditing < this.sourceGrid.length - 1) {
          this.indexEditing = this.indexEditing + 1;
          this.initEditor(this.indexEditing, this.colCantidad);
        } else {
          if (e) {
            e.target.classList.remove("focus-editor-grid");
          }
        }
        this.enviarOtroRenglon = false;
      }
    }, 50);
  }

  up(e: any, celda: number) {
    e.preventDefault();
    if (this.indexEditing > 0) {
      e.target.blur();
      setTimeout(() => {
        this.indexEditing = this.indexEditing - 1;
        this.initEditor(this.indexEditing, celda + 1);
      }, 50);
    } else {
      this.initEditor(this.indexEditing, this.colCantidad);
    }
  }


  down(e: any, celda: number) {
    e.preventDefault();
    let c = this.getLastItem();
    if (this.indexEditing < c - 1) {
      this.enviarOtroRenglon = false;
      e.target.blur();
      setTimeout(() => {
        this.indexEditing = this.indexEditing + 1;
        this.initEditor(this.indexEditing, celda + 1);
      }, 50);
    } else {
      if (this.indexEditing + 1 < c + 1) {
        this.enviarOtroRenglon = true;
        e.target.blur();
      }
    }
  }

  setIndexEdit(index: number, e: any, item: any) {
    //todo revisar este codigo y ver como aplica con el nuevo grid
    if (this.SoloConsulta || this.EsCancelacion) {
      return;
    }

    this.indexEditing = index;
    e.target.select();
    e.target.classList.add("focus-editor-grid");
  }



  sourceGridReset(detalle: any[] = []) {
    if (!detalle) {
      this.sourceGrid = [];
    } else {
      this.sourceGrid = detalle;
    }

    this.sourceGrid.map(p => {
      if (p.unidadMedida) {
        p.unidadMedidaDescripcion = p.unidadMedida.nombre;
        p.productoNombre = p.producto.nombre;
      }
    });
    let totalRegs = 1;
    if (this.sourceGrid.length < 20) {
      totalRegs = 20;
    }
  }

  closeModal() {
    this.mService.closeModal(null);
  }

  verificarAceptar(cb: any) {
    const sel: boolean = this.myForm.value.EntregaNuestra;

    let mensaje = '¿Esta seguro que desea devolver estos productos?.';
    if (this.EsCancelacion) {
      mensaje = '¿Esta seguro que desea cancelar la salida de estos productos?.';
    }


    this.eventsService.publish('home:showAlert', {
      textAccept: "Si, Continuar",
      textNo: "No.",
      message: mensaje,
      onConfirm: (data: AlertResponse) => {
        if (data.isAccept) {
          cb && cb();
        }
      }
    });
  }




  aceptar() {
    if (this.sourceGrid.length > 0) {
      this.verificarAceptar(() => {
        //let item: OrdenCarga;
        this.ordenCarga = this.factura!;

        if (this.EsCancelacion) {
          this.ordenCarga.fechaCancelacion = new Date();
        }
        this.ordenCarga.motivoCancelacion = this.myForm.value.motivoCancelacion;
        this.ordenCarga.conceptos = this.sourceGrid;
        this.ordenCarga.fechaUltimaDevolucion = new Date();
        this.ordenCarga.fechaEmision = new Date();
        this.eventsService.publish('home:isLoading', { isLoading: true });
        this.fService.ordenCargaDevolverProductos(this.ordenCarga).subscribe(result => {
          this.eventsService.publish('home:isLoading', { isLoading: false });
          let mensaje = `La devolución se realizó correctamente.`;
          if (this.EsCancelacion) {
            mensaje = `La cancelación se realizó correctamente.`;
          }
          this.eventsService.publish('home:showAlert', {
            message: mensaje, cancelButton: false
            , onConfirm: (data: AlertResponse) => {
              if (data.isAccept) {
                this.onClose.emit(this.ordenCarga);
                this.mService.closeModal(null);
              }
            }
          });
        });
      });
    }
  }


  focoComentarios(value: any) {
    this.utilsService.setHelpLine("Indique un comentario para el movimiento de Traspaso.");
  }



  buscarSalida() {
    const folio = this.myForm.get('folio')?.value;
    if (folio <= 0) {
      this.eventsService.publish('home:showAlert', {
        message: `Debe de indicar el Folio a buscar.`, cancelButton: false
        , onConfirm: (data: AlertResponse) => {
          if (data.isAccept) {
            const folio: any = this.txtFolioSalida;
            folio.tagInput.nativeElement.focus();
          }
        }
      });
      return;
    }
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.fService.obtenerOrdenesDeCargaEntregadas(folio).subscribe((result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result) {
        this.factura = result;
        if (result.conceptos.length > 0) {
          this.serieFolioSalida = result.conceptos[0].serie + '' + result.conceptos[0].folio;
          this.usuarioElabora = result.usuarioElabora.clave + ' ' + result.usuarioElabora.nombre;
          this.sourceGridReset(result.conceptos);
        } else {
          this.eventsService.publish('home:showAlert', { message: `No se encontró información del Folio indicado por favor verifique. `, cancelButton: false });
        }
      } else {
        this.eventsService.publish('home:showAlert', { message: `No se encontró información del Folio indicado por favor verifique. `, cancelButton: false });
      }
    })
  }



  calcTotales() {
    let kilos: number = 0;
    let cantidad: number = 0;
    this.sourceGrid.forEach(item => {
      kilos += item.totalKilo;
      cantidad += item.cantidad;
    })
    this.factura!.kilosCarga = kilos;
    this.factura!.kilos = kilos;
    this.factura!.cantidadTotalCarga = cantidad;
  }

  deleteRow(indx: number) {
    if (this.sourceGrid.length == 1) {
      Swal.fire({ text: `No es posible eliminar todos los productos de la lista, por favor verifique.`, icon: 'error', }).then(() => {
        return;
      });
      return;
    }

    this.sourceGrid.splice(indx, 1);
    this.sourceGrid = [...this.sourceGrid];
  }
}
