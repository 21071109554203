
<div class="card text-center border-0" cdkDrag  style="box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;width:605px">
<div class="screen-content" >
  <div style="cursor: move;width:607px ;" cdkDragHandle class="card-header fw-bold" >Reporte de Estado de Cuenta Contable</div>
  <span *ngIf="loading" class="loaderfact"><i class="fas fa-spinner fa-spin spin"></i></span>
  <form [formGroup]="myForm" autocomplete="off">
    <app-panel-base title="Reporte de estado de cuenta" style="width:600px">
      <div class="panel-content">
        <div class="card border-0 mb-0">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <components-ui-text-box label='Nombre del Reporte' formControlName="NombreReporte" [tabIndex]="100"
                  formControlName="NombreReporte" [widthLabelColumns]="3" [widthTextColumns]="8" [maxLength]="100"
                  #txtNombreReporte />
              </div>
            </div>
            <div class="row">
              <div class="col-md-5" style="margin-left: 4px;">
                <component-ui-combo-box (onSelectedItem)="selectedCombo($event,'mes1')" [selectManualItem]="getMes1"
                  [setItemsList]="listaMeses" listProperty='Nombre' label="Del mes" [tabIndex]="101"
                  [enabled]="!this.checkTodoElAnio" [widthLabelColumns]="7" [widthTextColumns]="4" />
              </div>
              <div class="col-md-3">
                <div class="d-flex mt-0 mt-0">
                  <component-ui-combo-box (onSelectedItem)="selectedCombo($event,'mes2')" [selectManualItem]="getMes2"
                    listProperty='Nombre' [setItemsList]="listaMeses" label="al" [tabIndex]="102"
                    [enabled]="!this.checkTodoElAnio" [widthLabelColumns]="3" [widthTextColumns]="7" />
                </div>
              </div>
              <div class="col-md-3">
                <component-ui-combo-box label='Año' [tabIndex]="103" [zeroMask]="3"
                  [selectManualItem]="selectAnioManualItem" [setItemsList]="this.listaAnios" [widthTextColumns]="8"
                  [widthLabelColumns]="5" (onSelectedItem)="selectedCombo($event,'anio')" [inputProperty]="'Nombre'"
                  [listProperty]="'Nombre'" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <component-ui-check-box label='En moneda nacional' formControlName="Opc1" [tabIndex]="104"
                  [widthLabelColumns]="8" [widthTextColumns]="4" [tabIndex]="3" />
              </div>
            </div>
          </div>
        </div>

        <div style="text-align: right; margin-top: 5px;">
          <button (click)="accept()" class="btn btn-success w-180px" style="margin-right: 5px;">Imprimir</button>
          <button (click)="closeModal()" class="btn btn-danger w-80px">Salir</button>
        </div>
      </div>

    </app-panel-base>
  </form>
</div>
</div>
