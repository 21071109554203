<app-container-base (onItemsComboSearched)="onItemsComboSearched($event)" (onItemSearched)="onItemSearched($event)"
  [entity]="myForm" (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)"
  entityName="TipoDocumentoInventario" title="Documentos de Inventarios" icon="fa fa-newspaper"
  subTitle="Administra la configuración de Documentos de Inventarios">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Información">
        <div class="panel-content">
          <component-ui-combo-box (onNewCatalog)="onNewCatalog($event)" [label]="'Documento de Inventario'" [widthList]="350"
            (onChangueEntity)="onChangueEntity($event)" formControlName="Id" [isCatalog]="true" [isNewItem]="getIsNew"
            [zeroMask]="3" [setItems]="getItemsCombo" [tabIndex]="1" [listProperty]="'ClaveNombre'"
            [widthTextColumns]="1" [widthLabelColumns]="3" entityName="TipoDocumentoInventario" />

          <components-ui-text-box [tabIndex]="2" [label]="'Nombre'" formControlName="Nombre" [widthTextColumns]="4"
            [widthLabelColumns]="3" [required]="true" [maxLength]="150" #txtNombre />

          <components-ui-text-box [tabIndex]="3" [label]="'Imprimir'" formControlName="TextoImprimir"
            [widthTextColumns]="4" [widthLabelColumns]="3" [required]="true" [maxLength]="100" />

          <components-ui-text-box [tabIndex]="4" [label]="'Documentos que puede copiar'"
            formControlName="DocumentosPuedeCopiar" [widthTextColumns]="4" [widthLabelColumns]="3" [maxLength]="200" />


        </div>
      </app-panel-base>


      <app-panel-base title="Movimiento de Inventario a Generar 1">
        <div class="panel-content">
          <component-ui-combo-box [label]="'Tipo movimiento'" formControlName="TipoMovimiento1" [tabIndex]="5"
            [zeroMask]="2" entityName="TipoMovimientoAlmacen" [widthTextColumns]="1" [widthLabelColumns]="3"
            [widthRightColumns]="8" (onSelectedItem)="selectedCombo($event,'TipoMovimiento1')"
            [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box [label]="'Almacén'" formControlName="Almacen1" [tabIndex]="6" [zeroMask]="2"
            entityName="Almacen" [widthTextColumns]="1" [widthLabelColumns]="3" [widthRightColumns]="8"
            (onSelectedItem)="selectedCombo($event,'Almacen1')" [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box [label]="'Concepto almacén'" formControlName="Concepto1" [tabIndex]="7"
            [zeroMask]="2" entityName="ConceptoAlmacen" [widthTextColumns]="1" [widthLabelColumns]="3"
            [widthRightColumns]="8" (onSelectedItem)="selectedCombo($event,'Concepto1')"
            [listProperty]="'ClaveNombre'" />
        </div>
      </app-panel-base>

      <app-panel-base title="Movimiento de Inventario a Generar 2">
        <div class="panel-content">
          <component-ui-combo-box [label]="'Tipo movimiento'" formControlName="TipoMovimiento2" [tabIndex]="8"
            [zeroMask]="2" entityName="TipoMovimientoAlmacen" [widthTextColumns]="1" [widthLabelColumns]="3"
            [widthRightColumns]="8" (onSelectedItem)="selectedCombo($event,'TipoMovimiento2')"
            [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box [label]="'Almacén'" formControlName="Almacen2" [tabIndex]="9" [zeroMask]="2"
            entityName="Almacen" [widthTextColumns]="1" [widthLabelColumns]="3" [widthRightColumns]="8"
            (onSelectedItem)="selectedCombo($event,'Almacen2')" [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box [label]="'Concepto almacén'" formControlName="Concepto2" [tabIndex]="10"
            [zeroMask]="2" entityName="ConceptoAlmacen" [widthTextColumns]="1" [widthLabelColumns]="3"
            [widthRightColumns]="8" (onSelectedItem)="selectedCombo($event,'Concepto2')"
            [listProperty]="'ClaveNombre'" />
        </div>
      </app-panel-base>

    </form>
  </div>
</app-container-base>
