<app-container-base [setIsNew]="getIsNew" (onItemsComboSearched)="onItemsComboSearched($event)"
  [searchItemsCombo]="false" (onItemSearched)="onItemSearched($event)" (onBeforeSave)="onBeforeSave($event)"
  (onAfterSave)="onAfterSave($event)" [entity]="myForm" entityName="Proveedor" title="Proveedor" icon="fa fa-user-tag"
  subTitle="Administra la configuración de Proveedores">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Información Fiscal">
        <div class="panel-content">

          <component-ui-combo-box [required]="true" [enabled]="false" [label]="'Empresa'" formControlName="Empresa"
            [zeroMask]="2" entityName="Empresa" [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="8"
            [tabIndex]="0" (onSelectedItem)="selectedCombo($event,'Empresa')" [listProperty]="'ClaveNombre'" />

          <components-ui-text-box (keydown.f2)="busquedaRegistro()" entidadBusqueda="Proveedor"
            (elementoSeleccionado)="elementoSeleccionado($event)" [enabled]="getEnableText" [maxLength]="8"
            [label]="'Clave'" (keydown.enter)="enterField($event)" (blur)="blur($event)" formControlName="Clave"
            [tabIndex]="1" [widthTextColumns]="1" [widthLabelColumns]="2" #txtClave />

          <components-ui-text-box [enabled]="!getIsBaseCustomer" [required]="true" [tabIndex]="2" [maxLength]="13"
            [upperCase]="true" [required]="true" [label]="'RFC'" [dataType]="EDT.RFC" formControlName="Rfc"
            [widthTextColumns]="2" [widthLabelColumns]="2" #txtNombre />

          <components-ui-text-box [enabled]="!getIsBaseCustomer" [maxLength]="300" [required]="true" [label]="'Nombre'"
            [required]="true" formControlName="Nombre" [tabIndex]="3" [widthTextColumns]="10" [widthLabelColumns]="2" />


        </div>
      </app-panel-base>

      <ul class="nav nav-tabs m-2" role="tablist">
        <li class="nav-item" role="presentation">
          <a href="#default-tab-1" data-bs-toggle="tab" class="nav-link active" aria-selected="true" role="tab">
            <span class="d-sm-block d-none">
              Generales
            </span>
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a href="#default-tab-2" data-bs-toggle="tab" class="nav-link" aria-selected="false" role="tab">
            <span class="d-sm-block d-none">Dirección</span>
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a href="#default-tab-3" data-bs-toggle="tab" class="nav-link" aria-selected="false" role="tab">
            <span class="d-sm-block d-none">DIOT</span>
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a href="#default-tab-4" data-bs-toggle="tab" class="nav-link" aria-selected="false" role="tab">
            <span class="d-sm-block d-none">Contabilidad</span>
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a href="#default-tab-5" data-bs-toggle="tab" class="nav-link" aria-selected="false" role="tab">
            <span class="d-sm-block d-none">Inventario/Compra Sugerida</span>
          </a>
        </li>
      </ul>

      <div class="tab-content panel rounded-0 p-3 me-2 ms-2 mt-0">
        <div id="default-tab-1" class="tab-pane fade active show" role="tabpanel">
          <app-panel-base title="Generales">
            <div class="panel-content">
              <component-ui-combo-box [enabled]="!getIsBaseCustomer" [required]="true" [label]="'Régimen Fiscal'"
                [filterQuery]="getFiltro" formControlName="RegimenFiscal" [tabIndex]="4" [zeroMask]="2"
                entityName="c_RegimenFiscal" [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="8"
                (onSelectedItem)="selectedCombo($event,'RegimenFiscal')" [listProperty]="'ClaveNombre'" />


              <component-ui-combo-box [enabled]="!getIsBaseCustomer" [required]="true" [label]="'Condición de Pago'"
                formControlName="CondicionDePago" [tabIndex]="5" [zeroMask]="2" entityName="CondicionPagoProveedor"
                [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="8"
                (onSelectedItem)="selectedCombo($event,'CondicionDePago')" [listProperty]="'ClaveNombre'" />


              <component-ui-combo-box [enabled]="!getIsBaseCustomer" [required]="true" [label]="'Forma de Pago'"
                formControlName="FormaDePago" [tabIndex]="6" [zeroMask]="2" entityName="c_FormaPago"
                [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="8"
                (onSelectedItem)="selectedCombo($event,'FormaDePago')" [listProperty]="'ClaveNombre'" />

              <component-ui-combo-box [enabled]="!getIsBaseCustomer" [label]="'Moneda'" [required]="true"
                formControlName="Moneda" [tabIndex]="7" [zeroMask]="2" entityName="Moneda" [widthTextColumns]="2"
                [widthLabelColumns]="2" [widthRightColumns]="8" (onSelectedItem)="selectedCombo($event,'Moneda')"
                [listProperty]="'ClaveNombre'" />

              <component-ui-combo-box [required]="true" [enabled]="!getIsBaseCustomer" [label]="'Clase'"
                formControlName="Clase" [tabIndex]="8" [zeroMask]="2" entityName="ClaseProveedor" [widthTextColumns]="2"
                [widthLabelColumns]="2" [widthRightColumns]="8" (onSelectedItem)="selectedCombo($event,'Clase')"
                [listProperty]="'ClaveNombre'" />

              <component-ui-combo-box [required]="true" [enabled]="!getIsBaseCustomer" [label]="'Casificación'"
                formControlName="Clasificacion" [tabIndex]="9" [zeroMask]="2" entityName="ClasificacionProveedor"
                [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="8"
                (onSelectedItem)="selectedCombo($event,'Clasificacion')" [listProperty]="'ClaveNombre'" />

              <component-ui-combo-box [required]="true" [enabled]="!getIsBaseCustomer" [label]="'Tipo'"
                formControlName="Tipo" [tabIndex]="10" [zeroMask]="2" entityName="TipoProveedor" [widthTextColumns]="2"
                [widthLabelColumns]="2" [widthRightColumns]="8" (onSelectedItem)="selectedCombo($event,'Tipo')"
                [listProperty]="'ClaveNombre'" />


              <component-ui-combo-box [required]="true" [label]="'Clase compra'" formControlName="ClaseCompra"
                [tabIndex]="11" [zeroMask]="2" entityName="ClaseCompra" [widthTextColumns]="2" [widthLabelColumns]="2"
                [widthRightColumns]="8" (onSelectedItem)="selectedCombo($event,'ClaseCompra')"
                [listProperty]="'ClaveNombre'" />

              <!-- <components-ui-text-box [enabled]="!getIsBaseCustomer" [label]="'Comentarios'" formControlName="Comentarios"
              [tabIndex]="11" [widthTextColumns]="10" [maxLength]="500" [widthLabelColumns]="2" /> -->

              <components-ui-text-box [enabled]="!getIsBaseCustomer" [label]="'Teléfonos'" formControlName="Telefonos"
                [tabIndex]="12" [widthTextColumns]="10" [maxLength]="500" [widthLabelColumns]="2" />

              <components-ui-text-box [enabled]="!getIsBaseCustomer" [label]="'Correos'" formControlName="Correos"
                [tabIndex]="13" [widthTextColumns]="10" [maxLength]="500" [widthLabelColumns]="2" />

              <component-ui-check-box [tabIndex]="14" [widthLabelColumns]="2" formControlName="EsProntoPago"
                [label]="'Maneja pronto pago'" />

              <components-ui-number-box [maxLength]="4" label="% Descuento p/pronto pago"
                (keydown.enter)="enterField($event)" formControlName="PorcDescuentoProntoPago" [tabIndex]="15"
                [aceptaPunto]="true" [widthTextColumns]="2" [widthLabelColumns]="2"
                helpLine='Es el % de descuento que el ' />

            </div>
          </app-panel-base>
        </div>
        <div id="default-tab-2" class="tab-pane fade" role="tabpanel">
          <component-ui-address [collapsable]="false" [tabIndex]="16"
            formControlName="Direccion"></component-ui-address>
        </div>
        <div id="default-tab-3" class="tab-pane fade" role="tabpanel">
          <app-panel-base title="Datos para la Declaración Informativa de Operaciones con Terceros">
            <div class="panel-content">
              <component-ui-combo-box [label]="'Tipo de Tercero'" formControlName="TipoTercero" [tabIndex]="28"
                [zeroMask]="2" entityName="TipoTerceroDIOT" [widthTextColumns]="2" [widthLabelColumns]="2"
                [widthRightColumns]="8" (onSelectedItem)="selectedCombo($event,'TipoTercero')"
                [listProperty]="'ClaveNombre'" />
              <component-ui-combo-box [label]="'Tipo de Operación'" formControlName="TipoOperacion" [tabIndex]="29"
                [zeroMask]="2" entityName="TipoOperacionDIOT" [widthTextColumns]="2" [widthLabelColumns]="2"
                [widthRightColumns]="8" (onSelectedItem)="selectedCombo($event,'TipoOperacion')"
                [listProperty]="'ClaveNombre'" />

              <components-ui-text-box [label]="'Id Fiscal'" formControlName="NumRegIdTrib" [tabIndex]="30"
                [widthTextColumns]="2" [widthLabelColumns]="2" [maxLength]="50" />

              <component-ui-combo-box [label]="'Nacionalidad'" formControlName="Nacionalidad" [tabIndex]="31"
                [zeroMask]="2" entityName="c_Pais" [widthTextColumns]="2" [widthLabelColumns]="2"
                [widthRightColumns]="8" (onSelectedItem)="selectedCombo($event,'Nacionalidad')"
                [listProperty]="'ClaveNombre'" />

              <component-ui-combo-box [label]="'Residencia'" formControlName="PaisResidencia" [tabIndex]="32"
                [zeroMask]="2" entityName="c_Pais" [widthTextColumns]="2" [widthLabelColumns]="2"
                [widthRightColumns]="8" (onSelectedItem)="selectedCombo($event,'PaisResidencia')"
                [listProperty]="'ClaveNombre'" />

              <component-ui-check-box [tabIndex]="33" [widthLabelColumns]="2" formControlName="NoGeneraDiot"
                [label]="'No Genera DIOT'" />
            </div>
          </app-panel-base>
        </div>

        <div id="default-tab-4" class="tab-pane fade" role="tabpanel">
          <app-panel-base title="Asientos contables por póliza a generar">
            <div class="panel-content">
              <app-ctrl-asiento-contable [Asientos]="myForm.value.Asientos" (onClose)="onCloseAsiento($event)" />
            </div>
          </app-panel-base>
        </div>
        <div id="default-tab-5" class="tab-pane fade" role="tabpanel">
          <app-panel-base title="Datos">
            <div class="panel-content">
              <components-ui-number-box [maxLength]="2" label="Días de entrega (promesa de entrega)"
                (keydown.enter)="enterField($event)" formControlName="DiasEntrega" [tabIndex]="34"
                [widthTextColumns]="1" [widthLabelColumns]="3" />
              <components-ui-number-box [maxLength]="2" label="Días de entrega máxima"
                (keydown.enter)="enterField($event)" formControlName="DiasEntregaMaxima" [tabIndex]="35"
                [widthTextColumns]="1" [widthLabelColumns]="3" />
              <components-ui-number-box [maxLength]="2" label="Días de Seguridad" (keydown.enter)="enterField($event)"
                formControlName="DiasSeguridad" [tabIndex]="36" [widthTextColumns]="1" [widthLabelColumns]="3" />
              <component-ui-check-box (onClick)="clickChkSobrePedido($event)" [tabIndex]="37" [widthLabelColumns]="3"
                formControlName="SobrePedido" label="Sobre Pedido" />
              <components-ui-number-box [maxLength]="3" label="Ciclo de revisión" (keydown.enter)="enterField($event)"
                formControlName="CicloRevision" [tabIndex]="38" [widthTextColumns]="1" [widthLabelColumns]="3"
                [enabled]="habilitarCicloRevision" />
            </div>
          </app-panel-base>
        </div>
      </div>
    </form>
  </div>

</app-container-base>
<ng-template #ctrlBusqueda let-modal let-c="close">
  <app-data-search titulo="Búsqueda de Proveedores" [columns]="getColumns" [entityName]="'Proveedor'"
    [filter]="getFilter" [propertys]="getPropertys" [orders]="'Nombre|asc'" #busqueda />
</ng-template>
