import { Component, inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { ConfiguracionService } from 'src/app/configuracion/service/configuracion.service';
import { ModalService } from 'src/app/service/modal.service';
import { CorteCajaConteoEfectivo } from '../../interfaces/cortecaja.interface';

@Component({
  selector: 'conteo-efectivo',
  templateUrl: './conteo-efectivo.component.html',
  styleUrls: ['./conteo-efectivo.component.scss']
})
export class ConteoEfectivoComponent implements OnInit {




  bVeinte: number = 0;
  bCincuenta: number = 0;
  bCien: number = 0;
  bDoscientos: number = 0;
  bQuinientos: number = 0;
  bMil: number = 0;


  mDiezCentavos: number = 0;
  mVeinteCentavos: number = 0;
  mCincuentaCentavos: number = 0;
  mUnPeso: number = 0;
  mDosPesos: number = 0;
  mCincoPesos: number = 0;
  mDiezPesos: number = 0;
  mVeintePesos: number = 0;
  mCincuentaPesos: number = 0;
  mCienPesos: number = 0;

  totalEfectivo: number = 0;

  private modalService = inject(ModalService);


  @Input()
  public conteo: CorteCajaConteoEfectivo | null = null;


  ngOnInit(): void {
    if (!this.conteo) {
      this.conteo = {
        Id: 0,
        Billete20: 0,
        Billete50: 0,
        Billete100: 0,
        Billete200: 0,
        Billete500: 0,
        Billete1000: 0,
        Moneda10Centavos: 0,
        Moneda20Centavos: 0,
        Moneda50Centavos: 0,
        Moneda1Pesos: 0,
        Moneda2Pesos: 0,
        Moneda5Pesos: 0,
        Moneda10Pesos: 0,
        Moneda20Pesos: 0,
        Moneda50Pesos: 0,
        Moneda100Pesos: 0,
        TotalEfectivo: 0,
      }
    } else {
      this.bVeinte = 20 * this.conteo.Billete20
      this.bCincuenta = 50 * this.conteo.Billete50
      this.bCien = 100 * this.conteo.Billete100;
      this.bDoscientos = 200 * this.conteo.Billete200;
      this.bQuinientos = 500 * this.conteo.Billete500;
      this.bMil = 1000 * this.conteo.Billete1000;

      this.mDiezCentavos = .1 * this.conteo.Moneda10Centavos;
      this.mVeinteCentavos = .2 * this.conteo.Moneda20Centavos;
      this.mCincuentaCentavos = .5 * this.conteo.Moneda50Centavos;
      this.mUnPeso = this.conteo.Moneda1Pesos;
      this.mDosPesos = 2 * this.conteo.Moneda2Pesos;
      this.mCincoPesos = 5 * this.conteo.Moneda5Pesos;
      this.mDiezPesos = 10 * this.conteo.Moneda10Pesos;
      this.mVeintePesos = 20 * this.conteo.Moneda20Pesos;
      this.mCincuentaPesos = 50 * this.conteo.Moneda50Pesos;
      this.mCienPesos = 100 * this.conteo.Moneda100Pesos;

      this.totalEfectivo = this.conteo.TotalEfectivo;

    }
    this.myForm.reset({ ...this.conteo });
  }

  private newEntityObj = {
    Id: [0],
    Billete20: [0],
    Billete50: [0],
    Billete100: [0],
    Billete200: [0],
    Billete500: [0],
    Billete1000: [0],
    Moneda10Centavos: [0],
    Moneda20Centavos: [0],
    Moneda50Centavos: [0],
    Moneda1Pesos: [0],
    Moneda2Pesos: [0],
    Moneda5Pesos: [0],
    Moneda10Pesos: [0],
    Moneda20Pesos: [0],
    Moneda50Pesos: [0],
    Moneda100Pesos: [0],
    TotalEfectivo: [0],
  };

  public myForm: FormGroup = this.fb.group(this.newEntityObj);

  constructor(private fb: FormBuilder, private containerService: ContainerBaseService) { }

  calcularBilletes(event: any, billete: number) {
    billete = Number(billete);
    let value: number = 0;
    if (event.target.value) {
      value = Number(event.target.value);
    }
    switch (billete) {
      case 20:
        this.bVeinte = value * billete;
        break;
      case 50:
        this.bCincuenta = value * billete;
        break;
      case 100:
        this.bCien = value * billete;
        break;
      case 200:
        this.bDoscientos = value * billete;
        break;
      case 500:
        this.bQuinientos = value * billete;
        break;
      case 1000:
        this.bMil = value * billete;
        break;
    }

    this.calcularTotalEfectivo();
  }

  calcularMonedas(event: any, moneda: number) {
    moneda = Number(moneda);
    let value: number = 0;
    if (event.target.value) {
      value = Number(event.target.value);
    }
    switch (moneda) {
      case .1:
        this.mDiezCentavos = value * moneda;
        break;
      case .2:
        this.mVeinteCentavos = value * moneda;
        break;
      case .5:
        this.mCincuentaCentavos = value * moneda;
        break;
      case 1:
        this.mUnPeso = value * moneda;
        break;
      case 2:
        this.mDosPesos = value * moneda;
        break;
      case 5:
        this.mCincoPesos = value * moneda;
        break;
      case 10:
        this.mDiezPesos = value * moneda;
        break;
      case 20:
        this.mVeintePesos = value * moneda;
        break;
      case 50:
        this.mCincuentaPesos = value * moneda;
        break;
      case 100:
        this.mCienPesos = value * moneda;
        break;
    }
    this.calcularTotalEfectivo();
  }


  calcularTotalEfectivo() {
    this.totalEfectivo = this.bVeinte + this.bCincuenta + this.bCien + this.bDoscientos + this.bQuinientos + this.bMil + this.mDiezCentavos + this.mVeinteCentavos + this.mCincuentaCentavos + this.mUnPeso + this.mDosPesos + this.mCincoPesos + this.mDiezPesos + this.mVeintePesos + this.mCincuentaPesos + this.mCienPesos;
    this.myForm.controls["TotalEfectivo"].setValue(this.totalEfectivo);
  }

  accept() {
    this.modalService.closeModal(this.myForm.value);
  }

  close() {
    this.modalService.closeModal(null);
  }
}
