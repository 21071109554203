import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { ConsultaSaldoResguardo } from '../../interfaces/venta.interface';
import { FilterOptions, ReportFilter, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ReportsService } from 'src/app/service/reports.service';
import { ModalService } from 'src/app/service/modal.service';

@Component({
  selector: 'app-saldo-factura-resguardo-page',
  templateUrl: './saldo-factura-resguardo-page.component.html',
  styles: [
  ]
})
export class SaldoFacturaResguardoPageComponent implements OnInit {


  private reportsService = inject(ReportsService)
  indexEditing: number = -1;


  @Input()
  public lista: ConsultaSaldoResguardo[] = [];//ConsultaSaldoResguardo

  @Input()
  public facturaId: number = 0;

  constructor(private ms: ModalService) {
  }

  ngOnInit(): void {
  }


  imprimirEntregado() {

    let filtroReporte: ReportFilter = {
      ReportHeader: {} as ReportHeader,
      NombreReporte: 'FACTURAS PENDIENTES POR ENTREGAR',
      TituloVisor: 'Verificador de facturas pendientes por entregar',
      NombreExcel: 'Verificador de facturas pendientes por entregar.xlsx',
      FilterOptions: [
        { Campo: 'rem.idVenta', Etiqueta: '', Tipo: TypeFilter.number, Valor: String(this.facturaId) },
      ]
    }

    let reportHeader: ReportHeader = {
      NombreReporte: 'FACTURAS PENDIENTES POR ENTREGAR ',
    }

    filtroReporte.ReportHeader = reportHeader;
    this.reportsService.printReport(filtroReporte, '/Resguardo/ImprimirFacturasPendientesPorEntregar');
  }

  closeModal() {
    this.ms.closeModal(null);
  }
}
