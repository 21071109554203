<app-container-base [hasCounter]="false" icon="fa fa-bars-staggered" [activeButtons]="activeButtons" [hasBar]="true"
  (onClickBarButton)="onClickBarButton($event)" subTitle="Captura de Producción">
  <div class="screen-content" style="width:1400px;">
    <form [formGroup]="myForm" autocomplete="off">
      <div class="d-flex">
        <div style="width: 1000px;">
          <app-panel-base title="Identificación de la Captura">
            <div class="panel-content">
              <div class="d-flex">
                <components-ui-date-box formControlName="FechaEmision" style="width:15%; margin-right: 5px;"
                  label="Fecha" [topLabel]="true" [tabIndex]="1" [widthLabelColumns]="12" [widthTextColumns]="12" />
                <components-ui-number-box style="width:15%; margin-right: 5px;" [tabIndex]="2" [maxLength]="10"
                  [topLabel]="true" [label]="'Folio '" formControlName="Folio" [required]="true" [widthTextColumns]="12"
                  (blur)="blurFolio($event)" [widthLabelColumns]="12" />
                <div style="width: 25%;">
                  &nbsp;
                </div>
                <component-ui-combo-box style="width:15%; margin-right: 5px;" [topLabel]="true" label='SerieFactura'
                  formControlName="SerieFactura" entityName="SerieVenta" [tabIndex]="3" [widthTextColumns]="12"
                  inputProperty="Serie" filterQuery='SerieManufacturaId > 0' [widthLabelColumns]="12"
                  [widthRightColumns]="0" extras="Serie" [required]="true"
                  (onSelectedItem)="selectedCombo($event,'SerieFactura')" [zeroMask]="3" [enabled]="myForm.value.Id==0"
                  #cboSerieFactura />

                <components-ui-number-box style="width:15%; margin-right: 5px;" formControlName="FolioFactura"
                  (blur)="blurFolioFactura($event)" [tabIndex]="4" [maxLength]="10" [topLabel]="true"
                  [enabled]="myForm.value.Id==0" [label]="'Folio Factura'" [required]="true" [widthTextColumns]="12"
                  [widthLabelColumns]="12" />

                <component-ui-combo-box *ngIf="myForm.value.Id==0" style="width:15%; margin-right: 5px;"
                  [topLabel]="true" label='Orden' [tabIndex]="5" [setItemsList]="getItemsOrdenes"
                  [widthTextColumns]="12" [widthLabelColumns]="12" [widthRightColumns]="0" [inputProperty]="'Nombre'"
                  [listProperty]="'Nombre'" (onSelectedItem)="selectedCombo($event,'Orden')"
                  [required]="myForm.value.Id == 0" [enabled]="myForm.value.Id == 0" [zeroMask]="3" />
              </div>

            </div>
          </app-panel-base>

          <app-panel-base title="Información Del Producto Terminado ">
            <div class="panel-content">

              <div class="card border-0">
                <div class="card-header bg-none p-3 h6 m-0 d-flex align-items-center">
                  <i class="fa fa-credit-card fa-lg me-2 text-gray text-opacity-50"></i>
                  Cliente: {{(myForm.value.Cliente ? myForm.value.Cliente.Nombre : '')}}
                </div>
              </div>

              <div class="card border-0">
                <div class="card-header bg-none p-3 h6 m-0 d-flex align-items-center">
                  <i class="fa fa-shopping-bag fa-lg me-2 text-gray text-opacity-50"></i>
                  Producto a Producir: {{(myForm.value.Producto ? (myForm.value.Producto.Clave + " "
                  + myForm.value.Producto.Nombre): '')}}
                </div>
              </div>

              <components-ui-text-box [tabIndex]="7" [label]="'Observaciones'" [widthTextColumns]="10"
                [widthLabelColumns]="2" />

              <components-ui-number-box [tabIndex]="2" [maxLength]="10" [label]="'Cantidad'"
                formControlName="CantidadProducir" [required]="true" [widthTextColumns]="2" [widthLabelColumns]="2"
                [enabled]="false" />

              <div class="d-flex">
                <div style="width: 50%;">
                  <component-ui-combo-box label='Vehículo' (onSelectedItem)="selectedCombo($event,'Vehiculo')"
                    formControlName="Vehiculo" entityName="Unidad" [tabIndex]="8" [widthTextColumns]="3"
                    [widthLabelColumns]="4" [widthRightColumns]="5" [required]="true" [zeroMask]="3" />
                  <component-ui-combo-box label='Operador' (onSelectedItem)="selectedCombo($event,'Chofer')"
                    formControlName="Chofer" entityName="Chofer" [tabIndex]="9" [widthTextColumns]="3"
                    [widthLabelColumns]="4" [widthRightColumns]="5" [required]="true" [zeroMask]="3" />
                  <component-ui-combo-box label='Ubicación' (onSelectedItem)="selectedCombo($event,'Ubicacion')"
                    formControlName="Ubicacion" entityName="UbicacionProductoTerminado" [tabIndex]="10"
                    [widthTextColumns]="3" [widthLabelColumns]="4" [widthRightColumns]="5" [required]="true"
                    [zeroMask]="3" />
                  <component-ui-combo-box label='Almacén Prod T'
                    (onSelectedItem)="selectedCombo($event,'AlmacenProductoTerminado')"
                    formControlName="AlmacenProductoTerminado" entityName="Almacen" [tabIndex]="11"
                    [widthTextColumns]="3" [widthLabelColumns]="4" [widthRightColumns]="5" [required]="true"
                    [zeroMask]="3" />
                </div>
                <div style="width: 50%;">
                  <component-ui-combo-box label='Tipo' (onSelectedItem)="selectedCombo($event,'Tipo')"
                    formControlName="Tipo" entityName="TipoProduccion" [tabIndex]="12" [widthTextColumns]="3"
                    [widthLabelColumns]="4" [widthRightColumns]="5" [required]="true" [zeroMask]="3" />

                  <component-ui-combo-box label='Vehículo Bombeo' (onSelectedItem)="selectedCombo($event,'VehiculoBombeo')"
                    formControlName="VehiculoBombeo" entityName="Unidad" [tabIndex]="13" [widthTextColumns]="3"
                    [widthLabelColumns]="4" [widthRightColumns]="5" [zeroMask]="3" />
                </div>
              </div>
            </div>
          </app-panel-base>
          <app-panel-base title="Materia Prima por cada unidad producida">
            <div class="panel-content">

              <!-- <div class="pt-2 pb-2 text-start" style="width:200px;margin-left: 15px;">
                <a (click)="onDblClickRow(-1)" class="btn btn-primary btn-rounded px-4 rounded-pill"><i
                    class="fa fa-plus fa-lg me-2 ms-n2"></i>Agregar</a>
              </div> -->
              <ng-scrollbar style="height: 200px;border-bottom: 1px solid #AAAAAA;width:1000px;">
                <table class="blueTable" style="width:1000px">
                  <thead style="position: sticky;top:0;">
                    <tr>
                      <!-- <th style="width: 50px;"> </th>
                      <th style="width: 50px;"> </th> -->
                      <th style="width: 250px;">Producto</th>
                      <th style="width: 70px;text-align: right;">Cantidad</th>
                      <th style="width: 100px;text-align: center">Unidad</th>
                      <th style="width: 60px;text-align: center">Costo</th>
                      <th style="width: 60px;text-align: center">Existencia</th>
                      <th style="width: 60px;text-align: center"></th>
                      <th style="width: 200px;">Almacen</th>
                    </tr>
                  </thead>
                  <tbody class="bg-light">
                    <tr [style]=" indexEditing == index ?  'background-color: #ffdbb7;': ''"
                      *ngFor="let item of myForm.value.MateriaPrima; let index = index;">
                      <!-- <td style="text-align: center;">
                        <i (click)="deleteRowCompuesto(index)" *ngIf="item.Producto" class="fa fa-rectangle-xmark fa-1x"
                          style="color:red;font-weight: bold; cursor: pointer;"></i>
                      </td>
                      <td style="text-align: center;">
                        <i (click)="onDblClickRow(index)" *ngIf="item.Producto" class="fa fa-pencil text-yellow fa-lg"
                          style="color:red;font-weight: bold; cursor: pointer;"></i>
                      </td> -->
                      <td>
                        <span style="margin-left: 3px;">{{item.Producto ?
                          (item.Producto.Clave + " => " + item.Producto.Nombre) : '' }}</span>
                      </td>
                      <td style="width: 70px;min-width:70px;max-width: 70px;"><input *ngIf="item.Producto"
                          (keydown.escape)="escapeCantidad()" (keydown.enter)="enterCantidad($event)"
                          class="grid-editor text-dark"
                          [style]="'text-align:right;width: 77px;min-width:70px;max-width: 77px;height: 22px;' + (item.TieneToken?'color:red !important':'')"
                          [id]="'txt_'+index+'_1' + idUnico" (blur)="blurCantidad($event)"
                          (focus)="setIndexEdit(index, $event, item)" type="number"
                          [value]="item.Producto ? item.Cantidad: 0" />
                      </td>
                      <td style="text-align: center;">
                        <span style="margin-left: 3px;">{{item.Producto ?
                          (item.Unidad ? item.Unidad.Nombre : '') : '' }}</span>
                      </td>
                      <td class="text-end pe-1">
                        <span *ngIf="!noVerCostos">{{ item.Costo | number:'1.2-6' }}</span>
                      </td>
                      <td class="text-end pe-1">
                        <span>{{ item.Existencia | number:'1.2-6' }}</span>
                      </td>
                      <td class="text-center">
                        <i
                          [class]="'fas fa-lg fa-fw me-10px  '+(item.ExistenciaSuficiente?'fa-circle-check text-green':'fa-circle-xmark text-red')+' '"></i>
                      </td>
                      <td>
                        <span style="margin-left: 3px;">{{item.Almacen ? (item.Almacen.Nombre) : '' }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ng-scrollbar>


            </div>
          </app-panel-base>
        </div>
        <div style="width: 400px;">
          <app-panel-base title="Opciones especiales" *ngIf="myForm.value.Id>0">
            <div class="panel-content">
              <div class="btn btn-sm btn-white mb-10px me-2 d-block"><i
                  class="fa fa-prescription-bottle t-plus-1 text-success fa-fw fa-lg"></i>
                Orden de Prod: {{myForm.value.SerieOrden}}{{myForm.value.FolioOrden}}
              </div>
              <div *ngIf="myForm.value.UsuarioElabora" class="btn btn-sm btn-white mb-10px me-2 d-block"><i
                  class="fa fa-user-check t-plus-1 text-success fa-fw fa-lg"></i>
                Elabaró: {{myForm.value.UsuarioElabora.Nombre}}
              </div>
              <div *ngIf="myForm.value.Id > 0 && !myForm.value.Aplicado" class="pt-2 pb-2 text-start">
                <a (click)="aplicarProduccion()" class="btn btn-primary btn-rounded px-4 rounded-pill d-block"><i
                    class="fa fa-circle-play fa-lg me-2 ms-n2"></i>Aplicar
                  Producción</a>
              </div>
              <components-ui-date-box *ngIf="myForm.value.Id > 0 && myForm.value.Aplicado"
                formControlName="FechaAplicacion" label="Fecha Aplicación" [enabled]="false" [widthLabelColumns]="4"
                [widthTextColumns]="10" />

              <div *ngIf="myForm.value.UsuarioCancela" class="btn btn-sm btn-white mb-10px me-2 d-block"><i
                  class="fa fa-user-check t-plus-1 text-success fa-fw fa-lg"></i>
                Canceló: {{myForm.value.UsuarioCancela.Nombre}}
              </div>
              <div *ngIf="myForm.value.UsuarioAplica" class="btn btn-sm btn-white mb-10px me-2 d-block"><i
                  class="fa fa-user-check t-plus-1 text-success fa-fw fa-lg"></i>
                Aplicó: {{myForm.value.UsuarioAplica.Nombre}}
              </div>

            </div>
          </app-panel-base>

          <app-panel-base title="Costos" *ngIf="!noVerCostos">
            <div class="panel-content">

              <div class="btn btn-sm btn-white mb-10px me-2 d-block"><i
                  class="fa fa-hand-holding-dollar t-plus-1 text-green fa-fw fa-lg"></i>
                Costo Unitario: {{myForm.value.CostoUnitario | number:'1.2-6'}}
              </div>
              <div class="btn btn-sm btn-white mb-10px me-2 d-block"><i
                  class="fa fa-dollar-sign t-plus-1 text-green fa-fw fa-lg"></i>
                Costo Total: {{myForm.value.CostoTotal | number:'1.2-6'}}
              </div>

            </div>
          </app-panel-base>
        </div>
      </div>
    </form>
  </div>
</app-container-base>
