import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { EventReturn } from 'src/app/component-ui/interfaces/container-base.interface';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { ConfiguracionService } from 'src/app/configuracion/service/configuracion.service';
import { TipoDocumentoInventario } from '../../interfaces/documentosinventario.interface';
import { EventsService } from 'src/app/service/events.service';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';
import Swal from 'sweetalert2';
import { InventarioService } from '../../services/inventario.service';

@Component({
  selector: 'app-tipos-documentos-inventarios-page',
  templateUrl: './tipos-documentos-inventarios-page.component.html',
  styles: [
  ]
})
export class TiposDocumentosInventariosPageComponent {
  @ViewChild('txtNombre')
  public txtNombre!: ElementRef<TextBoxComponent>;
  public itemsCombo: ComboBoxEntity[] = [];
  esNuevo: boolean = false;
  private eventsService = inject(EventsService);
  public myForm: FormGroup = this.fb.group({
    Id: [0],
    Empresa: [null],
    Clave: [0],
    Nombre: [''],
    TextoImprimir: [''],
    DocumentosPuedeCopiar: [''],
    TipoMovimiento1: [null],
    Almacen1: [null],
    Concepto1: [null],
    TipoMovimiento2: [null],
    Almacen2: [null],
    Concepto2: [null],
    Eliminado: [false],
    Baja: [false],
  })

  constructor(private configuracionService: ConfiguracionService, private fb: FormBuilder, private containerService: ContainerBaseService, private iService: InventarioService) {
  }

  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombre;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  onItemsComboSearched(items: ComboBoxEntity[]): void {
    this.itemsCombo = items;
  }

  onBeforeSave(param: EventReturn) {
    const ent = this.myForm.value;
    this.esNuevo = ent.Id == 0;
    if (ent.Almacen1?.Id > 0) {
      if (!ent.Concepto1 || !ent.TipoMovimiento1) {
        this.eventsService.publish('home:showAlert', { message: `Indique todos los campos para generar inventario (1).`, cancelButton: false });
        param.callback(false);
        return;
      }
    }
    if (ent.Concepto1?.Id > 0) {
      if (!ent.Almacen1 || !ent.TipoMovimiento1) {
        this.eventsService.publish('home:showAlert', { message: `Indique todos los campos para generar inventario (1).`, cancelButton: false });
        param.callback(false);
        return;
      }
    }

    if (ent.TipoMovimiento1.Id > 0) {
      if (!ent.Concepto1 || !ent.Almacen1) {
        this.eventsService.publish('home:showAlert', { message: `Indique todos los campos para generar inventario (1).`, cancelButton: false });
        param.callback(false);
        return;
      }
    }

    if (ent.Almacen2?.Id > 0) {
      if (!ent.Concepto2 || !ent.TipoMovimiento2) {
        this.eventsService.publish('home:showAlert', { message: `Indique todos los campos para generar inventario (2).`, cancelButton: false });
        param.callback(false);
        return;
      }
    }
    if (ent.Concepto2?.Id > 0) {
      if (!ent.Almacen2 || !ent.TipoMovimiento2) {
        this.eventsService.publish('home:showAlert', { message: `Indique todos los campos para generar inventario (2).`, cancelButton: false });
        param.callback(false);
        return;
      }
    }

    if (ent.TipoMovimiento2?.Id > 0) {
      if (!ent.Concepto2 || !ent.Almacen2) {
        this.eventsService.publish('home:showAlert', { message: `Indique todos los campos para generar inventario (2).`, cancelButton: false });
        param.callback(false);
        return;
      }
    }


    param.callback(true);
  }

  onItemSearched(entity: TipoDocumentoInventario): void {
    if (entity) {
      this.myForm.reset(entity);
    }
    this.focus("txtNombre");
  }

  onAfterSave(entity: TipoDocumentoInventario) {
    this.myForm.reset(entity);
    if (this.esNuevo) {
      this.eventsService.publish('home:showAlert', {
        message: "¿Desea dar de alta una Serie de Inventario relacionada a este Documento?", onConfirm: (data: AlertResponse) => {
          if (data.isAccept) {
            Swal.fire({
              title: "Indique la SERIE a relacionar",
              input: "text",
              inputAttributes: {
                autocapitalize: "off"
              },
              showCancelButton: true,
              confirmButtonText: "Generar Serie",
              showLoaderOnConfirm: true,
              allowOutsideClick: () => !Swal.isLoading()
            }).then((result) => {
              if (result.isConfirmed) {
                if (result.value) {
                  this.eventsService.publish('home:isLoading', { isLoading: true });
                  this.iService.agregarSerieInventario(entity.Id, result.value).subscribe((r) => {
                    this.eventsService.publish('home:isLoading', { isLoading: false });
                    if (r.success) {
                      Swal.fire({ position: 'center', icon: 'success', title: `Se relacionó la serie ${result.value} correctamente`, showConfirmButton: false, timer: 2000 });
                    } else {
                      this.eventsService.publish('home:showAlert', { message: r.message, cancelButton: false, icon: 'fa-triangle-exclamation text-yellow' });
                    }
                  })
                } else {
                  this.eventsService.publish('home:showAlert', { message: "No se dió de alta ningúna Serie, agregue una Serie manualmente.", cancelButton: false, icon: 'fa-triangle-exclamation text-yellow' });
                }
              }
            });
          }
        }
      });
      this.esNuevo = false;
    }
  }


  onChangueEntity(ent: any) {
    if (ent) {
      this.myForm.reset(ent);
    }
    this.focus('txtNombre')
  }

  get getIsNew(): any {
    return this.myForm.value;
  }

  onNewCatalog(next: number) {
    this.containerService.getEmptyEntity("TipoDocumentoInventario").subscribe((elem) => {
      elem.Clave = next;
      this.myForm.reset(elem);
      this.focus("txtNombre")
    })
  }

  selectedCombo(entity: ComboBoxEntity, tipo: string) {
    this.myForm.controls[tipo].setValue(entity);
  }

  get getItemsCombo(): ComboBoxEntity[] {
    return this.itemsCombo;
  }

}
