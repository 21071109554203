import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserLogged } from 'src/app/auth/interfaces';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { EventReturn } from 'src/app/component-ui/interfaces/container-base.interface';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { ConfiguracionService } from 'src/app/configuracion/service/configuracion.service';
import { BaseService } from 'src/app/service/base.service';
import { EventsService } from 'src/app/service/events.service';
import { UtilsService } from 'src/app/service/utils.service';
import { SerieInventario } from '../../interfaces/documentosinventario.interface';

@Component({
  selector: 'app-series-inventarios-page',
  templateUrl: './series-inventarios-page.component.html',
  styles: [
  ]
})
export class SeriesInventariosPageComponent {
  public itemsCombo: ComboBoxEntity[] = [];
  private eventsService = inject(EventsService);
  private baseService = inject(BaseService);
  filter = ``
  info: UserLogged = {} as UserLogged;
  formatoImpresionSeleccionado: ComboBoxEntity | null = null;
  listaFormatosImpresion: any[] = [];
  private newEntityObj = {
    Id: [0],
    Clave: [0],
    Nombre: [''],
    Serie: [''],
    Empresa: [null],
    Sucursal: [null],
    DocumentoInventario: [null],
    Formato: [''],
    Copias: [''],
    IniciarConFolio: [0],
    Eliminado: [false],
    Baja: [false],
  };

  public myForm: FormGroup = this.fb.group(this.newEntityObj);

  @ViewChild('txtNombre')
  public txtNombre!: ElementRef<TextBoxComponent>;
  constructor(private configuracionService: ConfiguracionService,
    private fb: FormBuilder, private containerService: ContainerBaseService, private utilsService: UtilsService) {
  }
  ngOnInit(): void {
    this.info = this.utilsService.getUserLogged();
    this.filter = `Empresa.Id = ${this.info.empresa!.numero} and Sucursal.Id = ${this.info.sucursal!.numero}`
    this.baseService.getlistadoFormatosImpresion(this.info.empresa?.rfc!).subscribe(result => {
      if (result) {
        if (result.length > 0) {
          result.forEach((element: string) => {
            this.listaFormatosImpresion.push({ Id: element, Nombre: element.toString() as string, Clave: element });
          });
        }
        if (this.myForm.value.Formato) {
          this.setFormatoImpresion(this.myForm.value.Formato);
        }
      }
    });
  }

  onBeforeSave(param: EventReturn) {
    param.callback(true);
  }

  get getFormatoImpresion() {
    //if (this.formatoImpresionSeleccionado) {
    return this.formatoImpresionSeleccionado;
    // } else {
    //   return null;
    // }
  }
  onAfterSave(entity: SerieInventario) {
    this.myForm.reset(entity);
  }

  onNewCatalog(next: number) {
    this.containerService.getEmptyEntity("SerieInventario").subscribe((elem) => {
      elem.Empresa = { Id: this.info.empresa!.numero, Clave: this.info.empresa!.clave, Nombre: this.info.empresa!.nombre };
      elem.Sucursal = { Id: this.info.sucursal!.numero, Clave: this.info.sucursal!.clave, Nombre: this.info.sucursal!.nombre };
      elem.Clave = next;
      this.myForm.reset(elem);
      this.focus("txtNombre")
    })
  }


  selectedCombo(entity: ComboBoxEntity, tipo: string) {
    this.myForm.controls[tipo].setValue(entity);
  }

  selectedComboFormato(entity: ComboBoxEntity, tipo: string) {
    if (entity) {
      this.myForm.controls[tipo].setValue(entity.Nombre);
    } else {
      this.myForm.controls[tipo].setValue(null);
    }
  }


  onItemSearched(ent: any): void {
    if (ent) {
      if (ent.Empresa) {
        this.filter = `Empresa.Id = ${ent.Empresa.Id} and Sucursal.Id = ${ent.Sucursal.Id}`
      } else {
        this.filter = '';
        if (this.myForm.value) {
          ent.Empresa = { Id: this.info.empresa!.numero, Clave: this.info.empresa!.clave, Nombre: this.info.empresa!.nombre };
          ent.Sucursal = { Id: this.info.sucursal!.numero, Clave: this.info.sucursal!.clave, Nombre: this.info.sucursal!.nombre };
          this.filter = `Empresa.Id = ${this.info.empresa!.numero} and Sucursal.Id = ${this.info.sucursal!.numero}`
        }
      }
      this.myForm.reset(ent);
      this.setFormatoImpresion(ent.Formato);
      this.focus('txtNombre')
    }
  }



  setFormatoImpresion(formato: string | null) {
    if (!formato) {
      this.formatoImpresionSeleccionado = null;
      return
    }
    let found = false;
    this.listaFormatosImpresion.forEach((item: ComboBoxEntity) => {
      if (item.Nombre == formato) {
        this.formatoImpresionSeleccionado = item;
        found = true;
      }
    });

    if (!found) {
      this.formatoImpresionSeleccionado = null;
    }

  }

  get getIsNew(): any {
    return this.myForm.value;
  }

  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombre;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  onChangueEntity(ent: any) {
    if (ent) {
      this.onItemSearched(ent);
    }
  }

  get getFilter(): string {
    return this.filter;
  }

  onItemsComboSearched(items: ComboBoxEntity[]): void {
    this.itemsCombo = items;
  }

  get getItemsCombo(): ComboBoxEntity[] {
    return this.itemsCombo;
  }

}
