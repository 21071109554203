import { Component, inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EventsService } from 'src/app/service/events.service';
import { ModalService } from 'src/app/service/modal.service';

@Component({
  selector: 'app-ctrl-asiento-contable-edicion',
  templateUrl: './ctrl-asiento-contable-edicion.component.html',
  styles: [
  ]
})
export class CtrlAsientoContableEdicionComponent implements OnInit {


  @Input()
  public item: any = null;
  private eventsService = inject(EventsService);
  public myForm: FormGroup = this.fb.group({
    Id: [0],
    Poliza: [null],
    CodigoContable: [''],
  })

  constructor(private ms: ModalService, private fb: FormBuilder) {
  }

  ngOnInit(): void {
    if (!this.item) {
      this.myForm.reset({
        Id: 0,
        Poliza: null,
        CodigoContable: ''
      })
    } else {
      this.myForm.reset({ ...this.item })
    }
  }

  onSelectedItem(entity: any, tipo: string) {
    this.myForm.controls[tipo].setValue(entity);
  }

  accept() {
    const ent = this.myForm.value;
    if (!ent.Poliza) {
      this.eventsService.publish('home:showAlert', { message: `Debe de indicar la Póliza.`, cancelButton: false });
      return;
    }
    if (!ent.CodigoContable) {
      this.eventsService.publish('home:showAlert', { message: `Debe de indicar el Código Contable.`, cancelButton: false });
      return;
    }
    this.ms.closeModal(ent);
  }

  closeModal() {
    this.ms.closeModal(null);
  }



}
