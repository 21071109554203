<app-container-base (onItemsComboSearched)="onItemsComboSearched($event)" (onItemSearched)="onItemSearched($event)"
  (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)" [entity]="myForm"
  entityName="TIpoTransferencia" title="Tipo Transferencias" icon="fa fa-building"
  subTitle="Administra los Tipos de Transferencias, esto para poder realizar transferencias bancarias">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Información">
        <div class="panel-content">
          <component-ui-combo-box (onNewCatalog)="onNewCatalog($event)" (onChangueEntity)="onChangueEntity($event)"
            [isCatalog]="true" formControlName="Id" [isNewItem]="getIsNew" [setItems]="getItemsCombo"
            [label]="'Tipo de transferencia'" entityName="TIpoTransferencia" [tabIndex]="1"
            [listProperty]="'ClaveNombre'" [widthTextColumns]="1" [widthLabelColumns]="2" />

          <components-ui-text-box [label]="'Nombre'" [required]="true" formControlName="Nombre" [tabIndex]="2"
            [widthTextColumns]="3" [widthLabelColumns]="2" #txtNombre [maxLength]="50" />

          <components-ui-text-box [label]="'Abreviación'" [required]="true" formControlName="Abreviacion" [tabIndex]="3"
            [widthTextColumns]="3" [widthLabelColumns]="2" [maxLength]="50" />

          <div class="card-header h6 mb-1 bg-none p-2 text-orange">
            <i class="fa fa-bank fa-lg fa-fw me-1"></i>
            Datos bancarios
          </div>

          <components-ui-text-box [label]="'Número de Cuenta'" [required]="true" formControlName="NumeroCuenta"
            [tabIndex]="4" [widthTextColumns]="3" [widthLabelColumns]="2" [maxLength]="50" />

          <components-ui-text-box [label]="'Número de Tarjeta'" formControlName="NumeroTarjeta" [tabIndex]="5"
            [widthTextColumns]="3" [widthLabelColumns]="2" [maxLength]="50" />

          <components-ui-text-box [label]="'CLABE'" [required]="true" formControlName="CLABE" [tabIndex]="6"
            [widthTextColumns]="3" [widthLabelColumns]="2" [maxLength]="50" />

          <components-ui-text-box [label]="'Número de Sucursal'" formControlName="NumeroSucursal" [tabIndex]="7"
            [widthTextColumns]="3" [widthLabelColumns]="2" [maxLength]="50" />

          <components-ui-text-box [label]="'Número de Cliente'" formControlName="NumeroCliente" [tabIndex]="8"
            [widthTextColumns]="3" [widthLabelColumns]="2" [maxLength]="50" />

          <component-ui-combo-box formControlName="Moneda" [required]="true" label='Moneda' entityName="Moneda"
            [tabIndex]="9" [widthLabelColumns]="2" (onSelectedItem)="selectedItem($event, 'Moneda')"
            [widthTextColumns]="2" [widthRightColumns]="8" />

          <div class="card-header h6 mb-1 bg-none p-2 text-orange">
            <i class="fa fa-user-clock fa-lg fa-fw me-1"></i>
            Cuentas Por Pagar
          </div>

          <component-ui-combo-box formControlName="CuentaCxp" label='Cuenta de CXP' entityName="CuentaCxp"
            (onSelectedItem)="selectedItem($event, 'CuentaCxp')" [tabIndex]="10" [widthLabelColumns]="2"
            [widthTextColumns]="2" [widthRightColumns]="8" />

          <component-ui-combo-box formControlName="ConceptoCxp" label='Concepto de CXP' entityName="ConceptoCxp"
            (onSelectedItem)="selectedItem($event, 'ConceptoCxp')" [tabIndex]="11" [widthLabelColumns]="2"
            [widthTextColumns]="2" [widthRightColumns]="8" />

          <component-ui-combo-box formControlName="TipoMovimiento" label='Tipo de Movimiento'
            entityName="TipoMovimientoCxp" (onSelectedItem)="selectedItem($event, 'TipoMovimiento')" [tabIndex]="12"
            [widthLabelColumns]="2" [widthTextColumns]="2" [widthRightColumns]="8" />

          <component-ui-combo-box formControlName="LayoutDispersion" label='Layout de dispersión'
            entityName="LayoutDispersion" (onSelectedItem)="selectedItem($event, 'LayoutDispersion')" [tabIndex]="13"
            [widthLabelColumns]="2" [widthTextColumns]="2" [widthRightColumns]="8" />

          <div class="card-header h6 mb-1 bg-none p-2 text-orange">
            <i class="fa fa-calculator fa-lg fa-fw me-1"></i>
            Póliza
          </div>
          <!-- <component-ui-combo-box formControlName="Poliza" label='Póliza a Generar' entityName="PolizaAutomatica"
            (onSelectedItem)="selectedItem($event, 'Poliza')" [tabIndex]="14" [widthLabelColumns]="2"
            [widthTextColumns]="2" [widthRightColumns]="8" /> -->

          <component-ui-combo-box formControlName="TipoPoliza" label='Tipo de Póliza' entityName="TipoPoliza"
            (onSelectedItem)="selectedItem($event, 'TipoPoliza')" [required]="true" [tabIndex]="14"
            [widthLabelColumns]="2" [widthTextColumns]="2" [widthRightColumns]="8" />


        </div>
      </app-panel-base>
    </form>
  </div>
</app-container-base>
