import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CajaChica } from '../interfaces/tipotransferencia.interface';
import { Observable } from 'rxjs';
import { Proveedor } from 'src/app/compras/interfaces/proveedores.interface';
import { Result } from 'src/app/auth/interfaces';
import { DescargaCfdi } from 'src/app/efisco/interfaces/solicitud.interface';
import { Diot } from '../interfaces/diot.interface';
import { TerminalBancaria } from '../interfaces/terminalbancaria.interface';
import { Cheque, Transferencia } from '../interfaces/transferencia.interface';
import { MovimientoPoliza } from 'src/app/contabilidad/interfaces/movimientopoliza.interface';
import { MovimientoConciliacion } from '../interfaces/conciliacion.interface';

@Injectable({ providedIn: 'root' })



export class FinanzasService {

  constructor(private http: HttpClient) { }
  private readonly baseUrl: string = environment.baseUrlApi;

  decargarEjemploConciliacion() {
    return this.http.get(`${this.baseUrl}/Tesoreria/DecargarEjemploConciliacion`, { responseType: 'arraybuffer' });
  }

  obtenerSiguienteFolioVale(idEmpresa: number, idSucursal: number) {
    const params = new HttpParams().set("idEmpresa", idEmpresa).set("idSucursal", idSucursal);
    return this.http.get<Result>(`${this.baseUrl}/Tesoreria/ObtenerSiguienteFolioVale`, { params });
  }

  sendConciliacionImportacion(fileName: string): Observable<Result> {
    const params = new HttpParams().set("fileName", fileName);
    return this.http.get<Result>(`${this.baseUrl}/Tesoreria/IniciarImportacionConciiliacion`, { params });
  }

  eliminarConciliacion(): Observable<Result> {
    return this.http.get<Result>(`${this.baseUrl}/Tesoreria/EliminarConciliacion`);
  }

  obtenerValePorFolio(idEmpresa: number, idSucursal: number, folio: number) {
    const params = new HttpParams().set("idSucursal", idSucursal).set("idEmpresa", idEmpresa).set("folio", folio);
    return this.http.get<Result>(`${this.baseUrl}/Tesoreria/ObtenerValePorFolio`, { params });
  }

  desConciliarMovimientosManualesNosotros(idsEllos: string, idsNosotros: string) {
    const params = new HttpParams().set("idsEllos", idsEllos).set("idsNosotros", idsNosotros);
    return this.http.get<Result>(`${this.baseUrl}/Tesoreria/DesConciliarMovimientosManualesNosotros`, { params });
  }

  conciliarMovimientosManuales(idsEllos: string, idsNosotros: string) {
    const params = new HttpParams().set("idsEllos", idsEllos).set("idsNosotros", idsNosotros);
    return this.http.get<Result>(`${this.baseUrl}/Tesoreria/ConciliarMovimientosManuales`, { params });
  }

  cancelarVale(id: number, motivo: string) {
    const params = new HttpParams().set("id", id).set("motivo", motivo);
    return this.http.get<Result>(`${this.baseUrl}/Tesoreria/CancelarVale`, { params });
  }

  guardarCheque(ent: Cheque, poliza: MovimientoPoliza[]) {
    const pmts = {
      Cheque: ent,
      MovimientosPoliza: poliza
    }
    return this.http.post<Result>(`${this.baseUrl}/Tesoreria/GuardarCheque`, pmts)
  }

  guardarTransferencia(ent: Transferencia, poliza: MovimientoPoliza[]) {
    const pmts = {
      Transferencia: ent,
      MovimientosPoliza: poliza
    }
    return this.http.post<Result>(`${this.baseUrl}/Tesoreria/GuardarTransferencia`, pmts)
  }

  findEntityByParams(idEmpresa: number, idTipo: number, folio: number) {
    const params = new HttpParams()
      .set("idEmpresa", idEmpresa)
      .set("idTipo", idTipo)
      .set("folio", folio);
    return this.http.get<Result>(`${this.baseUrl}/Tesoreria/ObtenerTransferenciaPorParametros`, { params });
  }

  obtenerTipoPoliza(idTipo: number) {
    const params = new HttpParams()
      .set("idTipo", idTipo);
    return this.http.get<Result>(`${this.baseUrl}/Tesoreria/ObtenerTipoPoliza`, { params });
  }

  findChequeByParams(idEmpresa: number, idTipo: number, folio: number) {
    const params = new HttpParams()
      .set("idEmpresa", idEmpresa)
      .set("idTipo", idTipo)
      .set("folio", folio);
    return this.http.get<Result>(`${this.baseUrl}/Tesoreria/ObtenerChequePorParametros`, { params });
  }

  cancelarTransferencia(idTrans: number, motivo: string) {
    const params = new HttpParams()
      .set("idTrans", idTrans)
      .set("motivo", motivo);
    return this.http.get<Result>(`${this.baseUrl}/Tesoreria/CancelarTransferencia`, { params });
  }

  cancelarCheque(idTrans: number, motivo: string) {
    const params = new HttpParams()
      .set("idCheque", idTrans)
      .set("motivo", motivo);
    return this.http.get<Result>(`${this.baseUrl}/Tesoreria/CancelarCheque`, { params });
  }

  findEntityById(id: number) {
    const params = new HttpParams()
      .set("id", id);
    return this.http.get<Result>(`${this.baseUrl}/Tesoreria/ObtenerTransferenciaPorId`, { params });
  }

  findChequeById(id: number) {
    const params = new HttpParams()
      .set("id", id);
    return this.http.get<Result>(`${this.baseUrl}/Tesoreria/ObtenerChequePorId`, { params });
  }

  buscarTerminalesPorSucursal(idSucursal: number): Observable<Result> {
    const params = new HttpParams().set("idSucursal", idSucursal);
    return this.http.get<Result>(`${this.baseUrl}/Tesoreria/ObteneTerminalesPorSucursal`, { params })
  }

  getSiguienteFolio(idEmpresa: number, idTipo: number): Observable<number> {
    const params = new HttpParams()
      .set("idEmpresa", idEmpresa)
      .set("idTipo", idTipo);
    return this.http.get<number>(`${this.baseUrl}/Tesoreria/ObtenerSiguienteFolio`, { params })
  }

  getSiguienteFolioCheque(idEmpresa: number, idTipo: number): Observable<number> {
    const params = new HttpParams()
      .set("idEmpresa", idEmpresa)
      .set("idTipo", idTipo);
    return this.http.get<number>(`${this.baseUrl}/Tesoreria/ObtenerSiguienteFolioCheque`, { params })
  }

  buscarCfdi(seriefolio: string, rfcProveedor: string): Observable<DescargaCfdi> {
    const params = new HttpParams().set("serieFolio", seriefolio).set("rfcProveedor", rfcProveedor);
    return this.http.get<DescargaCfdi>(`${this.baseUrl}/Fiscal/ObtenerCfdiPorSerieFolio`, { params })
  }

  obtenerDiotPorId(id: number): Observable<Diot> {
    const params = new HttpParams()
      .set("id", id)
    return this.http.get<Diot>(`${this.baseUrl}/Tesoreria/ObtenerDiotPorId`, { params })
  }

  guardarDiot(jsonDiot: string, idPoliza: number, numeroPoliza: number, idTipoPoliza: number): Observable<Result> {
    const params = new HttpParams()
      .set("jsonDiot", jsonDiot)
      .set("idPoliza", idPoliza)
      .set("numeroPoliza", numeroPoliza)
      .set("idTipoPoliza", idTipoPoliza);
    return this.http.get<Result>(`${this.baseUrl}/Tesoreria/GuardarDiot`, { params })
  }

  buscarProveedor(idEmpresa: number, clave: number): Observable<Proveedor> {
    const params = new HttpParams().set("idEmpresa", idEmpresa).set("clave", clave);
    return this.http.get<Proveedor>(`${this.baseUrl}/Compras/BuscarProveedorPorClave`, { params })
  }

  obtenerMovimientosContabilidad(tipo: number): Observable<MovimientoConciliacion[]> {
    const params = new HttpParams().set("tipo", tipo);
    return this.http.get<MovimientoConciliacion[]>(`${this.baseUrl}/Tesoreria/ObtenerMovimientosContabilidad`, { params });
  }

  eliminarDetalle(id: number): Observable<Result> {
    const params = new HttpParams().set("id", id);
    return this.http.get<Result>(`${this.baseUrl}/Tesoreria/EliminarDetalle`, { params });
  }

  actualizarMovimientos(ids: string, anterior: number, cargos: number, abonos: number, actual: number): Observable<Result> {
    const params = new HttpParams()
      .set("ids", ids)
      .set("anterior", anterior)
      .set("cargos", cargos)
      .set("abonos", abonos)
      .set("actual", actual);
    return this.http.get<Result>(`${this.baseUrl}/Tesoreria/ActualizarMovimientos`, { params });
  }

  buscarCajaChica(idTipo: number, fecha: string): Observable<CajaChica> {
    const params = new HttpParams().set("idTipo", idTipo).set("strFecha", fecha);
    return this.http.get<CajaChica>(`${this.baseUrl}/Tesoreria/ObtenerCajaChica`, { params });
  }

  obtenerConfiguracionConciliacion(idEmpresa: number, idUsuario: number): Observable<Result> {
    const params = new HttpParams().set("idEmpresa", idEmpresa).set("idUsuario", idUsuario);
    return this.http.get<Result>(`${this.baseUrl}/Tesoreria/ObtenerUltimaConfiguracionPorEmpresaUsuario`, { params });
  }

  generarDiot(mesini: number, mesfin: number, anio: number): Observable<any> {
    const params = new HttpParams().set("mesini", mesini).set("mesfin", mesfin).set("anio", anio);
    return this.http.get(`${this.baseUrl}/Fiscal/GenerarDiot`, { params });
  }

}
