<app-container-base subTitle="Actualizar catálogos del SAT" [hasBar]="false" [hasCounter]="false" icon=" fas fa-sync">
  <div class="screen-content">
    <div class="panel panel-inverse" data-sortable-id="ui-modal-notification-1">
      <div class="panel-heading ui-sortable-handle">
        <h4 class="panel-title">Actualizar los catálogos del SAT</h4>
      </div>
      <table class="table table-panel mb-0">
        <thead>
          <tr>
            <th colspan="2">Nombre del catálogo</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="align-middle">Clave de Productos y Servicios, Códigos Postales</td>
            <td width="1%"><a (click)="actualizar(1)" *ngIf="!actualizando" class="btn btn-sm btn-success w-100px">Actualizar</a>
            </td>
          </tr>
        </tbody>
      </table>



    </div>
  </div>
</app-container-base>
