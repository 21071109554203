<app-container-base [getLast]="false" (onDelete)="onDelete()" [activeButtons]="activeButtons" [entity]="myForm"
  (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)" entityName="RubroER"
  title="Rubros para Estado de Resultados" icon="fa fa-folder-tree" subTitle="Configuración de Estado de Resultados">
  <div class="screen-content">
    <span *ngIf="saving" class="loaderfact"><i class="fas fa-spinner fa-spin spin"></i></span>
    <div style="height: 41px;padding: 2px;">

      <div style="margin-left: 5px;" *ngIf="showAddFamily" (click)="addLevel()" class="btn btn-primary"><i
          class="fa fa-lg me-1 fa-plus"></i>
        Agregar Rubro [raiz]</div>

      <div style="margin-left: 5px;" (click)="addSubLevel()" *ngIf="showAddSubFam" class="btn btn-primary"><i
          class="fa fa-lg me-1 fa-plus"></i>
        Agregar Sub Rubro a <span>[{{selectedTree.nombre | uppercase}}]</span></div>
    </div>
    <form [formGroup]="myForm" autocomplete="off">
      <div class="d-flex" style="height: 100%;">

        <components-ui-tree-view (onLoad)="onLoad()" [setIsNew]="getReload" (onSelectedItem)="onSelectedItem($event)"
          [type]="'RubroER'" />

        <div style="width: 70%;padding-left: 5px;">
          <app-panel-base title="Información general" style="width: 637px;" *ngIf="this.myForm.value.clave>0">
            <div class="panel-content">
              <components-ui-number-box [tabIndex]="1" [label]="'Clave'" formControlName="clave" [widthTextColumns]="4"
                [widthLabelColumns]="3" [maxLength]="longClave" [enabled]="myForm.value.id==0" [required]="true" />
              <components-ui-text-box [tabIndex]="2" [label]="'Nombre'" formControlName="nombre" [widthTextColumns]="4"
                [widthLabelColumns]="3" [required]="true" #txtNombre />
              <components-ui-number-box [tabIndex]="3" [label]="'Orden'" formControlName="orden" [widthTextColumns]="4"
                [widthLabelColumns]="3" [maxLength]="3" [required]="true" />
                <component-ui-check-box label='Mostrar Saldo al momento' formControlName="mostrarSaldoAlMomento"
                    [tabIndex]="18" [widthLabelColumns]="3" [widthTextColumns]="4" />
            </div>
          </app-panel-base>
          <app-panel-base title="Cuentas Contables" style="width: 637px;" *ngIf="this.myForm.value.clave>0">
            <div class="panel-content">
              <div (click)="openSearch()" class="btn btn-primary d-block mb-3">Agregar Cuentas Contables</div>
              <ng-scrollbar style="height: 250px; width:600px; border-bottom: 1px solid #AAAAAA;">
                <table class="blueTable" style="width:590px;">
                  <thead style="position: sticky;top:0;">
                    <tr>
                      <th style="width: 20px;"></th>
                      <th style="width: 20px;">Orden</th>
                      <th style="width: 50px;">Cuenta</th>
                      <th style="width: 120px;">Nombre</th>
                    </tr>
                  </thead>
                  <tbody class="bg-light">
                    <tr
                      [style]=" indexEditing == index ?  'background-color: #ffdbb7;padding-top:5px;': 'padding-top:5px;'"
                      *ngFor="let item of listaCuentas; let index = index;">
                      <td style="text-align: center;">
                        <i (click)="deleteRow(index)" class="fa fa-rectangle-xmark fa-1x"
                          style="color:red;font-weight: bold; cursor: pointer;"></i>
                      </td>
                      <td class="text-dark" style="width:80px">
                        {{ item.orden }}
                      </td>
                      <td class="text-dark" style="width:80px">
                        {{ item.cuenta!.claveGeneral }}
                      </td>
                      <td class="text-dark" style="width:120px;">
                        {{ item.cuenta!.nombre }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ng-scrollbar>
            </div>
          </app-panel-base>

        </div>
      </div>
    </form>
    <ng-template let-modal let-c="close" #seleccionar>
      <app-seleccionar-tree [onlyLevelOne]="true" [multiSelect]="true" [type]="'CuentaContable'" #seleccionar />
    </ng-template>
  </div>
</app-container-base>
