import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, ElementRef, inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Editor } from 'ngx-editor';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';
import { EventsService } from 'src/app/service/events.service';
import { FacturacionService } from 'src/app/ventas/services/facturacion.service';
import { environment } from 'src/environments/environment';
import { ConceptoOrdenManufactura, Diseno, DisenoDetalle, HistoricoOrdenManufactura, OrdenManufactura, OrdenManufacturaEnvioProduccion, ProduccionesOrdenManufacturaCabecera, SerieManufactura } from '../../interfaces/manufacura.interface';
import { Empresa } from 'src/app/configuracion/interfaces/empresa.interface';
import { Sucursal } from 'src/app/configuracion/interfaces/sucursal.interface';
import { Direccion } from 'src/app/configuracion/interfaces/direccion.interface';
import { Usuario } from 'src/app/configuracion/interfaces/usuario.interface';
import { Cliente } from 'src/app/ventas/interfaces/cliente.interface';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { ComboBoxComponent } from 'src/app/component-ui/components/combo-box/combo-box.component';
import { TabsNavService } from 'src/app/components/services/tabs-nav.service';
import { UtilsService } from 'src/app/service/utils.service';
import { Result, UserLogged } from 'src/app/auth/interfaces';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { ModalService } from 'src/app/service/modal.service';
import { InventarioService } from 'src/app/Inventarios/services/inventario.service';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import Swal from 'sweetalert2';
import { CapturaProduccionMateriaPrima } from 'src/app/home/interfaces/capturaproduccion.interface';
import { AppSettings } from 'src/app/home/services/app-settings.service';

@Component({
  selector: 'app-captura-produccion-page',
  templateUrl: './captura-produccion-page.component.html',
  styles: [
  ]
})
export class CapturaProduccionPageComponent implements OnInit {

  @ViewChild('txtRef')
  public txtRef!: ElementRef<TextBoxComponent>;

  @ViewChild('cboSerieFactura')
  public cboSerieFactura!: ElementRef<ComboBoxComponent>;
  @ViewChild('txtFolio')
  public txtFolio!: ElementRef<TextBoxComponent>;
  noVerCostos: boolean = false;
  indexEditing: number = -1;
  selectedIndex: number = -1;
  public appSettings = inject(AppSettings);
  listaOrdenes: ComboBoxEntity[] = [];
  editando: boolean = false;
  private eventsService = inject(EventsService);
  private tabsNavService = inject(TabsNavService);
  private fServices = inject(FacturacionService);
  private http = inject(HttpClient);
  info: UserLogged = {} as UserLogged;
  DisenoActual: DisenoDetalle | null = null;
  private fb = inject(FormBuilder);
  idUnico: string = '';
  private readonly baseUrl: string = environment.baseUrlApi;

  @ViewChild('ctrlEdicion')
  public ctrlEdicion!: ElementRef<HTMLElement>;


  activeButtons: ActiveButtons = {
    new: true,
    delete: false,
    return: false,
    save: false,
    first: false,
    left: false,
    right: false,
    last: false,
    search: false,
    print: true,
  }



  public myForm: FormGroup = this.fb.group({
    Id: [0],
    Empresa: [null],
    Sucursal: [null],
    Cliente: [null],
    FechaEmision: [null],
    FechaAplicacion: [null],
    FechaCancelacion: [null],
    UsuarioElabora: [null],
    UsuarioAplica: [null],
    UsuarioCancela: [null],
    SerieFactura: [null],
    FolioFactura: [0],
    IdOrden: [0],
    CostoUnitario: [0],
    CostoTotal: [0],
    Folio: [0],
    AlmacenProductoTerminado: [null],
    Aplicado: [false],
    SerieOrden: [''],
    FolioOrden: [0],
    CantidadProducir: [0],
    Tipo: [null],
    Vehiculo: [null],
    VehiculoBombeo: [null],
    Ubicacion: [null],
    Producto: [null],
    Observaciones: [''],
    MateriaPrima: [[]],
    Chofer: [null],

  });

  constructor(private iService: InventarioService, private utilsService: UtilsService, private containerService: ContainerBaseService, private ms: ModalService) { }

  selectedCombo(entity: ComboBoxEntity, tipo: string) {
    if (tipo != "Orden") {
      this.myForm.controls[tipo].setValue(entity);
    } else {
      this.myForm.controls["SerieOrden"].setValue(entity.Serie);
      this.myForm.controls["FolioOrden"].setValue(entity.Clave);
      this.myForm.controls["IdOrden"].setValue(entity.Id);
      this.eventsService.publish('home:isLoading', { isLoading: true });
      this.iService.obtenerOrdenProduccionPorId(entity.Id).subscribe((orden: any) => {
        console.log(orden);
        this.eventsService.publish('home:isLoading', { isLoading: false });
        const cli = { Id: orden.cliente.id, Clave: orden.cliente.clave, Nombre: orden.cliente.nombre, }
        const prod = { Id: orden.producto.id, Clave: orden.producto.clave, Nombre: orden.producto.nombre, }
        this.myForm.controls["Cliente"].setValue(cli);
        this.myForm.controls["Producto"].setValue(prod);
        this.myForm.controls["CantidadProducir"].setValue(orden.cantidad);


        let mp: any = [];
        let costoUnitario = 0;
        orden.disenos.forEach((d: any) => {
          d.diseno.detalle.forEach((det: any) => {
            if (det.producto.inventariable) {
              debugger
              mp = [...mp, {
                Id: 0,
                Producto: { Id: det.producto.id, Clave: det.producto.clave, Nombre: det.producto.nombre },
                Unidad: { Id: det.producto.unidad.id, Clave: det.producto.unidad.clave, Nombre: det.producto.unidad.nombre },
                Almacen: { Id: det.almacen.id, Clave: det.almacen.clave, Nombre: det.almacen.nombre },
                Cantidad: det.cantidad,
                Costo: det.costo,
                Existencia: det.existencia,
                ExistenciaSuficiente: (det.cantidad * orden.cantidad) <= det.existencia
              }]
              costoUnitario += det.cantidad * det.costo;
            }
          });
        })

        this.myForm.controls["CostoUnitario"].setValue(costoUnitario);
        this.myForm.controls["CostoTotal"].setValue(costoUnitario * orden.cantidad);
        this.myForm.controls["MateriaPrima"].setValue(mp);
      });
    }
  }

  get getItemsOrdenes(): ComboBoxEntity[] {
    return this.listaOrdenes;
  }

  aplicarProduccion() {


    let errorExistencia = false;
    this.myForm.value.MateriaPrima.map((item: CapturaProduccionMateriaPrima) => {
      if (!item.ExistenciaSuficiente) {
        errorExistencia = true;
      }
      return item;
    })

    if (errorExistencia) {
      this.eventsService.publish('home:showAlert', { message: 'Verifique las existencias ya que no se cuenta con la suficiente para realizr la producción.', cancelButton: false });
      return;
    }

    this.eventsService.publish('home:showAlert', {
      message: '¿Está seguro de aplicar la producción? Se van a generar movimientos de entrada de producto terminado y la salida de la Materia prima según la configuración del diseño',
      onConfirm: (r: AlertResponse) => {
        if (r.isAccept) {
          this.eventsService.publish('home:isLoading', { isLoading: true });
          this.iService.aplicaProduccion(this.myForm.value.Id).subscribe((result) => {
            if (result.success) {
              this.iService.obtenerCapturaPorId(this.myForm.value.Id).subscribe((r) => {
                this.eventsService.publish('home:isLoading', { isLoading: false });
                Swal.fire({ position: 'center', icon: 'success', title: 'Se aplicó la producción correctamente, verifique los Movimientos de Inventario.', showConfirmButton: false, timer: 5000 });
                const ent = JSON.parse(r.message);
                this.myForm.reset(ent);
              });
            } else {
              this.eventsService.publish('home:isLoading', { isLoading: false });
              this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
            }
          })
        }
      }
    });
  }


  blurFolio(values: any) {
    if (values.after != values.before) {
      this.eventsService.publish('home:isLoading', { isLoading: true });
      setTimeout(() => {
        const ent = this.myForm.value;
        this.listaOrdenes = [];
        if (ent.Folio > 0) {
          this.iService.obtenerCapturaPorFolio(this.info.empresa!.numero, this.info.sucursal!.numero, ent.Folio).subscribe((result) => {
            this.eventsService.publish('home:isLoading', { isLoading: false });
            if (result) {
              const ent = JSON.parse(result.message);
              this.myForm.reset(ent);
            } else {
              this.new();
            }
          })
        } else {
          this.new();
        }
      }, 50);
    }
  }

  blurFolioFactura(values: any) {
    if (values.after != values.before) {
      this.eventsService.publish('home:isLoading', { isLoading: true });
      setTimeout(() => {
        const ent = this.myForm.value;
        this.listaOrdenes = [];
        if (ent.FolioFactura > 0) {
          this.iService.obtenerListaOrdenesPorFactura(ent.SerieFactura.Id, ent.FolioFactura, false).subscribe((result) => {
            this.eventsService.publish('home:isLoading', { isLoading: false });
            const lista: ComboBoxEntity[] = JSON.parse(result.message);
            if (lista.length > 0) {
              this.listaOrdenes = lista;
            } else {
              this.eventsService.publish('home:showAlert', { message: "No se encontraron ordenes de producción asociadas a la Factura indicada<br> - La factura no existe o está cancelada<br>- No hay ordenes de producción nuevas", cancelButton: false });
            }
          })
        } else {
          this.myForm.controls["SerieOrden"].setValue("");
          this.myForm.controls["FolioOrden"].setValue(0);
          this.myForm.controls["IdOrden"].setValue(0);
          this.eventsService.publish('home:showAlert', { message: "Indique un Folio de Factura valido.", cancelButton: false });
        }
      }, 50);
    }
  }

  ngOnInit(): void {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.idUnico = String(new Date().getTime() * 10000);
    this.info = this.utilsService.getUserLogged();
    this.new();

    this.containerService.obtenerValorPropiedad("Usuario", "Rol.NoVerCostos", this.info.numero).subscribe((value) => {
      this.noVerCostos = value.toLowerCase() == "true";
    });


  }


  escapeCantidad() {
    this.initEditor(this.indexEditing, 1);
  }

  enterCantidad(e: any) {
    let cant: number = 0;
    if (e.target.value) {
      cant = parseFloat(e.target.value);
    }
    if (cant == 0) {
      e.preventDefault();
      return;
    }
    e.target.blur();
  }

  getEditorClass() {
    return this.appSettings.appDarkMode ? 'focus-editor-grid-dark' : 'focus-editor-grid';
  }

  blurCantidad(e: any) {

    e.target.classList.remove(this.getEditorClass());

    const cantidad = parseFloat(e.target.value);
    let item = this.myForm.value.MateriaPrima[this.indexEditing];
    item.Cantidad = cantidad;
    this.myForm.value.MateriaPrima[this.indexEditing] = { ...item };



    let costoUnitario = 0;
    this.myForm.value.MateriaPrima.map((det: CapturaProduccionMateriaPrima) => {
      costoUnitario += det.Cantidad * det.Costo!;
      det.ExistenciaSuficiente = (det.Cantidad * this.myForm.value.CantidadProducir) <= det.Existencia!;
      return det;
    });
    this.myForm.controls["MateriaPrima"].setValue([...this.myForm.value.MateriaPrima])
    this.myForm.controls["CostoUnitario"].setValue(costoUnitario);
    this.myForm.controls["CostoTotal"].setValue(costoUnitario * this.myForm.value.CantidadProducir);


  }

  setIndexEdit(index: number, e: any, item: CapturaProduccionMateriaPrima) {
    this.indexEditing = index;
    e.target.select();
    e.target.classList.add(this.getEditorClass());
  }



  initEditor(row: number, col: number) {
    const input = document.getElementById(`txt_${row}_${col}${this.idUnico}`)!;
    if (input) input.focus();
  }

  new() {
    this.containerService.getEmptyEntity("CapturaProduccion").subscribe((elem) => {
      elem.Empresa = { Id: this.info.empresa!.numero, Clave: this.info.empresa!.clave, Nombre: this.info.empresa!.nombre };
      elem.Sucursal = { Id: this.info.sucursal!.numero, Clave: this.info.sucursal!.clave, Nombre: this.info.sucursal!.nombre };
      elem.FechaEmision = new Date();
      this.iService.obtenerSiguienteFolioCapturaProduccion(this.info.empresa!.numero, this.info.sucursal!.numero).subscribe((result) => {
        elem.Folio = Number(result.message);
        this.myForm.reset(elem);
        this.eventsService.publish('home:isLoading', { isLoading: false });
        const t: any = this.cboSerieFactura;
        setTimeout(() => {
          t.tagInput.nativeElement.focus();
        }, 100);
      })
    })
  }
  save() {

    const ent = this.myForm.value;

    if (ent.Aplicado) {
      this.eventsService.publish('home:showAlert', { message: 'La Captura de Producción ya fue Aplicada anteriormente, verifique.', cancelButton: false });
      return;
    }

    if (ent.IdOrden == 0) {
      this.eventsService.publish('home:showAlert', { message: 'Debe de Seleccionar Factura y Orden de Producción.', cancelButton: false });
      return;
    }

    if (!ent.Vehiculo) {
      this.eventsService.publish('home:showAlert', { message: 'Debe de Seleccionar un Vehículo.', cancelButton: false });
      return;
    }
    if (!ent.Chofer) {
      this.eventsService.publish('home:showAlert', { message: 'Debe de Seleccionar un Chofer.', cancelButton: false });
      return;
    }
    if (!ent.Ubicacion) {
      this.eventsService.publish('home:showAlert', { message: 'Debe de Seleccionar una Ubicación.', cancelButton: false });
      return;
    }
    if (!ent.AlmacenProductoTerminado) {
      this.eventsService.publish('home:showAlert', { message: 'Debe de Seleccionar el Almacén para el Producto Terminado.', cancelButton: false });
      return;
    }
    if (!ent.Tipo) {
      this.eventsService.publish('home:showAlert', { message: 'Debe de Seleccionar el Tipo.', cancelButton: false });
      return;
    }

    if (ent.MateriaPrima.length == 0) {
      this.eventsService.publish('home:showAlert', { message: 'Debe de indicar la Materia Prima.', cancelButton: false });
      return;
    }



    this.eventsService.publish('home:isLoading', { isLoading: true });
    const json = JSON.stringify(ent);
    this.containerService.saveEntity("CapturaProduccion", json).subscribe((result: Result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result.success) {
        const obj = JSON.parse(result.message);
        this.myForm.reset(obj);
        Swal.fire({ position: 'center', icon: 'success', title: 'Se realizo la captura de la producción correctamente', showConfirmButton: false, timer: 2000 });
      } else {
        this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
      }
    });
  }

  downloadPdf(base64: string) {
    this.fServices.printDocument(this.fServices.base64ToArrayBuffer(base64));
  }

  print() {
    const params = new HttpParams().set("idOrden", this.myForm.value.Id);
    this.http.get<any>(`${this.baseUrl}/Manufactura/ImprimirCapturaDeProduccion`, { params }).subscribe((result) => {
      if (result.success) {
        this.downloadPdf(result.message);
      }
    });



    // this.fServices.imprimirDocumento(ent.Id, mostrarNoValido, imprimirEntrega).subscribe((result) => {
    //   this.eventsService.publish('home:isLoading', { isLoading: false });
    //   if (result.success) {
    //     const serie: ComboBoxEntity = this.listaSeries.filter(P => P.Id == this.myForm.value.Serie.Id)[0];
    //     if (!serie.UsaImpresora) {
    //       this.downloadPdf(result.message);
    //     } else if (serie.UsaImpresora) {
    //       if (serie.NombreImpresora) {
    //         //Aqui va el código para envíar a la impresora
    //         //En el result.message está el b64 del .PDF
    //         this.printerService.printPdfB64(result.message, serie.NombreImpresora);
    //       } else {
    //         this.eventsService.publish('home:showAlert', { message: `No ha definido el nombre de la impresora en la Serie de Ventas, verifique con el administrador.`, cancelButton: false });
    //       }
    //     }
    //     if (!mostrarNoValido) {
    //       ent.Impreso = true;
    //       this.myForm.reset(ent);
    //       cb && cb();
    //     }
    //   } else {
    //     this.eventsService.publish('home:showAlert', { message: `${result.message}`, cancelButton: false });
    //   }
    // })



  }

  onClickBarButton(button: string): void {
    switch (button) {
      case "new": this.new(); break;
      case "save": this.save(); break;
      case "print": this.print(); break;
    }
  }


}
