import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { ActiveButtons, EventReturn } from 'src/app/component-ui/interfaces/container-base.interface';
import { Empresa } from 'src/app/configuracion/interfaces/empresa.interface';
import { environment } from 'src/environments/environment';
import { Rubro, RubroCuentaContable } from '../../interfaces/rubro.interface';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from 'src/app/service/utils.service';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { RubroService } from '../../services/rubro.service';
import { CuentaContable } from '../../interfaces/cuentacontable.interface';
import { ModalService } from 'src/app/service/modal.service';
import { orderBy } from "lodash";
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';
import { EventsService } from 'src/app/service/events.service';

@Component({
  selector: 'app-rubros-page',
  templateUrl: './rubros-page.component.html',
  styleUrls: ['./rubros-page.component.scss']
})
export class RubrosPageComponent {

  @ViewChild('txtNombre')
  public txtNombre!: ElementRef<TextBoxComponent>;
  @ViewChild('seleccionar') public ctrlSeleccionar!: ElementRef<HTMLElement>;

  info: any;
  file: any;
  longClave: number = 0;
  saving: boolean = false;
  private readonly baseUrl: string = environment.baseUrlApi;
  listaCuentas: RubroCuentaContable[] = [];
  selectedTree: any = null;
  entityAfterSave: any = null;
  reload: boolean = false;
  indexEditing: number = -1;

  private eventsService = inject(EventsService);
  activeButtons: ActiveButtons = {
    new: false,
    delete: true,
    return: false,
    save: true,
    first: false,
    left: false,
    right: false,
    last: false,
    search: false,
    print: true,
  }

  public myForm: FormGroup = this.fb.group({
    id: [0],
    clave: [0],
    nombre: [''],
    numeroNivel: [0],
    orden: [0],
    empresa: [{} as Empresa],
    padre: [{} as Rubro],
    cuentas: [[] as RubroCuentaContable[]],
    eliminado: [false],
    baja: [false],
    aplicarEstadoDeResultados:[false]
  });

  constructor(private http: HttpClient,
    private ms: ModalService,
    private fb: FormBuilder,
    private containerService: ContainerBaseService,
    private rubroService: RubroService,
    private utileService: UtilsService) {
  }

  ngOnInit(): void {
    this.info = this.utileService.getUserLogged();
    this.myForm.reset(null);
  }

  selectedObjeto(entity: ComboBoxEntity, type: string) {
    this.myForm.controls[type].setValue(entity);
  }

  onLoad() {
    this.reload = false;
    this.entityAfterSave = null;
  }

  onDelete() {
    this.reload = true;
    this.entityAfterSave = null;
  }

  get getReload(): boolean {
    return this.reload;
  }

  get getEntityAfterSave(): Rubro {
    return this.entityAfterSave;
  }

  addSubLevel() {
    let level = this.selectedTree.numeroNivel + 1;
    let idPadre = this.selectedTree.id;
    this.longClave = this.rubroService.getLongLevel(this.info.empresa.numero, level);
    this.rubroService.getNextClave(this.info.empresa.numero, level, idPadre).subscribe((clave) => {
      this.containerService.getEmptyEntityNoJson("Rubro").subscribe((elem) => {
        this.listaCuentas = [];
        elem.clave = clave;
        elem.numeroNivel = level;
        elem.padre = { id: idPadre };
        this.myForm.reset(elem);
        setTimeout(() => {
          this.focus("txtNombre")
        }, 150);
      })
    })


  }

  cellEditSubmitted() {
    const sorted = orderBy(this.listaCuentas, ["orden"], ["asc"]);
    this.listaCuentas = [...sorted];
  }

  deleteRow(index: number) {

    this.eventsService.publish('home:showAlert', {
      message: '¿Está seguro que desea eliminar la cuenta de la lista?',
      cancelButton: true,
      textAccept: "Sí, Eliminar la cuenta",
      textCancel: "Cancelar",
      onConfirm: (data: AlertResponse) => {
        if (data.isAccept) {
          this.listaCuentas.splice(index, 1);
          this.listaCuentas.map((item: RubroCuentaContable, index: number) => {
            item.orden = index + 1;
            return item;
          });
          this.listaCuentas = [...this.listaCuentas];
          this.myForm.controls["cuentas"].setValue(this.listaCuentas);
        }
      }
    });




  }


  openSearch() {
    const b: any = this.ctrlSeleccionar;
    this.ms.openModal(b, (cuentasSeleccionadas: any[]) => {
      if (cuentasSeleccionadas) {
        this.listaCuentas = this.myForm.value.cuentas;
        if (!this.listaCuentas) {
          this.listaCuentas = [];
        }

        cuentasSeleccionadas.forEach((cuenta) => {
          let pos: number[] = [];
          this.listaCuentas.forEach((item) => {
            pos = [...pos, item.orden]
          })
          let max = 0;
          if (pos.length > 0) {
            max = Math.max(...pos);
          }
          let count = this.listaCuentas.filter(P => P.cuenta!.id == cuenta.id).length;
          if (count == 0) {
            this.listaCuentas = [...this.listaCuentas, { id: 0, cuenta: cuenta, orden: max + 1, padre: null }];
          }
        })

        this.myForm.controls["cuentas"].setValue(this.listaCuentas);
      }
    }, 'widthfamilia')
  }

  addLevel() {
    let level = 1;
    let idPadre = 0;
    if (this.selectedTree) {
      level = this.selectedTree.numeroNivel;
    }

    this.longClave = this.rubroService.getLongLevel(this.info.empresa.numero, level);
    this.rubroService.getNextClave(this.info.empresa.numero, level, idPadre).subscribe((clave) => {
      this.containerService.getEmptyEntityNoJson("Rubro").subscribe((elem) => {
        this.listaCuentas = [];
        elem.clave = clave;
        elem.orden = clave;
        elem.numeroNivel = 1;
        this.myForm.reset(elem);
        setTimeout(() => {
          this.focus("txtNombre")
        }, 150);
      })
    })
  }

  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombre;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  get showAddFamily(): boolean {

    if (this.selectedTree) {
      if (this.selectedTree.padre) {
        return false;
      }
    }
    return true;
  }

  get showAddSubFam(): boolean {

    if (this.selectedTree) {
      if (this.selectedTree.numeroNivel <= 3) {
        return true;
      }
    }
    return false;
  }


  onSelectedItem(item: Rubro) {
    if (!item.cuentas) item.cuentas = [];
    this.listaCuentas = item.cuentas;
    this.selectedTree = { ...item };
    this.myForm.reset(item);
    const txt: any = this.txtNombre;
    txt.tagInput.nativeElement.focus()
  }

  onBeforeSave(param: EventReturn) {
    const ent = this.myForm.value;
    if (!ent) { param.callback(false); return; }
    if (ent.clave == 0) { param.callback(false); return; }
    if (ent.cuentas) {
      ent.cuentas.map((cuenta: RubroCuentaContable) => {
        cuenta.id = 0;
        return cuenta;
      })
    }
    this.rubroService.deleteAccounts(ent.id).subscribe(() => {
      param.callback(true);
    })
  }

  onAfterSave(entity: Rubro) {
    this.myForm.reset(entity);
    this.selectedTree = null;
    this.entityAfterSave = entity;
    this.reload = true;
  }


}
