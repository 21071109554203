import { Component, ElementRef, ViewChild, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { EventReturn } from 'src/app/component-ui/interfaces/container-base.interface';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { TreeNode } from 'primeng/api';
import { AppMenuService } from 'src/app/home/services/app-menus.service';
import { RolMenu } from '../../interfaces/rolmenu.interface';
import { Rol } from '../../interfaces/rol.interface';
import { CheckBoxList, MenuItem } from 'src/app/component-ui/interfaces/checkboxlist.interface';
import Swal from 'sweetalert2';
import { UtilsService } from '../../../service/utils.service';
import { ReportsService } from 'src/app/service/reports.service';
import { Menu } from 'src/app/components/interfaces/menu.interface';

@Component({
  selector: 'app-rol-page',
  templateUrl: './rol-page.component.html',
  styles: [
  ]
})

export class RolPageComponent implements OnInit {
  // los que traen todos los menus
  parentItems!: CheckBoxList[];
  childItems!: CheckBoxList[];

  menus: MenuItem[] = [];
  menuLoad: Menu[] = [];
  listaCompleta!: any[];
  itemsCombo: ComboBoxEntity[] = [];
  permiteGuardar: boolean = false;
  permiteEliminar: boolean = false;
  childItemActual: CheckBoxList = {} as CheckBoxList;
  parentItemActual: CheckBoxList = {} as CheckBoxList;
  opcionesVisibles: boolean = false;
  opcionesActivas: boolean = false;

  private newEntityObjOpciones = {
    permiteGuardar: [false],
    permiteEliminar: [false]
  };

  private newEntityObj = {
    Id: [0],
    Clave: [0],
    Nombre: ['', [Validators.required]],
    EsGerenteSupervisor: [false],
    ComprobantesVentasPermisos: [''],
    ComprobantesVentasCompras: [''],
    ComprobantesInventarios: [''],
    SeriesVentas: [''],
    SeriesCompras: [''],
    SeriesInventarios: [''],
    VerConfiguraciones: [false],
    ReimprimirComprobantesVentas: [false],
    PermitirCancelarVentas: [false],
    BloquearFolio: [false],
    DesbloquearCortes: [false],
    NoActivarOpcionesCredito: [false],
    NoVerCostos: [false],
    ReemplazarPolizas: [false],
    VerTableroVentas: [false],
    VerTableroPowerBI: [false],
    VerTableroGastos: [false],
    VerBalance: [false],
    VerEstadoResultados: [false],
    VerTableroMisVentas: [false],
    CambiarVendedor: [false],
    CambiarEntrega: [false],
    CambiarCuentaVenta: [false],
    NoAplicarFechaProceso: [false],
    VerChat: [false],
    EnviarMensajesChat: [false],
    UrlPowerBI: [''],
    Eliminado: [false],
    Baja: [false],
    Menu: [{} as RolMenu],
    PuedeCancelarSalidaPorVenta: false
  };

  public myForm: FormGroup = this.fb.group(this.newEntityObj);

  @ViewChild('txtNombre')
  public txtNombre!: ElementRef<TextBoxComponent>;

  @ViewChild('children')
  public children!: ElementRef<TextBoxComponent>;

  private reportsService = inject(ReportsService);
  private utilsService = inject(UtilsService);
  constructor(private fb: FormBuilder, private containerService: ContainerBaseService, private menuService: AppMenuService) {
  }

  cambiar() {
    this.permiteGuardar = !this.permiteGuardar;

  }

  actualizaMenu(key: number, value: boolean, eliminar: boolean, guardar: boolean) {
    let RolMenu: RolMenu[] = this.myForm.controls["Menu"].value;
    let item = RolMenu.find(p => p.IdMenu === key);
    if (item) {
      RolMenu.map(p => {
        if (p.IdMenu === key) {
          p.Activado = value;
          p.Eliminar = eliminar;
          p.Guardar = guardar;
        }
        return p;
      });
    } else {
      let menu: RolMenu;
      let idMenu: number = 0;
      idMenu = key;
      menu = {
        IdMenu: idMenu,
        Guardar: guardar,
        Eliminar: eliminar,
        Activado: value,
        Eliminado: false,
        Padre: { Id: this.myForm.controls["Id"].value } as Rol,
        Id: 0
      }
      RolMenu.push(menu);
    }
    this.myForm.controls["Menu"].setValue(RolMenu);
  }

  getRolMenuItem(lista: RolMenu[], id: number): RolMenu | undefined {
    let item: RolMenu | undefined
    item = lista.find(f => f.IdMenu === id);

    return item;
  }

  onItemChange(value: CheckBoxList) {
    this.actualizaMenu(value.Id, value.Value!, this.permiteEliminar, this.permiteGuardar);
    this.opcionesVisibles = true;
    this.opcionesActivas = value.Value as boolean;

    let listaRolMenu: RolMenu[] = this.myForm.controls["Menu"].value;
    let item: RolMenu | undefined = this.getRolMenuItem(listaRolMenu, value.Id);
    this.permiteEliminar = item!.Eliminar;
    this.permiteGuardar = item!.Guardar!;
    this.childItemActual = value;

    let todosMarcados: boolean = true;
    this.childItems.forEach(item => {
      if (item.Id == value.Id) {
        item.Value = value.Value;
      }
      if (!item.Value) {
        todosMarcados = false;
      }
    })

    // if (todosMarcados) {
    let itemSel: CheckBoxList | undefined;
    itemSel = this.parentItems.find(item => item.Id === this.parentItemActual.Id);
    if (itemSel) {
      itemSel.Value = todosMarcados;
    }
  }

  onItemParentChange(value: CheckBoxList) {
    this.actualizaMenu(value.Id, value.Value!, this.permiteEliminar, this.permiteGuardar);
    this.opcionesVisibles = true;

    let listaRolMenu: RolMenu[] = this.myForm.controls["Menu"].value;
    let item: RolMenu | undefined = this.getRolMenuItem(listaRolMenu, value.Id);
    this.permiteEliminar = item!.Eliminar;
    this.permiteGuardar = item!.Guardar!;
    this.childItemActual = value;
  }

  onClickActivarGuardar(value: boolean) {
    this.permiteGuardar = value;
    this.actualizaMenu(this.childItemActual.Id, this.childItemActual.Value!, this.permiteEliminar, this.permiteGuardar);
  }

  onClickActivarEliminar(value: boolean) {
    this.permiteEliminar = value;
    this.actualizaMenu(this.childItemActual.Id, this.childItemActual.Value!, this.permiteEliminar, this.permiteGuardar);
  }


  selectItemChildren(value: CheckBoxList) {
    let listaRolMenu: RolMenu[] = this.myForm.controls["Menu"].value;
    if (listaRolMenu) {
      let item: RolMenu | undefined = this.getRolMenuItem(listaRolMenu, value.Id);
      if (!item) {
        item = {
          Id: 0,
          IdMenu: value.Id,
          Guardar: false,
          Eliminar: false,
          Activado: false,
          Eliminado: false,
          Padre: { Id: this.myForm.controls["Id"].value } as Rol
        }
        listaRolMenu.push(item);
      }
      this.permiteEliminar = item!.Eliminar;
      this.permiteGuardar = item!.Guardar!;
    }
    this.childItemActual = value;
    this.opcionesVisibles = value.Id > 0;
    this.opcionesActivas = value.Value as boolean;

  }

  getParents(arr: TreeNode[]): any[] {
    let result: TreeNode[] = [];
    let nodos = arr.map(obj => ({ ...obj }))
    nodos.forEach((item: TreeNode) => {
      if (item.children) { item.children = undefined }
      result.push(item);
    })
    return result;
  }

  getChildren(id: number, arr: any[]): CheckBoxList[] {
    let result: CheckBoxList[] = [];
    let tieneSubMenu: boolean = false;
    let ruta: string = '';
    let subruta: string = '';
    let subsubruta: string = '';

    arr.forEach((item) => {
      if (item.id === id) {
        if (item.submenu) {
          item.submenu.forEach((item2: any) => {
            tieneSubMenu = false;
            if (item2.submenu) {
              tieneSubMenu = item2.submenu.length;
            }
            if (tieneSubMenu) {
              item2.submenu.forEach((item3: any) => {
                subruta = item2.title;
                tieneSubMenu = false;
                if (item3.submenu) {
                  tieneSubMenu = item2.submenu.length;
                }
                if (!tieneSubMenu) {
                  const hijo1: CheckBoxList = {
                    Id: item3.id,
                    Clave: item3.id,
                    Nombre: subruta + "\\" + item3.title,
                    Value: false
                  }
                  result.push(hijo1);
                  subruta = "";
                  subsubruta = "";
                }
              });
            } else {
              const hijo: any = {
                Id: item2.id,
                Nombre: item2.title,
                Clave: item2.id,
                Value: false
              }
              result.push(hijo);
              subruta = "";
            }
          });
        }
      }
    })
    return result;
  }

  onSelect(item: any) {
    item.IsExpanded = !item.IsExpanded;
    this.menus = [...this.menus];
  }

  seleccionar(item: MenuItem) {
    item.Seleccionado = !item.Seleccionado;
    if (item.SubMenus) {
      item.SubMenus.map((item2) => {
        item2.Seleccionado = item.Seleccionado;
        if (item2.SubMenus) {
          item2.SubMenus.map((item3) => {
            item3.Seleccionado = item.Seleccionado;
          });
        }
        return item2;
      });
    }
    this.menus = [...this.menus];
  }

  activarAccion(item: MenuItem, accion: number) {
    if (accion === 0) {
      item.Eliminar = !item.Eliminar;
    } else {
      item.Guardar = !item.Guardar;
    }
  }

  getCaret(item: MenuItem, lastLevel: boolean = false): string {
    let ddclass: string = `fas fa-${(item.IsExpanded ? 'caret-down' : 'caret-right')}  fa-lg`;
    if (lastLevel) {
      ddclass = " mleft";
    }
    return ddclass;
    //return "";
  }

  ngOnInit(): void {

  }

  initItems() {
    this.parentItems.map(p => {
      p.Value = false;
      return p
    })

    this.childItems = this.getChildren(this.parentItems[0].Id, this.listaCompleta);
    this.parentItemActual = this.parentItems[0];
  }


  onBeforeSave(param: EventReturn) {
    const ent = this.myForm.value;
    let listaMenus: RolMenu[] = [];
    this.menus.forEach((i) => {
      if (i.Seleccionado) {
        listaMenus = [...listaMenus, {
          Id: 0,
          IdMenu: i.Id,
          Guardar: i.Guardar,
          Eliminar: i.Eliminar,
          Activado: i.Seleccionado,
          Eliminado: false,
          Padre: null,
        }]
      }

      if (i.SubMenus) {
        i.SubMenus.map((sm) => {
          if (sm.Seleccionado) {
            listaMenus = [...listaMenus, {
              Id: 0,
              IdMenu: sm.Id,
              Guardar: sm.Guardar,
              Eliminar: sm.Eliminar,
              Activado: sm.Seleccionado,
              Eliminado: false,
              Padre: null
            }]
          }

          if (sm.SubMenus) {
            sm.SubMenus.forEach((sm2) => {
              if (sm2.Seleccionado) {
                listaMenus = [...listaMenus, {
                  Id: 0,
                  IdMenu: sm2.Id,
                  Guardar: sm2.Guardar,
                  Eliminar: sm2.Eliminar,
                  Activado: sm2.Seleccionado,
                  Eliminado: false,
                  Padre: null
                }]
              }
              return sm2;
            })
          }
        })
      }
    })
    if (listaMenus.length == 0) {
      Swal.fire({ text: `Debe de indicar las opciones de menú.`, icon: 'error', })
      param.callback(false);
      return;
    }
    this.myForm.controls["Menu"].setValue(listaMenus);
    param.callback(true);
  }

  onAfterSave(entity: Rol) {
    this.myForm.reset(entity);
  }

  fillMenus(menus: RolMenu[]) {
    if (menus) {
      menus.forEach((r) => {
        this.menus.map((i) => {
          if (i.Id == r.IdMenu && r.Activado) {
            i.Seleccionado = true;
            i.Guardar = r.Guardar;
            i.Eliminar = r.Eliminar;
          } else {
            if (i.SubMenus) {
              i.SubMenus.map((sm) => {
                if (sm.Id == r.IdMenu && r.Activado) {
                  sm.Seleccionado = true;
                  sm.Guardar = r.Guardar;
                  sm.Eliminar = r.Eliminar;
                }
                if (sm.SubMenus) {
                  sm.SubMenus.forEach((sm2) => {
                    if (sm2.Id == r.IdMenu && r.Activado) {
                      sm2.Seleccionado = true;
                      sm2.Guardar = r.Guardar;
                      sm2.Eliminar = r.Eliminar;
                    }
                    return sm2;
                  })
                }
                return sm;
              })
            }
          }
          return i;
        })
      })
      this.menus = [...this.menus]
    }
    this.utilsService.isLoad(false);
  }

  todos(seleccionado: boolean) {
    this.menus.map((i) => {
      i.Seleccionado = seleccionado;
      if (i.SubMenus) {
        i.SubMenus.map((sm) => {
          sm.Seleccionado = seleccionado;
          if (sm.SubMenus) {
            sm.SubMenus.forEach((sm2) => {
              sm2.Seleccionado = seleccionado;
              return sm2;
            })
          }
          return sm;
        })
      }
      return i;
    })
    this.menus = [...this.menus]
  }

  todosGuardar(seleccionado: boolean) {
    this.menus.map((i) => {
      i.Guardar = seleccionado;
      if (i.SubMenus) {
        i.SubMenus.map((sm) => {
          sm.Guardar = seleccionado;
          if (sm.SubMenus) {
            sm.SubMenus.forEach((sm2) => {
              sm2.Guardar = seleccionado;
              return sm2;
            })
          }
          return sm;
        })
      }
      return i;
    })
    this.menus = [...this.menus]
  }

  todosEliminar(seleccionado: boolean) {
    this.menus.map((i) => {
      i.Eliminar = seleccionado;
      if (i.SubMenus) {
        i.SubMenus.map((sm) => {
          sm.Eliminar = seleccionado;
          if (sm.SubMenus) {
            sm.SubMenus.forEach((sm2) => {
              sm2.Eliminar = seleccionado;
              return sm2;
            })
          }
          return sm;
        })
      }
      return i;
    })
    this.menus = [...this.menus]
  }

  onItemSearched(entity: Rol): void {
    if (entity) {
      this.menus = [];
      if (this.menuLoad.length == 0) {
        this.menuService.getAppMenusPermisosCompleto().subscribe(menus => {
          this.menuLoad = [...menus];
          this.afterSearchMenu(menus, entity)
        });
      } else {
        this.afterSearchMenu(this.menuLoad, entity)
      }
    }
  }

  afterSearchMenu(menus: Menu[], entity: Rol) {
    if (menus) {
      menus.forEach((item: any) => {
        let menu: MenuItem = {
          Id: item.id,
          Seleccionado: item.activo,
          IsExpanded: false,
          Guardar: false,
          Eliminar: false,
          Nombre: item.title,
          Icono: item.icon,
          Img: item.img,
          SubMenus: []
        };

        if (item.submenu) {
          let submenus: any = [];
          item.submenu.forEach((sm: any) => {
            let itemS = {
              Id: sm.id,
              Seleccionado: sm.activo,
              IsExpanded: false,
              Guardar: false,
              Eliminar: false,
              Nombre: sm.title,
              Icono: "",
              Img: "",
              SubMenus: []
            };
            if (sm.submenu) {
              let submenus2: any = [];
              sm.submenu.forEach((sm2: any) => {
                let itemS2 = {
                  Id: sm2.id,
                  Seleccionado: sm2.activo,
                  IsExpanded: false,
                  Guardar: false,
                  Eliminar: false,
                  Nombre: sm2.title,
                  Icono: "",
                  Img: "",
                  SubMenus: []
                };
                submenus2 = [...submenus2, itemS2]
              });
              itemS.SubMenus = submenus2;
            }
            submenus = [...submenus, itemS]
            menu.SubMenus = submenus;
          })
        }
        this.menus = [...this.menus, menu];
      });
    }

    this.myForm.reset(entity);
    this.focus('txtNombre')
    if (entity.Menu == null) {
      this.myForm.get('Menu')?.setValue([]);
    } else {
      this.myForm.controls["Menu"].setValue(entity.Menu);
    }
    this.fillMenus(entity.Menu);
  }

  onNewCatalog(next: number) {
    this.containerService.getEmptyEntity("Rol").subscribe((elem) => {
      elem.Clave = next;
      this.focus("txtNombre")
      this.myForm.reset(elem);
    })
  }

  get getIsNew(): any {
    return this.myForm.value;
  }

  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombre;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  onChangueEntity(ent: any) {
    if (ent) {
      this.onItemSearched(ent);
      this.myForm.reset(ent);
    }
    this.focus('txtNombre')
  }

  onItemsComboSearched(items: ComboBoxEntity[]): void {
    this.itemsCombo = items;
  }

  get getItemsCombo(): ComboBoxEntity[] {
    return this.itemsCombo;
  }

  clickBarButton(event: any) {
    if (event === "print") {
      this.utilsService.isLoad(true);
      this.reportsService.printReportWithoutFilters('/Config/ReporteRoles', "Roles de Usuario");
    }
  }

  showIcon(item: any) {
    return item.Id !== 94000;
  }
}

