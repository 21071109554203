<app-container-base subTitle="Verificador de Órdenes" (onClickBarButton)="clickBoton($event)" [isReport]="true"
  [activeButtons]="activeButtons" icon=" fas fa-list-check" [hasCounter]="false">
  <div class="screen-content">
    <form [formGroup]="verificadorOrdenesFrm" autocomplete="off">
      <app-panel-base title="Generales">
        <div class="panel-content">
          <div class="row">
            <div class="col-md-7">
              <components-ui-text-box label='Nombre del Reporte' [tabIndex]="1" [widthLabelColumns]="4"
                [widthTextColumns]="8" [maxLength]="100" formControlName="NombreReporte" #txtNombreReporte />
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <components-ui-date-box label='Fecha inicio' [widthLabelColumns]="7" [widthTextColumns]="3" [tabIndex]="2"
                formControlName="Fecha1" [required]="true"
                (onChangeValue)="onChangeFecha($event)"></components-ui-date-box>
            </div>
            <div class="col-md-4">
              <components-ui-date-box label='Fecha Fin' [widthLabelColumns]="3" [widthTextColumns]="3" [tabIndex]="3"
                formControlName="Fecha2" [required]="true"
                (onChangeValue)="onChangeFecha($event)"></components-ui-date-box>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <component-ui-combo-box label='Estatus' [tabIndex]="4" [zeroMask]="2"
                [selectManualItem]="selectOpcionManualItem" [setItemsList]="this.listaOpciones" [widthTextColumns]="4"
                [widthLabelColumns]="7" [inputProperty]="'Nombre'" listProperty='Nombre'
                (onSelectedItem)="selectedCombo($event)" />
            </div>
          </div>
          <!-- <div class="row">
            <h1>Poner Check Imprimir diseños</h1>

          </div> -->
        </div>
      </app-panel-base>
      <app-panel-base title="Generales">
        <div class="panel-content">
          <component-ui-selection-filter [(FiltrosSeleccion)]="this.filtrosReporte" [tabIndex]="5"
            (ReportHeaderChange)="changeFiltros($event)" (FiltrosSeleccionChange)="changeselec($event)"
            [ReportHeader]="this.verificadorOrdenesFrm"></component-ui-selection-filter>
        </div>
      </app-panel-base>
    </form>
  </div>
</app-container-base>
