import { AfterViewInit, ChangeDetectorRef, Component, DoCheck, ElementRef, EventEmitter, Input, Output, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ComponentsUiService } from '../../services/components-ui.service';

@Component({
  selector: 'component-ui-check-box',
  templateUrl: './check-box.component.html',
  styleUrls: ['./check-box.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckBoxComponent),
      multi: true,
    }
  ]
})
export class CheckBoxComponent implements AfterViewInit, ControlValueAccessor, DoCheck {
  onBlur() {
    this.tagInput.nativeElement.classList.remove("pulse-text");
  }
  onFocus() {
    this.tagInput.nativeElement.classList.add("pulse-text");
    if (this.helpLine) {
      let pes = document.querySelectorAll('.lineadeayuda');
      pes.forEach((elem: any) => { elem.innerHTML = this.helpLine ? this.helpLine : ""; });
    }
  }

  @Input()
  public widthTextColumns: number = 8;
  @Input()
  public label: string = "Etiqueta";
  @Input()
  public widthLabelColumns: number = 2;
  @Input()
  public tabIndex: number = 0;
  @Input()
  public topLabel: boolean = false;
  @Input()
  public enabled: boolean = true;
  @Input()
  public style: string = "";
  @Input()
  public helpLine: string = "";
  @Output()
  public onClick: EventEmitter<boolean> = new EventEmitter();



  @ViewChild('tagChk')
  public tagInput!: ElementRef<HTMLInputElement>;

  @Input()
  value: boolean = false;

  onChange = (value: string) => { };

  onTouched = () => { };



  private isDisabled = false;
  inputField = new FormControl();
  constructor(private compsService: ComponentsUiService, private changeDetectorRef: ChangeDetectorRef,) {

  }
  ngDoCheck(): void {
  }
  ngAfterViewInit(): void {
    this.inputField.valueChanges.subscribe(value => {
      this.onChange(value!);
    });

    this.setDisabledState(this.isDisabled);
  }

  get getSelected(): boolean {
    return this.value;
  }

  public onclick() {
    this.writeValue(this.tagInput.nativeElement.checked);
    this.onClick.emit(this.tagInput.nativeElement.checked);
  }

  writeValue(val: boolean): void {
    this.inputField.setValue(val);
    this.value = val;
  }
  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }
  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }
  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    this.changeDetectorRef.markForCheck();
  }
}
