<div class="card text-center border-0" cdkDrag
  style="z-index:2;box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; width: 820px; position: fixed;left: 20%;top:20%;">
  <div class="card-header fw-bold" style="cursor: move;" cdkDragHandle>
    <span>
      Selección de Productos a Cargar
    </span>
  </div>
  <form [formGroup]="myForm" autocomplete="off">
    <app-panel-base title="Selección de Productos">
      <div class="panel-content">
        <div class="row">
          <div class="col-3 text-start" *ngIf="!this.SoloConsulta">
            <button  type="button" (click)="cargarFactura()" [tabIndex]="this.tabIndex+10" class="btn btn-success">Cargar
              Productos</button>
          </div>
          <!-- <div class="col-md-6 align-items-end" *ngIf=" this.tipoMovimiento==1 && !this.SoloConsulta">
            <component-ui-combo-box formControlName="formaPago" label="Forma pago" [inputProperty]="'Nombre'"
              [entityName]="'c_FormaPago'" [tabIndex]="this.tabIndex+1" [widthLabelColumns]="4" [widthTextColumns]="8"
              listProperty='Nombre' (onSelectedItem)="selectTipoForma($event)" [widthRightColumns]="0"
              (keydown.enter)="keydownEnter($event)" [required]="true" #tipoMovCxc />
          </div> -->
        </div>
        <div class="mt-2">
          <ng-scrollbar style="height: 250px; width:810px; border-bottom: 1px solid #AAAAAA;">
            <table class="blueTable" style="width:780px;">
              <thead style="position: sticky;top:0;">
                <tr>
                  <th style="width: 30px;" *ngIf="!this.SoloConsulta">Eliminar</th>
                  <th style="width: 30px;">Cantidad</th>
                  <th style="width: 60px;">Unidad Medida</th>
                  <th style="width: 100px;">Conversión</th>
                  <th style="width: 180px;">Producto</th>
                </tr>
              </thead>
              <tbody class="bg-light">
                <tr [style]=" indexEditing == index ?  'background-color: #ffdbb7;': ''"
                  *ngFor="let item of sourceGrid; let index = index;">
                  <td style="text-align: center;" *ngIf="!this.SoloConsulta">
                    <i (click)="deleteRow(index)" *ngIf="item.producto?.id>0 && !this.SoloConsulta"
                      class="fa fa-rectangle-xmark fa-1x" style="color:red;font-weight: bold; cursor: pointer;"></i>
                  </td>
                  <td style="width: 60px;min-width:60px;max-width: 60px;">
                    <input (keydown)="keyDownCantidad($event)" (keydown.escape)="escapeCantidad($event)"
                      (keydown.arrowup)="up($event,1)" (keydown.arrowdown)="down($event,1)"
                      (keydown.enter)="enterCantidad($event)" class="grid-editor text-dark"
                      [id]="'txt_'+index+'_1' + idUnico" (blur)="blurCantidad($event)" type="number"
                      (focus)="focusCantidad(index, $event, item)" [style]="'width:100%;text-align:right;'"
                      [value]="item.producto ? item.cantidad: 0" />
                  </td>
                  <td class="text-dark">
                    <span style="margin-left: 3px;"> {{item.unidadMedidaDescripcion}}</span>
                  </td>
                  <td>
                    <a *ngIf="item.textoConversiones" class="text-dark"
                      style="margin-left: 3px;">{{item.textoConversiones}} </a>
                  </td>
                  <td class="text-dark">
                    <span>{{item.productoNombre != "" ? item.productoNombre:""}}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </ng-scrollbar>
        </div>
        <table class="table table-borderless table-sm fw-bold m-0">
          <tbody>
            <tr>
              <td class="w-150px"></td>
              <td></td>
              <td class="text-end"><a class="text-black text-decoration-none">Productos: {{sourceGrid.length|
                  number}}</a>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="card-footer fw-bold text-end">
          <div style="width:10%">
            <label>Observaciones:</label>
          </div>
          <div class="row">
            <textarea maxlength="1000" formControlName="observaciones" class="w-100" rows="2" cols="20"
              (focus)="focoComentarios($event)"></textarea>
          </div>
          <button type="button" (click)="aceptar()" [tabIndex]="this.tabIndex+2" class="btn btn-success me-1 w-100px mt-1"
            #myButton>Aceptar</button>
          <button type="button" (click)="closeModal()" class="btn btn-danger w-100px mt-1"
            [tabIndex]="this.tabIndex+3">Cancelar</button>
        </div>
      </div>
    </app-panel-base>
  </form>
</div>
