import { Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserLogged } from 'src/app/auth/interfaces';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { EventsService } from 'src/app/service/events.service';
import { FinanzasService } from '../../services/finanzas.service';
import { ModalService } from 'src/app/service/modal.service';
import { UtilsService } from 'src/app/service/utils.service';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';
import { TabsNavService } from 'src/app/components/services/tabs-nav.service';
import { ConfiguracionConciliacion, MovimientoConciliacion } from '../../interfaces/conciliacion.interface';
import * as moment from 'moment';
import { FileUploadService } from '../../../component-ui/services/file-upload-box.service';
import { orderBy, result } from 'lodash';
import { aC } from '@fullcalendar/core/internal-common';
import Swal from 'sweetalert2';
import { AppSettings } from 'src/app/home/services/app-settings.service';


@Component({
  selector: 'app-conciliacion-manual-page',
  templateUrl: './conciliacion-manual-page.component.html',
  styles: [
  ]
})
export class ConciliacionManualPageComponent implements OnInit {


  info: UserLogged = {} as UserLogged;
  seleccionado: number = 2;
  private eventsService = inject(EventsService);
  private tabsNavService = inject(TabsNavService);
  private config: any;
  titulo: string = "";
  listaNosotros: MovimientoConciliacion[] = [];
  listaEllos: MovimientoConciliacion[] = [];
  listaFiltradaNosotros: MovimientoConciliacion[] = [];
  listaFiltradaEllos: MovimientoConciliacion[] = [];
  @ViewChild('txtFile')
  public txtFile!: ElementRef<HTMLInputElement>;


  public myForm: FormGroup = this.fb.group({
    AnteriorNosotros: [0],
    CargosNosotros: [0],
    AbonosNosotros: [0],
    ActualNosotros: [0],
    AnteriorEllos: [0],
    CargosEllos: [0],
    AbonosEllos: [0],
    ActualEllos: [0],
  });


  seleccionadoNosotros: number = 1;
  seleccionadoEllos: number = 1;

  ordenadosNosotros = [
    { name: 'tipoNumeroPoliza', order: 'desc' },
    { name: 'fecha', order: 'desc' },
    { name: 'referencia', order: 'desc' },
    { name: 'concepto', order: 'desc' },
    { name: 'importeCargo', order: 'desc' },
    { name: 'importeAbono', order: 'desc' },
    { name: 'conciliado', order: 'desc' },
  ];
  ordenadosEllos = [
    { name: 'tipoNumeroPoliza', order: 'desc' },
    { name: 'fecha', order: 'desc' },
    { name: 'referencia', order: 'desc' },
    { name: 'concepto', order: 'desc' },
    { name: 'importeCargo', order: 'desc' },
    { name: 'importeAbono', order: 'desc' },
    { name: 'conciliado', order: 'desc' },
  ];

  lastOrderNosotros: string = "";
  lastOrderAscNosotros: string = "";

  lastOrderEllos: string = "";
  lastOrderAscEllos: string = "";

  constructor(private fb: FormBuilder,
    private containerService: ContainerBaseService,
    private fs: FinanzasService,
    private ms: ModalService,
    private fuse: FileUploadService,
    private utileService: UtilsService) { }
  public appSettings = inject(AppSettings);
  ngOnInit(): void {
    this.appSettings.appSidebarMinified = true;
    this.tabsNavService.getIsOpenTab("configConciliacion", (resp: any) => {
      if (resp) {
        this.eventsService.publish('home:showAlert', {
          message: `Cierre la pantalla de Configuración de Conciliación primero.`, cancelButton: false, onConfirm: (data: AlertResponse) => {
            this.tabsNavService.closeCurrentTab();
          }
        });
      } else {
        this.info = this.utileService.getUserLogged();
        this.obtenerUltimaConfiguracion();
      }
    })
  }

  seleccionNosotrosFiltro(tipo: number) {
    this.seleccionadoNosotros = tipo;
    switch (tipo) {
      case 1:
        this.listaFiltradaNosotros = [...this.listaNosotros];
        break;
      case 2:
        this.listaFiltradaNosotros = [...this.listaNosotros.filter(P => P.conciliado)];
        break;
      case 3: this.listaFiltradaNosotros = [...this.listaNosotros.filter(P => !P.conciliado)]; break;
      case 4:
        const lista = this.listaNosotros.filter(P => P.conciliado && P.seleccionado);
        if (lista.length > 0) {
          this.desconciliarNosotros(lista[0]);
        }
        break;
      case 5: break;
    }
  }

  desconciliarNosotros(item: MovimientoConciliacion) {
    this.eventsService.publish('home:showAlert', {
      message: `¿Desea desconciliar los movimientos seleccionados? ${item.concepto}`, onConfirm: (data: AlertResponse) => {
        if (data.isAccept) {
          this.eventsService.publish('home:isLoading', { isLoading: true });
          let idsEllos = "";
          this.listaFiltradaEllos.forEach((i) => {
            let ids = `,${i.idsMovimientoConcilado},`;
            if (ids.includes(`,${item.id},`)) {
              idsEllos += `${i.id},`;
            }
          });
          idsEllos = idsEllos.substring(0, idsEllos.length - 1);
          this.fs.desConciliarMovimientosManualesNosotros(idsEllos, String(item.id)).subscribe((result) => {
            this.importarContabilidad();
          })
        }
      }
    });
  }

  seleccionEllosFiltro(tipo: number) {
    this.seleccionadoEllos = tipo;
    switch (tipo) {
      case 1:
        this.listaFiltradaEllos = [...this.listaEllos];
        break;
      case 2:
        this.listaFiltradaEllos = [...this.listaEllos.filter(P => P.conciliado)];
        break;
      case 3: this.listaFiltradaEllos = [...this.listaEllos.filter(P => !P.conciliado)]; break;
      case 4:


        break;
      case 5: break;
    }
  }



  seleccionarNosotros(pos: number) {
    this.listaFiltradaNosotros[pos].seleccionado = !this.listaFiltradaNosotros[pos].seleccionado;
    this.listaFiltradaNosotros = [...this.listaFiltradaNosotros];
    setTimeout(() => { this.validarSelecciones(1); }, 50);
  }

  obtenerEstiloSeleccionado(item: MovimientoConciliacion): string {
    let style = "";
    if (item.conciliado) {
      style = "background-color:#bcf0b5;";
    }
    if (item.seleccionado || item.marcar) {
      style = "background-color:yellow;";
    }

    return style;
  }

  validarSelecciones(tipo: number) {
    debugger
    let nosotros: MovimientoConciliacion[] = [...this.listaFiltradaNosotros.filter(P => P.seleccionado && !P.conciliado)];
    let ellos: MovimientoConciliacion[] = [...this.listaFiltradaEllos.filter(P => P.seleccionado && !P.conciliado)];

    let sumaNostros = 0;
    let sumaEllos = 0;
    let idsEllos = "", idsNosotros = "";
    nosotros.forEach((item) => {
      if (Number(item.importeAbono) != 0) { sumaNostros += Number(item.importeAbono); idsNosotros += `${item.id},`; }
      if (Number(item.importeCargo) != 0) { sumaNostros += Number(item.importeCargo); idsNosotros += `${item.id},`; }
    })

    ellos.forEach((item) => {
      if (Number(item.importeAbono) != 0) { sumaEllos += Number(item.importeAbono); idsEllos += `${item.id},` }
      if (Number(item.importeCargo) != 0) { sumaEllos += Number(item.importeCargo); idsEllos += `${item.id},` }
    })



    if (sumaEllos == sumaNostros && (sumaEllos != 0)) {
      idsNosotros = idsNosotros.substring(0, idsNosotros.length - 1);
      idsEllos = idsEllos.substring(0, idsEllos.length - 1);
      this.eventsService.publish('home:showAlert', {
        message: 'Los importes corresponden!! ¿desea conciliar los movimientos seleccionados?',
        onConfirm: (r: AlertResponse) => {
          if (r.isAccept) {
            this.eventsService.publish('home:isLoading', { isLoading: true });
            this.fs.conciliarMovimientosManuales(idsEllos, idsNosotros).subscribe((result) => {
              this.eventsService.publish('home:isLoading', { isLoading: false });
              if (result.success) {
                this.importarContabilidad();
                // Swal.fire({ position: 'center', icon: 'success', title: 'Se conciliaron los movimientos', showConfirmButton: false, timer: 2000 }).then(() => {
                // });
              } else {
                this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
              }
            })
          }
        }
      });
      return;
    }


    this.listaNosotros.map((e) => {
      e.marcar = false;
      return e;
    });
    this.listaEllos.map((e) => {
      e.marcar = false;
      return e;
    });

    this.listaFiltradaNosotros = [...this.listaNosotros];
    this.listaFiltradaEllos = [...this.listaEllos];

    if (tipo == 1) {
      let encontro = false;
      this.listaFiltradaNosotros.forEach((i) => {
        if (i.seleccionado && i.conciliado) {
          this.listaFiltradaEllos.map((e) => {
            e.marcar = false;
            if (e.conciliado) {
              let ids = `,${e.idsMovimientoConcilado},`;
              if (ids.includes(`,${i.id},`)) {
                e.marcar = true;
                encontro = true;
              }
            }
            return e;
          })
        }
      })
      if (encontro) {
        if (this.seleccionadoNosotros != 3) {
          this.listaFiltradaNosotros = [...this.listaFiltradaNosotros];
        }
        this.listaFiltradaEllos = [...this.listaFiltradaEllos.filter(P => P.marcar)];
      }
    }

  }

  get getNosotrosConciliadosPorcentaje(): number {

    let c = this.getNosotrosConciliados;
    let l = this.listaFiltradaNosotros.length;

    if (l > 0) {
      return (c * 100) / l;
    }
    return 0;
  }

  get getEllosConciliadosPorcentaje(): number {
    let c = this.getEllosConciliados;
    let l = this.listaFiltradaEllos.length;

    if (l > 0) {
      return (c * 100) / l;
    }
    return 0;
  }

  get getNosotrosConciliados(): number {
    return this.listaFiltradaNosotros.filter(P => P.conciliado).length;
  }

  get getEllosConciliados(): number {
    return this.listaFiltradaEllos.filter(P => P.conciliado).length;
  }

  seleccionarEllos(pos: number) {
    this.listaFiltradaEllos[pos].seleccionado = !this.listaFiltradaEllos[pos].seleccionado;
    this.listaFiltradaEllos = [...this.listaFiltradaEllos];
    setTimeout(() => { this.validarSelecciones(2); }, 50);
  }

  ordenarNosotros(campo: string) {
    this.lastOrderNosotros = campo;
    let order = this.ordenadosNosotros.filter(P => P.name == campo)[0];
    let ordenado = orderBy(this.listaFiltradaNosotros, [campo], [order.order == "desc" ? "asc" : "desc"]);
    order.order == "asc" ? order.order = "desc" : order.order = "asc";
    this.lastOrderAscNosotros = order.order;
    this.listaFiltradaNosotros = [...ordenado];
  }

  ordenarEllos(campo: string) {
    this.lastOrderEllos = campo;
    let order = this.ordenadosEllos.filter(P => P.name == campo)[0];
    let ordenado = orderBy(this.listaFiltradaEllos, [campo], [order.order == "desc" ? "asc" : "desc"]);
    order.order == "asc" ? order.order = "desc" : order.order = "asc";
    this.lastOrderAscEllos = order.order;
    this.listaFiltradaEllos = [...ordenado];
  }

  obtenerUltimaConfiguracion() {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.fs.obtenerConfiguracionConciliacion(this.info.empresa!.numero, this.info.numero).subscribe((result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result.success) {
        if (result.message.length > 0) {
          const ent = JSON.parse(result.message);
          this.config = { ...ent };
          this.titulo = `Conciliando: ${this.config?.Cuenta?.Nombre}  Del ${moment(this.config?.FechaInicio).format("DD/MM/YYYY")} al ${moment(this.config?.FechaFin).format("DD/MM/YYYY")}`;
          this.importarContabilidad();
        } else {
          this.enviarMensajeError();
        }
      } else {
        this.enviarMensajeError();
      }
    });
  }

  importarContabilidad() {
    this.seleccionadoEllos = 1;
    this.seleccionadoNosotros = 1;
    this.myForm.controls["AnteriorEllos"].setValue(0);
    this.myForm.controls["CargosEllos"].setValue(0)
    this.myForm.controls["AbonosEllos"].setValue(0);
    this.myForm.controls["ActualEllos"].setValue(0);
    this.myForm.controls["AnteriorNosotros"].setValue(0);
    this.myForm.controls["CargosNosotros"].setValue(0)
    this.myForm.controls["AbonosNosotros"].setValue(0);
    this.myForm.controls["ActualNosotros"].setValue(0);
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.fs.obtenerMovimientosContabilidad(2).subscribe((lista) => {
      this.listaNosotros = lista;
      this.listaFiltradaNosotros = lista;
      this.fs.obtenerMovimientosContabilidad(3).subscribe((listaEllos) => {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        this.listaEllos = listaEllos;
        this.listaFiltradaEllos = listaEllos;

        if (this.listaEllos.length > 0) {
          const m: MovimientoConciliacion = this.listaEllos[0];
          this.myForm.controls["AnteriorEllos"].setValue(m.saldoAnterior);
          this.myForm.controls["CargosEllos"].setValue(m.sumaCargos);
          this.myForm.controls["AbonosEllos"].setValue(m.sumaAbonos);
          this.myForm.controls["ActualEllos"].setValue(m.saldoActual);
        }

        if (this.listaNosotros.length > 0) {
          const m: MovimientoConciliacion = this.listaNosotros[0];
          this.myForm.controls["AnteriorNosotros"].setValue(m.saldoAnterior);
          this.myForm.controls["CargosNosotros"].setValue(m.sumaCargos);
          this.myForm.controls["AbonosNosotros"].setValue(m.sumaAbonos);
          this.myForm.controls["ActualNosotros"].setValue(m.saldoActual);
        }

      });
    });
  }



  get getConciliarCargosNosotros(): number {
    let t = 0;
    this.listaNosotros.forEach((item) => {
      if (!item.conciliado) {
        t += item.importeCargo;
      }
    })
    return t;
  }

  get getConciliarAbonosNosotros(): number {
    let t = 0;
    this.listaNosotros.forEach((item) => {
      if (!item.conciliado) {
        t += item.importeAbono;
      }
    })
    return t;
  }

  get getConciliarCargosEllos(): number {
    let t = 0;
    this.listaEllos.forEach((item) => {
      if (!item.conciliado) {
        t += item.importeCargo;
      }
    })
    return t;
  }

  get getConciliarAbonosEllos(): number {
    let t = 0;
    this.listaEllos.forEach((item) => {
      if (!item.conciliado) {
        t += item.importeAbono;
      }
    })
    return t;
  }




  enviarMensajeError() {
    this.eventsService.publish('home:showAlert', {
      message: "No se encontró configuración para poder iniciar el proceso de conciliación, de clic en aceptar para configurar.", cancelButton: false, onConfirm: (data: AlertResponse) => {
        this.tabsNavService.closeCurrentTab();
        this.eventsService.publish('home:openPage', 86100);
      }
    });
  }


}
