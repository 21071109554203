<app-container-base subTitle="Antigüedad De Saldos De Cuentas Por Cobrar" (onClickBarButton)="clickBoton($event)"
  [isReport]="true" [hasCounter]="false" [activeButtons]="activeButtons" icon=" fas fa-hand-holding-dollar">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Generales" [itemsMenuDropDown]="menuItems" (onClicMenu)="clicMenu($event)">
        <div class="panel-content">
          <div class="row">
            <div class="col-md-11">
              <components-ui-text-box label='Nombre del Reporte' formControlName="NombreReporte" [tabIndex]="1"
                helpLine="Es el nombre que se imprimirá en el reporte." [widthLabelColumns]="4" [widthTextColumns]="8"
                [maxLength]="100" #txtNombreReporte />
            </div>
          </div>
          <div class="row">
            <div class="col-md-7">
              <components-ui-date-box label='Fecha al' formControlName="Fecha1" [tabIndex]="2" [widthLabelColumns]="6"
                [widthTextColumns]="4" (onChangeValue)="onChangeValue($event)"
                [required]="true"></components-ui-date-box>
            </div>
          </div>
          <div class="card-header h6 mb-1 bg-none p-2 text-orange">
            <i class="fa fa-filter fa-lg fa-fw me-1"></i>
            Opciones especiales
          </div>
          <div class="row">
            <div class="col-md-7">
              <component-ui-check-box label='Solo con crédito excedido' formControlName="Opc4" [tabIndex]="3"
                [widthLabelColumns]="6" [widthTextColumns]="1" [tabIndex]="4" />
              <component-ui-check-box label='Agregar resumen de vencimientos' formControlName="Opc5" [tabIndex]="5"
                [widthLabelColumns]="6" [widthTextColumns]="1" />
              <component-ui-check-box label='Solo negativos' formControlName="Opc6" [tabIndex]="6"
                [widthLabelColumns]="6" [widthTextColumns]="1" />
              <component-ui-check-box label='En moneda nacional' formControlName="Opc8" [tabIndex]="7"
                [widthLabelColumns]="6" [widthTextColumns]="1" />
            </div>
          </div>

          <div class="card-header h6 mb-1 bg-none p-2 text-orange">
            <i class="fa fa-calendar-check fa-lg fa-fw me-1"></i>
            Filtrar por días vencidos (indique el rango de días, por ejemplo los vencidos de 1 a 60 días)
          </div>
          <div class="row">
            <div class="col-md-7">
              <components-ui-number-box [tabIndex]="8" [maxLength]="4" label='Días vencidos del' [widthLabelColumns]="6"
                [widthTextColumns]="2" formControlName="Num1" />
              <components-ui-number-box [tabIndex]="9" [maxLength]="4" label='al' [widthLabelColumns]="6"
                [widthTextColumns]="2" formControlName="Num2" />
            </div>
          </div>
          <div class="card-header h6 mb-1 bg-none p-2 text-orange">
            <i class="fa fa-calendar-check fa-lg fa-fw me-1"></i>
            Filtrar por fecha de Vencimiento (indique menor, igual o mayor según la fecha de vencimiento colocada)
          </div>
          <div class="row">
            <div class="col-md-7">
              <components-ui-date-box label='Fecha vencimiento' formControlName="Fecha2" [tabIndex]="10"
                [widthLabelColumns]="6" [widthTextColumns]="4"></components-ui-date-box>
              <component-ui-check-box label='Mayor' formControlName="Opc1" [tabIndex]="11" [widthLabelColumns]="6"
                [widthTextColumns]="2" (onClick)="onclickMayor($event,'opc1')" />
              <component-ui-check-box label='Igual' formControlName="Opc2" [tabIndex]="12" [widthLabelColumns]="6"
                [widthTextColumns]="2" (onClick)="onclickMayor($event,'opc2')" />
              <component-ui-check-box label='Menor' formControlName="Opc3" [tabIndex]="13" [widthLabelColumns]="6"
                [widthTextColumns]="2" (onClick)="onclickMayor($event,'opc3')" />
            </div>
          </div>
        </div>
      </app-panel-base>
      <app-panel-base title="Generales">
        <div class="panel-content">
          <component-ui-selection-filter [(FiltrosSeleccion)]="this.filtrosReporte" [tabIndex]="14"
            (FiltrosSeleccionChange)="changeselec($event)" [ReportHeader]="this.myForm"></component-ui-selection-filter>
        </div>
      </app-panel-base>
    </form>
    <ng-template #ctrlRangos let-modal let-c="close">
      <ctrl-edicion-rangos-antiguedad-saldos></ctrl-edicion-rangos-antiguedad-saldos>
    </ng-template>
  </div>
</app-container-base>
