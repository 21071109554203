import { Component, ElementRef, inject, Input, ViewChild } from '@angular/core';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';
import { EventsService } from 'src/app/service/events.service';
import { ModalService } from 'src/app/service/modal.service';

@Component({
  selector: 'app-enviar-produccion-page',
  templateUrl: './enviar-produccion-page.component.html',
  styles: [
  ]
})
export class EnviarProduccionPageComponent {




  @ViewChild('text')
  public text!: ElementRef<HTMLElement>;

  private eventsService = inject(EventsService);
  constructor(private ms: ModalService) {
  }

  accept() {
    const t: any = this.text;
    const value = t.nativeElement.value.trim();
    if (Number(value) > 0) {
      this.eventsService.publish('home:showAlert', {
        message: "¿Está seguro de imprimr reporte orden para envíar a producción?", onConfirm: (data: AlertResponse) => {
          if (data.isAccept) {
            setTimeout(() => {
              this.ms.closeModal(Number(value));
            }, 100);
          }
        }
      });
    } else {
      this.eventsService.publish('home:showAlert', { message: `Debe de indicar una Cantidad`, cancelButton: false });
    }
  }

  closeModal() {
    this.ms.closeModal(null);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.text.nativeElement.focus();
    }, 150);
  }

}
