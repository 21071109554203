import { Component, ElementRef, EventEmitter, inject, Input, Output, ViewChild } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { AppSettings } from 'src/app/home/services/app-settings.service';

@Component({
  selector: 'component-ui-file-upload-box',
  templateUrl: './file-upload-box.component.html',
  styleUrls: ['./file-upload-box.component.css']
})
export class FileUploadBoxComponent {
  public appSettings = inject(AppSettings);
  @Input()
  public widthLabelColumns: number = 2;
  @Input()
  public widthTextColumns: number = 10;
  @Input()
  public label: string = "Etiqueta";
  @Input()
  public separator: string = ":";
  @Input()
  public accepts: string = "";
  @Input()
  public tabIndex: number = 0;
  @Input()
  public helpLine: string = "";

  @Input()
  public text: string = "";

  @Output()
  public onChange: EventEmitter<any> = new EventEmitter();

  @ViewChild('txtFile')
  public txtFile!: ElementRef<HTMLInputElement>;

  @ViewChild('txtFileText')
  public txtFileText!: ElementRef<HTMLInputElement>;

  btnClic() {
    this.txtFile.nativeElement.click();
  }

  onchange(event: any) {
    this.onChange.emit(event.target.files.length > 0 ? event.target.files[0] : null);
    this.txtFileText.nativeElement.value = event.target.files[0].name;
  }

}


