<app-container-base [setIsNew]="getIsNew" (onClickBarButton)="onClickBarButton($event)" [hasCounter]="false"
  [activeButtons]="activeButtons" [hasBar]="true" icon="fa fa-wrench"
  subTitle="Bloqueo de módulos para evitar capturas en meses y años anteriores al actual">
  <div class="screen-content" style="width: 800px;">
    <form [formGroup]="myForm" autocomplete="off">

      <app-panel-base [camelCase]="false"
        title="Seleccione los módulos a bloquear, indique la fecha mínima a aceptar por cada módulo">
        <div class="panel-content">
          <div (click)="todos()" style="margin-left: 66px;" class="btn btn-primary mb-2">Seleccionar Todos</div>
          <hr>
          <table style="width: 400px;">
            <tbody>
              <tr>
                <td> <component-ui-check-box style="margin-top: 3px;"
                    helpLine="Bloquear todos las capturas de contabilidad" [tabIndex]="1" [widthLabelColumns]="9"
                    formControlName="Contabilidad" [widthTextColumns]="3" [label]="'Contabilidad'" /></td>
                <td><components-ui-date-box label="" [tabIndex]="2" formControlName="FechaContabilidad"
                    [required]="myForm.value.Contabilidad" [enabled]="myForm.value.Contabilidad" [widthLabelColumns]="0"
                    [widthTextColumns]="6" /></td>
              </tr>
              <tr>
                <td> <component-ui-check-box style="margin-top: 3px;" helpLine="Bloquear todos las capturas de compras"
                    [tabIndex]="3" [widthLabelColumns]="9" formControlName="Compras" [widthTextColumns]="3"
                    [label]="'Compras'" /></td>
                <td> <components-ui-date-box label="" [tabIndex]="4" formControlName="FechaCompras"
                    [required]="myForm.value.Compras" [enabled]="myForm.value.Compras" [widthLabelColumns]="0"
                    [widthTextColumns]="6" /></td>
              </tr>
              <tr>
                <td><component-ui-check-box style="margin-top: 3px;"
                    helpLine="Bloquear todos las capturas de Cuentas Por Pagar" [tabIndex]="5" [widthLabelColumns]="9"
                    formControlName="CXP" [widthTextColumns]="3" [label]="'Cuentas Por Cobrar'" /></td>
                <td> <components-ui-date-box label="" [tabIndex]="6" formControlName="FechaCXC"
                    [required]="myForm.value.CXP" [enabled]="myForm.value.CXP" [widthLabelColumns]="0"
                    [widthTextColumns]="6" /></td>
              </tr>
              <tr>
                <td><component-ui-check-box style="margin-top: 3px;"
                    helpLine="Bloquear todos las capturas de Cuentas Por Cobrar" [tabIndex]="7" [widthLabelColumns]="9"
                    formControlName="CXC" [widthTextColumns]="3" [label]="'Cuentas Por Pagar'" /></td>
                <td> <components-ui-date-box label="" [tabIndex]="8" formControlName="FechaCXP"
                    [required]="myForm.value.CXC" [enabled]="myForm.value.CXC" [widthLabelColumns]="0"
                    [widthTextColumns]="6" /></td>
              </tr>
              <tr>
                <td><component-ui-check-box style="margin-top: 3px;"
                    helpLine="Bloquear todos las capturas de Inventarios" [tabIndex]="9" [widthLabelColumns]="9"
                    formControlName="Inventario" [widthTextColumns]="3" [label]="'Inventarios'" /></td>
                <td> <components-ui-date-box label="" [tabIndex]="10" formControlName="FechaInventario"
                    [required]="myForm.value.Inventario" [enabled]="myForm.value.Inventario" [widthLabelColumns]="0"
                    [widthTextColumns]="6" /></td>
              </tr>
              <tr>
                <td><component-ui-check-box style="margin-top: 3px;"
                    helpLine="Bloquear todos las capturas de Producción" [tabIndex]="11" [widthLabelColumns]="9"
                    formControlName="Produccion" [widthTextColumns]="3" [label]="'Producción'" /></td>
                <td> <components-ui-date-box label="" [tabIndex]="12" formControlName="FechaProduccion"
                    [required]="myForm.value.Produccion" [enabled]="myForm.value.Produccion" [widthLabelColumns]="0"
                    [widthTextColumns]="6" /></td>
              </tr>
              <tr>
                <td><component-ui-check-box style="margin-top: 3px;" helpLine="Bloquear todos las capturas de Finanzas"
                    [tabIndex]="13" [widthLabelColumns]="9" formControlName="Finanzas" [widthTextColumns]="3"
                    [label]="'Finanzas'" /></td>
                <td> <components-ui-date-box label="" [tabIndex]="14" formControlName="FechaFinanzas"
                    [required]="myForm.value.Finanzas" [enabled]="myForm.value.Finanzas" [widthLabelColumns]="0"
                    [widthTextColumns]="6" /></td>
              </tr>
            </tbody>
          </table>

        </div>
      </app-panel-base>
    </form>
  </div>
</app-container-base>
