import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { environment } from "src/environments/environment";
import { SaldoCXP } from "../interfaces/saldocxp.interface";
import { ItemEstadoCuentaCXP } from "../interfaces/cuentacxp.interface";
import { Observable } from "rxjs";
import { Result } from "src/app/auth/interfaces";
import { CxpCabecera } from "../interfaces/cxpcabecera";

@Injectable({ providedIn: 'root' })
export class CuentasPorPagarService {
  private readonly baseUrl: string = environment.baseUrlApi;
  private http = inject(HttpClient);

  getProviderBalance(idEmpresa: number, idSucursal: number, idProvedor: number) {
    const params = new HttpParams().set("idEmpresa", idEmpresa).set("idSucursal", idSucursal).set("idProveedor", idProvedor);
    return this.http.get<SaldoCXP[]>(`${this.baseUrl}/CuentasPorPagar/ObtenerMovimientosProveedor`, { params });
  }

  obtenerListaDeAniosconMovimientosDeCXP() {
    return this.http.get<number[]>(`${this.baseUrl}/CuentasPorPagar/ObtenerListaDeAniosConMovtosDeCXP`);
  }

  getSaldo(idEmpresa: number, idProveedor: number) {
    const params = new HttpParams().set("idEmpresa", idEmpresa).set("idProveedor", idProveedor);
    return this.http.get<number>(`${this.baseUrl}/CuentasPorPagar/ObtenerSaldoProveedor`, { params });
  }

  getEstadoDeCuenta(idProveedor: number, idCuenta: number, anio: number) {
    const params = new HttpParams().set("idProveedor", idProveedor).set("idCuenta", idCuenta).set("anio", anio);
    return this.http.get<ItemEstadoCuentaCXP[]>(`${this.baseUrl}/CuentasPorPagar/ConsultaEstadoCuentaPorProveedor`, { params });
  }

  busquedaCuentas(filtro: string): Observable<Result> {
    const params = new HttpParams().set("filtro", filtro);
    return this.http.get<Result>(`${this.baseUrl}/CuentasPorPagar/BuscarCuentas`, { params });
  }

  busquedaConceptos(filtro: string): Observable<Result> {
    const params = new HttpParams().set("filtro", filtro);
    return this.http.get<Result>(`${this.baseUrl}/CuentasPorPagar/BuscarConceptos`, { params });
  }

  eliminarCxpDetalle(id: number): Observable<Result> {
    const params = new HttpParams().set("id", id);
    return this.http.get<Result>(`${this.baseUrl}/CuentasPorPagar/EliminarCxpDetalle`, { params });
  }

  obtenerCxpCabecera(anio: number, idTipoMov: number, folio: number, cancelados: boolean = true): Observable<CxpCabecera> {
    const params = new HttpParams()
      .set("anio", anio)
      .set("idTipoMovto", idTipoMov)
      .set("folio", folio)
      .set("cancelados", cancelados);
    return this.http.get<CxpCabecera>(`${this.baseUrl}/CuentasPorPagar/ObtenerCxpCabecera`, { params });
  }






}
