import { Component, ElementRef, inject, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';
import { EventsService } from 'src/app/service/events.service';
import { ModalService } from 'src/app/service/modal.service';

@Component({
  selector: 'terminales',
  templateUrl: './terminales.component.html',
  styleUrls: ['./terminales.component.scss']
})
export class TerminalesComponent {

  @ViewChild('modalAgregarTerminal')
  public modalAgregarTerminal!: ElementRef<HTMLElement>;

  private modalService = inject(ModalService);
  @Input()
  terminales: any[] = [];
  terminalesClone: any[] = [];

  terminal: any = {} as any;

  private fb = inject(FormBuilder);
  private eventsService = inject(EventsService);
  public agregarTerminalFrm: FormGroup = this.fb.group({
    Id: [0],
    Banco: [0, [Validators.required]],
    CuentaBancaria: [0, [Validators.required]],
    Credito: [false],
    Debito: [true],
    TerminalBancaria: [0],
    Importe: [0, [Validators.required]],
    NumeroLote: [''],
    Referencia: ["", [Validators.required]],
  });

  ngOnInit() {
    this.terminalesClone = _.cloneDeep(this.terminales);
  }

  onSelectedItem(entity: any, tipo: string) {
    this.agregarTerminalFrm.get(tipo)?.setValue(entity);
  }

  close() {
    this.modalService.closeModal(null);
  }

  closeListaTerminales(accept: boolean = true) {
    this.modalService.closeModal(accept ? this.terminalesClone : this.terminales);
  }

  agregar() {
    this.modalService.openModal(this.modalAgregarTerminal, () => {
    });
  }

  accept(isEnterInput: boolean = false, event: any = null) {
    this.terminal = this.agregarTerminalFrm.value;
    if (!this.terminal || (!this.terminal!.Banco && !this.terminal!.CuentaBancaria || !this.terminal!.TerminalBancaria || !this.terminal!.Importe || !this.terminal!.NumeroLote || !this.terminal!.Referencia)) {
      this.eventsService.publish('home:showAlert', { message: "Debe indicar todos los campos indicados con asterisco en rojo.", cancelButton: false });
      return;
    }

    if (this.terminal!.Id) {
      this.terminalesClone = this.terminalesClone.map((r: any) => {
        if (r.Id === this.terminal.Id) {
          return this.terminal;
        } return r;
      })
    } else {
      this.terminal = {
        ...this.terminal,
        Id: 0,
      }
      this.terminalesClone.push(this.terminal);
    }
    this.closeAgregarTerminal();
    this.terminal = {};
    this.sumarImportes();
  }

  closeAgregarTerminal() {
    this.modalService.closeModal(null);
    this.agregarTerminalFrm.reset();
  }

  onclicDebitoCredito(value: any, field: string) {
    if (field === "Debito") {
      this.agregarTerminalFrm.get("Debito")?.setValue(value);
      this.agregarTerminalFrm.get("Credito")?.setValue(!value);
    } else {
      this.agregarTerminalFrm.get("Credito")?.setValue(value);
      this.agregarTerminalFrm.get("Debito")?.setValue(!value);
    }
  }
  sumaImportes: number = 0;
  sumarImportes() {
    this.sumaImportes = _.sumBy(this.terminalesClone, "Importe");
  }

  deleteTerminal(terminal: any) {
    if (terminal.IdVenta > 0) {
      this.eventsService.publish('home:showAlert', {
        message: 'No es posible eliminar la terminal.',
        cancelButton: false
      });
      return;
    }

    this.eventsService.publish('home:showAlert', {
      message: '¿Desea eliminar el renglón?',
      onConfirm: (r: AlertResponse) => {
        if (r.isAccept) {
          this.terminalesClone = this.terminalesClone.filter((r: any) => r.Id !== terminal.Id);
          this.sumarImportes();
        }
      }
    });
  }

  editTerminal(terminal: any, index: number) {
    this.modalService.openModal(this.modalAgregarTerminal, () => { });
    setTimeout(() => {
      this.agregarTerminalFrm.reset(terminal);
      this.terminal = terminal;
    }, 0);
  }
}
