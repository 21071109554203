import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeRoutingModule } from './home-routing.module';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WidgetPage } from './pages/widget/widget';
import { ExtraTimelinePage } from './pages/extra/extra-timeline/extra-timeline';
import { ExtraComingSoonPage } from './pages/extra/extra-coming-soon/extra-coming-soon';
import { ExtraSearchResultsPage } from './pages/extra/extra-search-results/extra-search-results';
import { ExtraInvoicePage } from './pages/extra/extra-invoice/extra-invoice';
import { ExtraErrorPage } from './pages/extra/extra-error/extra-error';
import { ExtraProfilePage } from './pages/extra/extra-profile/extra-profile';
import { PrincipalPage } from '../configuracion/pages/principal-page/principal-page';
import { ExtraCookieAcceptanceBannerPage } from './pages/extra/extra-cookie-acceptance-banner/extra-cookie-acceptance-banner';
import { ExtraOrdersPage } from './pages/extra/extra-orders/extra-orders';
import { ExtraOrderDetailsPage } from './pages/extra/extra-order-details/extra-order-details';
import { ExtraProductsPage } from './pages/extra/extra-products/extra-products';
import { ExtraProductDetailsPage } from './pages/extra/extra-product-details/extra-product-details';
import { LoginV3Page } from './pages/login/login-v3/login-v3';
import { CalendarPage } from './pages/calendar/calendar';
import { PageBlank } from './pages/page-blank/page-blank';
import { PosCounterCheckoutPage } from './pages/pos/counter-checkout/counter-checkout';
import { PosKitchenOrderPage } from './pages/pos/kitchen-order/kitchen-order';
import { PosCustomerOrderPage } from '../ventas/pages/pos-page/customer-order';
import { PosTableBookingPage } from './pages/pos/table-booking/table-booking';
import { PosMenuStockPage } from './pages/pos/menu-stock/menu-stock';
import { EmailInboxPage } from './pages/email/inbox/email-inbox';
import { EmailComposePage } from './pages/email/compose/email-compose';
import { EmailDetailPage } from './pages/email/detail/email-detail';
import { DashboardV1Page } from './pages/v1/dashboard-v1';
import { DashboardV2Page } from './pages/v2/dashboard-v2';
import { DashboardV3Page } from './pages/v3/dashboard-v3';
import { ErrorPage } from './pages/error/error';
import { NgxEditorModule } from 'ngx-editor';
import { CountdownModule } from 'ngx-countdown';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgChartsModule } from 'ng2-charts';
import { HighlightModule } from 'ngx-highlightjs';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { TrendModule } from 'ngx-trend';
import { FullCalendarModule } from '@fullcalendar/angular';
import { HomeLayoutComponent } from './layout/home-layout.component';
import { ComponentsModule } from '../components/components.module';
import { ReportesPageComponent } from './pages/reportes.page/reportes.page.component';
import { ComponentUiModule } from '../component-ui/component-ui.module';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BusquedaMenuPageComponent } from './pages/busqueda-menu-page/busqueda-menu-page.component';

@NgModule({
  declarations: [
    HomeLayoutComponent,

    WidgetPage,

    ExtraTimelinePage,
    ExtraComingSoonPage,
    ExtraSearchResultsPage,
    ExtraInvoicePage,
    ExtraErrorPage,
    ExtraProfilePage,
    PrincipalPage,
    ExtraCookieAcceptanceBannerPage,
    ExtraOrdersPage,
    ExtraOrderDetailsPage,
    ExtraProductsPage,
    ExtraProductDetailsPage,

    LoginV3Page,

    CalendarPage,

    PageBlank,

    PosCounterCheckoutPage,
    PosKitchenOrderPage,
    PosCustomerOrderPage,
    PosTableBookingPage,
    PosMenuStockPage,

    EmailInboxPage,
    EmailComposePage,
    EmailDetailPage,

    DashboardV1Page,
    DashboardV2Page,
    DashboardV3Page,

    ErrorPage,

    ReportesPageComponent,
      BusquedaMenuPageComponent

  ],
  imports: [
    HomeRoutingModule,
    CommonModule,
    ComponentsModule,
    NgScrollbarModule,
    NgxEditorModule,
    CountdownModule,
    FullCalendarModule,
    NgxDaterangepickerMd.forRoot(),
    NgxChartsModule,
    NgApexchartsModule,
    NgChartsModule,
    HighlightModule,
    TrendModule,
    ReactiveFormsModule,
    DragDropModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    FormsModule,
    ComponentUiModule,
    NgIdleKeepaliveModule.forRoot()
  ]
})
export class HomeModule { }
