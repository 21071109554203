import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { HttpResponse } from '@microsoft/signalr';

import { Observable } from 'rxjs';
import { Result } from 'src/app/auth/interfaces';
import { AuthService } from 'src/app/auth/services/auth.service';
import { Visita } from 'src/app/crm/interfaces/visita.interface';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class CRMService {

  private readonly baseUrl: string = environment.baseUrlApi;


  private http = inject(HttpClient);
  private authService = inject(AuthService);
  constructor() { }


  uploadEvidencias(files: File[], idVisita: number, cb: any = null): void {
    const url = `${this.baseUrl}/CRM/UploadEvidenciasUsuario?idVisita=${idVisita}`;
    const xhr = this.createCORSRequest('POST', url);
    const fd = new FormData();
    for (const file of files) {
      fd.append(file.name, file);
    }
    xhr.setRequestHeader("hostFrontEnd", window.location.host);
    xhr.send(fd);
    xhr.onload = (resp: any) => {
      if (xhr.status === 200) {
        if (cb) {
          cb(JSON.parse(xhr.response));
        }
      } else {
        cb({ success: false, message: "No fue posible guardar las evidencias." })
      }
    }
  }

  createCORSRequest(method: string, url: string) {
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    xhr.open(method, url, true);
    xhr.setRequestHeader("authorization", `Bearer ${this.authService.getToken()}`);
    xhr.setRequestHeader("ambienteId", this.authService.getAmbienteId());
    return xhr;
  }


  getVisitaActiva() {
    return this.http.get<Visita>(`${this.baseUrl}/CRM/GetVisitaActiva`);
  }

  getVisitas() {
    return this.http.get<Visita[]>(`${this.baseUrl}/CRM/GetVisitas`);
  }

  guardarVisita(visita: Visita): Observable<HttpResponse> {
    return this.http.post<HttpResponse>(`${this.baseUrl}/CRM/GuardarVisita`, visita);
  }

  getAddresFromLngLat(lng: number, lat: number, tokenMapBox: string) {
    return this.http.get<any>(`https://api.mapbox.com/search/geocode/v6/reverse?longitude=${lng}&latitude=${lat}&access_token=${tokenMapBox}`);
  }
}
