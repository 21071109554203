<div cdkDrag class="data-search card border-1 conteo-efectivo">
  <div class="card-header" cdkDragHandle>
    <button (click)="close()" type="button" class="btn-close" aria-label="Close"></button>
    <h5>Conteo de Efectivo</h5>
  </div>
  <div class="card-body bg-white-100 p-0">
    <app-container-base [hasBar]="false" [hasCounter]="false" [hasHeader]="false" [containerInnerHeight]="0"
      [esPopup]="true">
      <div class="screen-content">
        <app-panel-base title="Indique las denominaciones">
          <div class="panel-content">
            <form [formGroup]="myForm" autocomplete="off">
              <div class="row">
                <div class="col">
                  <table class="table table-bordered table-sm">
                    <thead>
                      <tr>
                        <th colspan="3" class="fs-20px text-center">
                          <i class="fas fa-money-bill-1 text-green"></i>
                          Billetes
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-end w-60px">$20</td>
                        <td class="w-90px">
                          <components-ui-number-box label="" formControlName="Billete20" [aceptaPunto]="false"
                            [tabIndex]="1" [widthTextColumns]="12" [widthLabelColumns]="12" [showLabel]="false"
                            (input)="calcularBilletes($event, 20)" />
                        </td>
                        <td class="text-end w-90px">{{bVeinte | number: '1.2-2'}}</td>
                      </tr>
                      <tr>
                        <td class="text-end w-60px">$50</td>
                        <td class="w-90px">
                          <components-ui-number-box label="" formControlName="Billete50" [aceptaPunto]="false"
                            [tabIndex]="2" [widthTextColumns]="12" [widthLabelColumns]="12" [showLabel]="false"
                            (input)="calcularBilletes($event, 50)" />
                        </td>
                        <td class="text-end w-90px">{{bCincuenta | number: '1.2-2'}}</td>
                      </tr>
                      <tr>
                        <td class="text-end w-60px">$100</td>
                        <td class="w-90px">
                          <components-ui-number-box label="" formControlName="Billete100" [aceptaPunto]="false"
                            [tabIndex]="3" [widthTextColumns]="12" [widthLabelColumns]="12" [showLabel]="false"
                            (input)="calcularBilletes($event, 100)" />
                        </td>
                        <td class="text-end w-90px">{{bCien | number: '1.2-2'}}</td>
                      </tr>
                      <tr>
                        <td class="text-end w-60px">$200</td>
                        <td class="w-90px">
                          <components-ui-number-box label="" formControlName="Billete200" [aceptaPunto]="false"
                            [tabIndex]="4" [widthTextColumns]="12" [widthLabelColumns]="12" [showLabel]="false"
                            (input)="calcularBilletes($event, 200)" />
                        </td>
                        <td class="text-end w-90px">{{bDoscientos | number: '1.2-2'}}</td>
                      </tr>
                      <tr>
                        <td class="text-end w-60px">$500</td>
                        <td class="w-90px">
                          <components-ui-number-box label="" formControlName="Billete500" [aceptaPunto]="false"
                            [tabIndex]="5" [widthTextColumns]="12" [widthLabelColumns]="12" [showLabel]="false"
                            (input)="calcularBilletes($event, 500)" />
                        </td>
                        <td class="text-end w-90px">{{bQuinientos | number: '1.2-2'}}</td>
                      </tr>
                      <tr>
                        <td class="text-end w-60px">$1000</td>
                        <td class="w-90px">
                          <components-ui-number-box label="" formControlName="Billete1000" [aceptaPunto]="false"
                            [tabIndex]="6" [widthTextColumns]="12" [widthLabelColumns]="12" [showLabel]="false"
                            (input)="calcularBilletes($event, 1000)" />
                        </td>
                        <td class="text-end w-90px">{{bMil | number: '1.2-2'}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col">
                  <table class="table table-bordered table-sm">
                    <thead>
                      <tr>
                        <th colspan="3" class="fs-20px text-center">
                          <i class="fas fa-coins text-yellow"></i>
                          Monedas
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-end w-60px">¢10</td>
                        <td class="w-90px">
                          <components-ui-number-box label="" formControlName="Moneda10Centavos" [aceptaPunto]="false"
                            [tabIndex]="7" [widthTextColumns]="12" [widthLabelColumns]="12" [showLabel]="false"
                            (input)="calcularMonedas($event, .10)" />
                        </td>
                        <td class="text-end w-90px">{{mDiezCentavos | number: '1.2-2'}}</td>
                      </tr>
                      <tr>
                        <td class="text-end w-60px">¢20</td>
                        <td class="w-90px">

                          <components-ui-number-box label="" formControlName="Moneda20Centavos" [aceptaPunto]="false"
                            [tabIndex]="8" [widthTextColumns]="12" [widthLabelColumns]="12" [showLabel]="false"
                            (input)="calcularMonedas($event, .20)" />
                        </td>
                        <td class="text-end w-90px">{{mVeinteCentavos | number: '1.2-2'}}</td>
                      </tr>
                      <tr>
                        <td class="text-end w-60px">¢50</td>
                        <td class="w-90px">

                          <components-ui-number-box label="" formControlName="Moneda50Centavos" [aceptaPunto]="false"
                            [tabIndex]="9" [widthTextColumns]="12" [widthLabelColumns]="12" [showLabel]="false"
                            (input)="calcularMonedas($event, .50)" />
                        </td>
                        <td class="text-end w-90px">{{mCincuentaCentavos | number: '1.2-2'}}</td>
                      </tr>
                      <tr>
                        <td class="text-end w-60px">$1</td>
                        <td class="w-90px">

                          <components-ui-number-box label="" formControlName="Moneda1Pesos" [aceptaPunto]="false"
                            [tabIndex]="10" [widthTextColumns]="12" [widthLabelColumns]="12" [showLabel]="false"
                            (input)="calcularMonedas($event, 1)" />
                        </td>
                        <td class="text-end w-90px">{{mUnPeso | number: '1.2-2'}}</td>
                      </tr>
                      <tr>
                        <td class="text-end w-60px">$2</td>
                        <td class="w-90px">

                          <components-ui-number-box label="" formControlName="Moneda2Pesos" [aceptaPunto]="false"
                            [tabIndex]="11" [widthTextColumns]="12" [widthLabelColumns]="12" [showLabel]="false"
                            (input)="calcularMonedas($event, 2)" />
                        </td>
                        <td class="text-end w-90px">{{mDosPesos | number: '1.2-2'}}</td>
                      </tr>
                      <tr>
                        <td class="text-end w-60px">$5</td>
                        <td class="w-90px">
                          <components-ui-number-box label="" formControlName="Moneda5Pesos" [aceptaPunto]="false"
                            [tabIndex]="12" [widthTextColumns]="12" [widthLabelColumns]="12" [showLabel]="false"
                            (input)="calcularMonedas($event, 5)" />
                        </td>
                        <td class="text-end w-90px">{{mCincoPesos | number: '1.2-2'}}</td>
                      </tr>
                      <tr>
                        <td class="text-end w-60px">$10</td>
                        <td class="w-90px">

                          <components-ui-number-box label="" formControlName="Moneda10Pesos" [aceptaPunto]="false"
                            [tabIndex]="13" [widthTextColumns]="12" [widthLabelColumns]="12" [showLabel]="false"
                            (input)="calcularMonedas($event, 10)" />
                        </td>
                        <td class="text-end w-90px">{{mDiezPesos | number: '1.2-2'}}</td>
                      </tr>
                      <tr>
                        <td class="text-end w-60px">$20</td>
                        <td class="w-90px">
                          <components-ui-number-box label="" formControlName="Moneda20Pesos" [aceptaPunto]="false"
                            [tabIndex]="14" [widthTextColumns]="12" [widthLabelColumns]="12" [showLabel]="false"
                            (input)="calcularMonedas($event, 20)" />
                        </td>
                        <td class="text-end w-90px">{{mVeintePesos | number: '1.2-2'}}</td>
                      </tr>
                      <tr>
                        <td class="text-end w-60px">$50</td>
                        <td class="w-90px">
                          <components-ui-number-box label="" formControlName="Moneda50Pesos" [aceptaPunto]="false"
                            [tabIndex]="15" [widthTextColumns]="12" [widthLabelColumns]="12" [showLabel]="false"
                            (input)="calcularMonedas($event, 50)" />
                        </td>
                        <td class="text-end w-90px">{{mCincuentaPesos | number: '1.2-2'}}</td>
                      </tr>
                      <tr>
                        <td class="text-end w-60px">$100</td>
                        <td class="w-90px">
                          <components-ui-number-box label="" formControlName="Moneda100Pesos" [aceptaPunto]="false"
                            [tabIndex]="16" [widthTextColumns]="12" [widthLabelColumns]="12" [showLabel]="false"
                            (input)="calcularMonedas($event, 100)" />
                        </td>
                        <td class="text-end w-90px">{{mCienPesos | number: '1.2-2'}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>


              <div class="widget widget-stats bg-blue-600 mb-0 p-8px mt-2">
                <div class="stats-icon stats-icon-lg">
                  <i class="far fa-money-bill-1 fs-70px" style="top:-19px"></i>
                </div>
                <div class="stats-content">
                  <div class="stats-number m-0 fs-20px">TOTAL EFECTIVO</div>
                  <div class="stats-progress progress m-0 w-50">
                    <div class="progress-bar" style="width: 0;"></div>
                  </div>
                  <div class="stats-number fs-20px m-0">{{myForm.value.TotalEfectivo|number: '1.2-2'}}</div>
                </div>
              </div>

              <hr>
              <div style="text-align: right;">
                <button type="button" (click)="accept()" class="btn btn-success me-5px">Aceptar</button>
                <button type="button" (click)="close()" class="btn btn-danger" #txtCancelar>Cancelar</button>
              </div>
            </form>
          </div>
        </app-panel-base>
      </div>
    </app-container-base>
  </div>
</div>
