import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { ReportFilter, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ReportsService } from 'src/app/service/reports.service';
import { UtilsService } from 'src/app/service/utils.service';

@Component({
  selector: 'verificador-ordenes-manufactura-page',
  templateUrl: './verificador-ordenes-manufactura-page.component.html',
})
export class VerificadorOrdenesManufacturaPageComponent implements AfterViewInit,OnInit {

  @ViewChild('txtNombreReporte')
  public txtNombreReporte!: ElementRef;
  private reportsService =inject(ReportsService);
  private utils = inject(UtilsService);
  listaOpciones: any[] = [];
  opcionSeleccionada?:ComboBoxEntity;


  menuId: string = "verificadorordenesmanufactura";

  public filtrosReporte: ReportFilter =
    {
      menuId:this.menuId,
      ReportHeader: {} as ReportHeader,
      Desglose: 'a Detalle',
      DesgloseLista: [{Nombre:'a Detalle'},
                      {Nombre:'a Totales PreOrden'},
                      {Nombre:'a Totales Producto'}],
      Fecha1: new Date(),
      Fecha2: new Date(),
      NombreReporte: '',
      TituloVisor:'Pendientes Por Producir',
      NombreExcel:'Pendientes Por Producir.xlsx',
      FilterOptions: [
        { Campo: 'emp.Clave', Etiqueta: 'Empresa', Tipo: TypeFilter.number, BotonBusqueda:true, Entidad:'Empresa' },
        { Campo: 'suc.Clave', Etiqueta: 'Sucursal', Tipo: TypeFilter.number,BotonBusqueda:true, Entidad:'Sucursal' },
        { Campo: '(ord.SerieString + CAST(ord.Folio AS VARCHAR(50)))', Etiqueta: 'Serie y Folio PreOrden', Tipo: TypeFilter.text},
        { Campo: 'ord.SerieFolioDocumentoVentaOrigen', Etiqueta: 'Serie y folio Factura', Tipo: TypeFilter.text },
        { Campo: 'pro.Clave', Etiqueta: 'Producto', Tipo: TypeFilter.text },
        { Campo: 'cli.Clave', Etiqueta: 'Cliente', Tipo: TypeFilter.number,BotonBusqueda:true, Entidad:'Cliente'  },
      ]
    };


  public myForm: FormGroup = this.fb.group({
    Fecha1: new Date(),
    Fecha2: new Date(),
    NombreReporte: '',
    Num1:0
  });

  activeButtons: ActiveButtons = {
    all: false,
    print: true,
  }

  constructor(private fb: FormBuilder) { }
  ngAfterViewInit(): void {
    this.listaOpciones = [{Id:0,Clave:0,Nombre:"Todas"},{Id:1,Clave:1,Nombre:"Producidas Parcialmente"},{Id:2,Clave:2,Nombre:"Producidas completamente"}]
    this.opcionSeleccionada = this.listaOpciones[0];
  }


  changeFiltros(value:ReportHeader){

    if (value){
      this.opcionSeleccionada = this.listaOpciones.find(p=>p.Id==value.Num1);
    }

  }


  ngOnInit(): void {


    //this.opcionSeleccionada = this.listaOpciones[0];
    const fecha: string = this.utils.Date2Str(new Date(), 5);
    this.myForm.get('NombreReporte')?.setValue('PENDIENTES POR PRODUCIR ' + this.rangoFechas);
    this.focus('txtNombreReporte');
  }

  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombreReporte;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  changeselec(event: any) {
    this.filtrosReporte = event;
  }

  onChangeFecha(value:any)
  {
    this.myForm.get('NombreReporte')?.setValue('PENDIENTES POR PRODUCIR ' + this.rangoFechas);
  }

  get rangoFechas(){
    return this.utils.getEtiquetaRangoPorFechas(this.myForm.value.Fecha1,this.myForm.value.Fecha2,2).toUpperCase();
   }

  clickBoton(event: string) {

    this.myForm.get("Num1")?.setValue(this.opcionSeleccionada!.Id);
    this.filtrosReporte.ReportHeader = this.myForm.value;
    this.reportsService.printReport(this.filtrosReporte,'/Manufactura/VerificadorOrdenManufactura');
  }

  selectedCombo(entity: any, tipo: string) {
      this.opcionSeleccionada = entity;
      this.myForm.get("Num1")?.setValue(this.opcionSeleccionada!.Id);
  }

  get selectOpcionManualItem(): ComboBoxEntity | null {
  //  this.myForm.get('NombreReporte')?.setValue('VERIFICADOR DE ÓRDENES DE PRODUCCIÓN ' + this.rangoFechas);
    return this.opcionSeleccionada!;
  }





 }
