import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { LevelType, ReportFilter, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ReportsService } from 'src/app/service/reports.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'existencias-page',
  templateUrl: './existencias-page.component.html',
  styleUrls: ['./existencias-page.component.scss']
})
export class ExistenciasPageComponent {

  @ViewChild('txtNombreReporte') public txtNombreReporte!: ElementRef<TextBoxComponent>;

  private frmBuilder = inject(FormBuilder);
  private reportsService = inject(ReportsService);

  tipos: ComboBoxEntity[] = [
    { Id: 1, Nombre: "Con Existencia", Clave: 1, ClaveNombre: "01 Con Existencia" },
    { Id: 2, Nombre: "Solo Negativos", Clave: 2, ClaveNombre: "02 Solo Negativos" },
    { Id: 3, Nombre: "En Cero", Clave: 3, ClaveNombre: "03 En Cero" },
    { Id: 4, Nombre: "Todos", Clave: 4, ClaveNombre: "04 Todos" }
  ];

  agrupadores: ComboBoxEntity[] = [
    { Id: 1, Nombre: "Familia", Clave: 1, ClaveNombre: "01 Familia" },
    { Id: 2, Nombre: "Clasificación", Clave: 2, ClaveNombre: "02 Clasificación" },
    { Id: 3, Nombre: "Sucursal", Clave: 3, ClaveNombre: "03 Sucursal" },
  ];

  tiposCostos: ComboBoxEntity[] = [
    { Id: 1, Nombre: "Última Compra", Clave: 1, ClaveNombre: "01 Última Compra" },
    { Id: 2, Nombre: "Reposición (Lista de precios)", Clave: 2, ClaveNombre: "02 Reposición (Lista de precios)" },
    { Id: 3, Nombre: "Promedio", Clave: 3, ClaveNombre: "03 Promedio" },
  ];

  tipoSeleccionado?: ComboBoxEntity;
  agrupadorSelecconado?: ComboBoxEntity;
  tipoCostosSelecconado?: ComboBoxEntity;
  menuId: string = "existencias";

  activeButtons: ActiveButtons = {
    all: false,
    print: true,
  }

  noVerCostos: boolean = false;

  ngOnInit() {
    this.noVerCostos = localStorage["noVerCostos"] === "true";
    setTimeout(() => {
      this.tipoSeleccionado = this.tipos[3];
      this.agrupadorSelecconado = this.agrupadores[0];
      this.tipoCostosSelecconado = this.tiposCostos[0];
      this.focus();
    }, 100);
  }

  focus() {
    const txt: any = this.txtNombreReporte;
    txt.tagInput.nativeElement.focus()
  }

  get selectTipoManualItem(): ComboBoxEntity | null {
    return this.tipoSeleccionado!;
  }

  get selectAgrupadorManualItem(): ComboBoxEntity | null {
    return this.agrupadorSelecconado!;
  }

  get selectTipoCostoItem(): ComboBoxEntity | null {
    return this.tipoCostosSelecconado!;
  }

  public frmConteoInventario: FormGroup = this.frmBuilder.group({
    NombreReporte: ['REPORTE DE EXISTENCIAS', Validators.required],
    Opc1: [false],
    Opc2: [false],
    Opc3: [false],
    Opc4: [false],
    Num1: [1],
    Num2: [1],
    Num3: [1],
    Fecha1: new Date()
  });

  public filtrosExistencia: ReportFilter =
    {
      menuId:this.menuId,
      ReportHeader: {
        NombreReporte: 'REPORTE DE EXISTENCIAS'
      } as ReportHeader,
      TituloVisor: 'REPORTE DE EXISTENCIAS',
      NombreExcel: 'existencias.xlsx',
      FilterOptions: [
        { Campo: 'emp.Clave', Etiqueta: 'Empresa', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Empresa", MultiClave: false, HelpLine: "Clave de la Empresa a consultar (F2 para buscar)." },
        { Campo: 'suc.Clave', Etiqueta: 'Sucursal', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Sucursal", MultiClave: false, HelpLine: "Clave de la Sucursal a consultar (F2 para buscar)." },
        { Campo: 'alm.Clave', Etiqueta: 'Almacén', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Almacen", MultiClave: false, HelpLine: "Clave del Almacen a consultar (F2 para buscar)." },
        { Campo: 'fam.ClaveGeneral', Etiqueta: 'Familias', Tipo: TypeFilter.levels, TipoNiveles: LevelType.productFamily, HelpLine:"Familias a de las que se buscarán los registros."  },
        { Campo: 'clasificacion.Clave', Etiqueta: 'Clasificación', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "ClasificacionProducto", MultiClave: false, HelpLine: "Clave de la Clasificación a consultar (F2 para buscar)." },
        { Campo: 'prod.Clave', Etiqueta: 'Producto', Tipo: TypeFilter.text, BotonBusqueda: true, Entidad: "Producto", MultiClave: false, HelpLine: "Clave del Producto a consultar (F2 para buscar)." },
        { Campo: 'prod.Nombre', Etiqueta: 'Descripción', Tipo: TypeFilter.text, HelpLine: "Descripción del Producto." },
      ]
    };

  eventosBotones(event: any) {
    if (event === "print") {
      let valorEmpresa = this.filtrosExistencia.FilterOptions![0].Valor;
      let valorAlmacen = this.filtrosExistencia.FilterOptions![2].Valor;
      if (this.frmConteoInventario.value.Opc4 && (!valorEmpresa || !valorAlmacen)) {
        Swal.fire({
          title: 'Datos Incompletos.',
          text: "Debe seleccionar al menos una Empresa y un Almacén.",
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Aceptar',
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then((result) => {
          if (result.isConfirmed) {

          }
        });
        return;
      }
      this.filtrosExistencia.ReportHeader = this.frmConteoInventario.value;
      this.reportsService.printReport(this.filtrosExistencia, '/Inventarios/ReporteExistencias', false);
    }
  }

  changeselec(event: any) {
    this.filtrosExistencia = event;
  }

  onClickCheckBox(ctrlName: string, value: any) {
    console.log(value)
    if (ctrlName.toLocaleLowerCase() === 'opc1') {
      this.frmConteoInventario.get('Opc1')?.setValue(value);
      if (value) {
        this.frmConteoInventario.get('Opc3')?.setValue(false);
      }
    } else if (ctrlName.toLocaleLowerCase() === 'opc2') {
      this.frmConteoInventario.get('Opc2')?.setValue(value);
    } else if (ctrlName.toLocaleLowerCase() === 'opc3') {
      this.frmConteoInventario.get('Opc3')?.setValue(value);
      if (value) {
        this.frmConteoInventario.get('Opc1')?.setValue(false);
      }
    } else if (ctrlName.toLocaleLowerCase() === 'opc4') {

    }
  }

  selectedCombo(entity: any, tipo: string) {
    if (tipo === "Tipo") {
      this.tipoSeleccionado = entity;
      this.frmConteoInventario.get('Num1')?.setValue(this.tipoSeleccionado?.Id);
    } else if (tipo === "Agrupador") {
      this.agrupadorSelecconado = entity;
      this.frmConteoInventario.get('Num2')?.setValue(this.agrupadorSelecconado?.Id);
    } else if (tipo === "TipoCostos") {
      this.tipoCostosSelecconado = entity;
      this.frmConteoInventario.get('Num3')?.setValue(this.tipoCostosSelecconado?.Id);
    }
  }
}
