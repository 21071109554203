<div class="card text-center border-0" cdkDrag style="width: 900px;box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;">
  <div style="cursor: move;" cdkDragHandle class="card-header fw-bold">Aplicación de Anticipos Facturados</div>
  <div class="card-body bg-light p-1">
    <app-panel-base title="Relación de facturas de anticipo disponibles">
      <div class="panel-content">
        <div class="alert alert-success" style="background-color: #348fe273 !important; padding: 4px;">
          <strong>NOTAS:</strong>
          - Listado de Facturas donde se facturó anticipo y que tienen disponible para aplicar en otras facturas<br />
          - En automático se genera una Nota de Crédito por aplicación de antitipo.
        </div>
        <div style="min-height: 300px; max-height: 301px;border: 1px solid;">
          <ng-scrollbar style="height: 250px; width:800px; border-bottom: 1px solid #AAAAAA;">
            <table class="blueTable" style="width:780px;">
              <thead style="position: sticky;top:0;">
                <tr>
                  <th style="width: 40px;">Usar</th>
                  <th style="width: 100px;">Sucursal</th>
                  <th style="width: 90px;text-align:center;">SerieFolio</th>
                  <th style="width: 150px;text-align:center;">Emisión</th>
                  <th style="width: 100px;text-align:right;">Total</th>
                  <th style="width: 100px;text-align:right;">Aplicado</th>
                  <th style="width: 100px;text-align:right;">Disponible</th>
                  <th style="width: 100px;text-align:right;">Aplicar</th>
                </tr>
              </thead>
              <tbody class="bg-light">
                <tr *ngFor="let item of source; let index = index;">
                  <td style="text-align: center;">
                    <div class="form-check ms-2 mt-1"  style="text-align: center;">
                      <input (click)="mark(index)" type="checkbox" class="form-check-input"
                        [checked]="item.seleccionado" id="product1">
                    </div>
                  </td>
                  <td><span class="ms-1"> {{item.sucursal.nombre}}</span></td>
                  <td style="text-align: center;"><span class="ms-1">{{item.serieFolio}}</span></td>
                  <td  style="text-align: center;"><span> {{ getDate(item.fechaEmision) }}</span></td>
                  <td style="text-align: right;"><span class="me-1"> {{item.total| number:'1.2-6'}}</span></td>
                  <td style="text-align: right;"><span class="me-1"> {{item.aplicado| number:'1.2-6'}}</span></td>
                  <td style="text-align: right;"><span class="me-1"> {{item.disponible| number:'1.2-6'}}</span></td>
                  <td style="text-align: right;"> <span
                      [class]="item.seleccionado ? 'text-green me-1':'text-dark me-1'">{{item.utilizar|
                      number:'1.2-6'}}</span></td>
                </tr>
              </tbody>
            </table>
          </ng-scrollbar>
        </div>
        <div style="text-align: right; margin-top: 5px;">
          <h5>Total a aplicar: {{getTotalUsado |number:'1.2-6'}}</h5>
          <h5>Diferencia: {{getDiferencia|number:'1.2-6'}}</h5>
          <button (click)="accept()" class="btn btn-success" style="margin-right: 5px;">Aceptar</button>
          <button (click)="closeModal()" class="btn btn-danger">Cancelar</button>
        </div>
      </div>
    </app-panel-base>
  </div>
</div>
