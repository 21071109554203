<app-container-base [activeButtons]="activeButtons" [hasCounter]="false"
  (onItemsComboSearched)="onItemsComboSearched($event)" (onItemSearched)="onItemSearched($event)" [entity]="myForm"
  (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)" entityName="PredefinidoCliente"
  title="Predefinidos de Alta de Clientes" icon="fa fa-angles-down"
  subTitle="Configure los predefinidos de Clientes, se colocarán de forma predefinida al capturar un nuevo Cliente">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Información">
        <div class="panel-content">
          <component-ui-combo-box (onNewCatalog)="onNewCatalog($event)" [enabled]="false" [label]="'Predefinido'"
            [widthList]="350" (onChangueEntity)="onChangueEntity($event)" formControlName="Id" [isCatalog]="true"
            [isNewItem]="getIsNew" [zeroMask]="3" [setItems]="getItemsCombo" [tabIndex]="1"
            [listProperty]="'ClaveNombre'" [widthTextColumns]="1" [widthLabelColumns]="2"
            entityName="PredefinidoCliente" />

          <components-ui-text-box [tabIndex]="2" [label]="'Nombre'" formControlName="Nombre" [widthTextColumns]="4"
            [widthLabelColumns]="2" [required]="true" #txtNombre />
        </div>
      </app-panel-base>
      <app-panel-base title="Predefinidos">
        <div class="panel-content">

          <component-ui-combo-box [required]="true" [label]="'Condición de pago'" formControlName="CondicionDePago"
            [tabIndex]="3" [zeroMask]="2" entityName="CondicionPago" [widthTextColumns]="2" [widthLabelColumns]="2"
            [widthRightColumns]="8" (onSelectedItem)="selectCombo($event, 'CondicionDePago')"
            [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box [required]="true" [label]="'Forma de pago'" formControlName="FormaPago" [tabIndex]="4"
            [zeroMask]="2" entityName="c_FormaPago" [widthTextColumns]="2" [widthLabelColumns]="2"
            [widthRightColumns]="8" (onSelectedItem)="selectCombo($event, 'FormaPago')"
            [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box [required]="true" [label]="'ClaseVenta'" formControlName="ClaseVenta1" [tabIndex]="5"
            [zeroMask]="2" entityName="ClaseVenta" [widthTextColumns]="2" [widthLabelColumns]="2"
            [widthRightColumns]="8" (onSelectedItem)="selectCombo($event, 'ClaseVenta1')"
            [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box [label]="'ClaseVenta'" formControlName="ClaseVenta2" [tabIndex]="6"
            [zeroMask]="2" entityName="ClaseVenta" [widthTextColumns]="2" [widthLabelColumns]="2"
            [widthRightColumns]="8" (onSelectedItem)="selectCombo($event, 'ClaseVenta2')"
            [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box [label]="'ClaseVenta'" formControlName="ClaseVenta3" [tabIndex]="7"
            [zeroMask]="2" entityName="ClaseVenta" [widthTextColumns]="2" [widthLabelColumns]="2"
            [widthRightColumns]="8" (onSelectedItem)="selectCombo($event, 'ClaseVenta3')"
            [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box [required]="true" [label]="'Moneda'" formControlName="Moneda" [tabIndex]="8"
            [zeroMask]="2" entityName="Moneda" [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="8"
            (onSelectedItem)="selectCombo($event, 'Moneda')" [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box [required]="true" [label]="'Tipo'" formControlName="TipoCliente" [tabIndex]="9"
            [zeroMask]="2" entityName="TipoCliente" [widthTextColumns]="2" [widthLabelColumns]="2"
            [widthRightColumns]="8" (onSelectedItem)="selectCombo($event, 'TipoCliente')"
            [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box [required]="true" [label]="'Clase'" formControlName="ClaseCliente" [tabIndex]="10"
            [zeroMask]="2" entityName="ClaseCliente" [widthTextColumns]="2" [widthLabelColumns]="2"
            [widthRightColumns]="8" (onSelectedItem)="selectCombo($event, 'ClaseCliente')"
            [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box [required]="true" [label]="'Lista de Precios'" formControlName="ListaPrecio"
            [tabIndex]="11" [zeroMask]="2" entityName="ListaPrecio" [widthTextColumns]="2" [widthLabelColumns]="2"
            [widthRightColumns]="8" (onSelectedItem)="selectCombo($event, 'ListaPrecio')"
            [listProperty]="'ClaveNombre'" />


        </div>
      </app-panel-base>
    </form>
  </div>
</app-container-base>
