import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../components/components.module';
import { ComponentUiModule } from '../component-ui/component-ui.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProductoPageComponent } from './pages/producto-page/producto-page.component';
import { FamiliasComponent } from './pages/familias/familias.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { SeleccionarTreeComponent } from '../component-ui/components/seleccionar-familia-page/seleccionar-familia-page.component';
import { AlmacenesPageComponent } from './pages/almacenes-page/almacenes-page.component';
import { ConceptosAlmacenesPageComponent } from './pages/conceptos-almacenes-page/conceptos-almacenes-page.component';
import { TipoMovimientoAlmacenPageComponent } from './pages/tipo-movimiento-almacen-page/tipo-movimiento-almacen-page.component';
import { ClasificacionProductoPageComponent } from './pages/clasificacion-producto-page/clasificacion-producto-page.component';
import { KardexPageComponent } from './pages/kardex-page/kardex-page.component';
import { DateBoxComponent } from '../component-ui/components/date-box/date-box.component';
import { CostoVentasPageComponent } from './pages/costo-ventas-page/costo-ventas-page.component';
import { SerieTraspasoPageComponent } from './pages/serie-traspaso-page/serie-traspaso-page.component';
import { NgxEditorModule } from 'ngx-editor';
import { MaximosMinimosPageComponent } from './pages/maximos-minimos-page/maximos-minimos-page.component';
import { GuiGridModule } from '@generic-ui/ngx-grid';
import { TraspasoSucursalesPageComponent } from './pages/traspaso-sucursales-page/traspaso-sucursales-page.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { VentasModule } from '../ventas/ventas.module';
import { VerificadorTraspasosPageComponent } from './pages/reportes/verificador-traspasos-page/verificador-traspasos-page.component';
import { TraspasoAlmacenesPageComponent } from './pages/traspaso-almacenes-page/traspaso-almacenes-page.component';
import { TipoCapturaInvFisicoPageComponent } from './pages/tipo-captura-inv-fisico-page/tipo-captura-inv-fisico-page.component';
import { ConfiguracionInvFisicoPageComponent } from './pages/configuracion-inv-fisico-page/configuracion-inv-fisico-page.component';
import { CtrlExistenciasPageComponent } from '../components/ctrl-existencias-page/ctrl-existencias-page.component';
import { InventarioFisicoComponent } from './pages/inventario-fisico-page/inventario-fisico-page.component';
import { OpcionCancelacionTraspasoPageComponent } from './pages/opcion-cancelacion-traspaso-page/opcion-cancelacion-traspaso-page.component';
import { ConfiguracionTraspasoPageComponent } from './pages/configuracion-traspaso-page/configuracion-traspaso-page.component';
import { RecepcionTraspasoPageComponent } from './pages/recepcion-traspaso-page/recepcion-traspaso-page.component';
import { ImpresionConteoComponent } from './pages/impresion-conteo/impresion-conteo.component';
import { ExistenciasPageComponent } from './pages/existencias-page/existencias-page.component';
import { ExistenciasPaquetesComponent } from './pages/existencias-paquetes-page/existencias-paquetes-page.component';
import { ComprasModule } from '../compras/compras.module';
import { HistoricoPaquetesPageComponent } from './pages/historico-paquetes.page/historico-paquetes.page.component';
import { TipoProductoPageComponent } from './pages/tipo-producto-page/tipo-producto-page.component';
import { MovimientosInventarioComponent } from './pages/movimientos-inventario/movimientos-inventario.component';
import { MotivoDiferenciaPageComponent } from './pages/motivo-diferencia-page/motivo-diferencia-page.component';
import { VerificadorInventarioPageComponent } from './pages/verificador-inventario-page/verificador-inventario-page.component';
import { ImpresionProductosComponent } from './pages/impresion-productos/impresion-productos.component';
import { TiposDocumentosInventariosPageComponent } from './pages/tipos-documentos-inventarios-page/tipos-documentos-inventarios-page.component';
import { SeriesInventariosPageComponent } from './pages/series-inventarios-page/series-inventarios-page.component';
import { EmisionDocumentoInventarioPageComponent } from './pages/emision-documento-inventario-page/emision-documento-inventario-page.component';
import { VerificadorDocumentosInventarioComponent } from './pages/verificador-documentos-inventario/verificador-documentos-inventario.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { UnidadesMedidaPageComponent } from './pages/unidades-medida-page/unidades-medida-page.component';

@NgModule({
  declarations: [
    ProductoPageComponent,
    FamiliasComponent,
    AlmacenesPageComponent,
    ConceptosAlmacenesPageComponent,
    TipoMovimientoAlmacenPageComponent,
    ClasificacionProductoPageComponent,
    KardexPageComponent,
    CostoVentasPageComponent,
    SerieTraspasoPageComponent,
    MaximosMinimosPageComponent,
    TraspasoSucursalesPageComponent,
    VerificadorTraspasosPageComponent,
    TraspasoAlmacenesPageComponent,
    TipoCapturaInvFisicoPageComponent,
    ConfiguracionInvFisicoPageComponent,
    InventarioFisicoComponent,
    OpcionCancelacionTraspasoPageComponent,
    ConfiguracionTraspasoPageComponent,
    RecepcionTraspasoPageComponent,
    ImpresionConteoComponent,
    ExistenciasPageComponent,
    ExistenciasPaquetesComponent,
    HistoricoPaquetesPageComponent,
    TipoProductoPageComponent,
    MovimientosInventarioComponent,
    MotivoDiferenciaPageComponent,
    VerificadorInventarioPageComponent,
    ImpresionProductosComponent,
    TiposDocumentosInventariosPageComponent,
    SeriesInventariosPageComponent,
    EmisionDocumentoInventarioPageComponent,
    VerificadorDocumentosInventarioComponent,
    UnidadesMedidaPageComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ComponentsModule,
    ComponentUiModule,
    DateBoxComponent,
    ReactiveFormsModule,
    NgScrollbarModule,
    NgxEditorModule,
    GuiGridModule,
    NgbTooltipModule,
    VentasModule,
    ComprasModule,
    DragDropModule
  ]
})
export class InventarioModule { }
