<app-container-base [hasCounter]="false" icon="fa fa-bars-staggered" [hasBar]="true" [setIsNew]="esNuevo"
  entityName="DocumentoInventario" [entity]="myForm" [getLast]="false" (onBeforeSave)="onBeforeSave($event)"
  (onAfterSave)="onAfterSave($event)" subTitle="Emisión de Documentos de Inventario" [printManual]="true"
  (onClickBarButton)="onClickBarButton($event)" [activeButtons]="activeButtons">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off" style="display: flex;">
      <app-panel-base *ngIf="sourceProducts.length>0 && cords" title="Búsqueda de Productos"
        style="position: absolute; z-index:9;margin-left:150px;margin-top:80px;width:700px;box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;">
        <div class="panel-content">
          <ng-scrollbar style="height: 400px;">
            <table class="table table-sm table-bordered"
              style="border: 1px solid rgb(164, 164, 164);height: 38px;table-layout: fixed !important; width:670px;">
              <thead style="position: sticky;top:0;font-weight: 700 !important;background-color: #ff8000;">
                <tr>
                  <th style="font-weight:700;color:#ffffff;width:200px;" class="pt-0 pb-0">Producto</th>
                  <th style="font-weight:700;color:#ffffff;width:150px;" class="pt-0 pb-0">Unidad</th>
                  <th style="font-weight:700;color:#ffffff;width:70px;text-align: center;" class="pt-0 pb-0">Existencia
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style="cursor:pointer;width:200px;" (click)="clicTablaProducto($event, item)"
                  [id]="'row-search' + idUnico + '_'+i" [class]="selectedIndex == i ? 'bg-blue-800 pulse':''"
                  *ngFor="let item of sourceProducts; let i = index;">
                  <td
                    [style]="'width:100px;'+(selectedIndex == i ? 'color:#ffffff;font-weight:bold;':(item.Precio>0?'':'color:red;'))"
                    class="text-truncate"> {{item.Clave}} - {{item.Nombre}}</td>
                  <td
                    [style]="'width:100px;'+(selectedIndex == i ? 'color:#ffffff;font-weight:bold;':(item.Precio>0?'':'color:red;'))"
                    class="text-truncate">{{item.Unidad.Nombre}}</td>
                  <td
                    [style]="'width:100px;text-align: right;'+(selectedIndex == i ? 'color:#ffffff;font-weight:bold;':(item.Precio>0?'':'color:red;'))"
                    class="text-truncate">{{item.Existencia | number:'1.2-6'}}
                  </td>
                </tr>
              </tbody>
            </table>
          </ng-scrollbar>
        </div>
      </app-panel-base>
      <div style="width: 960px;">
        <app-panel-base title="Información del Documento" [style]="'width:1100px;'">
          <div class="panel-content">
            <div class="d-flex">
              <component-ui-combo-box style="width:15%; margin-right: 10px;" formControlName="Sucursal"
                [topLabel]="true" label="Sucursal" [inputProperty]="'Nombre'" [zeroMask]="3" [entityName]="'Sucursal'"
                [tabIndex]="1" [widthLabelColumns]="12" [widthTextColumns]="12" [enabled]="false"
                [widthRightColumns]="0" helpLine="Es la Sucursal de donde salen los Productos." />

              <component-ui-combo-box style="width:15%; margin-right: 10px;" formControlName="TipoDocumento"
                [topLabel]="true" label="Tipo Doc" [setItemsList]="getItemsComps" [zeroMask]="3" [tabIndex]="2"
                [widthLabelColumns]="12" [widthTextColumns]="12"
                (onSelectedItem)="selectedCombo($event,'TipoDocumento')" [widthRightColumns]="0"
                helpLine="Tipo de Documento de Inventario a Generar." #cboDocumento />


              <component-ui-combo-box style="width:15%; margin-right: 10px;" formControlName="Serie" [topLabel]="true"
                label="Serie" [setItemsList]="getItemsSerie" [zeroMask]="3" [tabIndex]="3" [widthLabelColumns]="12"
                [widthTextColumns]="12" (onSelectedItem)="selectedCombo($event,'Serie')" [inputProperty]="'Serie'"
                [widthRightColumns]="0" #cboSerie [listProperty]="'Nombre'" [extras]="getExtras"
                helpLine="Serie del Documnto de Inventario a generar." />


              <components-ui-number-box style="width:10%; margin-right: 10px;" [topLabel]="true" formControlName="Folio"
                label='Folio' [tabIndex]="4" [widthLabelColumns]="12" [widthTextColumns]="12" (blur)="blurFolio($event)"
                helpLine="Folio a emitir o Folio a buscar, indique el número de folio para buscar o bien, presione el botón Nuevo de la barra de botones."
                #txtFolioTrasSuc />

              <components-ui-date-box style="width: 15%;" label="Emisión" [tabIndex]="5" [topLabel]="true"
                formControlName="FechaEmision" [widthLabelColumns]="12" [widthTextColumns]="6" #fechaEmisionTrasSuc />

            </div>

          </div>
        </app-panel-base>
        <app-panel-base title="Conceptos" [style]="'width:1100px;'">
          <div class="panel-content">
            <div class="mb-1">
              <ng-scrollbar style="height: 200px; width:1290px; border-bottom: 1px solid #AAAAAA;">
                <table class="blueTable" style="width:1068px;">
                  <thead style="position: sticky;top:0;">
                    <tr>
                      <th style="width: 50px;"></th>
                      <th style="width: 65px;text-align:center;">Producto </th>
                      <th style="width: 180px;">Descripción</th>
                      <th style="width: 60px;min-width: 60px;max-width: 60px;text-align:right;padding-right:2px;">
                        Cantidad
                      </th>
                      <th style="width: 80px;text-align:center;">Unidad</th>
                      <th style="width: 160px;text-align:center;">Conversión</th>
                      <th style="width: 65px;text-align:right;">Costo</th>
                      <th style="width: 90px;text-align:right;">Existencia</th>
                    </tr>
                  </thead>
                  <tbody class="bg-light">
                    <tr [style]=" indexEditing == index ?  'background-color: #ffdbb7;': ''"
                      (keydown)="keyDownRow($event, index)" *ngFor="let item of source; let index = index;">
                      <td style="text-align: center;" (click)="deleteRow(index)">
                        <i (click)="deleteRow(index)" *ngIf="item.Producto" class="fa fa-rectangle-xmark fa-1x"
                          style="color:red;font-weight: bold; cursor: pointer;"></i>
                      </td>
                      <td style="width: 65px;min-width:65px;max-width: 65px;">
                        <input [disabled]="loading" (keyup)="buscarProducto($event)"
                          (keydown.escape)="escapeProducto($event)" (keydown.arrowup)="up($event,0)"
                          (keydown)="navegarProductos($event)" (keydown.arrowdown)="down($event,0)"
                          (keydown.enter)="enterProducto($event)" class="grid-editor text-dark"
                          [id]="'txt_'+index+'_0' + idUnico" (focus)="focusProducto(index, $event, item)"
                          (blur)="blurProducto($event)" type="text" [value]="item.Producto ? item.Producto.Clave: ''" />
                      </td>
                      <td>
                        <span style="margin-left: 3px;">{{item.Descripcion}}</span>
                      </td>
                      <td style="width: 60px;min-width:60px;max-width: 60px;"><input *ngIf="item.Producto"
                          [disabled]="loading" (keydown)="keyDownCantidad($event)"
                          (keydown.escape)="escapeCantidad($event)" (keydown.arrowup)="up($event,3)"
                          (keydown.arrowdown)="down($event,3)" (keydown.enter)="enterCantidad($event)"
                          class="grid-editor text-dark" [id]="'txt_'+index+'_3' + idUnico" (blur)="blurCantidad($event)"
                          (focus)="setIndexEdit(index, $event, item)" type="number" (keydown.f2)="openExistencias()"
                          [style]="'width:100%;text-align:right;'" [value]="item.Producto ? item.Cantidad: 0" />
                      </td>
                      <td class="text-dark">
                        <span style="margin-left: 3px;"> {{(item.Producto ? (item.Producto.Unidad ?
                          item.Producto.Unidad!.Nombre: ''):'')}}</span>
                      </td>
                      <td>
                        <a *ngIf="item.TextoConversiones" class="alert-link text-dark" (click)="conversionClick(index)"
                          style="cursor:pointer;margin-left: 3px;">{{item.TextoConversiones}} </a>
                      </td>
                      <td class="text-lg-end pe-1">
                        <span *ngIf="getNoVerCostos">{{item.Costo != 0 ? (item.Costo| number:'1.2-6'):''}}</span>
                      </td>
                      <td class="text-lg-end pe-1 bg-lime-200 text-dark" style="font-weight: bold;">
                        <span>{{item.Existencia | number:'1.2-6'}}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ng-scrollbar>
              <div class="card-body pe-0">
                <table class="table table-borderless table-sm fw-bold m-0">
                  <tbody>
                    <tr *ngIf="getNoVerCostos">
                      <td class="pb-2" colspan="2">
                        <h4><b>Total Costo</b></h4>
                      </td>
                      <td class="text-end pb-2 text-decoration-underline bg-success-subtle ">
                        <h3><b>${{getTotalCosto | number:'1.2'}}</b></h3>
                      </td>
                    </tr>
                    <tr class="mt-1">
                      <td class="pb-2" colspan="2">
                        <h4><b>Total Cantidad</b></h4>
                      </td>
                      <td class="text-end pb-2 text-decoration-underline bg-success ">
                        <h3><b>{{getTotalCantidad | number:'1.2-6'}}</b></h3>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <hr *ngIf="myForm.value.UsuarioElabora">
            <div class="d-flex">
              <hr *ngIf="myForm.value.UsuarioElabora">
              <div style="width:70%;">
                <div class="widget-list mb-0" style="display: flex" *ngIf="myForm.value.UsuarioElabora">
                  <div class="widget-list-item" style="width:25%;">
                    <div class="widget-list-media">
                      <i class="fas fa-user-check fa-1x text-green"></i>
                    </div>
                    <div class="widget-list-content">
                      <h4 class="widget-list-title">{{myForm.value.UsuarioElabora.Nombre}}</h4>
                      <p class="widget-list-desc">Usuario elaboró</p>
                    </div>
                  </div>
                  <div class="widget-list-item" style="width:40%;" *ngIf="myForm.value.UsuarioCancela">
                    <div class="widget-list-media">
                      <i class="fas fa-user-xmark fa-2x text-red"></i>
                    </div>
                    <div class="widget-list-content">
                      <h4 class="widget-list-title">{{myForm.value.UsuarioCancela.Nombre}}</h4>
                      <p class="widget-list-desc">Usuario canceló</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </app-panel-base>
        <div class="additional-desc" *ngIf="showDescription">
          <app-panel-base title="Descripción">
            <div class="form-group panel-content">
              <textarea class="form-control" formControlName="DescripcionAdicional" rows="10"
                #txtDescription></textarea>
              <div style="text-align: right; margin-top: 5px;">
                <button (click)="acceptDescription()" class="btn btn-success"
                  style="margin-right: 5px;">Aceptar</button>
                <button (click)="cancelDescription()" class="btn btn-danger">Cancelar</button>
              </div>
            </div>
          </app-panel-base>
        </div>
        <app-panel-base title="Comentarios" [style]="'width:1100px;height:100px;'">
          <div class="panel-content">
            <textarea maxlength="1000" formControlName="Comentarios" class="w-100" rows="2" cols="50"
              [tabIndex]="8"></textarea>
          </div>
        </app-panel-base>
      </div>
      <div style="width:280px;margin-left:143px;">
        <app-panel-base title="Opciones Especiales" *ngIf="myForm.value.Id>0">
          <div class="panel-content">
            <div class="d-flex" style="width:100%;flex-direction: column;padding: 10px;">
              <div *ngIf="!myForm.value.FechaCancelacion" class="btn btn-sm btn-white mb-10px me-2"><i
                  class="fa fa-circle-check t-plus-1 text-success fa-fw fa-lg"></i> Vigente</div>
              <div *ngIf="myForm.value.FechaCancelacion" class="btn btn-sm btn-white mb-10px me-2"><i
                  class="fa fa-circle-check t-plus-1 text-danger fa-fw fa-lg"></i> CANCELADO</div>
              <div (click)="openCancel()" *ngIf="!myForm.value.FechaCancelacion"
                class="btn btn-sm btn-white mb-10px me-2"><i
                  class="fa fa-calendar-xmark t-plus-1 text-danger fa-fw fa-lg"></i> Cancelar</div>
            </div>

          </div>
        </app-panel-base>

      </div>
    </form>
    <div style="margin-top:-500px;" class="cancelled-header animate__animated animate__bounceInDown"
      *ngIf="myForm.value.FechaCancelacion">
      <div class="cancelled">&nbsp;</div>
      <div class="cancelled-detail">{{getCancelDate}}</div>
      <div class="cancelled-detail">{{ myForm.value.MotivoCancelacion | uppercase}}</div>
    </div>

  </div>
  <ng-template #modalCancelOptiones let-modal let-c="close">
    <app-opcion-cancelacion-traspaso-page />
  </ng-template>

  <ng-template #ctrlConversiones let-modal let-c="close">
    <app-conversiones-page [items]="conversiones" [unidadFinal]="unidadFinal" />
  </ng-template>

  <ng-template #ctrlExistenciasTrasSuc let-modal let-c="close">
    <app-ctrl-existencias-page #ctrlExistencias [manejaPaquetes]="false"
      [idProducto]="this.source[this.indexEditing].Producto!.Id" [idEmpresa]="this.myForm.value.Empresa.Id"
      [idSucursal]="this.myForm.value.Sucursal.Id"
      [producto]="this.source[this.indexEditing].Producto!.Clave + ' ' + this.source[this.indexEditing].Producto!.Nombre" />
  </ng-template>


</app-container-base>
