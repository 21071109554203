<app-container-base (onItemsComboSearched)="onItemsComboSearched($event)" (onItemSearched)="onItemSearched($event)"
  (onBeforeSave)="onBeforeSave($event)" (onAfterSave)="onAfterSave($event)" [entity]="myForm" entityName="SerieTraspaso"
  title="Series de Traspasos" icon="fa fa-cart-flatbed-suitcase"
  subTitle="Administra la configuración de Series de Traspasos">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Identificación de la Serie de Traspaso">
        <div class="panel-content">
          <component-ui-combo-box [required]="true" [enabled]="false" [label]="'Empresa'" formControlName="Empresa"
            [zeroMask]="2" entityName="Empresa" [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="8"
            [tabIndex]="1" (onSelectedItem)="selectedCombo($event,'Empresa')" [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box [required]="true" [enabled]="false" [label]="'Sucursal'" formControlName="Sucursal"
            [tabIndex]="2" [zeroMask]="2" entityName="Sucursal" [widthTextColumns]="2" [widthLabelColumns]="2"
            [widthRightColumns]="8" (onSelectedItem)="selectedCombo($event,'Sucursal')"
            [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box formControlName="Id" (onNewCatalog)="onNewCatalog($event)" [label]="'Clave'"
            (onChangueEntity)="onChangueEntity($event)" [isCatalog]="true" [isNewItem]="getIsNew" [zeroMask]="3"
            [required]="true" [setItems]="getItemsCombo" [tabIndex]="3" [listProperty]="'ClaveNombre'"
            [widthTextColumns]="1" [widthLabelColumns]="2" entityName="SerieTraspaso" />

          <components-ui-text-box [label]="'Nombre'" formControlName="Nombre" [tabIndex]="4" [required]="true"
            helpLine="Nombre de la Serie de Traspaso, sirve para identificarla" [widthTextColumns]="3"
            [widthLabelColumns]="2" #txtNombre [maxlength]="150" />

          <components-ui-text-box [label]="'Serie'" formControlName="Serie" [maxLength]="10" [required]="true"
            helpLine="Serie a utilizar, 10 letras, ejemplo = TRP" [tabIndex]="5" [widthTextColumns]="3"
            [widthLabelColumns]="2" [maxlength]="20" />

          <components-ui-text-box [label]="'Formato'" formControlName="Formato" [maxLength]="50" [tabIndex]="6"
            helpLine="Formato a utilizar para imprimir el Traspaso/Solicitud" [widthTextColumns]="3"
            [widthLabelColumns]="2" />

          <components-ui-text-box [label]="'Texto que imprime'" formControlName="TextoImprimir" [maxLength]="50"
            helpLine="Indique el texto que imprimirá en el encabezado superior derecho del formato." [tabIndex]="7"
            [widthTextColumns]="3" [widthLabelColumns]="2" />

          <components-ui-text-box [label]="'Copias'" formControlName="Copias" [maxLength]="50" [tabIndex]="8"
            helpLine="Copias a imprimir, se indican separados por una coma, ejemplo: Origina, Copia, Almacen"
            [widthTextColumns]="3" [widthLabelColumns]="2" />

        </div>
      </app-panel-base>
      <app-panel-base title="Afectación de Almacenes">
        <div class="panel-content">

          <component-ui-combo-box [enabled]="!myForm.value.EsSolicitudDeTraspaso"
            [required]="!myForm.value.EsSolicitudDeTraspaso" [label]="'Tipo de Movimiento'"
            formControlName="TipoMovimiento" [tabIndex]="9" [zeroMask]="2" entityName="TipoMovimientoAlmacen"
            [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="8"
            (onSelectedItem)="selectedCombo($event,'TipoMovimiento')" [listProperty]="'ClaveNombre'" />

          <component-ui-combo-box [enabled]="!myForm.value.EsSolicitudDeTraspaso"
            [required]="!myForm.value.EsSolicitudDeTraspaso" [label]="'Almacén'" formControlName="Almacen"
            [tabIndex]="10" [zeroMask]="2" entityName="Almacen" [widthTextColumns]="2" [widthLabelColumns]="2"
            [widthRightColumns]="8" (onSelectedItem)="selectedCombo($event,'Almacen')" [listProperty]="'ClaveNombre'" />


          <component-ui-combo-box [enabled]="!myForm.value.EsSolicitudDeTraspaso"
            [required]="!myForm.value.EsSolicitudDeTraspaso" label='Concepto salida' formControlName="ConceptoSalida"
            entityName="ConceptoAlmacen" helpLine="Concepto de salida en la Sucursal/Almacen que envía"
            filterQuery="EsSalida = true" [tabIndex]="11" [widthTextColumns]="2" [widthLabelColumns]="2"
            [widthRightColumns]="8" (onSelectedItem)="selectedCombo($event,'ConceptoSalida')" [zeroMask]="3" />

          <component-ui-combo-box [enabled]="!myForm.value.EsSolicitudDeTraspaso"
            [required]="!myForm.value.EsSolicitudDeTraspaso" label='Concepto entrada' formControlName="ConceptoEntrada"
            helpLine="Concepto de entrada en la Sucursal/Almacen destino" filterQuery="EsEntrada = true"
            entityName="ConceptoAlmacen" [tabIndex]="12" [widthTextColumns]="2" [widthLabelColumns]="2"
            [widthRightColumns]="8" (onSelectedItem)="selectedCombo($event,'ConceptoEntrada')" [zeroMask]="3" />

          <component-ui-check-box helpLine="Indica que solo es para solicitar un traspaso entre sucursales"
            [tabIndex]="13" [widthLabelColumns]="2" formControlName="EsSolicitudDeTraspaso" [widthTextColumns]="7"
            [label]="'Es solicitud de Traspaso'" />

          <component-ui-check-box
            helpLine="Indica que la serie se usará para los traspasos entre sucursales, si no, se usara entre almacenes"
            [tabIndex]="14" [widthLabelColumns]="2" formControlName="EsEntreSucursales" [widthTextColumns]="7"
            [label]="'Es entre sucursales'" />

          <component-ui-check-box
            helpLine="Indica que mantendrá un estado de en tránsito, mientras no sea recibida en la Sucursal/Almacén destino."
            [tabIndex]="15" [widthLabelColumns]="2" formControlName="ManejaTransito" [widthTextColumns]="7"
            [label]="'Usa tránsito'" />


          <component-ui-combo-box [label]="'Almacén De Tránsito'" formControlName="AlmacenTransito" [tabIndex]="16"
            [zeroMask]="2" entityName="Almacen" [widthTextColumns]="2" [widthLabelColumns]="2" [widthRightColumns]="8"
            (onSelectedItem)="selectedCombo($event,'AlmacenTransito')" [listProperty]="'ClaveNombre'" />

        </div>
      </app-panel-base>

      <app-panel-base title="Configuración para el envió de correos">
        <div class="panel-content">
          <component-ui-check-box
            helpLine="Al guardar el Traspaso/Solicitud de Traspaso, se enviará por correo al encargado de Sucursal"
            [tabIndex]="16" [widthLabelColumns]="2" formControlName="EnviarCorreo" [widthTextColumns]="7"
            [label]="'Envíar correo a Sucursal'" />

          <div formGroupName="ConfiguracionCorreo">
            <input type="hidden" formControlName="Id">
            <components-ui-text-box
              helpLine="Indique el servidor que se usará para el envío de correos electrónicos, 100 caracteres"
              [tabIndex]="17" [enabled]="myForm.value.EnviarCorreo" [maxLength]="100" [label]="'ServidorSMTP'"
              formControlName="ServidorSMTP" [widthTextColumns]="2" [widthLabelColumns]="2" />
            <components-ui-number-box helpLine="Puerto a utilizar según el Servidor SMTP, 10 números" [tabIndex]="18"
              [maxLength]="10" [enabled]="myForm.value.EnviarCorreo" [label]="'Puerto'" formControlName="Puerto"
              [widthTextColumns]="2" [widthLabelColumns]="2" />
            <component-ui-check-box helpLine="Seleccione si el Servidor SMTP requiere SSL" [tabIndex]="19"
              [widthLabelColumns]="2" [enabled]="myForm.value.EnviarCorreo" formControlName="SSL" [widthTextColumns]="7"
              [label]="'SSL'" />
            <component-ui-check-box [enabled]="myForm.value.EnviarCorreo"
              helpLine="Seleccione si el Servidor SMTP requiere la versión 1.2 para la seguridad" [tabIndex]="20"
              [widthLabelColumns]="2" formControlName="TLS12" [widthTextColumns]="7" [label]="'TLS 1.2'" />
            <components-ui-text-box [enabled]="myForm.value.EnviarCorreo"
              helpLine="Usuario o correo electrónico, 100 caracteres" [tabIndex]="21" [maxLength]="100"
              [label]="'Usuario'" formControlName="Usuario" [widthTextColumns]="2" [widthLabelColumns]="2" />
            <components-ui-password-box [enabled]="myForm.value.EnviarCorreo"
              helpLine="Indique la contraseña del correo electrónico, 100 caracteres" [tabIndex]="22" [maxLength]="100"
              [label]="'Contraseña'" formControlName="Contrasena" [widthTextColumns]="2" [widthLabelColumns]="2" />
            <div class="NgxEditor__Wrapper border-1" style="border: 1px solid !important;">
              <ngx-editor-menu [editor]="editor"> </ngx-editor-menu>
              <ngx-editor [editor]="editor" [ngModel]="html" [disabled]="false" formControlName="CuerpoCorreo"
                [placeholder]="'Cuerpo del correo que se le enviará a los encargados de Sucursal destino'"></ngx-editor>
            </div>
          </div>
        </div>
      </app-panel-base>
    </form>
  </div>

</app-container-base>
