<div cdkDrag class="data-search card border-1 modal-corte-caja" style="width:1500px;">
  <div class="card-header" cdkDragHandle>
    <button (click)="close()" type="button" class="btn-close" aria-label="Close"></button>
    <h5>Búsqueda de Corte de Caja</h5>
  </div>
  <div class="card-body bg-white-100 p-5px">
    <app-container-base [hasBar]="false" [hasCounter]="false" [hasHeader]="false" [containerInnerHeight]="0"
      [esPopup]="true">
      <div class="screen-content">
        <app-panel-base title="Seleccione los filtros para buscar">
          <div class="panel-content">
            <form class="frmBuscarCorte" [formGroup]="frmBuscarCorte" autocomplete="off">
              <component-ui-combo-box #cboSucursal label="Sucursal" inputProperty="ClaveNombre" [zeroMask]="3"
                [tabIndex]="1" [widthLabelColumns]="2" [widthTextColumns]="3"
                (onSelectedItem)="seleccionSucursal($event)" [entityName]="'Sucursal'" formControlName="Sucursal"
                [tabIndex]="1" [selectManualItem]="selectCuentaManualItem"/>
              <div class="row">
                <div class="col">
                  <components-ui-date-box (onChangeValue)="onChangeFechasCorte($event, 'fechaInicio')"
                    label="Fecha Inicial" [widthLabelColumns]="6" [widthTextColumns]="6" [tabIndex]="2"
                    formControlName="FechaInicio" />
                </div>
                <div class="col">
                  <components-ui-date-box label="Fecha Inicial" [widthLabelColumns]="6" [widthTextColumns]="6"
                    [tabIndex]="3" (onChangeValue)="onChangeFechasCorte($event, 'fechaFin')"
                    formControlName="FechaFin" />
                </div>
                <div class="col">
                  <button type="button" class="btn btn-primary" (click)="buscarCorte()">
                    <i class="fa fa-magnifying-glass"></i>
                    Buscar
                  </button>
                </div>
              </div>
            </form>

            <table class="blueTable fs-14px mt-10px" style="width:1300px;">
              <thead style="position: sticky;top:0;">
                <tr>
                  <th style="width:80px;" class="">Sucursal</th>
                  <th style="width:80px;" class="text-center">Fecha</th>
                  <th style="width:80px;" class="text-center">Caja</th>
                  <th style="width:80px;" class="text-center">Encargado</th>
                  <th style="width:80px;" class="text-end">Efectivo</th>
                  <th style="width:80px;" class="text-end">Terminales</th>
                  <th style="width:80px;" class="text-end">Cheques</th>
                  <th style="width:80px;" class="text-end">Transferencias</th>
                  <th style="width:80px;" class="text-end">Retiros</th>
                  <th style="width:80px;" class="text-end">Fondo</th>
                  <th style="width:80px;" class="text-end">Declarar</th>
                  <th style="width:80px;" class="text-end">Vtas Contado</th>
                  <th style="width:80px;" class="text-end">Ctas Crédito</th>
                  <th style="width:80px;" class="text-end">Tot Contado</th>
                  <th style="width:80px;" class="text-end">Total día</th>
                  <th style="width:100px;" class="w-100px"></th>
                </tr>
              </thead>
            </table>
            <cdk-virtual-scroll-viewport itemSize="20" style="height: 300px;border-bottom: 1px solid #AAAAAA;">
              <table class="blueTable fs-14px">
                <thead style="visibility: collapse;">
                  <tr>
                    <th style="width:80px;" class="">Sucursal</th>
                    <th style="width:80px;" class="text-center">Fecha</th>
                    <th style="width:80px;" class="text-center">Caja</th>
                    <th style="width:80px;" class="text-center">Encargado</th>
                    <th style="width:80px;" class="text-end">Efectivo</th>
                    <th style="width:80px;" class="text-end">Terminales</th>
                    <th style="width:80px;" class="text-end">Cheques</th>
                    <th style="width:80px;" class="text-end">Transferencias</th>
                    <th style="width:80px;" class="text-end">Retiros</th>
                    <th style="width:80px;" class="text-end">Fondo</th>
                    <th style="width:80px;" class="text-end">Declarar</th>
                    <th style="width:80px;" class="text-end">Vtas Contado</th>
                    <th style="width:80px;" class="text-end">Ctas Crédito</th>
                    <th style="width:80px;" class="text-end">Tot Contado</th>
                    <th style="width:80px;" class="text-end">Total día</th>
                    <th style="width:100px;" class="w-100px"></th>
                  </tr>
                </thead>
                <tbody class="bg-light fs-12px" >
                  <tr *ngFor="let c of cortesCaja; let i = index">
                    <td class="">{{c.Sucursal ? c.Sucursal.Clave +' '+c.Sucursal.Nombre: ''}}</td>
                    <td class="text-center">{{c.Fecha | date: 'dd/MM/yyyy'}}</td>
                    <td class="">{{c.Caja ? c.Caja.Nombre : ''}}</td>
                    <td class="">{{c.Encargado ? c.Encargado.Nombre: ''}}</td>
                    <td class="text-end">{{c.Efectivo | number:'1.2' }}</td>
                    <td class="text-end">{{c.TotalTerminales | number:'1.2' }}</td>
                    <td class="text-end">{{c.TotalCheques | number:'1.2' }}</td>
                    <td class="text-end">{{c.TotalTransferencias | number:'1.2' }}</td>
                    <td class="text-end">{{c.TotalDepositosCajaGeneral | number:'1.2' }}</td>
                    <td class="text-end">{{c.Fondo | number:'1.2' }}</td>
                    <td class="text-end">{{c.TotalContado | number:'1.2' }}</td>
                    <td class="text-end">{{c.TotalVentasContado | number:'1.2' }}</td>
                    <td class="text-end">{{c.TotalCredito | number:'1.2' }}</td>
                    <td class="text-end">{{c.TotalDia | number:'1.2' }}</td>
                    <td class="text-end">{{c.TotalDia | number: '1.2-2'}}</td>
                    <td>
                      <button type="button" class="btn-select-corte btn btn-warning btn-xs"
                        (click)="seleccionarCorte(c)">
                        Seleccionar
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </cdk-virtual-scroll-viewport>
          </div>
        </app-panel-base>
      </div>
    </app-container-base>
  </div>
</div>
