import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ReportFilter, ReportFilterOrderList, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { UtilsService } from 'src/app/service/utils.service';
import { ReportsService } from '../../../../service/reports.service';
import { id } from '@swimlane/ngx-charts';

@Component({
  selector: 'diario-ventas-page.component',
  templateUrl: './diario-ventas-page.component.html',

})
export class DiarioVentasPageComponent implements OnInit, AfterViewInit {

  @ViewChild('txtNombreReporte')
  public txtNombreReporte!: ElementRef;

  private _utils = inject(UtilsService);

  menuId: string = "diarioventas";
  filtrosDiarioVentasPredefinidos: ReportFilter = {} as ReportFilter;

  public ordenadoLista: ReportFilterOrderList[] = [
    { Nombre: 'Ninguno', Orden: '[ven.Clave],[dv.Clave],[clasev.Clave],[cli.Clave]', Id: 0 },
    { Nombre: 'Empresa-Sucursal', Orden: 'emp.Clave,suc.Clave,[dv.Clave],[ven.Clave],[clasev.Clave],[cli.clave]', Id: 1 }]

  public luegoPorLista: ReportFilterOrderList[] = [
    { Nombre: 'Ninguno', Orden: '', Id: 0 },
    { Nombre: 'Cliente', Orden: 'cli.Clave', Id: 1 },
    { Nombre: 'Vendedor', Orden: 'ven.Clave', Id: 2 },
    { Nombre: 'Tipo Comprobante ', Orden: 'dv.Clave', Id: 3 },
    { Nombre: 'Clase Venta', Orden: 'clasev.Clave', Id: 4 }
  ]


  public filtrosDiarioVentas: ReportFilter =
    {
      menuId: this.menuId,
      ReportHeader: {} as ReportHeader,
      Desglose: 'a Detalle',
      DesgloseLista: [{ Nombre: 'a Detalle' },
      { Nombre: 'a Totales' }],
      OrdenadoLista: this.ordenadoLista,
      LuegoPorLista: this.luegoPorLista,
      Fecha1: new Date(),
      Fecha2: new Date(),
      NombreReporte: '',
      TituloVisor: 'Reporte de Diario de venta',
      NombreExcel: 'Diario de venta.xlsx',
      FilterOptions: [
        { Campo: 'emp.Clave', Etiqueta: 'Empresa', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Empresa" },
        { Campo: 'suc.Clave', Etiqueta: 'Sucursal', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Sucursal" },
        { Campo: 'dv.Clave', Etiqueta: 'Tipo de Comprobante', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "DocumentoVenta" },
        { Campo: 'cli.Clave', Etiqueta: 'Cliente', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Cliente" },
        { Campo: 'mon.Clave', Etiqueta: 'Moneda', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Moneda" },
        { Campo: 'clasev.Clave', Etiqueta: 'Clase de Venta', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "ClaseVenta" },
        { Campo: 'usuElab.Clave', Etiqueta: 'Usuario Elabora', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Usuario"},
        { Campo: 'ven.Clave', Etiqueta: 'Vendedor Factura', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Usuario", Filtro: "EsVendedor = true" },
        { Campo: 'vencliente.Clave', Etiqueta: 'Vendedor Cliente', Tipo: TypeFilter.number, Entidad: "Usuario", Filtro: "EsVendedor = true" },
        { Campo: 'cob.Clave', Etiqueta: 'Cobrador', Tipo: TypeFilter.number, Entidad: "Usuario", Filtro: "EsVendedor = true" },
        { Campo: 'v.Vigente', Etiqueta: 'Vigente', Tipo: TypeFilter.boolean, ListaItems: [{ Nombre: 'Sin selección' }, { Nombre: 'Vigentes' }, { Nombre: 'Cancelado' }]   },
        { Campo: 'metodo.Clave', Etiqueta: 'Método Pago', Tipo: TypeFilter.number, Entidad: "MetodoPago", },
      ]
    };


  public myForm: FormGroup = this.fb.group({
    Fecha1: new Date(),
    Fecha2: new Date(),
    NombreReporte: '',
    Opc1: [false],
    Opc2: [false],
    Opc3: [false],
    Opc4: [false],
  });

  activeButtons: ActiveButtons = {
    all: false,
    print: true,
  }

  constructor(private fb: FormBuilder, private reportsService: ReportsService) { }
  ngAfterViewInit(): void {

  }

  ngOnInit(): void {
    let fecha = new Date();
    this.myForm.get('Fecha1')?.setValue(fecha);
    this.myForm.get('NombreReporte')?.setValue('DIARIO DE VENTAS ' + this.rangoFechas);
    this.focus('txtNombreReporte');
  }


  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombreReporte;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  onChangeFecha(value: any) {
    this.myForm.get('NombreReporte')?.setValue('DIARIO DE VENTAS ' + this.rangoFechas);
  }

  get rangoFechas(): string {
    return this._utils.getEtiquetaRangoPorFechas(this.myForm.value.Fecha1, this.myForm.value.Fecha2, 2).toUpperCase();
  }


  onclicSinAnticipos(value: any) {
    if (value) {
      this.myForm.get('Opc3')?.setValue(!value);
    }
  }

  onclicSoloAnticipos(value: any) {
    if (value) {
      this.myForm.get('Opc1')?.setValue(!value);
    }
  }

  onclicSinFacturaGlobal(value: boolean) {
    if (value) {
      this.myForm.get('Opc4')?.setValue(!value);
    }
  }

  onclicSoloFacturaGlobal(value: boolean) {
    if (value) {
      this.myForm.get('Opc2')?.setValue(!value);
    }
  }

  changeselec(event: any) {
    this.filtrosDiarioVentas = event;
  }

  clickBoton(event: string) {
    this.filtrosDiarioVentas.ReportHeader = this.myForm.value;
    this.reportsService.printReport(this.filtrosDiarioVentas, '/Ventas/ReporteDiarioDeVentas');
  }

}
