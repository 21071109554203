import { MotivoDiferencia } from './../../interfaces/producto.interface';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, ViewChild, inject } from '@angular/core';
import { Result, UserLogged } from 'src/app/auth/interfaces';
import { ComboBoxComponent } from 'src/app/component-ui/components/combo-box/combo-box.component';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { ComboBoxEntity, Coordinates } from 'src/app/component-ui/interfaces/combo-text.interface';
import { EventsService } from 'src/app/service/events.service';
import { InventarioService } from '../../services/inventario.service';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilsService } from 'src/app/service/utils.service';
import { ModalService } from 'src/app/service/modal.service';
import { ReportsService } from 'src/app/service/reports.service';
import { environment } from 'src/environments/environment';
import { InventarioFisicoCabecera, InventarioFisicoDetalle, InventarioFisicoDetalleConversiones, TipoCapturaInventarioFisico } from '../../interfaces/fisico.interface';
import { Producto, ProductoUnidad } from '../../interfaces/producto.interface';
import { FilterOptions, ReportFilter, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { Empresa } from 'src/app/configuracion/interfaces/empresa.interface';
import { Sucursal } from 'src/app/configuracion/interfaces/sucursal.interface';
import { Almacen } from 'src/app/home/interfaces/almacen.interface';
import Swal from 'sweetalert2';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { PanelBaseDropDownMenu } from 'src/app/component-ui/interfaces/panelbase.interface';
import * as moment from 'moment';
import { FileUploadService } from 'src/app/component-ui/services/file-upload-box.service';
import { Usuario } from 'src/app/configuracion/interfaces/usuario.interface';
import { FacturacionService } from 'src/app/ventas/services/facturacion.service';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';
import { DateBoxComponent } from 'src/app/component-ui/components/date-box/date-box.component';
import { CheckBoxComponent } from 'src/app/component-ui/components/check-box/check-box.component';
import { CargaentregaService } from 'src/app/ventas/services/cargaentrega.service';
import { ConfiguracionCargaEntrega } from 'src/app/ventas/interfaces/cargaentrega.interface';
import { DocumentoInventarioDetalle } from '../../interfaces/documentosinventario.interface';
import { AppSettings } from 'src/app/home/services/app-settings.service';


@Component({
  selector: 'app-inventario-fisico',
  templateUrl: './inventario-fisico-page.component.html',
})
export class InventarioFisicoComponent {

  //* Referencia a elementos de la pantalla
  @ViewChild('almacenInv')
  public almacenInv!: ElementRef<ComboBoxComponent>;
  @ViewChild('txtFolioInv')
  public txtFolio!: ElementRef<TextBoxComponent>;
  @ViewChild('TipoCapturaInv')
  public TipoCapturaInv!: ElementRef<TextBoxComponent>;
  @ViewChild('txtDescription')
  public txtDescription!: ElementRef<HTMLElement>;
  @ViewChild('busquedaProductosInvFisico')
  public ctrlBusquedaProductos!: ElementRef<HTMLElement>;
  @ViewChild('modalCancelarInvFisico')
  public modalCancelarInvFisico!: ElementRef<HTMLElement>;
  @ViewChild('ctrlConversionesInvFisico')
  public ctrlConversiones!: ElementRef<HTMLElement>;
  @ViewChild('txtFileInvFisico')
  public txtFile!: ElementRef<HTMLInputElement>;
  @ViewChild('txtFechaEmisionInfFis')
  public txtFechaEmisionInfFis!: ElementRef<DateBoxComponent>;
  @ViewChild('esGlobalcheckBox')
  public esGlobalcheckBox!: ElementRef<CheckBoxComponent>;



  //*  Variables globales del usuario
  info: UserLogged = {} as UserLogged;
  usuarioActual: UserLogged = {} as UserLogged;
  almacenActual: ComboBoxEntity = {} as ComboBoxEntity;
  tipoCapturaActual: ComboBoxEntity = {} as ComboBoxEntity;
  almacenCargaEntrega: Almacen | null = null;
  listaSeries: ComboBoxEntity[] = [];
  private readonly baseUrl: string = environment.baseUrlApi;
  showDescription: boolean = false;
  loading: boolean = false;
  downloading: boolean = false;
  file: any = null;
  saving: boolean = false;
  blockCombos: boolean = false;
  mascara: string = "";
  noVerCostos: boolean = false;
  totalRegistros: number = 0;
  totalCosto: number = 0;
  totalCantidad: number = 0;


  //* injects
  private eventsService = inject(EventsService);
  private invService = inject(InventarioService);
  private cService = inject(ContainerBaseService);
  private fb = inject(FormBuilder);
  private utilsService = inject(UtilsService);
  private ms = inject(ModalService);
  private reportsService = inject(ReportsService)
  private http = inject(HttpClient);
  private fus = inject(FileUploadService)
  private fServices = inject(FacturacionService)
  private cargaEntregaService = inject(CargaentregaService);
  public appSettings = inject(AppSettings);
  private cb = inject(ContainerBaseService);

  //* variables del grid
  source: Array<InventarioFisicoDetalle> = [];
  columnEditing: number = -1;
  selectedIndex: number = -1;
  indexEditing: number = -1;
  //navigateColumns: boolean = false;
  sourceProducts: any[] = [];
  sourceMotivo: any[] = [];
  esEscapeProducto: boolean = false;
  esEscapeConteo: boolean = false;
  esEscapeMotivo: boolean = false;

  esEnterProducto: boolean = false
  esEnterCosto: boolean = false
  esEnterMotivo: boolean = false
  esEnterConteo: boolean = false

  enviarFocoProducto: boolean = false
  enviarFocoConteo: boolean = false
  enviarFocoMotivo: boolean = false
  enviarOtroRenglon: boolean = false;

  cords: any = null;
  idUnico: string = '';

  existencia: number = 0;
  conversiones: ProductoUnidad[] = [];
  unidadFinal: string = '';
  abrirBusqueda: boolean = false;
  importing: boolean = false;

  colProducto: number = 1;
  colConteo: number = 5;
  colMotivo: number = 10;



  //* oppciones del menu del panel
  menuItems: PanelBaseDropDownMenu[] = [
    { Id: 'plantilla', Nombre: 'Descargar ejemplo del layout *.csv' },
    { Id: 'cargar', Nombre: 'Cargar layout *.csv' },
    { Id: 'recalcular', Nombre: 'Recalcular diferencias' }]

  public filtroInvFisico: ReportFilter =
    {
      ReportHeader: {} as ReportHeader,
      NombreReporte: '',
      Desglose: 'a Detalle',
      TituloVisor: 'Reporte de inventario fisico',
      NombreExcel: 'Reporte de inventario fisico.xlsx',
      FilterOptions: [
        { Campo: 'Inv.Id', Etiqueta: '', Tipo: TypeFilter.number },
      ]
    };

  public myFormTotales: FormGroup = this.fb.group({
    TotalRegistros: [''],
  });

  public myForm: FormGroup = this.fb.group({
    Id: [0],
    Empresa: [{} as Empresa, Validators.required],
    Sucursal: [{} as Sucursal, Validators.required],
    Almacen: [{} as Almacen, Validators.required],
    Codigo: [0],
    Observaciones: [''],
    UsuarioCaptura: [null],
    UsuarioCancela: [null],
    UsuarioAplica: [null],
    Tipo: [null, Validators.required],
    Fecha: null,
    FechaCancelacion: null,
    FechaAplicacion: null,
    Aplicado: false,
    EsGlobal: false,
    Detalle: [[] as InventarioFisicoDetalle[]],
    Eliminado: false,
    Baja: false,
    MotivoCancelacion: ''
  })



  constructor() {
  }

  ngAfterViewInit(): void {
    let elem = this.myForm.value;
    elem.Empresa = { Id: this.info.empresa?.numero, Clave: this.info.empresa?.clave, Nombre: this.info.empresa?.nombre };
    elem.Sucursal = { Id: this.info.sucursal?.numero, Clave: this.info.sucursal?.clave, Nombre: this.info.sucursal?.nombre };
    elem.Fecha = new Date();
    setTimeout(() => {
      this.myForm.reset(elem);
      const txtSerie: any = this.almacenInv;
      txtSerie.tagInput.nativeElement.focus();
    }, 150);
  }

  ngOnInit(): void {
    this.idUnico = String(new Date().getTime() * 10000);
    this.appSettings.appSidebarMinified = true;
    this.info = this.utilsService.getUserLogged();
    this.usuarioActual = this.utilsService.getUserLogged();
    this.fServices.obtenerMascaraProducto(this.info.empresa!.numero).subscribe((result) => { this.mascara = result.message });
    this.sourceReset();
    this.cargaEntregaService.getConfiguracion().subscribe((ent: ConfiguracionCargaEntrega) => {
      if (ent) {
        this.almacenCargaEntrega = ent.AlmacenSalida;
      }
    });
    this.cb.obtenerValorPropiedad("Usuario", "Rol.NoVerCostos", this.info.numero).subscribe((value) => {
      this.noVerCostos = value.toLowerCase() == "true";
    });

  }

  //* ///////////////////////////////////////////////////
  //* 1.- metodos de inicializacion de la pantalla y grid


  // get getExtras() {
  //   return " Serie + String(' ', 1) + Nombre as SerieNombre, Serie";
  // }

  //* 2.- metodos de control cambio para los campos principales, (cancelaciones

  selectAlmacen(entity: ComboBoxEntity) {
    this.myForm.controls["Almacen"].setValue(entity);
    this.almacenActual = entity;
    setTimeout(() => {
      const txtTipo: any = this.TipoCapturaInv;
      txtTipo.tagInput.nativeElement.focus();
    }, 100);
  }

  get permiteEliminar() {
    return !(this.myForm.value.FechaAplicacion || this.myForm.value.FechaCancelacion);
  }
  openCancel() {
    const b: any = this.modalCancelarInvFisico;
    this.ms.openModal(b, (e: any) => {
      if (e) {
        this.proceedCancel(e)
      }
    })
  }
  openAplicar() {

    this.eventsService.publish('home:showAlert', {
      message: '¿Desea aplicar el Inventario Físico?',
      onConfirm: (r: AlertResponse) => {
        if (r.isAccept) {
          this.eventsService.publish('home:isLoading', { isLoading: true });
          const date2 = new Date();
          const d = { year: date2.getFullYear(), month: date2.getMonth(), day: date2.getDate(), hour: date2.getHours(), min: date2.getMinutes(), sec: date2.getSeconds() };
          const Fecha = new Date(d.year, d.month, d.day, d.hour, d.min, d.sec);
          const date = moment(Fecha).format("YYYY-MM-DDTHH:mm:ss");
          const params = new HttpParams()
            .set("id", this.myForm.value.Id)
            .set("fechaAplicacion", date);
          this.http.get<Result>(`${this.baseUrl}/Inventarios/AplicarInvFisico`, { params }).subscribe((result) => {
            this.eventsService.publish('home:isLoading', { isLoading: false });
            if (result.success) {
              this.findEntityByParams()
            } else {
              Swal.fire({
                text: result.message, icon: 'info',
              });
            }
          });
        }
      }
    });
  }


  get getNoVerCostos() {
    return this.noVerCostos
  }



  get getCancelDate(): string {
    if (this.myForm.value.FechaCancelacion) {
      return moment(this.myForm.value.FechaCancelacion).format("DD/MM/YYYY");
    }
    return "";
  }

  proceedCancel(e: any) {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    const date2 = new Date();
    const d = { year: e.Fecha.getFullYear(), month: e.Fecha.getMonth(), day: e.Fecha.getDate(), hour: date2.getHours(), min: date2.getMinutes(), sec: date2.getSeconds() };
    e.Fecha = new Date(d.year, d.month, d.day, d.hour, d.min, d.sec);
    const date = moment(e.Fecha).format("YYYY-MM-DDTHH:mm:ss");
    const params = new HttpParams()
      .set("id", this.myForm.value.Id)
      .set("motivoCancelacion", e.MotivoCancelacion)
      .set("fechaCancelacion", date);
    return this.http.get<Result>(`${this.baseUrl}/Inventarios/CancelarInvFisico`, { params }).subscribe((result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result.success) {
        this.findEntityByParams()
      } else {
        Swal.fire({
          text: result.message, icon: 'info',
        })
      }
    });
  }

  //* 3.- metodos de control de flujo del grid, (eliminar row)
  //* ////////////////////////////////////////////


  keyDownRow(e: any, index: number) {
    if (e.ctrlKey && e.keyCode == "46") {
      e.preventDefault();
      this.deleteRow(index);
    }
  }

  escapeProducto(e: any) {
    let index = this.indexEditing;
    this.esEscapeProducto = true;
    let item: InventarioFisicoDetalle = this.source[index];
    e.target.value = item.Producto?.Clave ? item.Producto?.Clave : "";
    setTimeout(() => {
      this.esEscapeProducto = true;
      const item = { ...this.source[index] };
      if (!item.Producto) {
        e.target.value = "";
      } else {
        e.target.value = item.Producto.Clave;
      }
      if (index > 0) {
        this.indexEditing = index - 1;
        //this.initEditor(index, 1);
        this.enviarFocoProducto = true;
        this.sendFocus();
      } else {
        const txt: any = this.esGlobalcheckBox;
        txt.tagInput.nativeElement.focus()
      }
    }, 50);
  }

  enterFecha() {
    const txt: any = this.esGlobalcheckBox;
    txt.tagInput.nativeElement.focus()
  }

  tabCheckBox(e: any) {
    e.preventDefault();
    this.indexEditing = 0
    this.initEditor(this.indexEditing, 1);
  }


  enterCheckBox() {

    this.indexEditing = 0
    this.initEditor(this.indexEditing, 1);
  }


  up(e: any, celda: number) {
    e.preventDefault();
    if (celda == 0 && this.sourceProducts.length > 0) {
      return;
    }
    if (this.indexEditing > 0) {
      if (celda == 0) {
        this.esEscapeProducto = true;
      }
      e.target.blur();
      setTimeout(() => {
        this.indexEditing = this.indexEditing - 1;
        this.initEditor(this.indexEditing, celda + 1);
        this.esEscapeProducto = false;
      }, 50);
    } else {
      this.initEditor(this.indexEditing, 1);
    }
  }

  down(e: any, celda: number) {
    e.preventDefault();
    if (celda == 0 && this.sourceProducts.length > 0) {
      return;
    }

    if (celda == 9 && this.sourceMotivo.length > 0) {
      return;
    }


    let c = this.getLastItem();
    if (this.indexEditing < c - 1) {
      e.target.blur();
      this.indexEditing = this.indexEditing + 1;
      setTimeout(() => {
        this.initEditor(this.indexEditing, celda + 1);
      }, 50);
    } else {
      if (this.indexEditing + 1 < c + 1) {
        //        this.esEscapeProducto = true;
        e.target.blur();
        this.indexEditing = this.indexEditing + 1;
        setTimeout(() => {
          this.source = [...this.source, this.estructuraVacia()];
          this.initEditor(this.indexEditing, 1);
        }, 250);
      }
    }
  }

  navegarMotivo(e: any) {
    let row = null;
    if (e.keyCode == 9) {
      e.preventDefault();
      return;
    }
    if (e.keyCode == 40) {
      if (this.selectedIndex + 1 < this.sourceMotivo.length) {
        this.selectedIndex++;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`)
        row!.scrollIntoView({ block: "center" });

      }
    } else if (e.keyCode == 38) {
      if (this.selectedIndex > 0) {
        this.selectedIndex--;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`)
        row!.scrollIntoView({ block: "center" });
      }
    }
  }


  getLastItem(): number {
    return this.source.filter(P => P.Producto).length;
  }

  enterProducto(e: any) {
    this.esEnterProducto = true;
    this.enviarFocoConteo = true;
    let value = '';
    if (this.sourceProducts.length > 0) {
      value = this.sourceProducts[this.selectedIndex].Clave;
    } else {
      value = e.target.value;
    }
    e.target.value = value;
    if (value) {
      e.target.blur();
    }
  }

  focusProducto(index: number, e: any, item: InventarioFisicoDetalle) {
    this.setIndexEdit(index, e, item);
    let c = this.getLastItem();

    if (c == 0 && index > 0) {
      e.target.blur();
      return;
    }

    if ((index >= c + 1) && c > 0) {
      e.target.blur();
    }
  }




  setIndexEdit(index: number, e: any, item: InventarioFisicoDetalle) {

    if (!this.myForm.value.Almacen.Id) {
      this.eventsService.publish('home:showAlert', {
        textAccept: "Aceptar",
        cancelButton: false,
        message: 'Favor de indicar el Almacén.',
        onConfirm: (data: AlertResponse) => {
          this.indexEditing = -1;
          const txt: any = this.almacenInv;
          txt.tagInput.nativeElement.focus()
          return;
        }
      });
      this.indexEditing = -1;
      e.target.blur();
      return;
    }

    if (this.myForm.value.Codigo == 0) {
      this.eventsService.publish('home:showAlert', {
        textAccept: "Aceptar",
        cancelButton: false,
        message: 'Favor de indicar el Folio.',
        onConfirm: (data: AlertResponse) => {
          this.indexEditing = -1;
          const txt: any = this.txtFolio;
          txt.tagInput.nativeElement.focus()
          return;
        }
      });
      this.indexEditing = -1;
      e.target.blur();
      return;
    }


    if (!this.myForm.value.Tipo.Id) {
      this.eventsService.publish('home:showAlert', {
        textAccept: "Aceptar",
        cancelButton: false,
        message: 'Favor de indicar el Tipo.',
        onConfirm: (data: AlertResponse) => {
          this.indexEditing = -1;
          const txt: any = this.TipoCapturaInv;
          txt.tagInput.nativeElement.focus()
          return;
        }
      });
      this.indexEditing = -1;
      e.target.blur();
      return;
    }

    if (!this.permiteGuardar) {
      this.indexEditing = -1;
      e.target.blur();
      return;
    }





    //todo revisar este codigo y ver como aplica con el nuevo grid
    // if (!this.permiteEliminar) {
    //   return false;
    // }

    // if (index > 0) {
    //   if (!this.source[index - 1].Producto) {
    //     return false;
    //   }
    //   if (this.source[index - 1].Producto!.Id == 0) {
    //     return false;
    //   }
    // }




    //* si esta cancelado, no deberia de poder editar algo.
    if (this.myForm.value.FechaCancelacion) {
      this.indexEditing = -1;
      e.target.blur();
      return;
    }

    this.indexEditing = index;
    e.target.select();
    e.target.classList.add("focus-editor-grid");
  }

  blurProducto(e: any) {
    let index = this.indexEditing;
    e.target.classList.remove("focus-editor-grid");

    setTimeout(() => {
      this.cords = null;
      if (this.esEscapeProducto) {
        this.esEnterProducto = false;
        this.esEscapeProducto = false;
        return;
      }
      let item = { ...this.source[index] };
      if (item.Producto) {
        if (!e.target.value) {
          e.target.value = item.Producto.Clave;
          this.enviarFocoProducto = true;
          this.sendFocus();
          return;
        }

        if (item.Producto.Clave != e.target.value) {
          this.searchProduct(e.target.value);
        } else {
          if (this.enviarFocoConteo) {
            this.sendFocus();
          }
        }
      } else {
        if (e.target.value != "") {
          this.searchProduct(e.target.value);
        } else {
          e.target.classList.remove("focus-editor-grid");
        }
      }
      this.esEnterProducto = false;
      this.esEscapeProducto = false;
    }, 100);
  }


  escapeMotivo(e: any) {
    this.esEscapeMotivo = true;
    let item: InventarioFisicoDetalle = this.source[this.indexEditing];
    e.target.value = item.MotivoDiferencia ? item.MotivoDiferencia.Clave : 0;
    this.initEditor(this.indexEditing, this.colConteo);
  }

  // enterMotivo(e: any) {
  //   this.enviarOtroRenglon = true;
  //   e.target.blur();
  // }


  enterMotivo(e: any) {
    this.esEnterMotivo = true;
    this.enviarOtroRenglon = true;
    let value: any = '';
    if (this.sourceMotivo.length > 0) {
      value = this.sourceMotivo[this.selectedIndex].Clave;
    } else {
      value = e.target.value;
    }
    e.target.value = value;
    if (value) {
      e.target.blur();
    }
  }




  keyDownConteo(e: any) {
    // const noDecimales: boolean = this.source[this.indexEditing].Producto!.NoDecimalesCantidad;
    // if (e.key === '.' && noDecimales) { e.preventDefault(); }
    // if (e.keyCode == 40 || e.keyCode == 38) {
    //   e.preventDefault()
    // }
  }



  escapeConteo(e: any) {
    this.esEscapeConteo = true;
    let item: InventarioFisicoDetalle = this.source[this.indexEditing];
    e.target.value = item.Conteo ? item.Conteo : 0;
    this.initEditor(this.indexEditing, 1);
  }

  enterConteo(e: any) {
    this.enviarFocoMotivo = true;
    this.esEnterConteo = true;
    e.target.blur();
  }


  blurConteo(e: any) {
    if (e.target.value == "") e.target.value = "0";
    const conteo: number = parseFloat(e.target.value);
    if (this.esEscapeConteo) {
      this.esEscapeConteo = false;
      return;
    }

    // if (conteo == 0  && this.esEnterConteo) {
    //   this.enviarFocoMotivo = false;
    //   this.enviarFocoConteo = true;
    //   this.sendFocus();
    //   return;
    // }

    e.target.classList.remove("focus-editor-grid");

    let item = this.source[this.indexEditing];
    // if (parseFloat(String(item.Conteo)) == conteo) {
    //   if (item.Diferencia==0){
    //     this.enviarFocoMotivo = false;
    //     this.enviarOtroRenglon = true;
    //     this.sendFocus();
    //   }else{
    //     if (this.enviarFocoMotivo) {
    //       this.sendFocus();
    //     }
    //   }
    //   return;
    // };
    if (item.Conversiones) {
      if (item.Conversiones.length > 0) {
        let totalEnConversiones = 0;
        item.Conversiones.forEach((i) => totalEnConversiones += i.CantidadUnidadConcepto);
        if (conteo != totalEnConversiones) {
          item.Conteo = totalEnConversiones;
        } else {
          item.Conteo = conteo;
        }
        item.Diferencia = item.Conteo - (item.Existencia + item.PorSurtir);
        this.source[this.indexEditing] = { ...item };
        this.source = [...this.source];
        if (item.Diferencia == 0) {
          this.enviarOtroRenglon = true;
        } else {
          this.enviarFocoMotivo = true;
        }
        this.sendFocus();
      } else {
        item.Conteo = conteo;
        item.Diferencia = item.Conteo - (item.Existencia + item.PorSurtir);
        item.Conversiones = [];
        item.TextoConversiones = "";
        this.source[this.indexEditing] = { ...item };
        this.source = [...this.source];
        if (item.Diferencia == 0) {
          this.enviarOtroRenglon = true;
        } else {
          this.enviarFocoMotivo = true;
        }
        this.sendFocus();
      }
    } else {
      item.Conteo = conteo;
      item.Diferencia = item.Conteo - (item.Existencia + item.PorSurtir);
      item.Conversiones = [];
      item.TextoConversiones = "";
      this.source[this.indexEditing] = { ...item };
      this.source = [...this.source];
      if (item.Diferencia == 0) {
        // this.source[this.indexEditing] = { ...item };
        // this.source = [...this.source];
        this.enviarOtroRenglon = true;
      } else {
        this.enviarFocoMotivo = true;
      }
      this.sendFocus();
    }
    this.calcTotales();
  }



  blurMotivo(e: any) {
    let index = this.indexEditing;
    e.target.classList.remove("focus-editor-grid");

    setTimeout(() => {
      this.cords = null;
      if (this.esEscapeMotivo) {
        this.esEnterMotivo = false;
        this.esEscapeMotivo = false;
        return;
      }
      let item = { ...this.source[index] };
      if (item.MotivoDiferencia) {
        if (!e.target.value) {
          e.target.value = item.MotivoDiferencia.Clave;
          this.enviarOtroRenglon = true;
          this.sendFocus();
          return;
        }
        if (item.MotivoDiferencia.Clave != e.target.value) {
          this.buscarMotivo(e.target.value, index);
        } else {
          if (this.esEnterMotivo) {
            if (this.enviarOtroRenglon) {
              this.sendFocus();
            }
          }
        }
      } else {
        if (e.target.value != "") {
          this.buscarMotivo(e.target.value, index);
        } else {
          e.target.classList.remove("focus-editor-grid");
        }
      }
      this.esEnterMotivo = false;
      this.esEscapeMotivo = false;
      this.source[this.indexEditing] = { ...item };
      this.source = [...this.source];
    }, 100);
  }

  focusMotivo(index: number, e: any, item: InventarioFisicoDetalle) {
    let c = this.getLastItem();
    if (index > 0 && index > (c + 1)) {
      return;
    }

    if (e.target.value == "") {
      this.buscarCatalogo(e, "Motivo", true);
      e.preventDefault();
    } else {
      this.sourceMotivo = [];
    }
    this.setIndexEdit(index, e, item);
    if (c == 0 && index > 0) {
      e.target.blur();
      return;
    }

    if ((index >= c + 1) && c > 0) {
      e.target.blur();
    }
  }



  buscarMotivo(value: string, index: number) {

    if (!value) {
      return;
    };

    if (!this.utilsService.esNumero(value)) {
      value = "0";
    }


    this.loading = true;
    const params = new HttpParams().set("entidad", "MotivoDiferencia").set("clave", value).set("filtro", "");
    return this.http.get<MotivoDiferencia>(`${this.baseUrl}/Base/ObtenerEntidadPorClave`, { params }).subscribe((motivo) => {
      this.loading = false;
      if (motivo) {
        let item = this.source[index];
        item.MotivoDiferencia = motivo;
        item.MotivoDiferenciaClave = motivo.Clave.toString();
        item.MotivoDiferenciaNombre = motivo.Nombre;
        this.sourceReset(this.source);
        if (index == this.indexEditing) {
          this.enviarOtroRenglon = true;
          this.sendFocus();
        }

      } else {
        this.eventsService.publish('home:showAlert', {
          textAccept: "Aceptar",
          cancelButton: false,
          message: "No se encontró el motivo indicado.",
          onConfirm: (data: AlertResponse) => {
            this.source[index].MotivoDiferenciaClave = "";
            this.source[index].MotivoDiferencia = null;
            this.source[index].MotivoDiferenciaNombre = "";
            this.source = [...this.source];
            this.initEditor(index, this.colMotivo);
          }
        });
      }
    });
  }




  //   blurMotivo(e: any) {
  //     e.target.classList.remove("focus-editor-grid");
  //     if (this.esEscapeMotivo) {
  //       this.esEscapeMotivo = false;
  //       return;
  //     }
  //     const motivo = parseFloat(e.target.value);
  //     let item = this.source[this.indexEditing];
  //     if (parseFloat(String(item.Conteo)) == conteo) {
  //       if (this.enviarOtroRenglon) {
  //         this.sendFocus();
  //       }
  //       return;
  //     };
  //     if (item.Conversiones) {
  //       if (item.Conversiones.length > 0) {
  //         let totalEnConversiones = 0;
  //         item.Conversiones.forEach((i) => totalEnConversiones += i.CantidadUnidadConcepto);
  //         if (conteo != totalEnConversiones) {
  //           item.Conteo = totalEnConversiones;
  //         } else {
  //           item.Conteo = conteo;
  //         }
  //         item.Diferencia = item.Conteo - (item.Existencia + item.PorSurtir);
  //       } else {
  //         item.Conteo = conteo;
  //         item.Diferencia = item.Conteo - (item.Existencia + item.PorSurtir);
  //         item.Conversiones = [];
  //         item.TextoConversiones = "";
  //         this.source[this.indexEditing] = { ...item };
  //         this.source = [...this.source];
  //         //this.enviarOtroRenglon = true;
  //         this.enviarFocoMotivo= true;
  //         this.sendFocus();
  //       }
  //     } else {
  //       item.Conteo = conteo;
  //       item.Diferencia = item.Conteo - (item.Existencia + item.PorSurtir);
  //       item.Conversiones = [];
  //       item.TextoConversiones = "";
  //       this.source[this.indexEditing] = { ...item };
  //       this.source = [...this.source];
  // //      this.enviarOtroRenglon = true;
  //       this.enviarFocoMotivo= true;
  //       this.sendFocus();
  //     }
  //     this.calcTotales();
  //   }




  sendFocus() {

    setTimeout(() => {
      if (this.enviarOtroRenglon) {
        this.indexEditing = this.indexEditing + 1;
        this.initEditor(this.indexEditing, this.colProducto);
        this.enviarOtroRenglon = false;
        this.enviarFocoConteo = false;
        this.enviarFocoProducto = false;
        this.enviarFocoMotivo = false;
      }

      if (this.enviarFocoConteo) {
        this.initEditor(this.indexEditing, this.colConteo);
        this.enviarFocoConteo = false;
      }

      if (this.enviarFocoProducto) {
        this.initEditor(this.indexEditing, this.colProducto);
        this.enviarFocoProducto = false;
      }

      if (this.enviarFocoMotivo) {
        this.initEditor(this.indexEditing, this.colMotivo);
        this.enviarFocoMotivo = false;
      }

    }, 50);
  }




  sourceReset(detalle: any = []) {
    if (!detalle) {
      this.source = [];
    } else {
      this.source = detalle;
    }

    this.source!.map((item: InventarioFisicoDetalle) => {
      if (item.UnidadMedida) {
        item.UnidadDescripcion = this.utilsService.AbreviarUniadesSAT(item.UnidadMedida!.Clave, item.UnidadMedida!.Nombre)
      } else {
        item.UnidadDescripcion = '';
      }
      if (item.Producto) {
        item.ProdDescripcion = item.Producto!.Nombre;
        item.NoIdentificacion = item.Producto!.Clave;
      } else {
        item.ProdDescripcion = '';
      }

      if (item.MotivoDiferencia) {
        item.MotivoDiferenciaClave = item.MotivoDiferencia.Clave.toString()
        item.MotivoDiferenciaNombre = item.MotivoDiferencia.Nombre;
      } else {
        item.MotivoDiferenciaNombre = '';
      }


      if (item.Conversiones && item.Conversiones.length > 0) {
        item.TextoConversiones = `${parseFloat(item.Conversiones[0].CantidadUnidadConversion.toFixed(2))} - ${item.Conversiones[0].NombreUnidad}\r\n`;;
      }
      item.Diferencia = item.Conteo - (item.Existencia + (item.PorSurtir ?? 0));

    });
    let totalRegs = 1;
    if (this.source.length < 20) {
      totalRegs = 20;
    }

    for (let index = 0; index < totalRegs; index++) {
      this.source = [...this.source, this.estructuraVacia()]
    }
  }

  estructuraVacia(): any {
    return {
      Id: 0,
      Producto: null,
      UnidadMedida: null,
      ProdDescripcion: '',
      UnidadDescripcion: '',
      NoIdentificacion: '',
      TextoConversiones: '',
      Costo: 0,
      Conteo: 0,
      Existencia: 0,
      Diferencia: 0,
      PorSurtir: 0,
      Baja: false,
      MotivoDiferencia: null,
      MotivoDiferenciaClave: '',
      MotivoDiferenciaNombre: '',
    };
  }


  deleteRow(indx: number) {

    this.eventsService.publish('home:showAlert', {
      message: `¿Desea eliminar el renglón`,
      textAccept: "Si, eliminar",
      textCancel: "Cancelar",
      cancelButton: true,
      onConfirm: (data: AlertResponse) => {
        if (data.isAccept) {
          const idEliminar = this.source[indx].Id;
          if (idEliminar > 0) {
            this.eventsService.publish('home:isLoading', { isLoading: true });
            this.invService.eliminarInvFisicoDetalle(idEliminar).subscribe((result) => {
              this.eventsService.publish('home:isLoading', { isLoading: false });
              this.source.splice(indx, 1);
              this.source = [...this.source];
              this.calcTotales();
              let ent: InventarioFisicoCabecera = this.myForm.value;
              ent.Detalle?.splice(indx, 1);
              this.myForm.reset(ent);
            })
          } else {
            this.source.splice(indx, 1);
            this.source = [...this.source];
            this.calcTotales();
            let ent: InventarioFisicoCabecera = this.myForm.value;
            ent.Detalle?.splice(indx, 1);
            this.myForm.reset(ent);
          }
        }
      }
    });
  }

  calcTotales() {
    let ent: InventarioFisicoCabecera = this.myForm.value;
    let regs: number = 0;
    let totalConceptos: number = 0
    this.source.map((concepto, index) => {
      if (concepto.Producto) {
        if (concepto.Producto.Id > 0) {
          regs++;
          totalConceptos += concepto.Costo * concepto.Conteo;
        }
      }
    });
    this.totalRegistros = regs;
    this.totalCosto = totalConceptos;


    this.myForm.reset(ent);
    this.source = [...this.source];
  }

  initEditor(row: number, col: number) {
    col = col - 1;
    const input = document.getElementById(`txt_${row}_${col}${this.idUnico}`)!;
    if (input) input.focus();
  }


  conversionClick(index: number) {

    if (!this.permiteEliminar) {
      return;
    }
    this.indexEditing = index;
    let item: InventarioFisicoDetalle = this.source[this.indexEditing];
    this.conversiones = item.Producto!.OtrasUnidades;
    this.unidadFinal = item.UnidadDescripcion;
    this.openConvertions((e: any) => this.aceptConversions(e, true));
  }


  aceptConversions(e: any, isclick: boolean = false) {
    this.conversiones = [];
    this.unidadFinal = '';
    let item: InventarioFisicoDetalle = this.source[this.indexEditing];
    if (e) {
      if (e.cantidad > 0) {
        if (item.Conversiones && item.Conversiones.length > 0) {
          item.Conversiones.map((p: InventarioFisicoDetalleConversiones) => {
            p.CantidadUnidadConcepto = e.items[0].CantidadUnidadConcepto;
            p.CantidadUnidadConversion = e.items[0].CantidadUnidadConversion;
            p.NombreUnidad = e.items[0].NombreUnidad;
          });
        } else {
          item.Conversiones = e.items;
        }
        item.TextoConversiones = e.conversiones;
        item.Conteo = e.cantidad;
        item.Diferencia = item.Conteo - (item.Existencia + item.PorSurtir);
        this.source = [...this.source];
        this.enviarFocoConteo = false;
        if (!isclick) {
          if (item.Diferencia != 0) {
            this.enviarFocoMotivo = true;
          } else {
            this.enviarOtroRenglon = true;
          }

          this.sendFocus();
        }

      } else {
        item.Conversiones = [];
        item.TextoConversiones = '';
        if (!isclick) {
          this.enviarFocoConteo = true;
          this.sendFocus();
        }

      }
      this.calcTotales();
      if (e.cantidad == 0) {
        if (!isclick) {
          this.enviarFocoConteo = false;
          if (item.Diferencia != 0) {
            this.enviarFocoMotivo = true;
          } else {
            this.enviarOtroRenglon = true;
          }
          this.sendFocus();
        }
      } else {
        if (!isclick) {
          if (this.enviarOtroRenglon) {
            this.sendFocus();
          }
        }
      }
    } else {
      this.source = [...this.source];
      if (!isclick) {
        this.enviarFocoConteo = true;
        this.sendFocus();
        //this.initEditor(this.indexEditing, 5);
      }
    }
  }


  //* 4.- matodos de consulta a el backend

  // navigate(type: string) {
  //   this.findEntityByParamsNavigate(type);
  // }




  clicMenu(value: string) {
    if (value == 'cargar') {
      if (this.myForm.value.Almacen && this.myForm.value.Almacen.Id > 0) {
        this.txtFile.nativeElement.click();

      } else {
        Swal.fire({
          text: 'Debe de indicar el Almacén para obtener el costo de los productos, por favor verifique.',
          icon: 'info',
        });
      }
    } else if (value == 'recalcular') {
      //this.cargarTodosSucursales();
    } else if (value == 'plantilla') {
      this.downloadFile("InventarioFisico_Conteo_layout")

    }
  }

  get getFileName(): string {
    return this.file ? this.file.name : '';
  }


  cargarInvFisico(value: any) {
    this.importing = true;
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.file = value.target.files.length > 0 ? value.target.files[0] : null
    this.fus.upload(this.file, 0, 'Utilerias/UploadFile', () => {
      this.sendImportData();
    });
    value.target.value = "";
  }


  onChangeFile(file: any) {
    this.file = file;
  }


  sendImportData() {
    const deleteItems = this.myForm.value.eliminar;
    const almacenId = this.myForm.value.Almacen.Id;
    this.invService.sendInvFisicoMigration(this.file.name, almacenId, deleteItems).subscribe((listado: InventarioFisicoDetalle[]) => {
      this.importing = false;
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (listado) {
        let total: number = listado.length;
        if (total == 0) {
          Swal.fire({
            text: 'No se encontró información a importar.',
            icon: 'info',
          })
          return;
        }
        Swal.fire({
          title: `En total se encontraron ${total} productos(s), ¿Desea cargar los productos importados y reemplazar la captura?.`,
          showDenyButton: true,
          confirmButtonText: 'Si, cargar los productos',
          denyButtonText: `Cancelar`,
        }).then((result) => {
          if (result.isConfirmed) {
            let invFisico: InventarioFisicoCabecera = this.myForm.value;
            if (!invFisico.Detalle) {
              invFisico.Detalle = [];
            }
            if (listado && listado.length > 0) {
              listado.forEach(item => {
                invFisico.Detalle!.push({
                  Id: 0, Costo: item.Costo,
                  Producto: item.Producto!,
                  Conteo: item.Conteo,
                  UnidadMedida: item.UnidadMedida,
                  NoIdentificacion: item.Producto?.Clave!,
                  ProdDescripcion: item.Producto?.Nombre!,
                  Conversiones: [],
                  Existencia: item.Existencia,
                  Diferencia: 0,
                  Eliminado: false,
                  Baja: false,
                  UnidadDescripcion: '',
                  TextoConversiones: '',
                  PorSurtir: 0,
                  MotivoDiferencia: item.MotivoDiferencia,
                  MotivoDiferenciaClave: item.MotivoDiferencia?.Clave!,
                  MotivoDiferenciaNombre: item.MotivoDiferencia?.Nombre!
                })
              })
              this.setEntity(invFisico)
            }
          }
        });
      } else {
        // todo
        //que pas si no hay datos
      }
    })
  }

  downloadFile(name: string) {
    this.downloading = true;
    return this.http.get(`${this.baseUrl}/Utilerias/DescargarArchivosImportacion?tipo=${name}`, { responseType: 'arraybuffer' }).subscribe((d: any) => {
      this.downloading = false;
      if (d) {
        let blob = new Blob([d], { type: "text/csv" });
        let url = window.URL.createObjectURL(blob);
        let pwa = window.open(url);
        if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
          Swal.fire({
            text: 'Tienes bloqueadas las descargas de esta página, habilitalas.',
            icon: 'info',
          })
        }
      }
    })
  }

  get esNuevo() {
    return this.myForm.value.Id == 0;
  }

  get getBlockCombos() {
    return !this.blockCombos;
  }

  selectTipoCaptura(entity: ComboBoxEntity) {
    this.myForm.get('Tipo')!.setValue(entity);
    this.tipoCapturaActual = entity;
    const ent: InventarioFisicoCabecera = this.myForm.value;
    this.getNextFolio(ent).subscribe(folio => {
      ent.Codigo = folio;
      this.blockCombos = true;
      this.setEntity(ent);
      setTimeout(() => {
        const txtFolio: any = this.txtFolio;
        txtFolio.tagInput.nativeElement.focus();
      }, 100);
    });
  }


  findEntityByParamsNavigate(type: string) {
    const params = new HttpParams()
      .set("idEmpresa", this.myForm.value.Empresa.Id)
      .set("idSucursal", this.myForm.value.Sucursal.Id)
      .set("folio", this.myForm.value.Codigo)
      .set("tipo", type);
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.http.get<InventarioFisicoCabecera>(`${this.baseUrl}/Inventarios/ObtenerInvFisicoNavegacion`, { params }).subscribe((invFisico) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      this.setEntity(invFisico);
    });
  }

  setEntity(inventario: InventarioFisicoCabecera) {
    if (!inventario) {
      this.new();
    } else {
      let ent: InventarioFisicoCabecera = this.myForm.value;
      ent = { ...inventario };
      this.sourceReset(ent.Detalle);
      this.calcTotales();
      this.myForm.reset(ent);
    }
  }

  new() {
    const elem: InventarioFisicoCabecera = this.myForm.value;
    this.cService.getEmptyEntity("InventarioFisicoCabecera").subscribe((ent: InventarioFisicoCabecera) => {
      ent.Detalle = [];
      this.sourceReset();
      ent.Empresa = { Id: this.info.empresa?.numero, Clave: this.info.empresa?.clave, Nombre: this.info.empresa?.nombre } as Empresa;
      ent.Sucursal = { Id: this.info.sucursal?.numero, Clave: this.info.sucursal?.clave, Nombre: this.info.sucursal?.nombre } as Sucursal;
      ent.Fecha = new Date();
      ent.Almacen = { Id: this.almacenActual.Id, Clave: this.almacenActual.Clave, Nombre: this.almacenActual.Nombre } as Almacen;
      ent.Tipo = { Id: this.tipoCapturaActual.Id, Clave: this.tipoCapturaActual.Clave, Nombre: this.tipoCapturaActual.Nombre } as TipoCapturaInventarioFisico
      this.getNextFolio(ent).subscribe((folio: number) => {
        ent.Codigo = folio;
        this.setEntity(ent);
      });
    });
  }




  getNextFolio(ent: InventarioFisicoCabecera): Observable<number> {
    const params = new HttpParams().set("idEmpresa", this.info.empresa?.numero!)
      .set("idSucursal", this.info.sucursal?.numero!)
    return this.http.get<number>(`${this.baseUrl}/Inventarios/ObtenerSiguienteFolioInvFisico`, { params });
  }

  searchProduct(value: string) {
    if (!value.trim()) {
      return;
    };

    this.loading = true;
    value = this.fServices.aplciarMascara(value, this.mascara);
    const params = new HttpParams().set("idEmpresa", this.info.empresa?.numero!).set("idSucursal", this.info.sucursal?.numero!).set("idCliente", 0).set("clave", value);
    return this.http.get<Producto>(`${this.baseUrl}/Ventas/ObtenerProducto`, { params }).subscribe((producto) => {
      this.loading = false;
      if (producto) {
        if (!producto.Inventariable) {
          Swal.fire({
            text: `El producto seleccionado no es Inventariable, por favor verifique.`, icon: 'info',
          }).then(() => {
            this.source[this.indexEditing].NoIdentificacion = "";
            this.source = [...this.source];
            this.enviarFocoProducto = true;
            this.sendFocus();
          })
          return;
        }

        this.invService.getUltimoCostoPorProducto(this.info.empresa?.numero!, this.info.sucursal?.numero!, producto.Almacen.Id, producto.Id).subscribe(prod => {
          let item = this.source[this.indexEditing];
          item.Producto = producto;
          item.NoIdentificacion = producto.Clave;
          item.ProdDescripcion = producto.Nombre;
          item.Conteo = 0;
          item.UnidadMedida = producto.Unidad;
          if (prod != 0) {
            item.Costo = prod;
          } else {
            item.Costo = 0;
          }
          item.UnidadDescripcion = producto.Unidad.Nombre;
          this.source = [...this.source];
          if (producto.Inventariable && producto.Almacen) {
            this.fServices.getExistencia(producto.Id, this.myForm.value.Sucursal.Id, producto.Almacen.Id).subscribe((existencia) => {
              this.existencia = existencia;
              //setTimeout(() => {

              this.source[this.indexEditing].Existencia = existencia;
              this.source = [...this.source];
              //}, 150);

              if (this.almacenCargaEntrega) {
                this.fServices.getExistencia(producto.Id, this.myForm.value.Sucursal.Id, this.almacenCargaEntrega.Id).subscribe((existencia) => {
                  this.source[this.indexEditing].PorSurtir = existencia;
                  this.source[this.indexEditing].Diferencia = this.source[this.indexEditing].PorSurtir + this.source[this.indexEditing].Existencia;
                  this.source = [...this.source];
                });
              } else {
                this.source[this.indexEditing].PorSurtir = 0;
                this.source[this.indexEditing].Diferencia = this.source[this.indexEditing].PorSurtir + this.source[this.indexEditing].Existencia;
                this.source = [...this.source];
              }
            });
          }
          producto.OtrasUnidades = producto.OtrasUnidades.filter(p => p.EsParaKilos == false);
          if (producto.OtrasUnidades && producto.OtrasUnidades?.length > 0) {
            this.conversiones = producto.OtrasUnidades;
            this.unidadFinal = item.UnidadDescripcion
            this.openConvertions((e: any) => this.aceptConversions(e));
          } else {
            this.conversiones = [];
            this.unidadFinal = '';
            item.TextoConversiones = '';
            item.Conversiones = [];
            this.enviarFocoConteo = true;
            this.sendFocus();
          }
          this.sourceProducts = [];
        });
      } else {
        Swal.fire({
          text: "No se encontró el Producto indicado.",
          icon: 'error',
        }).then(() => {
          this.source[this.indexEditing].NoIdentificacion = "";
          this.source = [...this.source];
          this.initEditor(this.indexEditing, 1);

        })
      }
    })
  }

  openConvertions(cb: any) {
    const b: any = this.ctrlConversiones;
    this.ms.openModal(b, (e: any) => {
      cb && cb(e);
    })
  }
  focoObservaciones(value: any) {
    let aa = 0;

  }

  save() {
    this.existencia = 0;
    if (this.saving) return;


    if (!this.permiteGuardar) {
      if (this.myForm.value.FechaAplicacion) {
        this.eventsService.publish('home:showAlert', { message: `No es posible realizar modificaciones, la captura ya fue aplicada.`, cancelButton: false });
        return;
      }
      this.eventsService.publish('home:showAlert', { message: `No es posible realizar modificaciones a la captura del Inventario Físico.`, cancelButton: false });
      return;
    }




    let ent: InventarioFisicoCabecera = this.myForm.value;

    if (this.myForm.value.Id == 0) {
      ent.UsuarioCaptura = { Id: this.usuarioActual.numero, Clave: this.usuarioActual.numero, Nombre: this.usuarioActual.nombreUsuario } as Usuario;
      ent.Fecha = new Date();
    }

    ent.Detalle = this.source.filter(P => P.NoIdentificacion != '');

    if (ent.Detalle.length == 0) {
      this.eventsService.publish('home:showAlert', { message: `Debe de indicar por lo menos un Producto para capturar el Inventario Físico.`, cancelButton: false });
      return;
    }





    // revisamos que solo tenga una conversion por detalle de la captura
    ent.Detalle.forEach((el: InventarioFisicoDetalle) => {
      if (el.Conversiones.length > 1) {
        el.Conversiones.pop();
      }
    });


    if (ent.Detalle.filter(p => p.Diferencia != 0 && p.MotivoDiferencia == null).length > 0) {
      this.eventsService.publish('home:showAlert', { message: `Debe de indicar el motivo de la diferencia para todos los Productos que asi corresponda.`, cancelButton: false });
      return;
    }

    console.log(ent);

    this.saving = true;
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.http.post<Result>(`${this.baseUrl}/Inventarios/GuardarInventarioFisico`, ent).subscribe((result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result.success) {
        const t: InventarioFisicoCabecera = JSON.parse(result.message);
        this.myForm.reset(t);
        this.sourceReset(t.Detalle);
        Swal.fire({ position: 'center', icon: 'success', title: 'Se guardó correctamente', showConfirmButton: false, timer: 1000 }).then(() => {
        });
      } else {
        Swal.fire({ text: `${result.message}`, icon: 'info', })
      }
      this.saving = false;
    })
  }

  activeButtons: ActiveButtons = {
    new: true,
    delete: false,
    return: true,
    save: true,
    first: true,
    left: true,
    right: true,
    last: true,
    search: false,
    print: true,
  }

  onClickBarButton(button: string): void {
    switch (button) {
      case "new":
        this.new();
        this.blockCombos = false;
        setTimeout(() => {
          const txtFolio: any = this.txtFolio;
          txtFolio.tagInput.nativeElement.focus();
        }, 100);
        break;
      case "save": this.save(); break;
      case "print": this.print(); break;
      case "first": this.findEntityByParamsNavigate(button); break;
      case "left": this.findEntityByParamsNavigate(button); break;
      case "right": this.findEntityByParamsNavigate(button); break;
      case "last": this.findEntityByParamsNavigate(button); break;
      case "return": this.findEntityByParams();
        this.blockCombos = false;
        setTimeout(() => {
          const txtFolio: any = this.txtFolio;
          txtFolio.tagInput.nativeElement.focus();
        }, 100);
        break;
    }
  }

  getOffset(elem: HTMLInputElement): Coordinates {
    var box = elem.getBoundingClientRect();
    var left = window.scrollX !== undefined ? window.scrollX :
      (document.documentElement || document.body.parentNode || document.body).scrollLeft;
    var top = window.scrollY !== undefined ? window.scrollY :
      (document.documentElement || document.body.parentNode || document.body).scrollTop;

    top += elem.offsetHeight;
    return { left: box.left + left, top: box.top + top };
  }

  navegarProductos(e: any) {
    if (e.keyCode == 9) {
      e.preventDefault();
      return;
    }
    let row = null;
    if (e.keyCode == 40) {
      if (this.selectedIndex + 1 < this.sourceProducts.length) {
        this.selectedIndex++;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`)
        row!.scrollIntoView({ block: "center" });
      }
    } else if (e.keyCode == 38) {
      if (this.selectedIndex > 0) {
        this.selectedIndex--;
        row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`)
        row!.scrollIntoView({ block: "center" });
      }
    }
  }


  buscarCatalogo(e: any, catalogo: string, busquedaCompleta: boolean = false) {
    if ((e.target.value == "" || !isNaN(e.target.value.trim().replace(/\s/g, ""))) && catalogo == "Producto") {
      // this.cords = null;
      // this.sourceProducts = [];
      return;
    }

    if (this.selectedIndex == -1) {
      this.selectedIndex = 0;
    }
    let row = null;
    if (e.keyCode == "38" || e.keyCode == "40") {
      e.preventDefault();
    }

    if (e.keyCode == "27" || e.keyCode == "37" || e.keyCode == "39" || e.keyCode == "38" || e.keyCode == "40") {
      return;
    }
    let cords = this.getOffset(e.target);
    cords.top = cords.top - 400;
    cords.left = cords.left - 290;
    this.cords = cords;

    this.sourceMotivo = [];
    this.sourceProducts = [];

    let valorABuscar = e.target.value;


    if (busquedaCompleta) {
      valorABuscar = "";
    }


    //todo si no ha seleccionado el tipode movimineto o el folio
    //if (!this.myForm.value.Cliente) { return }
    if (catalogo == "Producto") {
      this.fServices.busquedaProductos(e.target.value, 0).subscribe((result) => {
        const lista = JSON.parse(result.message);
        this.sourceProducts = lista;
        if (this.sourceProducts.length > 0) {
          this.selectedIndex = 0;
          row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`);
          if (row) {
            row.scrollIntoView({ block: "center" });
          }
        } else {
          this.selectedIndex = -1;
        }
      });
    }
    if (catalogo == "Motivo") {
      this.invService.busquedaMotivos(valorABuscar).subscribe((result) => {
        const lista = JSON.parse(result.message);
        this.sourceMotivo = lista;
        if (this.sourceMotivo.length > 0) {
          this.selectedIndex = 0;
          row = document.getElementById(`row-search${this.idUnico}_${this.selectedIndex}`);
          if (row) {
            row.scrollIntoView({ block: "center" });
          }
        } else {
          this.selectedIndex = -1;
        }
      });
    }
  }


  get permiteGuardar() {
    return (this.myForm.value.Id > 0 && !this.myForm.value.FechaAplicacion && !this.myForm.value.FechaCancelacion) || this.myForm.value.Id == 0;
  }

  get permiteAplicar() {
    return (this.myForm.value.Id > 0 && !this.myForm.value.FechaAplicacion && !this.myForm.value.FechaCancelacion && !this.myForm.value.EsGlobal)
  }

  get permiteAplicacionGlobal() {
    return (this.myForm.value.Id == 0 && !this.myForm.value.FechaAplicacion && !this.myForm.value.FechaCancelacion)
  }




  print() {
    if (this.myForm.value.Id == 0) {
      Swal.fire({
        text: "Es necesario que primero guarde su captura antes de poder imprimirlo, por favor verifique.",
        icon: 'warning',
      });
      return;
    }

    let reportHeader: ReportHeader = {
      Fecha1: this.myForm.get('FechaEmision')?.value,
      Fecha2: this.myForm.get('FechaEmision')?.value,
      NombreReporte: 'VERIFICADOR DE INVENTARIO FÍSICO',
      Dato1: '',
      Opc1: true,
    }
    let filtro: FilterOptions | undefined = this.filtroInvFisico.FilterOptions?.find(p => p.Campo == 'Inv.Id');
    if (filtro) {
      filtro!.Valor = String(this.myForm.get('Id')?.value);
    }
    this.filtroInvFisico.ReportHeader = reportHeader;
    this.reportsService.printReport(this.filtroInvFisico, '/Inventarios/VerificadorDeInventarioFisico');
  }

  blurFolio(values: any) {


    if (!this.myForm.value.Tipo) {
      this.eventsService.publish('home:showAlert', {
        textAccept: "Aceptar",
        cancelButton: false,
        message: 'Favor de indicar el Tipo.',
        onConfirm: (data: AlertResponse) => {
          this.indexEditing = -1;
          const txt: any = this.TipoCapturaInv;
          txt.tagInput.nativeElement.focus()
          return;
        }
      });
      return;
    }




    if (values.after != values.before) {
      this.findEntityByParams();
    }
  }

  findEntityByParams() {
    const ent: InventarioFisicoCabecera = this.myForm.value;
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.invService.obtenerInvFisico(ent.Empresa!.Id, ent.Sucursal!.Id, ent.Codigo).subscribe((invFisico) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      this.setEntity(invFisico);
    });
  }

  clicTablaProducto(e: any, item: any) {
    const t: any = document.activeElement;
    t.value = item.Clave;
    const input: any = document.getElementById(`txt_${this.indexEditing}_0${this.idUnico}`)!;
    if (input) {
      input.value = item.Clave;
      this.enviarFocoConteo = true;
    }
  }

  clicTablaMotivos(e: any, item: any) {
    const t: any = document.activeElement;
    t.value = item.Clave;
    const input: any = document.getElementById(`txt_${this.indexEditing}_9${this.idUnico}`)!;
    if (input) {
      input.value = item.Clave;
      this.enviarFocoConteo = true;
    }
  }



}
