<div class="card text-center border-0" cdkDrag style="width: 800px;box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;">
  <div style="cursor: move;" cdkDragHandle class="card-header fw-bold">Documentos relacionados</div>
  <div class="card-body bg-light">
    <app-container-base subTitle="" [containerInnerHeight]="0" [esPopup]="true" [hasHeader]="false" [hasBar]="false"
      [hasCounter]="false">
      <div class="screen-content">

        <app-panel-base [title]="'Listado de documentos donde se relaciona'">
          <div class="panel-content">
            <ng-scrollbar style="height: 300px;border-bottom: 1px solid #AAAAAA;">
              <table class="blueTable" style="width: 650px;position: fixed;">
                <thead style="position: sticky;top:0;">
                  <tr>

                    <th style="width: 100px; text-align: center;">Sucursal</th>
                    <th style="width: 400px;">Descripción</th>
                    <th style="width: 150px; text-align: center;">Fecha</th>
                  </tr>
                </thead>
                <tbody class="bg-light">
                  <tr *ngFor="let item of items; let i = index">
                    <td style="width: 100px; text-align: center;">{{item.sucursal.nombre}}</td>
                    <td style="width: 400px;">{{item.descripcion}}</td>
                    <td style="width: 150px; text-align: center;">{{item.fecha}}</td>
                  </tr>
                </tbody>
              </table>
            </ng-scrollbar>

          </div>
        </app-panel-base>

        <div class="card-footer fw-bold">
          <div style="text-align: right; margin-top: 5px;">
            <div (click)="closeModal()" class="btn btn-danger" #txtCancelar>Cerrar</div>
          </div>
        </div>
      </div>

    </app-container-base>

  </div>
</div>
