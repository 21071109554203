import { Component, ElementRef, inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserLogged } from 'src/app/auth/interfaces';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { SearchConfiguration } from 'src/app/service/interfaces/data-search.interface';
import { ModalService } from 'src/app/service/modal.service';
import { UtilsService } from 'src/app/service/utils.service';
import { FacturacionService } from '../../services/facturacion.service';
import { EventsService } from 'src/app/service/events.service';
import { ExistenciaAlmacenDTO } from 'src/app/Inventarios/interfaces/producto.interface';
import { ListaPrecioConsultaDTO } from '../../interfaces/listaprecio.interface';
import { ProveedorDTO } from 'src/app/compras/interfaces/proveedores.interface';

@Component({
  selector: 'app-info-general-producto-page',
  templateUrl: './info-general-producto-page.component.html',
  styleUrls: ['./info-general-producto-page.component.scss']
})
export class InfoGeneralProductoPageComponent implements OnInit {
  listaExistencias: ExistenciaAlmacenDTO[] = [];
  listaPrecios: ListaPrecioConsultaDTO[] = [];
  listaProveedores: ProveedorDTO[] = [];
  ultimaFechaCompra: string = "";
  existenciaGlobal: number = 0;
  private eventsService = inject(EventsService);
  @ViewChild('busquedaProductos')
  public ctrlBusquedaProductos!: ElementRef<HTMLElement>;
  searchConfigurationProducto: SearchConfiguration | null = null;
  info: UserLogged = {} as UserLogged;

  public myForm: FormGroup = this.fb.group({
    Producto: [null],
    Clave: [''],
    Nombre: [''],
    CodigoBarras: [''],
    UnidadMedida: [''],
    UltimoCosto: [0],
    Reposicion: [0],
    UltimaCompra: [0],
  });

  constructor(private fb: FormBuilder, private ms: ModalService, private utileService: UtilsService, private fs: FacturacionService) {

  }

  get getProductsColumns(): any {
    if (this.searchConfigurationProducto) {
      return this.searchConfigurationProducto.columns;
    }
    return [];
  }

  get getProductsPropertys(): string {
    if (this.searchConfigurationProducto) {
      let props = "";
      this.searchConfigurationProducto.propertys.forEach((prop) => {
        props += `${prop.name}|${prop.type},`
      })

      if (props.length > 0) {
        props = props.substring(0, props.length - 1);
        return props;
      }

    }
    return "";
  }

  openProductsSearch() {
    const b: any = this.ctrlBusquedaProductos;
    this.ms.openModal(b, (e: any) => {
      if (e) {
        this.eventsService.publish('home:isLoading', { isLoading: true });
        this.fs.buscarProducto(this.info.empresa!.numero, this.info.sucursal!.numero, 0, true, e.Clave).subscribe((producto) => {
          if (producto) {
            this.myForm.controls["Producto"].setValue(producto);
            this.myForm.controls["Clave"].setValue(producto.Clave);
            this.myForm.controls["Nombre"].setValue(producto.Nombre);
            this.myForm.controls["CodigoBarras"].setValue(producto.CodigoBarras);
            this.myForm.controls["UnidadMedida"].setValue(producto.Unidad.Nombre);
            this.myForm.controls["UltimoCosto"].setValue(producto.UltimoCosto);
            this.fs.obtenerExistenciasSucursales(producto.Id).subscribe((lista) => {
              this.listaExistencias = lista;
              this.listaExistencias.forEach((i) => { this.existenciaGlobal += Number(i.existencia); })
              this.fs.obtenerPrecios(producto.Id).subscribe((precios) => {
                this.listaPrecios = precios;
                this.fs.obtenerProveedores(producto.Id).subscribe((proveedores) => {
                  this.listaProveedores = proveedores;
                  this.fs.obtenerUltimaFechaCompra(producto.Id).subscribe((uf) => {
                    this.eventsService.publish('home:isLoading', { isLoading: false });
                    this.ultimaFechaCompra = uf.message;
                    this.rellenarGrids();
                  });
                });
              });
            })
          }
        });
      }
    }, 'xl')
  }

  rellenarGrids() {
    let totalExistencias = 0;
    let totalPrecios = 0;


    if (this.listaExistencias.length < 10) {
      while (totalExistencias <= 10) {
        this.listaExistencias.push({
          claveSucursal: -1,
          nombreSucursal: "",
          claveAlmacen: -1,
          nombreAlmacen: "",
          existencia: -1,
          costo: -1,
          ultimoCostoCompra: -1,
        })
        totalExistencias++;
      }
    }

    if (this.listaPrecios.length < 10) {
      while (totalPrecios <= 10) {
        this.listaPrecios.push({
          listaclave: -1,
          listaNombre: "",
          divisor: -1,
          costoReposicion: -1,
          precioMinimo: -1,
          precioMaximo: -1,
        })
        totalPrecios++;
      }
    }
  }

  ngOnInit(): void {
    this.rellenarGrids();
    this.info = this.utileService.getUserLogged();
    this.searchConfigurationProducto = this.ms.GetSearchConfiguration("Producto", `Empresa.Id = ${this.info.empresa!.numero}`);
    setTimeout(() => {
      this.openProductsSearch();
    }, 150);
  }

}
