<div class="card text-center border-0" cdkDrag style="width: 540px;box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;">
  <div style="cursor: move;" cdkDragHandle class="card-header fw-bold">Datos para generar la Nota de Crédito</div>
  <div class="bg-light">
    <app-container-base subTitle="" [containerInnerHeight]="0" [esPopup]="true" [hasHeader]="false" [hasBar]="false"
      [hasCounter]="false">
      <div class="screen-content">
        <div class="widget widget-stats bg-green-600 mb-0 p-8px">
          <div class="stats-icon stats-icon-lg">
            <i class="fa fa-cash-register fs-70px" style="top:-19px"></i>
          </div>
          <div class="stats-content">
            <div class="stats-number m-0 fs-20px">TOTAL Nota Crédito: {{this.totalNC| number:'1.2'}}</div>
            <div class="stats-number m-0 fs-20px">Aplicado CXC: {{getCapturado | number:'1.2'}}</div>
          </div>
        </div>
        <form [formGroup]="myForm" autocomplete="off">

          <app-panel-base [title]="'Datos de la Factura que origina la Nota de Crédito'">
            <div class="panel-content">


              <component-ui-combo-box [required]="true" [label]="'Tipo'" [widthList]="300"
                formControlName="TipoNotaCredito" [tabIndex]="1" (keydown.escape)="close()"
                [entityName]="'TIpoNotaCredito'" [widthTextColumns]="3" [widthLabelColumns]="4" [widthRightColumns]="5"
                (onSelectedItem)="selectedCombo($event,'TipoNotaCredito')" [listProperty]="'ClaveNombre'" #cboTipo />

              <component-ui-combo-box [required]="true" [label]="'Tipo Relación'" [widthList]="300"
                [filterQuery]="'Id = 1 or Id = 3'" formControlName="TipoRelacionNotaCredito" [tabIndex]="2"
                [entityName]="'c_TipoRelacion'" [widthTextColumns]="3" [widthLabelColumns]="4" [widthRightColumns]="5"
                (onSelectedItem)="selectedCombo($event,'TipoRelacionNotaCredito')" [listProperty]="'ClaveNombre'" />

              <div class="card-header h6 mb-1 bg-none p-2 text-orange">
                <i class="fa fa-cube fa-lg fa-fw me-1"></i>
                Datos del Documento que origina la Nota de Crédito
              </div>

              <component-ui-check-box [label]="'¿Es del sistema anterior?'" formControlName="EsFacturaMigrada"
                [tabIndex]="3" [widthTextColumns]="3" [widthLabelColumns]="4" />

              <component-ui-combo-box [enabled]="!myForm.value.EsFacturaMigrada"
                [required]="!myForm.value.EsFacturaMigrada" [label]="'Sucursal'" [widthList]="300"
                formControlName="SucursalOriginaNotaCredito" [tabIndex]="4" [entityName]="'Sucursal'"
                [widthTextColumns]="3" [widthLabelColumns]="4" [widthRightColumns]="5"
                (onSelectedItem)="selectedCombo($event,'SucursalOriginaNotaCredito')" [listProperty]="'ClaveNombre'" />


              <component-ui-combo-box [enabled]="!myForm.value.EsFacturaMigrada"
                [required]="!myForm.value.EsFacturaMigrada" [label]="'Documento Origina'" [widthList]="300"
                formControlName="DocumentoVentaOrigina" [tabIndex]="5" [entityName]="'DocumentoVenta'"
                [widthTextColumns]="3" [widthLabelColumns]="4" [widthRightColumns]="5"
                (onSelectedItem)="selectedCombo($event,'DocumentoVentaOrigina')" [listProperty]="'ClaveNombre'" />

              <components-ui-text-box [maxLength]="10" [label]="'Serie Origina'"
                [enabled]="!myForm.value.EsFacturaMigrada" [required]="!myForm.value.EsFacturaMigrada"
                formControlName="Serie" [tabIndex]="6" [widthTextColumns]="3" [widthLabelColumns]="4" #txtGrosor />

              <components-ui-text-box [maxLength]="15" [label]="'Folio Origina'"
                [enabled]="!myForm.value.EsFacturaMigrada" [required]="!myForm.value.EsFacturaMigrada"
                formControlName="Folio" [tabIndex]="7" [widthTextColumns]="3" [widthLabelColumns]="4" #txtFolio />

              <components-ui-text-box [tabIndex]="8" [enabled]="myForm.value.EsFacturaMigrada"
                formControlName="UUIDOriginaNotaCredito" [widthLabelColumns]="4" label="UUID" [widthTextColumns]="8"
                (keydown.enter)="accept()" />

            </div>
          </app-panel-base>
          <app-panel-base [title]="'Facturas a afectar en Cuentas Por Cobrar'">
            <div class="panel-content">
              <ng-scrollbar style="height: 250px;border-bottom: 1px solid #AAAAAA;">
                <div (click)="agregarEditar(-1)" class="pt-2 pb-2 text-start" style="width:100%;">
                  <a class="btn btn-success btn-rounded px-4 rounded-pill"><i
                      class="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i>Agregar</a>
                </div>
                <table class="blueTable" style="width: 460px;position: fixed;">
                  <thead style="position: sticky;top:0;">
                    <tr>
                      <th style="width: 30px;"></th>
                      <th style="width: 100px; text-align: center;">Sucursal</th>
                      <th style="width: 100px; text-align: center;">Serie</th>
                      <th style="width: 100px; text-align: center;">Folio</th>
                      <th style="width: 100px; text-align: right;">Importe</th>
                    </tr>
                  </thead>
                  <tbody class="bg-light">
                    <tr *ngFor="let item of afecta; let i = index">
                      <td style="width: 30px;text-align:center;"><i (click)="deleteRow(i)"
                          class="fas fa-circle-xmark text-red" style="cursor: pointer;"></i></td>
                      <td style="width: 100px; text-align: center;">{{ item.Sucursal ? ( item?.Sucursal?.Nombre) : ''}}
                      </td>
                      <td style="width: 100px; text-align: center;">{{item.Serie}}</td>
                      <td style="width: 100px; text-align: center;">{{item.Folio}}</td>
                      <td style="width: 100px;text-align: right;">{{item.Importe | number:'1.2-6'}}</td>
                    </tr>
                  </tbody>
                </table>
              </ng-scrollbar>

            </div>
          </app-panel-base>
        </form>
        <div style="text-align: right; margin-top: 5px;">
          <div style="margin-right: 5px;" (click)="accept()" class="btn btn-success" #txtAceptar>Aceptar</div>
          <div (click)="close()" class="btn btn-danger" #txtCancelar>Cancelar</div>
        </div>

      </div>

    </app-container-base>

    <ng-template #modalAfecta>
      <app-datos-venta-afecta-page [afecta]="null" />
    </ng-template>

  </div>
</div>
