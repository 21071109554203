<div class="card text-center border-0" cdkDrag style="width: 540px;box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;">
  <div style="cursor: move;" cdkDragHandle class="card-header fw-bold">Cancelación de Vale</div>
  <app-panel-base title="Cancelar Vale">
    <div class="panel-content">
      <form [formGroup]="myForm" autocomplete="off">
        <components-ui-text-box [tabIndex]="1" formControlName="MotivoCancelacion" [maxlength]="2000" label="Motivo"
          [widthLabelColumns]="3" [widthTextColumns]="9" />
        <div style="text-align: right; margin-top: 5px;">
          <button (click)="accept()" class="btn btn-success" style="margin-right: 5px;">Aceptar</button>
          <button (click)="closeModal()" class="btn btn-danger">Cancelar</button>
        </div>
      </form>
    </div>
  </app-panel-base>
</div>
