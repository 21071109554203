import { Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { FileUploadService } from 'src/app/component-ui/services/file-upload-box.service';
import { SistemaService } from '../../services/sistema.service';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { EventsService } from 'src/app/service/events.service';

@Component({
  selector: 'app-importacion-page',
  templateUrl: './importacion-page.component.html',
  styleUrls: ['./importacion-page.component.scss']
})
export class ImportacionPageComponent implements OnInit {

  private eventsService = inject(EventsService);
  @ViewChild('txtFile')
  public txtFile!: ElementRef<HTMLInputElement>;
  catalogoseleccionado: number = 0;
  actualStep = 1;
  downloading: boolean = false;
  file: any = null;
  importing: boolean = false;
  private readonly baseUrl: string = environment.baseUrlApi;

  public myForm: FormGroup = this.fb.group({
    eliminar: [false]
  });

  constructor(private fus: FileUploadService, private sistemaService: SistemaService, private fb: FormBuilder, private http: HttpClient) {

  }
  ngOnInit(): void {

  }

  importCatalog() {
    this.importing = true;
    this.fus.upload(this.file, 0, 'Utilerias/UploadFile', () => {
      this.sendImportData();
    });
  }

  sendImportData() {
    if (this.catalogoseleccionado == 1) {
      const deleteItems = this.myForm.value.eliminar;
      this.sistemaService.sendCustomerMigration(this.file.name, deleteItems).subscribe((result) => {
        this.importing = false;
        if (result.success) {
          Swal.fire({
            text: 'Se migraron ' + result.message + ' registros, favor de verificar.',
            icon: 'success',
          })
        } else {
          this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
        }
      })
    }
    if (this.catalogoseleccionado == 2) {
      const deleteItems = this.myForm.value.eliminar;
      this.sistemaService.sendProductMigration(this.file.name, deleteItems).subscribe((result) => {
        this.importing = false;
        if (result.success) {
          Swal.fire({
            text: 'Se migraron ' + result.message + ' registros, favor de verificar.',
            icon: 'success',
          })
        } else {
          this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
        }
      })
    }

    if (this.catalogoseleccionado == 3) {
      const deleteItems = this.myForm.value.eliminar;
      this.sistemaService.sendCxcMigration(this.file.name, deleteItems).subscribe((result) => {
        this.importing = false;
        if (result.success) {
          Swal.fire({
            text: 'Se migraron ' + result.message + ' registros, favor de verificar.',
            icon: 'success',
          })
        } else {
          this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
        }
      })
    }

    if (this.catalogoseleccionado == 4) {
      const deleteItems = this.myForm.value.eliminar;
      this.sistemaService.sendProveedoresMigration(this.file.name, deleteItems).subscribe((result) => {
        this.importing = false;
        if (result.success) {
          Swal.fire({
            text: 'Se migraron ' + result.message + ' registros, favor de verificar.',
            icon: 'success',
          })
        } else {
          this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
        }
      })
    }


    if (this.catalogoseleccionado == 5) {
      const deleteItems = this.myForm.value.eliminar;
      this.sistemaService.sendCxpMigration(this.file.name, deleteItems).subscribe((result) => {
        this.importing = false;
        if (result.success) {
          Swal.fire({
            text: 'Se migraron ' + result.message + ' registros, favor de verificar.',
            icon: 'success',
          })
        } else {
          this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
        }
      })
    }

    if (this.catalogoseleccionado == 6) {
      const deleteItems = this.myForm.value.eliminar;
      this.sistemaService.sendContaAccountsMigration(this.file.name, deleteItems).subscribe((result) => {
        this.importing = false;
        if (result.success) {
          Swal.fire({
            text: 'Se migraron ' + result.message + ' registros, favor de verificar.',
            icon: 'success',
          })
        } else {
          this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
        }
      })
    }

    if (this.catalogoseleccionado == 7) {
      const deleteItems = this.myForm.value.eliminar;
      this.sistemaService.sendContaPolicyMigration(this.file.name, deleteItems).subscribe((result) => {
        this.importing = false;
        if (result.success) {
          Swal.fire({
            text: 'Se migraron ' + result.message + ' registros, favor de verificar.',
            icon: 'success',
          })
        } else {
          this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
        }
      })
    }

    if (this.catalogoseleccionado == 8) {
      const deleteItems = this.myForm.value.eliminar;
      this.sistemaService.sendActivosMigration(this.file.name, deleteItems).subscribe((result) => {
        this.importing = false;
        if (result.success) {
          Swal.fire({
            text: 'Se migraron ' + result.message + ' registros, favor de verificar.',
            icon: 'success',
          })
        } else {
          this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
        }
      })
    }

    if (this.catalogoseleccionado == 9) {
      const deleteItems = this.myForm.value.eliminar;
      this.sistemaService.sendFamiliaProductosMigration(this.file.name, deleteItems).subscribe((result) => {
        if (result.success) {
          this.sistemaService.sendVerificarFamiliaNiveles().subscribe((result) => {
            this.importing = false;
            if (result.success) {
              Swal.fire({
                text: 'Se migraron ' + result.message + ' registros, favor de verificar.',
                icon: 'success',
              });
            } else {
              Swal.fire({
                text: result.message,
                icon: 'info',
              });
            }
          });
        } else {
          this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
        }
      })
    }
    if (this.catalogoseleccionado == 10) {
      const deleteItems = this.myForm.value.eliminar;
      this.sistemaService.sendVehiculosMigration(this.file.name, deleteItems).subscribe((result) => {
        this.importing = false;
        if (result.success) {
          Swal.fire({
            text: 'Se migraron ' + result.message + ' registros, favor de verificar.',
            icon: 'success',
          })
        } else {
          this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
        }
      })
    }

    if (this.catalogoseleccionado == 11) {
      const deleteItems = this.myForm.value.eliminar;
      this.sistemaService.sendUsuariosMigration(this.file.name, deleteItems).subscribe((result) => {
        this.importing = false;
        if (result.success) {
          Swal.fire({
            text: 'Se migraron ' + result.message + ' registros, favor de verificar.',
            icon: 'success',
          })
        } else {
          this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
        }
      })
    }

    if (this.catalogoseleccionado == 12) {
      const deleteItems = this.myForm.value.eliminar;
      this.sistemaService.sendConsignadoClienteMigration(this.file.name, deleteItems).subscribe((result) => {
        this.importing = false;
        if (result.success) {
          Swal.fire({
            text: 'Se migraron ' + result.message + ' registros, favor de verificar.',
            icon: 'success',
          })
        } else {
          this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
        }
      })
    }

    if (this.catalogoseleccionado == 13) {
      const deleteItems = this.myForm.value.eliminar;
      this.sistemaService.sendConsignadoProveedorMigration(this.file.name, deleteItems).subscribe((result) => {
        this.importing = false;
        if (result.success) {
          Swal.fire({
            text: 'Se migraron ' + result.message + ' registros, favor de verificar.',
            icon: 'success',
          })
        } else {
          this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
        }
      })
    }

    if (this.catalogoseleccionado == 14) {
      const deleteItems = this.myForm.value.eliminar;
      this.sistemaService.sendProductoUnidadMigration(this.file.name, deleteItems).subscribe((result) => {
        this.importing = false;
        if (result.success) {
          Swal.fire({
            text: 'Se migraron ' + result.message + ' registros, favor de verificar.',
            icon: 'success',
          })
        } else {
          this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
        }
      })
    }

    if (this.catalogoseleccionado == 15) {
      const deleteItems = this.myForm.value.eliminar;
      this.sistemaService.sendProductoComposicionMigration(this.file.name, deleteItems).subscribe((result) => {
        this.importing = false;
        if (result.success) {
          Swal.fire({
            text: 'Se migraron ' + result.message + ' registros, favor de verificar.',
            icon: 'success',
          })
        } else {
          this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
        }
      })
    }

    if (this.catalogoseleccionado == 16) {
      const deleteItems = this.myForm.value.eliminar;
      this.sistemaService.sendCargaEntregaMigration(this.file.name, deleteItems).subscribe((result) => {
        this.importing = false;
        if (result.success) {
          Swal.fire({
            text: 'Se migraron ' + result.message + ' registros, favor de verificar.',
            icon: 'success',
          })
        } else {
          this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
        }
      })
    }

    if (this.catalogoseleccionado == 17) {
      const deleteItems = this.myForm.value.eliminar;
      this.sistemaService.sendAnticiposMigration(this.file.name, deleteItems).subscribe((result) => {
        this.importing = false;
        if (result.success) {
          Swal.fire({
            text: 'Se migraron ' + result.message + ' registros, favor de verificar.',
            icon: 'success',
          })
        } else {
          this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
        }
      })
    }

    if (this.catalogoseleccionado == 18) {
      const deleteItems = this.myForm.value.eliminar;
      this.sistemaService.sendInventariosMigration(this.file.name, deleteItems).subscribe((result) => {
        this.importing = false;
        if (result.success) {
          Swal.fire({
            text: 'Se migraron ' + result.message + ' registros, favor de verificar.',
            icon: 'success',
          });
        } else {
          this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
        }
      });
    }


    if (this.catalogoseleccionado == 19) {
      const deleteItems = this.myForm.value.eliminar;
      this.sistemaService.sendResguardosMigration(this.file.name, deleteItems).subscribe((result) => {
        this.importing = false;
        if (result.success) {
          Swal.fire({
            text: 'Se migraron ' + result.message + ' registros, favor de verificar.',
            icon: 'success',
          })
        } else {
          this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
        }
      })
    }

    if (this.catalogoseleccionado == 20) {
      const deleteItems = this.myForm.value.eliminar;
      this.sistemaService.sendCentroCostoMigration(this.file.name, deleteItems).subscribe((result) => {
        this.importing = false;
        if (result.success) {
          Swal.fire({
            text: 'Se migraron ' + result.message + ' registros, favor de verificar.',
            icon: 'success',
          })
        } else {
          this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
        }
      })
    }
    if (this.catalogoseleccionado == 21) {
      const deleteItems = this.myForm.value.eliminar;
      this.sistemaService.sendDisenosMigration(this.file.name, deleteItems).subscribe((result) => {
        this.importing = false;
        if (result.success) {
          Swal.fire({
            text: 'Se migraron ' + result.message + ' registros, favor de verificar.',
            icon: 'success',
          })
        } else {
          this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
        }
      })
    }


  }

  searchFile() {
    this.txtFile.nativeElement.click();
  }

  next() {
    if (this.actualStep < 3) {
      if (this.actualStep == 2 && !this.file) {
        Swal.fire({
          text: 'Primero seleccione un archivo a importar.',
          icon: 'error',
        })
        return;
      }
      this.actualStep++;
    }
  }

  downloadFile(name: string) {
    this.downloading = true;
    return this.http.get(`${this.baseUrl}/Utilerias/DescargarArchivosImportacion?tipo=${name}`, { responseType: 'arraybuffer' }).subscribe((d: any) => {
      this.downloading = false;
      if (d) {
        let blob = new Blob([d], { type: "text/csv" });
        let url = window.URL.createObjectURL(blob);
        let pwa = window.open(url);
        if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
          Swal.fire({
            text: 'Tienes bloqueadas las descargas de esta página, habilitalas.',
            icon: 'info',
          })
        }
      }
    });
  }

  onchange(event: any) {
    this.file = event.target.files.length > 0 ? event.target.files[0] : null
  }

  get getFileName(): string {
    return this.file ? this.file.name : '';
  }

  get getCatalogName(): string {
    let name = "";

    switch (this.catalogoseleccionado) {
      case 1: name = "Clientes"; break;
      case 2: name = "Productos"; break;
      case 3: name = "Saldos Cuentas Por Cobrar"; break;
      case 4: name = "Proveedores"; break;
      case 5: name = "Saldos Cuentas Por Pagar"; break;
      case 6: name = "Cuentas Contables"; break;
      case 7: name = "Pólizas Contables"; break;
      case 8: name = "Activos Fijos"; break;
      case 9: name = "Familias Productos"; break;
      case 10: name = "Vehiculos"; break;
      case 11: name = "Usuarios"; break;
      case 12: name = "Consignados Clientes"; break;
      case 13: name = "Consignados Clientes"; break;
      case 14: name = "Productos unidades"; break;
      case 15: name = "Productos composicion"; break;
      case 16: name = "Carga y Entrega"; break;
      case 17: name = "Anticipo"; break;
      case 18: name = "Inventario"; break;
      case 19: name = "Resguardos"; break;
      case 20: name = "Centros de Costos"; break;
      case 21: name = "Diseños de Producción"; break;
    }

    return name;
  }

  back() {
    if (this.actualStep >= 1) {
      this.actualStep--;
    }
  }

  selectToImport(type: number) {
    this.catalogoseleccionado = type;
    //this.actualStep = 2;
  }

}
