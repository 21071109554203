import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { ActiveButtons, EventReturn } from 'src/app/component-ui/interfaces/container-base.interface';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { PredefinidoCliente } from '../../interfaces/predefinidocliente.interface';

@Component({
  selector: 'app-predefinidos-clientes-page',
  templateUrl: './predefinidos-clientes-page.component.html',
  styles: [
  ]
})
export class PredefinidosClientesPageComponent implements OnInit {

  ngOnInit(): void {

  }


  public itemsCombo: ComboBoxEntity[] = [];

  @ViewChild('txtNombre')
  public txtNombre!: ElementRef<TextBoxComponent>;
  activeButtons: ActiveButtons = {
    new: false,
    delete: false,
    return: true,
    save: true,
    first: false,
    left: false,
    right: false,
    last: false,
    search: false,
    print: false,
  }
  public myForm: FormGroup = this.fb.group({
    Id: [0],
    Clave: [0],
    Nombre: [''],
    Empresa: [null],
    CondicionDePago: [null],
    ClaseVenta1: [null],
    ClaseVenta2: [null],
    ClaseVenta3: [null],
    FormaPago: [null],
    Moneda: [null],
    TipoCliente: [null],
    ClaseCliente: [null],
    ListaPrecio: [null],
    Eliminado: [false],
    Baja: [false],

  })

  constructor(private fb: FormBuilder, private containerService: ContainerBaseService) {
  }

  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombre;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  onItemsComboSearched(items: ComboBoxEntity[]): void {
    this.itemsCombo = items;
  }

  onBeforeSave(param: EventReturn) {
    param.callback(true);
  }

  onItemSearched(entity: PredefinidoCliente): void {
    if (entity) {
      this.myForm.reset(entity);
    }
    this.focus("txtNombre");
  }

  onAfterSave(entity: PredefinidoCliente) {
    this.myForm.reset(entity);
  }


  onChangueEntity(ent: any) {
    if (ent) {
      this.myForm.reset(ent);
    }
    this.focus('txtNombre')
  }

  get getIsNew(): any {
    return this.myForm.value;
  }

  onNewCatalog(next: number) {
    this.containerService.getEmptyEntity("PredefinidoCliente").subscribe((elem) => {
      elem.Clave = next;
      this.myForm.reset(elem);
      this.focus("txtNombre")
    })
  }

  get getItemsCombo(): ComboBoxEntity[] {
    return this.itemsCombo;
  }


  selectCombo(entity: ComboBoxEntity, type: string) {
    this.myForm.controls[type].setValue(entity);
  }

}
