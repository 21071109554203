<div cdkDrag class="data-search card border-1 transferencias" style="box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;">
  <div class="card-header" cdkDragHandle>
    <button (click)="close()" type="button" class="btn-close" aria-label="Close"></button>
    <h5>Transferencias Bancarias</h5>
  </div>
  <div class="card-body bg-light p-5px">
    <div class="row">
      <div class="col">
        <button type="button" (click)="agregar()" class="btn btn-success m-5px">
          <i class="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i>
          Agregar
        </button>
      </div>
      <div class="col">
        <h4 class="text-end mt-10px">
          Total Importe: {{sumaImportes | number: '1.2'}}
        </h4>
      </div>
    </div>
    <ng-scrollbar style="height: 220px;" class="bg-light">
      <table class="blueTable fs-14px">
        <thead style="position: sticky;top:0;">
          <tr>
            <th class="w-30px text-end"></th>
            <th class="w-30px text-end"></th>
            <th class="w-20 text-end">Importe Aplicar</th>
            <th class="w-20 text-center">Importe Original</th>
            <th class="w-20 text-center">Fecha Deposito</th>
            <th class="w-20 text-center">Banco</th>
            <th class="w-20 text-center">Cuenta Bancaria</th>
            <th class="w-20 text-center">Ref. Bancaria</th>
            <th class="w-20 text-center">Referencias</th>
        </thead>
        <tbody class="bg-light">
          <tr *ngFor="let t of transferenciasClone; let i = index">
            <td class="text-center">
              <i (click)="deleteTransferencia(t)" class="fa fa-rectangle-xmark fa-lg text-red"
                style="cursor: pointer;"></i>
            </td>
            <td class="text-center">
              <i (click)="editTransferencia(t, i)" class="fa fa-pencil text-yellow fa-lg" style="cursor: pointer;"></i>
            </td>
            <td class="text-end">{{t.ImporteAplicar | number: '1.2'}}</td>
            <td class="text-end">{{t.ImporteOriginal | number: '1.2'}}</td>
            <td class="text-center">{{t.FechaDeposito | date: 'dd/MM/yyyy'}}</td>
            <td class="text-center">{{t.Banco.Nombre}}</td>
            <td class="text-center">{{t.CuentaBancaria.Nombre}}</td>
            <td class="text-center">{{t.ReferenciaBancaria}}</td>
            <td class="text-center">{{t.Referencia}}</td>
          </tr>
        </tbody>
      </table>
    </ng-scrollbar>
  </div>
  <div class="card-footer fw-bold">
    <div style="text-align: right; margin-top: 5px;">
      <button style="margin-right: 5px;" (click)="closeListaTransferencias()" class="btn btn-success">Aceptar</button>
      <button (click)="closeListaTransferencias(false)" class="btn btn-danger">Cancelar</button>
    </div>
  </div>
</div>

<ng-template #modalAgregarTransferencia let-modal let-c="close">
  <div class="card text-center border-0" cdkDrag style="width: 540px;box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;">
    <div style="cursor: move;" cdkDragHandle class="card-header fw-bold">Agregar Transferencia</div>
    <div class="card-body bg-white-100 p-0">
      <app-container-base [hasBar]="false" [hasCounter]="false" [hasHeader]="false" [containerInnerHeight]="0"
        [esPopup]="true">
        <div class="screen-content">
          <app-panel-base title="">
            <div class="panel-content">
              <form [formGroup]="agregarTransferenciaFrm" autocomplete="off">
                <div class="row mb-5px">
                  <components-ui-date-box [topLabel]="true" (onChangeValue)="onChangeFechasCorte($event)"
                    label="Fecha Depósito" [widthLabelColumns]="3" [widthTextColumns]="6" [tabIndex]="1"
                    formControlName="FechaDeposito" />
                </div>
                <div class="row mb-5px">
                  <div class="col">
                    <components-ui-number-box [required]="true" [tabIndex]="2" [maxLength]="10" label="Importe Aplicar"
                      formControlName="ImporteAplicar" [topLabel]="true" [widthTextColumns]="12"
                      [widthLabelColumns]="12" />
                  </div>
                  <div class="col">
                    <components-ui-number-box [required]="true" [tabIndex]="3" [maxLength]="10" label="Importe Original"
                      formControlName="ImporteOriginal" [topLabel]="true" [widthTextColumns]="12"
                      [widthLabelColumns]="12" />
                  </div>
                </div>
                <div class="row mb-5px">
                  <div class="col">
                    <component-ui-combo-box label="Banco" [tabIndex]="5" formControlName="Banco" entityName="c_Banco"
                      [topLabel]="true" [widthTextColumns]="12" [widthLabelColumns]="12" [inputProperty]="'Nombre'"
                      (onSelectedItem)="onSelectedItem($event,'Banco')" [required]="true"
                      [listProperty]="'ClaveNombre'" />
                  </div>
                  <div class="col">
                    <component-ui-combo-box label="Cuenta Bancaria" [tabIndex]="5" formControlName="CuentaBancaria"
                      entityName="CuentaBancaria" [topLabel]="true" [widthTextColumns]="12" [widthLabelColumns]="12"
                      [inputProperty]="'Nombre'" (onSelectedItem)="onSelectedItem($event,'CuentaBancaria')"
                      [required]="true" [listProperty]="'ClaveNombre'" />
                  </div>
                </div>
                <div class="row mb-5px">
                  <div class="col">
                    <components-ui-text-box label="Referencia Bancaria" [required]="true"
                      formControlName="ReferenciaBancaria" [topLabel]="true" [tabIndex]="6" [widthTextColumns]="12"
                      [widthLabelColumns]="12" [maxLength]="100" />
                  </div>
                  <div class="col">
                    <components-ui-text-box label="Referencia" [required]="true" formControlName="Referencia"
                      [topLabel]="true" [tabIndex]="7" [widthTextColumns]="12" [widthLabelColumns]="12"
                      [maxLength]="100" />
                  </div>
                </div>
              </form>
              <hr>
              <div style="text-align: right;">
                <button type="button" (click)="accept()" class="btn btn-success me-5px">Aceptar</button>
                <button type="button" (click)="closeAgregarTransferencia()" class="btn btn-danger"
                  #txtCancelar>Cancelar</button>
              </div>
            </div>
          </app-panel-base>
        </div>
      </app-container-base>
    </div>
  </div>
</ng-template>
