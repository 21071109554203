import { Component, ElementRef, inject, Input, OnInit, ViewChild } from '@angular/core';
import { ModalService } from 'src/app/service/modal.service';
import { FacturacionService } from '../../services/facturacion.service';
import { Diseno, DisenoDetalle } from 'src/app/Manufactura/interfaces/manufacura.interface';
import { EventsService } from 'src/app/service/events.service';
import { AppSettings } from 'src/app/home/services/app-settings.service';
import { IfStmt } from '@angular/compiler';

@Component({
  selector: 'app-ctrl-disenos-produccion-page',
  templateUrl: './ctrl-disenos-produccion-page.component.html',
  styles: [
  ]
})
export class CtrlDisenosProduccionPageComponent implements OnInit {

  private eventsService = inject(EventsService);
  appSettings = inject(AppSettings);
  disenos: Diseno[] = [];
  @Input()
  public idEmpresa: number = 0;

  @Input()
  public idDisenoBase: number = 0;

  @Input()
  public idsSeleccionados: string = "";

  @ViewChild('txtBuscar')
  public txtBuscar!: ElementRef<HTMLInputElement>;

  seleccionados: string = "";

  ngOnInit(): void {
    this.getDisenos('', true);
  }

  getDisenos(filter: string = '', cerrar: boolean = false) {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.fs.obtenerDisenosProduccion(this.idEmpresa, filter, this.idDisenoBase).subscribe((result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      const lista: Diseno[] = JSON.parse(result.message);
      if (lista.length) {
        this.disenos = lista;
        this.disenos.map((item) => {
          if (item.Id == this.idDisenoBase) {
            item.Seleccionado = item.Bloqueado = true;
          } else {
            item.Seleccionado = this.existeIdSeleccionado(String(item.Id));
          }

          if (this.idsSeleccionados.length > 0) {
            let ids: string[] = this.idsSeleccionados.split(",");
            ids.forEach((i) => {
              if (Number(i) == Number(item.Id)) {
                item.Seleccionado = true;
              }
            })
          }

          return item;
        });

        const disenosSel = this.disenos.filter(P => P.Seleccionado);
        const disenosNotSel = this.disenos.filter(P => !P.Seleccionado);
        if (disenosSel.length > 0) {
          this.fs.obtenerDisenosDetalle(disenosSel[0].Id).subscribe((result) => {
            this.eventsService.publish('home:isLoading', { isLoading: false });
            const lista: DisenoDetalle[] = JSON.parse(result.message);
            disenosSel[0].Detalle = lista;
            this.disenos = [...this.disenos];
            this.disenos = [...disenosSel, ...disenosNotSel];
          });
        } else {

        }

      } else {
        if (cerrar) {
          this.eventsService.publish('home:showAlert', { message: "No se encontraron diseños de producción, verifique.", cancelButton: false });
          this.ms.closeModal(null);
        } else {
          this.disenos = [];
        }
      }
    })
  }

  existeIdSeleccionado(id: string) {
    return this.seleccionados.includes(`,${id},`);
  }

  marcarSeleccionados() {
    this.seleccionados = "";
    this.disenos.forEach((i) => {
      if (i.Seleccionado) {
        this.seleccionados += `${i.Id},`;
      }
    })
    if (this.seleccionados.length > 0) {
      this.seleccionados = "," + this.seleccionados;
    }
  }

  seleccionar(index: number, e: any) {

    if (this.disenos[index].Id == this.idDisenoBase) {
      e.preventDefault();
      return;
    }

    let sel = !this.disenos[index].Seleccionado;
    if (sel) {
      this.eventsService.publish('home:isLoading', { isLoading: true });
      this.fs.obtenerDisenosDetalle(this.disenos[index].Id).subscribe((result) => {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        const lista: DisenoDetalle[] = JSON.parse(result.message);
        this.disenos[index].Seleccionado = sel;
        this.disenos[index].Detalle = lista;
        const disenosSel = this.disenos.filter(P => P.Seleccionado);
        const disenosNotSel = this.disenos.filter(P => !P.Seleccionado);
        if (disenosSel.length > 0) {
          this.disenos = [...disenosSel, ...disenosNotSel];
          setTimeout(() => {
            this.marcarSeleccionados();
          }, 100);
        } else {
          this.seleccionados = "";
          this.disenos = [...this.disenos];
        }
      });
    } else {
      this.disenos[index].Seleccionado = sel;
      this.disenos = [...this.disenos];
      setTimeout(() => {
        this.marcarSeleccionados();
      }, 100);
    }

  }

  keydown(key: any) {
    if (key.keyCode == 13) {
      const text = this.txtBuscar.nativeElement.value;
      this.getDisenos(text);
    }
  }

  getEnabledDiseno(idDiseno: number): boolean {
    return idDiseno == this.idDisenoBase;
  }

  accept() {
    const seleccionados: Diseno[] = this.disenos.filter(P => P.Seleccionado);
    if (seleccionados.length == 0) {
      this.eventsService.publish('home:showAlert', { message: "Debe de seleccionar al menos un Diseño.", cancelButton: false });
      return;
    }
    this.ms.closeModal(seleccionados);
  }

  closeModal() {
    this.ms.closeModal(null);
  }

  get getSeleccionadosLista(): Diseno[] {
    return this.disenos.filter(P => P.Seleccionado);
  }

  get getSeleccionados(): number {
    return this.disenos.filter(P => P.Seleccionado).length;
  }

  verComponentes(e: any) {
    e.preventDefault();
  }

  constructor(private ms: ModalService, private fs: FacturacionService) {

  }

}
