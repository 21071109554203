import { Component, ElementRef, inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActiveButtons, EventReturn } from 'src/app/component-ui/interfaces/container-base.interface';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { ConfiguracionService } from 'src/app/configuracion/service/configuracion.service';
import { Vale } from '../../interfaces/vales.interface';
import { EventsService } from 'src/app/service/events.service';
import { UtilsService } from 'src/app/service/utils.service';
import { Result, UserLogged } from 'src/app/auth/interfaces';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { FinanzasService } from '../../services/finanzas.service';
import { FacturacionService } from 'src/app/ventas/services/facturacion.service';
import * as moment from 'moment';
import { ComboBoxComponent } from 'src/app/component-ui/components/combo-box/combo-box.component';
import Swal from 'sweetalert2';
import { ModalService } from 'src/app/service/modal.service';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';
import { SucursalPageComponent } from '../../../configuracion/pages/sucursales-page/sucursal-page/sucursal-page.component';

@Component({
  selector: 'app-captura-vales-page',
  templateUrl: './captura-vales-page.component.html',
  styles: [
  ]
})
export class CapturaValesPageComponent implements OnInit {

  private eventsService = inject(EventsService);
  info: UserLogged = {} as UserLogged;
  @ViewChild('cboAutoriza')
  public cboAutoriza!: ElementRef<ComboBoxComponent>;

  @ViewChild('modalCancel')
  public modalCancel!: ElementRef<HTMLElement>;

  activeButtons: ActiveButtons = {
    new: true,
    delete: false,
    return: false,
    save: false,
    first: false,
    left: false,
    right: false,
    last: false,
    search: false,
    print: true,
  }
  private newEntityObj = {
    Id: [0],
    Clave: [0],
    Nombre: ['', [Validators.required]],
    Empresa: [null],
    Sucursal: [null],
    UsuarioElabora: [null],
    UsuarioAutoriza: [null],
    UsuarioCancela: [null],
    Recibio: [null],
    Impreso: [false],
    FechaEmision: [null],
    FechaElabora: [null],
    FechaEmisionString: [''],
    MotivoCancelacion: [''],
    Tipo: [null],
    FechaCancelacion: [null],
    Total: [0],
    Observaciones: [''],
    Baja: [false],
  };

  public myForm: FormGroup = this.fb.group(this.newEntityObj);


  constructor(private configuracionService: ConfiguracionService,
    private fService: FinanzasService, private factService: FacturacionService, private ms: ModalService,
    private fb: FormBuilder, private containerService: ContainerBaseService, private utilsService: UtilsService) { }

  onBeforeSave(param: EventReturn) {
    param.callback(true);
  }

  onAfterSave(entity: Vale) {
    this.myForm.reset(entity);
  }

  onItemSearched(entity: Vale): void {

    if (entity) {
      this.myForm.reset(entity);
      //this.focus('txtNombre')
    }
  }

  ngOnInit(): void {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.info = this.utilsService.getUserLogged();
    this.new();
  }

  selectedCombo(entity: ComboBoxEntity, tipo: string) {
    this.myForm.controls[tipo].setValue(entity);
  }


  openCancel() {
    const b: any = this.modalCancel;
    this.ms.openModal(b, (e: any) => {
      if (e) {
        this.proceedCancel(e)
      }
    })
  }


  proceedCancel(e: any) {
    this.eventsService.publish('home:showAlert', {
      message: '¿Está seguro de Cancelar el Vale?',
      cancelButton: true,
      textAccept: "Sí, Cancelar",
      textCancel: "Cancelar",
      onConfirm: (data: AlertResponse) => {
        if (data.isAccept) {
          this.eventsService.publish('home:isLoading', { isLoading: true });
          const fecha = new Date();
          const date = moment(fecha).format("YYYY-MM-DDTHH:mm:ss");
          this.fService.cancelarVale(this.myForm.value.Id, e.MotivoCancelacion).subscribe((r) => {
            this.eventsService.publish('home:isLoading', { isLoading: false });
            if (r.success) {
              const obj = JSON.parse(r.message);
              this.myForm.reset(obj);
              Swal.fire({ position: 'center', icon: 'success', title: 'Se Canceló el Vale Correctamente', showConfirmButton: false, timer: 2000 });
            } else {
              this.eventsService.publish('home:showAlert', { message: r.message, cancelButton: false });
            }

          })
        }
      }
    });
  }

  blurFolio(values: any) {
    if (values.after != values.before) {
      this.eventsService.publish('home:isLoading', { isLoading: true });
      setTimeout(() => {
        const ent = this.myForm.value;

        if (ent.Clave > 0) {
          this.fService.obtenerValePorFolio(this.info.empresa!.numero, this.info.sucursal!.numero, ent.Clave).subscribe((result) => {
            this.eventsService.publish('home:isLoading', { isLoading: false });
            if (result) {
              const ent = JSON.parse(result.message);
              this.myForm.reset(ent);
            } else {
              this.new();
            }
          })
        } else {
          this.new();
        }
      }, 50);
    }
  }

  onClickBarButton(button: string): void {
    switch (button) {
      case "new": this.new(); break;
      case "save": this.save(); break;
      case "print": this.print(); break;
    }
  }

  new() {
    this.containerService.getEmptyEntity("Vale").subscribe((elem) => {
      elem.Empresa = { Id: this.info.empresa!.numero, Clave: this.info.empresa!.clave, Nombre: this.info.empresa!.nombre };
      elem.Sucursal = { Id: this.info.sucursal!.numero, Clave: this.info.sucursal!.clave, Nombre: this.info.sucursal!.nombre };
      elem.FechaEmision = new Date();
      this.fService.obtenerSiguienteFolioVale(this.info.empresa!.numero, this.info.sucursal!.numero).subscribe((result) => {
        elem.Clave = Number(result.message);
        this.getDate(elem);
        const t: any = this.cboAutoriza;
        setTimeout(() => {
          t.tagInput.nativeElement.focus();
        }, 100);
      })
    })
  }

  getDate(ent: any) {
    this.factService.ObtenerFechaPorSucursal(ent.Sucursal.Id).subscribe((result) => {
      //2024-01-29T10:23:49
      const f = result.message.split('T')[0].split('-');
      const t = result.message.split('T')[1].split(':');
      ent.FechaEmision = new Date(parseInt(f[0]), parseInt(f[1]) - 1, parseInt(f[2]), parseInt(t[0]), parseInt(t[1]), parseInt(t[2]));
      ent.FechaEmisionString = moment(ent.FechaEmision).format("YYYY-MM-DDTHH:mm:ss");
      this.eventsService.publish('home:isLoading', { isLoading: false });
      ent.UsuarioElabora = { Id: this.info.numero, Clave: this.info.clave, Nombre: this.info.nombreUsuario };
      this.myForm.reset(ent);
    })
  }

  save() {


    const ent = this.myForm.value;

    if (!ent.FechaEmision) {
      this.eventsService.publish('home:showAlert', { message: 'Debe de indicar la Fecha de Emisión, verifique.', cancelButton: false });
      return;
    }

    if (ent.Clave <= 0) {
      this.eventsService.publish('home:showAlert', { message: 'Debe de Indicar una Clave Valida.', cancelButton: false });
      return;
    }

    if (!ent.UsuarioAutoriza) {
      this.eventsService.publish('home:showAlert', { message: 'Debe de Seleccionar el Usuario que autoriza el Vale.', cancelButton: false });
      return;
    }

    if (!ent.Recibio) {
      this.eventsService.publish('home:showAlert', { message: 'Debe de Seleccionar la persona que recibe el Vale.', cancelButton: false });
      return;
    }

    if (!ent.Tipo) {
      this.eventsService.publish('home:showAlert', { message: 'Debe de Seleccionar el Tipo de Vale.', cancelButton: false });
      return;
    }

    if (ent.Total <= 0) {
      this.eventsService.publish('home:showAlert', { message: 'Debe de un Importe de Valido.', cancelButton: false });
      return;
    }

    if (!ent.Observaciones) {
      this.eventsService.publish('home:showAlert', { message: 'Debe de indicar las Observaciones.', cancelButton: false });
      return;
    }

    this.eventsService.publish('home:isLoading', { isLoading: true });
    const json = JSON.stringify(ent);
    this.containerService.saveEntity("Vale", json).subscribe((result: Result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result.success) {
        const obj = JSON.parse(result.message);
        this.myForm.reset(obj);
        Swal.fire({ position: 'center', icon: 'success', title: 'Se Generó el Vale Correctamente', showConfirmButton: false, timer: 2000 });
      } else {
        this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
      }
    });
  }

  print() { }


  get getIsNew(): any {
    return this.myForm.value.Id == 0;
  }

}
