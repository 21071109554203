import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../components/components.module';
import { ComponentUiModule } from '../component-ui/component-ui.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { DateBoxComponent } from '../component-ui/components/date-box/date-box.component';
import { NgxEditorModule } from 'ngx-editor';
import { GuiGridModule } from '@generic-ui/ngx-grid';
import { SeriesManufacturaPageComponent } from './pages/series-manufactura-page/series-manufactura-page.component';
import { OrdenesManufacturaPageComponent } from './pages/ordenes-manufactura-page/ordenes-manufactura-page.component';
import { HistoricoOrdenPageComponent } from './pages/historico-orden-page/historico-orden-page.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { BusquedaOrdenPageComponent } from './pages/busqueda-orden-page/busqueda-orden-page.component';
import { GeneracionOrdenPageComponent } from './pages/generacion-orden-page/generacion-orden-page.component';
import { ProduccionesPorOrdenPageComponent } from './pages/producciones-por-orden-page/producciones-por-orden-page.component';
import { ConfiguracionManufacturaPageComponent } from './pages/configuracion-manufactura-page/configuracion-manufactura-page.component';
import { VerificadorOrdenesManufacturaPageComponent } from './pages/reportes/verificador-ordenes-manufactura-page/verificador-ordenes-manufactura-page.component';
import { MarcaOrdenTerminadaPageComponent } from './pages/marca-orden-terminada-page/marca-orden-terminada-page.component';
import { VentasModule } from '../ventas/ventas.module';
import { DireccionMapaPageComponent } from './pages/direccion-mapa-page/direccion-mapa-page.component';
import { TableroOrdnesPageComponent } from './pages/tablero-ordnes-page/tablero-ordnes-page.component';
import { OpcionCancelacionOrdenProduccionPageComponent } from './pages/opcion-cancelacion-orden-produccion-page.component/opcion-cancelacion-orden-produccion-page.component';
import { TipoProduccionPageComponent } from './pages/tipo-produccion-page/tipo-produccion-page.component';
import { DisenosProduccionPageComponent } from './pages/disenos-produccion-page/disenos-produccion-page.component';
import { CtrlEdicionProductoCompuestoPageComponent } from './pages/ctrl-edicion-producto-compuesto-page/ctrl-edicion-producto-compuesto-page.component';
import { ImpresionDisenosComponent } from './pages/impresion-disenos/impresion-disenos.component';
import { EnviarProduccionPageComponent } from './pages/enviar-produccion-page/enviar-produccion-page.component';
import { CapturaProduccionPageComponent } from './pages/captura-produccion-page/captura-produccion-page.component';
import { VerificadorOrdenesComponent } from './pages/verificador-ordenes/verificador-ordenes.component';
import { UbicacionesProdTermPageComponent } from './pages/ubicaciones-prod-term-page/ubicaciones-prod-term-page.component';



@NgModule({
  declarations: [
    SeriesManufacturaPageComponent,
       OrdenesManufacturaPageComponent,
       HistoricoOrdenPageComponent,
       BusquedaOrdenPageComponent,
       GeneracionOrdenPageComponent,
       VerificadorOrdenesManufacturaPageComponent,
       ConfiguracionManufacturaPageComponent,
       ProduccionesPorOrdenPageComponent,
       CtrlEdicionProductoCompuestoPageComponent,
       MarcaOrdenTerminadaPageComponent,
       DireccionMapaPageComponent,
       TableroOrdnesPageComponent,
       OpcionCancelacionOrdenProduccionPageComponent,
       TipoProduccionPageComponent,
       DisenosProduccionPageComponent,
       ImpresionDisenosComponent,
       EnviarProduccionPageComponent,
       CapturaProduccionPageComponent,
       VerificadorOrdenesComponent,
       UbicacionesProdTermPageComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ComponentsModule,
    ComponentUiModule,
    DateBoxComponent,
    ReactiveFormsModule,
    NgScrollbarModule,
    NgxEditorModule,
    GuiGridModule,
    DragDropModule,
    NgbTooltipModule,
    VentasModule
  ]
})
export class ManufacturaModule { }
