<app-container-base [hasCounter]="false" [entity]="myForm" icon="fa fa-money-bill-1" [activeButtons]="activeButtons"
  [applyFormValidations]="false" (onClickBarButton)="onClickBarButton($event)"
  subTitle="Emisión de Transferencias Bancarias">
  <div class="screen-content" style="height: 100%;width: 1250px;">

    <form [formGroup]="myForm" autocomplete="off">

      <div class="d-flex" style="flex-direction: row;">
        <div style="width:1000px;">
          <app-panel-base title="Información para realizar la Transferencia" style="height: 151px;">
            <div class="panel-content">

              <div class="d-flex">
                <component-ui-combo-box style="width:15%; margin-right: 5px;"
                  helpLine="Tipo de Transferencia a realizar." [topLabel]="true" label="Tipo"
                  entityName="TIpoTransferencia" [inputProperty]="'Nombre'" [zeroMask]="3" [tabIndex]="1"
                  [widthLabelColumns]="12" [widthTextColumns]="12" formControlName="Tipo"
                  (onSelectedItem)="onSelectedItem($event,'Tipo')" [widthRightColumns]="0" #cboTipoTrans />


                <components-ui-number-box style="width:15%;" [topLabel]="true" helpLine="Folio de la transferencia."
                  formControlName="Folio" label='Folio' [tabIndex]="2" [widthLabelColumns]="12"
                  (blur)="blurFolio($event)" [widthTextColumns]="12" />

                <div style="margin-left: 5px;">
                  <components-ui-date-box label="Emisión" [tabIndex]="3" [topLabel]="true"
                    formControlName="FechaTransferencia" helpLine="Fecha de emisión de la Transferencia Bancaria."
                    [widthLabelColumns]="7" [widthTextColumns]="6" #txtEmision />
                </div>
                <component-ui-combo-box style="width:15%; margin-left: 5px;" filterQuery="Abona = true"
                  helpLine="Concepto de Pago." [topLabel]="true" label="Concepto" [required]="true"
                  entityName="ConceptoCxp" [inputProperty]="'Nombre'" [zeroMask]="3" [tabIndex]="4"
                  [widthLabelColumns]="12" [widthTextColumns]="12" formControlName="Concepto"
                  (onSelectedItem)="onSelectedItem($event,'Concepto')" [widthRightColumns]="0" />
              </div>

              <div class="d-flex mt-1">
                <div style="width: 294px;margin-left: 14px;">
                  <components-ui-text-box [tabIndex]="5" (elementoSeleccionado)="elementoSeleccionado($event)"
                    (keydown.enter)="enterProveedor()" (blur)="enterProveedor()"
                    helpLine="Proveedor al que se le realizará la Transferencia Bancaria. Presione F2 para buscar."
                    label="Proveedor" [widthLabelColumns]="8" [widthTextColumns]="4" entidadBusqueda="Proveedor"
                    #txtProveedor />
                </div>
                <div style="width: 300px">
                  <div class="fs-13px lh-12 mb-2px fw-bold text-dark text-truncate">
                    {{ myForm.value.Proveedor?.Nombre | uppercase}}
                  </div>
                </div>
              </div>
              <!-- <div class="d-flex mt-1">
                <div style="width: 100%;margin-left: -5px;">
                  <components-ui-text-box [enabled]="myForm.value.ColocarBeneficiario" formControlName="Beneficiario"
                    [required]="myForm.value.ColocarBeneficiario" helpLine="Beneficiario, 300 caracteres."
                    [maxLength]="150" [label]="'Beneficiario'" [tabIndex]="7" [widthTextColumns]="8"
                    [widthLabelColumns]="4" />
                </div>
              </div> -->
            </div>
          </app-panel-base>
        </div>
        <!-- <div style="width:300px;">
          <app-panel-base title="Opciones" style="height: 151px;">
            <div class="panel-content">
              <component-ui-check-box helpLine="Marque para indicar que es un abono en cuenta." [tabIndex]="4"
                formControlName="AbonoEnCuenta" [widthLabelColumns]="8" [widthTextColumns]="4"
                [label]="'Abono en Cuenta'" />

              <component-ui-check-box helpLine="Marque para indicar el Beneficiario." [tabIndex]="5"
                formControlName="ColocarBeneficiario" [widthLabelColumns]="8" [widthTextColumns]="4"
                [label]="'Indicar Beneficiario'" />
            </div>
          </app-panel-base>
        </div> -->
        <div style="width:250px;" *ngIf="myForm.value.Id>0">
          <app-panel-base title="Opciones Especiales" style="height: 151px;">
            <div class="panel-content">

              <div (click)="cancelar()" *ngIf="!myForm.value.FechaCancelacion"
                class="btn btn-sm d-block btn-white mb-10px me-2"><i
                  class="fa fa-calendar-xmark t-plus-1 text-danger fa-fw fa-lg"></i> Cancelar</div>
              <div (click)="verPoliza()" class="btn btn-sm d-block btn-white mb-10px me-2"><i
                  class="fa fa-calculator t-plus-1 text-primary fa-fw fa-lg"></i> Póliza</div>

            </div>
          </app-panel-base>
        </div>
      </div>

      <app-panel-base title="Detalles">
        <div class="panel-content d-flex" style="flex-direction: row;">
          <div style="width: 500px;">
            <components-ui-number-box [formatoMoneda]="true" [helpLine]="'Importe de la Transferencia a realizar.'"
              [maxLength]="20" [label]="'Importe a Transferir'" [tabIndex]="6" [widthTextColumns]="4" [required]="true"
              formControlName="ImporteTransferencia" [widthLabelColumns]="5" #txtImporte />
            <components-ui-number-box [formatoMoneda]="true" [enabled]="false" [maxLength]="20" [label]="'Aplicado'"
              formControlName="Seleccionado" [tabIndex]="7" [widthTextColumns]="4" [widthLabelColumns]="5" />
            <components-ui-number-box [formatoMoneda]="true" [enabled]="false" [maxLength]="20" [label]="'Disponible'"
              formControlName="Disponible" [tabIndex]="8" [widthTextColumns]="4" [widthLabelColumns]="5" />
          </div>
          <div style="width: 500px;">
            <component-ui-combo-box helpLine="Moneda en la que se realizará la Transferencia" [required]="true"
              [label]="'Moneda'" [widthList]="300" [tabIndex]="9" [entityName]="'Moneda'" [widthTextColumns]="3"
              formControlName="Moneda" (onSelectedItem)="onSelectedItem($event,'Moneda')" [widthLabelColumns]="3"
              [widthRightColumns]="6" [listProperty]="'ClaveNombre'" />

            <components-ui-number-box [formatoMoneda]="true"
              [helpLine]="'Tipo de Cambio en caso de pagar con Moneda Extranjera.'" [maxLength]="20"
              formControlName="TipoCambio" [label]="'T Cambio'" [tabIndex]="10" [widthTextColumns]="2"
              [widthLabelColumns]="3" />

            <components-ui-text-box helpLine="Observaciones de la Transferencia, 300 caracteres." [maxLength]="300"
              formControlName="Observaciones" [label]="'Observaciones'" [tabIndex]="11" [widthTextColumns]="8"
              [widthLabelColumns]="3" />
          </div>
        </div>
      </app-panel-base>


      <ul class="nav nav-tabs m-2 bg-light" role="tablist">
        <li class="nav-item" role="presentation">
          <a [href]="'#transfer-default-tab-1' + idUnico" data-bs-toggle="tab" class="nav-link text-dark active"
            aria-selected="true" role="tab">
            <span class="d-sm-block d-none">
              Comprobantes de Cuentas Por Pagar
            </span>
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a [href]="'#transfer-default-tab-2' + idUnico" data-bs-toggle="tab" class="nav-link text-dark"
            aria-selected="false" role="tab">
            <span class="d-sm-block d-none">Póliza Contable</span>
          </a>
        </li>
      </ul>
      <div class="tab-content panel rounded-0 me-2 ms-2 mt-0">
        <div [id]="'transfer-default-tab-1' + idUnico" class="tab-pane fade active show" role="tabpanel">
          <app-panel-base title="Documentos Por Pagar" style="height: 300px;">
            <div class="panel-content">


              <ng-scrollbar style="height: 250px; width:1200px;">
                <table class="blueTable">
                  <thead style="position: sticky;top:0;">
                    <tr>
                      <th style="width: 50px;"></th>
                      <th style="width: 80px;text-align: center;">Suc</th>
                      <th style="width: 100px;text-align: center;">SerieFolio</th>
                      <th style="width: 100px;text-align: center;">Factura Prov</th>
                      <th style="width: 100px;text-align: center;">Emisión</th>
                      <th style="width: 100px;text-align: center;">Vence</th>
                      <th style="width: 100px;text-align: center;">Moneda</th>
                      <th style="width: 100px; text-align: right;">Total</th>
                      <th style="width: 100px; text-align: right;">Saldo</th>
                      <th style="width: 100px; text-align: right;">Abonar</th>
                      <th style="width: 100px; text-align: left;">Observaciones</th>
                    </tr>
                  </thead>
                  <tbody class="bg-light">
                    <tr
                      [style]=" (item.seleccionado && myForm.value.Id==0)?'background-color:#a9fda9;font-weight: bold;':''"
                      *ngFor="let item of movimientos ; let index = index;">
                      <td style="text-align: center;">
                        <div *ngIf="myForm.value.Id == 0" class="" style="text-align: center;">
                          <input (click)="mark(index, $event)" type="checkbox" class="form-check-input"
                            [checked]="item.seleccionado" id="product1">
                        </div>
                      </td>
                      <td style="text-align:center;"><span class="ms-1"> {{item.sucursal.nombre | uppercase}}</span>
                      </td>
                      <td style="text-align:center;"><span class="ms-1">{{item.serieFolio}}</span></td>
                      <td style="text-align:center;"><span> {{ item.facturaProveedor }}</span></td>
                      <td style="text-align:center;"><span> {{ item.emisionString }}</span></td>
                      <td style="text-align:center;"><span> {{ item.vencimientoString }}</span></td>
                      <td style="text-align:center;"><span> {{ item.moneda.nombre }}</span></td>

                      <td style="text-align: right;"><span class="me-1"> {{item.total| number:'1.2-6'}}</span></td>
                      <td style="text-align: right;"><span class="me-1"> {{item.saldo| number:'1.2-6'}}</span></td>
                      <td style="text-align: right;"><span class="me-1"> {{item.abono| number:'1.2-6'}}</span></td>
                      <td style="text-align:center;"><span> {{ item.observaciones }}</span></td>
                    </tr>
                  </tbody>
                </table>
              </ng-scrollbar>
            </div>
          </app-panel-base>
        </div>
        <div [id]="'transfer-default-tab-2' + idUnico" class="tab-pane fade" role="tabpanel">
          <app-captura-poliza-page [esCaptura]="true" [setTipoPago]="myForm.value.Tipo?.Id" #ctrlPoliza
            [setFechaTransFerencia]="myForm.value.FechaTransferencia" [idChequeOTrans] = "myForm.value.Id"/>
        </div>
      </div>

      <hr>
      <div class="widget-list mb-0" style="margin-left:5px;width: 1190px; display: flex;" *ngIf="myForm.value.Id>0">
        <div class="widget-list-item" style="width:25%;">
          <div class="widget-list-media">
            <i class="fas fa-user-check fa-2x text-green"></i>
          </div>
          <div class="widget-list-content">
            <h4 class="widget-list-title">{{myForm.value.UsuarioElabora.Nombre}}</h4>
            <p class="widget-list-desc">Usuario elaboró</p>
          </div>
        </div>
        <div class="widget-list-item" style="width:25%;" *ngIf="myForm.value.UsuarioCancela">
          <div class="widget-list-media">
            <i class="fas fa-user-xmark fa-2x text-red"></i>
          </div>
          <div class="widget-list-content">
            <h4 class="widget-list-title">{{myForm.value.UsuarioCancela.Nombre}}</h4>
            <p class="widget-list-desc">Usuario canceló</p>
          </div>
        </div>
      </div>
      <div class="cancelled-header animate__animated animate__bounceInDown" *ngIf="myForm.value.FechaCancelacion">
        <div class="cancelled">&nbsp;</div>
        <div class="cancelled-detail">{{getCancelDate}}</div>
        <div class="cancelled-detail">{{ myForm.value.MotivoCancelacion | uppercase}}</div>
      </div>
    </form>
    <ng-template #modalCancelacion let-modal let-c="close">
      <app-popup-cancelar-transferencia-page />
    </ng-template>
  </div>
</app-container-base>
