<app-container-base subTitle="Balanza de comprobacion" (onClickBarButton)="clickBoton($event)" [isReport]="true"
  [activeButtons]="activeButtons" icon=" fas fa-scale-unbalanced" [hasCounter]="false">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base title="Generales">
        <div class="panel-content">

          <div class="col-md-8">
            <components-ui-text-box label='Nombre del Reporte' formControlName="NombreReporte" [tabIndex]="1"
              [widthLabelColumns]="3" [widthTextColumns]="9" [maxLength]="100" #txtNombreReporte />
          </div>

          <div class="row">
            <div class="col-md-4">
              <component-ui-combo-box (onSelectedItem)="selectedCombo($event,'Mes1')" [selectManualItem]="getMes1"
                [setItemsList]="listaMeses" listProperty='Nombre' label="Del mes" [tabIndex]="2"
                [inputProperty]="'Nombre'" [widthLabelColumns]="6" [widthTextColumns]="4" #comboMes1 />
            </div>
            <div class="col-md-2">
              <component-ui-combo-box (onSelectedItem)="selectedCombo($event,'Mes2')" [selectManualItem]="getMes2"
                listProperty='Nombre' [setItemsList]="this.listaMeses" label="al" [tabIndex]="3"
                [inputProperty]="'Nombre'" [widthLabelColumns]="3" [widthTextColumns]="8" #comboMes2 />

            </div>
            <div class="col-md-2">
              <component-ui-combo-box label='Año' [tabIndex]="4" [zeroMask]="2"
                [selectManualItem]="selectAnioManualItem" [setItemsList]="this.listaAnios" [widthTextColumns]="6"
                [widthLabelColumns]="4" (onSelectedItem)="selectedCombo($event,'Anio1')" [inputProperty]="'Nombre'"
                [listProperty]="'Nombre'" #comboAnio />
            </div>

          </div>

        </div>
      </app-panel-base>
      <app-panel-base title="Generales">
        <div class="panel-content">
          <component-ui-selection-filter (ReportHeaderChange)="reportHeaderChange($event)"
            [(FiltrosSeleccion)]="this.filtrosReporte" [tabIndex]="4" (FiltrosSeleccionChange)="changeselec($event)"
            [ReportHeader]="this.myForm"></component-ui-selection-filter>
        </div>
      </app-panel-base>
    </form>
  </div>
</app-container-base>
