<app-container-base [hasCounter]="false" [entity]="myForm" icon="fa fa-money-bill-1" [activeButtons]="activeButtons"
  [applyFormValidations]="false" (onClickBarButton)="onClickBarButton($event)" subTitle="Emitir Documentos de Ventas">
  <div class="screen-content" style="height: 100%;">
    <form [formGroup]="myForm" autocomplete="off">


      <div *ngIf="mostrarListas" class="card text-center border-0" cdkDrag
        style="width: 800px;box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;position: absolute; margin-left: 50px;margin-top: 50px;z-index: 99;">
        <div style="cursor: move;" cdkDragHandle class="card-header fw-bold">Listas de Precios</div>
        <div class="card-body bg-light">
          <table class="table table-striped">
            <thead style="position: sticky;top:0;">
              <tr>
                <th style="width: 70px;">Clave</th>
                <th style="width: 70px;">Descripción</th>
                <th style="width: 70px;">Lista</th>
                <th style="width: 70px;">Mínimo</th>
                <th style="width: 70px;">Máximo</th>
                <th style="width: 70px;">Lista</th>
                <th style="width: 70px;">Mínimo</th>
                <th style="width: 70px;">Máximo</th>
                <th style="width: 70px;">Lista</th>
                <th style="width: 70px;">Mínimo</th>
                <th style="width: 70px;">Máximo</th>
              </tr>
            </thead>
          </table>
          <cdk-virtual-scroll-viewport itemSize="20" style="height: 300px;border-bottom: 1px solid #AAAAAA;">
            <table class="table table-striped">
              <tbody>
                <tr *cdkVirtualFor="let item of sourcePrecios">
                  <td class="text-truncate">{{item.productoClave}}</td>
                  <td class="text-truncate">{{item.productoNombre}}</td>
                  <td class="text-truncate">{{item.listaNombre1}}</td>
                  <td class="text-truncate">{{item.precioMinimo1 | number:'1.2'}}</td>
                  <td class="text-truncate">{{item.precioMaximo1 | number:'1.2'}}</td>
                  <td class="text-truncate">{{item.listaNombre2}}</td>
                  <td class="text-truncate">{{item.precioMinimo2 | number:'1.2'}}</td>
                  <td class="text-truncate">{{item.precioMaximo2 | number:'1.2'}}</td>
                  <td class="text-truncate">{{item.listaNombre3}}</td>
                  <td class="text-truncate">{{item.precioMinimo3 | number:'1.2'}}</td>
                  <td class="text-truncate">{{item.precioMaximo3 | number:'1.2'}}</td>
                  <!-- <td class="text-truncate">{{item.listaNombre4}}</td>
                  <td class="text-truncate">{{item.precioMinimo4 | number:'1.2'}}</td>
                  <td class="text-truncate">{{item.precioMaximo4 | number:'1.2'}}</td>
                  <td class="text-truncate">{{item.listaNombre5}}</td>
                  <td class="text-truncate">{{item.precioMinimo5 | number:'1.2'}}</td>
                  <td class="text-truncate">{{item.precioMaximo5 | number:'1.2'}}</td>
                  <td class="text-truncate">{{item.listaNombre6}}</td>
                  <td class="text-truncate">{{item.precioMinimo6 | number:'1.2'}}</td>
                  <td class="text-truncate">{{item.precioMaximo6 | number:'1.2'}}</td> -->
                </tr>
              </tbody>
            </table>
          </cdk-virtual-scroll-viewport>
        </div>
      </div>
      <app-panel-base *ngIf="sourceProducts.length>0 && cords" title="Búsqueda de Productos"
        style="font-family: monospace; position: absolute; z-index:9;margin-left:150px;margin-top:80px;width:1050px;box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;">
        <div class="panel-content">
          <ng-scrollbar style="height: 400px;">
            <table class="table table-sm table-bordered"
              style="border: 1px solid rgb(164, 164, 164);height: 38px;table-layout: fixed !important;">
              <thead style="position: sticky;top:0;font-weight: 700 !important;background-color: #ff8000;">
                <tr>
                  <th style="font-weight:700;color:#ffffff;width:200px;" class="pt-0 pb-0">Producto</th>
                  <th style="font-weight:700;color:#ffffff;width:100px;" class="pt-0 pb-0">Familia</th>
                  <th style="font-weight:700;color:#ffffff;width:100px;" class="pt-0 pb-0">Unidad</th>
                  <th style="font-weight:700;color:#ffffff;width:100px;text-align: right;" class="pt-0 pb-0">P Mínimo
                  </th>
                  <th style="font-weight:700;color:#ffffff;width:100px;text-align: right;" class="pt-0 pb-0">P Máximo
                  </th>
                  <th style="font-weight:700;color:#ffffff;width:100px;text-align: right;" class="pt-0 pb-0">Existencia
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style="cursor:pointer;width:200px;" (click)="clicTabla($event, item)"
                  [id]="'row-search' + idUnico + '_'+i" [class]="selectedIndex == i ? 'bg-blue-800 pulse':''"
                  *ngFor="let item of sourceProducts; let i = index;">
                  <td
                    [style]="'width:100px;'+(selectedIndex == i ? 'color:#ffffff;font-weight:bold;':(item.Precio>0?'':'color:red;'))"
                    class="text-truncate"> {{item.Clave}} - {{item.Nombre}}</td>
                  <td
                    [style]="'width:100px;'+(selectedIndex == i ? 'color:#ffffff;font-weight:bold;':(item.Precio>0?'':'color:red;'))"
                    class="text-truncate">{{item.Familia!.Nombre}}</td>
                  <td
                    [style]="'width:100px;'+(selectedIndex == i ? 'color:#ffffff;font-weight:bold;':(item.Precio>0?'':'color:red;'))"
                    class="text-truncate">{{item.Unidad.Nombre}}</td>
                  <td
                    [style]="'width:100px;text-align: right;'+(selectedIndex == i ? 'color:#ffffff;font-weight:bold;':(item.Precio>0?'':'color:red;'))"
                    class="text-truncate">{{item.PrecioMinimo |
                    number:'1.2-6'}}</td>
                  <td
                    [style]="'width:100px;text-align: right;'+(selectedIndex == i ? 'color:#ffffff;font-weight:bold;':(item.Precio>0?'':'color:red;'))"
                    class="text-truncate">{{item.Precio | number:'1.2-6'}}
                  </td>
                  <td
                    [style]="'width:100px;text-align: right;'+(selectedIndex == i ? 'color:#ffffff;font-weight:bold;':(item.Precio>0?'':'color:red;'))"
                    class="text-truncate">{{item.Existencia | number:'1.2-6'}}
                  </td>
                </tr>
              </tbody>
            </table>
          </ng-scrollbar>
        </div>
      </app-panel-base>

      <div class="card border-0 mb-0" style="height: 20px;background-color: var(--bs-app-header-input-bg);">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-6" style="text-align: left;">
              <div class="mb-0 d-md-flex fw-bold">
                <div class="ms-md-0 mt-md-0 mt-0 dropdown-toggle">
                  <a href="#" data-bs-toggle="dropdown" class="text-dark text-decoration-none">Archivos <b
                      class="caret"></b></a>
                  <div class="dropdown-menu">
                    <a class="dropdown-item" (click)="downloadFiles()">Descargar XML y PDF</a>
                    <a class="dropdown-item" (click)="sendEmail()">Envíar por e-mail</a>
                  </div>
                </div>
                <div class="ms-md-4 mt-md-0 mt-2 dropdown-toggle">
                  <a href="#" data-bs-toggle="dropdown" class="text-dark text-decoration-none">Acciones <b
                      class="caret"></b></a>
                  <div class="dropdown-menu">
                    <a class="dropdown-item" (click)="openCopy()">Clonar documentos</a>
                    <!-- <a class="dropdown-item" (click)="cancelarUUID()">Cancelar UUID</a> -->
                    <a class="dropdown-item" (click)="openCfdiRelations()">Relacionar a otro CFDI</a>
                    <a *ngIf="myForm.value.Id > 0" class="dropdown-item" (click)="verEstadoSAT()">Estado en el SAT</a>
                    <a *ngIf="myForm.value.Id > 0" class="dropdown-item" (click)="verUUIDSRelacionados()">UUIDS
                      Relacionados</a>
                    <a class="dropdown-item" (click)="mostrarListasF()">Listas de Precios</a>
                    <a *ngIf="myForm.value.Id==0" class="dropdown-item" (click)="cancelarIVA8()">Cancelar IVA 8%</a>

                  </div>
                </div>
                <!-- <div class="ms-md-4 mt-md-0 mt-2 dropdown-toggle">
                  <a href="#" data-bs-toggle="dropdown" class="text-dark text-decoration-none">Addendas <b
                      class="caret"></b></a>
                  <div class="dropdown-menu">
                    <a class="dropdown-item">Soriana reverse</a>
                    <a class="dropdown-item">Coppel muebles</a>
                    <a class="dropdown-item">Extra</a>
                    <a class="dropdown-item">Femsa</a>
                    <a class="dropdown-item">GNP talleres</a>
                    <a class="dropdown-item">MAPFRE talleres</a>
                    <a class="dropdown-item">Grupo modelo</a>
                    <a class="dropdown-item">Lala</a>
                    <a class="dropdown-item">Lala ariba</a>
                    <a class="dropdown-item">Femsa</a>
                    <a class="dropdown-item">Movistar pedido</a>
                    <a class="dropdown-item">Movistar sin pedido</a>
                    <a class="dropdown-item">OXXO</a>
                    <a class="dropdown-item">Pemex v3</a>
                    <a class="dropdown-item">Pepsico</a>
                    <a class="dropdown-item">Soriana</a>
                  </div>
                </div> -->
                <div class="ms-md-4 mt-md-0 mt-2 dropdown-toggle">
                  <a href="#" data-bs-toggle="dropdown" class="text-dark text-decoration-none">Complementos <b
                      class="caret"></b></a>
                  <div class="dropdown-menu">
                    <a (click)="openCartaPorte()" class="dropdown-item">Carta Porte 3.0</a>
                    <!-- <a class="dropdown-item">Comercio exterior 2.0</a>
                    <a class="dropdown-item">IEDU</a>
                    <a class="dropdown-item">Parciales construcción</a>
                    <a class="dropdown-item">Leyendas fiscales</a> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6" style="text-align: left;">
              <div class="widget-list rounded status-printer" *ngIf="mostrarStatusTicketera">
                <a class="widget-list-item">
                  <div class="widget-list-media icon">
                    <i class="fa fa-print {{statusImpresoraColor}} text-white"></i>
                  </div>
                  <div class="widget-list-content">
                    <h4 class="widget-list-title">{{statusImpresora}}</h4>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex">
        <div class="invoice" style="width: 1045px;background-color: var(--bs-app-header-input-bg);">
          <div class="invoice-header">
            <div class="invoice-from">
              <app-panel-base title="Selección de Documento" [style]="'height:290px;'" [itemsMenuDropDown]="menuItems"
                (onClicMenu)="clicMenu($event)">
                <div class="panel-content d-flex flex-column" style="height: 100%;">
                  <div style="height: 80%;">
                    <div class="d-flex">
                      <component-ui-combo-box style="width:15%; margin-right: 5px;"
                        helpLine="Documento a emitir: Factura, Pedido, Cotización, etc."
                        [extras]="'TipoDeComprobante.Clave as TipoComp, Clasificacion.Clave as Clasificacion'"
                        formControlName="DocumentoVenta" [enabled]="!blockCombos" [topLabel]="true" label="Documento"
                        [inputProperty]="'Nombre'" [zeroMask]="3" [setItemsList]="getItemsComps" [tabIndex]="1"
                        [widthLabelColumns]="12" [widthTextColumns]="12"
                        (onSelectedItem)="onSelectedItem($event,'DocumentoVenta')" [widthRightColumns]="0" #cboComp />

                      <component-ui-combo-box style="width:15%; margin-right: 5px;" formControlName="Serie"
                        helpLine="Serie a emitir, depende del Documento seleccionado." [topLabel]="true" label='Serie'
                        [enabled]="!blockCombos" [inputProperty]="'Serie'" [setItemsList]="getItemsSerie" [tabIndex]="2"
                        [widthLabelColumns]="12" [widthTextColumns]="12" [widthRightColumns]="0"
                        (onSelectedItem)="onSelectedItem($event,'Serie')" #cboSerie />

                      <components-ui-number-box style="width:15%;" [topLabel]="true" formControlName="Folio"
                        helpLine="Folio a emitir o Folio a buscar, indique el número de folio para buscar o bien, presione el botón Nuevo de la barra de botones."
                        (blur)="blurFolio($event)" label='Folio' [tabIndex]="3" [widthLabelColumns]="12"
                        [widthTextColumns]="12" />
                    </div>
                    <div class="d-flex mt-1">
                      <div style="width:46%;margin-left:7px;">
                        <components-ui-text-box [tabIndex]="4" (onClicLink)="openCustomerCatalog()"
                          helpLine="Cliente al que se le va a emitir el Documento. Presione F2 para buscar."
                          (blur)="blurCustomer($event)" (onClickIcon)="clicIcon($event)" label="Cliente"
                          [widthLabelColumns]="8" [enableLink]="true" (keydown.enter)="enterCliente()"
                          [widthTextColumns]="4" entidadBusqueda="Cliente" #txtCliente
                          (elementoSeleccionado)="elementoSeleccionado($event)"
                          [enabled]="myForm.value.DocumentoVenta?.Id > 0 && !esTicket" />
                      </div>
                      <div *ngIf="myForm.value.Cliente?.Id>0" style="width:53%;text-align: left;margin: auto;">
                        <div class="fs-13px lh-12 mb-2px fw-bold text-dark text-truncate">
                          [{{this.myForm.value.Cliente.Rfc | uppercase}}]
                          {{ this.myForm.value.Cliente.Nombre | uppercase}}
                        </div>
                      </div>
                    </div>
                    <div class="d-flex">
                      <div style="width:46%;margin-left:7px;">
                        <component-ui-combo-box inputProperty="Nombre" entityName="Consignado" [tabIndex]="5"
                          helpLine="Seleccione una dirección de entrega para el Cliente seleccionado, aquí también se pueden dar de alta y actualizar las direcciones."
                          [filterQuery]="getFiltroConsignado" (onSelectedItem)="onSelectedItem($event,'Consignado')"
                          (onClicLink)="clicConsignado()" [enableLink]="true" label="Entregar en"
                          [widthLabelColumns]="8" formControlName="Consignado" listProperty="Nombre"
                          [widthTextColumns]="4" [widthRightColumns]="0" #cboConsignado />
                      </div>
                      <div style="width:53%;text-align: left;margin: auto;" *ngIf="myForm.value.Consignado">
                        <div class="fs-13px lh-12 mb-2px fw-bold text-dark text-truncate">{{
                          myForm.value.Consignado.Nombre|
                          uppercase}}
                        </div>
                      </div>
                    </div>

                    <div *ngIf="myForm.value.Cliente?.Id>0" class="list-group-item d-flex border-top-0 mt-0"
                      style="margin-left:230px;">
                      <div class="flex-fill">
                        <div class="col-md-12">
                          <table class="table table-sm" style="border:0; color: #ffffff">
                            <thead>
                              <tr>
                                <td style="width: 20%; color: #ffffff;" class="text-center fw-bolder bg-orange-400">
                                  Tipo</td>
                                <td (click)="openBalanceAnt()" style="width: 20%; color: #ffffff;"
                                  class="text-center fw-bolder bg-orange-400">
                                  Anticipo</td>
                                <td style="width: 20%; color: #ffffff;"
                                  class="text-center fw-bolder bg-{{(myForm.value.Cliente.CreditoSuspendido  ?'red':'orange-400')}}">
                                  Bloqueado
                                </td>
                                <td style="width: 20%; color: #ffffff;" class="text-center fw-bolder bg-orange-400">
                                  Lim Cred
                                </td>
                                <td style="width: 20%; color: #ffffff;" class="text-center fw-bolder bg-orange-400">
                                  Tokens
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              <td class="text-center fw-bolder bg-green-200 text-black-400">{{
                                myForm.value.Cliente.CondicionDePago.Dias ==0 ?"Contado":"Crédito" }}</td>
                              <td style="text-decoration: underline;" (click)="openBalanceAnt()"
                                class="text-center text-blue fw-bolder bg-green-200">
                                ${{anticipoDisponible
                                | number:'1.2'}}</td>
                              <td
                                class="text-center fw-bolder text-black-400 bg-{{(myForm.value.Cliente.CreditoSuspendido  ?'red':'green-200')}}">
                                {{myForm.value.Cliente.CreditoSuspendido ? "SÍ":"NO"}}
                              </td>
                              <td class="text-center fw-bolder text-black-400 bg-green-200">
                                {{myForm.value.Cliente.LimiteCredito |
                                number:'1.2'}}</td>
                              <td class="text-center fw-bolder text-black-400 bg-green-200">
                                {{numeroTokens}}
                              </td>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="esManufactura && myForm.value.Cliente?.Id>0 && !getEsAnticipo"
                    style="position: absolute;top:175px;left:178px;width:573px;">
                    <app-panel-base title="Información para Producción" style="height: 100px;">
                      <div class="panel-content">
                        <components-ui-date-box label="Fecha de Entrega" [tabIndex]="500"
                          helpLine="Fecha de compromiso de entrega del producto final que se acordó con el Cliente."
                          formControlName="FechaCompromisoEntrega" [widthLabelColumns]="3" [widthTextColumns]="6" />
                      </div>
                    </app-panel-base>
                  </div>


                  <div class="row" *ngIf="myForm.value.Cliente?.Id>0">
                    <div class="col">
                      <div class="btn-group-vertical " style="width: 160px; position: absolute;top:146px;">
                        <div tabindex="0" (click)="openDireccionEntrega()" class="btn btn-xs btn-orange "
                          style='margin-bottom: 5px;'> Domicilio Cliente</div>
                        <div tabindex="0" (click)="openPaymentsSearch()" class="btn btn-xs btn-orange "
                          style='margin-bottom: 5px;'> Forma de pago</div>
                        <div (click)="openComments(1)" class="btn  btn-xs btn-orange" style='margin-bottom: 5px;'>
                          Comentarios
                        </div>
                        <div *ngIf="mostrarEntregaNuestra" (click)="openComments(2)" class="btn  btn-xs btn-orange"
                          style='margin-bottom: 5px;'>
                          Comentarios Entrega
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </app-panel-base>
            </div>
            <div class="invoice-date">
              <app-panel-base title="Opciones" [style]="'height:290px;width:250px'">
                <div class="panel-content">
                  <components-ui-date-box label="Emisión" [enabled]="false" [tabIndex]="6"
                    formControlName="FechaEmision"
                    helpLine="Fecha de emisión del Documento, para documentos fiscales puede seleccionar una fecha hasta 72 horas para atrás."
                    [widthLabelColumns]="6" [widthTextColumns]="6" #fechaEmision />

                  <component-ui-combo-box [inputProperty]="'Nombre'" formControlName="Clase" [tabIndex]="7"
                    [entityName]="'ClaseVenta'" [filterQuery]="getFilterClase"
                    helpLine="Clase de Venta, sirve para clasificar el documento emitido."
                    [enabled]="myForm.value.Id == 0"
                    [extras]="'Metodo.Id as metodopago, CondicionPago as condicionpago'"
                    (onSelectedItem)="onSelectedItem($event,'Clase')" label="Clase de venta" [widthTextColumns]="6"
                    [widthLabelColumns]="6" [widthRightColumns]="0" #cboClase />

                  <component-ui-combo-box [inputProperty]="'Nombre'" [tabIndex]="8" formControlName="Vendedor"
                    [enabled]="myForm.value.Id==0"
                    helpLine="Seleccione el Vendedor al que se le asignará este documento, puede servir para dar comisión en un futuro."
                    [entityName]="'Usuario'" label="Vendedor" (onSelectedItem)="onSelectedItem($event,'Vendedor')"
                    [widthTextColumns]="6" [widthLabelColumns]="6" [widthRightColumns]="0" />

                  <component-ui-combo-box [inputProperty]="'Nombre'" (onSelectedItem)="onSelectedItem($event,'Caja')"
                    helpLine="Caja que cobrará este documento." [setItemsList]="getItemsCajas" [tabIndex]="9"
                    formControlName="Caja" [enabled]="listaCajas.length>1 && this.myForm.value.Id == 0" label="Caja"
                    [widthTextColumns]="6" [widthLabelColumns]="6" [widthRightColumns]="0" />
                  <component-ui-combo-box [enabled]="false" formControlName="Condicion" [inputProperty]="'Nombre'"
                    helpLine="Condición de pago acordada con el cliente, Contado, Crédito, etc." [tabIndex]="10"
                    [entityName]="'CondicionPago'" [extras]="'Tipo.Id as TipoId, Dias as Dias'" label="Condición"
                    (onSelectedItem)="onSelectedItem($event,'Condicion')" [widthTextColumns]="6" [widthLabelColumns]="6"
                    [widthRightColumns]="0" />
                  <component-ui-combo-box formControlName="UsoCfdi" [inputProperty]="'Clave'" [tabIndex]="11"
                    [entityName]="'c_UsoCfdi'" label="Uso CFDI" [widthList]="500"
                    (onSelectedItem)="onSelectedItem($event,'UsoCfdi')" [widthTextColumns]="6" [widthLabelColumns]="6"
                    [widthRightColumns]="0" />
                  <components-ui-date-box [tabIndex]="12" [enabled]="false" label="Vencimiento"
                    formControlName="FechaVencimiento" [widthLabelColumns]="6" [widthTextColumns]="6" />

                  <component-ui-check-box [enabled]="myForm.value.Id==0"
                    *ngIf="mostrarEntregaNuestra  && !getEsAnticipo" [tabIndex]="13" [widthLabelColumns]="6"
                    formControlName="EntregaNuestra" [widthTextColumns]="6" [label]="'Entrega nuestra'" />

                  <component-ui-check-box [enabled]="myForm.value.Id==0"
                    *ngIf="mostrarEntregaNuestra && myForm.value?.EntregaNuestra  && !getEsAnticipo" [tabIndex]="14"
                    [widthLabelColumns]="6" formControlName="ArchivarCargaEntrega" [widthTextColumns]="6"
                    [label]="'Archivar entrega'" (onClick)="openArchivar($event)"
                    helpLine="Al Cliente se le va a entregar en otro día que no es el actua, se archiva para que no se acumulen en el módulo de Entregas a Domicilio." />


                  <div class="d-flex">
                    <component-ui-combo-box style="width:50%; margin-right: 2px;" formControlName="Moneda"
                      helpLine="Moneda del documento emitido." [topLabel]="true" label='Moneda'
                      (onSelectedItem)="onSelectedItem($event,'Moneda')" [extras]="'EsExtranjera as EsExtranjera'"
                      [inputProperty]="'Nombre'" entityName="Moneda" [tabIndex]="15" [widthLabelColumns]="12"
                      [widthTextColumns]="12" [widthRightColumns]="0" />

                    <components-ui-number-box style="width:50%;" [enabled]="esMonedaExtranjera" [topLabel]="true"
                      helpLine="Indique el Tipo de Cambio en caso de ser diferente de MXN, si se da de alta el tipo de cambio, aquí aparecerá en automático."
                      formControlName="TipoCambio" label='Tipo Cambio' [tabIndex]="16" [widthLabelColumns]="12"
                      [widthTextColumns]="12" />
                  </div>
                </div>
              </app-panel-base>
            </div>
          </div>
          <div class="fact-totales">
            <div class="invoice-detail" [id]="'divFacturacion' + idUnico">
              <app-panel-base title="Conceptos" [style]="'width:1020px;'">
                <div class="panel-content">
                  <ng-scrollbar cdkVirtualScrollViewport style="height: 300px;border-bottom: 1px solid #AAAAAA;">
                    <table class="blueTable">
                      <thead style="position: sticky;top:0;">
                        <tr>
                          <th style="width: 50px;"></th>
                          <th style="width: 80px;">Producto</th>
                          <th style="width: 230px;">Descripción</th>
                          <th style="width: 70px;min-width: 70px;max-width: 70px;text-align:right;">Cantidad</th>
                          <th style="width: 80px;">Unidad</th>
                          <th style="width: 100px;">Conversión</th>
                          <th style="width: 80px;text-align:right;">Precio</th>
                          <th style="width: 40px;text-align:right;">Desc</th>
                          <th style="width: 50px;">Exist</th>
                          <th style="width: 80px;">Subtotal</th>
                          <th style="width: 60px;">P/Neto</th>
                        </tr>
                      </thead>
                      <tbody class="bg-light">
                        <tr
                          [style]=" indexEditing == index ? 'background-color:'+(appSettings.appDarkMode ? '#3363b3;':'#ffdbb7;'): ''"
                          class="text-dark" (keydown)="keyDownRow($event, index)"
                          *ngFor="let item of source; let index = index;">
                          <td style="text-align: center;">
                            <i (click)="deleteRow(index)" *ngIf="item.Producto" class="fa fa-rectangle-xmark fa-lg"
                              style="color:red;font-weight: bold; cursor: pointer;"></i>
                          </td>
                          <td>
                            <input [disabled]="loading" (keyup)="buscarProductos($event)"
                              (keydown.escape)="escapeProducto($event)" (keydown.arrowup)="up($event,0)"
                              (keydown.arrowdown)="down($event,0)"
                              [style]="'width: 87px; text-align: right;height: 22px;' + (item.TieneToken?'color:red !important':'')"
                              (keydown)="keyDownProducto($event)" (keydown.enter)="enterProducto($event)"
                              class="grid-editor text-dark" [id]="'txt_'+index+'_0' + idUnico"
                              (focus)="focusProducto(index, $event, item)" (blur)="blurProducto($event)" type="text"
                              [value]="item.Producto ? item.Producto.Clave: ''" />
                          </td>
                          <td [style]="(item.TieneToken?'color:red !important':'')">
                            <span (click)="changueDescription(index, item)" style="margin-left: 3px;"
                              [class]="item.Producto?.ActualizarDescrpcion ? 'alert-link link text-dark':''">{{item.Descripcion}}</span>
                          </td>

                          <td style="width: 70px;min-width:70px;max-width: 70px;"><input *ngIf="item.Producto"
                              [disabled]="loading" (keydown)="keyDownCantidad($event)"
                              (keydown.escape)="escapeCantidad()" (keydown.arrowup)="up($event,2)"
                              (keydown.arrowdown)="down($event,2)" (keydown.enter)="enterCantidad($event)"
                              class="grid-editor text-dark"
                              [style]="'text-align:right;width: 77px;min-width:70px;max-width: 77px;height: 22px;' + (item.TieneToken?'color:red !important':'')"
                              (keydown.f2)="openExistencias()" [id]="'txt_'+index+'_2' + idUnico"
                              (blur)="blurCantidad($event)" (focus)="setIndexEdit(index, $event, item)" type="number"
                              [value]="item.Producto ? item.Cantidad: 0" />
                          </td>
                          <td class="text-dark" [style]="(item.TieneToken?'color:red !important':'')">
                            <span style="margin-left: 3px;"> {{item.UnidadNombre}}</span>
                          </td>
                          <td [style]="(item.TieneToken?'color:red !important':'')">
                            <a *ngIf="item.TextoConversiones" class="alert-link text-dark"
                              (click)="conversionClick(index)"
                              style="cursor:pointer;margin-left: 3px;">{{item.TextoConversiones}} </a>
                          </td>
                          <td>
                            <input (keydown.arrowup)="up($event,5)" (keydown.arrowdown)="down($event,5)"
                              [disabled]="loading" (keydown.escape)="escapePrecio()" *ngIf="item.Producto"
                              (keydown.enter)="enterPrecio($event)" class="grid-editor text-dark"
                              [id]="'txt_'+index+'_5' + idUnico" (blur)="blurPrecio($event)"
                              (focus)="setIndexEdit(index, $event, item)" type="number"
                              [style]="'width:88px;text-align:right;height: 22px;' + (item.TieneToken?'color:red !important':'')"
                              [value]="item.Producto ? item.ValorUnitario: 0" />
                          </td>
                          <td><input (keydown.arrowup)="up($event,6)" (keydown.arrowdown)="down($event,6)"
                              (keydown.escape)="escapeDescuento()"
                              [disabled]="this.configuracion?.NoModificarDescuento || loading" *ngIf="item.Producto"
                              (keydown.enter)="enterDescuento($event)" (blur)="blurDescuento($event)"
                              class="grid-editor text-dark" [id]="'txt_'+index+'_6' + idUnico"
                              (focus)="setIndexEdit(index, $event, item)" type="number"
                              [style]="'width:45px;text-align:right;height: 22px;' + (item.TieneToken?'color:red !important':'')"
                              [value]="item.Producto ? item.DescuentoPorcentaje: 0" />
                          </td>
                          <!-- <td class="text-dark" style="margin-left: 3px;">{{item.NumeroLotePaquete}}</td> -->
                          <td (click)="clicExistencia(index)" class="text-dark"
                            style="text-align: right;cursor: pointer !important;">
                            <span
                              [style]="'text-decoration: underline;margin-left: 3px;margin-right: 3px;width:100%;' + (item.TieneToken?'color:red !important':'')">{{item.Producto
                              ? ( item.Existencia |
                              number:'1.2-6'):
                              ''}}</span>
                          </td>
                          <td class="text-dark"
                            [style]="'font-weight: 700; text-align: right;padding-right: 5px;' + (item.TieneToken?'color:red !important':'')">
                            {{ item.Total>0
                            ? ( (item.Importe).toFixed(2) |
                            number:'1.2-6'):''}}</td>
                          <td class="text-dark"
                            [style]="'font-weight: 700; text-align: right;padding-right: 5px;' + (item.TieneToken?'color:red !important':'')">
                            {{ item.Total>0
                            ? ( (item.Total / item.Cantidad).toFixed(2) |
                            number:'1.2-6'):''}}</td>
                        </tr>

                      </tbody>
                    </table>
                  </ng-scrollbar>

                  <!-- <gui-grid [rowStyle]="rowStyle" [rowDetail]="rowDetail" [autoResizeWidth]="false"
                    [loading]="showDescription||loading" [width]="950" [source]="source" [maxHeight]="150"
                    [rowHeight]="20" [virtualScroll]="true" [rowSelection]="false" [theme]="'fabric'"
                    (sourceEdited)="sourceEdited($event)" (cellEditSubmitted)="cellEditSubmitted()"
                    (cellEditEntered)="cellEditEntered($event)" [cellEditing]="cellEditing" [maxHeight]="190">
                    <gui-grid-column index-column="0" [cellEditing]="false" header="" [width]="30"
                      [align]="GuiColumnAlign.CENTER">
                      <ng-template let-item="item" let-index="index">
                        <i (click)="deleteRow(index)" *ngIf="item.Producto?.Id>0" class="fa fa-rectangle-xmark"
                          style="color:red;font-weight: bold; cursor: pointer;"></i>
                      </ng-template>
                    </gui-grid-column>
                    <gui-grid-column index-column="1" header="Producto" [width]="110" [field]="'NoIdentificacion'"
                      [type]="'string'">
                    </gui-grid-column>
                    <gui-grid-column header="Descripción" [width]="205" [field]="'Descripcion'" [cellEditing]="false">
                      <ng-template let-item="item" let-index="index">
                        <span (click)="changueDescription(index, item)"
                          [class]="item.Producto?.ActualizarDescrpcion ? 'alert-link link':''">{{item.Descripcion}}</span>
                      </ng-template>
                    </gui-grid-column>
                    <gui-grid-column header="Cantidad" [width]="80" [field]="'Cantidad'" [type]="'number'"
                      [align]="GuiColumnAlign.RIGHT">
                      <ng-template let-value="value">
                        {{value > 0 ? (value | number:'1.2-6'): ''}}
                      </ng-template>
                    </gui-grid-column>
                    <gui-grid-column header="Unidad" [width]="100" [field]="'UnidadNombre'"
                      (formatter)="cellFormatter($event,'unidad')" [cellEditing]="false" />
                    <gui-grid-column header="Conversión" [width]="120" [cellEditing]="false"
                      [field]="'TextoConversiones'" [type]="'number'" [align]="GuiColumnAlign.CENTER">
                      <ng-template let-value="value" let-index="index">
                        <a *ngIf="value" class="alert-link text-blue" (click)="conversionClick(index)"
                          style="cursor:pointer;">{{value}} </a>
                      </ng-template>
                    </gui-grid-column>
                    <gui-grid-column header="Precio" [width]="90" [field]="'ValorUnitario'" [view]="GuiCellView.ITALIC"
                      [type]="'number'" [align]="GuiColumnAlign.RIGHT">
                      <ng-template let-value="value">
                        {{value > 0 ? (value | number:'1.2-6'): ''}}
                      </ng-template>
                    </gui-grid-column>
                    <gui-grid-column header="Descto" [width]="70" [field]="'DescuentoPorcentaje'" [type]="'number'"
                      [align]="GuiColumnAlign.RIGHT">
                      <ng-template let-value="value" let-item="item">
                        {{value > 0 ? (value | number:'1.2-6'): ( item.Producto?.Id>0?'0.00': '')}}
                      </ng-template>
                    </gui-grid-column>
                    <gui-grid-column header="Paquete/Lote" [field]="'NumeroLotePaquete'" [width]="200"
                      [align]="GuiColumnAlign.CENTER" [cellEditing]="false">
                    </gui-grid-column>
                    <gui-grid-column header="Subtotal" [width]="100" [field]="'Importe'" [align]="GuiColumnAlign.RIGHT"
                      [cellEditing]="false">
                      <ng-template let-value="value">
                        {{value > 0 ? (value | number:'1.2-6'): ''}}
                      </ng-template>
                    </gui-grid-column>
                    </gui-grid> -->
                  <!--
                    <gui-grid-column header="IVA" [width]="100" [field]="'TotalIvaTrasladado'"
                      [align]="GuiColumnAlign.RIGHT" [cellEditing]="false">
                      <ng-template let-value="value">
                        {{value > 0 ? (value | number:'1.2-6'): ''}}
                      </ng-template>
                    </gui-grid-column>
                    <gui-grid-column header="Total" [width]="100" [field]="'Total'" [align]="GuiColumnAlign.RIGHT"
                      [cellEditing]="false">
                      <ng-template let-value="value">
                        {{value > 0 ? (value | number:'1.2-6'): ''}}
                      </ng-template>
                    </gui-grid-column> -->

                  <div class="card-body">
                    <table class="table table-borderless table-sm fw-bold m-0">
                      <tbody>
                        <tr>
                          <td class="w-150px"></td>
                          <td></td>
                          <td class="text-end"><a class="text-dark text-decoration-none">Peso Total En Kilos:
                              {{getKilos | number:'1.2-6'}}</a>
                          </td>
                        </tr>
                        <hr>
                        <tr>
                          <td class="w-150px"></td>
                          <td></td>
                          <td class="text-end"><a class="text-dark text-decoration-none">Partidas: {{getPartidas |
                              number}}</a>
                          </td>
                        </tr>
                        <tr>
                          <td class="w-150px"></td>
                          <td></td>
                          <td class="text-end"><a class="text-dark text-decoration-none">Suma Cantidad: {{getCantidad |
                              number:'1.2-6'}}</a></td>
                        </tr>
                        <tr>
                          <td colspan="3">
                            <hr class="m-0">
                          </td>
                        </tr>
                        <tr>
                          <td class="w-150px">Subtotal</td>
                          <td></td>
                          <td class="text-end">${{myForm.value.SubTotal | number:'1.2'}}</td>
                        </tr>
                        <tr>
                          <td>+ IVA</td>
                          <td></td>
                          <td class="text-end">${{myForm.value.TotalIvaTrasladado | number:'1.2'}}</td>
                        </tr>
                        <tr *ngIf="myForm.value.TotalIvaRetenido>0">
                          <td class="text-danger ">- IVA Retenido</td>
                          <td></td>
                          <td class="text-end text-danger">${{myForm.value.TotalIvaRetenido| number:'1.2'}}</td>
                        </tr>
                        <tr *ngIf="myForm.value.TotalIsrRetenido>0">
                          <td class="text-danger ">- ISR Retenido</td>
                          <td></td>
                          <td class="text-end text-danger">${{myForm.value.TotalIsrRetenido| number:'1.2'}}</td>
                        </tr>
                        <tr>
                          <td colspan="3">
                            <hr class="m-0">
                          </td>
                        </tr>
                        <!-- <tr>
                          <td>Shipping Fee</td>
                          <td>promo code: <u class="text-success">FREESHIPPING</u> $1120.00</td>
                          <td class="text-end">$0.00</td>
                        </tr> -->
                        <tr>
                          <td class="pb-2" colspan="2"><b>Total</b></td>
                          <td class="text-end pb-2 text-decoration-underline bg-success-subtle ">
                            <b>${{myForm.value.Total |
                              number:'1.2-6'}}</b>
                          </td>
                        </tr>
                        <!-- <tr>
                          <td class="w-150px">Total en letra:</td>
                          <td>*** {{totalLetras()}} ***</td>
                          <td class="text-end"><a class="text-primary text-decoration-none"></a></td>
                        </tr> -->
                        <!-- <tr>
                          <td class="pt-2 pb-2" nowrap="">
                            Pagado por el Cliente
                          </td>
                          <td class="pt-2 pb-2">
                            via <a href="#" class="text-primary text-decoration-none">Paypal</a> (#IRU9589320)
                          </td>
                          <td class="pt-2 pb-2 text-end">$3670.80</td>
                        </tr> -->
                      </tbody>
                    </table>
                    <hr *ngIf="myForm.value.Id>0">
                    <div *ngIf="myForm.value.Id>0" class="widget-list mb-0 bg-light"
                      style="margin-left:-15px;width: 950px; display: flex;">
                      <div class="widget-list-item" style="width:25%;">
                        <div class="widget-list-media">
                          <i class="fas fa-user-check fa-2x text-green"></i>
                        </div>
                        <div class="widget-list-content">
                          <h4 class="widget-list-title">{{myForm.value.UsuarioAlta.Nombre}}</h4>
                          <p class="widget-list-desc">Usuario elaboró documento</p>
                        </div>
                      </div>
                      <div *ngIf="myForm.value.UsuarioCancela" class="widget-list-item" style="width:25%;">
                        <div class="widget-list-media">
                          <i class="fas fa-user-xmark fa-2x text-red"></i>
                        </div>
                        <div class="widget-list-content">
                          <h4 class="widget-list-title">{{myForm.value.UsuarioCancela.Nombre}}</h4>
                          <p class="widget-list-desc">Usuario canceló documento</p>
                        </div>
                      </div>
                      <div *ngIf="myForm.value.EnviadoWebTicket" class="widget-list-item" style="width:25%;">
                        <div class="widget-list-media">
                          <i class="fas fa-cloud-arrow-up fa-2x text-green"></i>
                        </div>
                        <div class="widget-list-content">
                          <h4 class="widget-list-title">Enviado a web ticket</h4>
                          <p class="widget-list-desc">El ticket se envió al portal web</p>
                        </div>
                      </div>
                    </div>
                    <hr *ngIf="myForm.value.Observaciones">
                    <div *ngIf="myForm.value.Observaciones" class="widget-list"
                      style="margin-left: -15px;margin-top:4px;width: 900px;padding-bottom: 0px;height: 55px;">
                      <div class="widget-list-item">
                        <div class="widget-list-media">
                          <i class="fas fa-comment fa-2x text-blue"></i>
                        </div>
                        <div class="widget-list-content" style="cursor:pointer;" (click)="openComments(1)">
                          <h4 class="widget-list-title">Comentarios</h4>
                          <p class="widget-list-desc" [innerHtml]="myForm.value.Observaciones"></p>
                        </div>
                      </div>
                    </div>
                    <hr *ngIf="myForm.value.ObservacionesCargaEntrega">
                    <div *ngIf="myForm.value.ObservacionesCargaEntrega" class="widget-list"
                      style="margin-left: -15px;margin-top:4px;width: 900px;padding-bottom: 0px;height: 55px;">
                      <div class="widget-list-item">
                        <div class="widget-list-media">
                          <i class="fas fa-comment fa-2x text-blue"></i>
                        </div>
                        <div class="widget-list-content" style="cursor:pointer;" (click)="openComments(2)">
                          <h4 class="widget-list-title">Comentarios</h4>
                          <p class="widget-list-desc" [innerHtml]="myForm.value.ObservacionesCargaEntrega"></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </app-panel-base>
            </div>
            <!--
          -->
          </div>



        </div>
        <div style="width: 500px;">
          <div style="margin-top:29px;">
            <app-panel-base title="Otros datos">
              <div class="panel-content">
                <component-ui-combo-box
                  helpLine="Vehículo relacionado al documento, puede filtrar reportes por este campo."
                  [label]="'Vehículo'" formControlName="Vehiculo" [zeroMask]="3" [tabIndex]="16" entityName="Unidad"
                  [widthTextColumns]="6" [widthLabelColumns]="5" [widthRightColumns]="0" [inputProperty]="'Nombre'"
                  (onSelectedItem)="onSelectedItem($event,'Vehiculo')" [listProperty]="'ClaveNombre'" />

                <component-ui-combo-box [label]="'Chofer'" [tabIndex]="17" formControlName="Chofer" [zeroMask]="3"
                  helpLine="Chofer relacionado al documento, puede filtrar reportes por este campo." entityName="Chofer"
                  [widthTextColumns]="6" [widthLabelColumns]="5" [widthRightColumns]="0" [inputProperty]="'Nombre'"
                  (onSelectedItem)="onSelectedItem($event,'Chofer')" [listProperty]="'ClaveNombre'" />
                <hr>
                <components-ui-text-box [tabIndex]="18" helpLine="Número de orden de compra del Cliente"
                  [label]="'Orden de Compra'" formControlName="OrdenCompra" [maxLength]="50" [widthTextColumns]="6"
                  [widthLabelColumns]="5" />

                <components-ui-date-box label="Fecha Orden de Compra" [tabIndex]="19"
                  helpLine="Fecha de la Orden de Compra del Cliente." formControlName="OrdenCompraFecha"
                  [widthLabelColumns]="5" (keydown.enter)="enterFechaOrden()" [widthTextColumns]="6" #txtChofer />
              </div>
            </app-panel-base>
            <app-panel-base title="Opciones especiales" *ngIf="myForm.value.EstadoSAT && myForm.value.Id>0">
              <div class="panel-content">
                <div class="d-flex" style="flex-direction: row;">
                  <div class="d-flex" style="width:100%;flex-direction: column;padding: 10px;">
                    <div (click)="openRelaciones()" class="btn btn-sm btn-white mb-10px me-2"><i
                        class="fa fa-book t-plus-1 text-blue-500 fa-fw fa-lg"></i> Relacionados </div>
                    <div *ngIf="getFacturado" class="btn btn-sm btn-white mb-10px me-2"><i
                        class="fa fa-bars t-plus-1 text-red fa-fw fa-lg"></i> Facturado </div>
                    <div *ngIf="myForm.value.EsFacturaGlobal" class="btn btn-sm btn-white mb-10px me-2"><i
                        class="fa fa-bars t-plus-1 text-red fa-fw fa-lg"></i>
                      Factura Global de Tickets </div>

                    <div class="btn btn-sm btn-white mb-10px me-2"><i
                        class="fa fa-flag t-plus-1 text-success fa-fw fa-lg"></i> {{(myForm.value.MetodoPago.Id ==
                      1?"PUE":"PPD")}}</div>

                    <div (click)="clicPrinted(myForm.value.Id)" *ngIf="myForm.value.Impreso"
                      class="btn btn-sm btn-white mb-10px me-2"><i
                        class="fa fa-print t-plus-1 text-success fa-fw fa-lg"></i>
                      Impreso</div>
                    <div *ngIf="myForm.value.EstadoSAT.Id == 1 && !myForm.value.FechaCancelacion"
                      class="btn btn-sm btn-white mb-10px me-2"><i
                        class="fa fa-circle-check t-plus-1 text-success fa-fw fa-lg"></i> Vigente</div>

                    <div (click)="printNC(myForm.value.IdNotaCreditoAnticipo)"
                      *ngIf="myForm.value.IdNotaCreditoAnticipo>0" class="btn btn-sm btn-white mb-10px me-2"><i
                        class="fa fa-arrow-trend-down t-plus-1 text-danger fa-fw fa-lg"></i>NC Anticipo aplicado</div>

                    <div *ngIf="myForm.value.EsNotaCreditoAnticipo" class="btn btn-sm btn-white mb-10px me-2"><i
                        class="fa fa-arrow-trend-down t-plus-1 text-danger fa-fw fa-lg"></i>Es Aplicación anticipo</div>

                    <div (click)="openNotaCredito(null)" *ngIf="myForm.value.FolioOriginaNotaCredito>0"
                      class="btn btn-sm btn-white mb-10px me-2"><i
                        class="fa fa-arrow-trend-down t-plus-1 text-danger fa-fw fa-lg"></i>Documento Origen
                      [{{myForm.value.SerieOriginaNotaCredito + myForm.value.FolioOriginaNotaCredito}}]</div>

                    <div (click)="canCancelSAT()" *ngIf="myForm.value.EstadoSAT.Id == 1 && !getSolicitaCancelar"
                      class="btn btn-sm btn-white mb-10px me-2"><i
                        class="fa fa-calendar-xmark t-plus-1 text-danger fa-fw fa-lg"></i> Cancelar</div>

                    <div
                      *ngIf="getSolicitaCancelar && myForm.value.EstadoSAT.Id == 1 && (!myForm.value.TieneSolicitudCancelacion)"
                      (click)="solicitarCancelacion()" class="btn btn-sm btn-white mb-10px me-2"><i
                        class="fa fa-calendar-xmark t-plus-1 text-danger fa-fw fa-lg"></i> Solicitar Cancelación</div>

                    <div (click)="cambiarVendedor()"
                      *ngIf="myForm.value.EstadoSAT.Id == 1 && myForm.value.Id>0 && this.permisoCambiarVendedor"
                      class="btn btn-sm btn-white mb-10px me-2"><i
                        class="fa fa-user-check t-plus-1 text-primary fa-fw fa-lg"></i> Cambiar Vendedor</div>

                    <div (click)="cambiarCuenta()"
                      *ngIf="myForm.value.EstadoSAT.Id == 1 && myForm.value.Id>0 && this.permisoCambiarCuentaVenta"
                      class="btn btn-sm btn-white mb-10px me-2"><i
                        class="fa fa-box-tissue t-plus-1 text-primary fa-fw fa-lg"></i> Cambiar Cuenta de Venta</div>

                    <div (click)="cambiarEntrega()"
                      *ngIf="myForm.value.EstadoSAT.Id == 1 && myForm.value.Id>0 && this.permisoCambiarEntrega && this.mostrarEntregaNuestra && !esAnticipo()"
                      class="btn btn-sm btn-white mb-10px me-2"><i
                        class="fa fa-car-side t-plus-1 text-primary fa-fw fa-lg"></i> Cambiar Entrega Nuestra</div>

                    <div
                      *ngIf="myForm.value.TieneSolicitudCancelacion && !myForm.value.TieneSolicitudCancelacionAceptada "
                      class="btn btn-sm btn-white mb-10px me-2 pulse">
                      <i class="fa fa-user-clock t-plus-1 text-danger fa-fw fa-lg me-2"></i>Esperando Cancelación
                      Supervisor
                    </div>

                    <div
                      *ngIf="myForm.value.TieneSolicitudCancelacionRechazada && !myForm.value.TieneSolicitudCancelacion"
                      class="btn btn-sm btn-white mb-10px me-2 pulse">
                      <i class="fa fa-comment t-plus-1 text-dark fa-fw fa-lg me-2"></i>Rechazada por:
                      {{myForm.value.ComentarioSolicitudCancelacion}}
                    </div>

                    <span *ngIf="myForm.value.EntregaNuestra" class="badge bg-success text-white  fs-10px pulse">
                      Entrega por nuestra cuenta</span>
                    <div (click)="imprimirOrdenManufactura()" *ngIf="myForm.value.IdOrdenManufactura > 0"
                      class="btn btn-sm btn-white mb-10px me-2"><i class="fa fa-print fa-fw fa-lg"></i> PREorden de
                      Producción
                    </div>
                    <div (click)="ObtenerEnviosPorOrden()" *ngIf="myForm.value.IdOrdenManufactura > 0"
                      class="btn btn-sm btn-white mb-10px me-2"><i class="fa fa-align-justify fa-fw fa-lg"></i> Ordenes
                      Generadas
                    </div>


                    <div *ngIf="myForm.value.TieneTokenCredito" class="btn btn-sm bg-lime-300 text-black mb-10px me-2">
                      <i class="fa fa-lock-open t-plus-1 text-yellow fa-fw fa-lg"></i> Se
                      usó TOKEN Crédito
                    </div>

                    <div *ngIf="myForm.value.EstadoSAT.Id == 2 || myForm.value.EstadoSAT.Id == 3"
                      class="btn btn-sm btn-white mb-10px me-2"><i
                        class="fa fa-circle-check t-plus-1 text-success fa-fw fa-lg"></i> Cancelada</div>
                    <div *ngIf="myForm.value.EstadoSAT.Id == 4" class="btn btn-sm btn-white mb-10px me-2"><i
                        class="fa fa-clock t-plus-1 text-danger fa-fw fa-lg"></i> Cancelación Rechazada</div>
                    <div *ngIf="myForm.value.EstadoSAT.Id == 5" class="btn btn-sm btn-white mb-10px me-2"><i
                        class="fa fa-clock t-plus-1 text-danger fa-fw fa-lg"></i> Cancelación en Proceso</div>
                  </div>

                </div>
              </div>
            </app-panel-base>
            <app-panel-base title="Salida por Venta"
              *ngIf="!myForm.value.EsResguardo && !myForm.value.EntregaNuestra && myForm.value.Id > 0 && mostrarEntregaNuestra">
              <div class="panel-content">
                <div class="d-flex" style="flex-direction: row;">
                  <div class="d-flex" style="width:100%;flex-direction: column;padding: 10px;">

                    <div (click)="clicImprimirSalidaPorVenta(myForm.value.Id)"
                      class="btn btn-sm btn-white mb-10px me-2"><i
                        class="fa fa-print t-plus-1 text-success fa-fw fa-lg"></i>
                      Imprimir Salida</div> <!-- <div (click)="canCancelSAT()" *ngIf="myForm.value.EstadoSAT.Id == 1 && !getSolicitaCancelar"
                      class="btn btn-sm btn-white mb-10px me-2"><i
                      class="fa fa-calendar-xmark t-plus-1 text-danger fa-fw fa-lg"></i> Cancelar</div> -->
                  </div>

                </div>
              </div>
            </app-panel-base>
            <app-panel-base title="Resguardo" *ngIf="myForm.value.EsResguardo && myForm.value.Id >0">
              <div class="panel-content">
                <div class="row align-items-center pb-1px">
                  <div class="col-12">
                    <!-- <div class="mb-2px text-truncate">Seguimiento De Remisiones</div>
                    <div class="d-flex align-items-center mb-2px">
                      <div class="flex-grow-1">
                        <div class="progress h-5px rounded-pill bg-white-transparent-1">
                          <div class="progress-bar progress-bar-striped bg-green"
                            [style]="'width: '+prcentajeAvanceResguardo+'%;'"></div>
                        </div>
                      </div>
                      <div class="ms-2 fs-11px w-30px text-center">{{prcentajeAvanceResguardo|number:'1.0-0'}}%</div>
                    </div> -->

                    <div (click)="verSaldoResguardo()" class="btn btn-sm btn-white mb-10px me-2 w-100"><i
                        class="fa fa-align-justify t-plus-1 fa-fw fa-lg"></i>
                      Saldos</div>

                    <div (click)="verHistoricoRemisiones()" class="btn btn-sm btn-white mb-10px me-2 w-100"><i
                        class="fa fa-clock-rotate-left t-plus-1 fa-fw fa-lg"></i>
                      Histórico Time Line</div>

                    <div class="btn btn-sm btn-white mb-10px me-2 w-100"><i
                        class="fa fa-print t-plus-1 fa-fw fa-lg"></i>
                      Contrato</div>
                  </div>
                </div>
              </div>
            </app-panel-base>
            <app-panel-base title="Carta Porte Capturada" *ngIf="myForm.value.CartaPorteId>0">
              <div class="panel-content text-center animate__animated animate__bounceIn">
                <i (click)="openCartaPorte()" class="fas fa-truck-moving text-green fa-10x" style="cursor:pointer;"></i>
              </div>
            </app-panel-base>
          </div>
        </div>
      </div>

      <div class="additional-desc" *ngIf="showDescription" style="box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;">
        <app-panel-base title="Descripción">
          <div class="form-group panel-content">
            <textarea class="form-control text-dark" formControlName="DescripcionAdicional" rows="10"
              #txtDescription></textarea>
            <div style="text-align: right; margin-top: 5px;">
              <button (click)="acceptDescription()" class="btn btn-success" style="margin-right: 5px;">Aceptar</button>
              <button (click)="cancelDescription()" class="btn btn-danger">Cancelar</button>
            </div>
          </div>
        </app-panel-base>
      </div>

      <div class="cancelled-header animate__animated animate__bounceInDown" *ngIf="myForm.value.FechaCancelacion">
        <div class="cancelled">&nbsp;</div>
        <div class="cancelled-detail">{{getCancelDate}}</div>
        <div class="cancelled-detail">{{ myForm.value.MotivoCancelacion | uppercase}}</div>
      </div>

    </form>
  </div>


  <ng-template #modalRelacionados let-modal let-c="close">
    <div class="card text-center border-0" cdkDrag
      style="width: 800px;box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;">
      <div style="cursor: move;" cdkDragHandle class="card-header fw-bold">UUIDS Relacionados</div>
      <div class="card-body bg-light">
        <app-panel-base [camelCase]="false"
          title="UUIDS Padres (Son donde este documento está el el nodo CfdiRelacionados o en el nodo DoctoRelacionado del XML)">
          <div class="panel-content">
            <table class="blueTable" style="width:530px;">
              <thead style="position: sticky;top:0;">
                <tr>
                  <th style="width: 50;text-align:right;"></th>
                  <th style="width: 300px;">UUID</th>
                  <th style="width: 90px;text-align:center;">Serie</th>
                  <th style="width: 90px;text-align:center;">Folio</th>
                </tr>
              </thead>
              <tbody class="bg-light">
                <tr *ngFor="let item of uuidsRelacionadosPadres; let index = index;">
                  <td style="text-align:center;"><a (click)="imprimirRelacionado(item)" ngbTooltip="Imprimir"
                      class="btn btn-primary btn-icon btn-circle btn-sm"><i class="fa fa-print"></i></a>
                  </td>
                  <td style="padding-left: 5px;"> {{item.uuid}} </td>
                  <td style="padding-left: 5px;text-align:center;"> {{item.serie}} </td>
                  <td style="padding-left: 5px;text-align:center;"> {{item.folio}} </td>
                </tr>
              </tbody>
            </table>
          </div>
        </app-panel-base>

        <app-panel-base [camelCase]="false"
          title="UUIDS HIJOS (Son los UUID que este documento tiene en el nodo CfdiRelacionados o en el nodo DoctoRelacionado del XML)">
          <div class="panel-content">
            <table class="blueTable" style="width:530px;">
              <thead style="position: sticky;top:0;">
                <tr>
                  <th style="width: 50;text-align:right;"></th>
                  <th style="width: 300px;">UUID</th>
                  <th style="width: 90px;text-align:center;">Serie</th>
                  <th style="width: 90px;text-align:center;">Folio</th>
                </tr>
              </thead>
              <tbody class="bg-light">
                <tr *ngFor="let item of uuidsRelacionadosHijos; let index = index;">
                  <td style="text-align:center;"><a (click)="imprimirRelacionado(item)" ngbTooltip="Imprimir"
                      class="btn btn-primary btn-icon btn-circle btn-sm"><i class="fa fa-print"></i></a>
                  </td>
                  <td style="padding-left: 5px;"> {{item.uuid}} </td>
                  <td style="padding-left: 5px;text-align:center;"> {{item.serie}} </td>
                  <td style="padding-left: 5px;text-align:center;"> {{item.folio}} </td>
                </tr>
              </tbody>
            </table>
          </div>
        </app-panel-base>
        <div style="text-align: right; margin-top: 5px;">

          <button (click)="cerrarRelaciones()" class="btn btn-danger">Cerrar</button>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #ctrlListado>
    <app-popup-listado-ordenes-page [items]="listaOrdenes" [soloConsulta]="true" />
  </ng-template>

  <ng-template #ctrlCambiarVendedor let-modal let-c="close">
    <app-cambiar-vendedor-factura-page />
  </ng-template>

  <ng-template #ctrlCambiarCuenta let-modal let-c="close">
    <app-cambiar-clase-factura-page />
  </ng-template>

  <ng-template #ctrlSaldosResguardo let-modal let-c="close">
    <app-saldo-factura-resguardo-page [facturaId]="this.myForm.value.Id" [lista]="listaSaldosEntregar" />
  </ng-template>

  <ng-template #ctrlHistRemisiones let-modal let-c="close">
    <app-historico-remisiones-page [lista]="listaHistoricoRemisiones" [SerieFolio]="RemisionSerieFolio" />
  </ng-template>

  <ng-template #busquedaProductos let-modal let-c="close">
    <app-data-search titulo="Búsqueda de Producto" [columns]="getProductsColumns" [entityName]="'Producto'"
      [filter]="getFilter" [propertys]="getProductsPropertys" [orders]="'Nombre|asc'" #busquedaProductos />
  </ng-template>

  <ng-template #ctrlHistorico let-modal let-c="close">
    <app-ctrl-historico-cliente-page [nombreCliente]="myForm.value.Cliente.Nombre" [idCliente]="myForm.value.Cliente.Id"
      [idEmpresa]="myForm.value.Empresa.Id" #ctrlHistorico />
  </ng-template>

  <ng-template #ctrlFormulacion let-modal let-c="close">
    <app-formulacion-page [formulacion]="ventaFormulacion" #ctrlFormulacion />
  </ng-template>

  <ng-template #ctrlDisenos let-modal let-c="close">
    <app-ctrl-disenos-produccion-page [idEmpresa]="this.myForm.value.Empresa.Id" [idDisenoBase]="idDisenoBase"
      [idsSeleccionados]="idsDisenosSeleccionados" />
  </ng-template>

  <ng-template #ctrlNotaCredito let-modal let-c="close">
    <app-datos-nota-credito-page [tipoNotaCredito]="myForm.value.TipoNotaCredito" [totalNC]="myForm.value.Total"
      [afecta]="myForm.value.AfectaNotaCredito" [sucursalOriginaNotaCredito]="myForm.value.SucursalOriginaNotaCredito"
      [uUIDOriginaNotaCredito]="myForm.value.UUIDOriginaNotaCredito"
      [tipoRelacionNotaCredito]="myForm.value.TipoRelacionNotaCredito" [idEmpresa]="myForm.value.Empresa.Id"
      [DocumentoVentaOrigina]="myForm.value.DocumentoVentaOrigina" [serie]="myForm.value.SerieOriginaNotaCredito"
      [folio]="myForm.value.FolioOriginaNotaCredito" #ctrlNotaCredito />
  </ng-template>

  <ng-template #ctrlExistencias let-modal let-c="close">
    <app-ctrl-existencias-page #ctrlExistencias
      [manejaPaquetes]="this.source[this.indexEditing].Producto?.ManejaPaquetesLotes!"
      [idProducto]="this.source[this.indexEditing].Producto!.Id" [idEmpresa]="this.myForm.value.Empresa.Id"
      [idSucursal]="this.myForm.value.Sucursal.Id"
      [producto]="this.source[this.indexEditing].Producto!.Clave + ' ' + this.source[this.indexEditing].Producto!.Nombre" />
  </ng-template>

  <ng-template #content let-modal let-c="close">
    <app-data-search titulo="Búsqueda de Clientes" [columns]="getColumns" [entityName]="'Cliente'" [filter]="getFilter"
      [propertys]="getPropertys" [orders]="'Nombre|asc'" #busqueda />
  </ng-template>

  <ng-template #modalCartaPorte let-modal let-c="close">
    <app-complemento-carta-porte-page [CartaPorte]="getCartaPorte" />
  </ng-template>


  <ng-template #modalFormaPago let-modal let-c="close">
    <app-forma-pago-page [formasPago]="getFormasPago" [total]="myForm.value.Total" [config]="this.configuracion"
      [serieFolio]="myForm.value.SerieString + myForm.value.Folio" [idSucursal]="myForm.value.Sucursal.Id"
      [anticipoAplicar]="getAnticipoAplicado" />
  </ng-template>


  <ng-template #modalDireccion let-modal let-c="close">
    <app-direccion-entrega-page [Direccion]="myForm.value.Direccion"
      [activar]="myForm.value.Cliente.Rfc.toUpperCase() == 'XAXX010101000'" />
  </ng-template>

  <ng-template #modalConisgnado let-modal let-c="close">
    <app-consignado-page [idCliente]="myForm.value.Cliente.Id" [nombreCliente]="myForm.value.Cliente.Nombre" />
  </ng-template>

  <ng-template #modalRelaciones let-modal let-c="close">
    <app-venta-relaciones-page [idVenta]="myForm.value.Id" />
  </ng-template>

  <ng-template #modalCancelOptiones let-modal let-c="close">
    <app-opcion-cancelacion-page [idDocto]="getIdDocto" [uuid]="myForm.value.UUID" />
  </ng-template>
  <ng-template #cfdiRelacionado let-modal let-c="close">
    <app-cfdi-relacionado-page [idEmpresa]="myForm.value.Empresa.Id" />
  </ng-template>

  <ng-template #comentarioGlobal let-modal let-c="close">
    <app-comentario-global-page
      [html]="(tipoComentario == 1 ? myForm.value.Observaciones :myForm.value.ObservacionesCargaEntrega)" />
  </ng-template>

  <ng-template #anticiposDisponibles let-modal let-c="close">
    <app-anticipos-disponibles-page [totalAplicar]="myForm.value.Total" [idEmpresa]="myForm.value.Empresa.Id"
      [idCliente]="myForm.value.Cliente.Id" />
  </ng-template>

  <ng-template #copiarComprobante let-modal let-c="close">
    <app-copiar-comprobantes-page [canCopy]="documentosPuedeCopiar" [empresa]="myForm.value.Empresa" />
  </ng-template>

  <ng-template #ctrlConversiones let-modal let-c="close">
    <app-conversiones-page [items]="conversiones" [unidadFinal]="unidadFinal" />
  </ng-template>
  <ng-template #ctrlCatClientes class="p-3">
    <div style="box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;">
      <app-clientes-page #ctrlCatClientes [esVentana]="true" />
    </div>
  </ng-template>

  <ng-template #modalArchivarFactura let-modal let-c="close">
    <opcion-archivar-carga-entrega />
  </ng-template>

</app-container-base>
