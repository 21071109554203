<app-container-base subTitle="Carga de Estados de Cuenta Para Conciliación" [hasBar]="false" [hasCounter]="false"
  icon=" fas fa-bars-staggered">
  <div class="screen-content">
    <form [formGroup]="myForm" autocomplete="off">
      <div class="d-flex">
        <div style="width:950px;">
          <app-panel-base [title]="'Carga de estados de cuenta para la conciliacion bancaria'" [camelCase]="false">
            <div class="panel-content">
              <div class="d-flex mb-3">
                <a>
                  <i class="fas fa-cog fa-5x"></i>
                </a>
                <div class="ps-3">
                  <div class="email-from text-dark fs-14px mb-3px fw-bold">
                    Configuración de conciliación
                  </div>
                  <div class="mb-3px"><i class="fa fa-clock fa-fw"></i> <span class="text-black">{{titulo}}</span></div>
                </div>
              </div>
              <hr>
              <div class="d-flex">
                <app-panel-base [title]="'Saldos'" style="width: 450px;height:220px;">
                  <div class="panel-content">
                    <h5>{{ seleccionado == 1 ? "ANTERIORES": (seleccionado == 2 ? "NOSOTROS": "ELLOS")}}</h5>
                    <hr>
                    <components-ui-number-box [maxLength]="15" [label]="'Saldo Anterior'" [tabIndex]="1"
                      (blur)="blurSaldoAnterior()" [widthTextColumns]="4" [widthLabelColumns]="3"
                      formControlName="Anterior" />
                    <div class="d-flex" style="flex-direction: row;">
                      <div style="width: 239px;margin-left: 7px;">
                        <components-ui-number-box [maxLength]="15" [label]="'Cargos'" [tabIndex]="2"
                          [widthTextColumns]="7" [widthLabelColumns]="5" formControlName="Cargos" [enabled]="false" />
                      </div>
                      <div style="width: 200px;">
                        <components-ui-number-box [maxLength]="15" [label]="''" separator="" [enabled]="false"
                          [widthTextColumns]="10" [widthLabelColumns]="1" />
                      </div>
                    </div>
                    <div class="d-flex" style="flex-direction: row;">
                      <div style="width: 239px;margin-left: 7px;">
                        <components-ui-number-box [maxLength]="15" [label]="'Abonos'" [tabIndex]="3"
                          [widthTextColumns]="7" [widthLabelColumns]="5" formControlName="Abonos" [enabled]="false" />
                      </div>
                      <div style="width: 200px;">
                        <components-ui-number-box [maxLength]="15" [label]="''" separator="" [enabled]="false"
                          [widthTextColumns]="10" [widthLabelColumns]="1" />
                      </div>
                    </div>

                    <components-ui-number-box [maxLength]="15" [label]="'Saldo Actual'" [tabIndex]="4" [enabled]="false"
                      [widthTextColumns]="4" [widthLabelColumns]="3" formControlName="Actual" />
                  </div>
                </app-panel-base>
                <app-panel-base [title]="'Opciones'" style="width: 450px;margin-left: 20px;height:220px;">
                  <div class="panel-content">
                    <div class="d-flex" style="width:100%;flex-direction: column;padding: 10px;">
                      <div class="btn btn-sm btn-white mb-10px me-2"><i
                          class="fa fa-magnifying-glass t-plus-1 text-blue-500 fa-fw fa-lg"></i> Buscar </div>
                      <div class="btn btn-sm btn-white mb-10px me-2" *ngIf="seleccionado >1" (click)="imprimir()">
                        <i class="fa fa-print t-plus-1 text-blue-500 fa-fw fa-lg"></i>
                        Imprimir
                      </div>
                      <div class="btn btn-sm btn-white mb-10px me-2" (click)="importarArchivo()"
                        *ngIf="seleccionado == 3"><i class="fa fa-file-import t-plus-1 text-blue-500 fa-fw fa-lg"></i>
                        Importar </div>
                      <div class="btn btn-sm btn-white mb-10px me-2" (click)="downloadFileExample()"
                        *ngIf="seleccionado == 3"><i class="fa fa-circle-info t-plus-1 text-blue-500 fa-fw fa-lg"></i>
                        Descargar Ejemplo </div>
                    </div>
                  </div>
                </app-panel-base>
              </div>
            </div>
          </app-panel-base>
          <app-panel-base title="Movimientos">
            <div class="panel-content">
              <div class="row">
                <div class="col-xl-3 col-md-6">
                  <div class="widget widget-stats bg-green-300">
                    <div class="stats-icon"></div>
                    <div class="stats-info">
                      <h4>Cargos por Conciliar Nosotros</h4>
                      <p>{{getConciliarCargosNosotros | number:'1.2'}}</p>
                    </div>
                  </div>
                </div>
                <div class="col-xl-3 col-md-6">
                  <div class="widget widget-stats bg-green-300">
                    <div class="stats-icon"></div>
                    <div class="stats-info">
                      <h4>Abonos por Conciliar Nosotros</h4>
                      <p>{{getConciliarAbonosNosotros | number:'1.2'}}</p>
                    </div>
                  </div>
                </div>
                <div class="col-xl-3 col-md-6">
                  <div class="widget widget-stats bg-info">
                    <div class="stats-icon"></div>
                    <div class="stats-info">
                      <h4>Cargos por Conciliar Ellos</h4>
                      <p>{{getConciliarCargosEllos | number:'1.2'}}</p>
                    </div>
                  </div>
                </div>
                <div class="col-xl-3 col-md-6">
                  <div class="widget widget-stats bg-info">
                    <div class="stats-icon"></div>
                    <div class="stats-info">
                      <h4>Abonos Por Conciliar Ellos</h4>
                      <p>{{getConciliarAbonosEllos | number:'1.2'}}</p>
                    </div>
                  </div>
                </div>
              </div>
              <ul class="nav nav-tabs m-2" role="tablist">
                <!-- <li class="nav-item" role="presentation">
                  <a (click)="seleccionar(1)" href="#edoctaconc-tab-1" data-bs-toggle="tab" class="nav-link active"
                    aria-selected="true" role="tab">
                    <span class="d-sm-block d-none">
                      <i class="fa fa-clock fa-fw me-1"></i>ANTERIORES
                    </span>
                  </a>
                </li> -->
                <li class="nav-item" role="presentation">
                  <a (click)="seleccionar(2)" href="#edoctaconc-tab-2" data-bs-toggle="tab" class="nav-link active"
                    aria-selected="false" role="tab">
                    <span class="d-sm-block d-none"><i class="fa fa-users fa-fw me-1"></i>NOSOTROS</span>
                  </a>
                </li>
                <li class="nav-item" role="presentation">
                  <a (click)="seleccionar(3)" href="#edoctaconc-tab-3" data-bs-toggle="tab" class="nav-link"
                    aria-selected="false" role="tab">
                    <span class="d-sm-block d-none "><i class="fa fa-user-tie fa-fw me-1"></i>ELLOS</span>
                  </a>
                </li>
              </ul>

              <div class="tab-content panel rounded-0 me-2 ms-2 mt-0">
                <!-- <div id="edoctaconc-tab-1" class="tab-pane fade active show" role="tabpanel">
                  <ng-scrollbar style="height: 250px; border-bottom: 1px solid #AAAAAA;">
                    <table class="blueTable" style="width:690px;">
                      <thead style="position: sticky;top:0;">
                        <tr>
                          <th style="width: 50px;">Póliza</th>
                          <th style="width: 70px;">Fecha</th>
                          <th style="width: 100px;">Referencia</th>
                          <th style="width: 200px;">Concepto</th>
                          <th style="width: 100px;text-align:right;">Cargo</th>
                          <th style="width: 100px;text-align:right;">Abono</th>
                          <th style="width: 70px;">Conciliado</th>
                        </tr>
                      </thead>
                      <tbody class="bg-light" style="font-family: math;">
                        <tr *ngFor="let item of listaAnteriores">
                          <td class="text-center" style="width:50px;"> {{ item.tipoNumeroPoliza| uppercase }}</td>
                          <td class="text-center" style="width:70px;"> {{ item.fecha | date:'dd/MM/yyyy' }}</td>
                          <td style="width:100px;"> {{ item.referencia| uppercase }}</td>
                          <td style="width:200px;"> {{ item.concepto | uppercase }}</td>
                          <td class="text-end" style="width:100px;"> {{ item.importeCargo | number:'1.2-6' }}</td>
                          <td class="text-end" style="width:100px;"> {{ item.importeAbono | number:'1.2-6' }}</td>
                          <td class="text-center" style="width:70px;"> {{ item.conciliado ? 'Si':'No' }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </ng-scrollbar>
                </div> -->
                <div id="edoctaconc-tab-2" class="tab-pane fade active show" role="tabpanel">
                  <ng-scrollbar style="height: 250px; border-bottom: 1px solid #AAAAAA;">
                    <table class="blueTable" style="width:790px;">
                      <thead style="position: sticky;top:0;">
                        <tr>
                          <th
                            [style]="'cursor: pointer;width:55px !important;'+((lastOrderNosotros=='tipoNumeroPoliza'?'background-color:orange;color:#ffffff;':''))"
                            (click)="ordenarNosotros('tipoNumeroPoliza')" class="px-10px">Póliza
                            <i *ngIf="lastOrderNosotros=='tipoNumeroPoliza'"
                              [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAscNosotros=='asc'?'down':'up')+'-wide-short float-end'"></i>
                          </th>
                          <th
                            [style]="'cursor: pointer;width:70px !important;'+((lastOrderNosotros=='fecha'?'background-color:orange;color:#ffffff;':''))"
                            (click)="ordenarNosotros('fecha')" class="px-10px">Fecha
                            <i *ngIf="lastOrderNosotros=='fecha'"
                              [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAscNosotros=='asc'?'down':'up')+'-wide-short float-end'"></i>
                          </th>
                          <th
                            [style]="'cursor: pointer;width:100px !important;'+((lastOrderNosotros=='referencia'?'background-color:orange;color:#ffffff;':''))"
                            (click)="ordenarNosotros('referencia')" class="px-10px">Referencia
                            <i *ngIf="lastOrderNosotros=='referencia'"
                              [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAscNosotros=='asc'?'down':'up')+'-wide-short float-end'"></i>
                          </th>
                          <th
                            [style]="'cursor: pointer;width:300px !important;'+((lastOrderNosotros=='concepto'?'background-color:orange;color:#ffffff;':''))"
                            (click)="ordenarNosotros('concepto')" class="px-10px">Concepto
                            <i *ngIf="lastOrderNosotros=='concepto'"
                              [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAscNosotros=='asc'?'down':'up')+'-wide-short float-end'"></i>
                          </th>
                          <th
                            [style]="'cursor: pointer;width:100px !important;'+((lastOrderNosotros=='importeCargo'?'background-color:orange;color:#ffffff;':''))"
                            (click)="ordenarNosotros('importeCargo')" class="px-10px">Cargo
                            <i *ngIf="lastOrderNosotros=='importeCargo'"
                              [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAscNosotros=='asc'?'down':'up')+'-wide-short float-end'"></i>
                          </th>
                          <th
                            [style]="'cursor: pointer;width:100px !important;'+((lastOrderNosotros=='importeAbono'?'background-color:orange;color:#ffffff;':''))"
                            (click)="ordenarNosotros('importeAbono')" class="px-10px">Abono
                            <i *ngIf="lastOrderNosotros=='importeAbono'"
                              [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAscNosotros=='asc'?'down':'up')+'-wide-short float-end'"></i>
                          </th>
                          <th
                            [style]="'cursor: pointer;width:80px !important;'+((lastOrderNosotros=='conciliado'?'background-color:orange;color:#ffffff;':''))"
                            (click)="ordenarNosotros('conciliado')" class="px-10px">Conciliado
                            <i *ngIf="lastOrderNosotros=='conciliado'"
                              [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAscNosotros=='asc'?'down':'up')+'-wide-short float-end'"></i>
                          </th>
                        </tr>
                      </thead>
                      <tbody class="bg-light" style="font-family: math;">
                        <tr *ngFor="let item of listaNosotros; let i = index" [id]="'row_nosotros_' + i"
                          [style]="item.conciliado ? 'background-color:#bcf0b5;':''">
                          <td class="text-center" style="width:50px;"> {{ item.tipoNumeroPoliza| uppercase }}</td>
                          <td class="text-center" style="width:70px;"> {{ item.fecha | date:'dd/MM/yyyy' }}</td>
                          <td style="width:100px;"> {{ item.referencia| uppercase }}</td>
                          <td style="width:300px;"> {{ item.concepto | uppercase }}</td>
                          <td class="text-end" style="width:100px;"> {{ item.importeCargo | number:'1.2-6' }}</td>
                          <td class="text-end" style="width:100px;"> {{ item.importeAbono | number:'1.2-6' }}</td>
                          <td class="text-center" style="width:70px;"> {{ item.conciliado ? 'Si':'No' }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </ng-scrollbar>
                </div>
                <div id="edoctaconc-tab-3" class="tab-pane fade" role="tabpanel">
                  <ng-scrollbar style="height: 250px; border-bottom: 1px solid #AAAAAA;">
                    <table class="blueTable" style="width:840px;">
                      <thead style="position: sticky;top:0;">
                        <tr>
                          <th
                            [style]="'cursor: pointer;width:70px !important;'+((lastOrderEllos=='fecha'?'background-color:orange;color:#ffffff;':''))"
                            (click)="ordenarEllos('fecha')" class="px-10px">Fecha
                            <i *ngIf="lastOrderEllos=='fecha'"
                              [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAscEllos=='asc'?'down':'up')+'-wide-short float-end'"></i>
                          </th>
                          <th
                            [style]="'cursor: pointer;width:100px !important;'+((lastOrderEllos=='referencia'?'background-color:orange;color:#ffffff;':''))"
                            (click)="ordenarEllos('referencia')" class="px-10px">Referencia
                            <i *ngIf="lastOrderEllos=='referencia'"
                              [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAscEllos=='asc'?'down':'up')+'-wide-short float-end'"></i>
                          </th>
                          <th
                            [style]="'cursor: pointer;width:300px !important;'+((lastOrderEllos=='concepto'?'background-color:orange;color:#ffffff;':''))"
                            (click)="ordenarEllos('concepto')" class="px-10px">Concepto
                            <i *ngIf="lastOrderEllos=='concepto'"
                              [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAscEllos=='asc'?'down':'up')+'-wide-short float-end'"></i>
                          </th>
                          <th
                            [style]="'cursor: pointer;width:100px !important;'+((lastOrderEllos=='importeCargo'?'background-color:orange;color:#ffffff;':''))"
                            (click)="ordenarEllos('importeCargo')" class="px-10px">Cargo
                            <i *ngIf="lastOrderEllos=='importeCargo'"
                              [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAscEllos=='asc'?'down':'up')+'-wide-short float-end'"></i>
                          </th>
                          <th
                            [style]="'cursor: pointer;width:100px !important;'+((lastOrderEllos=='importeAbono'?'background-color:orange;color:#ffffff;':''))"
                            (click)="ordenarEllos('importeAbono')" class="px-10px">Abono
                            <i *ngIf="lastOrderEllos=='importeAbono'"
                              [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAscEllos=='asc'?'down':'up')+'-wide-short float-end'"></i>
                          </th>
                          <th
                            [style]="'cursor: pointer;width:80px !important;'+((lastOrderEllos=='conciliado'?'background-color:orange;color:#ffffff;':''))"
                            (click)="ordenarEllos('conciliado')" class="px-10px">Conciliado
                            <i *ngIf="lastOrderEllos=='conciliado'"
                              [class]="'fa fa-xs fa-fw mt-2 text-primary fa-arrow-'+(lastOrderAscEllos=='asc'?'down':'up')+'-wide-short float-end'"></i>
                          </th>
                        </tr>
                      </thead>
                      <tbody class="bg-light" style="font-family: math;">
                        <tr *ngFor="let item of listaEllos let i = index" [id]="'row_ellos_' + i"
                          [style]="item.conciliado ? 'background-color:#bcf0b5;':''">
                          <td class="text-center" style="width:70px;"> {{ item.fecha | date:'dd/MM/yyyy' }}</td>
                          <td style="width:150px;"> {{ item.referencia| uppercase }}</td>
                          <td style="width:300px;"> {{ item.concepto | uppercase }}</td>
                          <td class="text-end" style="width:100px;"> {{ item.importeCargo | number:'1.2-6' }}</td>
                          <td class="text-end" style="width:100px;"> {{ item.importeAbono | number:'1.2-6' }}</td>
                          <td class="text-center" style="width:70px;"> {{ item.conciliado ? 'Si':'No' }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </ng-scrollbar>
                </div>
              </div>

            </div>
          </app-panel-base>
        </div>
        <app-panel-base title="Procesos extras" style="width: 240px;margin-left: 5px; height: 500px;">
          <div class="panel-content">
            <div class="d-flex" style="width:100%;flex-direction: column;padding: 10px;">
              <div class="btn btn-sm btn-white mb-10px me-2" (click)="abrirManual()"><i
                  class="fa fa-eye t-plus-1 text-blue-500 fa-fw fa-lg"></i> Conciliar de forma manual </div>
              <div class="btn btn-sm btn-white mb-10px me-2"><i
                  class="fa fa-print t-plus-1 text-blue-500 fa-fw fa-lg"></i> Imprimir resumen de conciliación </div>
              <div class="btn btn-sm btn-white mb-10px me-2" (click)="eliminarConciliacion()"><i
                  class="fa fa-delete-left t-plus-1 text-blue-500 fa-fw fa-lg"></i> Borrar los movimientos del periodo
              </div>
            </div>
          </div>
        </app-panel-base>
      </div>
      <input (change)="onchange($event)" [accept]="'.csv,.CSV'" id="fileUpload" type="file" style="display: none;"
        #txtFile />
    </form>
  </div>
</app-container-base>
