import { Component, ElementRef, Input, OnInit, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { ModalService } from 'src/app/service/modal.service';
import { UtilsService } from 'src/app/service/utils.service';
import { SerieReguardo } from '../../interfaces/serie.interface';
import { Usuario } from 'src/app/configuracion/interfaces/usuario.interface';
import { Empresa } from 'src/app/configuracion/interfaces/empresa.interface';
import { Sucursal } from 'src/app/configuracion/interfaces/sucursal.interface';
import { Cliente } from 'src/app/ventas/interfaces/cliente.interface';
import { ConceptoRemisionResguardo, ConsultaResguardo, RemisionResguardo } from '../../interfaces/remision.interface';
import { ResguardoService } from '../../services/resguardo.servuice';
import { Result, UserLogged } from 'src/app/auth/interfaces';
import { SearchConfiguration } from 'src/app/service/interfaces/data-search.interface';
import { EventsService } from 'src/app/service/events.service';
import { TabsNavService } from 'src/app/components/services/tabs-nav.service';
import Swal from 'sweetalert2';
import { Direccion } from '../../../configuracion/interfaces/direccion.interface';
import { BaseService } from 'src/app/service/base.service';
import { GuiCellEdit, GuiLocalization } from '@generic-ui/ngx-grid';
import * as moment from 'moment';
import { FilterOptions, ReportFilter, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ReportsService } from 'src/app/service/reports.service';
import { ProductoUnidad } from 'src/app/Inventarios/interfaces/producto.interface';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { FacturacionService } from 'src/app/ventas/services/facturacion.service';

@Component({
  selector: 'app-remisiones-resguardos-page',
  templateUrl: './remisiones-resguardos-page.component.html',
  styles: [
  ]
})
export class RemisionesResguardosPageComponent implements OnInit {
  listaSeries: ComboBoxEntity[] = [];
  lista: ConceptoRemisionResguardo[] = [];
  abrirListado: boolean = false;
  info: UserLogged = {} as UserLogged;
  searchConfigurationCliente: SearchConfiguration | null = null;
  usarFoliosPreImpresos: boolean = false;
  folioPreImpresio: number = 0;
  indexEditing: number = -1;
  private eventsService = inject(EventsService);
  private tabsNavService = inject(TabsNavService);
  private baseService = inject(BaseService);
  private fServices = inject(FacturacionService);
  private containerBase = inject(ContainerBaseService);

  //* variables que usa el grid
  esEscapeCantidad: boolean = false;
  esEnterCantidad: boolean = false
  enviarOtroRenglon: boolean = false;
  enviarFocoCantidad: boolean = false;
  idUnico: string = '';
  colCantidad = 2;


  private http = inject(HttpClient);
  private readonly baseUrl: string = environment.baseUrlApi;

  @Input()
  public idRemision: number = 0;
  @ViewChild('ctrlConversiones')
  public ctrlConversiones!: ElementRef<HTMLElement>;
  @ViewChild('modalDireccion')
  public modalDireccion!: ElementRef<HTMLElement>;
  @ViewChild('cboSerie')
  public cboSerie!: ElementRef<any>;
  @ViewChild('txtCliente')
  public txtCliente!: ElementRef<any>;

  @ViewChild('ctrlBusquedaFacturaResguardo')
  public ctrlBusquedaFacturaResguardo!: ElementRef<HTMLElement>;





  @ViewChild('content')
  public ctrlBusqueda!: ElementRef<HTMLElement>;
  @ViewChild('modalCancelacion')
  public modalCancelacion!: ElementRef<HTMLElement>;
  @ViewChild('modalListado')
  public modalListado!: ElementRef<HTMLElement>;

  conversiones: ProductoUnidad[] = [];
  unidadFinal: string = '';

  direccionentrega: Direccion | null = null;
  activeButtons: ActiveButtons = {
    new: true,
    delete: false,
    return: false,
    save: true,
    first: true,
    left: true,
    right: true,
    last: true,
    search: false,
    print: true,
  }

  public myForm: FormGroup = this.fb.group({
    Id: [0],
    Empresa: [{} as Empresa],
    Sucursal: [{} as Sucursal],
    SerieString: [''],
    FechaString: [''],
    Comentarios: [''],
    MotivoCancelacion: [''],
    Serie: [{} as SerieReguardo],
    Folio: [0],
    FechaEmision: [Date],
    FechaCancelacion: [null],
    DireccionEntrega: [{} as Direccion],
    UsuarioElabora: [{} as Usuario],
    UsuarioCancela: [null],
    Cliente: [{} as Cliente],
    EntregaNuestra: [false],
    IdVenta: [0],
    SerieFolioVenta: [''],
    Conceptos: [[] as ConceptoRemisionResguardo[]],
    Baja: [false],
    FolioFactura: [''],
    SerieFactura: 0
  });


  localization: GuiLocalization = {
    'translation': {
      'sourceEmpty': 'No hay elementos para mostrar',
    }
  };



  public filtroRemisiones: ReportFilter =
    {
      ReportHeader: {} as ReportHeader,
      NombreReporte: '',
      Desglose: 'a Detalle',
      TituloVisor: 'Reporte ',
      NombreExcel: 'Reporte de Remisión.xlsx',
      FilterOptions: [
        { Campo: 'rem.Id', Etiqueta: '', Tipo: TypeFilter.number },
      ]
    };




  constructor(private fb: FormBuilder, private cService: ContainerBaseService, private ms: ModalService, private utileService: UtilsService, private rService: ResguardoService) { }

  ngOnInit(): void {
    this.idUnico = String(new Date().getTime() * 10000);
    this.info = this.utileService.getUserLogged();
    this.searchConfigurationCliente = this.ms.GetSearchConfiguration("Cliente", `Empresa.Id = ${this.info.empresa!.numero}`);
    setTimeout(() => {
      this.getSeries();
    }, 150);
  }


  findById(id: number) {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.rService.findById(id).subscribe((r) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      this.setEntity(r);
    })
  }

  getSeries() {
    const txt: any = this.cboSerie;
    txt.tagInput.nativeElement.value = "";
    this.rService.getSeries(this.info.empresa!.numero, this.info.sucursal!.numero).subscribe((lista) => {
      if (lista.length > 0) {
        this.listaSeries = lista;
        if (lista.length == 1) {
          if (this.idRemision == 0) {
            this.getEmptyEntity(this.listaSeries[0].Id);
          } else {
            this.findById(this.idRemision);
          }
        } else {
          this.eventsService.publish('home:isLoading', { isLoading: false });
          setTimeout(() => {
            let ent = this.myForm.value;
            ent.Empresa = { Id: this.info.empresa!.numero };
            ent.Sucursal = { Id: this.info.sucursal!.numero };
            txt.tagInput.nativeElement.value = '';
            this.sourceReset([]);
            txt.tagInput.nativeElement.focus()
            this.myForm.reset(ent);

            if (this.idRemision > 0) {
              this.findById(this.idRemision);
            }

          }, 150);
        }
      } else {
        this.eventsService.publish('home:showAlert', {
          message: 'Primero debe Configurar una Serie de Remisiones.',
          onConfirm: (data: AlertResponse) => {
            this.tabsNavService.closeCurrentTab();
          }
        });
      }
    })


  }

  get getItemsSerie(): ComboBoxEntity[] {
    return this.listaSeries;
  }

  onSelectedItem(entity: any, type: string) {
    switch (type) {
      case "Serie":
        let entrar = true;
        if (this.myForm.value.Serie) {
          if (this.myForm.value.Serie.Id == entity.Id) {
            entrar = false;
          }
        }
        if (entrar) {
          this.getEmptyEntity(entity.Id);
        }
        break;
    }
  }

  deleteRow(index: number) {

    this.eventsService.publish('home:showAlert', {
      message: '¿Desea eliminar el registro?',
      onConfirm: (data: AlertResponse) => {
        if (data.isAccept) {
          this.lista.splice(index, 1);
          this.lista = [...this.lista];
        }
      }
    });
  }

  // * metodos de control de flujo del Grid

  keyDownCantidad(e: any) {
    const noDecimales: boolean = this.lista[this.indexEditing].Producto!.NoDecimalesCantidad;
    if (e.key === '.' && noDecimales) { e.preventDefault(); }
  }

  escapeCantidad(e: any) {
    this.esEscapeCantidad = true;
    let item: ConceptoRemisionResguardo = this.lista[this.indexEditing];
    e.target.value = item.Cantidad ? item.Cantidad : 0;
  }

  focusCantidad(index: number, e: any, item: ConceptoRemisionResguardo) {

    let c = this.getLastItem();
    if (index > 0 && index > (c - 1)) {
      return;
    }
    this.setIndexEdit(index, e, item);
    if (c == 0 && index > 0) {
      e.target.blur();
      return;
    }
    if ((index >= c + 1) && c > 0) {
      e.target.blur();
    }
  }


  enterCantidad(e: any) {
    // if (e.target.value == "" || parseInt(e.target.value) == 0) {
    //   return;
    // }
    this.esEnterCantidad = true;
    let cantidadO: number = this.lista[this.indexEditing].CantidadAnterior;
    let cantidad: number = parseInt(e.target.value);
    // let kilosO: number = this.lista[this.indexEditing].totalKiloOriginal;

    // let cantidadO:number= 0;
    // let cantidad:number= 0;
    // let kilosO: number=0;


    if (cantidad < 0) {
      this.eventsService.publish('home:showAlert', {
        textAccept: "Aceptar",
        cancelButton: false,
        message: `La cantidad no debe ser menor a cero, por favor verifique.`,
        onConfirm: (data: AlertResponse) => {
          //this.sourceGrid[this.indexEditing].cantidad = e.before.cantidad;
          e.target.value = 0;
          this.enviarFocoCantidad = true;
          this.initEditor(this.indexEditing, this.colCantidad);
          return;
        }
      });
      return;
    }

    if (cantidad > cantidadO) {
      this.eventsService.publish('home:showAlert', {
        textAccept: "Aceptar",
        cancelButton: false,
        message: `La cantidad máxima a cargar es de ${cantidadO}, por favor verifique.`,
        onConfirm: (data: AlertResponse) => {
          //this.sourceGrid[this.indexEditing].cantidad = e.before.cantidad;
          this.lista[this.indexEditing].Cantidad = cantidadO;
          e.target.value = String(cantidadO);
          this.enviarFocoCantidad = true;
          this.sendFocus(e);
          return;
        }
      });
      return;
    }

    // if (cantidad != cantidadO) {
    //   if (this.lista[this.indexEditing].totalKilo > 0) {
    //     this.lista[this.indexEditing].totalKilo = kilosO / cantidadO * cantidad;
    //   }
    // }
    let c = this.getLastItem();
    if (this.indexEditing >= (c - 1)) {
      e.target.blur();
      return;
    }
    this.enviarOtroRenglon = true;
    e.target.blur();
  }

  blurCantidad(e: any) {
    e.target.classList.remove("focus-editor-grid");
    if (this.esEscapeCantidad) {
      this.esEscapeCantidad = false;
      this.esEnterCantidad = false;
      return;
    }
    if (e.target.value == "" || parseInt(e.target.value) == 0 && !this.enterCantidad) {
      e.target.classList.remove("focus-editor-grid");
      return;
    }
    const cantidad = parseFloat(e.target.value);
    let item = this.lista[this.indexEditing];
    if (parseFloat(String(item.Cantidad)) == cantidad) {
      if (this.enviarOtroRenglon) {
        this.sendFocus(null);
      }
      return;
    };

    if (!this.esEnterCantidad) {
      if (cantidad < 0) {
        this.eventsService.publish('home:showAlert', {
          textAccept: "Aceptar",
          cancelButton: false,
          message: `La cantidad no debe ser menor a cero, por favor verifique.`,
          onConfirm: (data: AlertResponse) => {
            //this.sourceGrid[this.indexEditing].cantidad = e.before.cantidad;
            e.target.value = 0;
            this.enviarFocoCantidad = true;
            this.initEditor(this.indexEditing, this.colCantidad);
            return;
          }
        });
        return;
      }

      let cantidadO: number = this.lista[this.indexEditing].CantidadAnterior;

      if (cantidad > cantidadO) {
        this.eventsService.publish('home:showAlert', {
          textAccept: "Aceptar",
          cancelButton: false,
          message: `La cantidad máxima a cargar es de ${cantidadO}, por favor verifique.`,
          onConfirm: (data: AlertResponse) => {
            //this.sourceGrid[this.indexEditing].cantidad = e.before.cantidad;
            this.lista[this.indexEditing].Cantidad = cantidadO;
            e.target.value = String(cantidadO);
            this.enviarFocoCantidad = true;
            this.sendFocus(e);
            return;
          }
        });
        return;
      }
    }
    item.Cantidad = cantidad;
    item.CantidadSaldo = item.CantidadAnterior - cantidad;
    this.lista[this.indexEditing] = { ...item };
    this.lista = [...this.lista];
    this.esEnterCantidad = false;
    this.sendFocus(null);
  }

  getLastItem(): number {
    return this.lista.filter(P => P.Producto && P.Cantidad > 0).length;
  }

  initEditor(row: number, col: number) {
    col = col - 1;
    const input = document.getElementById(`txt_${row}_${col}${this.idUnico}`)!;
    if (input) input.focus();
  }



  sendFocus(e: any) {
    setTimeout(() => {
      if (this.enviarFocoCantidad) {
        this.initEditor(this.indexEditing, this.colCantidad);
        this.enviarFocoCantidad = false;
      }
      if (this.enviarOtroRenglon) {
        if (this.indexEditing < this.lista.length - 1) {
          this.indexEditing = this.indexEditing + 1;
          this.initEditor(this.indexEditing, this.colCantidad);
        } else {
          if (e) {
            e.target.classList.remove("focus-editor-grid");
          }
        }
        this.enviarOtroRenglon = false;
      }
    }, 50);
  }

  up(e: any, celda: number) {
    e.preventDefault();
    if (this.indexEditing > 0) {
      e.target.blur();
      setTimeout(() => {
        this.indexEditing = this.indexEditing - 1;
        this.initEditor(this.indexEditing, celda + 1);
      }, 50);
    } else {
      this.initEditor(this.indexEditing, this.colCantidad);
    }
  }


  down(e: any, celda: number) {
    e.preventDefault();
    let c = this.getLastItem();
    if (this.indexEditing < c - 1) {
      this.enviarOtroRenglon = false;
      e.target.blur();
      setTimeout(() => {
        this.indexEditing = this.indexEditing + 1;
        this.initEditor(this.indexEditing, celda + 1);
      }, 50);
    } else {
      if (this.indexEditing + 1 < c + 1) {
        this.enviarOtroRenglon = true;
        e.target.blur();
      }
    }
  }

  setIndexEdit(index: number, e: any, item: ConceptoRemisionResguardo) {
    //todo revisar este codigo y ver como aplica con el nuevo grid
    // if (this.SoloConsulta) {
    //   return;
    // }

    this.indexEditing = index;
    e.target.select();
    e.target.classList.add("focus-editor-grid");
  }

  aceptConversions(e: any) {
    this.conversiones = [];
    this.unidadFinal = '';
    let item = this.lista[this.indexEditing];
    if (e) {
      if (e.cantidad > 0) {
        if (e.cantidad <= (item.CantidadSaldo + item.Cantidad)) {
          item.Conversiones = e.items;
          item.TextoConversiones = e.conversiones;
          item.Cantidad = e.cantidad;

          this.lista[this.indexEditing].CantidadSaldo = this.lista[this.indexEditing].CantidadAnterior - e.cantidad;
          this.lista = [...this.lista];
        } else {
          this.eventsService.publish('home:showAlert', { message: `La cantidad es mayor a la cantidad pendiente por entregar, verifique.`, cancelButton: false });

          item.Conversiones = [];
          item.TextoConversiones = '';
        }
      } else {
        item.Conversiones = [];
        item.TextoConversiones = '';
      }
    } else {
      this.lista = [...this.lista];
    }
  }

  conversionClick(index: number) {
    this.indexEditing = index;
    let item = this.lista[this.indexEditing];
    this.rService.getOtrasUnidades(item.Producto.Id).subscribe((result) => {
      if (result.success) {
        const otrasUnidades = JSON.parse(result.message);
        if (otrasUnidades.length > 0) {
          this.conversiones = otrasUnidades;
          this.unidadFinal = item.Unidad.Nombre;
          this.openConvertions((e: any) => this.aceptConversions(e));
        } else {
          this.eventsService.publish('home:showAlert', { message: `El Producto no tiene otras unidades.`, cancelButton: false });
        }
      }
    })

  }

  openConvertions(cb: any) {
    const b: any = this.ctrlConversiones;
    this.ms.openModal(b, (e: any) => {
      cb && cb(e);
    })
  }

  // openCancel() {
  //   const params = new HttpParams()
  //     .set("id", this.myForm.value.Id)
  //   this.eventsService.publish('home:isLoading', { isLoading: true });
  //   return this.http.get<Result>(`${this.baseUrl}/Inventarios/VerificarPuedeCancelar`, { params }).subscribe((result) => {
  //     this.eventsService.publish('home:isLoading', { isLoading: false });
  //     if (result) {
  //       if (result.success) {
  //         const b: any = this.modalCancelOptiones;
  //         this.ms.openModal(b, (e: any) => {
  //           if (e) {
  //             this.proceedCancel(e)
  //           }
  //         })
  //       } else {
  //         this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
  //       }
  //     }
  //   });
  // }

  get getCancelDate(): string {
    if (this.myForm.value.FechaCancelacion) {
      return moment(this.myForm.value.FechaCancelacion).format("DD/MM/YYYY");
    }
    return "";
  }


  cancelar() {
    const params = new HttpParams()
      .set("id", this.myForm.value.Id)
    this.eventsService.publish('home:isLoading', { isLoading: true });
    return this.http.get<Result>(`${this.baseUrl}/Resguardo/VerificarPuedeCancelar`, { params }).subscribe((result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result) {
        if (result.success) {
          const b: any = this.modalCancelacion;
          this.ms.openModal(b, (e: any) => {
            if (e) {
              this.findById(this.myForm.value.Id);
            }
          }, 'lg')
        } else {
          this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
        }
      }
    });
  }
  onCloseListado(resg: ConsultaResguardo | null) {
    if (resg) {

      if (resg.idFactura) {
        this.myForm.controls["IdVenta"].setValue(resg.idFactura);
      }
      this.myForm.controls["SerieFactura"].setValue(resg?.serie);
      this.myForm.controls["FolioFactura"].setValue(resg?.folio);
      this.myForm.controls["SerieFolioVenta"].setValue(resg?.serie + resg?.folio);
      this.getPendiente(resg?.serie, resg?.folio);
    }
  }

  //getPendiente(idFactura: number) {
  getPendiente(serie: string, folio: number) {
    if (serie.length > 0 && folio > 0) {
      this.eventsService.publish('home:isLoading', { isLoading: true });
      this.rService.getResguardosPendiente(serie, folio, this.myForm.value.Cliente.Id).subscribe((result) => {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        let conceptos = JSON.parse(result.message);
        this.lista = conceptos;
      });
    } else {
      //Son migrados los traemos por empresa,sucursal, serie y folio

    }
  }

  get getCantidad(): number {
    let n = 0;
    this.lista.forEach((item) => { n += parseFloat(String(item.Cantidad)); })
    return n;
  }

  onCloseBusquedaFacturaResguardo(item: any) {


  }



  getResguardos() {
    const ent = this.myForm.value;
    if (ent.Cliente?.Id > 0 && ent.Id == 0 && !this.abrirListado) {
      this.abrirListado = true;
      const b: any = this.modalListado;
      this.ms.openModal(b, (e: any) => {
        this.abrirListado = false;
        if (e) {
          this.onCloseListado(e);
        }
      }, 'lg')

      return;
    }



    if (ent.Id > 0) {
      this.eventsService.publish('home:showAlert', { message: `No puede cambiar la factura a Remisionar, cancele la Remisión y emita otra nueva.`, cancelButton: false });
      return;
    }
    if (ent.Cliente?.Id == 0 || !ent.Cliente) {
      this.eventsService.publish('home:showAlert', { message: `Primero debe de indicar un Cliente.`, cancelButton: false });
      return;
    }

  }

  cellEditing: GuiCellEdit = {
    enabled: true,
    rowEdit: (value: any, item: ConceptoRemisionResguardo, index: number) => {
      this.indexEditing = index;
      return true;
    },
    cellEdit: (value: any, item: any, index: number) => {
      return true;
    }
  }

  sourceEdited(e: any) {
    let elem = this.lista[this.indexEditing];

    if (elem.Conversiones) {
      if (elem.Conversiones.length > 0) {
        let totalEnConversiones = 0;
        elem.Conversiones.forEach((i) => totalEnConversiones += i.CantidadUnidadConcepto);
        if (e.after.Cantidad != totalEnConversiones) {
          elem.Cantidad = e.before.Cantidad;
          elem.CantidadSaldo = elem.CantidadAnterior - elem.Cantidad;
          this.lista[this.indexEditing] = elem;
          this.lista = [...this.lista];
          return;
        }
      } else {
        elem.Conversiones = [];
        elem.TextoConversiones = "";
        this.lista[this.indexEditing] = elem;
        this.lista = [...this.lista];
      }
    } else {
      elem.Conversiones = [];
      elem.TextoConversiones = "";
      this.lista[this.indexEditing] = elem;
      this.lista = [...this.lista];
    }


    if (e.after.Cantidad > e.after.CantidadAnterior) {
      this.lista[this.indexEditing].Cantidad = elem.CantidadAnterior;
      this.lista[this.indexEditing].CantidadSaldo = this.lista[this.indexEditing].CantidadAnterior - this.lista[this.indexEditing].Cantidad;
      this.lista = [...this.lista];
      return;
    }
    //this.lista[this.indexEditing].Cantidad = e.after.cantidad;
    this.lista[this.indexEditing].CantidadSaldo = this.lista[this.indexEditing].CantidadAnterior - this.lista[this.indexEditing].Cantidad;
    this.lista = [...this.lista];
  }

  openCustomerSearch() {
    const b: any = this.ctrlBusqueda;
    this.ms.openModal(b, (e: any) => {
      if (e) {
        this.searchCustomer(e.Clave)
      }
    }, 'lg')
  }

  enterCliente() {
    const txt: any = this.txtCliente;
    this.searchCustomer(txt.tagInput.nativeElement.value);
  }

  blurCustomer(values: any) {
    if (values.after != values.before) {
      this.searchCustomer(values.after);
    }
  }

  searchCustomer(clave: string, buscarFacturas: boolean = true) {
    if (clave) {
      const txt: any = this.txtCliente;
      this.eventsService.publish('home:isLoading', { isLoading: true });
      this.baseService.getClientePorClave(this.myForm.value.Empresa.Id, Number(clave), false).subscribe((cliente) => {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        let ent = this.myForm.value;
        if (cliente) {
          txt.tagInput.nativeElement.value = cliente.Clave;
          this.myForm.controls["Cliente"].setValue(cliente);
          if (cliente.Direccion) {
            this.myForm.controls["DireccionEntrega"].setValue({ ...cliente.Direccion, Id: 0 })
          }
          if (buscarFacturas) {
            setTimeout(() => {
              if (this.myForm.value.Id == 0) {
                this.getResguardos();
              }
            }, 150);
          }
        } else {
          this.limpiarCliente();
          this.eventsService.publish('home:showAlert', { message: `No se encontró el cliente indicado.`, cancelButton: false });
        }
      })
    } else {
      this.limpiarCliente();
    }
  }

  limpiarCliente() {
    const txt: any = this.txtCliente;
    txt.tagInput.nativeElement.value = "";
    this.myForm.controls["Cliente"].setValue(null);
    this.myForm.controls["DireccionEntrega"].setValue(null)
  }


  get getColumns(): any {
    if (this.searchConfigurationCliente) {
      return this.searchConfigurationCliente.columns;
    }
    return [];
  }

  get getPropertys(): string {
    if (this.searchConfigurationCliente) {
      let props = "";
      this.searchConfigurationCliente.propertys.forEach((prop) => {
        props += `${prop.name}|${prop.type},`
      })

      if (props.length > 0) {
        props = props.substring(0, props.length - 1);
        return props;
      }

    }
    return "";
  }

  get getFilter(): any {
    if (this.searchConfigurationCliente) {
      return this.searchConfigurationCliente.filter;
    }
    return "";
  }

  getEmptyEntity(idSerie: number = 0) {
    this.cService.getEmptyEntity("RemisionResguardo").subscribe((ent) => {
      this.initializeEntity(ent, idSerie)
    })
  }

  getNewElement() {
    return { Id: 0, Producto: null, Unidad: null, Descripcion: '', Cantidad: 0, Conversiones: [] };
  }

  sourceReset(conceptos: any = []) {
    this.lista = conceptos;
  }

  initializeEntity(ent: any, idSerie: number = 0) {
    ent.Empresa = { Id: this.info.empresa!.numero };
    ent.Sucursal = { Id: this.info.sucursal!.numero };
    if (idSerie > 0) {
      const serie = this.listaSeries.filter((P) => P.Id == idSerie)[0];
      ent.Serie = { Id: idSerie };
      ent.SerieString = serie.Serie;
      this.usarFoliosPreImpresos = serie.UsarFolioPreImpreso!;
    }
    const txt: any = this.txtCliente;
    txt.tagInput.nativeElement.value = '';
    this.sourceReset([]);
    this.getNextFolio(ent);
  }

  get getIsNew(): boolean {
    return this.myForm.value.Id == 0;
  }

  getNextFolio(ent: RemisionResguardo) {
    this.rService.getNextFolio(ent.Empresa.Id, ent.Sucursal.Id, ent.SerieString).subscribe((folio) => {
      ent.Folio = folio;
      this.getDate(ent);
    })
  }

  getDate(ent: RemisionResguardo) {
    ent.FechaEmision = new Date();
    ent.FechaString = moment(ent.FechaEmision).format("DD/MM/YYYY HH:mm:ss")
    this.eventsService.publish('home:isLoading', { isLoading: false });
    this.myForm.reset(ent);
    this.focusCliente();
  }

  focusCliente() {
    const txt: any = this.txtCliente;
    txt.tagInput.nativeElement.focus()
  }

  blurFolio(values: any) {
    if (values.after != values.before) {
      this.findEntityByParams();
    }
  }

  findEntityByParams(cb: any = null) {
    const ent = this.myForm.value;
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.rService.findEntityByParams(ent.Empresa.Id, ent.Sucursal.Id, ent.SerieString, ent.Folio).subscribe((rem) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      this.setEntity(rem, cb);
    });
  }

  new() {
    const ent: RemisionResguardo = this.myForm.value;
    this.getEmptyEntity(ent.Serie.Id);
    this.sourceReset();
    const txt: any = this.txtCliente;
    txt.tagInput.nativeElement.value = '';
  }

  setEntity(venta: RemisionResguardo, cb: any = null) {
    if (!venta) {
      this.new();
    } else {
      let ent = this.myForm.value;
      ent = { ...venta };
      this.sourceReset(ent.Conceptos);
      this.myForm.reset(ent);
      const txt: any = this.txtCliente;
      txt.tagInput.nativeElement.value = ent.Cliente.Clave;
      if (cb) cb();
    }
  }

  onClicEdit() {
    const ent = this.myForm.value.DireccionEntrega;
    this.direccionentrega = { ...ent };
    const b: any = this.modalDireccion;
    this.ms.openModal(b, (e: any) => {
      if (e) {
        this.aceptarDireccion(e);
      }
    }, 'xl')
  }

  onBuscarFacturasResguardo() {
    const b: any = this.ctrlBusquedaFacturaResguardo;
    this.ms.openModal(b, (e: any) => {
      if (e) {
        const txt: any = this.txtCliente;
        txt.tagInput.nativeElement.value = e.cliente.clave;
        this.containerBase.getEntityById("Cliente", e.cliente.id).subscribe((ent: any) => {
          this.searchCustomer(e.cliente.clave, false);
        });
        this.onCloseListado(e);
      }
    }, 'md')
  }

  aceptarDireccion(ent: any) { this.myForm.controls["DireccionEntrega"].setValue(ent); }
  verificarEntregaNuestra(cb: any) {
    const sel: boolean = this.myForm.value.EntregaNuestra;

    this.eventsService.publish('home:showAlert', {
      textAccept: "Si, Continuar",
      message: sel ? 'Ha seleccionado que SI se entrega por nuestra cuenta, ¿es correcto?' : 'Ha seleccionado que NO se entrega por nuestra cuenta, ¿es correcto?',
      onConfirm: (data: AlertResponse) => {

        if (data.isAccept) {
          cb && cb();
        }
      }
    });
  }



  save() {
    const ent = this.myForm.value;


    if (ent.Id > 0) {
      this.eventsService.publish('home:showAlert', { message: `No puede actualizar una Remisión, puede cancelar y emitir otra de nuevo.`, cancelButton: false });
      return;
    }

    if (!ent.Cliente || ent.Cliente?.Id == 0) {
      this.eventsService.publish('home:showAlert', { message: `Primero debe indicar un Cliente.`, cancelButton: false });
      return;
    }


    // if (ent.IdVenta == 0) {
    //   this.eventsService.publish('home:showAlert', { message: `Seleccione la factura a Remisionar.`, cancelButton: false });
    //   return;
    // }

    if (this.getCantidad == 0) {
      this.eventsService.publish('home:showAlert', { message: `Debe de indicar la cantidad a entregar en los conceptos.`, cancelButton: false });
      return;
    }

    this.verificarEntregaNuestra(() => {

      ent.Conceptos = this.lista;

      const json = JSON.stringify(ent);
      this.eventsService.publish('home:isLoading', { isLoading: true });
      this.cService.saveEntity("RemisionResguardo", json).subscribe((result: Result) => {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        if (result.success) {
          Swal.fire({ position: 'center', icon: 'success', title: 'Se guardó correctamente', showConfirmButton: false, timer: 1000 }).then(() => {
            this.findEntityByParams(() => {
              this.print();
            });
          });
        } else {
          this.eventsService.publish('home:showAlert', { message: result.message, cancelButton: false });
        }
      });
    });
  }


  navigate(type: string) {
    this.findEntityByParamsNavigate(type);
  }

  findEntityByParamsNavigate(type: string) {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    const ent = this.myForm.value;
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.rService.findEntityByParamsNavigate(ent.Empresa.Id, ent.Sucursal.Id, ent.SerieString, ent.Folio, type).subscribe((rem) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      this.setEntity(rem);
    });
  }

  print() {
    let ent = this.myForm.value;
    if (ent.Id == 0) {
      this.eventsService.publish('home:showAlert', { message: "Primero debe de guardar el Documento", cancelButton: false });
      return;
    }

    this.eventsService.publish('home:isLoading', { isLoading: true });
    const params = new HttpParams().set("idRemision", ent.Id);
    this.http.get<Result>(`${this.baseUrl}/Resguardo/ImprimirDocumento`, { params }).subscribe((result) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (result.success) {
        this.downloadPdf(result.message);
      } else {
        this.eventsService.publish('home:showAlert', { message: `${result.message}`, cancelButton: false });
      }
    });
  }

  downloadPdf(base64: string) {
    this.fServices.printDocument(this.fServices.base64ToArrayBuffer(base64));
  }

  abrirBusqueda() {


  }


  onClickBarButton(button: string): void {
    switch (button) {
      case "new": this.new(); break;
      case "save": this.save(); break;
      case "print": this.print(); break;
      case "first": this.navigate(button); break;
      case "left": this.navigate(button); break;
      case "right": this.navigate(button); break;
      case "last": this.navigate(button); break;
    }
  }

}
