import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { ReportFilter, ReportFilterOrderList, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ReportsService } from 'src/app/service/reports.service';
import { UtilsService } from 'src/app/service/utils.service';

@Component({
  selector: 'app-verificador-orden-carga',
  templateUrl: './verificador-facturas-entregadas-a-domicilio.component.html',
})
export class VerificadorFacturasEntregadasADomicilioComponent {

  @ViewChild('txtNombreReporte')
  public txtNombreReporte!: ElementRef;

  private utilsService= inject(UtilsService);
  private fb =  inject(FormBuilder);
  private reportsService = inject(ReportsService);

  menuId: string = "diarioventas";
  filtrosDiarioVentasPredefinidos: ReportFilter = {} as ReportFilter;

  public ordenadoLista: ReportFilterOrderList[] = [
    { Nombre: 'Ninguno', Orden: '[chof.Clave],[cli.Clave],his.Serie,his.fecha,his.folio,his.FolioSalida', Id: 0 },
    { Nombre: 'Empresa-Sucursal', Orden: 'emp.Clave,suc.Clave,[chof.Clave],[cli.Clave],his.Serie,his.folio,his.FolioSalida', Id: 1 }]


    public luegoPorLista: ReportFilterOrderList[] = [
      { Nombre: 'Ninguno', Orden: '', Id: 0 },
      { Nombre: 'Cliente', Orden: 'cli.Clave', Id: 1 },
      { Nombre: 'Chofer', Orden: 'chof.Clave', Id: 1 },

    ]

  //public ordenadoLista: ReportFilterOrderList[] = [{ Nombre: 'Empresa-Sucursal-Tipo Documento', Orden: 'empClave,sucClave,doc.Clave' }]

  public filtrosDiarioVentas: ReportFilter =
    {
      menuId: this.menuId,
      ReportHeader: {} as ReportHeader,
      Desglose: 'a Detalle',
      DesgloseLista: [{ Nombre: 'a Detalle' }],
      OrdenadoLista: this.ordenadoLista,
      LuegoPorLista: this.luegoPorLista,
      Fecha1: new Date(),
      Fecha2: new Date(),
      NombreReporte: '',
      TituloVisor: 'Verificador de Ordenes de carga',
      NombreExcel: 'Verificador de Ordenes de carga.xlsx',
      FilterOptions: [
        { Campo: 'emp.Clave', Etiqueta: 'Empresa', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Empresa" },
        { Campo: 'suc.Clave', Etiqueta: 'Sucursal', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Sucursal" },
        { Campo: 'cli.Clave', Etiqueta: 'Cliente', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Cliente" },
        { Campo: 'veh.Clave', Etiqueta: 'Vehículo', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Unidad" },
        { Campo: 'chof.Clave', Etiqueta: 'Chofer', Tipo: TypeFilter.number, Entidad: "Chofer" },
        { Campo: 'vend.Clave', Etiqueta: 'Vendedor', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Usuario", Filtro: "EsVendedor = true" },
        { Campo: 'vta.seriestring', Etiqueta: 'Serie Factura', Tipo: TypeFilter.text,  },
        { Campo: 'vta.folio', Etiqueta: 'Folio Factura', Tipo: TypeFilter.number,  },
      ]
    };


  public myForm: FormGroup = this.fb.group({
    Fecha1: new Date(),
    Fecha2: new Date(),
    NombreReporte: '',
    Opc1: [false],
    Opc2: [false],
  });

  activeButtons: ActiveButtons = {
    all: false,
    print: true,
  }

  constructor() { }
  ngAfterViewInit(): void {

  }

  ngOnInit(): void {
      let fecha = new Date();
      this.myForm.get('Fecha1')?.setValue(fecha);
      this.myForm.get('NombreReporte')?.setValue('VERIFICADOR DE FACTURAS ENTREGADAS A DOMICILIO ' + this.rangoFechas);
      this.focus('txtNombreReporte');
  }


  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombreReporte;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  onChangeFecha(value: any) {
    this.myForm.get('NombreReporte')?.setValue('VERIFICADOR DE FACTURAS ENTREGADAS A DOMICILIO ' + this.rangoFechas);
  }

  get rangoFechas(): string {
    return this.utilsService.getEtiquetaRangoPorFechas(this.myForm.value.Fecha1, this.myForm.value.Fecha2, 2).toUpperCase();
  }

  incluirTraspasosEntreSUcursales(value: boolean) {
    if (value) {
      this.myForm.get('Opc1')?.setValue(!value);
    }
  }

  changeselec(event: any) {
    this.filtrosDiarioVentas = event;
  }

  clickBoton(event: string) {
    this.filtrosDiarioVentas.ReportHeader = this.myForm.value;
    this.reportsService.printReport(this.filtrosDiarioVentas, '/Ventas/VerificadorFacturasEntregadasDomicilio');
  }

}
