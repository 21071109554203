<div class="tabs-container">
  <ul [class]="'bg-light nav nav-tabs ' + (pagesminimized.length==0 && pages.length>0? ' ':'mb-1')">
    <li class="nav-item">
      <div class="d-flex" style="padding: 5px;margin-left: 5px;margin-top: 14px;">
        <i class="fas fa-circle-xmark text-red fa-lg " (click)="closeAll()"></i>
        <i [class]="'fas fa-'+(pagesminimized.length==0 ? 'minimize ':'maximize')+' text-primary fa-lg'"
          (click)="minimizeAll()" style="margin-left: 5px;"></i>
        <div class="divider"></div>
      </div>
    </li>
    <li class="nav-item" *ngFor="let p of pages; let idx = index" (click)="selectTab(p)">
      <ng-template #tipFav class="w-35px">
        <div>Click para {{p.isFav ? 'quitar de' : 'agregar a'}} favoritos.</div>
      </ng-template>
      <a class="nav-link {{ p.active ? 'active': ''}} {{ p.active ? 'text-dark-500' : 'text-dark-200' }} text-truncate">
        <div placement="top" container="body" [ngbTooltip]="tipFav" [openDelay]="100" [closeDelay]="100"
          style="margin-top: -7px; padding-top: 7px;">
          <img class="icon-star-tab" src="../../../assets/img/tabs-fav/star-icon{{p.isFav ? '-fav' : ''}}.png"
            (click)="guardarFav(p, idx)" />
        </div>
        <span class="d-sm-none">{{p.title}} - {{p.isFav}}</span>
        <span class="d-sm-block d-none text-truncate w-auto"><span *ngIf="altKeyPressed"
            class="text-primary">{{"("+(idx+1)+") "}}</span>{{p.title}}</span>
        <i class="fas fa-circle-xmark {{p.active &&'text-red'}} fa-lg" (click)="closeTab(p, idx)"></i>
      </a>
    </li>
  </ul>

  <div [class]="'tab-content rounded-0 pt-1 m-0 ' + (pagesminimized.length==0 ? 'd-block':'  d-none')">
    <div class="tabs-pantallas tab-pane fade {{p.active ? 'active show' : ''}}" *ngFor="let p of pages; let idx = index"
      id="{{p.url +'-'+ idx}}">
      <ng-container *ngComponentOutlet="p.component; inputs: p.inputs"></ng-container>
    </div>
  </div>
</div>
