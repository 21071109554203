import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { ActiveButtons } from 'src/app/component-ui/interfaces/container-base.interface';
import { ReportFilter, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ReportsService } from 'src/app/service/reports.service';

@Component({
  selector: 'app-verificador-cheques',
  templateUrl: './verificador-cheques.component.html',
  styleUrls: ['./verificador-cheques.component.scss']
})
export class VerificadorChequesComponent {
  @ViewChild('txtNombreReporte')
  public txtNombreReporte!: ElementRef;
  menuId: string = "verificadorcheques";
  private reportsService = inject(ReportsService);
  private frmBuilder = inject(FormBuilder);

  public frmVerificadorCheques: FormGroup = this.frmBuilder.group({
    Fecha1: new Date(),
    Fecha2: new Date(),
    NombreReporte: ['Verificador de Cheques', Validators.required],
    Num1: [1],
    Num2: [1],
    Opc1: [false]
  });

  activeButtons: ActiveButtons = {
    all: false,
    print: true,
  }

  activarDesactivarInputs(itemCombo: any) {
    let enabledInput = itemCombo.Id == 1;
    let filtrosCopy = _.cloneDeep(this.filtrosVerificadorCheques);
    for (const filter of filtrosCopy.FilterOptions!) {
      if (filter.Campo === "det.FacturaProveedor" || filter.Campo === "det.Serie" || filter.Campo === "det.Folio") {
        filter.Enabled = enabledInput;
        filter.Placeholder = enabledInput ? "" : "Filtros disponibles para el contenido A Detalle.";
        filter.Valor = "";
      }
    }
    this.filtrosVerificadorCheques = filtrosCopy;
  }

  contenidoSeleccionado?: ComboBoxEntity;
  pendienteXmlSeleccionado?: ComboBoxEntity;

  listaContenido: any[] = [
    { Id: 1, Nombre: "A Detalle" },
    { Id: 2, Nombre: "A Totales" },
  ];

  listaPendienteXml: any[] = [
    { Id: 1, Nombre: "Si" },
    { Id: 2, Nombre: "No" },
    { Id: 3, Nombre: "Todos" },
  ];

  ngOnInit(): void {
    setTimeout(() => {
      const txt: any = this.txtNombreReporte;
      txt.tagInput.nativeElement.focus();
      this.contenidoSeleccionado = this.listaContenido[0];
      this.pendienteXmlSeleccionado = this.listaPendienteXml[2];
    }, 300);
  }

  get selectContenidoItem(): ComboBoxEntity | null {
    return this.contenidoSeleccionado!;
  }

  get selectpendeinteXmlItem(): ComboBoxEntity | null {
    return this.pendienteXmlSeleccionado!;
  }

  selectedCombo(entity: ComboBoxEntity, field: string) {
    if (field == 'Num1') {
      this.contenidoSeleccionado = entity;
      this.activarDesactivarInputs(entity);
    } else if (field == 'Num2') {
      this.pendienteXmlSeleccionado = entity;
    }
    this.frmVerificadorCheques.controls[field].setValue(entity.Id);
  }

  public filtrosVerificadorCheques: ReportFilter =
    {
      menuId: this.menuId,
      ReportHeader: {
        Fecha1: new Date(),
        Fecha2: new Date(),
        NombreReporte: 'Kardex'
      } as ReportHeader,
      Fecha1: new Date(),
      Fecha2: new Date(),
      NombreReporte: 'Verificador de Cheques',
      TituloVisor: 'Verificador de Cheques',
      NombreExcel: 'VerificadorCheques.xlsx',
      FilterOptions: [
        { Campo: 'emp.Clave', Etiqueta: 'Empresa', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Empresa" },
        { Campo: 'tipo.Clave', Etiqueta: 'Tipo de Pago', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "TipoTransferencia" },
        { Campo: 'prov.Clave', Etiqueta: 'Proveedor', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Proveedor" },
        { Campo: 'cxp.Clave', Etiqueta: 'Concepto', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "ConceptoCxp" },
        { Campo: 'elabora.Clave', Etiqueta: 'Usuario Emite', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Usuario" },
        { Campo: 'cancela.Clave', Etiqueta: 'Usuario Cancela', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Usuario" },
        { Campo: 'det.FacturaProveedor', Etiqueta: 'Factura del Proveedor', Tipo: TypeFilter.text },
        { Campo: 'det.Serie', Etiqueta: 'Serie Comprobante', Tipo: TypeFilter.text },
        { Campo: 'det.Folio', Etiqueta: 'Folio comprobante', Tipo: TypeFilter.text },
      ]
    };


  imprimir(event: any) {
    this.filtrosVerificadorCheques.ReportHeader = this.frmVerificadorCheques.value;
    this.reportsService.printReport(this.filtrosVerificadorCheques, '/Tesoreria/ReporteVerificadorCheques');
  }

  changeselec(event: any) {
    this.filtrosVerificadorCheques = event;
  }

  onClickMonedaNacional(value: any) {
    if (value) {
      this.frmVerificadorCheques.get('Opc1')?.setValue(value);
    }
  }
}
