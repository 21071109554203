import { ComboBoxEntity } from './../../../component-ui/interfaces/combo-text.interface';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild, inject } from '@angular/core';
import { UserLogged } from 'src/app/auth/interfaces';
import { UtilsService } from 'src/app/service/utils.service';
import { FacturacionService } from '../../services/facturacion.service';
import { ConceptosCarga, EntregaNuestra } from '../../interfaces/cargaentrega.interface';
import { GuiCellEdit, GuiCellView, GuiColumnAlign, GuiDataType } from '@generic-ui/ngx-grid';

import { ModalService } from 'src/app/service/modal.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
import { EventsService } from 'src/app/service/events.service';
import { OrdenCargaConcepto } from '../../interfaces/ordencarga.interface';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Item } from '@generic-ui/ngx-grid/core/structure/source/src/api/item/item';



@Component({
  selector: 'ctrl-edicion-carga-entrega',
  templateUrl: './ctrl-edicion-carga-entrega.component.html'
})
export class CtrlEdicionCargaEntregaComponent {

  //* injects
  public utilsService = inject(UtilsService);
  public fService = inject(FacturacionService);
  private mService = inject(ModalService)
  private fb = inject(FormBuilder);
  private eventsService = inject(EventsService)
  private http = inject(HttpClient);

  //* vaiables para el grid
  indexEditing: number = -1;
  sourceGrid: Array<OrdenCargaConcepto> = [];
  esEscapeCantidad: boolean = false;
  enviarOtroRenglon: boolean = false;
  enviarFocoCantidad: boolean = false;
  idUnico: string = '';
  colCantidad= 2;
  private readonly baseUrl: string = environment.baseUrlApi;
  itemGuardar: ConceptosCarga |null= null;



  //*  eventos y parametros del componente
  @Input()
  public factura: ConceptosCarga | null = null;

  @Input()
  public SoloConsulta: boolean = false;

  @Input()
  public tabIndex: number = 0;

  @ViewChild('myButton', { static: false })
  myButton!: ElementRef;


  //*  variables globales del usuario
  info: UserLogged = {} as UserLogged;
  tipoMovimiento: number = 0;  //1.- facturas, 2.- Traspasos, 3.- Remisiones
  idMovto: number = 0;

  public myForm: FormGroup = this.fb.group({
    formaPago: null,
    observaciones:''
  });


  ngAfterViewInit(): void {
    this.info = this.utilsService.getUserLogged();
    if (this.SoloConsulta) {
      this.fService.ObtenerOrdenCargaPorTipo(this.factura!.idOrdenCarga, this.factura?.serie!, this.factura?.folio!).subscribe((result) => {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        if (result) {
          this.factura = result;
          if (result.conceptos.length > 0) {
            this.sourceGridReset(result.conceptos);
          } else {
            this.eventsService.publish('home:showAlert', { message: `No se encontró información del Folio indicado por favor verifique. `, cancelButton: false });
          }
        } else {
          this.eventsService.publish('home:showAlert', { message: `No se encontró información del Folio indicado por favor verifique. `, cancelButton: false });
        }
      })
      this.factura = null;

    } else {
      if (!this.factura?.editado) {
        this.cargarFactura();
      } else {
        if (this.factura?.formaPago) {
          setTimeout(() => {
            this.myForm.reset({ formaPago: this.factura?.formaPago })
            this.eventsService.publish('home:isLoading', { isLoading: false });
          }, 200);
        } else {
          this.eventsService.publish('home:isLoading', { isLoading: false });
        }
      }
    }
  }



  ngOnInit(): void {
    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.info = this.utilsService.getUserLogged();
    this.idUnico = String(new Date().getTime() * 10000);
    this.sourceGridReset(this.factura?.detalleProductos);

    if (this.factura?.idVenta! > 0) {
      this.tipoMovimiento = 1;
    }
    if (this.factura?.idTraspaso! > 0) {
      this.tipoMovimiento = 2;
    }
    if (this.factura?.idRemision! > 0) {
      this.tipoMovimiento = 3;
    }

  }

  //*1.- inicialiacion de la pantalla (carga de datos y grid)
  // initEditor(row: number, col: number) {
  //   const elem: any = this.getElemEditor(row, col);
  //   if (elem) {
  //     setTimeout(() => {
  //       elem.firstElementChild?.click();
  //     }, 250);
  //   }
  // }

  initEditor(row: number, col: number) {
    col = col - 1;
    const input = document.getElementById(`txt_${row}_${col}${this.idUnico}`)!;
    if (input) input.focus();
  }



  //*2.- Control de flujo del grid

  escapeCantidad(e: any) {
    this.esEscapeCantidad = true;
    let item: OrdenCargaConcepto = this.sourceGrid[this.indexEditing];
    e.target.value = item.cantidad ? item.cantidad : 0;
  }


  enterCantidad(e: any) {
    // if (e.target.value == "" || parseInt(e.target.value) == 0) {
    //   return;
    // }
    let cantidadO: number = this.sourceGrid[this.indexEditing].cantidadOriginal;
    let cantidad: number = parseInt(e.target.value);
    let kilosO: number = this.sourceGrid[this.indexEditing].totalKiloOriginal;

    if (cantidad <= 0) {
      this.eventsService.publish('home:showAlert', {
        textAccept: "Aceptar",
        cancelButton: false,
        message: `La cantidad no debe ser menor o gual a cero, por favor verifique.`,
        onConfirm: (data: AlertResponse) => {
          //this.sourceGrid[this.indexEditing].cantidad = e.before.cantidad;
          this.enviarFocoCantidad = true;
          this.initEditor(this.indexEditing, this.colCantidad);
          return;
        }
      });
      return;
    }

    if (cantidad > cantidadO) {
      this.eventsService.publish('home:showAlert', {
        textAccept: "Aceptar",
        cancelButton: false,
        message: `La cantidad máxima a cargar es de ${cantidadO}, por favor verifique.`,
        onConfirm: (data: AlertResponse) => {
          //this.sourceGrid[this.indexEditing].cantidad = e.before.cantidad;
          this.enviarFocoCantidad = true;
          this.sendFocus(e);
          return;
        }
      });
      return;
    }

    // if (cantidad != cantidadO) {
    //   if (this.sourceGrid[this.indexEditing].totalKilo > 0) {
    //     this.sourceGrid[this.indexEditing].totalKilo = kilosO / cantidadO * cantidad;
    //   }
    // }
    let c = this.getLastItem();
    if (this.indexEditing >= (c - 1)) {
      e.target.blur();
      return;
    }
    this.enviarOtroRenglon = true;
    e.target.blur();
  }

  blurCantidad(e: any) {
    e.target.classList.remove("focus-editor-grid");
    if (this.esEscapeCantidad) {
      this.esEscapeCantidad = false;
      return;
    }
    if (e.target.value == "" || parseInt(e.target.value) == 0 && !this.enterCantidad) {
      e.target.classList.remove("focus-editor-grid");
      return;
    }
    const cantidad = parseFloat(e.target.value);
    let item = this.sourceGrid[this.indexEditing];
    if (parseFloat(String(item.cantidad)) == cantidad) {
      if (this.enviarOtroRenglon) {
        this.sendFocus(null);
      }
      return;
    };
    item.cantidad = cantidad;
    item.kilosCarga = (cantidad * item.producto.pesoEnKilos);
    //item.totalKilo = item.totalKiloOriginal - (cantidad * item.producto.pesoEnKilos);
    item.totalKilo = (cantidad * item.producto.pesoEnKilos);
    this.sourceGrid[this.indexEditing] = { ...item };
    this.sourceGrid = [...this.sourceGrid];
    this.sendFocus(null);
  }


  keyDownCantidad(e: any) {
    const noDecimales: boolean = this.sourceGrid[this.indexEditing].producto!.NoDecimalesCantidad;
    if (e.key === '.' && noDecimales) { e.preventDefault(); }
  }


  getLastItem(): number {
    return this.sourceGrid.filter(P => P.producto && P.cantidadOriginal > 0).length;
  }

  sendFocus(e: any) {
    setTimeout(() => {
      if (this.enviarFocoCantidad) {
        this.initEditor(this.indexEditing, this.colCantidad);
        this.enviarFocoCantidad = false;
      }
      if (this.enviarOtroRenglon) {
        if (this.indexEditing < this.sourceGrid.length - 1) {
          this.indexEditing = this.indexEditing + 1;
          this.initEditor(this.indexEditing, this.colCantidad);
        } else {
          if (e) {
            e.target.classList.remove("focus-editor-grid");
          }
        }
        this.enviarOtroRenglon = false;
      }
    }, 50);
  }

  up(e: any, celda: number) {
    e.preventDefault();
    if (this.indexEditing > 0) {
      e.target.blur();
      setTimeout(() => {
        this.indexEditing = this.indexEditing - 1;
        this.initEditor(this.indexEditing, celda + 1);
      }, 50);
    } else {
      this.initEditor(this.indexEditing, this.colCantidad);
    }
  }


  down(e: any, celda: number) {
    e.preventDefault();
    let c = this.getLastItem();
    if (this.indexEditing < c - 1) {
      this.enviarOtroRenglon = false;
      e.target.blur();
      setTimeout(() => {
        this.indexEditing = this.indexEditing + 1;
        this.initEditor(this.indexEditing, celda + 1);
      }, 50);
    } else {
      if (this.indexEditing + 1 < c + 1) {
        this.enviarOtroRenglon = true;
        e.target.blur();
      }
    }
  }

  setIndexEdit(index: number, e: any, item: OrdenCargaConcepto) {
    //todo revisar este codigo y ver como aplica con el nuevo grid
    if (this.SoloConsulta) {
      return;
    }

    this.indexEditing = index;
    e.target.select();
    e.target.classList.add("focus-editor-grid");
  }

  sourceGridReset(detalle: any[] = []) {
    if (!detalle) {
      this.sourceGrid = [];
    } else {
      this.sourceGrid = detalle;
    }

    this.sourceGrid.map(p => {
      if (p.unidadMedida) {
        p.unidadMedidaDescripcion = p.unidadMedida.nombre;
        p.productoNombre = p.producto?.nombre!;
      }
    });
    let totalRegs = 1;
    if (this.sourceGrid.length < 20) {
      totalRegs = 20;
    }
  }
  focusCantidad(index: number, e: any, item: OrdenCargaConcepto) {

    let c = this.getLastItem();
    if (index > 0 && index > (c - 1)) {
      return;
    }
    this.setIndexEdit(index, e, item);
    if (c == 0 && index > 0) {
      e.target.blur();
      return;
    }
    if ((index >= c + 1) && c > 0) {
      e.target.blur();
    }
  }

  selectTipoForma(entity: ComboBoxEntity) {
    this.myForm.controls["formaPago"].setValue(entity);
    this.factura!.idFormaPago = entity.Id;
    this.factura!.formaPago = entity;
  }


  keydownEnter(event: any) {
    this.myButton.nativeElement.focus();
  }

  cargarFactura() {
    this.eventsService.publish('home:isLoading', { isLoading: true });

    this.fService.obtenerPendientesEntregar(this.factura!.idVenta, this.factura!.idTraspaso, this.factura!.idRemision,this.factura!.serie,this.factura!.folio, true).subscribe((lista: ConceptosCarga[]) => {
      this.eventsService.publish('home:isLoading', { isLoading: false });
      if (lista.length == 0) {
        this.eventsService.publish('home:showAlert', { message: `No se encontró información del Folio indicado por favor verifique. `, cancelButton: false });
        return;
      }
      this.factura!.detalleProductos = lista[0].detalleProductos;
      this.myForm.get("observaciones")?.setValue(this.factura?.comentarios);
      //todo
      this.factura!.detalleProductos.forEach(p => {
        p.cantidadOriginal = p.cantidad;
        p.totalKiloOriginal = p.totalKilo;
        p.kilosCarga = p.totalKilo;
        p.facturaId = this.factura?.idVenta + "_" + this.factura?.idTraspaso + "_" + this.factura?.idRemision;
      });

      this.sourceGridReset(this.factura?.detalleProductos);
      this.eventsService.publish('home:isLoading', { isLoading: false });
    })
  }

  focoComentarios(value: any) {
    this.utilsService.setHelpLine("Indique un comentario para el movimiento de Traspaso.");
  }

  closeModal() {
    this.mService.closeModal(null);
    // this.onCloseModal.emit(null);
    // mService
  }

  aceptar() {
    this.itemGuardar = this.factura!;
    this.itemGuardar.comentarios = this.myForm.value.observaciones;
    this.itemGuardar.detalleProductos = this.sourceGrid;
    this.itemGuardar.detalleProductos.map(item=>{
      item.observaciones = this.myForm.value.observaciones;
    });
    this.calcTotales();
    this.itemGuardar.seleccionado = false;
    this.http.post<any>(`${this.baseUrl}/Ventas/ActualizarComentariosOrdenCarga`, this.itemGuardar).subscribe({
      next: result => {
        this.mService.closeModal(this.itemGuardar);
      }
    });
  }

  calcTotales() {
    let kilos: number = 0;
    let cantidad: number = 0;
    this.sourceGrid.forEach(item => {
      kilos += item.kilosCarga;
      cantidad += item.cantidad;
    })
    this.factura!.kilosCarga = kilos;
    this.factura!.kilos = this.factura!.kilos - kilos;
    this.factura!.cantidadTotalCarga = cantidad;
  }

  deleteRow(indx: number) {
    if (this.sourceGrid.length == 1) {
      Swal.fire({ text: `No es posible eliminar todos los productos de la lista, por favor verifique.`, icon: 'error', }).then(() => {
        return;
      });
      return;
    }

    this.sourceGrid.splice(indx, 1);
    this.sourceGrid = [...this.sourceGrid];
  }

}









