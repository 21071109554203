import { Component, ElementRef, inject, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';
import { EventsService } from 'src/app/service/events.service';
import { ModalService } from 'src/app/service/modal.service';

@Component({
  selector: 'transferencias',
  templateUrl: './transferencias.component.html',
  styleUrls: ['./transferencias.component.scss']
})
export class TransferenciasComponent {
  @ViewChild('modalAgregarTransferencia')
  public modalAgregarTransferencia!: ElementRef<HTMLElement>;

  private fb = inject(FormBuilder);
  private modalService = inject(ModalService);
  private eventsService = inject(EventsService);
  @Input()
  transferencias: any[] = [];
  transferenciasClone: any[] = [];
  transferencia: any = {} as any;


  public agregarTransferenciaFrm: FormGroup = this.fb.group({
    Id: [0],
    Banco: [0, [Validators.required]],
    CuentaBancaria: [0, [Validators.required]],
    FechaDeposito: [new Date()],
    ImporteAplicar: [''],
    ImporteOriginal: [''],
    ReferenciaBancaria: [''],
    Referencia: ["", [Validators.required]],
  });

  ngOnInit() {
    this.transferenciasClone = _.cloneDeep(this.transferencias);
    this.sumarImportes();
  }

  close() {
    this.modalService.closeModal(null);
  }

  agregar() {
    this.modalService.openModal(this.modalAgregarTransferencia, () => {
    });
  }

  sumaImportes: number = 0;
  sumarImportes() {
    this.sumaImportes = _.sumBy(this.transferenciasClone, "ImporteAplicar");
  }

  deleteTransferencia(terminal: any) {
    if (terminal.IdVenta > 0) {
      this.eventsService.publish('home:showAlert', {
        message: 'No es posible eliminar la transferencia.',
      });
      return;
    }

    this.eventsService.publish('home:showAlert', {
      message: '¿Desea eliminar el renglón?',
      onConfirm: (r: AlertResponse) => {
        if (r.isAccept) {
          this.transferenciasClone = this.transferenciasClone.filter((r: any) => r.Id !== terminal.Id);
          this.sumarImportes();
        }
      }
    });
  }

  editTransferencia(transferencia: any, index: number) {
    this.modalService.openModal(this.modalAgregarTransferencia, () => { });
    setTimeout(() => {
      this.agregarTransferenciaFrm.reset(transferencia);
      this.transferencia = transferencia;
    }, 0);
  }

  closeListaTransferencias(accept: boolean = true) {
    this.modalService.closeModal(accept ? this.transferenciasClone : this.transferencias);
  }

  onSelectedItem(entity: any, tipo: string) {
    this.agregarTransferenciaFrm.get(tipo)?.setValue(entity);
  }

  accept(isEnterInput: boolean = false, event: any = null) {
    this.transferencia = this.agregarTransferenciaFrm.value;
    if (!this.transferencia || (!this.transferencia.Banco || !this.transferencia.CuentaBancaria || !this.transferencia.FechaDeposito || !this.transferencia.ImporteAplicar || !this.transferencia.ImporteOriginal || !this.transferencia.ReferenciaBancaria || !this.transferencia.Referencia)) {
      this.eventsService.publish('home:showAlert', { message: "Debe indicar todos los campos indicados con asterisco en rojo.", cancelButton: false });
      return;
    }

    if (this.transferencia!.Id) {
      this.transferenciasClone = this.transferenciasClone.map((r: any) => {
        if (r.Id === this.transferencia.Id) {
          return this.transferencia;
        } return r;
      })
    } else {
      this.transferencia = {
        ...this.transferencia,
        Id: 0,
      }
      this.transferenciasClone.push(this.transferencia);
    }
    this.closeAgregarTransferencia();
    this.transferencia = {};
    this.sumarImportes();
  }

  closeAgregarTransferencia() {
    this.modalService.closeModal(null);
    this.agregarTransferenciaFrm.reset();
  }

  onChangeFechasCorte(event: any) {
    this.agregarTransferenciaFrm.get("FechaDeposito")?.setValue(event);
  }
}
