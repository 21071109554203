<app-container-base [hasCounter]="false" icon="fa fa-bars-staggered" [hasBar]="true" [setIsNew]="esNuevo"
  [getLast]="false" subTitle="Captura de Movimientos de Cuentas por Cobrar"
  (onClickBarButton)="onClickBarButton($event)" [activeButtons]="activeButtons">
  <div class="screen-content" style="height: 100%;">
    <form [formGroup]="myForm" autocomplete="off">
      <app-panel-base *ngIf="sourceCliente.length>0 && cords" title="Búsqueda de Clientes"
        style="position: absolute; z-index:9;margin-left:150px;margin-top:80px;width:450px;box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;">
        <div class="panel-content">
          <ng-scrollbar style="height: 400px;">
            <table class="table table-sm table-bordered"
              style="border: 1px solid rgb(164, 164, 164);height: 38px;table-layout: fixed !important;">
              <thead style="position: sticky;top:0;font-weight: 700 !important;background-color: #ff8000;">
                <tr>
                  <th style="font-weight:700;color:#ffffff;width:200px;" class="pt-0 pb-0">Cliente</th>
                </tr>
              </thead>
              <tbody>
                <tr style="cursor:pointer;width:200px;" (click)="clicTablaCliente($event, item)"
                  [id]="'row-search' + idUnico + '_'+i" [class]="selectedIndex == i ? 'bg-blue-800 pulse':''"
                  *ngFor="let item of sourceCliente; let i = index;">
                  <td
                    [style]="'width:100px;'+(selectedIndex == i ? 'color:#ffffff;font-weight:bold;':'')"
                    class="text-truncate"> {{item.clave}} - {{item.nombre}}</td>
                </tr>
              </tbody>
            </table>
          </ng-scrollbar>
        </div>
      </app-panel-base>
      <app-panel-base *ngIf="sourceCuenta.length>0 && cords" title="Búsqueda de Cuentas de cuentas por Cobrar"
        style="position: absolute; z-index:9;margin-left:360px;margin-top:80px;width:450px;box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;">
        <div class="panel-content">
          <ng-scrollbar style="height: 400px;">
            <table class="table table-sm table-bordered"
              style="border: 1px solid rgb(164, 164, 164);height: 38px;table-layout: fixed !important;">
              <thead style="position: sticky;top:0;font-weight: 700 !important;background-color: #ff8000;">
                <tr>
                  <th style="font-weight:700;color:#ffffff;width:200px;" class="pt-0 pb-0">Cuenta</th>
                </tr>
              </thead>
              <tbody>
                <tr style="cursor:pointer;width:200px;" (click)="clicTablaCliente($event, item)"
                  [id]="'row-search' + idUnico + '_'+i" [class]="selectedIndex == i ? 'bg-blue-800 pulse':''"
                  *ngFor="let item of sourceCuenta; let i = index;">
                  <td
                    [style]="'width:100px;'+(selectedIndex == i ? 'color:#ffffff;font-weight:bold;':'')"
                    class="text-truncate"> {{item.Clave}} - {{item.Nombre}}</td>
                </tr>
              </tbody>
            </table>
          </ng-scrollbar>
        </div>
      </app-panel-base>


      <app-panel-base *ngIf="sourceConcepto.length>0 && cords" title="Búsqueda de Conceptos de Cuentas por Cobrar"
        style="position: absolute; z-index:9;margin-left:560px;margin-top:80px;width:450px;box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;">
        <div class="panel-content">
          <ng-scrollbar style="height: 400px;">
            <table class="table table-sm table-bordered"
              style="border: 1px solid rgb(164, 164, 164);height: 38px;table-layout: fixed !important;">
              <thead style="position: sticky;top:0;font-weight: 700 !important;background-color: #ff8000;">
                <tr>
                  <th style="font-weight:700;color:#ffffff;width:200px;" class="pt-0 pb-0">Concepto</th>
                </tr>
              </thead>
              <tbody>
                <tr style="cursor:pointer;width:200px;" (click)="clicTablaCliente($event, item)"
                  [id]="'row-search' + idUnico + '_'+i" [class]="selectedIndex == i ? 'bg-blue-800 pulse':''"
                  *ngFor="let item of sourceConcepto; let i = index;">
                  <td
                    [style]="'width:100px;'+(selectedIndex == i ? 'color:#ffffff;font-weight:bold;':'')"
                    class="text-truncate"> {{item.Clave}} - {{item.Nombre}}</td>
                </tr>
              </tbody>
            </table>
          </ng-scrollbar>
        </div>
      </app-panel-base>
      <div class="d-flex">
        <div
          style="width: 12900px;background-color: var(--bs-app-header-input-bg); padding-right: 0px; padding-top: 0px; padding-left: 0px;">
          <div style="display: flex;width: 1028px">
            <app-panel-base title="Información de la Captura de Movimientos de Cuentas por Cobrar"
              [style]="'height:150px;width:699px'">
              <div class="panel-content d-flex flex-column" style="height: 40%;">
                <div class="mb-3">
                  <div class="d-flex">
                    <components-ui-date-box label="Fecha" [tabIndex]="1" formControlName="Fecha" [widthLabelColumns]="8"
                      style="width:20%; margin-right: 10px;" [widthTextColumns]="6" [topLabel]="true" [tabIndex]="1"
                      [required]="true" [enabled]="getBlockCombos" />

                    <component-ui-combo-box style="width:30%; margin-right: 10px;" formControlName="TipoMovimiento"
                      [topLabel]="true" label="Tipo de movimiento" [inputProperty]="'Nombre'" [zeroMask]="3"
                      [entityName]="'TipoMovimientoCXC'" [tabIndex]="2" [widthLabelColumns]="12" [widthTextColumns]="12"
                      (onSelectedItem)="selectTipoMovto($event)" [widthRightColumns]="0" [required]="true"
                      [enabled]="getBlockCombos" #tipoMovCxc />

                    <components-ui-number-box style="width:10%; margin-right: 10px;" [topLabel]="true"
                      formControlName="Codigo" label='Folio' [tabIndex]="3" [widthLabelColumns]="12" (keydown.tab)="tabFolio($event)"
                      [widthTextColumns]="12" (blur)="blurFolio($event)" (keypress)="onKeyPressFolio($event)"  (keydown.enter)="initEditor(0,1)"
                      #txtFolioMovCXC />
                  </div>
                </div>
              </div>
            </app-panel-base>
            <div class="invoice-date" style="padding-right: 0px; padding-top: 0px; padding-left:1px;">
              <app-panel-base title="Opciones" [style]="'height:150px;width:232px'" *ngIf="this.myForm.value.Id > 0 ">
                <div class="panel-content">
                  <div class="d-flex" style="width:100%;flex-direction: column;">
                    <div *ngIf="!myForm.value.FechaCancelacion && !myForm.value.FechaAplicacion"
                      class="btn btn-sm btn-white mb-10px me-2"><i
                        class="fa fa-circle-check t-plus-1 text-success fa-fw fa-lg"></i> Vigente</div>
                    <div *ngIf="!myForm.value.FechaCancelacion && myForm.value.FechaAplicacion"
                      class="btn btn-sm btn-white mb-10px me-2"><i
                        class="fa fa-circle-check t-plus-1 text-success fa-fw fa-lg"></i> Vigente</div>
                    <div *ngIf="myForm.value.FechaCancelacion" class="btn btn-sm btn-white mb-10px me-2"><i
                        class="fa fa-circle-xmark t-plus-1 text-danger fa-fw fa-lg"></i> Cancelado</div>
                    <div (click)="openCancel()" *ngIf="!myForm.value.FechaCancelacion"
                      class="btn btn-sm btn-white mb-10px me-2"><i
                        class="fa fa-calendar-xmark t-plus-1 text-danger fa-fw fa-lg"></i> Cancelar</div>
                  </div>
                </div>
              </app-panel-base>
            </div>
            <div style="padding-right: 0px; padding-top: 0px; padding-left:1px;">
              <app-panel-base title="Usuarios" [style]="'height:150px;width:232px'" *ngIf="this.myForm.value.Id > 0 ">
                <div class="panel-content">
                  <div style="width:100%;">
                    <div class="widget-list"
                      *ngIf="myForm.value.UsuarioCaptura">
                      <div class="widget-list-item">
                        <div class="widget-list-media">
                          <i class="fas fa-user-check fa-lg text-green"></i>
                        </div>
                        <div class="widget-list-content">
                          <h4 class="widget-list-title">{{myForm.value.UsuarioCaptura.Nombre}}</h4>
                          <p class="widget-list-desc">Usuario elaboró</p>
                        </div>
                      </div>
                      <div class="widget-list-item" *ngIf="myForm.value.UsuarioCancela">
                        <div class="widget-list-media">
                          <i class="fas fa-user-xmark fa-lg text-red"></i>
                        </div>
                        <div class="widget-list-content">
                          <h3 class="widget-list-title">{{myForm.value.UsuarioCancela.Nombre}}</h3>
                          <p class="widget-list-desc">Usuario cancela</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </app-panel-base>
            </div>
          </div>
          <div class="detallecxc-header">
            <div  id="divMovCxc">
              <app-panel-base title="Conceptos" [style]="'width:1180px; '">
                <div class="panel-content">
                  <ng-scrollbar style="height: 280px;border-bottom: 1px solid #AAAAAA;">
                    <table class="blueTable">
                      <thead style="position: sticky;top:0;">
                        <tr>
                          <th style="width: 40px;"></th>
                          <th style="width: 70px;text-align:right; padding-right:4px;">Cliente</th>
                          <th style="width: 160px;">Nombre Cliente</th>
                          <th style="width: 60px;text-align:right;padding-right:4px;">Cuenta</th>
                          <th style="width: 150px;">Nombre Cta.</th>
                          <th style="width: 60px;text-align:right;padding-right:4px;">Concepto</th>
                          <th style="width: 150px;">Nombre Conc.</th>
                          <th style="width: 75px;">Serie Orig.</th>
                          <th style="width: 75px;text-align:right;padding-right:4px;">Folio Orig.</th>
                          <th style="width: 80px;text-align:right;padding-right:4px;">Cargo</th>
                          <th style="width: 80px;text-align:right;padding-right:4px;">Abono</th>
                          <th style="width: 70px;">Serie Afecta</th>
                          <th style="width: 70px;text-align:right;padding-right:4px;">Folio Afecta</th>
                        </tr>
                      </thead>
                      <tbody class="bg-light">
                        <tr [style]=" indexEditing == index ?  'background-color: #ffdbb7;': ''"
                           *ngFor="let item of source; let index = index;">
                          <td style="text-align: center;" (click)="deleteRow(index)">
                            <i (click)="deleteRow(index)" *ngIf="item.Cliente" class="fa fa-rectangle-xmark fa-1x"
                              style="color:red;font-weight: bold; cursor: pointer;"></i>
                          </td>
                          <td >
                            <input [disabled]="loading" (keyup)="buscarCatalogo($event,'Cliente')"
                              (keydown.escape)="escapeCliente($event)" (keydown.arrowup)="up($event,0)"
                              (keydown)="navegarCliente($event)" (keydown.arrowdown)="down($event,0)"
                              (keydown.enter)="enterCliente($event)" class="grid-editor text-dark"
                              [id]="'txt_'+index+'_0' + idUnico" (focus)="focusCliente(index, $event, item)"
                              (blur)="blurCliente($event)" type="text" entidadBusqueda="Almacen"
                              (keydown.f2)="buscarCatalogo($event,'Almacen')"
                              [style]="'width:70px;text-align:right;padding-right:5px;height: 22px'"
                              [value]="item.ClienteClave ? item.ClienteClave: ''" />
                          </td>
                          <td>
                            <span style="margin-left: 3px;">{{item.ClienteNombre}}</span>
                          </td>
                          <td>
                            <input [disabled]="loading" (keyup)="buscarCatalogo($event,'Cuenta')"
                              (keydown.escape)="escapeCuenta($event)" (keydown.arrowup)="up($event,2)"
                              (keydown)="navegarCuenta($event)" (keydown.arrowdown)="down($event,2)"
                              (keydown.enter)="enterCuenta($event)" class="grid-editor text-dark"
                              (keydown.tab)="enterCuenta($event)" [id]="'txt_'+index+'_2' + idUnico"
                              (focus)="focusCuenta(index, $event, item)" (blur)="blurCuenta($event)" type="text"
                              [style]="'width:60px;text-align:right;padding-right:5px;height: 22px'"
                              [value]="item.CuentaClave ? item.CuentaClave: ''" />
                          </td>
                          <td>
                            <span style="margin-left: 3px;">{{item.CuentaNombre}}</span>
                          </td>
                          <td >
                            <input [disabled]="loading" (keyup)="buscarCatalogo($event,'Concepto')"
                              (keydown)="navegarConcepto($event)" (keydown.escape)="escapeConcepto($event)"
                              (keydown.arrowup)="up($event,4)" (keydown.arrowdown)="down($event,4)"
                              (keydown.enter)="enterConcepto($event)" class="grid-editor text-dark"
                              [id]="'txt_'+index+'_4' + idUnico" (focus)="focusConcepto(index, $event, item)"
                              [style]="'width:60px;text-align:right;padding-right:5px;height: 22px'"
                              (blur)="blurConcepto($event)" type="text"
                              [value]="item.ConceptoClave ? item.ConceptoClave: ''" />
                          </td>
                          <td>
                            <span style="margin-left: 3px;">{{item.ConceptoNombre}}</span>
                          </td>
                          <td>
                            <input (keydown.arrowup)="up($event,6)"
                              (keydown.arrowdown)="down($event,6)" [disabled]="loading"
                              (keydown.escape)="escapeSerieOrigina($event)" *ngIf="item.Cliente"
                              (keydown.enter)="enterSerieOrigina($event)" class="grid-editor text-dark"
                              [id]="'txt_'+index+'_6' + idUnico" (blur)="blurSerieOrigina($event)"
                              (focus)="setIndexEdit(index, $event, item)" type="text"
                              [style]="'width:75px;text-align:right;height: 22px;text-transform: uppercase'"
                              [value]="item.Cliente ? item.SerieOrigina: ''" />
                          </td>
                          <td>
                            <input  (keydown.arrowup)="up($event,7)"
                              (keydown.arrowdown)="down($event,7)" [disabled]="loading"
                              (keydown.escape)="escapeFolioOrigina($event)" *ngIf="item.Cliente"
                              (keydown.enter)="enterFolioOrigina($event)" class="grid-editor text-dark"
                              [id]="'txt_'+index+'_7' + idUnico" (blur)="blurFolioOrigina($event)"
                              (focus)="setIndexEdit(index, $event, item)" type="number"
                              [style]="'width:75px;text-align:right;height: 22px;'"
                              [value]="item.Cliente ? item.FolioOrigina: 0" />
                          </td>
                          <td>
                            <input (keydown.arrowup)="up($event,8)" (keydown.arrowdown)="down($event,8)" [disabled]="loading"
                              (keydown.escape)="escapeCargo($event)" *ngIf="item.Cliente" (keydown.enter)="enterCargo($event)"
                              class="grid-editor text-dark" [id]="'txt_'+index+'_8' + idUnico" (blur)="blurCargo($event)"
                              (focus)="setIndexEdit(index, $event, item)" type="number" (keydown.tab)="keyDowncargoTab($event)"
                              [style]="'width:80px;text-align:right;height:22px'"
                              [value]="item.Cliente ? item.ImporteCargo: 0" />
                          </td>
                          <td>
                            <input (keydown.arrowup)="up($event,9)" (keydown.arrowdown)="down($event,9)" [disabled]="loading"
                              (keydown.escape)="escapeAbono($event)" *ngIf="item.Cliente" (keydown.enter)="enterAbono($event)"
                              class="grid-editor text-dark" [id]="'txt_'+index+'_9' + idUnico" (blur)="blurAbono($event)"
                              (focus)="setIndexEdit(index, $event, item)" type="number"
                              [style]="'width:80px;text-align:right;height:22px'"
                              [value]="item.Cliente ? item.ImporteAbono: 0" />
                          </td>
                          <td>
                            <input  (keydown.arrowup)="up($event,10)"
                              (keydown.arrowdown)="down($event,10)" [disabled]="loading"
                              (keydown.escape)="escapeSerieAfecta($event)" *ngIf="item.Cliente"
                              (keydown.enter)="enterSerieAfecta($event)" class="grid-editor text-dark"
                              [id]="'txt_'+index+'_10' + idUnico" (blur)="blurSerieAfecta($event)"
                              (focus)="setIndexEdit(index, $event, item)" type="text"
                              [style]="'width:70px;text-align:right;height: 22px;text-transform: uppercase'"
                              [value]="item.Cliente ? item.SerieAfecta: ''" />
                          </td>
                          <td>
                            <input (keydown.arrowup)="up($event,11)"
                              (keydown.arrowdown)="down($event,11)" [disabled]="loading"
                              (keydown.escape)="escapeFolioAfecta($event)" *ngIf="item.Cliente"
                              (keydown.enter)="enterFolioAfecta($event)" class="grid-editor text-dark"
                              [id]="'txt_'+index+'_11' + idUnico" (blur)="blurFolioAfecta($event)"
                              (focus)="setIndexEdit(index, $event, item)" type="number"
                              [style]="'width:70px;text-align:right;height: 22px;'"
                              [value]="item.Cliente ? item.FolioAfecta: 0" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </ng-scrollbar>
                  <div class="d-flex">
                    <div style="width:60%; padding-top: 10px;">
                      <div class="widget-list mb-0" style="display: flex" *ngIf="myForm.value.UsuarioElabora">
                        <div class="widget-list-item" style="width:25%;">
                          <div class="widget-list-media">
                            <i class="fas fa-user-check fa-1x text-green"></i>
                          </div>
                          <div class="widget-list-content">
                            <h4 class="widget-list-title">{{myForm.value.UsuarioElabora.Nombre}}</h4>
                            <p class="widget-list-desc">Usuario elaboró</p>
                          </div>
                        </div>
                        <div class="widget-list-item" style="width:40%;" *ngIf="myForm.value.UsuarioCancela">
                          <div class="widget-list-media">
                            <i class="fas fa-user-xmark fa-2x text-red"></i>
                          </div>
                          <div class="widget-list-content">
                            <h4 class="widget-list-title">{{myForm.value.UsuarioCancela.Nombre}}</h4>
                            <p class="widget-list-desc">Usuario canceló</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <table class="table table-borderless table-sm fw-bold m-0 mt-2" style="width:39%;">
                      <tbody>
                        <tr>
                          <td class="pb-2" colspan="2"><b>Total Cargos</b></td>
                          <td class="text-end pb-2 text-decoration-underline bg-success-subtle ">
                            <b>${{this.totalCargos|number:'1.2-6'}}</b>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3">
                            <hr class="m-0">
                          </td>
                        </tr>
                        <tr>
                          <td class="pb-2" colspan="2"><b>Total Abono</b></td>
                          <td class="text-end pb-2 text-decoration-underline bg-success-subtle ">
                            <b>${{this.totalAbonos|number:'1.2-6'}}</b>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3">
                            <hr class="m-0">
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </app-panel-base>
              <app-panel-base title="Comentarios" [style]="'width:1180px;height:110px;'">
                <div class="panel-content">
                  <textarea maxlength="1000" formControlName="Observaciones" class="w-100" rows="2" [tabIndex]="5"
                  (keydown)="onKeyDownObs($event)"  cols="50"
                  #txtObservaciones></textarea>
                </div>
              </app-panel-base>
            </div>
          </div>
        </div>
      </div>
      <div   class="cancelled-header animate__animated animate__bounceInDown" *ngIf="myForm.value.FechaCancelacion">
        <div class="cancelled">&nbsp;</div>
        <div class="cancelled-detail">{{getCancelDate}}</div>
        <div class="cancelled-detail">{{ myForm.value.MotivoCancelacion | uppercase}}</div>
      </div>
      <!-- <div class="cancelled-header animate__animated animate__bounceInDown" *ngIf="myForm.value.FechaCancelacion">
        <div class="cancelled">CANCELADO: {{getCancelDate}}</div>
        <div class="cancelled-detail">{{ myForm.value.MotivoCancelacion | uppercase}}</div>
      </div> -->
    </form>
  </div>
  <ng-template #modalCancelarMovCxc let-modal let-c="close">
    <opcion-cancelar-page></opcion-cancelar-page>
  </ng-template>
</app-container-base>
