import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { UserLogged } from 'src/app/auth/interfaces';

import { SeleccionEmpresa, SeleccionSucursal } from '../interfaces';
import { BaseService } from 'src/app/service/base.service';
import { EventsService } from 'src/app/service/events.service';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { UtilsService } from 'src/app/service/utils.service';
import Swal from 'sweetalert2';
import { Subject, debounceTime } from 'rxjs';
import { AppSettings } from 'src/app/home/services/app-settings.service';
import { AuthService } from '../../auth/services/auth.service';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';

@Component({
  selector: 'seleccionar-empresa-sucursal',
  templateUrl: './seleccionar-empresa-sucursal.component.html',
  styleUrls: ['./seleccionar-empresa-sucursal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SeleccionarEmpresaSucursalComponent implements OnInit {

  @Input() numeroPaginas: number = 0;
  @Output() onLogout = new EventEmitter<boolean>();

  @ViewChild('seleccionEmpresaSucursal')
  public seleccionEmpresaSucursal!: ElementRef<HTMLElement>;


  private searchEmpresas = new Subject<string>();
  private searchSucursales = new Subject<string>();
  inputEmpresas: string = '';
  inputSucursales: string = '';
  private readonly debounceTimeMs = 300;

  usuarioLoggeado: UserLogged = {
    ambienteId: 0,
    numero: 0,
    clave: 0,
    nombreUsuario: '',
    nombreCompletoUsuario: "",
    empresa: { numero: 0, clave: 0, nombre: "Seleccionar Sucursal", rfc: "", alias: "" },
    sucursal: { numero: 0, clave: 0, nombre: "Seleccionar Empresa", rfc: "", alias: "" },
  };

  private utilsService = inject(UtilsService);
  private baseService = inject(BaseService);
  private eventsService = inject(EventsService);
  private modalService = inject(NgbModal);
  private config = inject(NgbModalConfig);
  public appSettings = inject(AppSettings);
  public authService = inject(AuthService);

  visibleDialog: boolean = false;


  empresasFiltradas: SeleccionEmpresa[] = [];
  sucursalesFiltradas: SeleccionSucursal[] = [];
  empresas: SeleccionEmpresa[] = [];
  sucursales: SeleccionSucursal[] = [];
  empresaSeleccionada: SeleccionEmpresa = { id: 0, clave: 0, nombre: "", rfc: "", sucursales: [] };
  sucursalSeleccionada: SeleccionSucursal = { id: 0, clave: 0, nombre: "", alias: "" };


  ngOnInit(): void {
    this.config.backdrop = 'static';
    this.config.keyboard = false;
    this.usuarioLoggeado = this.utilsService.getUserLogged();

    if (!this.usuarioLoggeado.empresa?.numero || !this.usuarioLoggeado.sucursal?.numero) {
      setTimeout(() => {
        this.abrirSelectSucursal();
      }, 300);
    }

    this.searchEmpresas.pipe(debounceTime(this.debounceTimeMs)).subscribe((searchValue) => {
      this.filtrarEmpresas(searchValue);
    });

    this.searchSucursales.pipe(debounceTime(this.debounceTimeMs)).subscribe((searchValue) => {
      this.filtrarSucursales(searchValue);
    });

    this.eventsService.subscribe(('selectEmpresaSucursal:refreshInfo'), (data) => {
      this.refreshInfo();
    });
  }

  refreshInfo() {
    this.usuarioLoggeado = this.utilsService.getUserLogged();
  }

  filtrarEmpresas(search: string) {
    this.empresasFiltradas = this.empresas.filter(e => (e.nombre + e.rfc).toUpperCase().includes(search.toUpperCase()));
  }

  filtrarSucursales(search: string) {
    this.sucursalesFiltradas = this.sucursales.filter(e => (e.nombre.toUpperCase()).includes(search.toUpperCase()));
  }

  onSearchEmpresas() {
    this.searchEmpresas.next(this.inputEmpresas);
  }

  onSearchSucursales() {
    this.searchSucursales.next(this.inputSucursales);
  }

  abrirSelectSucursal() {
    this.empresas = [];
    this.sucursales = [];
    this.empresaSeleccionada = { id: 0, clave: 0, nombre: "", rfc: "", sucursales: [] };
    this.sucursalSeleccionada = { id: 0, clave: 0, nombre: "", alias: "" };


    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.baseService.getEmpresasSucursalesPorUsuario(this.usuarioLoggeado.numero).subscribe((result: SeleccionEmpresa[]) => {
      this.empresas = result;
      this.empresasFiltradas = result;
      this.empresaSeleccionada = (this.usuarioLoggeado.empresa!.numero > 0 ? this.empresas.find(e => e.id === this.usuarioLoggeado.empresa!.numero) : this.empresas[0]) || this.empresaSeleccionada;
      this.sucursalSeleccionada = (this.usuarioLoggeado.sucursal!.numero > 0 ? this.empresaSeleccionada.sucursales.find(s => s.id === this.usuarioLoggeado.sucursal!.numero) : this.empresaSeleccionada.sucursales[0]) || this.sucursalSeleccionada;
      this.sucursales = this.empresaSeleccionada.sucursales;
      this.sucursalesFiltradas = this.empresaSeleccionada.sucursales;
      this.eventsService.publish('home:isLoading', { isLoading: false });
      let abrirModal = true;
      if (this.empresas.length === 1 && this.empresas[0].sucursales.length === 1) {
        abrirModal = false;
      }
      if (abrirModal) {
        this.modalService.open(this.seleccionEmpresaSucursal, { size: 'lg', centered: true, backdropClass: 'light-blue-backdrop' });

        setTimeout(() => {
          let e = document.getElementById("item-emp-" + this.empresaSeleccionada.id);
          e?.scrollIntoView();

          let s = document.getElementById("item-suc-" + this.sucursalSeleccionada.id);
          s?.scrollIntoView();
        }, 300);
      } else {
        Swal.mixin({
          toast: true,
          position: "bottom-start",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
            toast.addEventListener('click', () => Swal.close());
          }
        }).fire({
          icon: "info",
          title: "Tu usuario solo tiene permiso a la Empresa y Sucursal actual."
        });

      }
    })
  }

  aceptar(accion: boolean) {
    if (accion) {
      let obj = {
        numeroUsuario: this.usuarioLoggeado.numero,
        numeroEmpresa: this.empresaSeleccionada.id,
        numeroSucursal: this.sucursalSeleccionada.id,
        nombreEmpresa: this.empresaSeleccionada.nombre
      }

      if (this.numeroPaginas > 0) {
        if (this.usuarioLoggeado.sucursal?.numero !== this.sucursalSeleccionada.id) {
          this.eventsService.publish('home:showAlert', {
            message: 'Se van a cerrar las páginas abiertas actualmente. ¿Está de acuerdo?',
            icon: 'fa-triangle-exclamation text-yellow',
            onConfirm: (r: AlertResponse) => {
              if (r.isAccept) {
                this.guardarSucursalSeleccionada(obj)
              }
            }
          });
        } else {
          this.guardarSucursalSeleccionada(obj);
        }
      } else {
        this.guardarSucursalSeleccionada(obj);
      }
    } else {
      this.modalService.dismissAll(null);
    }
  }

  guardarSucursalSeleccionada(obj: any) {
    this.utilsService.isLoad(true);
    this.baseService.guardarSucursalSeleccionada(obj).subscribe({
      next: (resp: any) => {
        if (resp.success) {
          if (this.usuarioLoggeado.sucursal?.numero !== this.sucursalSeleccionada.id) {
            this.usuarioLoggeado = {
              ...this.usuarioLoggeado,
              empresa: { numero: this.empresaSeleccionada.id, clave: this.empresaSeleccionada.clave, nombre: this.empresaSeleccionada.nombre, rfc: this.empresaSeleccionada.rfc, alias: "" },
              sucursal: { numero: this.sucursalSeleccionada.id, clave: this.sucursalSeleccionada.clave, nombre: this.sucursalSeleccionada.nombre, rfc: "", alias: this.sucursalSeleccionada.alias }
            }
            this.eventsService.publish('home:changeEmpSuc', { cambioEmpSuc: true });
            this.utilsService.setUserLogged(this.usuarioLoggeado);
          }
          this.modalService.dismissAll(null);
          this.authService.actualizarSesionesActivas(this.usuarioLoggeado).subscribe();
          this.utilsService.isLoad(false);
        }else{
          this.eventsService.publish('home:showAlert', {
            message: resp.message,
            icon: 'fa-triangle-exclamation text-yellow',
            textCancel: "Cerrar Sesión",
            onConfirm: (r: AlertResponse) => {
              if (r.isCancel) {
                //this.guardarSucursalSeleccionada(obj)
                this.logout();
              }
            }
          });
        }
        this.utilsService.isLoad(false);
      },
      error: (err) => {
        console.log(err);
        this.utilsService.isLoad(false);
      }
    })
  }

  clickEmpresa(e: SeleccionEmpresa) {
    this.empresaSeleccionada = e;
    this.sucursales = e.sucursales;
    this.sucursalesFiltradas = e.sucursales;
    this.sucursalSeleccionada = this.sucursales[0]
  }

  clickSucursal(s: SeleccionSucursal) {
    this.sucursalSeleccionada = s
  }

  logout() {
    this.onLogout.emit(true);
  }
}
