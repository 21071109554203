import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Editor } from 'ngx-editor';
import { UserLogged } from 'src/app/auth/interfaces';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { EventReturn } from 'src/app/component-ui/interfaces/container-base.interface';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import { Empresa } from 'src/app/configuracion/interfaces/empresa.interface';
import { Sucursal } from 'src/app/configuracion/interfaces/sucursal.interface';
import { Almacen, ConceptoAlmacen, TipoMovimientoAlmacen } from 'src/app/home/interfaces/almacen.interface';
import { UtilsService } from 'src/app/service/utils.service';
import { SerieManufactura } from '../../interfaces/manufacura.interface';

@Component({
  selector: 'app-series-manufactura-page',
  templateUrl: './series-manufactura-page.component.html',
  styles: [
  ]
})
export class SeriesManufacturaPageComponent {

  public itemsCombo: ComboBoxEntity[] = [];
  editor: Editor = new Editor;
  html: string = "";
  filter = ``
  info: UserLogged = {} as UserLogged;
  private newEntityObj = {
    Id: [0],
    Empresa: [{} as Empresa, [Validators.required]],
    Sucursal: [{} as Sucursal, [Validators.required]],
    Clave: [0],
    Nombre: ['', [Validators.required]],
    Serie: ['', [Validators.required]],
    TextoImprimir: [''],
    Copias: [''],
    AlmacenSalida: [{} as Almacen, [Validators.required]],
    TipoMovimiento: [{} as TipoMovimientoAlmacen, [Validators.required]],
    ConceptoSalida: [{} as ConceptoAlmacen, [Validators.required]],
    EnviarCorreo: [false],
    SeUsaParaOrden: [false],
    CorreoEncargado: [''],
    SerieOrdenProduccion:[''],
    SerieProduccion:[''],
    FolioOrdenProduccion:[0],
    ConfiguracionCorreo: this.fb.group({
      Id: [0],
      ServidorSMTP: [''],
      Puerto: [''],
      SSL: [false],
      TLS12: [false],
      Usuario: [''],
      Contrasena: [''],
      CuerpoCorreo: ['']
    }),
    Baja: [false],
  };

  public myForm: FormGroup = this.fb.group(this.newEntityObj);

  @ViewChild('txtNombre')
  public txtNombre!: ElementRef<TextBoxComponent>;
  constructor(private fb: FormBuilder, private containerService: ContainerBaseService, private utilsService: UtilsService) {
  }
  ngOnInit(): void {
    this.info = this.utilsService.getUserLogged();
    this.filter = `Empresa.Id = ${this.info.empresa!.numero} and Sucursal.Id = ${this.info.sucursal!.numero}`
  }

  onBeforeSave(param: EventReturn) {
    param.callback(true);
  }

  onAfterSave(entity: SerieManufactura) {
    this.html = entity.ConfiguracionCorreo.CuerpoCorreo;
    this.myForm.reset(entity);
  }

  onNewCatalog(next: number) {
    this.containerService.getEmptyEntity("SerieManufactura").subscribe((elem) => {
      elem.Empresa = { Id: this.info.empresa!.numero, Clave: this.info.empresa!.clave, Nombre: this.info.empresa!.nombre };
      elem.Sucursal = { Id: this.info.sucursal!.numero, Clave: this.info.sucursal!.clave, Nombre: this.info.sucursal!.nombre };
      elem.Clave = next;
      this.myForm.reset(elem);
      this.html = elem.ConfiguracionCorreo.CuerpoCorreo;
      this.focus("txtNombre")
    })
  }


  selectedCombo(entity: ComboBoxEntity, tipo: string) {
    this.myForm.controls[tipo].setValue(entity);
  }

  onItemSearched(ent: any): void {
    if (ent) {
      ent.Empresa = { Id: this.info.empresa!.numero, Clave: this.info.empresa!.clave, Nombre: this.info.empresa!.nombre };
      ent.Sucursal = { Id: this.info.sucursal!.numero, Clave: this.info.sucursal!.clave, Nombre: this.info.sucursal!.nombre };
      this.filter = `Empresa.Id = ${this.info.empresa?.numero} and Sucursal.Id = ${this.info.sucursal?.numero}`
      this.myForm.reset(ent);
      this.html = ent.ConfiguracionCorreo.CuerpoCorreo;
      this.focus('txtNombre')
    }
  }

  get getIsNew(): any {
    return this.myForm.value;
  }

  focus(field: string) {
    setTimeout(() => {
      const txt: any = this.txtNombre;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  onChangueEntity(ent: any) {
    if (ent) {
      this.html = ent.ConfiguracionCorreo.CuerpoCorreo;
      this.onItemSearched(ent);
    }
  }

  get getFilter(): string {
    return this.filter;
  }

  onItemsComboSearched(items: ComboBoxEntity[]): void {
    this.itemsCombo = items;
  }

  get getItemsCombo(): ComboBoxEntity[] {
    return this.itemsCombo;
  }
}
