import { Component, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { LevelType, ReportFilter, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ModalService } from 'src/app/service/modal.service';
import { ReportsService } from 'src/app/service/reports.service';

@Component({
  selector: 'impresion-productos',
  templateUrl: './impresion-productos.component.html',
  styleUrls: ['./impresion-productos.component.scss']
})
export class ImpresionProductosComponent {
  private modalService = inject(ModalService);
  private frmBuilder = inject(FormBuilder);
  private reportsService = inject(ReportsService);

  tipoReporte: ComboBoxEntity[] = [
    { Id: 1, Nombre: "Normal", Clave: 1, ClaveNombre: "01 Normal" },
  ];

  public frmImpresionProductos: FormGroup = this.frmBuilder.group({
    NombreReporte: ['Productos', Validators.required],
    Num1: [this.tipoReporteSeleccionado?.Id]//TipoReporte
  });


  ngOnInit() {
    setTimeout(() => {
      this.tipoReporteSeleccionado = this.tipoReporte[0];
    }, 300);
  }

  get selectTipoReporteItem(): ComboBoxEntity | null {
    return this.tipoReporteSeleccionado!;
  }

  tipoReporteSeleccionado?: ComboBoxEntity;
  selectedCombo(entity: any, tipo: string) {
    this.tipoReporteSeleccionado = entity;
    this.frmImpresionProductos.get('Num1')?.setValue(this.tipoReporteSeleccionado?.Id);
  }

  close() {
    this.modalService.closeModal(null);
  }

  public filtrosImpresionProductos: ReportFilter =
    {
      ReportHeader: {
        NombreReporte: 'REPORTE PRODUCTOS'
      } as ReportHeader,
      TituloVisor: 'Productos',
      NombreExcel: 'Productos.xlsx',
      FilterOptions: [
        { Campo: 'e.Clave', Etiqueta: 'Empresa', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Empresa", HelpLine: "Claves de Empresas a consultar separadas por coma (1,2,3)." },
        { Campo: 's.Clave', Etiqueta: 'Sucursal', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Sucursal", HelpLine: "Claves de Sucursales a consultar separadas por coma (1,2,3)." },
        { Campo: 'p.Clave', Etiqueta: 'Clave Producto', Tipo: TypeFilter.text, BotonBusqueda: true, Entidad: "Producto", HelpLine: "Claves de Productos a consultar separadas por coma (1,2,3)." },
        { Campo: 'p.familias', Etiqueta: 'Familia', Tipo: TypeFilter.levels, TipoNiveles: LevelType.productFamily, HelpLine: "Líneas y Sublineas de los productos a consultar." },
        { Campo: 'p.Nombre', Etiqueta: 'Nombre', Tipo: TypeFilter.text, HelpLine: "Nombre de los productos que seran consultados en el reporte." },
      ]
    };

  changeselec(event: any) {
    this.filtrosImpresionProductos = event;
  }

  print() {
    this.filtrosImpresionProductos.ReportHeader = this.frmImpresionProductos.value;
    this.reportsService.printReport(this.filtrosImpresionProductos, '/Inventarios/ReporteProductosKit');
  }
}
