import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertResponse } from 'src/app/component-ui/interfaces/alert.interface';
import { TabsNavService } from 'src/app/components/services/tabs-nav.service';
import { EventsService } from 'src/app/service/events.service';
import { ModalService } from 'src/app/service/modal.service';
import { CorteCajaService } from '../../services/cortecaja.service';
import { UtilsService } from '../../../service/utils.service';
import { Result } from 'src/app/auth/interfaces';
import { TextBoxComponent } from 'src/app/component-ui/components/text-box/text-box.component';
import { id } from '@swimlane/ngx-charts';
import { ActiveButtons, EventReturn } from 'src/app/component-ui/interfaces/container-base.interface';
import { CorteCaja, CorteCajaConteoEfectivo, CorteCajaEfectivoEntregado } from '../../interfaces/cortecaja.interface';
import { ContainerBaseService } from 'src/app/component-ui/services/container-base.service';
import Swal from 'sweetalert2';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import * as _ from 'lodash';
import { ReportsService } from 'src/app/service/reports.service';

@Component({
  selector: 'app-corte-caja-page',
  templateUrl: './corte-caja-page.component.html',
  styleUrls: ['./corte-caja-page.component.scss'],
  styles: [
  ]
})
export class CorteCajaPageComponent {
  @ViewChild('modalCorteCaja')
  public modalCorteCaja!: ElementRef<HTMLElement>;
  @ViewChild('modalConteoEfectivo')
  public modalConteoEfectivo!: ElementRef<HTMLElement>;
  @ViewChild('modalRetirosCaja')
  public modalRetirosCaja!: ElementRef<HTMLElement>;
  @ViewChild('modalTerminales')
  public modalTerminales!: ElementRef<HTMLElement>;
  @ViewChild('modalTransferencias')
  public modalTransferencias!: ElementRef<HTMLElement>;
  @ViewChild('modalCheques')
  public modalCheques!: ElementRef<HTMLElement>;

  labelAnticipoFacturado: string = '(+) Anticipo Facturado';
  labelAnticipoAplicadoContado: string = '(-) Anticipo Aplicado';
  labelNotasCreditoContado: string = '(-) Notas de Crédito';
  labelAnticipoAplicadoCredito: string = '(-) Anticipo Aplicado';
  labelNotasCreditoCredito: string = '(-) Notas de Crédito';

  private fb = inject(FormBuilder);
  private modalService = inject(ModalService);
  private tabsNavService = inject(TabsNavService);
  private eventsService = inject(EventsService);
  private containerService = inject(ContainerBaseService);
  private corteService = inject(CorteCajaService);
  private reportsService = inject(ReportsService);



  opcionSeleccionada?: ComboBoxEntity;
  get selectCuentaManualItem(): ComboBoxEntity | null {
    return this.opcionSeleccionada!;
  }

  activeButtons: ActiveButtons = {
    new: false,
    delete: false,
    return: false,
    save: true,
    first: false,
    left: false,
    right: false,
    last: false,
    search: false,
    print: true,
  }

  private newEntityFrm = {
    Id: [0],
    Empresa: [null],
    Sucursal: [null],
    Caja: [null],
    Bloqueado: [false],
    Encargado: [null],
    Fecha: [Date],
    IdEstado: [0],
    Banco: [null],
    CuentaBancaria: [null, [Validators.required]],
    AutorizadoParaMover: [false],
    ConteoEfectivo: [null],
    Efectivo: [0],
    Turno: [0],
    Fondo: [0],
    Cheques: [[]],
    Terminales: [[]],
    Transferencias: [[]],
    DepositosCajaGeneral: [[]],
    TotalCheques: [0],
    TotalTerminales: [0],
    TotalTransferencias: [0],
    TotalDepositosCajaGeneral: [0],
    DocumentosPendientesPagadosCredito: [[]],
    Documentos: [[]],
    TotalIngresosDocumentos: [0],
    TotalEntregado: [0],
    TotalVentasContado: [0],
    TotalNotasCreditoContado: [0],
    TotalAnticipoFacturado: [0],
    TotalAnticipoAplicadoContado: [0],
    TotalDocumentosPendientesPagadosCredito: [0],
    TotalContado: [0],
    TotalVentasCredito: [0],
    TotalAnticipoAplicadoCredito: [0],
    TotalNotasCredito: [0],
    TotalCredito: [0],
    TotalPorPagar: [0],
    TotalDia: [0],
    Observaciones: [''],
    Baja: [false],
    Eliminado: [false],
  };

  public frmCorteCaja: FormGroup = this.fb.group(this.newEntityFrm);


  terminalesAgrupadas: any[] = [];

  modalCorteOpen: boolean = false;
  ngOnInit(): void {
    this.tabsNavService.getIsOpenTab("facturacion", (resp: any) => {
      if (resp) {
        this.eventsService.publish('home:showAlert', {
          message: `No se puede mantener abiertas las pantallas de Corte de Caja y Facturación al mismo tiempo.`, cancelButton: false, onConfirm: (data: AlertResponse) => {
            this.tabsNavService.closeCurrentTab();
          }
        });
      } else {
        setTimeout(() => {
          if (!this.modalCorteOpen) {
            this.openModalCorte();
          }
        }, 300);
      }
    })
  }

  onClickBarButton(button: string): void {
    switch (button) {
      case "save": this.save(); break;
      case "print": this.print(); break;
    }
  }

  save() {
    if (this.frmCorteCaja.value.Id == 0) {
      this.eventsService.publish('home:showAlert', { message: "No puede guardar un Corte vacio.", cancelButton: false });
      return;
    }

    if (!this.frmCorteCaja.value.CuentaBancaria) {
      this.eventsService.publish('home:showAlert', { message: "Debe seleccionar una Cuenta de Deposito.", cancelButton: false });
      return;
    }

    this.eventsService.publish('home:isLoading', { isLoading: true });
    this.containerService.saveEntity("CorteCaja", JSON.stringify(this.frmCorteCaja.value), false).subscribe((item) => {
      if (item.success) {
        const i = eval(`[${item.message}]`);
        this.corteService.buscarCortePorId(this.frmCorteCaja.value.Id).subscribe((cor) => {
          this.eventsService.publish('home:isLoading', { isLoading: false });
          const cort: CorteCaja = JSON.parse(cor.message);
          this.frmCorteCaja.reset(cort);
          Swal.fire({ position: 'center', icon: 'success', title: 'Se guardó correctamente', showConfirmButton: false, timer: 1000 });
        })
      } else {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        this.eventsService.publish('home:showAlert', { message: item.message, icon: 'fa-triangle-exclamation text-yellow', cancelButton: false });
      }
    },
      error => {
        this.eventsService.publish('home:isLoading', { isLoading: false });
        this.eventsService.publish('home:showAlert', { message: 'Ocurrió un error la guardar.', icon: 'fa-triangle-exclamation text-yellow', cancelButton: false });

      });
  }




  print() {
    this.reportsService.printReportWithBody(this.frmCorteCaja.value.Id, '/Ventas/ReporteCorteCaja', "Corte de Caja");
  }

  onBeforeSave(event: EventReturn) {
  }

  onItemsComboSearched(event: any) {

  }

  onItemSearched(event: any) {

  }

  onAfterSave(event: any) {

  }

  fechaCorte: Date = new Date();
  totalDia: number = 0;
  openModalCorte() {
    this.modalCorteOpen = true;
    this.modalService.openModal(this.modalCorteCaja, (corte: any) => {
      this.modalCorteOpen = false;
      if (corte) {
        if (corte.Bloqueado) {
          this.frmCorteCaja.reset(corte);
          setTimeout(() => {
            this.opcionSeleccionada = corte.CuentaBancaria;
          }, 100);
          this.agruparTerminales(this.frmCorteCaja.value.Terminales);
        } else {
          this.eventsService.publish('home:showAlert', {
            message: 'No es posible cargar el corte de caja debido a que no ha sido cerrado, favor de seleccionar otro.',
            cancelButton: false
          });
          this.openModalCorte();
          return;
        }
      } else {
        if (this.frmCorteCaja.get("Id")?.value === 0) {
          this.tabsNavService.closeCurrentTab();
        }
      }
    }, 'xl')
  }

  agruparTerminales(terminales: any[]) {
    this.terminalesAgrupadas = [];
    if (terminales) {
      let importesBancos = [];
      for (const t of terminales) {
        importesBancos.push({
          nombre: t.Banco.Nombre,
          importe: t.Importe
        })
      }
      let bancos = _.uniq(importesBancos.map(x => x.nombre));
      for (const banco of bancos) {

        let sum = _.sumBy(importesBancos.filter(x => x.nombre === banco), 'importe');
        this.terminalesAgrupadas.push({
          nombre: banco,
          importe: sum
        })
      }
    }
  }

  openConteoEfectivo() {
    this.modalService.openModal(this.modalConteoEfectivo, (e: CorteCajaConteoEfectivo | null) => {
      if (e) {
        this.frmCorteCaja.controls["ConteoEfectivo"].setValue(e);
        this.frmCorteCaja.controls["Efectivo"].setValue(e.TotalEfectivo);
        const TotalEntregado: number = this.frmCorteCaja.value.Efectivo + this.frmCorteCaja.value.TotalIngresosDocumentos - this.frmCorteCaja.value.Fondo + Number(this.frmCorteCaja.value.TotalDepositosCajaGeneral);
        this.frmCorteCaja.controls["TotalEntregado"].setValue(TotalEntregado);
        const totalDia: number = this.frmCorteCaja.value.TotalPorPagar - this.frmCorteCaja.value.TotalEntregado;
        this.frmCorteCaja.controls["TotalDia"].setValue(totalDia);
      }
    })
  }


  listaRetiros: CorteCajaEfectivoEntregado[] = [];
  openRetirosCaja() {
    this.listaRetiros = this.frmCorteCaja.value.DepositosCajaGeneral;
    this.modalService.openModal(this.modalRetirosCaja, (retiros: CorteCajaEfectivoEntregado[]) => {
      if (retiros) {
        let totalRetiros: number = 0;
        retiros.forEach((item) => { totalRetiros += Number(item.Importe); })
        this.frmCorteCaja.controls["DepositosCajaGeneral"].setValue(retiros);
        this.frmCorteCaja.controls["TotalDepositosCajaGeneral"].setValue(totalRetiros);
        this.frmCorteCaja.controls["TotalIngresosDocumentos"].setValue(this.frmCorteCaja.value.TotalCheques + this.frmCorteCaja.value.TotalTerminales + this.frmCorteCaja.value.TotalTransferencias + this.frmCorteCaja.value.TotalDepositosCajaGeneral);
        const TotalEntregado: number = this.frmCorteCaja.value.Efectivo + this.frmCorteCaja.value.TotalIngresosDocumentos - this.frmCorteCaja.value.Fondo;
        this.frmCorteCaja.controls["TotalEntregado"].setValue(TotalEntregado);
        const totalDia: number = this.frmCorteCaja.value.TotalPorPagar - this.frmCorteCaja.value.TotalEntregado;
        this.frmCorteCaja.controls["TotalDia"].setValue(totalDia);
        this.listaRetiros = retiros;
      }
    }, 'lg')
  }

  listaTerminales: any[] = [];
  openTerminales() {
    this.listaTerminales = this.frmCorteCaja.value.Terminales;
    this.modalService.openModal(this.modalTerminales, (terminales: any) => {
      if (terminales) {
        this.frmCorteCaja.controls["Terminales"].setValue(terminales);
        let totalTerminales: number = 0;
        terminales.forEach((item: any) => { totalTerminales += Number(item.Importe); })
        this.frmCorteCaja.controls["Terminales"].setValue(terminales);
        this.frmCorteCaja.controls["TotalTerminales"].setValue(totalTerminales);
        this.frmCorteCaja.controls["TotalIngresosDocumentos"].setValue(this.frmCorteCaja.value.TotalCheques + this.frmCorteCaja.value.TotalTerminales + this.frmCorteCaja.value.TotalTransferencias + this.frmCorteCaja.value.TotalDepositosCajaGeneral);
        const TotalEntregado: number = this.frmCorteCaja.value.Efectivo + this.frmCorteCaja.value.TotalIngresosDocumentos - this.frmCorteCaja.value.Fondo;
        this.frmCorteCaja.controls["TotalEntregado"].setValue(TotalEntregado);
        const totalDia: number = this.frmCorteCaja.value.TotalPorPagar - this.frmCorteCaja.value.TotalEntregado;
        this.frmCorteCaja.controls["TotalDia"].setValue(totalDia);
        this.listaTerminales = terminales;
        this.agruparTerminales(terminales);
      }
    }, 'xl')
  }

  listaTransferencias: any[] = [];
  openTransferencias() {
    this.listaTransferencias = this.frmCorteCaja.value.Transferencias;
    this.modalService.openModal(this.modalTransferencias, (transferencias: any) => {
      if (transferencias) {
        this.frmCorteCaja.controls["Transferencias"].setValue(transferencias);
        let totalTransferencias: number = 0;
        transferencias.forEach((item: any) => { totalTransferencias += Number(item.ImporteAplicar); })
        this.frmCorteCaja.controls["Transferencias"].setValue(transferencias);
        this.frmCorteCaja.controls["TotalTransferencias"].setValue(totalTransferencias);
        this.frmCorteCaja.controls["TotalIngresosDocumentos"].setValue(this.frmCorteCaja.value.TotalCheques + this.frmCorteCaja.value.TotalTerminales + this.frmCorteCaja.value.TotalTransferencias + this.frmCorteCaja.value.TotalDepositosCajaGeneral);
        const TotalEntregado: number = this.frmCorteCaja.value.Efectivo + this.frmCorteCaja.value.TotalIngresosDocumentos - this.frmCorteCaja.value.Fondo;
        this.frmCorteCaja.controls["TotalEntregado"].setValue(TotalEntregado);
        const totalDia: number = this.frmCorteCaja.value.TotalPorPagar - this.frmCorteCaja.value.TotalEntregado;
        this.frmCorteCaja.controls["TotalDia"].setValue(totalDia);
        this.listaTransferencias = transferencias;
      }
    }, 'xl')
  }

  listaCheques: any[] = [];
  openCheques() {
    this.listaCheques = this.frmCorteCaja.value.Cheques;
    this.modalService.openModal(this.modalCheques, (cheques: any) => {
      if (cheques) {
        this.frmCorteCaja.controls["Cheques"].setValue(cheques);
        let totalCheques: number = 0;
        cheques.forEach((item: any) => { totalCheques += Number(item.ImporteAplicar); })
        this.frmCorteCaja.controls["Cheques"].setValue(cheques);
        this.frmCorteCaja.controls["TotalCheques"].setValue(totalCheques);
        this.frmCorteCaja.controls["TotalIngresosDocumentos"].setValue(this.frmCorteCaja.value.TotalCheques + this.frmCorteCaja.value.TotalTerminales + this.frmCorteCaja.value.TotalTransferencias + this.frmCorteCaja.value.TotalDepositosCajaGeneral);
        const TotalEntregado: number = this.frmCorteCaja.value.Efectivo + this.frmCorteCaja.value.TotalIngresosDocumentos - this.frmCorteCaja.value.Fondo;
        this.frmCorteCaja.controls["TotalEntregado"].setValue(TotalEntregado);
        const totalDia: number = this.frmCorteCaja.value.TotalPorPagar - this.frmCorteCaja.value.TotalEntregado;
        this.frmCorteCaja.controls["TotalDia"].setValue(totalDia);
        this.listaCheques = cheques;
      }
    }, 'xl')
  }

  onSelectedCuentaBancaria(event: any) {
    this.frmCorteCaja.controls["CuentaBancaria"].setValue(event);
  }
}
