<div class="data-search card border-1 {{isMobile ? 'animate__animated animate__faster animate__fadeInDownBig': ''}}"
  cdkDrag>
  <div style="cursor: move;" cdkDragHandle class="card-header text-center">
    <button (click)="close()" type="button" class="btn-close" aria-label="Close"></button>
    Búsqueda en catálogo
    <h4>[registros: {{rows.length|number}}]</h4>
  </div>
  <div class="card-body bg-light">
    <div class="widget-input-box">
      <input (keydown.escape)="close()" (keydown)="navegar($event)" (keydown.enter)="buscar()" type="text"
        class="form-control" placeholder="Ingrese el texto a buscar y presione enter" #txtBuscar>
    </div>
    <div class="ds-container {{searching?'search-back':''}}" [style.display]="!isMobile ? 'init' : 'none'">
      <div style="height: 300px;border-bottom: 1px solid #AAAAAA;overflow: auto;">
        <table class="blueTable" *ngIf="!searching;">
          <thead style="position: sticky;top:0;">
            <tr>
              <th (click)="ordenar(item.prop)" *ngFor="let item of columns" [style]="'width:' + item.width + 'px;'">
                {{item.name ? item.name: item.prop}}
              </th>
            </tr>
          </thead>
          <tbody  class="bg-light">
            <tr (click)="click(i)" (dblclick)="onDblClick(row)" *ngFor="let row of rows; let i = index;"
              [id]="'row-search' + idUnico + '_'+i" [class]="selectedIndex == i ? 'bg-blue-700 text-light':''">
              <td class="ps-2 pe-2 text-truncate" *ngFor="let item of columns" [style]="'width:' + item.width + 'px;'">
                {{row[item.prop]}}
              </td>
            </tr>
          </tbody>
        </table>

      </div>
      <ng-template #load>
        <i style="position: absolute;left: 50%; top:50%" class="fas fa-spinner fa-spin fa-5x"></i>
      </ng-template>
    </div>


    <ng-scrollbar [style]="scrollbarStyle">
      <ul
        class="list-group list-group-flush {{searching ? '': 'animate__animated animate__faster animate__fadeInDownBig'}}"
        *ngIf="isMobile">
        <li class="list-group-item" *ngIf="searching">Cargando registros...</li>
        <li (click)="selectMobile(r)" class="list-group-item cursor-pointer" *ngFor="let r of rows" matRipple>
          {{r.Nombre}}</li>
      </ul>
    </ng-scrollbar>
  </div>
</div>
