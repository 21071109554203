<div class="card text-center border-0" cdkDrag
  style="z-index:2;box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; width: 720px; position: fixed;left: 30%;top:30%;">
  <div class="card-header fw-bold" style="cursor: move;" cdkDragHandle>
    <span>
      Cancelar Orden de Producción
    </span>
  </div>
  <app-panel-base title="Información para la cancelacion">
    <div class="panel-content">
      <form [formGroup]="myForm" autocomplete="off">
        <components-ui-text-box [tabIndex]="2" formControlName="MotivoCancelacion" [maxlength]="2000" label="Motivo"
          [widthLabelColumns]="3" [widthTextColumns]="9" />
        <div style="text-align: right; margin-top: 5px;">
          <button (click)="accept()" class="btn btn-success" style="margin-right: 5px;">Aceptar</button>
          <button (click)="closeModal()" class="btn btn-danger">Cancelar</button>
        </div>
      </form>
    </div>
  </app-panel-base>
</div>
