<app-container-base [hasCounter]="false" [entity]="myForm" icon="fa fa-arrow-trend-up" [hasBar]="false"
  subTitle="Carga y mantenimiento de Listas de Precios">
  <div class="screen-content" style="height: 100%;">
    <form [formGroup]="myForm" autocomplete="off">
      <div class="d-flex">
        <div class="col-sm-6">
          <app-panel-base title="Filtros de búsqueda">
            <div class="panel-content">

              <components-ui-text-box [enableIcon]="true" [maxLength]="5000" [tabIndex]="1" [label]="'Lista de Precio'"
                formControlName="ListaPrecio" [Entidad]="'ListaPrecio'" [widthTextColumns]="9" [widthLabelColumns]="3"
                #txtClase />

              <app-level-text-box [idEmpresa]="myForm.value.IdEmpresa" [captureMode]="false" label="Familia"
                (onChangue)="onChangueCuenta($event)" [widthLabelColumns]="3" [widthTextColumns]="9" [tabIndex]="2"
                [type]="'FamiliaProducto'" #txtFamilias />

              <components-ui-text-box [enableIcon]="true" [maxLength]="5000" [tabIndex]="7"
                [label]="'Clasificación producto'" formControlName="Clasificacion" [Entidad]="'ClasificacionProducto'"
                [widthTextColumns]="9" [widthLabelColumns]="3" />

              <components-ui-text-box [enableIcon]="true" [Entidad]="'TipoProducto'" [maxLength]="5000" [tabIndex]="8"
                [label]="'Tipo producto'" formControlName="Tipo" [widthTextColumns]="9" [widthLabelColumns]="3" />

              <components-ui-text-box [enableIcon]="true" [Entidad]="'Producto'" [maxLength]="5000" [tabIndex]="9"
                [label]="'Códigos'" formControlName="Codigos" [widthTextColumns]="9" [widthLabelColumns]="3" />

              <components-ui-text-box [enableIcon]="true" [Entidad]="'Producto'" [maxLength]="5000" [tabIndex]="10"
                [label]="'Excluir códigos'" formControlName="ExcluirCodigos" [widthTextColumns]="9"
                [widthLabelColumns]="3" />

              <components-ui-text-box [maxLength]="10" [tabIndex]="11" [label]="'Costo Por Kilo'"
                (keydown.enter)="enterKilo()" formControlName="CostoKilo" [widthTextColumns]="9" [widthLabelColumns]="3"
                #txtCostoKilo />



              <div class="form-group row">
                <div class="col-sm-3">

                </div>
                <div class="col-sm-9" style="text-align: right;">
                  <div (click)="calcularKilos()" *ngIf="myForm.value.CostoKilo>0" style="margin-right: 5px;" class="btn btn-success">Calcular
                  </div>
                  <div (click)="searchPrices()" style="margin-right: 15px;" class="btn btn-primary">Buscar</div>
                </div>
              </div>

            </div>
          </app-panel-base>
        </div>
        <div class="col-sm-6">
          <app-panel-base title="Importacion/Exportación" style="height:214px;">
            <div class="panel-content">
              <div class="btn-group-vertical" style="margin-top:10px;">
                <div (click)="searchFile()" class="btn btn-primary mb-1">Importar
                  CSV</div>
                <div (click)="exportData()" class="btn btn-primary mb-1 {{source.length>0?'':'disabled'}}">Exportar a
                  CSV
                </div>
                <div (click)="downloadFileExample()" class="btn btn-success">Decargar ejemplo
                </div>
              </div>
            </div>
          </app-panel-base>
        </div>

      </div>

      <app-panel-base title="Carga de precios">
        <div class="panel-content">
          <div style="min-height: 250px;">
            <div class="pt-2 pb-2 text-start" style="width:100%;">
              <a (click)="openAgregar()" class="btn btn-success btn-rounded px-4 rounded-pill"><i
                  class="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i>Agregar Producto</a>
            </div>
            <div id="divCargaPrecios">
              <table class="blueTable">
                <thead style="position: sticky;top:0;width:1190px;">
                  <tr>
                    <th style="width: 50px;"></th>
                    <th style="width: 80px;">Sucursal</th>
                    <th style="width: 150px;">Lista</th>
                    <th style="width: 90px;">Código</th>
                    <th style="width: 250px;">Descripción</th>
                    <th style="width: 50px;text-align:right;">Peso KG</th>
                    <th style="width: 100px;text-align:right;">Costo Rep</th>
                    <th style="width: 100px;text-align:right;">Divisor</th>
                    <th style="width: 100px;text-align:right;">% P Minimo</th>
                    <th style="width: 100px;text-align:right;">Precio Mínimo</th>
                    <th style="width: 100px;text-align:right;">Precio Máximo</th>
                    <th style="width: 100px;text-align:center;">Modificación</th>
                    <th style="width: 50px;text-align:center;">Global</th>
                  </tr>
                </thead>
              </table>
              <cdk-virtual-scroll-viewport itemSize="20" style="height: 300px;border-bottom: 1px solid #AAAAAA;">
                <table class="blueTable">
                  <thead style="width:1190px;visibility: collapse;">
                    <tr>
                      <th style="width: 50px;"></th>
                      <th style="width: 80px;">Sucursal</th>
                      <th style="width: 150px;">Lista</th>
                      <th style="width: 90px;">Código</th>
                      <th style="width: 250px;">Descripción</th>
                      <th style="width: 50px;text-align:right;">Peso KG</th>
                      <th style="width: 100px;text-align:right;">Costo Rep</th>
                      <th style="width: 100px;text-align:right;">Divisor</th>
                      <th style="width: 100px;text-align:right;">% P Minimo</th>
                      <th style="width: 100px;text-align:right;">Precio Mínimo</th>
                      <th style="width: 100px;text-align:right;">Precio Máximo</th>
                      <th style="width: 100px;text-align:center;">Modificación</th>
                      <th style="width: 50px;text-align:center;">Global</th>
                    </tr>
                  </thead>
                  <tbody class="bg-light">
                    <tr *cdkVirtualFor="let item of source; let index = index;"
                      [id]="'row-search' + idUnico + '_'+index"
                      [style]=" indexEditing == index ?  'background-color: #ffdbb7;': ''">
                      <td style="text-align: center;">
                        <i (click)="deleteRow(index, item.id)" class="fa fa-rectangle-xmark fa-lg"
                          style="color:red;font-weight: bold; cursor: pointer;"></i>
                      </td>
                      <td style="padding-left:5px;">{{item.sucursal.nombre}}</td>
                      <td style="padding-left:5px;">{{item.listaPrecio.nombre}}</td>
                      <td style="padding-left:5px;">{{item.producto.clave}}</td>
                      <td style="padding-left:5px;">{{item.producto.nombre}}</td>
                      <td style="padding-right:5px;text-align:right;">{{item.pesoEnKilos}}</td>
                      <td style="width: 100px;text-align:right;padding-right:5px;">
                        <input (keydown.arrowup)="up($event,4)" (keydown.arrowdown)="down($event,4)"
                          (keydown.escape)="escapecostoreposicion($event)" (keydown)="keyDownCostoRep($event, index)"
                          (keydown.enter)="entercostoreposicion($event)" (blur)="blurcostoreposicion($event)"
                          class="grid-editor text-dark" [id]="'txt_'+index+'_4' + idUnico"
                          (focus)="setIndexEdit(index, $event, item)" type="number"
                          [style]="'width:100px;text-align:right;height: 22px;'" [value]="item.costoReposicion" />
                      </td>
                      <td style="width: 100px;text-align:right;padding-right:5px;">
                        <input (keydown.arrowup)="up($event,5)" (keydown.arrowdown)="down($event,5)"
                          (keydown)="keyDownDivisor($event, index)" (keydown.escape)="escapedivisor($event)"
                          (keydown.enter)="enterdivisor($event)" (blur)="blurdivisor($event)"
                          class="grid-editor text-dark" [id]="'txt_'+index+'_5' + idUnico"
                          (focus)="setIndexEdit(index, $event, item)" type="number"
                          [style]="'width:100px;text-align:right;height: 22px;'" [value]="item.divisor" />
                      </td>
                      <td style="width: 100px;text-align:right;padding-right:5px;">
                        <input (keydown.arrowup)="up($event,6)" (keydown.arrowdown)="down($event,6)"
                          (keydown.escape)="escapeprecioutilidad($event)" (keydown.enter)="enterprecioutilidad($event)"
                          (keydown)="keyDownPrecioUtilidad($event, index)"
                          (blur)="blurprecioutilidad($event)" class="grid-editor text-dark"
                          [id]="'txt_'+index+'_6' + idUnico" (focus)="setIndexEdit(index, $event, item)" type="number"
                          [style]="'width:100px;text-align:right;height: 22px;'" [value]="item.porcentajePrecioMinimo" />
                      </td>
                      <td style="width: 100px;text-align:right;padding-right:5px;">
                        <input (keydown.arrowup)="up($event,7)" (keydown.arrowdown)="down($event,7)"
                          (keydown.escape)="escapepreciomimo($event)" (keydown.enter)="enterpreciomimo($event)"
                          (blur)="blurpreciomimo($event)" class="grid-editor text-dark"
                          [id]="'txt_'+index+'_7' + idUnico" (focus)="setIndexEdit(index, $event, item)" type="number"
                          [style]="'width:100px;text-align:right;height: 22px;'" [value]="item.precioMinimo" />
                      </td>
                      <td style="width: 100px;text-align:right;padding-right:5px;">
                        <input (keydown.arrowup)="up($event,8)" (keydown.arrowdown)="down($event,8)"
                          (keydown.escape)="escapepreciomaximo($event)" (keydown.enter)="enterpreciomaximo($event)"
                          (blur)="blurpreciomaximo($event)" class="grid-editor text-dark"
                          [id]="'txt_'+index+'_8' + idUnico" (focus)="setIndexEdit(index, $event, item)" type="number"
                          [style]="'width:100px;text-align:right;height: 22px;'" [value]="item.precioMaximo" />
                      </td>
                      <td style="width: 100px;text-align:center;">{{getDate(item.ultimaFechaModificacion)}}</td>
                      <td style="width: 50px;text-align:center;"> {{ item.global ? 'Si' : 'No' }}</td>
                    </tr>

                  </tbody>
                </table>
              </cdk-virtual-scroll-viewport>
              <!-- <ng-scrollbar cdkVirtualScrollViewport style="height: 300px;border-bottom: 1px solid #AAAAAA;">

              </ng-scrollbar> -->
            </div>
            <div class="alert alert-success"
              style="background-color: #348fe273 !important; margin-top: 3px;padding: 4px;text-align: right;">
              <strong>Número de registros:{{ source.length | number }}</strong>
            </div>
            <div class="form-group row" *ngIf="source.length>0;">
              <div class="col-sm-3">
              </div>
              <div class="col-sm-9" style="text-align: right;">
                <div (click)="savePrices()" style="margin-right: 0px; margin-top: 5px;" class="btn btn-primary">Guardar
                </div>
              </div>
            </div>
          </div>

          <ng-template #vacio>
            <div class="card bg-light border-0 text-dark" style="width: 1035px; height: 250px;">
              <div class="card-body">
                <h3 class="card-title" style="text-align: center;">No hay elementos para mostrar</h3>
                <p class="card-text" style="text-align: center;">Coloque los filtros y presione el botón buscar o bien
                  importe los registros.</p>
              </div>
            </div>
          </ng-template>
        </div>
      </app-panel-base>
      <input (change)="onchange($event)" [accept]="'.csv,.CSV'" tabindex="0" id="fileUpload" type="file"
        style="display: none;" #txtFile />
    </form>

    <ng-template #modalAgregar let-modal let-c="close">
      <app-agregar-producto-lista-page />
    </ng-template>

  </div>
</app-container-base>
