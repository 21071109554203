import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ComboBoxEntity } from 'src/app/component-ui/interfaces/combo-text.interface';
import { LevelType, ReportFilter, ReportHeader, TypeFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { ModalService } from 'src/app/service/modal.service';
import { ReportsService } from 'src/app/service/reports.service';

@Component({
  selector: 'impresion-disenos',
  templateUrl: './impresion-disenos.component.html',
  styleUrls: ['./impresion-disenos.component.scss']
})
export class ImpresionDisenosComponent {
  @ViewChild('txtNombreReporte')
  public txtNombreReporte!: ElementRef;

  private modalService = inject(ModalService);
  private frmBuilder = inject(FormBuilder);
  private reportsService = inject(ReportsService);

  public frmImpresionDisenos: FormGroup = this.frmBuilder.group({
    NombreReporte: ['Diseños de Producción', Validators.required],
    Num1: [2]
  });

  listaOpciones: any[] = [
    { Id: 1, Clave: 1, Nombre: "Base" },
    { Id: 2, Clave: 2, Nombre: "Especial" },
    { Id: 3, Clave: 3, Nombre: "Todos" }
  ];


  ngOnInit(): void {
    setTimeout(() => {
      this.opcionSeleccionada = this.listaOpciones[2];
      this.focus();
    }, 100);
  }

  focus() {
    setTimeout(() => {
      const txt: any = this.txtNombreReporte;
      txt.tagInput.nativeElement.focus()
    }, 100);
  }

  public filtrosImpresionDisenos: ReportFilter =
    {
      ReportHeader: {
        NombreReporte: 'REPORTE DISEÑOS DE PRODUCCIÓN'
      } as ReportHeader,
      TituloVisor: 'Diseños de Producción',
      NombreExcel: 'Disenos.xlsx',
      FilterOptions: [
        { Campo: 'e.Clave', Etiqueta: 'Empresa', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Empresa", HelpLine: "Claves de Empresas a consultar separadas por coma (1,2,3)." },
        { Campo: 's.Clave', Etiqueta: 'Sucursal', Tipo: TypeFilter.number, BotonBusqueda: true, Entidad: "Sucursal", HelpLine: "Claves de Sucursales a consultar separadas por coma (1,2,3)." },
        { Campo: 'p.Clave', Etiqueta: 'Clave Producto', Tipo: TypeFilter.text, BotonBusqueda: true, Entidad: "Producto", HelpLine: "Claves de Productos a consultar separadas por coma (1,2,3)." },
        { Campo: 'p.familias', Etiqueta: 'Familia', Tipo: TypeFilter.levels, TipoNiveles: LevelType.productFamily, HelpLine: "Líneas y Sublineas de los productos a consultar." },
        { Campo: 'p.Nombre', Etiqueta: 'Nombre', Tipo: TypeFilter.text, HelpLine: "Nombre de los productos que seran consultados en el reporte." },
      ]
    };

  changeselec(event: any) {
    this.filtrosImpresionDisenos = event;
  }

  print() {
    this.filtrosImpresionDisenos.ReportHeader = this.frmImpresionDisenos.value;
    this.reportsService.printReport(this.filtrosImpresionDisenos, '/Manufactura/ReporteDisenos');
  }

  close() {
    this.modalService.closeModal(null);
  }

  selectedCombo(entity: any) {
    this.opcionSeleccionada = entity;
    this.frmImpresionDisenos.get("Num1")?.setValue(this.opcionSeleccionada!.Id);
  }

  opcionSeleccionada?: ComboBoxEntity;
  get selectOpcionManualItem(): ComboBoxEntity | null {
    return this.opcionSeleccionada!;
  }
}
