import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EstadoCuentaCXC, SaldoCXC } from '../interfaces/saldocxc.interface';
import { Observable } from 'rxjs';
import { ReportFilter } from 'src/app/component-ui/interfaces/selection-filter.interface';
import { Result } from 'src/app/auth/interfaces';
import { PagoAplicadoCXC } from '../interfaces/pagos-aplicados-cxc.interface';
import { ItemEstadoCuentaCXC } from '../interfaces/cuentacxc.interface';
import { CxcCabecera } from '../interfaces/cxccabecera';
import { PagoDetalle } from '../interfaces/pago.interface';

@Injectable({ providedIn: 'root' })

export class CuentasPorCobrarService {

  constructor(private http: HttpClient) {

  }
  private readonly baseUrl: string = environment.baseUrlApi;

  getCustomerBalance(idEmp: number, idSuc: number, idCli: number) {
    const params = new HttpParams().set("idEmpresa", idEmp).set("idSucursal", idSuc).set("idCliente", idCli);
    return this.http.get<SaldoCXC[]>(`${this.baseUrl}/CuentasPorCobrar/ObtenerMovimientosCliente`, { params });
  }

  obtenerSaldoCliente(idEmpresa: number, idCliente: number) {
    const params = new HttpParams().set("idEmpresa", idEmpresa).set("idCliente", idCliente);
    return this.http.get<number>(`${this.baseUrl}/CuentasPorCobrar/ObtenerSaldoCliente`, { params });
  }

  getPagosAplicados(idCliente: number) {
    const params = new HttpParams().set("idCliente", idCliente);
    return this.http.get<PagoAplicadoCXC[]>(`${this.baseUrl}/CuentasPorCobrar/ObtenerPagosAplicados`, { params });
  }

  getPagoDetalle(idPago: number) {
    const params = new HttpParams().set("idPago", idPago);
    return this.http.get<PagoDetalle[]>(`${this.baseUrl}/CuentasPorCobrar/ObtenerPagoDetalle`, { params });
  }

  eliminarPagoAplicado(idPago: number) {
    const params = new HttpParams().set("idPago", idPago);
    return this.http.get<any>(`${this.baseUrl}/CuentasPorCobrar/EliminarPagoAplicado`, { params });
  }

  ReporteAntiguedadDeSaldos(reportFilter: ReportFilter): Observable<Result> {
    return this.http.post<any>(`${this.baseUrl}/CuentasPorCobrar/ReporteAntiguedadDeSaldos`, reportFilter);
  }

  obtenerListaDeAniosconMovimientosDeCXC() {
    // const params = new HttpParams().set("idEmpresa", idEmp).set("idSucursal", idSuc);
    return this.http.get<number[]>(`${this.baseUrl}/CuentasPorCobrar/ObtenerListaDeAniosConMovtosDeCXC`);
  }

  getEstadoDeCuenta(idCliente: number, idCuenta: number, anio: number) {
    const params = new HttpParams().set("idCliente", idCliente).set("idCuenta", idCuenta).set("anio", anio);
    return this.http.get<ItemEstadoCuentaCXC[]>(`${this.baseUrl}/CuentasPorCobrar/ConsultaEstadoCuentaPorCliente`, { params });
  }

  busquedaCuentas(filtro: string): Observable<Result> {
    const params = new HttpParams().set("filtro", filtro);
    return this.http.get<Result>(`${this.baseUrl}/CuentasPorCobrar/BuscarCuentas`, { params });
  }

  busquedaConceptos(filtro: string): Observable<Result> {
    const params = new HttpParams().set("filtro", filtro);
    return this.http.get<Result>(`${this.baseUrl}/CuentasPorCobrar/BuscarConceptos`, { params });
  }


  eliminarCxcDetalle(id: number): Observable<Result> {
    const params = new HttpParams().set("id", id);
    return this.http.get<Result>(`${this.baseUrl}/CuentasPorCobrar/EliminarCxcDetalle`, { params });
  }

  obtenerCxcCabecera(anio: number, idTipoMov: number, folio: number, cancelados: boolean = true): Observable<CxcCabecera> {
    const params = new HttpParams()
      .set("anio", anio)
      .set("idTipoMovto", idTipoMov)
      .set("folio", folio)
      .set("cancelados", cancelados);
    return this.http.get<CxcCabecera>(`${this.baseUrl}/CuentasPorCobrar/ObtenerCxcCabecera`, { params });
  }







  //eliminarInvFisicoDetalle


}
